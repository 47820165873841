import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import Close from "@mui/icons-material/Close";

export const EditXmlDialog = props => {
    const { open, handleClose, handleSave, xml } = props;
    const [xmlValue, setXmlValue] = useState(xml);

    useEffect(() => {
        setXmlValue(xml);
    }, [xml]);

    const handleXmlChange = e => {
        setXmlValue(e.target.value);
    }

    return (
        <Dialog open={open} fullWidth maxWidth='md'>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Edit XML</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    label='XML'
                    type='text'
                    fullWidth
                    multiline
                    rows={20}
                    value={xmlValue}
                    onChange={handleXmlChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancel
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button onClick={() => {
                    handleSave(xmlValue);
                    handleClose();
                }} color='primary'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}