import Publish from "@mui/icons-material/Publish";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";
import { RibbonButton } from "../Ribbon";

export const CreateDealButton = ({ expanded, disabled, formId, ...buttonProps }) => {
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);
    const { onSubmit, handleSubmit } = useFormContext();

    return (
        <>
            <DealEntryDialog
                open={openDealEntryDialog}
                dealToEdit={null}
                handleClose={() => setOpenDealEntryDialog(false)}
                handleSave={() => {
                    setOpenDealEntryDialog(false);
                    //submit the form to refresh the grid
                    handleSubmit(onSubmit)();
                }}
            />
            <Tooltip title="Create a new deal.">
                <span>
                    <RibbonButton
                        onMouseDown={() => {
                            setOpenDealEntryDialog(true);
                        }}
                        icon={<Publish fontSize='small' />}
                        label={'Create'}
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}
