import { MaterialDesignContent } from "notistack";
import { styled } from "@mui/system";

export const StyledSnackbar = styled(MaterialDesignContent)(({ theme }) => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.primary.green,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.primary.red,
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.primary.main,
    },
}));