import { debounce } from 'lodash';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, userGroups } from '../../../authConfig';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useHeader from '../../useHeader';
import 'ag-grid-community/styles/ag-grid.css'
import useStalkerGridLayout from './useStalkerGridLayout';
import { RowDetailsToolPanel } from '../../ToolPanels/DetailsToolPanel';
import { LayoutToolPanel } from '../../ToolPanels/LayoutToolPanel.js';
import { AgGridReact, } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import StalkerToolbar from './StalkerToolbar';
import TriggerOnChangeDialog from "./TriggerOnChangeDialog";
import _ from 'lodash';
import { useHubMessages } from '../../HubContext/useHubMessages';
import { tooltipClasses, styled, Divider, List, ListItem, Box, Tooltip, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useProtectedColumns from '../../useProtectedColumns';
import { stringIntComparator } from '../../../utils/gridFunctions';
import { AgGridContainer } from '../../AgGrid/AgGridContainer';
import useHubAction from '../../HubContext/useHubAction_v2';

const CustomTooltip = forwardRef((props, ref) => {
  const [data, setData] = useState(
    props.api.getDisplayedRowAtIndex(props.rowIndex).data
  );

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  alert();

  return (
    <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
      <p>ARef: {data.assignmentRef}</p>

    </div>
  );

});

export default (props) => {
  const { view, visible, handleOverwriteCriteria } = props;
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_tsrActivityWithAtc&parm=${guid}`;
  const gridRef = useRef();
  const formatString = 'YYYY-MM-DD HH:mm:ss.SSS';

  const storageLocation = `stalker-grid-${view.id}`;
  const [rowDetails, setRowDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [openTriggerOnChangeDialog, setOpenTriggerOnChangeDialog] = useState(false);
  const [selectedTsrs, setSelectedTsrs] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const hubMessage = useHubMessages();
  const [autoRefresh, setAutoRefresh] = useState(true);
  const newRows = useRef();
  const [loaded, setLoaded] = useState(false);
  const { filter } = useProtectedColumns([userGroups.admins]);

  useEffect(() => {
    if (gridRef.current && loaded) {
      retrieveTsrs();
    }
  }, [view]);

  const { diffData, } = useHubAction({
    action: silentRetrieveTsrs,
    allowedMessages: ['PowerStationMetaData.tsr_v2_insertUpdate'],
    predicate: (visible && autoRefresh && gridRef.current),
    callbackDependencies: [gridRef.current?.api, view],
  });

  function onCellFocused(e) {
    const i = e.rowIndex;
    const row = e.api.rowModel.rowsToDisplay[i];
    //row.data has the fields mapped to cell values, but we want headerNames mapped to cell values
    //(to make it look nice.) So first we create a mapping of fields to headerNames,
    //then use this to create the headerName:value mapping.
    if (row) {
      const colDefs = e.columnApi.columnModel.columnDefs;
      const headerNames = colDefs.reduce(function (names, current) {
        names[current.field] = current.headerName;
        return names;
      }, {}); //headerNames looks like { field: headerName }

      const data = Object.keys(row.data).reduce(function (data, field) {
        data[headerNames[field]] = row.data[field];
        return data;
      }, {}); //data looks like { headerName: cellValue }

      setRowDetails(data);
      const show = e.api.isToolPanelShowing('details');
      setShowDetails(show);
    }
  };

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      conte: yValue
    }
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED',
      "row-staged": params => params.data.TRIGGER_IDS?.split(','),
    },
  };

  // comparator: dateComparator,
  function dateComparator(date1, date2) {
    return moment(date1).diff(moment(date2));
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  const DarkTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(1, 1, 1, .95)',
      // color: 'rgba(0, 0, 0, 0.87)',
      color: 'white',
      boxShadow: theme.shadows[5],
      fontSize: 12,
    },
  }));

  const ArefCellRenderer = props => {
    const triggerIds = props.data.TRIGGER_IDS?.split(',');
    const info = JSON.parse(props.data.triggerInfo ?? '[]');
    return (
      <span>
        <span>{props.value}</span>&nbsp;
        {triggerIds && (
          <DarkTooltip arrow placement='right' title={
            <div>
              <Box sx={{ pt: 2, }}>
                <Divider>Trigger Info</Divider>
                {info.map(trigger => <><List dense>
                  <ListItem>ID: {trigger.id}</ListItem>
                  <ListItem>User: {trigger.userName}</ListItem>
                  <ListItem>Rule: {trigger.name}</ListItem>
                </List>
                  <Divider /></>)}
              </Box>
            </div>
          }>
            <VisibilityIcon fontSize="inherit" />
          </DarkTooltip>
        )}
      </span>
    )
  }

  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      }
    ]
  };

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      rowDrag: true,
      headerName: "ARef",
      enableRowGroup: true,
      field: "AssignmentRef",
      comparator: stringIntComparator,
      cellRenderer: 'arefCellRenderer',
      //tooltipComponent: CustomTooltip,
      //tooltipField: "assignmentRef",
      //tooltipComponentParams: { color: '#ececec' },
    },
    {
      headerName: "Time Queued",
      enableRowGroup: true,
      initialHide: false,
      field: "TimeQueued",
      comparator: dateComparator,
    },
    {
      headerName: "Est Time Queued",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "estimatedTimeQueued",
      comparator: dateComparator,
      protected: true,
    },
    {
      headerName: "Est Execution Time",
      // enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "estimatedExecutionTime",
      comparator: dateComparator,
      protected: false,
    },
    {
      headerName: "Est Lead Time",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "estimatedLeadTime",
      protected: false,
    },
    {
      headerName: "Actual Lead Time",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "actualLeadTime",
      protected: false,
    },
    {
      headerName: "Executed At",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "ExecutedAt",
      comparator: dateComparator,
      protected: false,
    },
    {
      headerName: "Completed At",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "CompletedAt",
      comparator: dateComparator,
      protected: true,
    },
    {
      headerName: "Triggered At",
      enableRowGroup: true,
      initialHide: true,
      field: "TriggeredAt",
      enableCellChangeFlash: true,
      comparator: dateComparator,
      protected: true,
    },
    {
      headerName: "Rank",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "rankWithinTimeQueued",
      protected: true,
    },
    {
      headerName: "Overall Rank",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "overallRanks",
      protected: true,
    },
    {
      headerName: "Trigger IDs",
      enableRowGroup: false,
      initialHide: true,
      field: "TRIGGER_IDS",
      protected: false,
    },
    {
      headerName: "Customer Code",
      enableRowGroup: true,
      initialHide: false,
      field: "CustomerCode",
    },
    {
      headerName: "Request Type",
      enableRowGroup: true,
      initialHide: false,
      field: "RequestType",
    },
    {
      headerName: "Status",
      enableRowGroup: true,
      initialHide: false,
      tooltipField: "Status",
      field: "Status",
      enableCellChangeFlash: true,
    },
    {
      headerName: "Seller Comments",
      enableRowGroup: true,
      initialHide: true,
      field: "SELLER_COMMENTS",
      enableCellChangeFlash: true,
    },
    {
      headerName: "Customer Comments",
      enableRowGroup: true,
      initialHide: true,
      field: "CUSTOMER_COMMENTS",
      enableCellChangeFlash: true,
    },
    {
      headerName: "Provider Comments",
      enableRowGroup: true,
      initialHide: true,
      field: "PRIMARY_PROVIDER_COMMENTS",
      enableCellChangeFlash: true,
    },
    {
      headerName: "Provider",
      enableRowGroup: true,
      field: "Provider",
    },
    {
      headerName: "POR",
      enableRowGroup: true,
      field: "PointOfReceipt",
    },
    {
      headerName: "POD",
      enableRowGroup: true,
      field: "PointOfDelivery",
    },
    {
      headerName: "Volume",
      enableRowGroup: true,
      field: "Capacity",
    },
    {
      headerName: "Start",
      enableRowGroup: true,
      field: "StartTime",
      comparator: dateComparator,
    },
    {
      headerName: "Stop",
      enableRowGroup: true,
      field: "StopTime",
      comparator: dateComparator,
    },
    {
      headerName: "Current NFATC",
      enableRowGroup: true,
      field: "currentNFATC",
      initialHide: true,
      enableCellChangeFlash: true,
      protected: true,
    },
    {
      //field: "atc",
      headerName: "Profile",
      cellRenderer: 'agSparklineCellRenderer',
      equals: _.isEqual,
      initialHide: true,
      minWidth: 400,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.capacityProfile) {
          const atcData = JSON.parse(params.data.capacityProfile).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      //field: "atc",
      headerName: "Competition",
      cellRenderer: 'agSparklineCellRenderer',
      enableCellChangeFlash: true,
      initialHide: true,
      minWidth: 400,
      equals: _.isEqual,
      protected: true,
      //flex: 1,
      initialHide: false,
      valueGetter: (params) => {
        if (params.data.historicalTraffic) {
          const trafficData = JSON.parse(params.data.historicalTraffic).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return trafficData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      //field: "atc",
      headerName: "NFATC",
      cellRenderer: 'agSparklineCellRenderer',
      enableCellChangeFlash: true,
      initialHide: true,
      equals: _.isEqual,
      minWidth: 400,
      protected: true,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.NFATC) {
          const atcData = JSON.parse(params.data.NFATC).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      //field: "atc",
      headerName: "FATC",
      cellRenderer: 'agSparklineCellRenderer',
      enableCellChangeFlash: true,
      equals: _.isEqual,
      minWidth: 400,
      initialHide: true,
      protected: true,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.FATC) {
          const atcData = JSON.parse(params.data.FATC).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      headerName: "Seller Code",
      enableRowGroup: true,
      initialHide: false,
      field: "SellerCode",
    },
    {
      headerName: "Preconfirmed",
      enableRowGroup: true,
      initialHide: true,
      field: "Preconfirmed",
    },
    {
      headerName: "Source",
      enableRowGroup: true,
      field: "Source",
      initialHide: true,
    },
    {
      headerName: "Sink",
      enableRowGroup: true,
      initialHide: true,
      field: "Sink",
    },
    {
      headerName: "Path Name",
      enableRowGroup: true,
      field: "PathName",
      initialHide: false,
    },
    {
      headerName: "System Element",
      enableRowGroup: true,
      field: "SYSTEM_ELEMENT",
      initialHide: true,
      protected: true,
    },
    {
      headerName: "Service Inc",
      enableRowGroup: true,
      initialHide: true,
      field: "ServiceIncrement",
    },
    {
      headerName: "Class",
      enableRowGroup: true,
      initialHide: true,
      field: "TSClass",
    },
    {
      headerName: "Type",
      enableRowGroup: true,
      initialHide: true,
      field: "TSType",
    },
    {
      headerName: "Period",
      enableRowGroup: true,
      initialHide: true,
      field: "TSPeriod",
    },
    {
      headerName: "Window",
      enableRowGroup: true,
      initialHide: true,
      field: "TSWindow",
    },
    {
      headerName: "Subclass",
      enableRowGroup: true,
      initialHide: true,
      field: "TSSubclass",
    },
    {
      headerName: "Ceiling Price",
      enableRowGroup: true,
      field: "CeilingPrice",
    },
    {
      headerName: "Offer Price",
      enableRowGroup: true,
      field: "OfferPrice",
    },
    {
      headerName: "Bid Price",
      enableRowGroup: true,
      initialHide: true,
      field: "BidPrice",
    },
    {
      headerName: "Price Unit",
      enableRowGroup: true,
      initialHide: true,
      field: "PriceUnit",
    },
    {
      headerName: "Sale Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "SaleRef",
    },
    {
      headerName: "Request Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "RequestRef",
    },
    {
      headerName: "Deal Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "DealRef",
    },
    {
      headerName: "PostingRef",
      enableRowGroup: true,
      initialHide: true,
      field: "PostingRef",
    },
    {
      headerName: "Reassigned Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedRef",
      enableCellChangeFlash: true,
    },
    {
      headerName: "Reassigned Capacity",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedCapacity",
    },
    {
      headerName: "Reassigned Start",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedStartTime",
      comparator: dateComparator,
    },
    {
      headerName: "Reassigned Stop",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedStopTime",
      comparator: dateComparator,
    },
    {
      headerName: "Related Ref",
      enableRowGroup: true,
      field: "RelatedRef",
    },
    {
      headerName: "Seller Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "SellerRef",
    },
    {
      headerName: "Reservation Profile Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "ReservationProfileFlag",
    },
    {
      headerName: "Affiliate Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "AffiliateFlag",
    },
    {
      headerName: "NP Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "NegotiatedPriceFlag",
    },
    {
      headerName: "Response Time Limit",
      enableRowGroup: true,
      initialHide: true,
      field: "ResponseTimeLimit",
    },
    {
      headerName: "Last Update",
      enableRowGroup: true,
      initialHide: true,
      field: "TimeOfLastUpdate",
      enableCellChangeFlash: true,
      comparator: dateComparator,
    },
    {
      headerName: "Capacity Granted",
      enableRowGroup: true,
      initialHide: true,
      enableCellChangeFlash: true,
      field: "CapacityGranted",
    },
    {
      headerName: "Impacted",
      enableRowGroup: true,
      enableCellChangeFlash: true,
      initialHide: true,
      field: "Impacted",
    },
    {
      headerName: "Competing Request Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "CompetingRequestFlag",
    },
    {
      headerName: "primaryProviderApproval",
      enableRowGroup: true,
      initialHide: true,
      field: "PrimaryProviderApproval",
    },
    {
      headerName: "primaryProviderProvisions",
      enableRowGroup: true,
      initialHide: true,
      field: "PrimaryProviderProvisions",
    },
    {
      headerName: "Rollover Waived",
      enableRowGroup: true,
      initialHide: true,
      field: "RolloverWaived",
    },
    {
      headerName: "CGStatus",
      enableRowGroup: true,
      initialHide: true,
      field: "CGStatus",
    },
    {
      headerName: "CGDeadline",
      enableRowGroup: true,
      initialHide: true,
      field: "CGDeadline",
    },
    {
      headerName: "Phone",
      enableRowGroup: true,
      initialHide: true,
      field: "CUSTOMER_PHONE",
    },
    {
      headerName: "Name",
      enableRowGroup: true,
      initialHide: true,
      field: "CUSTOMER_NAME",
    },
    {
      headerName: "Email",
      enableRowGroup: true,
      initialHide: true,
      field: "CUSTOMER_EMAIL",
    },
  ], []);

  const { layoutPanel, colDefs, loadLayout } = useStalkerGridLayout(storageLocation, gridRef, baseColDefs);

  const protectedDefs = useMemo(() => filter(colDefs), [colDefs]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        },
        layoutPanel,
        {
          id: 'details',
          labelDefault: 'Details',
          labelKey: 'details',
          //iconKey: '',
          toolPanel: 'detailsToolPanel',
          toolPanelParams: { rowData: rowDetails },
          minWidth: 470,
          maxWidth: 490,
          width: 490
        }
      ],
      position: 'right',
      //defaultToolPanel: 'filters'
    }
  }, [rowDetails]);

  function onRowDataUpdated(params) {
    if (newRows.current?.length && loaded) {
      const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
      params.api.flashCells({
        rowNodes: nodes,
        flashDelay: 1000,
        fadeDelay: 2000,
      });
      newRows.current = undefined;
    }
  }

  function getRowNodeId(params) {
    return params.data.ID;
  }

  async function silentRetrieveTsrs() {
    return fetch().then(response => {
      if (response) {
        console.log(`${view.label} TSR data retrieved.`);
        handleDataUpdate(response.data ?? []);
      }
    });
  }

  async function fetch() {
    const uri = `${loadUri}&parm=${encodeURIComponent(view.timezone)}`
      + `&parm=${view.requestType ?? ''}`
      + `&parm=${view.Provider ?? ''}`
      + `&parm=${view.POR ?? ''}`
      + `&parm=${view.POD ?? ''}`
      + `&parm=${view.customerCode ?? ''}`
      + `&parm=${view.serviceIncrement ?? ''}`
      + `&parm=${view.serviceClass ?? ''}`
      + `&parm=${view.startDate ?? ''}`
      + `&parm=${view.startHour ?? ''}`
      + `&parm=${view.stopDate ?? ''}`
      + `&parm=${view.stopHour ?? ''}`

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
      gridRef.current?.api.hideOverlay();
      setIsLoading(false);
    });
  }

  function retrieveTsrs() {
    gridRef.current.api.showLoadingOverlay();
    setIsLoading(true);

    fetch().then(response => {
      handleDataUpdate(response.data ?? [])
      enqueueSnackbar(`${view.label} TSR data retrieved.`);
      gridRef.current.api.hideOverlay();
      setIsLoading(false);
    })
  }

  function handleDataUpdate(newData) {
    const timestamp = moment();
    const oldData = [];
    gridRef.current.api.forEachNode(node => oldData.push(node.data));

    const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData);

    console.log(`Stalker grid ${view.label} data diff complete. ${moment().diff(timestamp)}ms elapsed.`)

    gridRef.current.api.applyTransaction({
      add: toAdd,
      addIndex: 0,
      update: toUpdate,
      remove: toDelete,
    });

    console.log(`Stalker grid ${view.label} update complete. ${moment().diff(timestamp)}ms elapsed.`)
    setLoaded(true);

    gridRef.current.api.refreshCells({ columns: ['AssignmentRef'], force: true })
    setLoaded(true);
  }

  function formatData(data) {
    return data.map(tsr => {
      const info = JSON.parse(tsr.ProfileInfo ?? '[]');
      return {
        ...tsr,
        profileInfo: configureProfile(info),
        profileConfig: configureProfileConfig(info)
      }
    })
  }

  function configureProfile(info) {
    return info.map(segment => ({
      price: segment.SegmentBidPrice,
      capacityRequested: segment.SegmentCapacity,
      startDateTime: moment(segment.StartTime_UTC).format(formatString),
      endDateTime: moment(segment.EndTime_UTC).format(formatString),
    }));
  }

  function configureProfileConfig(info) {
    const startDates = info.map(block => moment(block.StartTime_UTC));
    const endDates = info.map(block => moment(block.EndTime_UTC));
    const startDate = startDates.sort((a, b) => a - b)[0];
    const endDate = endDates.sort((a, b) => b - a)[0];
    return {
      startDate: startDate.format('MM/DD/YYYY'),
      startHour: startDate.format('HH'),
      stopDate: endDate.format('MM/DD/YYYY'),
      stopHour: endDate.format('HH'),
      defaultPrice: info[0] ? info[0].SegmentBidPrice : 0,
      defaultCapacity: info[0] ? info[0].SegmentCapacity : 0,
    };
  }

  async function handleSetTrigger(triggerTsr, confirmed = 0) {
    const params = {
      userGuid: guid,
      timeZone: triggerTsr.timezone,
      dispatchQueueRuleID: triggerTsr.dispatchQueueRuleID,
      arefs: gatherArefs(),
      label: triggerTsr.label,
      data: triggerTsr,
    }

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TSR_DispatchQueueTrigger_Insert&parm=${guid}&parm=${params.timeZone}&parm=${params.dispatchQueueRuleID}&parm=${params.arefs}&parm=${params.label}&parm=${confirmed}`,
    }

    if (!!confirmed) {
      gridRef.current.api.deselectAll();
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error saving TSR trigger. ${error.message}`);
    });
  }

  function gatherArefs() {
    return selectedTsrs.reduce((value, next, idx) => { //gathers all the arefs into a string separated by commas.
      return `${value}${next.AssignmentRef}${(idx < selectedTsrs.length - 1) ? ', ' : ''}`;
    }, '')
  }

  const onGridReady = () => {
    if (gridRef.current) {
      silentRetrieveTsrs();
    }
    loadLayout();
  }

  function handleTriggerClick() {
    const rows = gridRef.current.api.getSelectedRows();
    setSelectedTsrs(formatData(rows));
    setOpenTriggerOnChangeDialog(true);
  }

  function clearSelected() {
    gridRef.current.api.deselectAll();
  }

  function onSelectionChange(params) {
    const selected = gridRef.current.api.getSelectedRows();
    setSelected(selected);
  }

  const gatheredArefs = useMemo(() => {
    return selectedTsrs.reduce((value, next, idx) => { //gathers all the arefs into a string separated by commas.
      return `${value}${next.AssignmentRef}${(idx < selectedTsrs.length - 1) ? ', ' : ''}`;
    }, '')
  }, [selectedTsrs]);

  return (
    <AgGridContainer
      style={{
        width: "100%", height: '73vh'
      }}
    >
      <TriggerOnChangeDialog
        open={openTriggerOnChangeDialog}
        closeDialog={() => setOpenTriggerOnChangeDialog(false)}
        tsrs={selectedTsrs}
        arefs={gatheredArefs}
        handleSave={handleSetTrigger}
        configStorageKey={'stalker-trigger-config'}
      />
      <StalkerToolbar
        onRefresh={retrieveTsrs}
        clearSelected={clearSelected}
        view={view}
        selectedRows={selected}
        disableAllButtons={isLoading}
        handleTriggerClick={handleTriggerClick}
        autoRefresh={autoRefresh}
        setAutoRefresh={setAutoRefresh}
        handleOverwriteCriteria={handleOverwriteCriteria}
      />
      <AgGridReact
        ref={gridRef}
        statusBar={statusBar}
        onGridReady={onGridReady}
        columnDefs={protectedDefs}
        getRowId={getRowNodeId}
        onRowDataUpdated={onRowDataUpdated}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        sideBar={sideBar}
        enableCellTextSelection={true}
        paginationAutoPageSize={true}
        groupDefaultExpanded={1}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        onSelectionChanged={onSelectionChange}
        enableRangeSelection={true}
        rowSelection='multiple'
        onCellFocused={onCellFocused}
        animateRows={true}
        tooltipShowDelay={0}
        groupSelectsChildren={true}
        components={{
          customTooltip: CustomTooltip,
          detailsToolPanel: RowDetailsToolPanel,
          layoutToolPanel: LayoutToolPanel,
          arefCellRenderer: ArefCellRenderer,
        }}
      />
    </AgGridContainer>
  )
}
