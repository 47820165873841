import React, { useEffect, useState, useRef, useMemo } from 'react';
import useHeader from '../useHeader';
import { useRemoteLayout } from '../useRemoteLayout';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels'
import { DatabaseLayoutToolPanel } from '../ToolPanels/DatabaseLayoutToolPanel';
import { useData } from '../useData';
import { AgGridReact } from 'ag-grid-react';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
//import Box from '@mui/material/Box';
import { Box, Button } from '@mui/material';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
// import { LocalDataSourceViewUpdateNotifier } from 'igniteui-react-core';
//import FileUploaderDialog from '../FileUploaderDialog';
import GroupGuard from '../Guards/GroupGuard';
import { userGroups } from '../../authConfig';
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import AddBidLimitDialog from './AddBidLimitDialog';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';

export default React.memo(BidmanBidLimits);

function BidmanBidLimits({ node, refreshAll, triggerRefresh }) {
  const { nodeId, gridDataApiEndpoint, submitApiEndpoint } = node;
  const placeholderDate = '1/1/1900';
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const [startValue, setStartValue] = useState(null);
  const [stopValue, setStopValue] = useState(null);
  const [addBidLimitDialogOpen, setAddBidLimitDialogOpen] = useState(false);
  const { rowData, saveData, getData } = useData();


  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${placeholderDate}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${placeholderDate}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${placeholderDate}`;
  }

  const headers = useHeader();

  const handleAddBidLimitDialogOpen = () => {
    setAddBidLimitDialogOpen(true);
    // const message = 'Adding bid limit.';
    // return message;
  };

  const handleAddBidLimitDialogClose = () => {
    setAddBidLimitDialogOpen(false);
    const message = 'Done.';
    return message;
  };

  const handleAddBidLimitDialogCancel = () => {
    setAddBidLimitDialogOpen(false);
    const message = 'Canceled.';
    return message;
  };

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
  };

  const onBtCopyRange = () => {
    gridRef.current.api.copySelectedRangeToClipboard();
  };

  const onGetRowButtonClick = e => {
    const selectedNodes = gridRef.current.api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    enqueueSnackbar(`Selected nodes: ${JSON.stringify(selectedData)}`)
  }

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    enqueueSnackbar(`Selected nodes: ${JSON.stringify(rowData)}`)

  }

  async function handleDeleteButtonClick() {

    var api = gridRef.current.api;
    var selectedRows = api.getSelectedRows();
    if (!selectedRows || selectedRows.length === 0) {
      const message = 'No rows selected!';
      enqueueSnackbar(message);
    }

    //gridRef.current.api.stopEditing();

    //gridRef.current.api.forEachNode(node => rowData.push(node.data));

    const response = await axios.post(
      `${apiUrlPrefix}/CrystalBall/Bidman/BidLimitDelete?userGuid=${guid}&nodeId=${nodeId}`,
      // `${submitApiEndpoint}userGuid=${accounts[0].localAccountId}&nodeId=${nodeId}`,
      JSON.stringify(selectedRows),
      {
        headers: headers,
      }
    ).catch(err => enqueueSnackbar()`Error deleting bid limit.  NodeId: ${nodeId} Status: ${err.response?.status}. ${err}`);

    const returnMessage = await response.data;

    let message = '';

    if (returnMessage !== 'Success') {
      enqueueSnackbar(`Error deleting row.  Node: ${nodeId} Error Message:${returnMessage}`);
    } else {
      api.applyTransaction({ remove: selectedRows });
      message = 'Bid limit deleted.';

      enqueueSnackbar(message);
    }

  }

  async function handleSaveButtonClick() {

    gridRef.current.api.stopEditing();

    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`
    } else {
      url = `${apiUrlPrefix}/${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    }

    const response = await axios.post(
      url,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).catch(err => enqueueSnackbar(`Status: ${err.response?.status}. ${err}`));

    const returnMessage = await response.data;
    let message = 'Bids saved to database.';
    if (returnMessage !== 'Success') {
      message = returnMessage;
    }

    enqueueSnackbar(message);
    triggerRefresh({ market: node.market });
  }

  // enables the fill handle
  const enableFillHandle = true;

  // enables undo / redo
  const undoRedoCellEditing = true;

  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;

  // enables flashing to help see cell changes
  const enableCellChangeFlash = true;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const groupDefaultExpanded = -1;

  const onRefreshBtn = () => {
    getData(loadDataUri);
  };


  const gridOptions = {
    rowStyle: { paddingRight: '0', },
  };

  useEffect(() => {

    let abortController = new AbortController();
    // your async action is here  
    getData(loadDataUri);
    return () => {
      abortController.abort();
    }

  }, []);

  useEffect(() => {
    if (refreshAll.market === node.market) { getData(loadDataUri); }
  }, [refreshAll]);

  let newCount = 1;
  function createNewRowData() {
    const newData = {
      // make: 'Toyota ' + newCount,
      // model: 'Celica ' + newCount,
      // price: 35000 + newCount * 17,
      // zombies: 'Headless',
      // style: 'Little',
      // clothes: 'Airbag',
    };
    newCount++;
    return newData;
  }

  const handleDeleteRow = () => {
    var api = gridRef.current.api;
    var selectedRows = api.getSelectedRows();
    if (!selectedRows || selectedRows.length === 0) {
      const message = 'No rows selected!';
      return message;
    }

    //https://crystalballsandbox-development.azurewebsites.netCrystalBall/Bidman/BidLimitDelete?userGuid=df3791a2-140f-4f8d-9a27-d515ce266d5e&nodeId=1

    api.applyTransaction({ remove: selectedRows });
    const message = 'Row deleted.';
    return message;
  };

  const handleAddRowButtonClick = (addIndex) => {
    const newItems = [
      createNewRowData(),
    ];
    const res = gridRef.current.api.applyTransaction({
      add: newItems,
      addIndex: 0, //addIndex,
    });
    //printResult(res);
    const message = 'A blank row has been added. Enter the bid limit information and click the Save button.';
    enqueueSnackbar(message);
  };

  const markets = {
    values: ['PJM', 'NYISO', 'MISO', 'ERCOT', ''],
  };

  const categories = {
    values: ['LCI', 'PCI', ''],
  };

  const subaccounts = {
    values: ['PJM', 'NYISO', 'MISO', 'ERCOT', ''],
  };

  const colDefs = [
    {
      editable: true,
      checkboxSelection: true,
      headerName: "Market",
      enableRowGroup: true,
      field: "Market",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "Subaccount",
      enableRowGroup: true,
      field: "SubAccount",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "Category",
      enableRowGroup: true,
      field: "Category",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "Location",
      enableRowGroup: true,
      rowGroup: true,
      field: "InternalName",
      sortable: true,
      filter: true,
    },
    {
      suppressMenu: true,
      editable: true,
      headerName: "Start",
      field: "OprDayStart",
      cellEditor: "datePicker",
      cellEditorPopup: true,
    },
    {
      suppressMenu: true,
      editable: true,
      headerName: "End",
      cellEditor: "datePicker",
      cellEditorPopup: true,
      field: "OprDayThru",
    },
    {
      suppressMenu: true,
      editable: true,
      headerName: "Hard Limit",
      field: "HardLimit",
    },
    {
      suppressMenu: true,
      editable: true,
      headerName: "Soft Limit",
      field: "SoftLimit",
    },
  ];

  const { dbLayoutPanel, loadLayout, } = useRemoteLayout(gridRef, nodeId, colDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: false,
    floatingFilter: true,
    flex: 1,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout(params.api);
  }

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <AddBidLimitDialog
          addBidLimitDialogOpen={addBidLimitDialogOpen}
          handleAddBidLimitDialogClose={handleAddBidLimitDialogClose}
          handleAddBidLimitDialogCancel={handleAddBidLimitDialogCancel}
          startValue={startValue}
          stopValue={stopValue}
          setStartValue={setStartValue}
          setStopValue={setStopValue}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            //className="refresh-btn"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <Button
            onClick={() => onBtCopyRows()}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Ctrl-c</Button>&nbsp;
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineOutlinedIcon />}
            id="undoBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={() => onBtCopyRange()}
          >
            Copy Range
          </Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetRowButtonClick}>Get Selected</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetGridDataButtonClick}>Get Data</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleSaveButtonClick}
          >Save</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleAddRowButtonClick}
          >Add New Row</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleAddBidLimitDialogOpen}
          >Add New</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleDeleteButtonClick}
          >Delete</Button>&nbsp;
        </Box>

        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          ref={gridRef}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          rowData={rowData}
          sideBar={sideBar}
          //rowClass={denseGrid}
          //rowClassRules={rowClassRules}
          //suppressColumnVirtualisation={true}
          rowSelection="single"
          //pivotMode={true}
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          enableCellChangeFlash={enableCellChangeFlash}
          groupDefaultExpanded={groupDefaultExpanded}
          suppressCopyRowsToClipboard={true}
          defaultColDef={defaultColDef}
          //singleClickEdit 
          gridOptions={gridOptions}
          //onCellValueChanged={onCellValueChanged}
          //onPasteStart={onPasteStart}
          //onPasteEnd={onPasteEnd}
          //onFirstDataRendered={onFirstDataRendered}
          rowGroupPanelShow={'always'}
          enableRangeSelection={true}
          //domLayout={'autoHeight'}
          animateRows={true}
          //rowSelection="single"
          groupSelectsChildren={true}
          components={{
            dbLayoutToolPanel: DatabaseLayoutToolPanel,
            datePicker: DateCellEditor,
          }}
        />
      </AgGridContainer>
    </div>
  );


};

// export default function BidmanGridExport({ nodeId, gridDataAPIendpoint }) {
//   return <BidmanGrid />;
// }

