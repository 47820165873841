import { Chip } from "@mui/material";
import { styled } from '@mui/material/styles';

export const MessageChip = styled(Chip)(({ theme, error, sender, }) => ({
    height: 'auto',
    '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'normal',
    },
    width: 'fit-content',
    padding: '5px',
    ...(sender === 'user' && {
        color: theme.palette.primary.contrastText,
        backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
        float: 'right',
        marginLeft: 'auto',
    }),
    ...(sender !== 'user' && {
        float: 'left',
        marginRight: 'auto',
    }),
}));