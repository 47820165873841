import { useRef, useMemo, useState, useCallback, } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import useGridLayout from "../../useGridLayout"
import { useFormContext } from "react-hook-form"
import { useApi } from "../../useApi"

export const ScheduleHistoryGrid = ({ scheduleId, }) => {
    const gridRef = useRef(null);
    const [historyData, setHistoryData] = useState([]);
    const { columnTypes } = useColumnTypes();
    const layoutStorageKey = 'schedule-history-layout';
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const { get, headers, apiUrlPrefix, } = useApi();
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    async function fetchHistory() {
        gridRef.current?.api.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchScheduleHistory`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${scheduleId}`

        return get(url).then(response => {
            gridRef.current?.api.deselectAll();
            const newData = !!response?.data ? response.data : [];
            setHistoryData(newData);
        });
    }

    const baseColDefs = useMemo(() => ([
        {
            field: 'DATE_TIME_STAMP_UTC',
            headerName: "Time Stamp",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'changeProcess',
            header: "Process"
        },
        {
            field: 'Transaction_Type',
            header: "Buy/Sell"
        },
        { field: "Schedule_Status", initialHide: true, initialWidth: "150px", headerName: "Schedule Status" },
        { field: "followTag", initialHide: true, initialWidth: "100px", headerName: "Dynamic" },
        // { field: "linkedTags", initialHide: true,, initialWidth: "200px", headerName: "Linked Tags" },
        {
            headerName: "Net Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.scheduleProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        // { field: "Tag_Code", initialHide: true, headerName: "Tag Code" },
        // { field: "Req_Type", initialHide: true, headerName: "Req Type" },
        // { field: "Req_Status", initialHide: true, headerName: "Req Status" },
        // { field: "GPE", initialHide: true, headerName: "GPE" },
        // { field: "GCA", initialHide: true, headerName: "GCA" },
        // { field: "LCA", initialHide: true, headerName: "LCA" },
        // { field: "LSE", initialHide: true, headerName: "LSE" },
        // { field: "TPs", initialHide: true, headerName: "TPs" },
        // { field: "Sched_Entities", initialHide: true, headerName: "Sched Entities" },
        // { field: "Tag_Last_Action", initialHide: true, headerName: "Tag Last Action" },
        // { field: "Tag_Status", headerName: "Tag Status" },
        // { field: "Tag_Error", headerName: "Tag Error" },
        // { field: "Tag_Start_Time_MPT", headerName: "Tag Start Time MPT", type: 'dateColumn', },
        // { field: "Tag_Stop_Time_MPT", headerName: "Tag Stop Time MPT", type: 'dateColumn', },
        // {
        //     field: "Tag_Start_Time",
        //     headerName: "Tag Start Time",
        //     type: 'dateColumn',
        //     filter: 'agDateColumnFilter',
        // },
        // {
        //     field: "Tag_Stop_Time",
        //     headerName: "Tag Stop Time",
        //     type: 'dateColumn',
        //     filter: 'agDateColumnFilter',
        // },
        { field: "Schedule_Comment", initialHide: true,  headerName: "Schedule Comment" },
        { field: "Creator", headerName: "Creator" },
        { field: "Modifier", headerName: "Modifier" },
        {
            field: "Start_Date",
            headerName: "Start Date",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "End_Date",
            headerName: "End Date",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "Creation_Time",
            headerName: "Creation Time",
            initialHide: true, 
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "Modified_Time",
            headerName: "Modified Time",
            initialHide: true, 
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        { field: "Source", initialHide: true, headerName: "Source" },
        { field: "Sink", initialHide: true, headerName: "Sink" },
        { field: "POR",initialHide: true,  headerName: "POR" },
        { field: "POD",initialHide: true,  headerName: "POD" },
        // { field: "PSE_Comment", headerName: "PSE Comment" },

    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
    }), []);

    const { applySavedFilters, loadLayout, colDefs, layoutPanel, } = useGridLayout(
        layoutStorageKey,
        gridRef,
        baseColDefs,
        defaultColDef,
    );

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        fetchHistory();
        loadLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applySavedFilters();
    }, []);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                rowData={historyData}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '99%', }}
                ref={gridRef}
                columnDefs={styledColDefs}
                rowSelection="single"
                rowMultiSelectWithClick
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                suppressAggFuncInHeader={true}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                }}
            />
        </AgGridContainer>
    )
};
