import { IconButton, Dialog, DialogActions, DialogContent, } from "@mui/material"
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Datetime from "../DateTimePicker";
import ConfirmationDialog from "../ConfirmationDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ProfileDialog from "./ProfileDialog";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Tooltip, Switch, Stack, FormControl, Button, Box, TextField, Grid, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, } from 'react'
import { useSnackbar } from "notistack";
import moment from "moment";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import useFilteredOptions from "../useFilteredOptions";
import renderTimezone from "../renderTimezones";
import renderMomentTimezone from "../renderMomentTimezones";
import GroupGuard from "../../Guards/GroupGuard";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, date, array, } from 'yup';
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormCheckbox from "../../FormControls/FormCheckbox";

export default (props) => {
  const { currentEvent, open, onClose, onCancel, onDelete, confirmationMessage, openConfirm, setOpenConfirm, handleCopy, loadingConfirmation } = props;
  const isNew = !currentEvent?.id;
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const [disableAll, setDisableAll] = useState(false);
  const [processBatch, setProcessBatch] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const defaultValues = useMemo(() => ({
    ...currentEvent,
  }), [currentEvent]);

  const schema = object().shape({
    TargetExecutionTime: date().typeError('Must be a valid date').required('Target Execution Time is required'),
    'Customer Code': string().required('Customer Code is required'),
    requestType: string().required('Request Type is required.'),
    TimeZone: string().required('Timezone is required.'),
    Provider: string().required('Provider is required.'),
    POR: string().required('POR is required.'),
    POD: string().required('POD is required.'),
    Service_Increment: string().required('Service Increment is required.'),
    TS_Class: string().required('TS Class is required.'),
    TS_Period: string().required('TS Period is required.'),
    TS_Window: string().required('TS Window is required.'),
    TS_Type: string().required('TS Type is required.'),
    profileInfo: array().min(1, 'Please create a profile.'),
  });

  const { control, handleSubmit, setValue, formState: { errors, }, reset, getValues, register, watch, } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const TargetExecutionTime = watch('TargetExecutionTime');

  useEffect(() => {
    if (open) {
      reset(defaultValues);
      setProcessBatch(false);
      const now = moment();
      var eventTimeZone = renderMomentTimezone[currentEvent.TimeZone]
      const then = moment.tz(currentEvent.TargetExecutionTime, eventTimeZone)
      setDisableAll(now.isAfter(then));
    }
  }, [open])

  const watchFields = ['Provider', 'POR', 'POD', 'Source', 'Sink', 'Path_Name', 'Service_Increment', 'TS_Class', 'TS_Window', 'TS_Period', 'TS_Subclass', 'TS_Type',];
  const formValues = watchFields.reduce((acc, field) => {
    acc[field] = getValues(field);
    return acc;
  }, {});

  const { providers, filteredCombos, customerCodes, filteredTSCombos, relatedRefs } = useFilteredOptions(formValues);

  function onSubmit(data) {
    onClose(data);
  }

  function onConfirmation(data) {
    onClose(data, 1, processBatch);
  }

  function onError(errors) {
    if (errors.profileInfo) {
      enqueueSnackbar(`${errors.profileInfo.message}`, { variant: 'error' });
    }
  }

  function handleProfileUpdate(info, config) {
    setValue('profileInfo', info);
    setValue('profileConfig', config);
  }

  function getRelatedRefHelperText() {
    const relatedRef = getValues('relatedRef');
    const obj = relatedRefs.find(option => option.AssignmentRef === relatedRef);
    return obj ? `POR: ${obj.PointOfReceipt} - POD: ${obj.PointOfDelivery}` : '';
  }

  function handleDeleteTsr() {
    setOpenConfirmDelete(true);
    setProcessBatch(false);
  }

  return (
    <Dialog maxWidth={'md'} open={open}>
      <ConfirmationDialog
        open={openConfirm}
        message={confirmationMessage}
        loading={loadingConfirmation}
        onCancel={() => setOpenConfirm(false)}
        onConfirmation={handleSubmit(onConfirmation)}
        cancelText={'CANCEL'}
        confirmText={'SCHEDULE'}
      >
        {(currentEvent.batchCount > 1) &&
          <Box sx={{ p: 2, display: 'flex' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>This one only</Typography>
              <Switch checked={processBatch} onChange={() => setProcessBatch(checked => !checked)} />
              <Typography>Entire batch</Typography>
            </Stack>
          </Box>
        }
      </ConfirmationDialog>
      <DialogTitle>
        <Grid container alignItems={'center'}>
          <Grid item xs={6} container justifyContent={'flex-start'}>
            <Typography>Edit a Scheduled TSR</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={'flex-end'}>
            <Tooltip title='Copy the contents of this TSR to the scheduler.' arrow placement='top'>
              <IconButton onClick={handleSubmit(handleCopy)} size="large" >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Tooltip>&nbsp;&nbsp;
            <Tooltip title='Permanently delete this TSR schedule.' arrow placement='top'>
              <IconButton onClick={handleDeleteTsr} size="large" disabled={currentEvent.UserGuid !== guid || disableAll}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ConfirmationDialog
          open={openConfirmDelete}
          loading={loadingConfirmation}
          message={`You are about to delete the scheduled TSR. Continue?`}
          onCancel={() => setOpenConfirmDelete(false)}
          onConfirmation={() => {
            onDelete(currentEvent.id, processBatch);
            setOpenConfirmDelete(false);
            onCancel();
          }}
        >
          {(currentEvent.batchCount > 1) &&
            <Box sx={{ p: 2, display: 'flex' }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>This one only</Typography>
                <Switch checked={processBatch} onChange={() => setProcessBatch(checked => !checked)} />
                <Typography>Entire batch</Typography>
              </Stack>
            </Box>
          }
        </ConfirmationDialog>
        {openProfileDialog && <ProfileDialog
          open={openProfileDialog}
          closeDialog={() => setOpenProfileDialog(false)}
          currentEvent={{
            profileInfo: getValues('profileInfo'),
            profileConfig: getValues('profileConfig'),
            Provider: getValues('Provider'),
            Service_Increment: getValues('Service_Increment'),
          }}
          handleProfileUpdate={handleProfileUpdate}
          disabled={disableAll}
        />}
        <form onSubmit={handleSubmit(onSubmit, onError)} id='spy-monkey-edit-schedule-form'>
          <Box sx={{ p: 1, }}>
            {showMore
              ? (<Grid container justifyItems='start' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<ChevronLeftIcon />}
                    variant="contained"
                    size='small'
                    onClick={() => setShowMore(false)}
                  >Back</Button>
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name={'Customer Code'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled
                    options={customerCodes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Customer Code"
                        color="success"
                        placeholder="Customer Code"
                        error={!!errors['Customer Code']}
                        helperText={errors['Customer Code']?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('Customer_Comments')}
                    fullWidth
                    multiline
                    disabled={disableAll}
                    placeholder="Customer Comments"
                    rows={5}
                    variant='outlined'
                    label='Customer Comments'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('Sale Ref')}
                    label={'Sales Ref'}
                    size='small'
                    fullWidth
                    disabled={disableAll}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name='Related Ref'
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={relatedRefs.map(obj => obj.AssignmentRef)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Related Ref"
                        color="success"
                        helperText={getRelatedRefHelperText()}
                      />
                    )}
                  />
                </Grid>
                <GroupGuard groups={[]}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      placeholder="Raw JSON"
                      value={JSON.stringify(currentEvent)}
                      rows={5}
                      variant='outlined'
                      label='Raw JSON'
                    />
                  </Grid>
                </GroupGuard>
              </Grid>)
              : (<Grid container justifyContent="center" alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    {...register('label')}
                    label={'Label'}
                    size='small'
                    placeholder="Label"
                    disabled={disableAll}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}>
                  <Datetime
                    initialValue={TargetExecutionTime ?? currentEvent.TargetExecutionTime}
                    onChange={(newValue) => setValue('TargetExecutionTime', newValue)}
                    error={!!errors.TargetExecutionTime}
                    label='Schedule Date'
                    disabled={disableAll}
                    fullWidth
                    placeholder={'Schedule Date'}
                    helperText={errors.TargetExecutionTime?.message}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormAutocomplete
                    name='TimeZone'
                    control={control}
                    fullWidth
                    disabled={disableAll}
                    getOptionLabel={(option) => renderTimezone[option]}
                    options={Object.keys(renderTimezone).filter(opt => opt.includes('US'))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Timezone"
                        error={!!errors.TimeZone}
                        helperText={errors.TimeZone?.message}
                        color="success"
                        placeholder="Timezone"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    endIcon={<ChevronRightIcon />}
                    variant="contained"
                    fullWidth
                    onClick={() => setShowMore(true)}
                  >More</Button>
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name={'Provider'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={providers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Provider"
                        color="success"
                        placeholder="Provider"
                        error={!!errors.Provider}
                        helperText={errors.Provider?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'POR'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredCombos.pors}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POR"
                        color="success"
                        placeholder="POR"
                        error={!!errors.POR}
                        helperText={errors.POR?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'POD'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredCombos.pods}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POD"
                        color="success"
                        placeholder="POD"
                        error={!!errors.POD}
                        helperText={errors.POD?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'Source'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={filteredCombos.sources}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Source"
                        color="success"
                        placeholder="Source"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'Sink'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={filteredCombos.sinks}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Sink"
                        color="success"
                        placeholder="Sink"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name={'Path_Name'}
                    control={control}
                    fullWidth
                    disabled={disableAll}
                    options={filteredCombos.paths}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Path Name"
                        color="success"
                        placeholder="Path Name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} justifyContent='flex-start'>
                  <FormAutocomplete
                    name={'Service_Increment'}
                    control={control}
                    options={filteredTSCombos.serviceIncrements}
                    disabled={disableAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Service Increment"
                        size='small'
                        color="success"
                        error={!!errors.Service_Increment}
                        helperText={errors.Service_Increment?.message}
                        placeholder="Service Increment"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'TS_Class'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsClasses}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Class"
                        color="success"
                        placeholder="TS Class"
                        error={errors.TS_Class}
                        helperText={errors.TS_Class?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'TS_Type'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Type"
                        color="success"
                        placeholder="TS Type"
                        error={!!errors.TS_Type}
                        helperText={errors.TS_Type?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'TS_Period'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsPeriods}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Period"
                        color="success"
                        placeholder="TS Period"
                        error={!!errors.TS_Period}
                        helperText={errors.TS_Period?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'TS_Window'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsWindows}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Window"
                        color="success"
                        placeholder="TS Window"
                        error={!!errors.TS_Window}
                        helperText={errors.TS_Window?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'TS_Subclass'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsSubclasses}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Subclass"
                        color="success"
                        placeholder="TS Subclass"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'requestType'}
                    control={control}
                    fullWidth
                    disabled={disableAll}
                    options={['Original', 'Redirect']}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Request Type"
                        color="success"
                        error={!!errors.requestType}
                        helperText={errors.requestType?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl disabled={disableAll} fullWidth size="small">
                    <FormControlLabel label={'Pre-confirmed'} control={
                      <FormCheckbox name='Preconfirmed' control={control} />
                    } />
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    onClick={() => setOpenProfileDialog(true)}
                    variant="contained"
                    fullWidth
                  >Edit Profile</Button>
                </Grid>
              </Grid>)}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1, }} />
        <Button disabled={disableAll} variant="contained" color="primary" type="submit" form='spy-monkey-edit-schedule-form'>{isNew ? 'Schedule' : 'Save Changes'}</Button>
      </DialogActions>
    </Dialog>
  )
}
