import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material"
import { Typography, Button, Box, } from "@mui/material";
import { useMemo, useContext, memo } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { userGroups } from "../../authConfig";
import momentTimezones from "../momentTimezones";
import { renderStandardTimezones } from "../../utils/renderTimezones";
import dayjs from '../dayjs-tz';
import { TransmissionForm } from "../Forms/TransmissionForm";
import { useUserGroups } from "../../data/useUserGroups";

export const TransDialog = (props) => {
    const { open, data = {}, closeDialog, scheduleDateTime, handleUpdate, } = props;
    const { userIsInGroup } = useUserGroups();
    const proUser = userIsInGroup(userGroups.admins, userGroups.originalrequests);

    const defaultValues = useMemo(() => {
        const profile = JSON.parse(data?.ProfileInfo ?? '[]').map(block => ({
            //price: leg.OfferPrice,
            capacityRequested: parseInt(block.maxAvailability),
            startDateTime: dayjs(block.StartTime).format('MM/DD/YYYY HH:mm'),
            endDateTime: dayjs(block.StopTime).format('MM/DD/YYYY HH:mm'),
        }));
        const tz = data.timezone ?? 'Pacific Standard Time';
        const tzone = momentTimezones[tz ?? 'Pacific Standard Time'];
        const timezone = tz.includes('Standard') ? renderStandardTimezones[tz ?? 'Pacific Standard Time'] : tz ?? 'Pacific';
        return {
            ...data,
            Customer_Comments: data.CUSTOMER_COMMENTS ?? '',
            CustomerCode: data['Customer Code'],
            Service_Increment: data.ServiceIncrement ?? '',
            timezone: timezone,
            maxProfile: profile,
            action: 'reservefuture',
            targetExecutionTime: dayjs(scheduleDateTime).tz(tzone, true),
            Preconfirmed: data.Preconfirmed === 'Yes',
            profileConfig: {
                startDate: dayjs(profile[0]?.startDateTime),
                startHour: dayjs(profile[0]?.startDateTime).hour(),
                stopDate: dayjs(profile[profile.length - 1]?.endDateTime),
                stopHour: dayjs(profile[profile.length - 1]?.endDateTime).hour(),
                defaultPrice: data.OfferPrice ?? 0,
                defaultCapacity: data.maxCapacity ?? 0,
            }
        }
    }, [data])

    function formatJson(config) {
        return {
            ...config,
            ServiceIncrement: config.Service_Increment,
            Preconfirmed: config.Preconfirmed ? 'Yes' : 'No',
            'Customer Code': config.CustomerCode,
        }
    }

    function onSubmit(data) {
        handleUpdate(formatJson(data));
    }

    return (
        <Dialog maxWidth={'lg'} open={open}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Edit Reservation</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={closeDialog} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TransmissionForm
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    scheduleDateTime={scheduleDateTime}
                    disabled={!proUser}
                    disableChangeAction
                />
            </DialogContent>
            <DialogActions>
                <Box sx={{ flexGrow: 1, }} />
                <Button variant="contained" type='submit' form='tsr-transmission-form-0' disabled={!proUser} color="primary" >Save Changes</Button>
            </DialogActions>
        </Dialog>
    )
}

export const EditTsrDialog = memo(TransDialog);