import { useState, useEffect, forwardRef, useContext } from 'react';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { userGroups } from "../authConfig";
import Collapse from '@mui/material/Collapse';
import Help from '../components/Help';
import TransHunterAppMenu from '../components/TransHunter/TransHunterAppMenu';
import TransAlerts from '../components/TransHunter/TransAlerts';
import GroupGuard from '../components/Guards/GroupGuard';
import { UserContext } from "../data/fetchUserGroups";
import TSRActivity from './TSRActivity';
import CornerRibbon from 'react-corner-ribbon';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import { Box, Button, Stack, Fade } from '@mui/material';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';
import { useParams } from 'react-router';
import { Outlet, useNavigate } from 'react-router';
import FlexCollapse from '../components/FlexCollapse';

const MyRibbon = forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <CornerRibbon
                style={{ fontSize: 20, p: 5 }}
                position="top-right"
            >
                <Stack>
                    <pre style={{ fontFamily: ["Roboto", "Helvetica", "Arial"] }}>                          Search results are limited in demo version.                      </pre>

                    <Button
                        size="large"
                        sx={{ color: '#FFF', }}
                        href="https://webforms.pipedrive.com/f/5X4yAVMERh0y8mGszS6ntpwcPhTToxMyqwqNXhrZXfn7xl0R187lTPeBzlp0B4jxCP"
                    >Click here to request upgrade</Button>

                </Stack>
            </CornerRibbon>
        </div>
    );
})

const viewStorageKey = 'transHunterView';

export const TransHunter = () => {
    const savedView = localStorage.getItem(viewStorageKey);
    const params = useParams();
    const view = params.view;
    const navigate = useNavigate();
    const groups = useContext(UserContext);

    const proUser = groups.includes(userGroups.transhunterpro) || groups.includes(userGroups.admins);

    useEffect(() => {
        if (!view) {
            //remove spaces in the saved view and make lowercase
            const newView = savedView?.replace(/\s/g, '').toLowerCase() ?? 'availabilityalerts';
            navigate(`/transhunter/${newView}`);
        }
    }, [view, savedView, navigate]);

    return (
        <CriteriaProvider>
            {!proUser && <Fade timeout={3000}><MyRibbon /></Fade>}
            <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden', }}>
                <TransHunterAppMenu selected={view} />
                <Outlet />
            </Box>
        </CriteriaProvider>
    )
}

export const TransHunterViews = () => {
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;

    const transAlertsViews = ['availabilityalerts', 'longtermavailability', 'alldailysegments', 'flowgates', 'transofferings', 'summarystatistics', 'outages',];
    const transAlertsViewNames = ['Availability Alerts', 'Long Term Availability', 'All Daily Segments', 'Flowgates', 'Trans Offerings', 'Summary Statistics', 'Outages',];

    const findViewName = (view) => {
        const index = transAlertsViews.indexOf(view);
        const name = transAlertsViewNames[index];
        return name;
    }

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    return (
        <Box
            className='flex-column'
            sx={{
                p: '0px',
                overflowY: 'auto',
            }}
        >
            {loadedViews['audit'] && <GroupGuard groups={[userGroups.transhunter]}>
                <Collapse in={view === 'audit'} key={'audit_collapse'}>
                    <TransHunterAuditGrid />
                </Collapse>
            </GroupGuard>}
            {transAlertsViews.find(v => loadedViews[v]) && <Collapse in={transAlertsViews.includes(view)} key={'transalerts_collapse'}>
                <TransAlerts view={findViewName(view)} />
            </Collapse>}
            {loadedViews['transhunterhelp'] && <Collapse in={view === 'transhunterhelp'} key={'transhunterhelp_collapse'}>
                <Help appName={'TransHunter'} />
            </Collapse>}
            {loadedViews['tsractivity'] && <FlexCollapse in={view === 'tsractivity'} key={'tsractivity_collapse'}>
                <TSRActivity visible={view === 'tsractivity'} application='transhunter' />
            </FlexCollapse>}
            {loadedViews['pathmonitor'] && <Collapse in={view === 'pathmonitor'} key={'pathmonitor_collapse'}>
                <PathMonitor />
            </Collapse>}
            {loadedViews['transactionqueue'] && <Collapse in={view === 'transactionqueue'} key={'transactionqueue_collapse'}>
                <CriteriaProvider>
                    <PathMonitor application={'transhunter'} />
                </CriteriaProvider>
            </Collapse>}
        </Box>
    )
}
