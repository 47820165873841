import { Box, IconButton } from "@mui/material";
import { useEffect, } from "react";
import { useAzureBlobs } from "./useAzureBlobs";
import styled from "@emotion/styled";

export default (props) => {
  const { data, node, api, } = props;
  const containerName = process.env.REACT_APP_AZURE_BLOB_IMAGE_CONTAINER_NAME;
  const { blobs, fetchBlobs, } = useAzureBlobs(containerName);

  useEffect(() => {
    fetchBlobs(`ticket-${data.supportRequestID}`);
  }, [data]);

  return (
    <Box sx={{ p: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {blobs.length === 0 ? <h3>No Images Found</h3> : (
        blobs && blobs.map((blobItem, index) => {
          return (
            <Box key={index} sx={{ padding: 1, }}>
              <img
                src={blobItem.url}
                style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }}
                onClick={() => window.open(blobItem.url, '_blank')}
              />
            </Box>
          )
        }))}
    </Box>
  );
}