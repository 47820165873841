import { UserContext } from '../../data/fetchUserGroups'
import React, { useState, useEffect } from "react";
import { useIsAuthenticated } from '@azure/msal-react';

export default function GroupGuard(props) {
    const { groups, authenticatedOnly=false, } = props;
    const [isAuthorized, setIsAuthorized] = useState(false);
    const userGroups = React.useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if(authenticatedOnly) {
            setIsAuthorized(isAuthenticated);
        } else {
            const intersection = groups.filter(grp => userGroups.includes(grp));
            setIsAuthorized((intersection.length > 0 || userGroups.includes('admins')) && isAuthenticated);
        }
    }, [userGroups])

    if(isAuthorized) {
        return <>{props.children}</>;
    }
    return null;
} 
