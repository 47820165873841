import * as React from 'react';
import { useState, useEffect, useMemo, forwardRef, useContext, } from "react";
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../useActionAudit";

export default forwardRef((props, ref) => {
  const { logAction } = useActionAudit();
  const { rowData, } = props;

  const colDefs = useMemo(() => [
    {
      field: 'Provider',
    },
    {
      field: 'PointOfReceipt',
      headerName: 'POR',
    },
    {
      field: 'PointOfDelivery',
      headerName: 'POD',
    },
    {
      field: 'Source',
    },
    {
      field: 'Sink',
    },
    {
      field: 'RequestType',
      headerName: 'Request Type',
    },
    {
      field: 'ServiceIncrement',
      headerName: 'Increment',
    },
    {
      field: 'TSClass',
      headerName: 'TS Class',
    },
    {
      field: 'TSPeriod',
      headerName: 'TS Period',
    },
    {
      field: 'TSSubclass',
      headerName: 'TS Subclass',
    },
    {
      field: 'TSType',
      headerName: 'TS Type',
    },
    {
      field: 'TSWindow',
      headerName: 'TS Window',
    },
    {
      field: 'Capacity',
    },
    {
      field: 'BidPrice',
      headerName: 'Bid Price',
    },
    {
      field: 'SellerCode',
      headerName: 'Seller Code',
    },
  ], [])

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ]
  }), []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  return (
    <AgGridReact 
      ref={ref}
      rowData={rowData}
      columnDefs={colDefs}
      rowSelection={'single'}
      defaultColDef={defaultColDef}
      sideBar={sideBar}
      overlayNoRowsTemplate={'<span>Fetch routes to see data here.</span>'}
      animateRows={true}
    />
  )
})