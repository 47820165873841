import { useLocalGuid } from "../data/UserGuidContext/useLocalGuid"
import { subscriptionKey } from "../authConfig";
import { useMemo } from "react";

export default (contentType='application/json') => {
    const guid = useLocalGuid();
    const headers = useMemo(() => {
      return {
        'Content-Type': contentType,
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'userGuid': guid,
      }
    }, [guid, subscriptionKey])
    return headers;
}