import React, { useMemo, useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, TextField, Stack, Autocomplete } from "@mui/material";
import Select from '@mui/material/Select';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import ResizingPane from 'react-resizing-pane';
import { AgGridReact, } from 'ag-grid-react';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useViewPanel } from '../ToolPanels/useViewPanel'
import { useData } from '../useData';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels.js';
import EastIcon from '@mui/icons-material/East';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const { userTemplates, handleMapping } = props;
  const { rowData, getData, } = useData();
  const grid = "certificates";
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ name: '', });
  const [openMappingDialog, setOpenMappingDialog] = useState(false);

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const guid = useLocalGuid();
  const loadUri = `${apiUrlPrefix}/CrystalBall/TSR/CertificateInfo?UserGuid=${guid}`;


  const onRefreshBtn = () => {
    saveLayoutLocal();
    getData(loadUri);
    let message = 'certificate List Refreshing...';
    enqueueSnackbar(message);
  };

  useEffect(() => {

    //getData(loadUri);

    let abortController = new AbortController();
    // your async action is here  
    getData(loadUri);
    return () => {
      abortController.abort();
    }

  }, []);

  const onBtnExport = () => {
    ref.current.api.exportDataAsCsv();
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-fail": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-pass": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-waiting": params => params.api.getValue("Status", params.node) === 'QUEUED'
    },
  };

  const colDefs = [
    {
      checkboxSelection: true,
      rowDrag: true,
      width: 170,
      cellRenderer: 'agGroupCellRenderer',
      headerName: "Name",
      field: "FriendlyName",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Start",
      width: 100,
      filter: "agMultiColumnFilter",
      field: "GoodOn",
    },
    {
      headerName: "Expiration",
      width: 100,
      filter: "agMultiColumnFilter",
      field: "ExpiresOn",
    },
    {
      headerName: "Subject",
      enableRowGroup: true,
      width: "60",
      field: "Subject",
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Thumbprint",
      enableRowGroup: true,
      width: "100",
      field: "ThumbPrint",
      filter: "agMultiColumnFilter",
    },
  ]

  const { viewPanel, saveLayoutLocal, loadLayoutLocal } = useViewPanel(grid, ref, `${grid}-grid-layout`, colDefs, true);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  const certificatesSideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
      viewPanel,
    ],
    position: 'right',
  };

  function onGridReady(params) {
    loadLayoutLocal();
  }

  function onSelectionChanged(params) {
    const rows = params.api.getSelectedRows();
    setSelectedRows(rows);
  }

  function onHandleMapping() {
    setOpenMappingDialog(false);
    handleMapping(selectedRows, selectedUser);
  }

  return (
    <AgGridContainer
      style={{
        height: '86vh',
        width: "100%"
      }}
    >
      <Dialog open={openMappingDialog}>
        <DialogTitle>
          {"Select a user to map certificates to."}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 1, }}>
            <Autocomplete
              fullWidth
              autoComplete
              autoSelect
              options={userTemplates}
              value={selectedUser}
              getOptionLabel={o => o.name}
              onChange={(_, newValue) => {
                setSelectedUser(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  label="User"
                  color="success"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMappingDialog(false)}>Cancel</Button>
          <Button onClick={onHandleMapping}>Continue</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ display: 'flex', p: 1 }}>
        <Stack alignItems='center' spacing={2} direction='row'>
          <Tooltip title="Refresh the certificate list." arrow placement="top">
            <Button
              endIcon={<RefreshOutlinedIcon />}
              id="refresh"
              size={buttonSize}
              variant={buttonVariant}
              color={buttonColor}
              onClick={() => onRefreshBtn()}
            >Refresh</Button>
          </Tooltip>
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <Button
              onClick={() => onBtnExport()}
              endIcon={<DownloadForOfflineIcon />}
              id="csv"
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
            >CSV</Button>
          </Tooltip>
          <Tooltip title="Map selected certs to users." arrow placement="top">
            <Button
              onClick={() => setOpenMappingDialog(true)}
              endIcon={<EastIcon />}
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
              disabled={!selectedRows.length}
            >Map Certificate</Button>
          </Tooltip>
        </Stack>
      </Box>
      <AgGridReact
        ref={ref}
        rowData={rowData}
        onSelectionChanged={onSelectionChanged}
        enableFillHandle={true}
        onGridReady={onGridReady}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        enableCellChangeFlash={true}
        groupDefaultExpanded={true}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        sideBar={certificatesSideBar}
        gridOptions={gridOptions}
        animateRows={true}
        rowSelection='multiple'
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        tooltipShowDelay={0}
        components={{
          viewToolPanel: GridViewToolPanel,
        }}
      />
    </AgGridContainer>
  );
});