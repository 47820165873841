import { useEffect, useState, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/dayAheadStyles.css'
import { useSnackbar } from 'notistack';
import DayAheadTSR from './DayAheadTSR';
import DayAheadReassignments from './DayAheadReassignments';
import DayAheadRedirects from './DayAheadRedirects';
import TemplateTable from './TemplateTable'
import ResizingPane from 'react-resizing-pane';
import { serviceRowValues } from '../../utils/RedirectServiceValues';
import DivGuard from '../../components/Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import { useTemplateTable } from '../useTemplateTable';

const DayAheadGrids = () => {

  const tsrGridRef = useRef();
  const reassignGridRef = useRef();
  const redirectGridRef = useRef();
  const reassignmentTemplateTypeId = 2;
  const redirectTemplateTypeId = 3;

  const {
    templates: reassignTemplates,
    addTemplate: addReassignTemplate,
    deleteTemplate: deleteReassignTemplate,
    onTemplateClick: onReassignTemplateClick,
    addGridDropZone
  } = useTemplateTable(reassignTemplateRowTransform, reassignmentTemplateTypeId, tsrGridRef, reassignGridRef, 'da-reassign', 'TSR Grid', 'Reassign Grid');

  const {
    templates: redirectTemplates,
    addTemplate: addRedirectTemplate,
    deleteTemplate: deleteRedirectTemplate,
    onTemplateClick: onRedirectTemplateClick,
  } = useTemplateTable(redirectTemplateRowTransform, redirectTemplateTypeId, reassignGridRef, redirectGridRef, 'da-redirect', 'Reassign Grid', 'Redirect Grid');

  useTemplateTable(redirectTemplateRowTransform, redirectTemplateTypeId, tsrGridRef, redirectGridRef, 'da-redirect', 'TSR Grid', 'Redirect Grid');

  const { enqueueSnackbar } = useSnackbar();

  let storageConfig = { name: 'ResizableTSRgrid', type: localStorage };

  useEffect(() => {
    if (addGridDropZone) {
      addGridDropZone('#day-ahead-reassign-grid', reassignTemplateRowTransform, tsrGridRef, reassignGridRef);
      addGridDropZone('#day-ahead-redirects-grid', redirectTemplateRowTransform, tsrGridRef, redirectGridRef);
      addGridDropZone('#day-ahead-redirects-grid', redirectTemplateRowTransform, reassignGridRef, redirectGridRef);
    }
  }, [reassignTemplates, redirectTemplates]);

  function redirectTemplateRowTransform(row, template) {
    const newRow = { ...row }
    if (template) {
      newRow['PointOfDelivery'] = template.POD;
      newRow['PointOfReceipt'] = template.POR;
    }
    if (row['defaultStartDate']) { newRow['Start Date'] = row['defaultStartDate']; }
    if (row['defaultStopDate']) { newRow['Stop Date'] = row['defaultStopDate']; }
    //if(row['defaultStartTime']) {newRow['Start Time'] = row['defaultStartTime'];} 
    //if(row['defaultStopTime']) {newRow['Stop Time'] = row['defaultStopTime'];} 
    if (row['AssignmentRef']) {
      newRow['Redirected Ref'] = row['AssignmentRef'];
    }
    else {
      newRow['Redirected Ref'] = row['New Aref'];
      newRow['New Aref'] = '';
    }
    newRow['Status'] = 'PRE-SUBMITTED';
    newRow['Preconfirmed'] = 'YES';
    newRow['Price'] = '0'; //per TEA, default price of redirects to 0 -ecz 4/9/2022

    newRow['Sale Ref'] = undefined;
    newRow['AssignmentRef'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["OASISInstance"] = undefined;
    newRow["StartUTC"] = undefined;
    newRow["StopUTC"] = undefined;
    newRow["Start"] = undefined;
    newRow["Stop"] = undefined;
    newRow["Start Time"] = undefined;
    newRow["Stop Time"] = undefined;
    newRow["defaultStartDate"] = undefined;
    newRow["defaultStartTime"] = undefined;
    newRow["defaultStopDate"] = undefined;
    newRow["defaultStopTime"] = undefined;
    newRow["Reassigned Ref"] = undefined;
    newRow["RequestType"] = undefined;
    newRow["Related Ref"] = undefined;
    newRow["SellerRef"] = undefined;
    newRow["PriceUnit"] = undefined;
    newRow["CUSTOMER_NAME"] = undefined;
    newRow["CUSTOMER_EMAIL"] = undefined;
    newRow["Request Ref"] = undefined;
    newRow["Deal Ref"] = undefined;
    newRow["Posting Ref"] = undefined;

    //match the row values to a service.
    const matchingService = Object.keys(serviceRowValues).find(svc => {
      const values = serviceRowValues[svc];
      const isMatch = values['Service_Increment'] === row['Service_Increment']
        && values['TS_Class'] === row['TS_Class']
        && values['TS_Type'] === row['TS_Type']
        && values['TS_Period'] === row['TS_Period']
        && values['TS_Window'] === row['TS_Window']
        && values['TS_Subclass'] === row['TS_Subclass'];
      return (isMatch);
    }) ?? 'Custom';
    newRow.Service = matchingService;
    return newRow;
  }

  function reassignTemplateRowTransform(row, template) {
    const newRow = { ...row };
    if (template) {
      newRow['Customer Code'] = template.PUD;
    }
    newRow['Status'] = 'PRE-SUBMITTED';
    if (row['AssignmentRef']) {
      newRow['Reassigned Ref'] = row['AssignmentRef'];
    }
    else {
      newRow['Reassigned Ref'] = row['New Aref'];
      newRow['New Aref'] = undefined;
    }
    newRow['Start Date'] = row['defaultStartDate'];
    //newRow['Start Time'] = row['defaultStartTime'];
    newRow['Stop Date'] = row['defaultStopDate'];
    newRow['Price'] = newRow['Price'].toString();
    //newRow['Stop Time'] = row['defaultStopTime'];
    newRow['Preconfirmed'] = 'YES';
    newRow['AssignmentRef'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["OASISInstance"] = undefined;
    newRow["StartUTC"] = undefined;
    newRow["StopUTC"] = undefined;
    newRow["Start"] = undefined;
    newRow["Stop"] = undefined;
    newRow["Start Time"] = undefined;
    newRow["Stop Time"] = undefined;
    newRow["defaultStartDate"] = undefined;
    newRow["defaultStartTime"] = undefined;
    newRow["defaultStopDate"] = undefined;
    newRow["defaultStopTime"] = undefined;
    newRow["Redirected Ref"] = undefined;
    newRow["RequestType"] = undefined;
    newRow["Related Ref"] = undefined;
    newRow["SellerRef"] = undefined;
    newRow["PriceUnit"] = undefined;
    newRow["CUSTOMER_NAME"] = undefined;
    newRow["CUSTOMER_EMAIL"] = undefined;
    newRow["Request Ref"] = undefined;
    newRow["Deal Ref"] = undefined;
    newRow["Posting Ref"] = undefined;

    return newRow;
  }

  const [timezone, setTimezone] = useState('Pacific Standard Time');

  const handleMasterTimezoneChange = (event) => {
    setTimezone(event.target.value);
    let message = `Time zone changed to ${event.target.value}.`;
    enqueueSnackbar(message);

  };

  return (
    <DivGuard groups={[userGroups.dayaheadgrids]} >
      <div className="day-ahead-example-wrapper">

        <ResizingPane
          sides={['right']}
          storageId={21}
          storageConfig={storageConfig}
          height="100%"
          width={750}
          style={{
            border: '0px solid blue',
          }}
        >
          <DayAheadTSR ref={tsrGridRef} handleMasterTimezoneChange={handleMasterTimezoneChange} timezone={timezone} />
        </ResizingPane>
        <div className="day-ahead-inner-col vertical-toolbar">
          <TemplateTable
            title='TransAssign'
            tableId='da-reassign'
            id='day-ahead-reassign'
            tooltipText='These are TransAssign templates. To create new ones, use the Templates tool panel on the reassignments grid.'
            templates={reassignTemplates}
            handleRowClick={onReassignTemplateClick}
            rowTooltip={function (template) {
              return (<span>Using this template will reassign a TSR to {template.PUD}</span>);
            }}
            panelParams={{
              fieldNames: ['PUD', 'Name'],
              currentTemplates: reassignTemplates,
              addTemplate: addReassignTemplate,
              deleteTemplate: deleteReassignTemplate,
            }}
          />
          <div className="day-ahead-divider"></div>
          <TemplateTable
            title='Redirect'
            tableId='da-redirect'
            id='day-ahead-redirects'
            tooltipText='These are Redirects templates. To create new ones, use the Templates tool panel on the redirects grid.'
            templates={redirectTemplates}
            handleRowClick={onRedirectTemplateClick}
            rowTooltip={function (template) {
              return (<span>Using this template will redirect a TSR from {template.POR} to {template.POD}</span>);
            }}
            panelParams={{
              fieldNames: ['POD', 'POR', 'Name'],
              currentTemplates: redirectTemplates,
              addTemplate: addRedirectTemplate,
              deleteTemplate: deleteRedirectTemplate,
            }}
          />
        </div>
        <div className="day-ahead-container">
          <div id='day-ahead-reassign-grid'>
            <DayAheadReassignments ref={reassignGridRef} templates={reassignTemplates} addTemplate={addReassignTemplate} deleteTemplate={deleteReassignTemplate} timezone={timezone} />
          </div>
          <div className="day-ahead-divider" />
          <div id='day-ahead-redirects-grid'>
            <DayAheadRedirects ref={redirectGridRef} templates={redirectTemplates} addTemplate={addRedirectTemplate} deleteTemplate={deleteRedirectTemplate} timezone={timezone} />
          </div>
        </div>
      </div>
    </DivGuard>
  );
};

export default function DayAheadGridsExport() {
  return <DayAheadGrids />;
}