import { useUserGroups } from '../../data/useUserGroups';
import { userGroups } from '../../authConfig';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Chip, InputAdornment, Box, Dialog, FormControlLabel, DialogActions, MenuItem, DialogContent, TextField, Button, DialogTitle, Typography, IconButton, Grid, Tooltip, Collapse, } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array, } from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { useState, useRef, useMemo, useEffect, } from 'react';
import EmailEditor from 'react-email-editor';
import sample from '../Settings/sample.json';
import axios from 'axios';
import { apiUrlPrefix } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useActionAudit } from '../useActionAudit';
import useHeader from '../useHeader';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import FormCheckbox from '../FormControls/FormCheckbox';
import { routePaths } from '../../routePaths';
import { useAzureBlobs } from '../useAzureBlobs';
import styled from '@mui/system/styled';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useImageUploader } from '../useImageUploader';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';

const schema = object().shape({
  title: string().required('Title is required.'),
  tags: array().of(string()).min(1, 'At least one tag is required.'),
  description: string().required('Description is required.'),
  imageUrl: string().required('Image source is required.'),
  quickLinkUrl: string().required('Updated app is required.'),
});

export const ContentDialog = ({ open, closeDialog, content = {}, handleFetch, usedTags = [], }) => {
  const [editMode, setEditMode] = useState(!content.contentID);
  const dialogTitle = editMode ? (content.contentID ? 'Edit Content' : 'Add New Content') : content.title;
  const { userIsInGroup } = useUserGroups();
  const userIsAdmin = userIsInGroup(userGroups.admins);
  const [contentHtml, setContentHtml] = useState();
  const editorRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();
  const headers = useHeader();
  const { blobs: images, fetchBlobs, uploadBlob, } = useAzureBlobs('product-update-images');
  const { ImageUploader, handleImageUpload, handleUpdateFiles, clearImages } = useImageUploader();

  useEffect(() => {
    userIsAdmin && fetchBlobs();
  }, [userIsAdmin]);

  const { register, handleSubmit, formState: { errors }, watch, setValue, reset, control, } = useForm({
    resolver: yupResolver(schema),
    defaultValues: content,
  });

  const appOptions = useMemo(() => {
    return Object.keys(routePaths);
  }, []);

  useEffect(() => {
    reset({
      title: '',
      tags: [],
      description: '',
      newTag: '',
      active: false,
      imageUrl: '',
      quickLinkUrl: '',
      ...content,
    });
    setEditMode(!content.contentID);
    setContentHtml(JSON.parse(content.design ?? '{}').html);
  }, [content]);

  const tags = watch('tags');

  const handleClickAddTag = (val) => {
    if (!val?.trim()) return;
    const existingTags = tags ?? [];
    setValue('tags', [...new Set([...existingTags, val.trim()])]);
    setValue('newTag', '');
  };

  const handleKeyDownTags = (event) => {
    if (event.key === 'Enter') {
      handleClickAddTag(event.target.value);
      event.preventDefault();
    }
  };

  const handleMouseDownTag = (event) => {
    event.stopPropagation();
  }

  function onSubmit(data) {
    editorRef.current.editor.exportHtml((designerData) => {
      const { design, html } = designerData;

      handleSave({
        ...data,
        design: {
          json: design,
          html: html,
        }
      });

      closeDialog();
    });
  }

  const onEditorLoad = () => {
    if (editorRef.current) {
      const contentJson = JSON.parse(content.design ?? '{}').json ?? sample;
      editorRef.current.editor.loadDesign(contentJson);
    }
  }

  function toggleEditMode() {
    setEditMode(mode => !mode);
    editMode && editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setContentHtml(html);
    });
  }

  function handleSave(saveData) {
    if (content.contentID) {
      handleUpdate(saveData);
    } else {
      handleInsert(saveData);
    }
  }

  function handleInsert(saveData) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_insertContent&parm=${headers.userGuid}`
      + `&parm=${saveData.title}`
      + `&parm=${saveData.description}`
      + `&parm=${saveData.tags.join(',')}`
      + `&parm=productupdates`
      + `&parm=${encodeURIComponent(saveData.quickLinkUrl)}`
      + `&parm=${encodeURIComponent(saveData.imageUrl)}`

    const options = {
      method: 'POST',
      headers: headers,
      url: url,
      data: saveData.design,
    }

    axios(options).then(response => {
      enqueueSnackbar('Content saved successfully. Refreshing...', { variant: 'success' });
      handleFetch();
    }).catch(error => {
      logAction(`Error inserting product update. Message: ${error}`, 'Product Updates');
      enqueueSnackbar(`Error inserting product update. Message: ${error}`, { variant: 'error' })
    });
  }

  function handleUpdate(saveData) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_updateContent`
      + `&parm=${content.contentID}`
      + `&parm=${headers.userGuid}`
      + `&parm=${saveData.title}`
      + `&parm=${saveData.description}`
      + `&parm=${saveData.tags.join(',')}`
      + `&parm=productupdates`
      + `&parm=${encodeURIComponent(saveData.quickLinkUrl)}`
      + `&parm=${encodeURIComponent(saveData.imageUrl)}`
      + `&parm=${saveData.active ? 1 : 0}`

    const options = {
      method: 'POST',
      headers: headers,
      url: url,
      data: saveData.design,
    }

    axios(options).then(response => {
      enqueueSnackbar('Content updated successfully. Refreshing...', { variant: 'success' });
      handleFetch();
    }).catch(error => {
      logAction(`Error updating product update content. Message: ${error}`, 'Product Updates');
      enqueueSnackbar(`Error updating product update content. Message: ${error}`, { variant: 'error' })
    });
  }

  function handleAddImages(e) {
    handleUpdateFiles(e).then(images => {
      Promise.all(
        images.map(image => uploadBlob(image))
      ).then(() => {
        enqueueSnackbar('Images uploaded successfully.', { variant: 'success' });
        setValue('imageUrl', images[0]?.name);
        fetchBlobs();
        clearImages();
      });
    });
  }

  return (
    <Dialog open={open} maxWidth='xl' fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{dialogTitle}</Typography>
          <Box sx={{ flexGrow: 1, }} />
          {userIsAdmin && (
            <Button onClick={toggleEditMode}>{editMode ? 'View' : 'Edit'}</Button>
          )}
          <IconButton onClick={closeDialog} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Collapse in={editMode}>
          <form id='add-or-edit-content-dialog' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ p: 1, overflow: 'hidden', }}>
              <Grid item xs={2}>
                <TextField
                  {...register("title")}
                  autoFocus
                  label="Title"
                  fullWidth
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='quickLinkUrl'
                  control={control}
                  options={appOptions}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Updated App"
                      error={!!errors.quickLinkUrl}
                      helperText={errors.quickLinkUrl?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sx={{ alignItems: 'center', }}>
                <Tooltip title="Press the plus sign or enter to add the tag." placement='top'>
                  <FormAutocomplete
                    name='newTag'
                    control={control}
                    freeSolo
                    //autoComplete
                    //autoSelect
                    //openOnFocus
                    onKeyDown={handleKeyDownTags}
                    forcePopupIcon={false}
                    disableClearable
                    options={usedTags}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tags"
                        ref={params.InputProps.ref}
                        error={!!errors.tags}
                        helperText={errors.tags?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={e => {
                                  handleClickAddTag(params.inputProps.value);
                                }}
                                onMouseDown={handleMouseDownTag}
                                edge="end"
                              >
                                <AddIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                {tags?.map((tag, i) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => setValue('tags', tags.filter(t => t !== tag))}
                    sx={{ m: 1, }}
                  />
                ))}
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...register("description")}
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </Grid>
              <Grid item xs={2} sx={{ alignItems: 'center', display: 'flex', }}>
                <Box sx={{ flexGrow: 1, }}>
                  <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormControlLabel
                      control={
                        <FormCheckbox name={'active'} control={control} />
                      }
                      label="Active"
                    />
                    <ImageUploader onChange={handleAddImages} />
                    <Button
                      onClick={handleImageUpload}
                      variant='contained'
                    >
                      Add Image
                    </Button>
                  </Box>
                  <Tooltip title="Select an image, or type a URL to the image source." placement='left' arrow>
                    <FormAutocomplete
                      name='imageUrl'
                      control={control}
                      options={images.map(image => image.name)}
                      freeSolo
                      fullWidth
                      renderOption={(props, option) => {
                        const image = images.find(img => img.name === option);
                        return (
                          <CustomWidthTooltip placement='left' arrow title={
                            <img src={image.url} style={{ maxWidth: '500px', padding: 1, }} />
                          }>
                            <Typography {...props} width='100%' component='li'>{option}</Typography>
                          </CustomWidthTooltip>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Image Source"
                          error={!!errors.imageUrl}
                          helperText={errors.imageUrl?.message}
                        />
                      )}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <EmailEditor ref={editorRef} onLoad={onEditorLoad} />
          </form>
        </Collapse>
        <Collapse in={!editMode}>
          <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
        </Collapse>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={closeDialog}>
          Close
        </Button>
        {userIsAdmin && (
          <Button type='submit' form='add-or-edit-content-dialog'>
            Save & Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 520,
  },
});