import { useEffect, useState, useRef, } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import "react-tabs/style/react-tabs.css"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';
import useHeader from '../../useHeader';
import DivGuard from "../../Guards/DivGuard";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../../SpyMonkey/ConfirmationDialog';
import RealTimeChart from './RealTimeChart';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const [tabIndex, setTabIndex] = useState(0);
  const [tabViews, setTabViews] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const views = [
    {
      type: 'realtime',
      label: 'Real Time',
      params: {
        data: [],
      }
    },
  ]

  function renderChart(view) {
    if (view.type === 'realtime') {
      return <RealTimeChart {...view.params} />
    }
  }

  async function fetch() {
    setTabViews(views);
  }

  function handleTabSelect(event, newIndex) {
    setTabIndex(newIndex);
  }

  return (
    <DivGuard groups={[]}>
      <Box
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            bgcolor: 'background.paper',
            p: '0px',
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            maxHeight="10px"
            color="primary"
            backgroundColor="transparent"
          >
            {tabViews.map((view) => (
              <Tab
                backgroundColor="transparent"
                label={view.label}
                id={`data-analysis-tab-${view.id}`}
              />
            ))}
          </Tabs>
          {tabViews.map((view, i) => (
            <Collapse in={tabIndex === i} orientation={'vertical'}>
              {renderChart(view)}
            </Collapse>
          ))}
        </Box>
      </Box>
    </DivGuard>
  );
};