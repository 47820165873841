import { DateTimeCellEditor } from './AgGrid/DateTimeCellEditor';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useEffect, useMemo, useRef, useState, } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AgChartsReact } from 'ag-charts-react';
import { Autocomplete, Box, Button, TextField, useTheme, } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Grid from '@mui/material/Grid';
import { PriceEditor } from './PriceEditor';
import 'ag-grid-community/styles/ag-grid.css'
import '../styles/gridStyleOverrides.css'
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, date, number } from 'yup';
import FormAutocomplete from './FormControls/FormAutocomplete';
import FormTextField from './FormControls/FormTextField';
import FormDatePicker from './FormControls/FormDatePicker';
import { AgGridContainer } from './AgGrid/AgGridContainer';
import { useColorMode } from "../styles/ColorMode/useColorMode";

const hours = [...Array(24).keys()];

export default (props) => {
  const { currentLeg, pathData = [], maxInfo, disabled = false, gridData, setGridData, defaultValues = {}, chartHeight, } = props;
  const formatString = 'MM/DD/YYYY HH:mm'
  const [selectedRow, setSelectedRow] = useState(false);
  const gridRef = useRef();
  const theme = useTheme();
  const palette = theme.palette.primary;
  const { mode } = useColorMode();

  const maxProfileData = useMemo(() => maxInfo.map(row => ({
    ...row,
    maxCapacity: row.capacityRequested,
    price: defaultValues.defaultPriceData ? defaultValues.defaultPriceData : row.price,
  })), [])

  const firstStartDate = useMemo(() => {
    const info = !!maxInfo.length ? maxInfo : gridData;
    return info.reduce((first, next) => {
      const isBefore = dayjs(next.startDateTime).isBefore(dayjs(first));
      return isBefore ? next.startDateTime : first;
    }, info[0]?.startDateTime ?? dayjs());
  }, [])

  const lastEndDate = useMemo(() => {
    const info = !!maxInfo.length ? maxInfo : gridData;
    return info.reduce((last, next) => {
      const isAfter = dayjs(next.endDateTime).isAfter(dayjs(last));
      return isAfter ? next.endDateTime : last;
    }, info[0]?.endDateTime ?? dayjs());
  }, [])

  const defaults = useMemo(() => ({
    startDate: dayjs(defaultValues.startDate ?? firstStartDate),
    stopDate: dayjs(defaultValues.stopDate ?? lastEndDate),
    startHour: defaultValues.startHour ? dayjs(defaultValues.startDate).hour() : 0,
    stopHour: defaultValues.stopHour ? dayjs(defaultValues.stopDate).hour() : 0,
    price: defaultValues.defaultPriceData ? defaultValues.defaultPriceData[currentLeg + 1] : (defaultValues.defaultPrice ?? 0),
    capacity: defaultValues.defaultCapacity ?? 0,
  }), [defaultValues, currentLeg])


  const schema = object().shape({
    capacity: number().integer().typeError('Capacity must be an integer').required('Capacity is required').min(0, 'Capacity must be greater than or equal to 0'),
    price: number().typeError('Price must be a number').required('Price is required').min(0, 'Price must be greater than or equal to 0'),
    startHour: number().integer().required('Start hour is required'),
    stopHour: number().integer().required('Stop hour is required'),
    startDate: date().required('Start date is required'),
    stopDate: date().required('Stop date is required'),
  })

  const { register, handleSubmit, formState: { errors, }, reset, getValues, setValue, control, } = useForm({
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  const maxProfile = useMemo(() => formatDataForChart(maxProfileData), [maxProfileData])

  function formatDataForChart(data) {
    return data?.reduce((profile, block, idx) => {
      const endDate = new Date(block.endDateTime);
      const startDate = new Date(block.startDateTime);
      const capacity = parseInt(block.capacityRequested);
      const nextBlockData = [];
      if (idx > 0) {
        const prevBlock = data[idx - 1]; //fill in gaps in blocks with 0 capacity
        if (prevBlock.endDateTime && Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
          nextBlockData.push({
            //x: new Date(prevBlock.endDateTime + 1000),
            x: new Date(prevBlock.endDateTime),
            y: 0,
          })
          nextBlockData.push({
            //x: new Date(startDate - 1000),
            x: new Date(startDate),
            y: 0,
          })
        }
      }
      block.startDateTime && nextBlockData.push({
        x: startDate,
        y: capacity,
      })
      block.endDateTime && nextBlockData.push({
        x: endDate,
        y: capacity,
      })
      return [...profile, ...nextBlockData]
    }, [])
  }

  function buildSeries(title, data) {
    return {
      data: data,
      type: 'line',
      title: title,
      xKey: 'x',
      yKey: 'y',
    }
  }

  const defaultOptions = {
    theme: {
      baseTheme: mode === 'light' ? 'ag-material' : 'ag-material-dark',
      palette: {
        fills: [
          palette.main,
          palette.yellow,
          palette.red,
        ],
        strokes: [
          palette.main,
          palette.yellow,
          palette.red,
        ],
      },
      overrides: {
        line: { series: { strokeWidth: 3, marker: { enabled: true } } },
      },
    },
    autoSize: true,
    padding: {
      left: 40,
      right: 40,
    },
    axes: [
      {
        position: 'bottom',
        type: 'time',
        label: {
          format: '%m/%d/%y %H:%M',
          rotation: 30,
        },
      },
      {
        position: 'left',
        type: 'number',
        min: 0,
        title: {
          text: 'Capacity',
        },
      },
    ],
    legend: {
      position: 'right',
    },
  }

  const [chartOptions, setChartOptions] = useState(defaultOptions);

  function updateChart() {
    const newOptions = { ...chartOptions };

    newOptions.series = [
      buildSeries('Capacity', formatDataForChart(gridData)),
      buildSeries('Availability', maxProfile),
    ];

    setChartOptions(newOptions);
  }

  useEffect(() => {
    updateChart();
  }, [gridData])

  /*useEffect(() => {
    //hacky bug fix... the chart was not redrawing the max profile after updating the user profile,
    // so its x and y values would be out of sync with the chart axes after updating,
    //As a workaround we render the chart without the max profile, then add it back.
    if(chartOptions.series?.length < 2) {
      const newOptions = { ...chartOptions };

      newOptions.series = [
        buildSeries('Capacity', formatDataForChart(gridData)),
        buildSeries('Availability', maxProfile),
      ];

      setChartOptions(newOptions);
    }
  }, [chartOptions])*/

  const priceColParams = defaultValues.defaultPriceData ? {
    cellEditorPopup: true,
    cellEditor: 'priceEditor',
    cellEditorParams: {
      segments: pathData,
      defaultValue: defaultValues.defaultPriceData,
    },
    //valueFormatter: params => params.value ? params.value[currentLeg] : defaultPriceData[currentLeg],
    cellRenderer: params => params.value ? params.value[currentLeg + 1] : defaultValues.defaultPriceData[currentLeg + 1],
  } : {}

  const colDefs = useMemo(() => [
    {
      //field: 'startDateTime',
      checkboxSelection: true,
      editable: true,
      headerName: 'Start',
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        timeFormat: 'HH',
      },
      valueGetter: params => {
        return params.data.startDateTime;
      },
      valueSetter: params => {
        params.data.startDateTime = dayjs(params.newValue).format(formatString);
        return true;
      },
    },
    {
      //field: 'endDateTime',
      headerName: 'Stop',
      editable: true,
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        timeFormat: 'HH',
      },
      valueGetter: params => {
        return params.data.endDateTime;
      },
      valueSetter: params => {
        params.data.endDateTime = dayjs(params.newValue).format(formatString);
        return true;
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      ...priceColParams,
      editable: true,
    },
    {
      field: 'capacityRequested',
      headerName: 'Capacity',
      editable: true,
    },
    {
      field: 'maxCapacity',
      headerName: 'Max Capacity',
    }
  ], [])

  const gridOptions = {
    rowClassRules: {
      "row-missing-price": params => params.data.price === '',
    },
  };

  function onCellValueChanged(params) {
    updateChart();
  }

  function formatHour(hour) {
    return (hour < 10 ? `0${hour}:00` : `${hour}:00`);
  };

  function handleDateUpdate(key, value) {
    //make sure start date is before stop date
    const start = getValues('startDate');
    const stop = getValues('stopDate');
    if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'stopDate' && dayjs(start).isAfter(value))) {
      setValue('startDate', value);
      setValue('stopDate', value);

      //also update the hours
      const startHour = getValues('startHour');
      const stopHour = getValues('stopHour');
      if (key === 'startDate') {
        setValue('stopHour', Math.max(startHour, stopHour));
      } else if (key === 'stopDate') {
        setValue('startHour', Math.min(startHour, stopHour));
      }
    } else {
      setValue(key, value);
    }
  }

  function handleHourUpdate(key, value) {
    //make sure start hour is before stop hour
    const start = getValues('startHour');
    const stop = getValues('stopHour');
    const sameDay = dayjs(getValues('startDate')).isSame(dayjs(getValues('stopDate')), 'day');
    if (sameDay && ((key === 'startHour' && stop < value) || (key === 'stopHour' && start > value))) {
      setValue('startHour', value);
      setValue('stopHour', value);
      return;
    } else {
      setValue(key, value);
    }
  }

  function addNewBlock(data, e) {
    e.preventDefault();
    const blocks = generateTransmissionBlocksUsingTemplate(data);

    const newData = [
      ...gridData,
      ...blocks
    ].sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)))

    setGridData(newData);
  }

  function generateTransmissionBlocksUsingTemplate(config) {
    const blocks = [];
    let start = dayjs(config.startDate).hour(config.startHour).startOf('hour');
    const end = dayjs(config.stopDate).hour(config.stopHour).startOf('hour');
    switch (config.profile) {
      case 'HLH':
        return hlhBlocks(start, end, config.price, config.capacity);
      case 'LLH':
        return llhBlocks(start, end, config.price, config.capacity);
      case 'ATC':
        return [createNewBlock(start, end, config.price, config.capacity)];
      case 'Shaped':
        let next = start;
        while (end.isAfter(next, 'hour')) {
          blocks.push(createNewBlock(next, next.add(1, 'hour'), config.price, config.capacity));
          next = dayjs(next).add(1, 'hour');
        }
        return blocks;
      case '1x8':
        return offPeakBlocks(start, end, config.price, config.capacity);
      case '1x16':
        return onPeakBlocks(start, end, config.price, config.capacity);
      default: //default is ATC
        return [createNewBlock(start, end, config.price, config.capacity)];
    }
  }

  const createNewBlock = (start, end, price, capacity) => ({
    startDateTime: start.format(formatString),
    endDateTime: end.format(formatString),
    capacityRequested: capacity,
    price: defaultValues.defaultPriceData ? {
      ...defaultValues.defaultPriceData,
      [currentLeg + 1]: price,
    } : price,
  })

  function generateBlocks(start, end, price, capacity, selector) {
    const blocks = [];
    let next = dayjs(start).startOf('hour');
    while (end.isAfter(next, 'hour')) {
      if (selector(next)) {
        blocks.push(createNewBlock(next, next.add(1, 'hour'), price, capacity));
      }
      next = next.add(1, 'hour');
    }
    return rollUpBlocks(blocks);
  }

  function llhBlocks(start, end, price, capacity) {
    const selector = (date) => {
      const isOffPeak = date.hour() < 6 || date.hour() >= 22;
      const isSunday = date.day() === 0;
      return isOffPeak && !isSunday;
    }
    return generateBlocks(start, end, price, capacity, selector);
  }

  function offPeakBlocks(start, end, price, capacity) {
    const selector = (date) => date.hour() < 6 || date.hour() >= 22;
    return generateBlocks(start, end, price, capacity, selector);
  }

  function hlhBlocks(start, end, price, capacity) {
    const selector = (date) => {
      const isOnPeak = date.hour() >= 6 && date.hour() < 22;
      const isSunday = date.day() === 0;
      return (isSunday && !isOnPeak) || (!isSunday && isOnPeak);
    }
    return generateBlocks(start, end, price, capacity, selector);
  }

  function onPeakBlocks(start, end, price, capacity) {
    const selector = (date) => date.hour() >= 6 && date.hour() < 22;
    return generateBlocks(start, end, price, capacity, selector);
  }

  function rollUpBlocks(blocks) {
    return blocks.reduce((rolledUp, next) => {
      const last = rolledUp[rolledUp.length - 1];
      if (last && last.endDateTime === next.startDateTime && last.capacityRequested === next.capacityRequested) {
        last.endDateTime = next.endDateTime;
      } else {
        rolledUp.push(next);
      }
      return rolledUp;
    }, [])
  }

  function onSelectionChanged(params) {
    const rows = gridRef.current.api.getSelectedRows();
    const selected = rows.length ? rows[0] : false;
    setSelectedRow(selected);
  }

  function copyBlock() {
    reset({
      startDate: dayjs(selectedRow.startDateTime),
      stopDate: dayjs(selectedRow.endDateTime),
      startHour: dayjs(selectedRow.startDateTime).hour(),
      stopHour: dayjs(selectedRow.endDateTime).hour(),
      capacity: selectedRow.capacityRequested,
      price: defaultValues.defaultPriceData ? selectedRow.price[currentLeg + 1] : selectedRow.price,
    })
  }

  function deleteBlock() {
    const rowsToKeep = [];
    gridRef.current.api.forEachNode(node => {
      if (!node.selected) {
        rowsToKeep.push(node.data);
      }
    })
    setGridData(rowsToKeep);
    setSelectedRow(false);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
    flex: 1,
  }), []);

  return (
    <Box sx={{ p: 1, height: '100%', overflow: 'hidden', }}>
      <Box sx={{ height: chartHeight ?? '100%', }}>
        <AgChartsReact options={chartOptions} />
      </Box>
      <form onSubmit={handleSubmit(addNewBlock)} id="profileEditorNewBlockForm">
        <Grid container alignItems='center' sx={{ p: 2, }} spacing={2}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormDatePicker
                name='startDate'
                control={control}
                disabled={disabled}
                label="Start Date"
                onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.startDate}
                    helperText={errors.startDate?.message}
                  />
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormAutocomplete
              name='startHour'
              control={control}
              disabled={disabled}
              options={hours}
              getOptionLabel={(option) => formatHour(option)}
              defaultValue={0}
              onChange={(_, newValue) => {
                handleHourUpdate('startHour', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Start Hour"
                  color="success"
                  placeholder="Start Hour"
                  error={!!errors.startHour}
                  helperText={errors.startHour?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormDatePicker
                name='stopDate'
                control={control}
                label="Stop Date"
                onChange={(newValue) => handleDateUpdate('stopDate', newValue?.startOf('date'))}
                disabled={disabled}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.stopDate}
                    helperText={errors.stopDate?.message}
                  />
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormAutocomplete
              name='stopHour'
              control={control}
              disabled={disabled}
              getOptionLabel={(option) => formatHour(option)}
              fullWidth
              defaultValue={0}
              options={hours}
              onChange={(_, newValue) => {
                handleHourUpdate('stopHour', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Stop Hour"
                  color="success"
                  placeholder="Stop Hour"
                  error={!!errors.stopHour}
                  helperText={errors.stopHour?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleSubmit(addNewBlock)}
              disabled={disabled}
            >Add Block</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={deleteBlock}
              disabled={!selectedRow || disabled}
            >Delete Selected</Button>
          </Grid>
          <Grid item xs={2}>
            <TextField
              {...register('capacity')}
              variant="outlined"
              label="Capacity"
              color="success"
              fullWidth
              disabled={disabled}
              error={!!errors.capacity}
              helperText={errors.capacity?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              {...register('price')}
              variant="outlined"
              fullWidth
              label="Price"
              color="success"
              disabled={disabled}
              error={!!errors.price}
              helperText={errors.price?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <FormAutocomplete
              name='profile'
              control={control}
              disabled={disabled}
              options={['HLH', 'LLH', 'ATC', '1x8', '1x16', 'Shaped']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Profile"
                  color="success"
                  error={!!errors.profile}
                  helperText={errors.profile?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={copyBlock}
              disabled={!selectedRow || disabled}
            >Copy Selected</Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => setGridData([])}
              disabled={disabled}
            >Clear All</Button>
          </Grid>
        </Grid>
      </form>
      <AgGridContainer style={{ width: "100%", flexGrow: '1', p: 2, }} >
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={colDefs}
          domLayout='autoHeight'
          onCellValueChanged={onCellValueChanged}
          overlayNoRowsTemplate={'<span>Add blocks to view and edit profile information here.</span>'}
          enableRangeSelection
          enableFillHandle
          enterMovesDown
          rowSelection='multiple'
          enterMovesDownAfterEdit
          stopEditingWhenCellsLoseFocus
          onSelectionChanged={onSelectionChanged}
          //singleClickEdit 
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          components={{
            priceEditor: PriceEditor,
            dateEditor: DateTimeCellEditor,
          }}
        />
      </AgGridContainer>
    </Box>
  )
}