import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React, { useState } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/conditionalGridStyles.css'

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AgGridContainer } from "../AgGrid/AgGridContainer";

const truncate = (str) => {
  return str.length > 25 ? str.substring(0, 7) + "..." : str;
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const serviceChoices = {
  values: [
    'Custom',
  ],
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function ServiceToolPanel(props) {

  const [service, setService] = useState(props.service);

  const handleChange = (event) => {
    setService(event.target.value);
  };

  //const service = props.service;
  const headers = service ? Object.keys(service) : [];
  //alert(headers);


  const [rowData] = useState([
    { field: 'Service', value: "", },
    { field: 'Service_Increment', value: "", },
    { field: 'TS_Class', value: "", },
    { field: 'TS_Type', value: "", },
    { field: 'TS_Period', value: "", },
    { field: 'TS_Window', value: "", },
    { field: 'TS_Subclass', value: "", }

  ]);

  const [columnDefs] = useState([
    { field: 'field', width: 170, editable: false, },
    { field: 'value', width: 150, editable: true, }, //cellEditor:"agRichSelectCellEditor", cellEditorParams:{serviceChoices} 
  ])

  return (
    headers.length > 0 ?
      <AgGridContainer style={{ height: '100%', width: '350px' }}>
        <Box sx={{ p: 2, }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Service</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              value={service}
              label="Service"
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          singleClickEdit
          rowData={rowData}
          columnDefs={columnDefs}>
        </AgGridReact>
      </AgGridContainer>
      // ? <TableContainer component={Paper}>
      //     <Table sx={{ width:400 }} size="small" aria-label="customized table">
      //       <colgroup>
      //         <col style={{width:'60%'}}/>
      //         <col style={{width:'20%'}}/>
      //         <col style={{width:'20%'}}/>
      //       </colgroup>
      //       <TableHead>
      //         <StyledTableRow>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">Info</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} align="center" component="th" scope="row">Volume</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} align="right">Price</StyledTableCell>
      //         </StyledTableRow>
      //       </TableHead>
      //       <TableBody>
      //       {/* {headers.map((header) =>
      //         <StyledTableRow>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">{header}</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} colspan="2" align="right">{profile[header]}</StyledTableCell>
      //         </StyledTableRow>
      //       )} */}
      //         <StyledTableRow>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">HE1</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} editable="true" align="center">{profile["HEQ1"]}</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} align="right">{profile["HEP1"]}</StyledTableCell>
      //         </StyledTableRow>
      //         <StyledTableRow>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">HE2</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} editable="true" align="center">{profile["HEQ2"]}</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} align="right">{profile["HEP2"]}</StyledTableCell>
      //         </StyledTableRow>
      //         <StyledTableRow>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">HE3</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} editable="true" align="center">{profile["HEQ3"]}</StyledTableCell>
      //           <StyledTableCell sx={{ fontWeight: 'light' }} align="right">{profile["HEP3"]}</StyledTableCell>
      //         </StyledTableRow>
      //     </TableBody>
      //   </Table>
      // </TableContainer>
      : <Grid sx={{ p: 2 }} container>
        <Grid item>
          <Typography>
            Select a cell to view its row details.
          </Typography>
        </Grid>
      </Grid>
  );
};