import { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useActionAudit } from './useActionAudit';
import { apiUrlPrefix } from "../authConfig";
import useHeader from './useHeader';

export const useRemoteLayout = (gridRef, id, columnDefs, updateColDefs, autoSaveLocal = true) => {
  const localStorageKey = `configurable-grid-${id}-layout`;
  const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_BidmanLayoutDelete&parm=${id}`;
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_BidmanLayoutUpdate&parm=${id}`;
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Fetch?name=PowerStationMetaData.UI_layoutJSONFetch&parm=${id}`;

  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();
  const headers = useHeader();

  //save layout when window unloads
  useEffect(() => {
    autoSaveLocal && window.addEventListener('beforeunload', saveLayoutLocal)
    return () => {
      autoSaveLocal && window.removeEventListener('beforeunload', saveLayoutLocal)
    }
  }, []);

  function saveLayoutLocal() {
    if (localStorageKey) {
      const newSave = captureLayout();
      const val = JSON.stringify(newSave);
      localStorage.setItem(localStorageKey, val);
      enqueueSnackbar('Layout saved.');
      logAction('Layout saved', localStorageKey, JSON.stringify(newSave));
    }
    else {
      enqueueSnackbar('Unable to save: no save location provided.');
    }
  };

  function loadLayoutLocal(api = gridRef.current?.api) {
    try {
      const storedLayout = localStorage.getItem(localStorageKey);
      const val = JSON.parse(storedLayout ?? '{}');
      if (!val.colDefs?.length) { //if col defs are missing or empty, the saved layout is messed up so load from the db
        loadFromDatabase(loadUri, api);
      } else {
        applyLayout(val, api);
      }
    } catch (err) {
      enqueueSnackbar(`Error loading layout from ${localStorageKey ?? 'local storage'}. Message: ${err}`);
      logAction(`Error loading layout from ${localStorageKey ?? 'local storage'}. Message: ${err}`, localStorageKey);
    }
  };

  const deleteLayoutLocal = () => {
    clearLayout();
    localStorage.removeItem(localStorageKey);
    enqueueSnackbar('Local layout deleted.');
  };

  function newLayout(defs, state, filters, groupCol) {
    return ({
      colDefs: defs,
      colState: state,
      filters: filters,
      groupCol: groupCol,
    });
  }

  function applyLayout(layout, api = gridRef.current?.api) {
    if (layout) {
      let defs = layout.colDefs?.length ? layout.colDefs : columnDefs//? merge(columnDefs, currentLayout.colDefs) : columnDefs;
      defs = updateColDefs ? updateColDefs(defs) : defs;
      api.setColumnDefs(defs);

      api.setAutoGroupColumnDef(autoGroupColumnDef(layout?.groupCol))

      api.setFilterModel(layout.filters);
      api.onFilterChanged();
      layout.colState && gridRef.current?.columnApi && gridRef.current.columnApi.applyColumnState({ state: layout.colState });
    }
  };

  function captureLayout() {
    const defs = gridRef.current.api.getColumnDefs();
    defs.forEach(def => { //explicitly set column visibility; default is undefined, which causes layout bug when used in conjunction with initialHide property
      if (!def.hide) { def.hide = false; }
    });
    const filters = gridRef.current.api.getFilterModel();
    const groupCol = saveAutoGroupCol();

    const colState = gridRef.current.columnApi.getColumnState();

    return newLayout(defs, colState, filters, groupCol);
  };

  //we have to handle the auto group column separately as it is not part of the col defs
  //save any info we need about it here
  function saveAutoGroupCol() {
    const groupCol = gridRef.current.columnApi.getColumn('ag-Grid-AutoColumn');
    return (groupCol ? {
      pinned: groupCol.pinned,
    } : {});
  }

  const autoGroupColumnDef = (groupCol) => {
    return {
      editable: false,
      headerName: 'Group',
      cellRendererParams: {
        suppressCount: true,
      },
      ...groupCol
    };
  }

  function deleteFromDatabase(url) {
    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    };

    axios(options).then(() => {
      enqueueSnackbar('Grid layout deleted from database.');
    }).catch(function (error) {
      enqueueSnackbar(`Status: ${error.response?.status}. Error deleting layout from database. ${localStorageKey} ${error}`)
    });
  }

  function saveToDatabase(url) {
    const newSave = captureLayout();
    localStorage.setItem(localStorageKey, JSON.stringify(newSave));

    const options = {
      method: 'POST',
      headers: headers,
      data: newSave,
      url: url,
    };

    axios(options).then(() => {
      enqueueSnackbar('Layout saved to database.')
    }).catch(function (error) {
      enqueueSnackbar(`Status: ${error.response?.status}. Error save layout to database ${localStorageKey} ${error}`)
    });
  };

  function loadFromDatabase(url = loadUri, api = gridRef.current?.api) {
    const handleResponse = (response) => {
      const stringLayout = response.data[0]?.layoutJson;
      if (stringLayout) {
        const lyt = JSON.parse(stringLayout);
        autoSaveLocal && localStorage.setItem(localStorageKey, stringLayout);
        applyLayout(lyt, api);
      } else { applyLayout({}, api); }
    }

    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    };

    axios(options).then((response) => handleResponse(response)).catch(function (error) {
      enqueueSnackbar(`Status: ${error.response?.status}. Error loading layout from database ${localStorageKey} ${error}`)
    });
  };

  function loadLayout(api = gridRef.current?.api) {
    const hasLayout = localStorage.getItem(localStorageKey);
    if (!hasLayout) {
      loadFromDatabase(loadUri, api);
    }
    else {
      loadLayoutLocal(api);
    }
  }

  function clearLayout() {
    //setCurrentLayout({});
    loadFromDatabase(loadUri);
    enqueueSnackbar('Layout cleared.');
  }

  const dbLayoutPanel = {
    id: 'layout',
    labelDefault: 'Layout',
    labelKey: 'layout',
    toolPanel: 'dbLayoutToolPanel',
    toolPanelParams: {
      onLayoutClear: clearLayout,
      onLayoutLoad: loadLayout,
      onLayoutSave: saveLayoutLocal,
      onLayoutDelete: deleteLayoutLocal,
      onLayoutDatabaseDelete: () => deleteFromDatabase(deleteUri),
      onLayoutDatabaseSave: () => saveToDatabase(saveUri),
      onLayoutDatabaseRestore: () => loadFromDatabase(loadUri),
    },
    minWidth: 180,
    maxWidth: 400,
    width: 200
  };

  return {
    dbLayoutPanel,
    loadLayout,
    saveLayoutLocal,
    loadFromDatabase,
  };
}