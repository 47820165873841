export const renderStandardTimezones = {
  'Pacific': 'Pacific Standard Time',
  'Pacific Standard Time': 'Pacific',
  'Eastern': 'Eastern Standard Time',
  'Eastern Standard Time': 'Eastern',
  'Central': 'Central Standard Time',
  'Central Standard Time': 'Central',
  'Mountain': 'Mountain Standard Time',
  'Mountain Standard Time': 'Mountain',
}

export const renderUSTimezones = {
  'US/Pacific': 'Pacific Standard Time',
  'Pacific Standard Time': 'US/Pacific',
  'US/Eastern': 'Eastern Standard Time',
  'Eastern Standard Time': 'US/Eastern',
  'US/Central': 'Central Standard Time',
  'Central Standard Time': 'US/Central',
  'US/Mountain': 'Mountain Standard Time',
  'Mountain Standard Time': 'US/Mountain',
}

export const renderStandardDayjsTimezones = {
  'America/Los_Angeles': 'Pacific Standard Time',
  'Pacific Standard Time': 'America/Los_Angeles',
  'America/Detroit': 'Eastern Standard Time',
  'Eastern Standard Time': 'America/Detroit',
  'America/Chicago': 'Central Standard Time',
  'Central Standard Time': 'America/Chicago',
  'America/Denver': 'Mountain Standard Time',
  'Mountain Standard Time': 'America/Denver',
}

export const renderDayjsTimezones = {
  'America/Los_Angeles': 'Pacific',
  'Pacific': 'America/Los_Angeles',
  'America/Detroit': 'Eastern',
  'Eastern': 'America/Detroit',
  'America/Chicago': 'Central',
  'Central': 'America/Chicago',
  'America/Denver': 'Mountain',
  'Mountain': 'America/Denver',
}