import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { SoundsContext } from './SoundsContext';
import { useApi } from '../useApi';
import useHubObject from "../HubContext/useHubObject"
import { useUserInfo } from "../UserInfoContext"

export function SoundsProvider({ children }) {
    const audioContextRef = useRef(null);
    const audioBuffersRef = useRef({});
    const userInfo = useUserInfo();

    const soundFiles = useMemo(() => {
        const soundContext = require.context('../../assets/sounds', false, /\.mp3$/);
        const soundFiles = soundContext.keys().reduce((files, file) => {
            const key = file.replace('./', '');
            files[key] = soundContext(file);
            return files;
        }, {});
        return soundFiles;
    }, []);

    const loadAllSounds = useCallback(async () => {
        // Create an AudioContext if we don't have one already
        if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        }


        // Load and decode all sound files
        const entries = Object.entries(soundFiles);
        const decodedSounds = await Promise.all(
            entries.map(async ([key, url]) => {
                const response = await fetch(url);
                const arrayBuffer = await response.arrayBuffer();
                const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
                return [key, audioBuffer];
            })
        );

        // Store all decoded buffers in a dictionary
        audioBuffersRef.current = decodedSounds.reduce((acc, [key, buffer]) => {
            acc[key] = buffer;
            return acc;
        }, {});
    }, [soundFiles]);

    useEffect(() => {
        if (!soundFiles || typeof soundFiles !== 'object') return;

        loadAllSounds();
    }, [loadAllSounds, soundFiles]);

    const playSound = useCallback((key) => {
        if (!audioContextRef.current) return;
        const buffer = audioBuffersRef.current[key];
        if (buffer) {
            const source = audioContextRef.current.createBufferSource();
            source.buffer = buffer;
            source.connect(audioContextRef.current.destination);
            source.start(0);
        } else {
            console.warn(`No sound loaded for key: ${key}`);
        }
    }, []);

    //USER AUDIO SETTINGS
    const { get, post, headers, apiUrlPrefix, enqueueSnackbar } = useApi();
    const [allAlerts, setAllAlerts] = useState([]);
    const [userAlerts, setUserAlerts] = useState([]);

    const fetchAudioAlerts = useCallback(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchAudioAlert&parm=${headers.userGuid}`;
        return get(url).then((response) => {
            if (response.status === 200) {
                setAllAlerts(response.data);
            }
            return response;
        });
    }, [get, apiUrlPrefix, headers.userGuid]);

    const fetchUserAudioAlerts = useCallback(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchUserAudioAlert&parm=${headers.userGuid}`;
        return get(url).then((response) => {
            if (response.status === 200) {
                setUserAlerts(response.data);
            }
            return response;
        });
    }, [get, apiUrlPrefix, headers.userGuid]);

    const updateUserAudioAlerts = useCallback(async (userAlertData) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_updateUserAudioAlert&parm=${headers.userGuid}`;
        return post(url, userAlertData);
    }, [post, apiUrlPrefix, headers.userGuid]);

    useEffect(() => {
        fetchAudioAlerts();
        fetchUserAudioAlerts();
    }, [fetchAudioAlerts, fetchUserAudioAlerts]);

    const handleToggleActive = useCallback((audioAlertID) => {
        const oldUserAlerts = [...userAlerts];
        const currentlyActive = userAlerts.find(alert => audioAlertID === alert.audioAlertID);
        const newUserAlerts = currentlyActive
            ? userAlerts.filter((alert) => alert.audioAlertID !== audioAlertID)
            : [...userAlerts, allAlerts.find((alert) => alert.audioAlertID === audioAlertID)];

        //Optimistically update the user alerts
        setUserAlerts(newUserAlerts);

        const userAlertData = allAlerts.filter((alert) => newUserAlerts.some((userAlert) => userAlert.audioAlertID === alert.audioAlertID));
        updateUserAudioAlerts(userAlertData).then(response => {
            if (response.status !== 200) {
                //If the update failed, roll back the change
                setUserAlerts(oldUserAlerts);
                enqueueSnackbar('Failed to update alert settings.', { variant: 'error' });
            } else {
                enqueueSnackbar('Alert settings updated.', { variant: 'success' });
            }
        });
    }, [userAlerts, allAlerts, updateUserAudioAlerts, enqueueSnackbar]);

    const handleUpdateAlertSound = useCallback((audioAlertID, soundFile) => {
        const oldUserAlerts = [...userAlerts];
        const newUserAlerts = userAlerts.map((alert) => {
            if (alert.audioAlertID === audioAlertID) {
                return { ...alert, soundFile };
            }
            return alert;
        });

        //Optimistically update the user alerts
        setUserAlerts(newUserAlerts);

        updateUserAudioAlerts(newUserAlerts).then(response => {
            if (response.status !== 200) {
                //If the update failed, roll back the change
                setUserAlerts(oldUserAlerts);
                enqueueSnackbar('Failed to update alert settings.', { variant: 'error' });
            } else {
                enqueueSnackbar('Alert settings updated.', { variant: 'success' });
            }
        });
    }, [userAlerts, updateUserAudioAlerts, enqueueSnackbar]);


    const handleAudioAlert = useCallback(async ({ audioAlertID, snackBarMessage }) => {
        const alert = userAlerts.find((alert) => String(alert.audioAlertID) === String(audioAlertID));
        if (alert) {
            playSound(alert.soundFile);
            enqueueSnackbar(snackBarMessage, { variant: 'info' });
        }
        //return resolved promise to prevent error as the hub expects a promise
        return Promise.resolve();
    }, [userAlerts, playSound, enqueueSnackbar]);

    useHubObject({
        action: handleAudioAlert,
        allowedMessages: ['playSound'],
        callbackDependencies: [userAlerts, playSound, enqueueSnackbar],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo?.tenantId?.toString()
                //if there is a userGuid, only update if the userGuid matches	
                && (!obj.userGuid || obj.userGuid === headers.userGuid)
            );
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const data = useMemo(() => ({
        playSound,
        audioContextRef,
        audioBuffersRef,
        allAlerts,
        userAlerts,
        handleToggleActive,
        handleUpdateAlertSound,
        soundFiles,
    }), [soundFiles, playSound, audioContextRef, audioBuffersRef, allAlerts, userAlerts, handleToggleActive, handleUpdateAlertSound]);

    return (
        <SoundsContext.Provider value={data}>
            {children}
        </SoundsContext.Provider>
    );
}

