import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { HubContext } from './hubContext';
import { hubUrlPrefix } from '../../authConfig'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import _ from 'lodash';
import moment from 'moment';


export function HubProvider({ children }) {
    const { enqueueSnackbar } = useSnackbar();
    const [connection, setConnection] = useState();

    useEffect(() => {
        try {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${hubUrlPrefix}/hubs/chat/`)
                .configureLogging(LogLevel.Debug)
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
            console.log(`Connection Object Created`);
        } catch (error) {
            enqueueSnackbar(`Hub connection error: ${error.message}`);
        }
    }, []);

    useEffect(() => {
        if (connection) {
            try {
                connection.start().then(result => {
                    console.log('Receive data Connected!');
                    connection.on('ReceiveMessage', handleMessageUpdate)
                }).catch(e => enqueueSnackbar(`Hub connection failed. Detailed error: ${e}`));
            } catch (error) {
                enqueueSnackbar(`Hub connection error: ${error.message}`);
            }
        }
    }, [connection]);

    const diffData = useCallback((newData, oldData, ID = 'ID') => {
        const diffStart = moment();

        const newIdHash = newData.reduce((hash, next) => {
            hash[next[ID]] = next;
            return hash;
        }, {})

        const oldIdHash = oldData.reduce((hash, next) => {
            hash[next[ID]] = next;
            return hash;
        }, {})

        const toAdd = newData.reduce((adding, next) => {
            if (!oldIdHash[next[ID]]) { //if the old data doesn't have this ID, it's a new row
                adding.push(next);
            }
            return adding;
        }, [])

        const toUpdate = newData.reduce((updating, next) => {
            if (oldIdHash[next[ID]] && !_.isEqual(newIdHash[next[ID]], oldIdHash[next[ID]])) { //if the old data also has this ID and the data changed, update it
                updating.push(next);
            }
            return updating;
        }, [])

        const toDelete = oldData.reduce((deleting, next) => {
            if (!newIdHash[next[ID]]) { //if the new data doesn't have this ID, delete the row
                deleting.push(next);
            }
            return deleting;
        }, [])

        return {
            toAdd,
            toUpdate,
            toDelete,
            diffStart,
        }
    }, [])

    const [data, setData] = useState({
        diffData: diffData,
    });

    const handleMessageUpdate = useCallback((newMessage) => {
        if (!acceptedMessageList.includes(newMessage) && !acceptedMessageList.includes(newMessage.hubMessage)) { return; }

        setData({
            ...data,
            message: newMessage,
        })
    }, []);

    return (
        <HubContext.Provider value={data}>
            {children}
        </HubContext.Provider>
    )
}

const acceptedMessageList = [
    'trg_tsrDispatchQueueUpdate',
    'PowerStationMetaData.WJ_TSR_DispatchQueueUpdate',
    'PowerStationMetaData.userMessagesQueueUpdate',
    'PowerStationMetaData.UI_SupportRequestsSave',
    'PowerStationMetaData.tsr_v2_insertUpdate',
    'PorPodPointsUpdate',
    'trg_sysDataAlertTrigger',
    'tsrGridUpdateTrigger',
    'trg_TransQueueUpdateTrigger',
    //'neuronHistoryInsertUpdate',
    'brainInsertUpdateDelete',
    'BrainUpdate',
    'NeuronHistoryUpdate',
    'trg_lookupValuesInsertUpdate',
    'dealUpdate',
    'scheduleUpdate',
    'tagUpdate',
    'playSound',
    'tagTxUpdate',
    'lookupValuesChanged',
]
