import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { forwardRef, useRef, useMemo, useCallback, } from "react"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { heatmapStyle, handleUpdateTotalRows, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { useFormContext } from "react-hook-form"
import { useDashboardLayout } from "../useDashboardLayout"
import { useGridCrossHighlight } from "../useGridCrossHighlight"

export const UnscheduledMWsGrid = forwardRef(({ setSelectedRow, getRowId }, ref) => {
    const { getValues } = useFormContext();
    const id = getValues('id');
    const layout = getValues('layout');
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const layoutStorageKey = `deal-rizz-unscheduled-mws-grid-layout-${id}`;
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
        },
        {
            field: 'Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Name',
        },
        {
            field: 'Status',
            headerName: 'Deal Status',
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED', 'DRAFT']
            },
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            field: 'Counterparty',
        },
        {
            field: 'Contract',
        },
        {
            headerName: 'Firm/Non-Firm',
            field: 'FirmNonFirm',
        },
        {
            field: 'Index',
        },
        {
            field: 'Creator',
        },
        {
            field: 'Market',
        },
        {
            field: 'Zone',
            headerName: 'Zone',
        },
        {
            field: 'Hidden',
            headerName: 'Hidden',
        },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
        },
        {
            field: 'Term',
        },
        {
            headerName: "Full Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: "Full Price Curve",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.dealProfileJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            minWidth: 200,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                const date = getValues('startDate');
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: "2*",
            field: "2*",
            flex: 1,
            enableRowGroup: false,
            enableValue: true,    // Enable value aggregation for these columns
            aggFunc: 'sum',       // Set 'sum' as the default aggregation function
            allowedAggFuncs: ['sum', 'min', 'max', 'avg', 'count', 'first', 'last', 'myNetPosition'],
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            // cellStyle: params => heatmapStyle(params, paletteRef),
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            flex: 1,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
        {
            field: 'Type_F_P',
            headerName: 'Transaction',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'deal-rizz-unscheduled-mws-palette'),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);


    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, colDefs[0]?.field);
    }, [colDefs]);

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ height: '100%', width: '100%' }}
                ref={ref}
                getRowId={getRowId}
                gridOptions={gridOptions}
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={updateTotalRows}
                onSelectionChanged={handleSelectionChanged}
                onCellMouseOver={handleCellMouseOverHighlight}
                rowSelection='single'
                rowMultiSelectWithClick
                overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
                enableCharts
                columnDefs={styledColDefs}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                suppressAggFuncInHeader
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    );
});
