import { RibbonGroupContainer } from "../../Ribbon";
import { ConfirmScheduleButton, VoidScheduleButton, ScheduleHistoryButton } from "../../RibbonButtons"

export const ScheduleActionsGroup = ({ expanded, selectedSchedule, gridRef, }) => {
    const toConfirm = !selectedSchedule || selectedSchedule.Status !== 'CONFIRMED';
    const toVoid = !selectedSchedule || selectedSchedule?.Status !== 'VOIDED';

    return (
        <RibbonGroupContainer>

            <ConfirmScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                gridRef={gridRef}
                toConfirm={toConfirm}
            />

            <VoidScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                gridRef={gridRef}
                toVoid={toVoid}
            />

            <ScheduleHistoryButton
                expanded={expanded}
                schedule={selectedSchedule}
            />
        </RibbonGroupContainer>
    );
}
