export default {
    'Pacific Standard Time': 'America/Los_Angeles',
    'Mountain Standard Time': 'America/Denver',
    'Central Standard Time': 'America/Chicago',
    'Eastern Standard Time': 'America/Detroit',
    'Pacific': 'America/Los_Angeles',
    'Mountain': 'America/Denver',
    'Central': 'America/Chicago',
    'Eastern': 'America/Detroit',
    'US/Pacific': 'America/Los_Angeles',
    'US/Mountain': 'America/Denver',
    'US/Central': 'America/Chicago',
    'US/Eastern': 'America/Detroit',
}