import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";
import 'ag-grid-community/styles/ag-grid.css'
import '../styles/realTimeStyles.css'
import { useSnackbar } from 'notistack';
import axios from "axios";
import useHeader from './useHeader';

export const AutocompleteEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState([]);
  const inRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const header = useHeader();

  useEffect(() => {
    let abortController = new AbortController();
    fetchSelectChoices();
    return () => {
      abortController.abort();
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
      afterGuiAttached: () => {
        inRef.current.focus();
      }
    };
  });

  async function fetchSelectChoices() {
    const options = {
      method: 'GET',
      headers: header,
      url: props.url,
    }

    axios(options).then(response => {
      setOptions(response.data.map(choice => choice[props.field]));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });

  };

  return (
    <Autocomplete
      value={value}
      onInputChange={(event, newValue) => {
        setValue(newValue);
      }}
      sx={{ fontSize: 6 }}
      openOnFocus
      //autoComplete={true} does nothing
      //autoSelect={true} breaks the popup
      autoHighlight
      size="small"
      //fullWidth
      //selectOnFocus
      //clearOnBlur
      //handleHomeEndKeys
      options={options}
      freeSolo={true}
      renderInput={(params) => <TextField
        inputRef={inRef}
        variant='standard'
        fullWidth
        //sx={{width:100}}
        size="small"
        {...params}
      />}
    />
  );
});