import { DateTimeCellEditor } from './AgGrid/DateTimeCellEditor';
import { LocalizationProvider, } from "@mui/x-date-pickers";
import { useEffect, useMemo, useRef, useState, } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AgChartsReact } from 'ag-charts-react';
import { Box, Button, ButtonGroup, TextField, Tooltip, useTheme, } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Grid from '@mui/material/Grid';
import 'ag-grid-community/styles/ag-grid.css'
import '../styles/gridStyleOverrides.css'
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, date, number } from 'yup';
import { AgGridContainer } from './AgGrid/AgGridContainer';
import FormDateTimePicker from './FormControls/FormDateTimePicker';
import { useProfileTemplates } from './DealRizz/DealEntry/ProfileEditor/useProfileTemplates';

export default (props) => {
    const { maxInfo = [], disabled = false, gridData, setGridData, defaultValues = {}, chartHeight, } = props;
    const formatString = 'MM/DD/YYYY HH:mm'

    const gridRef = useRef();
    const theme = useTheme();
    const palette = theme.palette.primary;

    const maxProfileData = useMemo(() => maxInfo.map(row => ({
        ...row,
        maxCapacity: row.capacityRequested,
    })), [])

    const firstStartDate = useMemo(() => {
        const info = !!maxInfo.length ? maxInfo : gridData;
        return info.reduce((first, next) => {
            const isBefore = dayjs(next.startDateTime).isBefore(dayjs(first));
            return isBefore ? next.startDateTime : first;
        }, info[0]?.startDateTime ?? dayjs());
    }, [])

    const lastEndDate = useMemo(() => {
        const info = !!maxInfo.length ? maxInfo : gridData;
        return info.reduce((last, next) => {
            const isAfter = dayjs(next.endDateTime).isAfter(dayjs(last));
            return isAfter ? next.endDateTime : last;
        }, info[0]?.endDateTime ?? dayjs());
    }, [])

    const defaults = {
        ...defaultValues,
        startDateTime: defaultValues.startDate ? dayjs(defaultValues.startDate) : firstStartDate,
        stopDateTime: defaultValues.stopDate ? dayjs(defaultValues.stopDate) : lastEndDate,
        capacity: defaultValues.defaultCapacity ?? 0,
    }

    const { generateTransmissionBlocksUsingTemplate } = useProfileTemplates(defaults, formatString);

    const schema = object().shape({ //TODO: min and max dates not working
        capacity: number().integer().typeError('Capacity must be an integer').required('Capacity is required').min(0, 'Capacity must be greater than or equal to 0'),
        //startDateTime: date().required('Start date is required').min(defaults.startDateTime?.format(formatString), 'Start date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Start date is outside acceptble range.'),
        //stopDateTime: date().required('Stop date is required').min(defaults.startDateTime.format(formatString), 'Stop date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Stop date is outside acceptble range.'),
    })

    const { register, handleSubmit, formState: { errors, }, getValues, setValue, control, watch, } = useForm({
        defaultValues: defaults,
        resolver: yupResolver(schema),
    });

    const selectedProfile = watch('profile');

    const maxProfile = useMemo(() => formatDataForChart(maxProfileData), [maxProfileData])

    function formatDataForChart(data) {
        return data?.reduce((profile, block, idx) => {
            const endDate = new Date(block.endDateTime);
            const startDate = new Date(block.startDateTime);
            const capacity = parseInt(block.capacityRequested);
            const nextBlockData = [];
            if (idx > 0) {
                const prevBlock = data[idx - 1]; //fill in gaps in blocks with 0 capacity
                if (prevBlock.endDateTime && Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
                    nextBlockData.push({
                        //x: new Date(prevBlock.endDateTime + 1000),
                        x: new Date(prevBlock.endDateTime),
                        y: 0,
                    })
                    nextBlockData.push({
                        //x: new Date(startDate - 1000),
                        x: new Date(startDate),
                        y: 0,
                    })
                }
            }
            block.startDateTime && nextBlockData.push({
                x: startDate,
                y: capacity,
            })
            block.endDateTime && nextBlockData.push({
                x: endDate,
                y: capacity,
            })
            return [...profile, ...nextBlockData]
        }, [])
    }

    function buildSeries(title, data) {
        return {
            data: data,
            type: 'line',
            title: title,
            xKey: 'x',
            yKey: 'y',
        }
    }

    const defaultOptions = {
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                ],
                strokes: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                ],
            },
            overrides: {
                line: { series: { strokeWidth: 3, marker: { enabled: true } } },
            },
        },
        autoSize: true,
        padding: {
            left: 40,
            right: 40,
        },
        axes: [
            {
                position: 'bottom',
                type: 'time',
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
            },
            {
                position: 'left',
                type: 'number',
                min: 0,
                title: {
                    text: 'Capacity',
                },
            },
        ],
        legend: {
            position: 'right',
        },
    }

    const [chartOptions, setChartOptions] = useState(defaultOptions);

    function updateChart() {
        const newOptions = { ...chartOptions };

        newOptions.series = [
            buildSeries('Availability', maxProfile),
            buildSeries('Capacity', formatDataForChart(gridData)),
        ];

        setChartOptions(newOptions);
    }

    useEffect(() => {
        updateChart();
    }, [gridData])

    const colDefs = useMemo(() => [
        {
            checkboxSelection: true,
            editable: true,
            headerName: 'Start',
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.startDateTime;
            },
            valueSetter: params => {
                params.data.startDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            headerName: 'Stop',
            editable: true,
            cellEditorPopup: true,
            cellEditor: 'dateEditor',
            cellEditorParams: {
                timeFormat: 'HH',
            },
            valueGetter: params => {
                return params.data.endDateTime;
            },
            valueSetter: params => {
                params.data.endDateTime = dayjs(params.newValue).format(formatString);
                return true;
            },
        },
        {
            field: 'price',
            headerName: 'Price',
            //...priceColParams,
            editable: true,
        },
        {
            field: 'capacityRequested',
            headerName: 'Capacity',
            editable: true,
        },
        {
            field: 'maxCapacity',
            headerName: 'Max Capacity',
        }
    ], [])

    const gridOptions = {
        rowClassRules: {
            "row-missing-price": params => params.data.price === '',
        },
    };

    function onCellValueChanged(params) {
        updateChart();
    }

    function handleDateUpdate(key, value) {
        //make sure start date is before stop date
        const start = getValues('startDateTime');
        const stop = getValues('stopDateTime');
        if ((key === 'startDateTime' && dayjs(stop).isBefore(value)) || (key === 'stopDateTime' && dayjs(start).isAfter(value))) {
            setValue('startDateTime', value);
            setValue('stopDateTime', value);
        } else {
            setValue(key, value);
        }
    }

    function onSubmit(data, e) {
        e.preventDefault();

        const unsortedData = generateTransmissionBlocksUsingTemplate(data);
        const newData = unsortedData.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)))
        setGridData(newData);
    }

    const defaultColDef = useMemo(() => ({
        editable: false,
        resizable: true,
        flex: 1,
    }), []);

    const profileOptions = [
        {
            name: 'HLH',
            tooltip: 'Adds blocks for on peak hours, except on Sundays.',
        },
        {
            name: 'LLH',
            tooltip: 'Adds blocks for off peak hours, except on Sundays.',
        },
        {
            name: 'ATC',
            tooltip: 'Adds blocks covering all hours in the selected date range.',
        },
        {
            name: '1x8',
            tooltip: 'Adds blocks for off peak hours in the selected date range.',
        },
        {
            name: '1x16',
            tooltip: 'Adds blocks for on peak hours in the selected date range.',
        },
        {
            name: 'Shaped',
            tooltip: 'Adds a block for each hour in the selected date range.',
        },
    ];

    return (
        <Box sx={{ p: 1, height: '100%', }}>
            <Box sx={{ height: chartHeight ?? '100%', }}>
                <AgChartsReact options={chartOptions} />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)} id="profileEditorConfigForm">
                <Grid container alignItems='center' sx={{ p: 2, }} spacing={2}>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormDateTimePicker
                                name='startDateTime'
                                control={control}
                                disabled={disabled}
                                label="Start Date"
                                onAccept={(newValue) => handleDateUpdate('startDateTime', newValue?.startOf('hour'))}
                                views={['year', 'month', 'day', 'hours']}
                                format="MM/DD/YYYY HH:mm"
                                maxDateTime={defaults.maxDateTime}
                                minDateTime={defaults.minDateTime}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: !!errors.startDateTime,
                                        helperText: errors.startDateTime?.message,
                                        onBlur: (e) => {
                                            handleDateUpdate('startDateTime', dayjs(e.target.value).startOf('hour'));
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormDateTimePicker
                                name='stopDateTime'
                                control={control}
                                label="Stop Date"
                                onAccept={(newValue) => handleDateUpdate('stopDateTime', newValue?.startOf('hour'))}
                                disabled={disabled}
                                views={['year', 'month', 'day', 'hours']}
                                format="MM/DD/YYYY HH:mm"
                                maxDateTime={defaults.maxDateTime}
                                minDateTime={defaults.minDateTime}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: !!errors.stopDateTime,
                                        helperText: errors.stopDateTime?.message,
                                        onBlur: (e) => {
                                            handleDateUpdate('stopDateTime', dayjs(e.target.value).startOf('hour'));
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            {...register('capacity')}
                            variant="outlined"
                            label="Capacity"
                            color="success"
                            size='small'
                            fullWidth
                            disabled={disabled}
                            error={!!errors.capacity}
                            helperText={errors.capacity?.message}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <ButtonGroup fullWidth>
                            {profileOptions.map(profile => (
                                <Tooltip key={profile.name} title={profile.tooltip} arrow>
                                    <Button
                                        key={profile.name}
                                        variant='contained'
                                        type='submit'
                                        form='profileEditorConfigForm'
                                        onClick={() => setValue('profile', profile.name)}
                                        disabled={disabled}
                                        sx={{ backgroundColor: selectedProfile === profile.name ? palette.dark : palette.main, }}
                                    >{profile.name}</Button>
                                </Tooltip>
                            ))}
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => setGridData([])}
                            disabled={disabled}
                        >Clear All</Button>
                    </Grid>
                </Grid>
            </form>
            <AgGridContainer style={{ width: "100%", p: 2, }} >
                <AgGridReact
                    ref={gridRef}
                    rowData={gridData}
                    columnDefs={colDefs}
                    domLayout='autoHeight'
                    onCellValueChanged={onCellValueChanged}
                    overlayNoRowsTemplate={'<span>Add blocks to view and edit profile information here.</span>'}
                    enableRangeSelection
                    enableFillHandle
                    rowSelection='multiple'
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    gridOptions={gridOptions}
                    defaultColDef={defaultColDef}
                    components={{
                        dateEditor: DateTimeCellEditor,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
}