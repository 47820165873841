import { Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, TextField, Grid, } from "@mui/material";
import { useState, useEffect, useMemo, memo, useCallback } from 'react'
import { apiUrlPrefix, } from "../../authConfig";
import { useSnackbar } from "notistack";
import { logAction } from "../../utils/auditLogger";
import axios from "axios";
import useHeader from "../useHeader";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, array } from "yup";
import { renderUSTimezones, renderStandardTimezones } from "../../utils/renderTimezones";
import ProfileEditor from "../ProfileEditor";
import ConfirmationDialog from "../TSRActivity/ConfirmationDialog";
import { debounce } from "lodash";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin
import CloseIcon from '@mui/icons-material/Close';

const RelinquishDialog = (props) => {
  const { open, closeDialog, tsr={}, timezone, application='Day Ahead Redirects',} = props;
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  dayjs.extend(utc);
  dayjs.extend(tz);

  const schema = object().shape({
    profile: array().min(1, 'Please create a profile.')
  })

  const defaultValues = {
    aref: tsr.AssignmentRef,
    timezone: renderStandardTimezones[timezone],
    provider: tsr.Provider,
    profile: [],
  }

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const profile = watch('profile');

  useEffect(() => {
    if(tsr?.AssignmentRef && !open) {
      reset(defaultValues);
    }
  }, [tsr])

  useEffect(() => {
    setValue('timezone', renderStandardTimezones[timezone]);
  }, [timezone])

  async function handleRelinquish(confirmed=false) {
    const params = {
      userGuid: headers.userGuid,
      timeZone: renderUSTimezones[timezone],
      action: 'relinquish',
      TargetExecutionTime: '',
      application: application,
      data: formatData(),
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${params.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=${confirmed}&parm=${params.application}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    return axios(options).catch(error => { 
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
      logAction(`Error submitting relinquish. ${error}`, 'DayAheadRedirects', headers.userGuid);
    });
  }

  function onSubmit() {
    setOpenConfirmationDialog(true);
    setLoadingConfirmation(true);
    handleRelinquish().then(response => {
      setConfirmationMessage(response.data);
      setLoadingConfirmation(false);
    });
  }

  function onError(errors) {
    if(errors.profile) {
      enqueueSnackbar(errors.profile.message);
    } else {
      enqueueSnackbar('Please address errors before submitting.')
    }
  }

  const handleConfirmation = useCallback(debounce(() => {
    enqueueSnackbar('Submitting relinquish to OASIS database...')
    closeDialog();
    setOpenConfirmationDialog(false);
    handleRelinquish(true).then(response => {
      enqueueSnackbar('Relinquish submitted.')
      logAction('User confirmed relinquish', 'Redirect Dialog', confirmationMessage);
    })
  }, 2000, { leading: true, }), [confirmationMessage, openConfirmationDialog])

  function formatData() {
    return {
      AssignmentRef: tsr.AssignmentRef,
      Provider: tsr.Provider,
      profileInfo: profile.map(block => ({
        startDateTime: block.startDateTime,
        endDateTime: block.endDateTime,
        capacityRelinquish: block.capacityRequested,
      }))
    }
  }

  const maxInfo = useMemo(() => {
    return JSON.parse(tsr.availabilityData ?? '[]').map(block => ({
      capacityRequested: block.availability,
      startDateTime: dayjs(block.dateTime, 'YYYY-MM-DD HH:mm'),
    }))
  }, [tsr.availabilityData])

  const startDate = useMemo(() => {
    const firstNonzeroBlock = JSON.parse(tsr.availabilityData ?? '[]').find(block => block.availability > 0)
    return firstNonzeroBlock?.dateTime;
  }, [tsr.availabilityData])

  const stopDate = useMemo(() => {
    const lastNonzeroBlock =  JSON.parse(tsr.availabilityData ?? '[]').slice().reverse().find(block => block.availability > 0);
    return lastNonzeroBlock?.dateTime;
  }, [tsr.availabilityData])

  const defaultCapacity = useMemo(() => {
    //find smallest non-zero availaility, or set to 0 
    const blocks = JSON.parse(tsr.availabilityData ?? '[]');
    const availabilities = blocks.map(block => block.availability);
    const nonZeroAvailabilities = availabilities.filter(availability => availability > 0);
    const min = nonZeroAvailabilities.length === 0 ? 0 : Math.min(...nonZeroAvailabilities);
    return min;
  }, [tsr.availabilityData])

  return(
    <Dialog open={open} maxWidth={'xl'} fullWidth >
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography>Set Relinquish Profile</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={closeDialog} size='large'>
              <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ height: '100%',   display: 'flex', flexDirection: 'column' }}>
        <ConfirmationDialog 
          open={openConfirmationDialog} 
          message={confirmationMessage}
          loading={loadingConfirmation}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirmation={handleConfirmation}
          cancelText={'CANCEL'}
          confirmText={'CONFIRM RELINQUISH'}
        />
        <form id='da-relinquish-form' onSubmit={handleSubmit(onSubmit, onError)}>
          <Box sx={{ p: 1, }}>
            <Grid container spacing={2} rowSpacing={0}>
              <Grid item xs={2}>
                <TextField
                  label='Aref'
                  {...register('aref')}
                  disabled
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label='Provider'
                  {...register('provider')}
                  disabled
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label='Timezone'
                  {...register('timezone')}
                  disabled
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12} sx={{ height: '60vh'}}>
                <ProfileEditor 
                  maxInfo={maxInfo}
                  gridData={profile}
                  setGridData={(newProfile) => {
                    setValue('profile', newProfile);
                  }}
                  defaultValues={{
                    startDate: startDate,
                    stopDate: stopDate,
                    defaultCapacity: defaultCapacity,
                  }}
                  chartHeight='30vh' 
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeDialog}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button 
          type="submit"
          form='da-relinquish-form'
          variant="contained" 
          color="primary" 
        >Relinquish</Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(RelinquishDialog);