import { forwardRef, useContext, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import renderTimezones from "../renderTimezones";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Switch, Tooltip, Box, Grid, Autocomplete, TextField, FormGroup, FormControlLabel } from "@mui/material"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import hours from '../TimedTSRs/Hours'
import { Toolbar, Button,  } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import useFilteredOptions from "../useFilteredOptions";
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { useSnackbar } from "notistack";
import { apiUrlPrefix, userGroups } from "../../../authConfig";
import GroupGuard from "../../Guards/GroupGuard";

export default (props) => {
  const { open, view, handleSave, handleCancel } = props;
  const [criteria, setCriteria] = useState({...view})
  const { providers, filteredCombos, customerCodes, filteredTSCombos, requestTypes } = useFilteredOptions({Provider: criteria.Provider, POR: criteria.POR, POD: criteria.POD, });

  const {
    requestType,
    Provider,
    POR,
    POD,
    customerCode,
    serviceIncrement,
    serviceClass,
  } = criteria;

  function handleCriteriaChange(key, value) {
    setCriteria({
      ...criteria,
      [key]: value
    });
  }

  return(
    <Dialog open={open} fullWidth maxWidth='lg'>
        <DialogTitle>Edit Filter Criteria</DialogTitle>
        <DialogContent>
          <Box sx={{display: 'flex', p:1, paddingTop:2 }}>
            <Grid container spacing={2}>
              <Grid item container spacing={1} alignItems='center' >
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    options={requestTypes}
                    value={requestType}
                    onChange={(_, newValue) => {
                      handleCriteriaChange('requestType', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Request Type"
                        color="success" 
                        placeholder="Request Type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    options={providers}
                    value={Provider}
                    onInputChange={(e, newValue) => {
                      handleCriteriaChange('Provider', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Provider"
                        color="success" 
                        placeholder="Provider"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="POR"
                    size="small"
                    freeSolo
                    fullWidth
                    options={filteredCombos.pors}
                    value={POR}
                    onInputChange={(e, newValue) => {
                      handleCriteriaChange('POR', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="POR"
                        color="success" 
                        placeholder="POR"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="POD"
                    freeSolo
                    fullWidth
                    options={filteredCombos.pods}
                    value={POD}
                    size="small"
                    onInputChange={(e, newValue) => {
                      handleCriteriaChange('POD', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="POD"
                        color="success" 
                        placeholder="POD"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={1} alignItems='center' >
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    multiple
                    size="small"
                    options={customerCodes}
                    value={customerCode}
                    onChange={(_, newValue) => {
                      handleCriteriaChange('customerCode', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Customer Code"
                        color="success" 
                        placeholder="Customer Code"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    options={filteredTSCombos.serviceIncrements}
                    value={serviceIncrement}
                    onInputChange={(_, newValue) => {
                      handleCriteriaChange('serviceIncrement', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Service Increment"
                        color="success" 
                        placeholder="Service Increment"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    id="Class"
                    fullWidth
                    freeSolo
                    size="small"
                    options={[ 'FIRM', 'NON-FIRM', 'SECONDARY' ]}
                    value={serviceClass}
                    onInputChange={(_, newValue) => {
                      handleCriteriaChange('serviceClass', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Class"
                        color="success" 
                        placeholder="Class"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    fullWidth
                    options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                    value={renderTimezones[criteria.timezone]}
                    onChange={(_, newValue) => {
                      handleCriteriaChange('timezone', renderTimezones[newValue]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size='small'
                        label="Timezone"
                        color="success" 
                        placeholder="Timezone"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid> 
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
          <Box sx={{ flexGrow: 1, }} />
          <Button variant="contained" color="primary" onClick={() => handleSave(criteria)}>{`Save & Close`}</Button>
        </DialogActions>
    </Dialog>
  )
}