import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from "react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { UserGroupsProvider } from './data/fetchUserGroups';
import { UserGuidProvider } from './data/UserGuidContext/userGuidProvider';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Power Systems Operation,LicensedApplication=PowerStationPlatform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-033907,SupportServicesEnd=12_October_2023_[v2]_MTY5NzA2NTIwMDAwMA==61e52b086e934c4e17f6e6a0269e0099");

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient();
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

root.render(
  <QueryClientProvider client={queryClient}>
    <MsalProvider instance={msalInstance}>
      <UserGuidProvider>
        <UserGroupsProvider>
          <App/>
        </UserGroupsProvider>
      </UserGuidProvider>
    </MsalProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
