import { Dialog, Stack, Slide, DialogContent, DialogTitle, Typography, Box, IconButton, DialogActions, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { apiUrlPrefix } from "../../../authConfig";
import BarChartIcon from '@mui/icons-material/BarChart';
import { CreateScheduleForm } from "./CreateScheduleForm";
import { useTheme } from "@mui/material";
import { AnalysisChart } from "./AnalysisChart";
import { useApi } from "../../useApi";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps";
import useHeader from "../../useHeader";
import dayjs from "dayjs";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { useFormContext } from "react-hook-form";
import { MismatchAlert } from "./ScheduleMismatchAlert";
import { isLongDay } from "../Utils";

export const ScheduleDialog = ({ open, handleCancel, selectedRows, formId, sourceSinkOptions, handleClose, handleRefresh }) => {
    const [showAnalysis, setShowAnalysis] = useState(false);
    const contentContainerRef = useRef(null);
    const theme = useTheme();
    const { enqueueSnackbar, logAction, post } = useApi();
    const headers = useHeader();
    const gridRef = useRef();
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false, });
    const { watch, handleSubmit } = useFormContext();
    const startDate = watch('startDate');

    const profileHours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(i);
            if (i === 2 && isLongDay(startDate)) acc.push('2*');
            return acc;
        }, []);
    }, [startDate]);

    const defaultRowData = useMemo(() => [
        ...selectedRows.purchases,
        ...selectedRows.sales,
    ], [selectedRows]);

    const [data, setData] = useState(defaultRowData);

    const tagIds = useMemo(() => {
        return selectedRows.tag?.tagIdx;
    }, [selectedRows]);

    useEffect(() => {
        setData(defaultRowData);
    }, [selectedRows]);

    function confirmInsertUpdateSchedule(data) {
        if (selectedRows.tag?.Scheduled?.toLowerCase() === 'partial') {
            setConfirmationDialogProps({
                open: true,
                title: `Partial Tag - ${selectedRows.tag?.tagIdx}`,
                message: 'This tag is partially scheduled. Are you sure you want to use this tag?',
                onConfirmation: () => insertUpdateSchedule(data),
                onCancel: () => setConfirmationDialogProps({ open: false }),
            });
        } else if (selectedRows.tag?.Scheduled?.toLowerCase() === 'yes') {
            setConfirmationDialogProps({
                open: true,
                title: `Scheduled Tag - ${selectedRows.tag?.tagIdx}`,
                message: 'This tag is already fully scheduled. Are you sure you want to use this tag?',
                onConfirmation: () => insertUpdateSchedule(data),
                onCancel: () => setConfirmationDialogProps({ open: false }),
            });
        } else {
            insertUpdateSchedule(data);
        }
    }

    async function insertUpdateSchedule(data) {
        setConfirmationDialogProps({ open: false });
        handleClose(); // close the dialog and clear selections
        const { scheduleID, comments, source, sink, por, pod, timezone, } = data;
        const profileInfo = formatProfileInfo(data);
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_scheduleInsertUpdate_v6`
            + `&parm=${headers.userGuid}`
            + `&parm=${scheduleID ?? ''}`
            //+ `&parm=${selectedRows.purchase.dealID ?? ''}`
            //+ `&parm=${selectedRows.sale.dealID ?? ''}`
            + `&parm=${comments ?? ''}`
            + `&parm=${por ?? ''}`
            + `&parm=${pod ?? ''}`
            + `&parm=${source ?? ''}`
            + `&parm=${sink ?? ''}`
            + `&parm=${tagIds ?? ''}`
            + `&parm=${timezone ?? ''}`

        return post(url, profileInfo).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Schedule saved successfully.', { variant: 'success' });
                logAction('User saved schedule', 'Deal Rizz Scheduling', { ...data, deals: selectedRows });
                handleSubmit(handleRefresh)();
            }
        });
    }

    function formatProfileInfo(data) {
        const profileData = [];
        gridRef.current.api.forEachNode(node => {
            if (node.data?.rowType === 'Schedule') {
                const dealID = node.data.dealID;
                const start = dayjs(startDate).startOf('day');
                profileHours.forEach((hour, idx) => {
                    const startDateTime = start.add(idx, 'hour').format('MM/DD/YYYY HH:mm');
                    const endDateTime = start.add(idx + 1, 'hour').format('MM/DD/YYYY HH:mm');
                    profileData.push({ dealID, startDateTime, endDateTime, capacityRequested: node.data[hour] });
                });
            }
        });
        return profileData;
    }

    const defaultHourColDef = useCallback((i) => ({
        enableCellChangeFlash: true,
        colId: `${i}`,
        minWidth: 55,
        headerName: `${i}`,
        type: 'numericColumn',
        cellDataType: 'number',
        editable: (params) => params.data.rowType === 'Schedule',
        valueGetter: params => {
            if (!(params.data.rowType === 'Remaining')) {
                return params.data[i];
            } else {
                return calculateRemaining(params);
            }
        },
        valueSetter: params => {
            params.data[params.column.colId] = params.newValue;
            return true;
        },
    }), []);

    const hourlyColDefs = useMemo(() => {
        return profileHours.map(hour => defaultHourColDef(hour));
    }, [defaultHourColDef, profileHours]);

    const colDefs = useMemo(() => [
        {
            field: 'dealID',
            headerName: 'Deal ID',
            minWidth: 83,
            cellDataType: 'text',
        },
        { field: 'rowType', rowGroup: true, hide: true },
        {
            field: 'Deal_Token',
            minWidth: 140,
        },
        ...hourlyColDefs,
    ], [hourlyColDefs]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        flex: 1,
    }), []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-row-purchase": params => params.data?.Transaction_Type?.toLowerCase() === 'purchase' && theme.palette.mode === 'light',
            "ag-row-sale": params => params.data?.Transaction_Type?.toLowerCase() === 'sale' && theme.palette.mode === 'light',
            "ag-row-purchase-dark": params => params.data?.Transaction_Type?.toLowerCase() === 'purchase' && theme.palette.mode === 'dark',
            "ag-row-sale-dark": params => params.data?.Transaction_Type?.toLowerCase() === 'sale' && theme.palette.mode === 'dark',
        },
    }), [theme.palette.mode]);

    const purchaseColorClass = theme.palette.mode === 'light' ? 'ag-row-purchase' : 'ag-row-purchase-dark';
    const saleColorClass = theme.palette.mode === 'light' ? 'ag-row-sale' : 'ag-row-sale-dark';

    function calculateRemaining(params) {
        const originalRow = params.api.getRowNode(params.data.dealID + '-Original');
        const scheduleRow = params.api.getRowNode(params.data.dealID + '-Schedule');
        const column = params.column.colId;
        const originalValue = originalRow.data[column];
        const scheduleValue = scheduleRow.data[column];
        const remaining = originalValue - scheduleValue;
        return remaining;
    }

    const rowData = useMemo(() => {
        const scheduleRows = data.map(row => {
            const profile = profileHours.reduce((acc, hour) => {
                const val = selectedRows.tag?.[hour] ?? 0;
                acc[hour] = val;
                return acc;
            }, {});

            return {
                ...row,
                rowType: 'Schedule',
                ...profile,
            }
        });
        const remainingRows = data.map(row => ({ ...row, rowType: 'Remaining', }));
        const originalRows = data.map(row => ({ ...row, rowType: 'Original', }));

        return [
            ...originalRows,
            ...scheduleRows,
            ...remainingRows,
        ];
    }, [data]);

    const getRowId = useCallback(params => `${params.data.dealID}-${params.data.rowType}`, []);

    function onCellValueChanged(params) {
        //get the remaining row node
        const remainingRow = params.api.getRowNode(params.data.dealID + '-Remaining');
        //update the value in the remaining row
        params.api.refreshCells({
            force: true,
            rowNodes: [remainingRow],
            columns: [params.column]
        })
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            ref={contentContainerRef}
        >
            <DialogTitle sx={{ pb: 0, pt: 0.5, px: 2 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Create Schedule</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={() => setShowAnalysis(s => !s)} size='large'>
                        <BarChartIcon />
                    </IconButton>
                    <IconButton onClick={handleCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
                <ConfirmationDialog {...confirmationDialogProps} />
                <Slide in={showAnalysis} direction='left' container={contentContainerRef.current}>
                    <div style={{ display: showAnalysis ? 'block' : 'none', width: '100%', padding: theme.spacing(1), height: '40vh' }}>
                        <AnalysisChart deals={data} date={startDate} />
                    </div>
                </Slide>
                <Slide in={!showAnalysis} direction='right' container={contentContainerRef.current}>
                    <div style={{ display: !showAnalysis ? 'flex' : 'none', width: '100%', flexDirection: 'column' }}>
                        <MismatchAlert selectedRows={selectedRows} />
                        <CreateScheduleForm
                            onSubmit={confirmInsertUpdateSchedule}
                            formId={formId}
                            selectedRows={selectedRows}
                            sourceSinkOptions={sourceSinkOptions}
                        />
                        <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', pt: 1, }}>
                            <Typography variant='title'>Selected Deals</Typography>
                            <Box sx={{ flexGrow: 1, }} />
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Box className={purchaseColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                                <Typography align='center'>- Purchases</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Box className={saleColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                                <Typography align='center'>- Sales</Typography>
                            </Stack>
                        </Stack>
                        <AgGridContainer style={{ padding: theme.spacing(1), }}>
                            <AgGridReact
                                {...defaultGridOptions}
                                ref={gridRef}
                                columnDefs={colDefs}
                                defaultColDef={defaultColDef}
                                rowData={rowData}
                                domLayout={'autoHeight'}
                                gridOptions={gridOptions}
                                getRowId={getRowId}
                                onCellValueChanged={onCellValueChanged}
                                groupDisplayType={'groupRows'}
                                groupDefaultExpanded={1}
                            />
                        </AgGridContainer>
                    </div>
                </Slide>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                <Button onClick={handleCancel} color='error'>Cancel</Button>
                <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
            </DialogActions>
        </Dialog>
    );
}
