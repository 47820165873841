import dayjs from '../../../dayjs-tz';

export const useProfileTemplates = (defaults, formatString = 'MM/DD/YYYY HH:mm') => {
    function generateTransmissionBlocksUsingTemplate(config) {
        const blocks = [];
        let start = config.startDateTime;
        const end = config.stopDateTime;
        switch (config.profile) {
            case 'HLH':
                return hlhBlocks(start, end, config.capacity);
            case 'LLH':
                return llhBlocks(start, end, config.capacity);
            case 'Shaped':
                let next = start;
                while (end.isAfter(next, 'hour')) {
                    blocks.push(createNewBlock(next, next.add(1, 'hour'), config.capacity));
                    next = dayjs(next).add(1, 'hour');
                }
                return blocks;
            case '1x8':
                return offPeakBlocks(start, end, config.capacity);
            case '1x16':
                return onPeakBlocks(start, end, config.capacity);
            default: //default is ATC
                return atcBlocks(start, end, config.capacity);
        }
    }

    const createNewBlock = (start, end, capacity) => {
        const blockStart = dayjs(start).tz(defaults.timezone, true)
        const price = defaults.defaultPriceData?.find(block => {
            const priceStart = dayjs(block.StartTime).tz(defaults.timezone);
            const sameOrBefore = blockStart.isSame(priceStart, 'hour')
            return sameOrBefore;
        })?.OfferPrice ?? 0;
        return {
            startDateTime: start.format(formatString),
            endDateTime: end.format(formatString),
            capacityRequested: capacity,
            price: price,
        }
    }

    function generateBlocks(start, end, capacity, selector, getBlockSignature = defaultBlockSignature) {
        const blocks = [];
        let next = dayjs(start).startOf('hour');
        while (end.isAfter(next, 'hour')) {
            if (selector(next)) {
                blocks.push(createNewBlock(next, next.add(1, 'hour'), capacity));
            }
            next = next.add(1, 'hour');
        }
        return rollUpBlocks(blocks, getBlockSignature);
    }

    function atcBlocks(start, end, capacity) {
        const selector = () => true;
        const isOffPeak = (date) => date.hour() < 6 || date.hour() >= 22;
        const getAtcBlockSignature = (block) => `${block.capacityRequested}-${isOffPeak(dayjs(block.startDateTime))}`;
        return generateBlocks(start, end, capacity, selector, getAtcBlockSignature);
    }

    function llhBlocks(start, end, capacity) {
        const selector = (date) => {
            const isOffPeak = date.hour() < 6 || date.hour() >= 22;
            const isSunday = date.day() === 0;
            return isOffPeak || isSunday;
        }
        return generateBlocks(start, end, capacity, selector);
    }

    function offPeakBlocks(start, end, capacity) {
        const selector = (date) => date.hour() < 6 || date.hour() >= 22;
        return generateBlocks(start, end, capacity, selector);
    }

    function hlhBlocks(start, end, capacity) {
        const selector = (date) => {
            const isOnPeak = date.hour() >= 6 && date.hour() < 22;
            const isSunday = date.day() === 0;
            return (!isSunday && isOnPeak)
        }
        return generateBlocks(start, end, capacity, selector);
    }

    function onPeakBlocks(start, end, capacity) {
        const selector = (date) => date.hour() >= 6 && date.hour() < 22;
        return generateBlocks(start, end, capacity, selector);
    }

    function rollUpBlocks(blocks, getBlockSignature = defaultBlockSignature) {
        return blocks.reduce((rolledUp, next) => {
            const last = rolledUp[rolledUp.length - 1];
            if (last && (last.endDateTime === next.startDateTime) && (getBlockSignature(last) === getBlockSignature(next))) {
                last.endDateTime = next.endDateTime;
            } else {
                rolledUp.push(next);
            }
            return rolledUp;
        }, [])
    }

    const defaultBlockSignature = (block) => `${block.capacityRequested}-${block.price}`;

    return {
        generateTransmissionBlocksUsingTemplate,
    }
}