import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupGuard from '../Guards/GroupGuard';
import CachedIcon from '@mui/icons-material/Cached';
import { LayoutToolPanel } from './LayoutToolPanel';
import { userGroups } from '../../authConfig';

export const DatabaseLayoutToolPanel = (props) => {  
    const { onLayoutDatabaseDelete, onLayoutDatabaseRestore, onLayoutDatabaseSave, ...layoutPanelProps } = props;

    return(
      <>
        <LayoutToolPanel 
          {...layoutPanelProps}
        />
        <MenuList>
          <MenuItem onClick={onLayoutDatabaseRestore}>
            <ListItemIcon>
              <CachedIcon color='primary' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Restore From DB</ListItemText>
          </MenuItem>
          <GroupGuard groups={[userGroups.admins]}>
            <MenuItem onClick={onLayoutDatabaseDelete}>
              <ListItemIcon>
                <DeleteForeverIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete From DB</ListItemText>
            </MenuItem>
            <MenuItem onClick={onLayoutDatabaseSave}>
              <ListItemIcon>
                <SaveIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Save To DB</ListItemText>
            </MenuItem>
          </GroupGuard>
        </MenuList>
      </> 
    );
};