import { useState, useEffect, useMemo, memo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToggleButton, ToggleButtonGroup, FormControlLabel, Grid, Dialog, DialogTitle, DialogContent, TextField, Button, Tooltip, Box, Typography, IconButton, DialogActions, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix, userGroups } from '../../../authConfig';
import { useUserGroups } from '../../../data/useUserGroups';
import { TransferList } from './TransferList';
import FormCheckbox from '../../FormControls/FormCheckbox';

const NewBrainDialog = ({ open, onClose, existingBrainNames, handleAddNewBrain, setUpNewBrainEasy, }) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [allInputOutputs, setAllInputOutputs] = useState([]);
    const [exampleOutputs, setExampleOutputs] = useState([]);
    const { userIsInGroup } = useUserGroups();
    const hasFullAccess = userIsInGroup(userGroups.brain);
    const [mode, setMode] = useState('simple');
    const simpleMode = mode === 'simple';

    const maxSize = hasFullAccess ? 5000 : 50;
    const maxHours = hasFullAccess ? 720 : 24;
    const maxInputs = hasFullAccess ? 500 : 25;
    const maxOutputs = hasFullAccess ? 500 : 5;

    useEffect(() => {
        loadInputsOutputs();
    }, [mode]);

    const schema = yup.object().shape({
        brainName: yup.string().required('Brain name is required.').test(
            'is-unique',
            'Brain name already exists. Please choose a unique name.',
            value => !existingBrainNames.includes(value)
        ).max(50, 'Brain name must be less than 50 characters.'),
        size: yup.string().when('simpleMode', {
            is: false,
            then: () => yup.number().required('Size is required').max(maxSize, `Max size is ${maxSize}. As a paying subscribed, you can have up to 5,000.`).typeError('Size must be a number.'),
            otherwise: () => yup.string().nullable().notRequired(),
        }),
        hours: yup.number().required('Hours are required.').max(maxHours, `Max number of hours is ${maxHours}. As a paying subscribed, you can have up to 720.`).typeError('Hours must be a number.'),
        inputs: yup.array().when('simpleMode', {
            is: false,
            then: () => yup.array().required('Please select at least one input.').max(maxInputs, `Max number of inputs is ${maxInputs}. As a paying subscribed, you can have up to 500.`),
            otherwise: () => yup.array().nullable().notRequired(),
        }),
        outputs: yup.array().required('Please select an output.').max(maxOutputs, `Max number of outputs is ${maxOutputs}. As a paying subscriber, you can have up to 500.`),
        autoTrain: yup.boolean(),
        beta: yup.string().when('simpleMode', {
            is: false,
            then: () => yup.number().typeError('Beta must be a number.').test(
                'is-decimal',
                'Beta must be a decimal value.',
                value => value ? value.toString().match(/^\d+(\.\d+)?$/) : true,
            ).required('Beta is required.'),
            otherwise: () => yup.string().nullable().notRequired(),
        }),
        simpleMode: yup.boolean(),
    });

    const defaultValues = useMemo(() => ({
        autoTrain: true,
        beta: 10.0,
    }), []);

    const { register, handleSubmit, formState: { errors }, reset, setValue, watch, control, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        setValue('simpleMode', simpleMode);
    }, [simpleMode]);

    const inputs = watch('inputs', []);
    const outputs = watch('outputs', []);
    const autoTrain = watch('autoTrain', false);

    const onSubmit = (data) => {
        onClose(data.brainName);
        enqueueSnackbar(`Creating brain ${data.brainName}. We will alert you on completion.`, { variant: 'info', });
        const addNewBrainFunction = simpleMode ? setUpNewBrainEasy : handleAddNewBrain;
        addNewBrainFunction(data).then((response) => {
            reset();
        }).catch((error) => {
            enqueueSnackbar(`Error creating brain: ${error}`, { variant: 'error' });
        });
    };

    function loadInputsOutputs() {
        fetchExampleOutputs().then(() => {
            fetchAllInputOutputs();
        });
    }

    async function fetchAllInputOutputs() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchAllInputsOutputs`;
        return axios.get(url, { headers: headers }).then((response) => {
            setAllInputOutputs(response.data);
        }).catch((error) => {
            enqueueSnackbar(`Error fetching inputs and outputs: ${error}`, { variant: 'error' });
        });
    }

    async function fetchExampleOutputs() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchAllExampleOutputs`;
        return axios.get(url, { headers: headers }).then((response) => {
            setExampleOutputs(response.data);
        }).catch((error) => {
            enqueueSnackbar(`Error fetching inputs and outputs: ${error}`, { variant: 'error' });
        });
    }

    function handleListTransfer(listKey, items) {
        setValue(listKey, items);
    }

    function onError(errors) {
        const firstError = Object.values(errors)[0];
        enqueueSnackbar(`Error: ${firstError.message}`, { variant: 'error' });
    }

    const transferListItems = simpleMode ? exampleOutputs : allInputOutputs;

    return (
        <Dialog open={open} fullWidth maxWidth='xl'>
            <DialogTitle>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Set Up New Brain</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <ToggleButtonGroup
                        exclusive
                        value={mode}
                        size="small"
                        color="primary"
                        sx={{ mt: .2, mr: 1.3, display: 'contents' }}
                        onChange={(e, newMode) => {
                            newMode && setMode(newMode)
                        }}
                    >
                        <ToggleButton sx={{ mt: .5 }} key={'simple-mode-button'} value={'simple'}>
                            Simple
                        </ToggleButton>
                        <ToggleButton sx={{ mt: .5 }} key={'advanced-mode-button'} value={'advanced'}>
                            Advanced
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton onClick={() => onClose()} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit, onError)} id='add-new-brain-dialog'>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', }}>
                        <Grid item xs={4} sx={{ p: 1, }}>
                            <Tooltip title="Enter the name of the brain" arrow>
                                <TextField
                                    {...register("brainName")}
                                    label="Brain Name"
                                    error={!!errors.brainName}
                                    helperText={errors.brainName?.message}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>
                        {!simpleMode && <Grid item xs={2} sx={{ p: 1, }}>
                            <Tooltip title={`More neurons in a neural network generally increase its capacity to learn complex patterns, but can also risk overfitting. Your max is ${maxSize}.`} arrow>
                                <TextField
                                    {...register("size")}
                                    label="Size"
                                    error={!!errors.size}
                                    helperText={errors.size?.message}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>}
                        <Grid item xs={2} sx={{ p: 1, }}>
                            <Tooltip title={`Enter how many hours out you want the predictions to be from current time/day. Your max is ${maxHours}.`} arrow>
                                <TextField
                                    {...register("hours")}
                                    label="Hours"
                                    error={!!errors.hours}
                                    helperText={errors.hours?.message}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>
                        {!simpleMode && <Grid item xs={1} sx={{ p: 1, }}>
                            <Tooltip title='Automatically begin brain training upon creation.' arrow placement='top'>
                                <FormControlLabel
                                    control={<FormCheckbox
                                        name={'autoTrain'}
                                        control={control}
                                    />}
                                    label="Auto Train"
                                />
                            </Tooltip>
                        </Grid>}
                        {!simpleMode && <Grid item xs={1} sx={{ display: autoTrain ? 'block' : 'none', p: 1, }}>
                            <Tooltip title="Higher values help prevent overfitting of the model, but underfitting can happen with very large values. You may want to experiment with different values. Start with 0.0, check the training error versus the testing error, and raise beta slowly until the error is similar (e.g. 0.0, .01, .1, 1.0, 10, 100, 1000)." arrow>
                                <TextField
                                    {...register("beta")}
                                    label="Beta"
                                    error={!!errors.beta}
                                    helperText={errors.beta?.message}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>}
                        <TransferList
                            items={transferListItems}
                            handleTransfer={handleListTransfer}
                            inputs={inputs}
                            outputs={outputs}
                            maxInputs={maxInputs}
                            maxOutputs={maxOutputs}
                            mode={mode}
                        />
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">Cancel</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button type="submit" form='add-new-brain-dialog' variant="contained" color="primary">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default memo(NewBrainDialog);