import { UserContext } from '../../data/fetchUserGroups'
import React, { useState, useEffect, useMemo } from "react";
import { Alert, Collapse } from '@mui/material';
import { useIsAuthenticated } from '@azure/msal-react';
import SignInButton from '../SignInButton';
import { RegisterButton } from '../RegisterButton';
import { Stack, Typography } from '@mui/material';

export const DivGuard = (props) => {
    const { groups, message, allowDisable = true, authorizeIfAuthenticated = false, } = props;
    const [isAuthorized, setIsAuthorized] = useState(false);
    const userGroups = React.useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();

    const alertMessage = message ? message : 'Unfortunately, your subscription does not include this part of Power Station. You can look but you can\'t touch.';

    useEffect(() => {
        if (authorizeIfAuthenticated) {
            setIsAuthorized(isAuthenticated);
        } else {
            const intersection = groups.filter(grp => userGroups.includes(grp));
            setIsAuthorized(intersection.length > 0 || userGroups.includes('admins'));
        }
    }, [userGroups, isAuthenticated])

    const guardStyle = useMemo(() => {
        const applyStyle = allowDisable && !isAuthorized;
        const style = applyStyle ? { pointerEvents: "none", opacity: "0.4" } : {}
        return style;
    }, [isAuthorized, allowDisable])

    return (
        <div className='flex-column' style={{ width: '100%' }}>
            <Collapse in={!isAuthorized}>
                <Alert severity="warning" >
                    <Stack sx={{ display: 'flex', alignItems: 'center', }} spacing={2} direction={'row'}>
                        <Typography>
                            {alertMessage}
                        </Typography>
                        <RegisterButton
                            color="primary"
                            variant="outlined"
                            sx={{ display: isAuthenticated ? 'none' : 'block' }} // hide if authenticated
                        >
                            Register
                        </RegisterButton>
                        <SignInButton
                            color="primary"
                            variant="outlined"
                            sx={{ display: isAuthenticated ? 'none' : 'block' }} // hide if authenticated
                        >
                            Sign In
                        </SignInButton>
                    </Stack>
                </Alert>
            </Collapse>
            <div style={{ display: 'flex', height: '100%', width: '100%', ...guardStyle }}>
                {props.children}
            </div>
        </div>
    );
} 
