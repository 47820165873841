import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ProfileGrid } from './ProfileGrid';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ProfileSettings } from './ProfileSettings';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export const ProfileEditor = ({open, handleEditorClose}) => {
  const formatString = 'MM/DD/YYYY';
  const defaultDate = dayjs().format(formatString);
  const [startDate, setStartDate] = useState(defaultDate);
  const [stopDate, setStopDate] = useState(defaultDate);
  const [MW, setMW] = useState(0);
  const [profile, setProfile] = useState(profileOptions[0]);
  const [bidPrice, setBidPrice]= useState();
  const [displaySettings, setDisplaySettings] = useState(false);
  const [userSettings, setUserSettings] = useState();
  const userSettingsStorage = 'profile-picker-user-settings'

  useEffect(() => {
  }, [])

  function getBidPrice() {};

  function onLoad() {};

  function handleClose() {
    if(displaySettings) {handleDisplaySettings()}
    else {
      const data = grabEditorState();
      handleEditorClose(data);
    }
  };

  function grabEditorState() {
    return({
      Start: startDate,
      Stop: stopDate,
    });
  };

  function handleDisplaySettings() {
    setDisplaySettings(!displaySettings);
  };

  function handleSettingsSave(newSettings) {
    localStorage.setItem(userSettingsStorage, JSON.stringify(newSettings));
    setUserSettings(newSettings);
  };

  function handleProfileSelection(_, value) {
    setProfile(profileOptions.find(profile => profile.name === value));
  }

  return (
    <Dialog
        open={open}
        //onClose={handleEditorClose}
        PaperProps={{ sx: { width:'80%', height:'70%'}}}
        fullScreen
        >
          <DialogTitle>{"Add New Row"}</DialogTitle>
          <DialogContent>
            {displaySettings
              ?
              <ProfileSettings profile={profile} settings={userSettings} handleSettingsSave={handleSettingsSave}/>
              :
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} sx={{p:1}}>
                  <Grid item xs={3}>
                    <Autocomplete
                      value={profile.name}
                      onChange={handleProfileSelection}
                      autoHighlight
                      variant='outlined'
                      //fullWidth 
                      options={profileOptions.map(profile => profile.name)}
                      renderInput={(params) => <TextField {...params} variant='standard'/>}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{p:1}}>
                  <Grid item>
                    <DatePicker
                      id="startDate"
                      label="Start Date"
                      value={dayjs(startDate)}
                      onChange={(newStart) => {
                        setStartDate(newStart.format(formatString));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      id="stopDate"
                      label="Stop Date"
                      value={dayjs(stopDate)}
                      onChange={(newStop) => {
                        setStopDate(newStop.format(formatString));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='MW'
                      variant='outlined'
                      value={MW}
                      defaultValue={100}
                      onChange={(event) => setMW(event.target.value)}/>
                  </Grid>
                  <Grid item>
                    <TextField
                      label='Bid Price'
                      variant='outlined'
                      value={bidPrice}
                      defaultValue={2.38}
                      onChange={(event) => setBidPrice(event.target.value)}/>
                    <Button onClick={getBidPrice}>Get Price</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <ProfileGrid 
                      profile={profile} 
                      startDate={startDate} 
                      stopDate={stopDate} 
                      MW={MW} 
                      bidPrice={bidPrice}
                      timezone={'PST'}/>
                  </Grid>
                </Grid>
              </LocalizationProvider>}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid container item xs={6} justifyContent={'flex-start'}>
                <Button onClick={handleDisplaySettings}>Settings</Button>
              </Grid>
              <Grid container item xs={6} justifyContent={'flex-end'}>
                <Button onClick={handleClose}>Close</Button>
              </Grid>
            </Grid>
          </DialogActions>
    </Dialog>
  )
};

const profileOptions = [
  {   
      name: 'HE 7-22',
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
  },
  {
      name: 'HE 7-22 (5 weekdays)',
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
  },
  {
      name: 'HE 7-22 (5 weekdays+Sat)',
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
  },
  {
      name: 'HE 7-22 (fullweek)',
      hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
  },
  {
      name: 'HE 1-6, 23-24',
      hours: [1, 2, 3, 4, 5, 6, 23, 24],
  },
  {
      name: 'HE 1-6, 23-24 (Sat+Sun)',
      hours: [1, 2, 3, 4, 5, 6, 23, 24],
  },
  {
      name: 'HE 1-6, 23-24 (fullweek)',
      hours: [1, 2, 3, 4, 5, 6, 23, 24],
  },
  {
      name: 'HE 1-6, 23-24 (5 weekdays+Sat), 1-24 (Sun)',
      hours: [1, 2, 3, 4, 5, 6, 23, 24],
  },
  {
      name: 'HE 1-6, 23-24 (5 weekdays+Sat), 1-24 (Sun) (Full Month)',
      hours: [1, 2, 3, 4, 5, 6, 23, 24],
  },
  {
      name: 'Custom',
      hours: [],
  }
];
