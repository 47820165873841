import { Autocomplete, FormControlLabel, IconButton, Stack, TextField, FormGroup, Switch, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useState } from "react";
import dayjs from "dayjs";

export const Toolbar = () => {
    const [date, setDate] = useState(dayjs());
    const [tagCode, setTagCode] = useState('None');
    const [section, setSection] = useState('Tags Without Schedule');

    const defaultLabelSx = {
        labelPlacement: 'start',
        width: 'fit-content',
        componentsProps: {
            typography: {
                sx: { marginRight: 1, whiteSpace: 'noWrap' },
                color: 'primary',
                fontWeight: 'bold',
            }
        }
    }

    function onDateChange(newDate) {
        setDate(newDate);
    }

    return (
        <Stack direction='row' spacing={3} sx={{ p: 1, }}>
            <FormControlLabel
                {...defaultLabelSx}
                label='Date: '
                control={
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onClick={() => {
                            setDate(date.add(1, 'day'))
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                        <DatePicker
                            value={date}
                            onChange={onDateChange}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                }
                            }}
                        />
                        <IconButton size='small' onClick={() => {
                            setDate(date.subtract(1, 'day'))
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                    </LocalizationProvider>
                }
            />
            <FormControlLabel
                {...defaultLabelSx}
                label='Time Zone: '
                control={
                    <b>VALUE</b>
                }
            />
            <FormControlLabel
                {...defaultLabelSx}
                label='Tag Code: '
                control={
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        autoSelect
                        value={tagCode}
                        options={['None']}
                        fullWidth
                        slotProps={{
                            paper: {
                                sx: {
                                    width: 'fit-content',
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="standard"
                                sx={{ minWidth: 150, }}
                            />
                        )}
                    />
                }
            />
            <FormControlLabel
                {...defaultLabelSx}
                label='Section: '
                control={
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        autoSelect
                        value={section}
                        options={['Tags Without Schedule']}
                        fullWidth
                        slotProps={{
                            paper: {
                                sx: {
                                    width: 'fit-content',
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="standard"
                                sx={{ minWidth: 300, }}
                            />
                        )}
                    />
                }
            />
        </Stack>
    );
}