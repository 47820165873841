import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import useHeader from "../../useHeader";
import { useSnackbar } from "notistack";
import { useEffect, useState, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import "react-tabs/style/react-tabs.css"
import { Typography, Tab, Tabs, Box, Collapse, } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog.js';
import CriteriaProvider from '../../CriteriaContext/CriteriaProvider.js';
import EditIcon from '@mui/icons-material/Edit';
import AddNewOrUpdateDialog from './AddNewOrUpdateDialog.js';
import { useUserLayouts } from '../../useUserLayouts.js';
import { useActionAudit } from '../../useActionAudit.js';
import { WeatherMap } from './WeatherMap.js';

export const ForecastDashboard = ({ visible, }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [openAddNew, setOpenAddNew] = useState(false);
    const { layouts: views, setLayouts: setViews, deleteLayout: handleDelete, insertUpdateLayout: handleSave, } = useUserLayouts('weatherforecast');
    const [viewToUpdate, setViewToUpdate] = useState({});
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const { logAction } = useActionAudit();
    const { enqueueSnackbar } = useSnackbar();
    const headers = useHeader();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchForecast();
    }, []);

    function fetchForecast() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=weatherForecastFetch`;
        axios.get(url, { headers: headers }).then((response) => {
            if (response.data) {
                setData(response.data);
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching weather forecast: ${error}`, { variant: 'error', });
        });
    }

    const locations = useMemo(() => {
        return data.reduce((acc, next) => {
            const key = next.resolvedAddress;
            if (acc.has(key)) {
                const value = acc.get(key);
                value[next.element] = next;
                acc.set(key, value);
            } else {
                acc.set(key, { [next.element]: next, });
            }
            return acc;
        }, new Map());
    }, [data]);

    const locationNames = useMemo(() => {
        return [...locations.keys()].map(name => name.split(',').slice(0, 2).join(','));
    }, [locations]);

    function handleTabSelect(event, newIndex) {
        if (newIndex <= views.length) { //last tab handles deletes, don't switch to it
            setTabIndex(newIndex);
        }
    }

    function handleAddNew(criteria) {
        if (viewToUpdate.id) {
            const oldView = views.find(view => view.id === criteria.id);
            logAction(`User updated the weather forecast view ${oldView.label}`, 'Weather Forecast', `Old view data: ${JSON.stringify(oldView)} New view data: ${JSON.stringify(criteria)}`);
            handleUpdate(criteria);
        } else {
            setTabIndex(views.length); //switch to new tab
            logAction(`User created a new weather forecast view ${criteria.location}`, 'Weather Forecast', JSON.stringify(criteria));
            handleSave(criteria);
        }
        setOpenAddNew(false);
    }

    function handleUpdate(criteria) {
        setViews(views.map(view => view.id === criteria.id ? criteria : view));
        handleSave(criteria, false);
    }

    function handleAddNewClick() {
        setViewToUpdate({});
        setOpenAddNew(true);
    }

    function handleViewUpdate() {
        setViewToUpdate(views[tabIndex]);
        setOpenAddNew(true);
    }

    function handleConfirmDelete() {
        setOpenConfirmDelete(false);
        const current = views[tabIndex];
        logAction(`User deleted the weather forecast view ${current.label}`, 'Weather Forecast', JSON.stringify(current));
        handleDelete(current.id).then(response => {
            const remaining = views?.filter(v => v.id !== current.id);
            if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
                setOpenAddNew(true);
            }
            setTabIndex(0);
        });
    }

    return (
        <CriteriaProvider>
            <Box
                sx={{
                    p: '7px',
                    flexGrow: 1,
                }}
            >
                <AddNewOrUpdateDialog
                    open={openAddNew}
                    viewToUpdate={viewToUpdate}
                    handleCancel={() => setOpenAddNew(false)}
                    handleAddNew={handleAddNew}
                    allLocations={locationNames}
                />
                <ConfirmationDialog
                    open={openConfirmDelete}
                    message={`You are about to delete the current tab${views[tabIndex]?.label ? ' ' + views[tabIndex].label : ''}. Continue?`}
                    onCancel={() => setOpenConfirmDelete(false)}
                    onConfirmation={handleConfirmDelete}
                />
                <Box
                    sx={{
                        borderBottom: 1,
                        bgcolor: 'background.paper',
                        p: '0px',
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabSelect}
                        indicatorColor="primary"
                        textColor="primary"
                        maxHeight="10px"
                        color="primary"
                        backgroundColor="transparent"
                        sx={{ maxHeight: '40px', minHeight: '40px' }}
                    >
                        {views.map((view) => (
                            <Tab
                                sx={{ maxHeight: '40px', minHeight: '40px' }}
                                backgroundColor="transparent"
                                label={view.label}
                                id={`deal-rizz-weather-forecast-tab-${view.id}`} />
                        ))}
                        <Tab id='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
                        <Tab id='editCurrentTab' label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleViewUpdate} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
                        <Tab id='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
                    </Tabs>
                    {views.map((view, i) => {
                        const viewLocations = new Map([...locations].filter(d => {
                            return view.locations?.find(l => d[0].includes(l))
                        }));
                        return (<Collapse in={tabIndex === i} orientation={'vertical'}>
                            <WeatherMap
                                locations={viewLocations}
                                defaultLocation={viewLocations.values().next().value?.temp}
                                id={`deal-rizz-weather-forecast-map-${view.id}`}
                            />
                        </Collapse>)
                    })}
                </Box>
                {!views.length && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '16px' }}>
                    <Typography> No views found.  Click Add New to create a new view.  </Typography>
                </div>}
            </Box>
        </CriteriaProvider>
    );
}