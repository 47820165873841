import { userGroups } from '../authConfig';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import { DivGuard } from '../components/Guards/DivGuardFlex';
import TSRActivityGrid from '../components/TSRActivity/TSRActivityGrid';
import { DashboardLazy } from "../components/DealRizz/Dashboard/DashboardLazy.js";
import AddNewOrUpdateDialog from '../components/TSRActivity/AddNewDialog';

const TSRActivity = ({ visible, application = 'transnow', }) => {
    const dashboardKey = 'tsractivity';
    const useShared = false;

    return (
        <DivGuard groups={[userGroups.tsractivity]}>
            <CriteriaProvider application={application}>
                <DashboardLazy
                    dashboardKey={dashboardKey}
                    useShared={useShared}
                    ViewComponent={TSRActivityGrid}
                    AddNewOrUpdateDialog={AddNewOrUpdateDialog}
                    visible={visible}
                />
            </CriteriaProvider>
        </DivGuard>
    );
}

export default TSRActivity;
