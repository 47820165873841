import { useEffect, useState, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/userGridStyles.css'
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import Users from './Users';
import UserGroupsGrid from './UserGroupsGrid';
import axios from 'axios';
import TemplateTable from '../TransNow/TemplateTable';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import ResizingPane from 'react-resizing-pane';
import DivGuard from '../../components/Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import { useTemplateTable } from '../useTemplateTable';
import useHeader from '../useHeader';

const UserMapping = () => {

  const guid = useLocalGuid();
  const userGridRef = useRef();
  const userGroupGridRef = useRef();
  const [userTemplates, setUserTemplates] = useState([]);
  const { newTemplate, addDropZone, transformRows } = useTemplateTable(userTemplateRowTransform, undefined, userGridRef, userGroupGridRef, 'user-mapping', 'User Grid', 'Group Grid')
  const { enqueueSnackbar } = useSnackbar();

  const addIndex = 0;
  const header = useHeader();
  let storageConfig = { name: 'ResizableUserGrid', type: localStorage };

  useEffect(() => {
    //loadTemplates();
    let abortController = new AbortController();
    // your async action is here  
    loadTemplates();
    return () => {
      abortController.abort();
    }
  }, []);

  useEffect(() => {
    if (userTemplates.length > 0 && userGridRef) {
      userTemplates.forEach(temp => {
        addDropZone(temp.id, (params) => transformRows(temp, params))
      });
    }
  }, [userTemplates, userGridRef]);

  async function loadTemplates() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_GroupsFetch&parm=${guid}`
    let options = {
      headers: header,
      method: 'GET',
      url: url,
    };
    const userTemps = await axios(options).catch(err => { enqueueSnackbar(`Error loading groups.  URL: ${url} Error:  ${err}`) });

    if (userTemps) {
      setUserTemplates(userTemps.data.map(temp => newTemplate(temp.powerStationGroupID, temp, temp.groupName)));
    }
  };

  function addUserRowTemplateClick(temp) {
    const rows = userGridRef.current.api.getSelectedRows();

    if (!rows?.length > 0) {
      enqueueSnackbar('Please select users to map to a group.');
      return;
    }

    const users = rows.map(row => userTemplateRowTransform(row, temp.data));
    userGroupGridRef.current.api.applyTransaction({ add: users, addIndex: addIndex, });
  };

  function userTemplateRowTransform(row, template) {
    const newRow = { ...row }
    newRow['userGuid'] = row['Guid'];
    newRow['groupName'] = template.groupName;
    newRow['powerStationGroupID'] = template.powerStationGroupID;
    //newRow['AssignmentRef'] = undefined;

    return newRow;
  }

  return (
    <DivGuard groups={[userGroups.usermapping]} >
      <div className="user-example-wrapper">
        <ResizingPane
          sides={['right']}
          storageId={26}
          storageConfig={storageConfig}
          height="100%"
          width={550}
          style={{
            border: '0px solid blue',
          }}
        >
          <Users ref={userGridRef} />
        </ResizingPane>
        <div className="user-inner-col vertical-toolbar">
          <TemplateTable
            tableId='user-mapping'
            title='Groups'
            tooltipText='These are user groups you can assign users to.'
            templates={userTemplates}
            handleRowClick={addUserRowTemplateClick}
            rowTooltip={function (template) {
              return (
                <span>{template.descriptionOfAccess}</span>
              )
            }} />
        </div>
        <div className="user-container">
          <UserGroupsGrid ref={userGroupGridRef} templates={userTemplates} />
        </div>
      </div>
    </DivGuard>
  );
};

export default function UserGridsExport() {
  return <UserMapping />;
}