import { useMemo, } from 'react'
import { MultiLegTransmissionDialog } from "../MultiLegTransmissionDialog";

export const EditMultiLegTsrDialog = (props) => {
    const { open, data, handleClose, scheduleDateTime, handleUpdate, } = props;
    const storageKey = `fast-path-transmission-dialog-east`;

    const defaults = useMemo(() => {
        const storedValueString = localStorage.getItem(storageKey);
        const storedValues = JSON.parse(storedValueString ?? '{}');
        const legData = data.reduce((acc, leg, i) => {
            acc[i] = {
                ...storedValues,
                ...leg,
                SaleRef: storedValues.SaleRef ?? leg['Sale Ref'] ?? '',
                Service_Increment: storedValues.Service_Increment ?? leg.ServiceIncrement,
                timezone: leg.timezone,
                maxProfile: [],
                Preconfirmed: leg.Preconfirmed === 'Yes',
            }
            return acc;
        }, {})

        return {
            timezone: data[0].timezone,
            scheduleDateTime: scheduleDateTime,
            action: 'reservefuture',
            legs: legData,
        }
    }, [data]);

    return (
        <MultiLegTransmissionDialog
            handleClose={handleClose}
            defaultData={defaults}
            open={open}
            handleSave={handleUpdate}
            storageKey={storageKey}
            disableChangeAction
        />
    )
}