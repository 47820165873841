import { useEffect, useState, } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { userGroups } from "../authConfig";
import DivGuard from "../components/Guards/DivGuard";
import { FastPath, TSRActivity } from '.';
import Collapse from '@mui/material/Collapse';
import FastPathMenu from '../components/FastPathPro/FastPathMenu';
import FastPathPro from '../components/FastPathPro/FastPath';
import Help from '../components/Help';
import { Box } from '@mui/material';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';
import TariffPrices from '../components/TransNow/TariffPrices';
import { useParams } from 'react-router';
import FlexCollapse from '../components/FlexCollapse';

const viewStorageKey = 'fast-path-pro-view';

export const FastPathView = () => {
    const savedView = localStorage.getItem(viewStorageKey);
    const params = useParams();
    const view = params.view;
    const navigate = useNavigate();

    useEffect(() => {
        if (!view) {
            const newView = savedView ?? 'fastpath';
            navigate(`/fastpath/${newView}`);
        }
    }, [view, savedView, navigate]);

    return (
        <CriteriaProvider>
            <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden', }}>
                <FastPathMenu selected={view} />
                <Outlet />
            </Box>
        </CriteriaProvider>
    )
}

export const FastPathViews = () => {
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    return (
        <Box
            sx={{
                p: '0px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'auto',
            }}
        >
            {loadedViews['fastpathhelp'] && <Collapse in={view === 'fastpathhelp'}>
                <Help appName={'FastPath'} />
            </Collapse>}
            {(loadedViews['fastpath'] || loadedViews['dashboard']) && <Collapse in={['fastpath', 'dashboard'].includes(view)}>
                <FastPathPro view={view} />
            </Collapse>}
            {loadedViews['activity'] && <FlexCollapse in={view === 'activity'}>
                <TSRActivity visible={view === 'activity'} application='fastpathpro' />
            </FlexCollapse>}
            {loadedViews['legacy'] && <Collapse in={view === 'legacy'}>
                <DivGuard groups={[userGroups.admins]}>
                    <FastPath />
                </DivGuard>
            </Collapse>}
            {loadedViews['audit'] && <Collapse in={view === 'audit'}>
                <TransHunterAuditGrid />
            </Collapse>}
            {loadedViews['transactionqueue'] && <Collapse in={view === 'transactionqueue'}>
                <PathMonitor application={'fastpathpro'} />
            </Collapse>}
            {loadedViews['tariffprices'] && <Collapse in={view === 'tariffprices'} >
                <DivGuard groups={[userGroups.fastpathpro]} >
                    <TariffPrices />
                </DivGuard>
            </Collapse>}

        </Box>
    )
}
