import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

export const ProfileSettings = ({profile, settings, handleSettingsSave}) => {
    const [defaultTimezone, setDefaultTimezone] = useState();

    useEffect(() => {
      const newSettings = {
        defaultTimezone: defaultTimezone,
      };
      handleSettingsSave(newSettings);
    }, [])
    
    return(
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Default TimeZone</InputLabel>
            <Select
              defaultValue={'PST'}
              label='Default TimeZone'
              fullWidth
              autoWidth={false}
              value={defaultTimezone}
              onChange={(event) => setDefaultTimezone(event.target.value)}>
                <MenuItem value={'PST'}>PST</MenuItem>
                <MenuItem value={'MST'}>MST</MenuItem>
                <MenuItem value={'CST'}>CST</MenuItem>
                <MenuItem value={'EST'}>EST</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };