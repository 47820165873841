import { memo, useState } from "react";
import { Drawer } from "../StyledDrawer";
import { List, Tooltip, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import InsightsIcon from '@mui/icons-material/Insights';
import StormIcon from '@mui/icons-material/Storm';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SpeedIcon from '@mui/icons-material/Speed';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import GroupGuard from "../Guards/GroupGuard";
import { userGroups } from "../../authConfig";
import BuildIcon from '@mui/icons-material/Build';

const Menu = (props) => {
    const { setSelected, selected } = props;
    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <Drawer variant="permanent" open={openDrawer} onMouseEnter={() => setOpenDrawer(true)} onMouseLeave={() => setOpenDrawer(false)} sx={{ display: 'inline-block' }}>
            <List sx={{ display: 'inline-block' }}>
                <Tooltip title="View the network graph of different brains." arrow placement="right">
                    <ListItemButton
                        selected={selected === 'braintopology'}
                        onClick={() => setSelected('braintopology')}>
                        <ListItemIcon>
                            <InsightsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Brain Topology" />
                    </ListItemButton>
                </Tooltip>
                <GroupGuard groups={[userGroups.brain]}>
                    <Tooltip title="View statistics for different brain inputs." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'correlations'}
                            onClick={() => setSelected('correlations')}>
                            <ListItemIcon>
                                <SsidChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Correlations" />
                        </ListItemButton>
                    </Tooltip>
                </GroupGuard>
                <GroupGuard groups={[userGroups.brain]}>
                    <Tooltip title="Experiment with your brain weights." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'adjustments'}
                            onClick={() => setSelected('adjustments')}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary="Adjustments" />
                        </ListItemButton>
                    </Tooltip>
                </GroupGuard>
                <Tooltip title="View the weather forecast." arrow placement="right">
                    <ListItemButton
                        selected={selected === 'forecast'}
                        onClick={() => setSelected('forecast')}>
                        <ListItemIcon>
                            <StormIcon />
                        </ListItemIcon>
                        <ListItemText primary="Weather Forecast" />
                    </ListItemButton>
                </Tooltip>
                <ListItemButton
                    selected={selected === 'lmps'}
                    onClick={() => setSelected('lmps')}>
                    <ListItemIcon>
                        <PriceCheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="LMP Heatmap" />
                </ListItemButton>
                <ListItemButton
                    selected={selected === 'brainstatus'}
                    onClick={() => setSelected('brainstatus')}>
                    <ListItemIcon>
                        <SpeedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Brain Status" />
                </ListItemButton>
            </List>
        </Drawer>
    )
}

export default memo(Menu);