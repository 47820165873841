import { Button, Box, Dialog, Slide, Typography, IconButton, } from '@mui/material'
import { useMemo, forwardRef, } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AddNewOrUpdateForm } from './AddNewOrUpdateForm';
import CloseIcon from '@mui/icons-material/Close';

export const AddNewOrUpdateDialog = (props) => {
    const { open, handleCancel, handleAddNew, viewToUpdate, allViewLabels, } = props;

    const defaultValues = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...viewToUpdate,
        shared: !!viewToUpdate.shared ?? false,
    }), [viewToUpdate]);

    return (
        <Dialog maxWidth="md" fullWidth open={open} TransitionComponent={Transition} id='scheduling-add-new-or-update-dialog'>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{viewToUpdate.label ? 'Edit View Filters' : 'Add New View'}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter a name for the view and the default filter criteria. If you are updating a view, the grid layout and filters will also be saved.
                </DialogContentText>
                <AddNewOrUpdateForm handleAddNew={handleAddNew} viewToUpdate={viewToUpdate} defaultValues={defaultValues} allViewLabels={allViewLabels} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" color="primary" type="submit" form='deal-rizz-tag-report-add-or-update-form'>{viewToUpdate?.label ? 'Update' : 'Add New'}</Button>
            </DialogActions>
        </Dialog>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});