import { useEffect, useState } from 'react';
import { UserGuidContext } from './userGuidContext'
import { useMsal } from '@azure/msal-react';

export function UserGuidProvider({ children }) {
    const [guid, setGuid] = useState();
    const {accounts} = useMsal();
    
    useEffect(() => {
        if(accounts[0]) {
            const id = accounts[0].localAccountId;
            setGuid(id);
        } else {
          setGuid('');
        }
    }, [accounts]);
 
    return (
      <UserGuidContext.Provider value={guid}>
        {children}
      </UserGuidContext.Provider>
    )
}