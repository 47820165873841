import { useRef, useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import EmailEditor from 'react-email-editor';
import sample from '../Settings/sample.json';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem, TextField, } from "@mui/material";
import Button from '@mui/material/Button';
import useHeader from '../useHeader';
import { styled } from '@mui/material/styles';
  
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
}));

const Bar = styled('div')(({ theme }) => ({
  flex: 1,
  padding: 10,
  display: 'flex',
  maxHeight: 60,
  h1: {
    flex: 1,
    fontSize: 16,
    textAlign: 'left',
  },
  div: {
    width: 200,
  },
  button: {
    flex: 1,
    padding: 10,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    border: 0,
    maxWidth: 150,
    cursor: 'pointer',
  }
}));

const EmailComposer = (props) => {
  const emailEditorRef = useRef(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('Sample');
  const [templateDesign, setTemplateDesign] = useState(sample);
  const [templateHtml, setTemplateHtml] = useState('');
  const header = useHeader();
  
  const {enqueueSnackbar} = useSnackbar();
  
  const guid = useLocalGuid();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  useEffect(() => {
    
    //loadTemplates();

    let abortController = new AbortController();  
    // your async action is here  
    loadTemplates();
    return () => {  
      abortController.abort();  
    }  

  }, []);

  async function loadTemplates() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_EmailTemplatesFetch&parm=${guid}`
    //const loadUserTemplates = url + templateTypeId;
    let options = {
      headers: header,
      method: 'GET',
      url: url, 
    };

    //enqueueSnackbar('loading templates');
    const emailTemps = await axios(options).catch(err => { enqueueSnackbar(`Error loading groups.  URL: ${url} Error:  ${err}`) });

    if(emailTemps.data) {
      setEmailTemplates(emailTemps.data ?? []);
    }

    //loadTemplates();

  };

  const saveDesign = () => {
    // emailEditorRef.current.editor.saveDesign((design) => {
    //   saveEmailTemplateJson(design);
    //   setTemplateDesign(design);
    // });

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      
      saveEmailTemplateJson(design);
      saveEmailTemplateHtml(html);
      setTemplateHtml(html);
      setTemplateDesign(design);

    });

    loadTemplates();
    loadTemplates();

  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setTemplateHtml(html);
      setTemplateDesign(design);
      navigator.clipboard.writeText(html)
      enqueueSnackbar('HTML copied to clipboard...');
      //saveEmailTemplateHtml(html);
    });


  };

  // const onDesignLoad = (data) => {
    
  //   //console.log('onDesignLoad', data);

  // };

  const onLoad = () => {
    //console.log('onLoad');

    // emailEditorRef.current.editor.addEventListener(
    //   'design:loaded',
    //   onDesignLoad
    // );

    if (emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(templateDesign);

      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        setTemplateHtml(html);
      });
    }

  }

  async function saveEmailTemplateHtml(html) {

    let message = 'Submitting email template HTML...';
    enqueueSnackbar(message);
  
    message = 'Email template HTML sent to power station database...';
    
    //setTemplateName('');
    
    const response = await axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_EmailTemplateHtmlSave&parm=${guid}&parm=${templateName}`,
      JSON.stringify(html),
      {
        headers: header,
      }
    ).catch(err => function() { message = `Error saving email template html... Status: ${err.response?.status}. Message: ${err}`});

    enqueueSnackbar(message);
    
  }

  async function saveEmailTemplateJson(design) {

    let message = 'Submitting email template JSON...';
    enqueueSnackbar(message);
  
    message = 'Email template JSON sent to power station database...';
    
    //setTemplateName('');
    
    const response = await axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_EmailTemplateJsonSave&parm=${guid}&parm=${templateName}`,
      JSON.stringify(design),
      {
        headers: header,
      }
    ).catch(err => function() { message = `Error saving email template json... Status: ${err.response?.status}. Message: ${err}`});

    enqueueSnackbar(message);
    
  }

  function handleTemplateChange(temp) {
    setTemplateDesign(temp.json);
    setTemplateName(temp.name);
    emailEditorRef.current.editor.loadDesign(JSON.parse(temp.json));
  }

  function handleTemplateNameChange(event) {
    
    setTemplateName(event.target.value);

    emailEditorRef.current.editor.saveDesign((design) => {
      setTemplateDesign(design);
    });
    
  }

  const onReady = () => {
    console.log('onReady');
  };

  return (
    <div style={{
      height: '90vh', 
      width: "98%"}}>
    <Container sx={{height: '100%'}}>
      <Bar>
        <h1>PSP Email Composer</h1>
        <Tooltip title="Select an email template." arrow placement="top">
          {/* <InputLabel id="emailTemplateLabel">Template</InputLabel> */}
          <Select
            labelId="emailTemplateLabel"
            id="emailtemplate"
            width="100px"
            defaultValue={templateName}
            size={buttonSize}
            color={buttonColor}
            label="Saved Templates"
          >
            {emailTemplates.map(temp => 
              <MenuItem onClick={() => handleTemplateChange(temp)}>{temp.name}</MenuItem>
            )}
            <MenuItem onClick={() => handleTemplateChange({name: 'Sample', json: JSON.stringify(sample)})}>Sample</MenuItem>
          </Select>
        </Tooltip>&nbsp;&nbsp;
        <Tooltip title="Select an email template." arrow placement="top">
          <TextField
            id="emailTemplateName"
            width="100px"
            value={templateName}
            size={buttonSize}
            onChange={handleTemplateNameChange}
            color={buttonColor}
            label="Email Template"
          />
        </Tooltip>&nbsp;
        <Button onClick={saveDesign}>Save Design</Button>
        <Button onClick={exportHtml}>Export HTML</Button>
      </Bar>

      {/* <React.StrictMode> */}
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      {/* </React.StrictMode> */}
    </Container>
    </div>
  );
};

export default EmailComposer;
