import { useMsal } from "@azure/msal-react";
import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useState, useEffect } from "react";
import axios from "axios";
import {useSnackbar} from 'notistack';
import { apiUrlPrefix, subscriptionKey } from "../../authConfig";
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import useHeader from "../useHeader";

export default function RowDataTemplatePanel({addGridRows, transmissionTemplateTypeId}) {
  //const { accounts } = useMsal();
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateFetch&parm=${guid}&parm=${transmissionTemplateTypeId}`;
  const [templates, setTemplates] = useState([]);

  const header = useHeader();

  useEffect(() => {
    let abortController = new AbortController();  
    loadTemplates();
    return () => {  
      abortController.abort();  
    }  
  }, []);

  async function loadTemplates() {
    const options = {
      method: 'GET',
      headers: header,
      url: loadUri,
    }
    
    const response = await axios(options).catch(err => { enqueueSnackbar(`Error loading templates in rowDataTemplatePanel.  ${err}`) });
    setTemplates(response ? response.data : []);
  }

  function handleAddTemplateRowClick(e) {
    const tempName = e.target.textContent;
    const stringData = templates.find(temp => temp.name === tempName).json;
    const rowData = JSON.parse(stringData);
    
    addGridRows(rowData);
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return(
    <TableContainer component={Paper}>
    <Table sx={{ width:450 }} size="small" aria-label="customized table">
      {/*<colgroup>
        <col style={{width:'25%'}}/>
        <col style={{width:'70%'}}/>
      </colgroup>*/}
      <TableHead>
        <StyledTableRow>
          <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">Templates</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {templates.map((template) =>
          <StyledTableRow>
            <StyledTableCell sx={{ fontWeight: 'light' }} onClick={handleAddTemplateRowClick} component="th" scope="row">
              {template.name}
            </StyledTableCell>
          </StyledTableRow>
        )}
      </TableBody>
      </Table>
    </TableContainer>
  );
};