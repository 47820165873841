import { useParams } from 'react-router';
import { Box, Collapse } from '@mui/material';
import { useDashboard } from './useDashboard';

export const DashboardViewContainer = ({ ViewComponent, }) => {
    const { views, dashboardKey, viewsRef, } = useDashboard();
    const params = useParams();
    const currentViewId = params.id;

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            {views.map((view, i) => {
                return (
                    <Collapse
                        in={currentViewId === view.id.toString()}
                        orientation={'vertical'}
                        key={`${view.id}-${dashboardKey}-view`}
                    >
                        <ViewComponent
                            view={view}
                            ref={el => viewsRef.current[i] = el}
                        />
                    </Collapse>
                )
            })}
        </Box>
    );
}
