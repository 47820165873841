import React from "react";
//import useState from "react";
//import { useSpyMonkeyStyles } from "../styles";
//import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import ComponentGuard from '../components/Guards/ComponentGuard.js';
import { userGroups } from "../authConfig";

//components
import BulkOriginalRequests from "../components/TransNow/BulkOriginalRequests";

function BulkOriginalRequestsView() {
  //const classes = useSpyMonkeyStyles();

  return (
    <ComponentGuard groups={[userGroups.BulkOriginalRequestsView]} >
      {/* <div style={{
        height: 800, 
        width: "100%",
      }}> */}
        <Box sx={{ p: 1, display: 'flex' }}>
          <BulkOriginalRequests />
        </Box>
      {/* </div> */}
    </ComponentGuard>
    );

}

export default BulkOriginalRequestsView;