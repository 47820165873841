import { UserContext } from "../data/fetchUserGroups"
import { useContext } from "react"

export default (groups) => {
    const userGroups = useContext(UserContext);

    function filter(defs) {
        return defs.filter(def => !def.protected || userGroups.find(grp => groups.includes(grp)));
    }

    return { 
        filter, 
    }
}