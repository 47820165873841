import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

//const FileUploaderDialog = ({ fileUploaderOpen, handleFileUploaderDialogClose }) => {
const AddBidLimitDialog = ({ addBidLimitDialogOpen, handleAddBidLimitDialogClose, handleAddBidLimitDialogCancel, startValue, stopValue, setStartValue, setStopValue }) => {

  const { enqueueSnackbar } = useSnackbar();

  const handleSaveAndClose = () => {
    //put logic here to update api

    handleAddBidLimitDialogClose(false);
    const message = 'Adding bid limit.';
    enqueueSnackbar(message);

  };

  const handleAddBidLimitCancel = () => {
    //put logic here to update api

    handleAddBidLimitDialogCancel(false);
    const message = 'Cancelled';
    enqueueSnackbar(message);

  };

    return (
        <Dialog 
            open={addBidLimitDialogOpen} 
            onClose={handleAddBidLimitDialogClose}
            fullWidth
            maxWidth="lg"
          >
            <DialogTitle>Reassign Selected Rows</DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1},
                }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    required
                    width="10ch"
                    // size="small"
                    id="market"
                    label="Market"
                    // defaultValue="Hello World"
                  />
                  <TextField
                    required
                    // size="small"
                    width="10ch"
                    id="category"
                    label="Category"
                    // defaultValue="Hello World"
                  /> 
                  <TextField
                    required
                    // size="small"
                    width="10ch"
                    id="subaccount"
                    label="Subaccount"
                    // defaultValue="Hello World"
                  /> 
                  <TextField
                    required
                    // size="small"
                    width="10ch"
                    id="location"
                    label="Location"
                    // defaultValue="Hello World"
                  /> 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      id="start"
                      label="Start"
                      width="50ch"
                      // size="small"
                      value={dayjs(startValue)}
                      onChange={(newStartValue) => {
                        setStartValue(newStartValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                      id="stop"
                      label="Stop"
                      width="50ch"
                      // size="small"
                      value={stopValue}
                      onChange={(newStopValue) => {
                        setStopValue(newStopValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <TextField
                    required
                    // size="small"
                    width="10ch"
                    id="hardlimit"
                    label="Hard Limit"
                    // defaultValue="Hello World"
                  /> 
                  <TextField
                    required
                    // size="small"
                    width="10ch"
                    id="softlimit"
                    label="Soft Limit"
                    // defaultValue="Hello World"
                  /> 
                </div>
              </Box>
              </DialogContent>
            <DialogActions>
              <Button onClick={handleAddBidLimitCancel}>Cancel</Button>
              <Button onClick={handleSaveAndClose}>Save</Button>
            </DialogActions>
          </Dialog>
    )
}

export default AddBidLimitDialog;

