import { IconButton, Typography, Box, Tooltip, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from '@mui/material'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useMemo, useState, } from 'react';
import { apiUrlPrefix } from '../../../authConfig';
import axios from 'axios';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';
import Close from '@mui/icons-material/Close';

export default (props) => {
    const { open, message, onConfirmation, onCancel, cancelText = 'NO', confirmText = 'YES', title = 'Are you sure?', brainName } = props;
    const [allowTraining, setAllowTraining] = useState(false);
    const [notAllowedText, setNotAllowedText] = useState('');
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        open && confirmAllowTraining();
    }, [open]);

    const schema = yup.object().shape({
        beta: yup.number().test(
            'is-decimal',
            'Beta must be a decimal value.',
            value => value.toString().match(/^\d+(\.\d+)?$/),
        ).required('Beta is required.').typeError('Beta must be a number.'),
    });

    const defaultValues = useMemo(() => ({
        beta: 10.0
    }), []);

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    function confirmAllowTraining() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=trainBrainConfirmationCheck&parm=${headers.userGuid}&parm=${brainName}`;

        axios.get(url, { headers: headers }).then(response => {
            setAllowTraining(!!response.data[0]?.allowed);
            setNotAllowedText(response.data[0]?.userMessage);
        }).catch((error) => {
            enqueueSnackbar(`Error fetching training confirmation: ${error}`, { variant: 'error', });
        });
    }

    return (
        <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle id="alert-dialog-title">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{title}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={onCancel} size='large'>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent id="alert-dialog-description">
                <DialogContentText id="alert-dialog-description">
                    {allowTraining ? message : notAllowedText}
                </DialogContentText>
                <Box component='form' id='training-confirmation-dialog-form' onSubmit={handleSubmit(onConfirmation)} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Higher values help prevent overfitting of the model, but underfitting can happen with very large values You may want to experiment with different values.  Start with 0.0, check the training error versus the testing error, and raise beta slowly until the error is similar (e.g. 0.0, .01, .1, 1.0, 10, 100, 1000)." arrow>
                        <TextField
                            label="Beta"
                            maxWidth='sm'
                            variant="outlined"
                            margin="normal"
                            size="small"
                            disabled={!allowTraining}
                            {...register('beta')}
                            error={!!errors.beta}
                            helperText={errors.beta?.message}
                        />
                    </Tooltip>
                </Box>
                <DialogActions>
                    <Button color="inherit" size="small" onClick={onCancel}>
                        {cancelText}
                    </Button>&nbsp;&nbsp;
                    <Button color="inherit" size="small" type='submit' form='training-confirmation-dialog-form' disabled={!allowTraining}>
                        {confirmText}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}