import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef, useState, useCallback } from "react";
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps";
import dayjs from "dayjs";
import { useApi } from "../../useApi";
import { styled } from '@mui/material/styles';
import { useColumnTypes } from "../../AgGrid/useColumnTypes";
import { useTheme } from "@mui/material";
import { columnPanel } from "../../ToolPanels/DefaultToolPanels";
import { useFormContext } from "react-hook-form";
import { DetailCellRenderer } from "./DetailCellRenderer";
import { useGridCrossHighlight } from "../useGridCrossHighlight";
import { isLongDay } from "../Utils";
import useHubObject from "../../HubContext/useHubObjectNoDebounce"
import { useUserInfo } from "../../UserInfoContext"
import { debounce } from "lodash";

export const ProfileDetailRenderer = (props) => {
    const { data, node, api, setSelectedAdjustment, getDetailData, setDetailData, setDetailEdited, getRowId, } = props;
    const { get, headers, post, enqueueSnackbar, apiUrlPrefix, } = useApi();
    const rowId = getRowId({ data, });

    const detailData = getDetailData(node.id) ?? {};

    const [transData, setTransData] = useState(detailData?.transData ?? []);
    const [adjustmentData, setAdjustmentData] = useState(detailData?.adjustmentData ?? []);
    const adjustmentsGridRef = useRef();
    const transGridRef = useRef();
    const { columnTypes } = useColumnTypes({ dateFormat: 'MM/DD/YYYY' });
    const theme = useTheme();
    const tagId = data.tagIdx;
    const { watch } = useFormContext();
    const timezone = watch('timezone');
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');
    const { highlightingCellClassRules: transHighlightingCellClassRules, clearHighlighting: clearTransGridHighlighting, handleCellMouseOverHighlight: handleTransCellHighlight, } = useGridCrossHighlight(transGridRef);
    const { highlightingCellClassRules: adjHighlightingCellClassRules, clearHighlighting: clearAdjGridHighlighting, handleCellMouseOverHighlight: handleAdjCellHighlight, } = useGridCrossHighlight(adjustmentsGridRef);
    const userInfo = useUserInfo();

    function silentUpdate() {
        fetchTransmissionProfiles();
        fetchAdjustments();
        transGridRef.current?.api?.refreshCells({ force: true, });
        adjustmentsGridRef.current?.api?.refreshCells({ force: true, });
    }

    useHubObject({
        action: silentUpdate,
        allowedMessages: ['tagUpdate', 'tagTxUpdate'],
        predicate: (obj) => {
            return (obj.tenantID?.toString() === userInfo?.tenantId?.toString())
                && obj.tagIdx?.toString() === tagId?.toString();
            //TODO: check obj dates against the grid's date range
        },
    });

    async function fetchTransmissionProfiles() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_transAllocForTag_v5`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${tagId}`;
        return get(url).then(response => {
            const newData = response?.data ?? [];
            const formattedData = newData.map(row => {
                const newRow = { ...row };
                Array.from({ length: 26 }, (_, i) => i + 1).forEach(i => {
                    newRow[i] = JSON.parse(row[i]);

                    if (i === 2) {
                        newRow['2*'] = JSON.parse(row['2*']);
                    }
                });
                return newRow;
            });
            setTransData(formattedData);
            setDetailData(node.id, { ...detailData, transData: formattedData });
        }).finally(() => {
            transGridRef.current?.api?.hideOverlay();
            toggleExtraHourColBasedOnDate(transGridRef);
        });
    }

    async function fetchAdjustments() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_adjsForTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${tagId}`;
        return get(url).then(response => {
            setAdjustmentData(response?.data ?? []);
            setDetailData(node.id, { ...detailData, adjustmentData: response?.data ?? [] });
        }).finally(() => {
            adjustmentsGridRef.current?.api?.hideOverlay();
            toggleExtraHourColBasedOnDate(adjustmentsGridRef);
        });
    }

    function toggleExtraHourColBasedOnDate(ref) {
        let start = dayjs(startDate).startOf('day');
        const end = dayjs(stopDate).startOf('day');
        let longDayInRange = false;

        do {
            const isLong = isLongDay(start);
            if (isLong) {
                longDayInRange = true;
                break;
            }
            start = start.add(1, 'day');
        } while (start.isSameOrBefore(end, 'day'));

        ref.current?.columnApi?.setColumnVisible('2*', longDayInRange);
    }

    const adjustmentColDefs = useMemo(() => ([
        { field: 'RequestId', headerName: 'Request ID', checkboxSelection: true, },
        { field: 'Date', type: 'dateColumn', },
        { field: 'RequestTimeStamp' },
        { field: 'Entity' },
        { field: 'Contact' },
        { field: 'Phaone' },
        { field: 'RequestStatus', headerName: 'Status', },


        // ProfileType
        // TimeClassification
        // ActOnByTime
        // TerminateTag
        // RequestorEntityCode
        // RequestorEntityType
        // Fax
        // Notes

        ...Array.from({ length: 26 }, (_, i) => ({
            field: `${i + 1}`,
            enableRowGroup: false,
            minWidth: 75,
            chartDataType: 'series',
            type: 'numericColumn',
        })),
    ]), []);

    const defaultTransHourColDefs = useCallback((i) => ({
        enableRowGroup: false,
        minWidth: 75,
        chartDataType: 'series',
        type: 'numericColumn',
        cellDataType: 'number',
        headerName: `${i}`,
        colId: `${i}`,
        editable: params => {
            const cellData = params.data?.[i];
            const submitted = cellData?.status === 'submitted';
            return !submitted;
        },
        valueSetter: params => {
            const data = params.data?.[i];
            data.value = params.newValue;
            if (params.oldValue !== params.newValue) {
                data.status = 'edited';
            }
            return true;
        },
        valueGetter: params => {
            return params.data?.[i]?.value;
        },
        cellStyle: params => {
            const wasEdited = params.data?.[i]?.status === 'edited';
            const borderStyles = wasEdited ? { border: '1px dashed #727272' } : {};
            return {
                paddingLeft: '0px',
                ...borderStyles,
            }
        },
        cellRenderer: 'detailCellRenderer',
        cellRendererParams: {
            hour: i,
        },
    }), []);

    const transProfileColDefs = useMemo(() => ([
        { field: 'transmissionAllocationId', headerName: 'Id', },
        { field: 'tagIdx', headerName: 'Tag Index', initialHide: true, },
        { field: 'contract', headerName: 'Contract' },
        { field: 'POR' },
        { field: 'POD' },
        { field: 'Type', },
        { field: 'Date', type: 'dateColumn', },
        { field: 'TP' },
        { field: 'product', headerName: 'Product' },
        { field: 'transCust', headerName: 'Trans Cust' },
        ...Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(defaultTransHourColDefs(i));

            if (i === 2) {
                acc.push(defaultTransHourColDefs('2*'));
            }
            return acc;
        }, []),
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        minWidth: 100,
        flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
        ]
    }), []);

    const gridOptions = useMemo(() => ({
        ...defaultGridOptions,
        defaultColDef,
        columnTypes,
        domLayout: 'autoHeight',
        rowClassRules: {
            'ag-row-draft-tsr': params => params.data.Type?.toLowerCase() === 'draft' && theme.palette.mode === 'light',
            'ag-row-draft-tsr-dark': params => params.data.Type?.toLowerCase() === 'draft' && theme.palette.mode === 'dark',
        },
    }), [defaultColDef, theme.palette.mode, columnTypes]);

    const handleTagTxUpdate = useCallback((data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_ApplyTransmissionEdits_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${tagId}`
            + `&parm=${timezone}`

        post(url, data).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar('Allocation modification saved.', { variant: 'info' });
            }
        });
    }, [headers, timezone, tagId, apiUrlPrefix, post, enqueueSnackbar]);

    const rowHasEdits = useCallback((rowData) => {
        const hasEdits = rowData.some(row => {
            return Array.from({ length: 26 }, (_, i) => i + 1).some(i => row?.[i]?.status === 'edited');
        });
        return hasEdits;
    }, []);

    const updateHasEdits = useCallback((rowData) => {
        const hasEdits = rowHasEdits(rowData);
        setDetailEdited(rowId, hasEdits);
        console.log(rowData);
        console.log(rowId, hasEdits);
    }, [rowHasEdits, setDetailEdited, rowId]);

    const handleCellValueChanged = useCallback(debounce((params) => {
        const rowData = params.api.getModel().rowsToDisplay.map(row => row.data);
        handleTagTxUpdate(rowData);
        updateHasEdits(rowData);
    }, 100), [handleTagTxUpdate, updateHasEdits]);

    const handleRowDataUpdated = useCallback((params) => {
        const rowData = params.api.getModel().rowsToDisplay.map(row => row.data);
        updateHasEdits(rowData);
    }, [updateHasEdits]);

    function handleAdjustmentSelectionChanged() {
        const selected = adjustmentsGridRef.current.api.getSelectedRows();
        setSelectedAdjustment(selected[0]);
    }

    const transGridHeightRef = useRef();
    const adjustmentsGridHeightRef = useRef();
    const transGridContainerId = 'trans-detail-grid-display-container';
    const adjustmentsGridContainerId = 'adjustments-detail-grid-display-container';
    //lock in the height of the div container to prevent resizing to fit the tool panel
    function onToolPanelVisibleChanged(containerId) {
        const ref = containerId.includes('trans') ? transGridRef : adjustmentsGridRef;
        const heightRef = containerId.includes('trans') ? transGridHeightRef : adjustmentsGridHeightRef;
        if (ref.current.api.isToolPanelShowing()) {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current + 220}px`);
            ref.current.api.setDomLayout('normal');
        } else {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current - 220}px`);
            ref.current.api.setDomLayout('autoHeight');
        }
    };

    function onGridSizeChanged(containerId) {
        const container = document.getElementById(containerId);
        const heightRef = containerId.includes('trans') ? transGridHeightRef : adjustmentsGridHeightRef;
        heightRef.current = container?.clientHeight;
    };

    const styledTransColDefs = useMemo(() => transProfileColDefs.map(colDef => ({
        ...colDef,
        cellClassRules: transHighlightingCellClassRules,
    })), [transProfileColDefs, transHighlightingCellClassRules]);

    const styledAdjColDefs = useMemo(() => adjustmentColDefs.map(colDef => ({
        ...colDef,
        cellClassRules: adjHighlightingCellClassRules,
    })), [adjustmentColDefs, adjHighlightingCellClassRules]);

    const onAdjustmentsGridReady = useCallback((params) => {
        if (!!detailData?.adjustmentData) {
            setAdjustmentData(detailData.adjustmentData);
            toggleExtraHourColBasedOnDate(adjustmentsGridRef);
        } else {
            params.api.showLoadingOverlay();
        }
        fetchAdjustments();
    }, []);

    const onTransGridReady = useCallback((params) => {
        if (!!detailData?.transData) {
            setTransData(detailData.transData);
            toggleExtraHourColBasedOnDate(transGridRef);
        } else {
            params.api.showLoadingOverlay();
        }
        fetchTransmissionProfiles();
    }, []);

    return (
        <Stack spacing={1} sx={{ p: 3, }}>
            <Accordion disableGutters >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ minHeight: '24px', }}
                >
                    <Typography sx={{ p: 0.5 }}>Adjustments</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <AgGridContainer id={adjustmentsGridContainerId} onMouseLeave={clearAdjGridHighlighting}>
                        <AgGridReact
                            {...gridOptions}
                            rowData={adjustmentData}
                            columnDefs={styledAdjColDefs}
                            ref={adjustmentsGridRef}
                            onSelectionChanged={handleAdjustmentSelectionChanged}
                            rowSelection="single"
                            rowMultiSelectWithClick
                            sideBar={sideBar}
                            onGridSizeChanged={() => onGridSizeChanged(adjustmentsGridContainerId)}
                            onToolPanelVisibleChanged={() => onToolPanelVisibleChanged(adjustmentsGridContainerId)}
                            onGridReady={onAdjustmentsGridReady}
                            onCellMouseOver={handleAdjCellHighlight}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </Accordion>

            <Accordion disableGutters defaultExpanded >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{ minHeight: '24px' }}
                >
                    <Typography sx={{ p: 0.5 }}>Transmission Allocations</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <AgGridContainer id={transGridContainerId} onMouseLeave={clearTransGridHighlighting}>
                        <AgGridReact
                            {...gridOptions}
                            rowData={transData}
                            columnDefs={styledTransColDefs}
                            ref={transGridRef}
                            onCellValueChanged={handleCellValueChanged}
                            onRowDataUpdated={handleRowDataUpdated}
                            sideBar={sideBar}
                            onGridSizeChanged={() => onGridSizeChanged(transGridContainerId)}
                            onToolPanelVisibleChanged={() => onToolPanelVisibleChanged(transGridContainerId)}
                            onGridReady={onTransGridReady}
                            onCellMouseOver={handleTransCellHighlight}
                            components={{
                                detailCellRenderer: DetailCellRenderer,
                            }}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));
