import { useSnackbar } from 'notistack';
import { DurableFunctionContext } from './durableFunctionContext';
import axios from 'axios';

export function DurableFunctionProvider({ children }) {
    const { enqueueSnackbar } = useSnackbar();
    const code = process.env.REACT_APP_DURABLE_FUNCTION_CODE;

    function defaultOnComplete(data) {
        enqueueSnackbar('Function completed successfully.', { variant: 'success' });
    }

    function defaultOnError(error) {
        enqueueSnackbar(`Error invoking function: ${error.message}`, { variant: 'error' });
    }

    const invokeFunction = async ({
        orchestrator,
        functionAppName = 'power-people-durable-functions',
        queryParameters = {},
        body,
        pollingInterval = 1000,
        onComplete = defaultOnComplete,
        onError = defaultOnError,
        onRunning,
    }) => {
        try {
            let url = `https://${functionAppName}.azurewebsites.net/api/orchestrators/${orchestrator}?code=${code}`;

            // Add query parameters to the URL
            const query = new URLSearchParams(queryParameters);
            url += `&${query.toString()}`;

            // Start the durable function; if there is a body, it is a POST request
            let statusQueryGetUri;
            if (body) {
                const response = await axios.post(url, body);
                statusQueryGetUri = response.data.statusQueryGetUri;
            } else {
                const response = await axios.get(url);
                statusQueryGetUri = response.data.statusQueryGetUri;
            }

            // Poll for the function result
            const intervalId = setInterval(async () => {
                const result = await axios.get(statusQueryGetUri);
                if (result.data.runtimeStatus === 'Completed') {
                    clearInterval(intervalId);
                    if (result?.data?.output?.error) {
                        onError(result.data.output.error);
                    } else {
                        onComplete(result.data.output);
                    }
                } else if (result.data.runtimeStatus === 'Failed') {
                    clearInterval(intervalId);
                    onError(result.data?.output?.error);
                } else if (result.data.runtimeStatus === 'Running') {
                    onRunning && onRunning(result.data);
                }
            }, pollingInterval);

            return () => clearInterval(intervalId); // Clean up on unmount
        } catch (err) {
            onError(err);
        }
    };

    const value = { invokeFunction };

    return (
        <DurableFunctionContext.Provider value={value}>
            {children}
        </DurableFunctionContext.Provider>
    )
}