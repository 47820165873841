import { useState, useEffect, useMemo } from "react";
import { columnPanel, filterPanel } from "../ToolPanels/DefaultToolPanels";
import '../../styles/fastPathStyles.css';
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../useActionAudit";
import { Box } from '@mui/material';
import DetailCellRenderer from './fastPathRowDetailRenderer';
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { CalendarToday } from "@mui/icons-material";

export default (props) => {
  const { logAction } = useActionAudit();
  const { lookupResults, lookupInputVals } = props;
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(lookupResults);
    logAction(`Generated results from a fast path query`, 'fastPathDisplayResults');
  }, [lookupResults])

  const {
    date,
    por,
    pod,
    startHE,
    endHE,
    // minCapacity, //not displayed
    // singleSeg
  } = lookupInputVals;

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({ ...props, });
  }, []);

  function onRowClicked(params) {
    //toggle detail grid
    params.node.setExpanded(!params.node.expanded)
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED'
    },
  };

  // all rows assigned CSS class 'default-class'
  const rowClass = 'default-class';

  // all even rows assigned 'my-shaded-effect'
  const getRowClass = params => {
    if (params.node.rowIndex % 2 === 0) {
      return 'my-shaded-effect';
    }
  };

  const colDefs = useMemo(() => [
    {
      headerName: 'Path',
      field: 'pathName',
      flex: 3,
      cellRenderer: 'agGroupCellRenderer'
    },
    {
      headerName: 'Total Cost',
      field: 'totalCost',
      flex: 1,
      valueFormatter: params => `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(params.value)}`
    }
  ], [])

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ]
  }), []);



  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  return (
    <Box sx={{ p: 2, flexGrow: 1, }}>
      <AgGridContainer
        style={{
          width: "98%", height: '85vh'
        }}
      >
        <div className={'searchDetails'}>
          <CalendarToday fontSize="small" />
          <p />
          <p>Date: {date}</p>
          <p>POR: {por?.value}</p>
          <p>
            POD: {pod?.value}
          </p>
          <p>HE Range: {`${startHE} - ${endHE}`}</p>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          // rowClass={rowClass}
          getRowClass={getRowClass}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          overlayNoRowsTemplate={'<span>Fetch routes to see data here.</span>'}
          animateRows={true}
          masterDetail={true}
          detailRowAutoHeight
          onRowClicked={onRowClicked}
          detailCellRenderer={'detailCellRenderer'}
          components={{
            detailCellRenderer: detailCellRenderer,
          }}
        />
      </AgGridContainer>
    </Box>
  )
}