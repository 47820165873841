import { useEffect, useState, useRef, forwardRef } from 'react';
import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, TextField, Button, Checkbox } from "@mui/material";
import { StyledTableCell, StyledTableRow } from '../StyledTableComps';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function GridViewToolPanel(props) {
    const { saveView, updateView, deleteView, getViews } = props;
    const [viewName, setViewName] = useState();
    const [invalidName, setInvalidName] = useState(false);
    const [selected, setSelected] = useState([]);

    function updateName(e) {
      setViewName(e.target.value);
    }
  
    function handleSaveViewClick() {
      if(!viewName) {
        setInvalidName(true);
        return;
      }
      saveView(viewName);
    }

    function deleteSelected() {
      selected.forEach(deleteView);
      //props.api.refreshToolPanel('gridViewToolPanel')
      getViews();
    }

    function updateSelected(event, name) {
      const checked = event.target.checked;
      if(checked) { //add selected template
        setSelected([name, ...selected]);
      } else { //remove selected template
        setSelected(selected.filter(template => template !== name));
      }
    }
  
    return(
      <div className="template-table" >
        <TextField
          size="small"
          value={viewName}
          onChange={updateName}
          variant='outlined'
          label="Name"
          error={invalidName}
          helperText={'Name can not be blank.'}/>
        <Button size="small" variant='outlined' fullWidth onClick={handleSaveViewClick} sx={{fontSize: 12}}>Save Current Layout</Button>&nbsp;
        <Button size="small" variant='outlined' fullWidth onClick={() => updateView(undefined)} sx={{fontSize: 12}}>Restore Default</Button>&nbsp;
        <TableContainer sx={{p:1}} component={Paper}>

          <Table sx={{ p:0, width:'100%' }} size="small" aria-label="customized table">
            <TableBody>
              {props.views?.map((view) => 
                <StyledTableRow key={view.userLayoutID}>
                  <StyledTableCell align="left" padding='checkbox' size='small'>
                    <Checkbox onChange={(e) => updateSelected(e, view.template)}/>
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 'light' }} align="left" padding='none' onClick={() => updateView(JSON.parse(view.json))}>{view.template}</StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>&nbsp;
        <Button size="small" variant='outlined' fullWidth onClick={deleteSelected} endIcon={<DeleteForeverIcon />} sx={{fontSize: 12}}>Delete Selected</Button>&nbsp;
      </div>
    );
  };