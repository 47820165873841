import { DashboardLazy } from "../Dashboard/DashboardLazy";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { IndexPricingView } from "./IndexPricingView";

export const IndexPricingDashboard = ({ visible, }) => {
	const dashboardKey = 'IndexPricing';
	const useShared = true;

	return (
		<DashboardLazy
			dashboardKey={dashboardKey}
			useShared={useShared}
			ViewComponent={IndexPricingView}
			AddNewOrUpdateDialog={AddNewOrUpdateDialog}
			visible={visible}
		/>
	);
}
