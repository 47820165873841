import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { userGroups } from '../authConfig';
import FlexCollapse from '../components/FlexCollapse';
import GroupGuard from '../components/Guards/GroupGuard';
import HelpComposer from '../components/HelpComposer';
import AISupportTickets from '../components/PowerPeople/AISupportTickets';
import { ChatbotManagement } from '../components/PowerPeople/ChatbotManagement';
import DataAnalysis from '../components/PowerPeople/DataAnalysis/DataAnalysis';
import DBUtility from '../components/PowerPeople/DBUtility';
import EmailComposer from '../components/PowerPeople/EmailComposer';
import PowerPeopleAppMenu from '../components/PowerPeople/PowerPeopleAppMenu';
import Requirements from '../components/PowerPeople/Requirements';
import SupportRequests from '../components/PowerPeople/SupportRequests';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import { useUserGroups } from '../data/useUserGroups';

const PowerPeople = () => {
    const { userIsInGroup } = useUserGroups();
    const savedView = localStorage.getItem('powerPeopleView');

    let defaultView = '';
    if (userIsInGroup(userGroups.support)) {
        defaultView = 'support';
    } else if (userIsInGroup(userGroups.helpcomposer)) {
        defaultView = 'helpcomposer';
    } else if (userIsInGroup(userGroups.requirements)) {
        defaultView = 'requirements';
    } else if (userIsInGroup(userGroups.dataanalysis)) {
        defaultView = 'dataanalysis';
    }

    const [view, setView] = useState(savedView || defaultView);
    const [loadedViews, setLoadedViews] = useState({});

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    const handleSelection = (viewName) => {
        localStorage.setItem('powerPeopleView', viewName);
        setView(viewName);
    };

    const MenuOptions = [
        /*{
          name: 'sandbox',
          component: <Sandbox />
        },*/
        {
            name: 'dataanalysis',
            component: <DataAnalysis />
        },
        {
            name: 'apiaudit',
            component: <TransHunterAuditGrid />
        },
        {
            name: 'emailcomposer',
            component: <EmailComposer />
        },
        {
            name: 'dbutility',
            component: <DBUtility />
        },
        {
            name: 'support',
            component: <SupportRequests visible={view === 'support'} />
        },
        {
            name: 'requirements',
            component: <Requirements />
        },
        {
            name: 'helpcomposer',
            component: <GroupGuard groups={[userGroups.helpcomposer]}>
                <HelpComposer />
            </GroupGuard>
        },
        {
            name: 'chatbot',
            component: <ChatbotManagement />
        },
        {
            name: 'aiSupport',
            component: <AISupportTickets />
        }
    ]

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <PowerPeopleAppMenu handleSelection={handleSelection} selected={view} />
            <Box
                className="flex-column"
                sx={{
                    p: '6px',
                }}
            >
                {MenuOptions.map(option =>
                    loadedViews[option.name] && <FlexCollapse in={view === option.name}>
                        {option.component}
                    </FlexCollapse>
                )}
            </Box>
        </Box>
    );
}

export default PowerPeople;
