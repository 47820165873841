import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from "react";

export const PriceEditor = forwardRef((props, ref) => {
    const { segments, defaultValue } = props;
    const [prices, setPrices] = useState(props.value ?? defaultValue);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return prices;
            },
        };
    });

    function handleUpdate(key, value) {
        setPrices({
            ...prices,
            [key]: value
        });
    }

    return(
        <Box sx={{ display: 'flex '}}>
          <Grid container spacing={2} alignItems='center' sx={{ p: 1, maxWidth: 400 }}>
            {segments.map((segment, idx) => 
                <><Grid item xs={8}>
                    <Typography>{`${segment.PointOfReceipt} -> ${segment.PointOfDelivery}`}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        defaultValue={prices[idx+1] ?? segment.OfferPrice}
                        onChange={e => handleUpdate(idx+1, e.target.value)}
                    />
                </Grid></>
            )}
          </Grid>
        </Box>
    );
});