import { useState } from "react";
import { RibbonButton } from "../Ribbon";
import { Tooltip } from "@mui/material";
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import { ResupplyTagDialog } from "../TagReport/ResupplyTagDialog/ResupplyTagDialog";

export const ResupplyTagButton = ({ expanded, disabled, selectedTag, ...buttonProps }) => {
    const [openResupplyDialog, setOpenResupplyDialog] = useState(false);

    return (
        <>
            <ResupplyTagDialog
                open={openResupplyDialog}
                onClose={() => setOpenResupplyDialog(false)}
                selectedTag={selectedTag}
            />
            <Tooltip title="Find transmission to resupply curtailed tags.">
                <span>
                    <RibbonButton
                        onMouseDown={() => setOpenResupplyDialog(true)}
                        icon={<FindReplaceIcon fontSize='small' />}
                        label={'Resupply'}
                        disabled={disabled}
                        expanded={expanded}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}