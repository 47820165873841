import { useForm } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    newValue: yup.string().required('A new value is required'),
});

export const CleanUpLookupFieldDialog = ({ open, onClose, onCleanUp, lookupData, }) => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        onCleanUp({
            ...lookupData,
            ...data,
        });
        reset();
        onClose();
    }

    return (
        <Dialog open={!!open} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                Clean Up Lookup Field - {lookupData?.lookupField}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <TextField
                        {...register('newValue')}
                        autoFocus
                        margin="dense"
                        label="New Value"
                        fullWidth
                        error={!!errors.newValue}
                        helperText={errors.newValue?.message ?? `Old Value: ${lookupData?.value}`}
                    />

                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Button color="inherit" size="small" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="inherit" size="small" type="submit">
                            Clean Up
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}
