import axios from "axios"
import { apiUrlPrefix, subscriptionKey } from '../authConfig';
import useHeader from "../components/useHeader";


//react-query is not working well with how we need to post to lookup (technically we're querying and mutating at the same time..?) so we're doing this manually in FastPath.js via axios

// const fastPathLookup = async (body) => {
//     console.log("Body: ", body); //not reaching this, may need to use mutation
//     const { data } = await axios.post(
//         `https://powerstation-api.azurewebsites.net/fastpath/routes/lookup`,
//         body
//     );

//     return data;
// }

/**
 * 
 * @returns locations
 */
const getLocations = async () => {
    try {
        
        //alert(`${apiUrlPrefix}/CrystalBall/Locations`);

        const { data } = await axios.get(
            `${apiUrlPrefix}/CrystalBall/Locations`,
            {
                headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey, },
            }
        );

        return data;
    } catch (e) {
        console.log(e);
        return [];
    }

}

export {
    getLocations
};