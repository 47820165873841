import { CircularProgress, Typography, } from '@mui/material';
import { styled } from '@mui/material';
import { useDashboard } from './useDashboard';

export const NoDataOverlay = () => {
    const { loading, views } = useDashboard();
    const visible = !loading && !views.length;
    return (
        <OverlayContainer visible={visible}>
            <Typography> No views found.  Click Add New in the upper left to create a new view.  </Typography>
        </OverlayContainer>
    );
}

export const LoadingOverlay = () => {
    const { loading } = useDashboard();
    return (
        <OverlayContainer visible={loading}>
            <CircularProgress />&nbsp;&nbsp;
            <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    top: '45%',
    left: 0,
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));
