import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
//import TSRActivityGrid from '../components/TSRActivityGrid';
// import BulkReassignmentGrid from '../components/BulkReassignmentGrid';
import BidmanGrid from '../components/Bidman/BidmanGrid.js';
import BidmanDashboard from '../components/Bidman/BidmanDashboard.js';
import BidmanBidLimits from '../components/Bidman/BidmanBidLimits.js';
import BidmanErcotGrid from '../components/Bidman/BidmanErcotGrid.js';
import BidmanUtilityGrid from '../components/Bidman/BidmanUtilityGrid.js';
import BidmanAppMenu from '../components/Bidman/BidmanAppMenu';
import BidmanPivot from '../components/Bidman/BidmanPivot.js';
import ComponentGuard from '../components/Guards/ComponentGuard.js';
import { apiUrlPrefix, userGroups, subscriptionKey } from "../authConfig";
import Collapse from '@mui/material/Collapse';
import dayjs from 'dayjs';
import {useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid.js';
import { useData } from '../components/useData.js';
import MasterDetailDashboard from '../components/Bidman/MasterDetailDashboard.js';

function Bidman() {
  const [view, setView] = React.useState('pjm');
  const {enqueueSnackbar} = useSnackbar();
  const guid = useLocalGuid();
  const { rowData: treeData, getData } = useData([]);
  const dataUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Fetch?name=PowerStationMetaData.UI_BidmanTreeHiearchyFetch&parm=${guid}`
  const [refreshAll, setRefreshAll] = React.useState(0);
  // const [buttonsDisabled, setButtonsDisabled] = React.useState(true);

  //const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formatString = 'MM/DD/YYYY';
  const [dateValue, setDateValue] = React.useState(dayjs(tomorrow).format(formatString));
  
  const handleDateChange = useCallback((newDateValue) => {
    setDateValue(dayjs(newDateValue).format(formatString));
    enqueueSnackbar(`Master date set to ${newDateValue}`);
  }, []);
  
  const handleSelection = useCallback((viewName) => {
    setView(viewName);
  }, []);

  useEffect(() => {
    //getData(dataUrl);
    let abortController = new AbortController();  
    // your async action is here  
    getData(dataUrl);
    return () => {  
      abortController.abort();  
    }  
  }, []);

  function renderTreeGrids(node) {
    let grids = []

    if (Array.isArray(node.children)) {
      grids.push(node.children.map(renderTreeGrids))
    }

    if (node.gridName && node.component === 'BidmanGrid') {grids.push(
      <Collapse in={view === node.gridName}>
        <BidmanGrid node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />
      </Collapse>
    )} else if (node.gridName && node.component === 'BidmanDashboard') {grids.push(
      <Collapse in={view === node.gridName}>
        {/*<BidmanDashboard node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />*/}
        <MasterDetailDashboard node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} treeData={treeData} />
      </Collapse>
    )} else if (node.gridName && node.component === 'BidmanBidLimits') {grids.push(
      <Collapse in={view === node.gridName}>
        <BidmanBidLimits node={node} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />
      </Collapse>
    )}  else if (node.gridName && node.component === 'BidmanPivot') {grids.push(
      <Collapse in={view === node.gridName}>
        <BidmanPivot node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />
      </Collapse>
    )} else if (node.gridName && node.component === 'BidmanErcotGrid') {grids.push(
      <Collapse in={view === node.gridName}>
          {/* mts 5/11/2022, changed to look the  same as bidmangrid.
            <BidmanErcotGrid nodeId={node.nodeId} gridDataApiEndpoint={node.gridDataApiEndpoint} submitApiEndpoint={node.submitApiEndpoint} oprDay={dateValue}/>
        */}
        <BidmanErcotGrid node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />
      </Collapse>
    )} else if (node.gridName && node.component === 'BidmanUtilityGrid') {grids.push(
      <Collapse in={view === node.gridName}>
        <BidmanUtilityGrid node={node} oprDay={dateValue} refreshAll={refreshAll} triggerRefresh={setRefreshAll} />
      </Collapse>
    )}

    return(grids);
  }; 

  return (
    <ComponentGuard groups={[userGroups.engie, userGroups.bidman]} >
      <Box sx={{ display: 'flex' }}>
        <BidmanAppMenu 
          handleSelection={handleSelection} 
          selected={view} 
          treeData={treeData}
          date={dateValue}
          handleDateChange={handleDateChange}/>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: '100%',
            p:'7px',
            //height: '110%',
          }}
        >
          {treeData.map(renderTreeGrids)}
        </Box>      
      </Box>
        
      {/* </div> */}
    </ComponentGuard>
  );
}

export default function BidmanContent() {
  return <Bidman/>;
}


