import React, { useEffect, useImperativeHandle, useState } from "react";
import { useSnackbar } from "notistack";

export const useLocalData = (storageLocation, gridRef) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(false);

    //this will call loadDataLocal on each render until rows are actually loaded into the grid.
    useEffect(() => {
      const rowCount = gridRef.current?.api?.rowModel.rowsToDisplay.length;
      if(!loaded && rowCount === 0) {
        loadDataLocal();
      }
      else if(!loaded && rowCount > 0) {
        setLoaded(true); //when we have successfully loaded the rows set loaded to true.
      }
    });

    useEffect(() => {
      window.addEventListener('beforeunload', saveDataLocal);
      return () => {
        window.removeEventListener('beforeunload', saveDataLocal);
      }
    }, []);

    function saveDataLocal() {
      if(storageLocation ){//&& gridRef.current.api.rowModel.rowsToDisplay.length > 0) {
        const rowData = [];
        
        gridRef.current.api.forEachNode(function(node) {
          const newData = node.data
          rowData.push(newData);
        })
        localStorage.setItem(storageLocation + 'Data', JSON.stringify(rowData));
        //enqueueSnackbar(`Local data saved for ${storageLocation} and the JSON is ${JSON.stringify(rowData)}`)
      }
      
    };

    function loadDataLocal() {
      try {
        const dataObject = localStorage.getItem(storageLocation + 'Data');
        const data = JSON.parse(dataObject);
        const rowData = data ?? [];
        gridRef.current.api.setRowData(rowData);
        if(!loaded && rowData.length === 0) { setLoaded(true); }
      } catch (err) {
        enqueueSnackbar(`Error loading data from ${storageLocation ?? 'local storage'}. Message: ${err}`);
      }
    };

    return {
      saveDataLocal,
      loadDataLocal
    };
};
