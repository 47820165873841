import { RefreshButton } from "../../RibbonButtons"
import { ClearSelectionsButton } from "../../RibbonButtons/ClearSelectionsButton"
import { RibbonGroupContainer } from "../../Ribbon"

export const GridActionsGroup = ({ formId, expanded, gridRef, handleFetchData }) => {
    return (
        <RibbonGroupContainer sx={{ alignItems: 'start', }} spacing={1}>
            <ClearSelectionsButton
                gridRef={gridRef}
                expanded={expanded}
            />

            <RefreshButton
                form={formId}
                label={'Refresh All'}
                expanded={expanded}
                onRefresh={handleFetchData}
            />
        </RibbonGroupContainer>
    )
}