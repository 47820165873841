import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Card, CardActionArea, CardContent, Checkbox, Collapse, IconButton, List, ListItemButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useSounds } from '../Sound/useSounds.js';

export const AlertSettings = () => {
    const { allAlerts, userAlerts, handleToggleActive, } = useSounds();
    const alerts = useMemo(() => {
        return allAlerts.map(alert => {
            const userAlert = userAlerts.find(userAlert => userAlert.audioAlertID === alert.audioAlertID);
            return {
                ...alert,
                ...userAlert,
                active: !!userAlert,
            };
        });
    }, [allAlerts, userAlerts]);

    return (
        <Box sx={{ height: '100%', }}>
            <Table sx={{ width: '50%', }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: '1.25em', }}>Alert</TableCell>
                        <TableCell sx={{ fontSize: '1.25em', }}>Sound</TableCell>
                        <TableCell sx={{ fontSize: '1.25em', }}>Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {alerts.map(alert => (
                        <TableRow key={`alert-row-${alert.audioAlertID}`}>
                            <TableCell>{alert.name}</TableCell>
                            <TableCell>
                                <SoundPicker alert={alert} />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    onChange={() => handleToggleActive(alert.audioAlertID)}
                                    checked={alert.active}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box >
    );
}

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));

const SoundPicker = (props) => {
    const { alert } = props;
    const { handleUpdateAlertSound, playSound, soundFiles } = useSounds();
    const [expanded, setExpanded] = useState();

    const getFileName = (name) => {
        //trim .mp3 from the end of the file name
        const fileName = name.slice(0, -4);
        return fileName;
    }

    const handleExpandClick = () => {
        setExpanded(e => !e);
    }

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={() => setExpanded(false)}
        >
            <Card>
                <CardActionArea
                    onMouseDown={e => {
                        handleExpandClick();
                    }}
                    sx={{ padding: '6px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <IconButton
                            onMouseDown={e => {
                                e.stopPropagation();
                                playSound(alert.soundFile);
                            }}
                            sx={{ padding: '4px', }}
                        >
                            <PlayCircleIcon />
                        </IconButton>&nbsp;&nbsp;
                        {getFileName(alert.soundFile)}
                    </Box>
                    <ExpandMore
                        expand={expanded}
                        onMouseDown={e => {
                            //let the event be handled by the card action area
                            e.preventDefault();
                        }}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActionArea>
                <Collapse in={expanded}>
                    <CardContent sx={{ px: 0, }}>
                        <List dense>
                            {[...Object.keys(soundFiles)].map(soundFile => (
                                <ListItemButton
                                    key={`${alert.audioAlertID}-sound-file-${soundFile}`}
                                    onMouseDown={_ => {
                                        handleExpandClick();
                                        handleUpdateAlertSound(alert.audioAlertID, soundFile);
                                    }}
                                >
                                    <IconButton
                                        onMouseDown={e => {
                                            e.stopPropagation();
                                            playSound(soundFile);
                                        }}
                                        sx={{ padding: '4px', }}
                                    >
                                        <PlayCircleIcon />
                                    </IconButton>&nbsp;&nbsp;
                                    {getFileName(soundFile)}
                                </ListItemButton>
                            ))}
                        </List>
                    </CardContent>
                </Collapse>
            </Card>
        </ClickAwayListener>
    );
}
