import useHeader from "./useHeader"
import axios from "axios"
import { useSnackbar } from "notistack";
import { useActionAudit } from "./useActionAudit";
import { apiUrlPrefix } from "../authConfig";
import { useLocation } from "react-router";
import { useCallback } from "react";

export const useApi = (gridRef) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const location = useLocation();

    const handleErrorResponse = useCallback((error, url, requestPayload) => {
        let message = error.message;

        if (error.message === 'canceled') return; //User cancelled the request

        const errorData = error?.response?.data;
        if (typeof errorData === 'string' && errorData.includes('Crystal Ball API')) {
            const split = error.response.data.split('  ');
            message = message + ". " + split[0];
        }

        enqueueSnackbar(message, { variant: 'error' });
        const pathParts = location.pathname.split('/').slice(1);
        const app = pathParts[0];
        const view = pathParts[1];
        const viewId = pathParts[2];
        const userInterface = `${app} - ${view}${!!viewId ? ` - View Id: ${viewId}` : ''}`;

        //get the string from the url between name= and &; remove the schema name
        const procName = url.match(/name=(.*?)&/)[1].split('.').pop();
        const action = `User recieved backend error: ${procName}`;
        const dataToLog = {
            error: error.message,
            response: errorData,
            url: url,
            headers: error.response?.config.headers,
            method: error.response?.config.method,
            status: error.response?.status,
        }
        requestPayload && (dataToLog.requestPayload = requestPayload);

        logAction(action, userInterface, dataToLog);
        gridRef?.current?.api?.hideOverlay();
        return error;
    }, [enqueueSnackbar, location, logAction, gridRef]);

    const get = useCallback(async (url) => {
        return axios.get(url, { headers, }).catch(error => handleErrorResponse(error, url));
    }, [headers, handleErrorResponse]);

    const post = useCallback(async (url, data, config = {}) => {
        const cfg = { headers, ...config };
        return axios.post(url, data, cfg).catch(error => handleErrorResponse(error, url, data));
    }, [headers, handleErrorResponse]);

    return {
        handleErrorResponse,
        get,
        post,
        headers,
        apiUrlPrefix,
        enqueueSnackbar,
        logAction,
    }
}
