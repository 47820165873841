import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSnackbar } from 'notistack';

const truncate = (str) => {
  return str.length > 25 ? str.substring(0, 7) + "..." : str;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function SmallDetailsToolPanel(props) {
  const rowData = props.rowData;
  const headers = rowData ? Object.keys(rowData) : [];
  const {enqueueSnackbar} = useSnackbar();

  function handleClick(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`${text} copied to the clipboard`)
  }

  return(
    headers.length > 0 
    ? <TableContainer component={Paper}>
        <Table 
          sx={{ width:'100%' }} 
          size="small" 
          aria-label="customized table"
        >
          
          {/* <TableHead>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">Info</StyledTableCell>
            </StyledTableRow>
          </TableHead>  */}
          <TableBody>
          {headers.map((header) =>
            rowData[header] ? 
            <StyledTableRow onClick={() => handleClick(rowData[header])}>
              {/* <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">
                {header}
              </StyledTableCell> */}
              <StyledTableCell sx={{ fontWeight: 'light' }} align="left">{header} - {rowData[header]}</StyledTableCell>
            </StyledTableRow>
            : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
    : <Grid sx={{ p:2 }} container>
        <Grid item>
            <Typography>
                Select a cell to view its row details.
            </Typography>
        </Grid>
    </Grid>
  );
};