import React, { useMemo, useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeOriginals.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import CustomTooltip from './customTooltip.jsx';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem, Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, TextField, MenuList } from "@mui/material";
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { useData } from '../useData';
import { AgGridReact } from 'ag-grid-react';
import { useActionAudit } from '../useActionAudit';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useHeader from '../useHeader';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

//import { useActionAudit } from '../useActionAudit';
// const { logAction } = useActionAudit();
//logAction(`Changed header label date to: ${event}`, storageLocation);

export default forwardRef((props, ref) => {
  const { handleMasterTimezoneChange, timezone, handleMasterHeaderLabelDateChange, headerLabelDate, } = props;
  const guid = useLocalGuid();
  const grid = "availability-grid";
  const storageLocation = 'ResizableAvailabilityGrid';
  const [smartFilter, setSmartFilter] = useState('Rolling Segments');

  const newDate = new Date(headerLabelDate);
  const year = newDate.getFullYear();
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const hour = newDate.getHours();
  const headers = useHeader();
  const [headerLabelHour, setHeaderLabelHour] = useState(`${hour}:00`);
  const [headerLabelDay, setHeaderLabelDay] = useState(`${month}/${day}/${year}`);

  const { rowData, getData } = useData([]);
  //const { colDefs, autoGroupColumnDef } = useSmartFilterColDefs(smartFilter);
  const [firmNonFirm, setFirmNonFirm] = useState('NFATC');
  const [hoursOut, setHoursOut] = useState('6');
  const { logAction } = useActionAudit();
  const [buttonsDisabled, setButtonsDiasabled] = useState(false);
  const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);

  const [colDefs, setColDefs] = useState([]);

  const [autoGroupColumnDef, setAutoGroupColumnDef] = useState([]);

  const [porChoices, setPorChoices] = useState([]);
  const [podChoices, setPodChoices] = useState([]);

  const [selectedPor, setSelectedPor] = useState('');
  const [selectedPod, setSelectedPod] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  //const { viewPanel, onRowDataUpdated , saveLocalLayout, } = useViewPanel(grid, ref, storageLocation);
  //const { viewPanel, onRowDataUpdated } = useViewPanel(grid, ref, storageLocation);

  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_AvailabilityFetch&parm=${encodeURIComponent(timezone)}&parm=${guid}&parm=${encodeURIComponent(smartFilter)}&parm=${encodeURIComponent(selectedPor)}&parm=${encodeURIComponent(selectedPod)}&parm=${encodeURIComponent(firmNonFirm)}&parm=${encodeURIComponent(hoursOut)}&parm=${encodeURIComponent(headerLabelDate)}`;

  async function fetchPorSelectChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
    }

    axios(options).then(response => {
      setPorChoices(response.data.map(choice => choice['por']));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });

  };

  function PopulateHourChoices() {

    // const d = new Date(headerLabelDate);
    // const year = d.getFullYear();
    // const day = d.getDate();
    // const month = d.getMonth() + 1;
    // let newDay = d.getDate();

    const choices = [];
    // choices.push(<MenuItem selected value={headerLabelDate}>{headerLabelDate}</MenuItem>);
    // [...Array(12).keys()].forEach(idx => {
    //   idx++; //1 based index, no HE0,
    //   let hour = d.getHours() - 1; //start with current hour
    //   hour += idx; //add hours according to key
    //   hour = (hour % 24) + 1; //hours wrap around at 24

    //   if (d.getHours() + idx > 24) {newDay = day + 1;} else {newDay = day;}

    //   let dateString = `${month}/${newDay}/${year} ${hour}:00`;
    //   choices.push(<MenuItem value={dateString}>{dateString}</MenuItem>);
    // });

    [...Array(23).keys()].forEach(idx => {
      //idx++; //1 based index, no HE0,
      let startHour = idx + 1;
      let endHour = idx + 1; //start with current hour
      //hour += idx; //add hours according to key

      let hourValue = `${startHour}:00`;

      // if (endHour === 24) {
      //   hourValue = `0:00`;
      // } 

      let hourString = `${endHour}:00`;
      choices.push(<MenuItem value={hourValue}>{hourString}</MenuItem>);
    });

    return choices;
  };

  function setColDefsForSmartFilter() {
    let newDefs = [];
    let newAutoGroupColumnDef = [];

    //HE0 should be HE24!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    function checkForHourColumns(key) {
      //key may look like 'H11', try to grab the number from the end
      const hoursToAdd = parseInt(key.replace('H', '')) - 2;
      //alert(hoursToAdd);
      if (isNaN(hoursToAdd)) {
        return key.value;
      }
      else {
        const d = new Date(headerLabelDate);
        let hour = d.getHours(); //start with current hour
        hour += hoursToAdd; //add hours according to key
        if ((hour % 24) + 1 === 0) {
          hour = 24;
        } else {
          hour = (hour % 24) + 1; //hours wrap around at 24
        }

        //hour += hoursToAdd + 1; //add hours according to key
        //hour %= hour;

        return (hour.toString());
        //return hour ? `HE(${hour})` : 'Total Value';
      }
    };

    switch (smartFilter) {
      case 'All Segments':
        newDefs = [
          {
            field: "POSTING_REF",
            headerName: "Posting Ref",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            rowDrag: true,
            //rowGroup: true,
            initialWidth: 100,
            initialHide: false,
            //enableRowGroup: true,
          },
          {
            field: "SYSTEM_ELEMENT",
            headerName: "Sys Element",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "TP",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POR",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POD",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Availability",
            initialWidth: 50,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Start Date",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Start Time",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Stop Date",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Stop Time",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Last Updated",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "LLHPrice",
            initialWidth: 50,
            initialHide: false,
          },
          {
            field: "HLHPrice",
            initialWidth: 50,
            initialHide: false,
          },
          {
            field: "offPeakPrice",
            headerName: "LLH Cost",
            initialWidth: 50,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "peakPrice",
            headerName: "HLH Cost",
            initialWidth: 50,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "Hour",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Term",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Source",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Sink",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },

        ];
        break;
      case 'Rolling Segments':
        newDefs = [
          {
            field: "System Element",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            rowDrag: true,
            initialWidth: 300,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "TP",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          // {
          //   field: "Term",
          //   initialWidth: 150,
          //   initialHide: true,
          //   filter: "agMultiColumnFilter",
          // },
          {
            field: "POR",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POD",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Source",
            initialWidth: 150,
            initialHide: true,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Sink",
            initialWidth: 150,
            initialHide: true,
            filter: "agMultiColumnFilter",
          },
          {
            field: "LLHPrice",
            initialWidth: 150,
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "HLHPrice",
            initialWidth: 150,
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "offPeakPrice",
            headerName: "LLH Cost",
            initialWidth: 150,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "peakPrice",
            headerName: "HLH Cost",
            initialWidth: 150,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          { field: "H1", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H1"), },
          { field: "H2", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H2"), },
          { field: "H3", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H3"), },
          { field: "H4", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H4"), },
          { field: "H5", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H5"), },
          { field: "H6", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H6"), },
          { field: "H7", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H7"), },
          { field: "H8", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H8"), },
          { field: "H9", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H9"), },
          { field: "H10", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H10"), },
          { field: "H11", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H11"), },
          { field: "H12", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H12"), },
          // {field: "H13", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H13"),},
          // {field: "H14", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H14"),},
          // {field: "H15", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H15"),},
          // {field: "H16", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H16"),},
          // {field: "H17", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H17"),},
          // {field: "H18", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H18"),},
          // {field: "H19", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H19"),},
          // {field: "H20", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H20"),},
          // {field: "H21", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H21"),},
          // {field: "H22", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H22"),},
          // {field: "H23", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H23"),},
          // {field: "H24", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H24"),},
        ];
        break;
      case 'Route Segments':
        newDefs = [
          {
            field: "System Element",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            rowDrag: true,
            initialWidth: 300,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "TP",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Term",
            initialWidth: 150,
            initialHide: true,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POR",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POD",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Source",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Sink",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "LLHPrice",
            initialWidth: 150,
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "HLHPrice",
            initialWidth: 150,
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "offPeakPrice",
            headerName: "LLH Cost",
            initialWidth: 150,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "peakPrice",
            headerName: "HLH Cost",
            initialWidth: 150,
            //aggFunc: "sum",
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          { field: "H1", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H1"), },
          { field: "H2", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H2"), },
          { field: "H3", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H3"), },
          { field: "H4", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H4"), },
          { field: "H5", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H5"), },
          { field: "H6", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H6"), },
          { field: "H7", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H7"), },
          { field: "H8", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H8"), },
          { field: "H9", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H9"), },
          { field: "H10", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H10"), },
          { field: "H11", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H11"), },
          { field: "H12", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H12"), },
          // {field: "H13", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H13"),},
          // {field: "H14", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H14"),},
          // {field: "H15", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H15"),},
          // {field: "H16", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H16"),},
          // {field: "H17", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H17"),},
          // {field: "H18", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H18"),},
          // {field: "H19", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H19"),},
          // {field: "H20", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H20"),},
          // {field: "H21", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H21"),},
          // {field: "H22", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H22"),},
          // {field: "H23", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H23"),},
          // {field: "H24", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H24"),},
        ];
        break;
      case 'Bundled Routes':
        newDefs = [
          {
            field: "Route",
            headerName: "Route",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            rowDrag: true,
            editable: false,
            rowGroup: true,
            initialWidth: 300,
            initialHide: true,
            filter: "agMultiColumnFilter",
            sortable: true,
            enableRowGroup: true,
          },
          {
            field: "Segment",
            initialWidth: 150,
            aggFunc: "count",
            initialHide: false,
            //filter: 'agNumberColumnFilter',
          },
          {
            field: "TP",
            initialWidth: 150,
            //aggFunc: "count",
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Term",
            initialWidth: 150,
            initialHide: true,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POR",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "POD",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Source",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "Sink",
            initialWidth: 150,
            initialHide: false,
            filter: "agMultiColumnFilter",
          },
          {
            field: "LLHPrice",
            initialWidth: 150,
            //aggFunc: "sum",
            aggFunc: function (params) {
              let sum = 0;
              params.values.forEach((value) => (sum += value));
              return Math.round((sum + Number.EPSILON) * 100) / 100;
            },
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "HLHPrice",
            initialWidth: 150,
            aggFunc: function (params) {
              let sum = 0;
              params.values.forEach((value) => (sum += value));
              return Math.round((sum + Number.EPSILON) * 100) / 100;
            },
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "offPeakPrice",
            headerName: "LLH Cost",
            initialWidth: 150,
            aggFunc: function (params) {
              let sum = 0;
              params.values.forEach((value) => (sum += value));
              return Math.round((sum + Number.EPSILON) * 100) / 100;
            },
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          {
            field: "peakPrice",
            headerName: "HLH Cost",
            initialWidth: 150,
            aggFunc: function (params) {
              let sum = 0;
              params.values.forEach((value) => (sum += value));
              return Math.round((sum + Number.EPSILON) * 100) / 100;
            },
            initialHide: false,
            filter: 'agNumberColumnFilter',
          },
          { field: "H1", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H1"), },
          { field: "H2", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H2"), },
          { field: "H3", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H3"), },
          { field: "H4", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H4"), },
          { field: "H5", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H5"), },
          { field: "H6", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H6"), },
          { field: "H7", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H7"), },
          { field: "H8", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H8"), },
          { field: "H9", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H9"), },
          { field: "H10", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H10"), },
          { field: "H11", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H11"), },
          { field: "H12", filter: 'agNumberColumnFilter', aggFunc: 'min', headerValueGetter: checkForHourColumns("H12"), },
          // {field: "H13", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H13"),},
          // {field: "H14", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H14"),},
          // {field: "H15", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H15"),},
          // {field: "H16", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H16"),},
          // {field: "H17", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H17"),},
          // {field: "H18", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H18"),},
          // {field: "H19", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H19"),},
          // {field: "H20", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H20"),},
          // {field: "H21", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H21"),},
          // {field: "H22", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H22"),},
          // {field: "H23", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H23"),},
          // {field: "H24", filter: 'agNumberColumnFilter', aggFunc:'min', headerValueGetter: checkForHourColumns("H24"),},
        ];
        newAutoGroupColumnDef = {
          headerName: 'Route',
          initialWidth: 400,
          floatingFilter: true,
          headerCheckboxSelection: true,
          filterValueGetter: params => params.data.Route,
          filter: "agMultiColumnFilter",
          cellRendererParams: {
            suppressCount: true,
            checkbox: true,
          }
        };
        break;

    }

    //alert(JSON.stringify(newAutoGroupColumnDef));
    setAutoGroupColumnDef(newAutoGroupColumnDef);
    setColDefs(newDefs);
    //ref.current.api.setColumnDefs(newDefs);

  }

  async function fetchPodSelectChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
    }

    axios(options).then(response => {
      setPodChoices(response.data.map(choice => choice['pod']));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });

  };

  function fetch() {
    ref.current.api.showLoadingOverlay();
    setButtonsDiasabled(true);

    getData(loadUri).then(response => {
      setColDefsForSmartFilter();
      setButtonsDiasabled(false);
      if (ref.current) { ref.current.api.hideOverlay(); }
    })
  };

  const handleTimezoneChange = (event) => {
    //saveLayoutLocal();
    handleMasterTimezoneChange(event);
    logAction('Changed Timezone', storageLocation);
  };

  // const handleHeaderLabelDateChange = (event) => {
  //   //saveLayoutLocal();
  //   handleMasterHeaderLabelDateChange(event);
  //   logAction(`Changed header label date to: ${event}`, storageLocation);
  // };

  const handleHeaderLabelDateChange = (event) => {

    const newDate = new Date(event);
    const year = newDate.getFullYear();
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;

    setHeaderLabelDay(`${month}/${day}/${year}`);

    handleMasterHeaderLabelDateChange(`${month}/${day}/${year} ${headerLabelHour}`);
    //enqueueSnackbar(JSON.stringify(event));

    //logAction(`Changed header label date to: ${event}`, storageLocation);
  };

  const handleHeaderLabelTimeChange = (event) => {
    //const d = new Date(headerLabelDate+event.target.value);

    setHeaderLabelHour(event.target.value);

    let newHeaderLabelDate = `${headerLabelDay} ${event.target.value}`

    //enqueueSnackbar(newHeaderLabelDate);

    handleMasterHeaderLabelDateChange(newHeaderLabelDate);

    //logAction(`Changed header label date to: ${event}`, storageLocation);
  };

  function onRowDataUpdated(e) {
    //setColDefsForSmartFilter();
    autoSizeAll(false);
    setRefreshButtonDisabled(false);
  }

  const groupDefaultExpanded = 1;

  const handleSmartFilterChange = (event) => {
    //ref.current.saveLayoutLocal();
    //handleMasterTimezoneChange(event);
    //enqueueSnackbar(event.target.value);
    setSmartFilter(event.target.value);
    logAction(`Changed smart filter to: ${event.target.value}`, storageLocation);

    if (event.target.value === 'All Segments' || event.target.value === 'Rolling Segments') {
      setButtonsDiasabled(true)
    } else {
      setButtonsDiasabled(false)
    }


  };

  const handleFirmNonFirmChange = (event) => {
    setFirmNonFirm(event.target.value);
  };

  const handleHoursOutChange = (event) => {
    setHoursOut(event.target.value);
    logAction(`Changed hours out to: ${event.target.value}`, storageLocation);
  };

  const handleSelectedPor = (event, value) => {
    //enqueueSnackbar(value);
    setSelectedPor(value);
  };


  const handleSelectedPod = (event, value) => {
    //enqueueSnackbar(value);
    setSelectedPod(value);
  };

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";


  function autoSizeAll(skipHeader) {
    const allColumnIds = [];
    ref.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    ref.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  const onRefreshBtn = () => {


    if (
      (smartFilter === 'All Segments' || smartFilter === 'Rolling Segments')
    ) {

      //ref.current.api.setRowData(null);

      //getData(loadUri);
      fetch();
      let message = 'Querying...';
      enqueueSnackbar(message);

    }
    else if (
      (smartFilter !== 'All Segments' && smartFilter !== 'Rolling Segments') &&
      (selectedPor === '' || selectedPod === '')
    ) {

      let message = 'You have to set your POR and POD to use this smart filter...';
      enqueueSnackbar(message);
      setRefreshButtonDisabled(false);
      ref.current.api.hideOverlay();

    } else {

      //ref.current.api.setRowData(null);

      //getData(loadUri);
      fetch();
      let message = 'Querying...';
      enqueueSnackbar(message);

    }

    //ref.current.api.hideOverlay();

    //setRefreshButtonDisabled(false);

  };

  // useEffect(() => {

  //   //setColDefsForSmartFilter();

  // }, [smartFilter]);

  useEffect(() => {

    let abortController = new AbortController();
    // your async action is here  
    fetchPodSelectChoices();
    fetchPorSelectChoices();
    return () => {
      abortController.abort();
    }

  }, []);

  // useEffect(() => {


  //   //getData(loadUri);

  //   //turned off to speed up initial load
  //   //fetch(); 

  //   const interval=setInterval(()=>{

  //     let newDate = new Date()
  //     let minute = newDate.getMinutes();
  //     let second = newDate.getSeconds() + 1;

  //     if (minute === 1 & second ===1) {
  //       enqueueSnackbar('We refreshed the availability data at the top of the hour.')
  //       logAction(`Refreshed availability data at the top of the hour`, storageLocation);
  //       onRefreshBtn();
  //     }

  //    },800)

  //    return()=>clearInterval(interval)

  // }, [timezone]);

  // useEffect(() => {
  //   // if(colDefs.length > 0) {
  //   //   ref.current.columnApi.autoSizeAllColumns();
  //   // }
  //   //autoSizeAll(false);
  // }, [colDefs]);

  const availabilitySideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
      //layoutPanel,
      //viewPanel
    ],
    position: 'right',
  };


  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);


  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-fail": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-pass": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-waiting": params => params.api.getValue("Status", params.node) === 'QUEUED'
    },
  };

  // const onTsrComponentStateChanged = (params) => {
  //   if(showTsrDetails) {params.api.openToolPanel('smallDetailsToolPanel')} 
  // };

  // var filterParams = {
  //   // provide comparator function
  //   comparator: (filterLocalDateAtMidnight, cellValue) => {
  //       const dateAsString = cellValue;

  //       if (dateAsString == null) {
  //           return 0;
  //       }

  //       // In the example application, dates are stored as dd/mm/yyyy
  //       // We create a Date object for comparison against the filter date
  //       const dateParts = dateAsString.split('/');
  //       const day = Number(dateParts[2]);
  //       const month = Number(dateParts[1]) - 1;
  //       const year = Number(dateParts[0]);
  //       const cellDate = new Date(year, month, day);

  //       // Now that both parameters are Date objects, we can compare
  //       if (cellDate < filterLocalDateAtMidnight) {
  //           return -1;
  //       } else if (cellDate > filterLocalDateAtMidnight) {
  //           return 1;
  //       }
  //       return 0;
  //   }
  // }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: "agMultiColumnFilter",
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  return (
    <div>
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Query/requery the grid of transmission segments available for purchase." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            disabled={refreshButtonDisabled}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Get Segments</Button>
        </Tooltip>&nbsp;&nbsp;
        <Tooltip title="Select the timezone of interest.  It will be used in when submitting TSRs in the list below." arrow placement="top">
          <TextField
            id="timezone"
            select
            value={timezone}
            size={buttonSize}
            color={buttonColor}
            disabled={refreshButtonDisabled}
            label="Timezone"
            onChange={handleTimezoneChange}
          >
            <MenuItem selected value={'Pacific Standard Time'}>Pacific</MenuItem>
            <MenuItem value={'Eastern Standard Time'}>Eastern</MenuItem>
            <MenuItem value={'Central Standard Time'}>Central</MenuItem>
            <MenuItem value={'Mountain Standard Time'}>Mountain</MenuItem>
          </TextField>
        </Tooltip>&nbsp;
        <Tooltip title="Select the type of filter you want to use to find available transmission." arrow placement="top">
          <TextField
            select
            id="smartFilter"
            value={smartFilter}
            size={buttonSize}
            color={buttonColor}
            disabled={refreshButtonDisabled}
            label="Smart Filter"
            onChange={handleSmartFilterChange}
          >
            <MenuItem selected value={'All Segments'}>All Segments</MenuItem>
            <MenuItem value={'Rolling Segments'}>Rolling Segments</MenuItem>
            <MenuItem value={'Route Segments'}>Route Segments</MenuItem>
            <MenuItem value={'Bundled Routes'}>Bundled Routes</MenuItem>
          </TextField>
        </Tooltip>&nbsp;
        <Tooltip title="Select either firm or non-firm available transmission capacity." arrow placement="top">
          <TextField
            id="firmNonFirm"
            value={firmNonFirm}
            size={buttonSize}
            select
            color={buttonColor}
            disabled={refreshButtonDisabled}
            label="Firm?"
            onChange={handleFirmNonFirmChange}
          >
            <MenuItem selected value={'NFATC'}>NFATC</MenuItem>
            <MenuItem value={'FATC'}>FATC</MenuItem>
          </TextField>
        </Tooltip>&nbsp;
        <Tooltip title="Select your POR. Leave it blank for all PORs." arrow placement="top">
          <Autocomplete
            sx={{ width: 200 }}
            value={selectedPor}
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            //disabled={buttonsDisabled}
            disabled={refreshButtonDisabled}
            onInputChange={(event, newValue) => {
              handleSelectedPor(event, newValue);
            }}
            onChange={handleSelectedPor}
            freeSolo
            id="pors"
            disableClearable
            options={porChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="POR?"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Tooltip>&nbsp;
        <Tooltip title="Select your POD. Leave it blank for all PODs." arrow placement="top">
          <Autocomplete
            sx={{ width: 200 }}
            value={selectedPod}
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            //disabled={buttonsDisabled}
            disabled={refreshButtonDisabled}
            onInputChange={(event, newValue) => {
              handleSelectedPod(event, newValue);
            }}
            onChange={handleSelectedPod}
            freeSolo
            id="pods"
            disableClearable
            options={podChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="POD?"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
        </Tooltip>&nbsp;
        <Tooltip title="Select the start date for viewing available transmission." arrow placement="top">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              id="headerLabelDay"
              value={new Date(headerLabelDay)}
              label="Start Date"
              disabled={refreshButtonDisabled}
              onChange={(newStartValue) => {
                handleHeaderLabelDateChange(newStartValue);
              }}
              slotProps={{ textField: { size: 'small', } }}
            />
          </LocalizationProvider>
        </Tooltip>&nbsp;
        <Tooltip title="Select the starting hour ending for viewing available transmission." arrow placement="top">
          <TextField
            label="1st HE"
            select
            id="headerLabelHour"
            value={headerLabelHour}
            size={buttonSize}
            color={buttonColor}
            //variant={buttonVariant} 
            disabled={refreshButtonDisabled}
            onChange={handleHeaderLabelTimeChange}
          >
            {PopulateHourChoices()}
          </TextField>
        </Tooltip>&nbsp;
        <Tooltip title="Select the number of hours in to the future we will be working with." arrow placement="top">
          <TextField
            id="hoursOut"
            select
            value={hoursOut}
            size={buttonSize}
            color={buttonColor}
            disabled={refreshButtonDisabled}
            label="Hours"
            onChange={handleHoursOutChange}
          >
            {/* {[...Array(12).keys()].map(idx => {
            idx++; //1 based index, no HE0,
            return(<MenuItem value='{idx}'>{idx} Hours</MenuItem>);
          })} */}

            <MenuItem value={'12'}>12 Hours</MenuItem>
            <MenuItem value={'11'}>11 Hours</MenuItem>
            <MenuItem value={'10'}>10 Hours</MenuItem>
            <MenuItem value={'9'}>9 Hours</MenuItem>
            <MenuItem value={'8'}>8 Hours</MenuItem>
            <MenuItem value={'7'}>7 Hours</MenuItem>
            <MenuItem selected value={'6'}>6 Hours</MenuItem>
            <MenuItem value={'5'}>5 Hours</MenuItem>
            <MenuItem value={'4'}>4 Hours</MenuItem>
            <MenuItem value={'3'}>3 Hours</MenuItem>
            <MenuItem value={'2'}>2 Hours</MenuItem>
            <MenuItem value={'1'}>1 Hour</MenuItem>

          </TextField>
        </Tooltip>&nbsp;
        {/* <div>{headerLabelDate}</div> */}
      </Box>
      <AgGridContainer
        style={{
          height: "40vh",
          width: "98%"
        }}
      >

        <AgGridReact
          ref={ref}
          groupAggFiltering={true}
          suppressAggFilteredOnly={true}
          suppressAggFuncInHeader={true}
          rowData={rowData}
          columnDefs={colDefs}
          onRowDataUpdated={onRowDataUpdated}
          groupSelectsChildren={true}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDefaultExpanded={-1}
          //hasLayoutPanel={false}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          statusBar={statusBar}
          // masterDetail={true}
          // detailCellRendererParams={detailCellRendererParams}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          overlayLoadingTemplate={'<span style="width: 70%; font-size: 20px">Please wait.  Loading...</span>'}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          defaultColDef={defaultColDef}
          sideBar={availabilitySideBar}
          animateRows={true}
          rowDragMultiRow
          rowSelection={"multiple"}
          enableCellTextSelection={true}
          tooltipShowDelay={0}
          components={{
            customTooltip: CustomTooltip,
            viewToolPanel: GridViewToolPanel,
          }}
        >
        </AgGridReact>
      </AgGridContainer>
    </div>
  );
});
