import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import * as yup from 'yup';
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormCheckbox from "../../../FormControls/FormCheckbox";
import ProfileEditor from "./ProfileEditor";
import { useProfileTemplates } from "./useProfileTemplates_v2";

const schema = yup.object().shape({ //TODO: min and max dates not working
    //startDateTime: date().required('Start date is required').min(defaults.startDateTime?.format(formatString), 'Start date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Start date is outside acceptble range.'),
    //stopDateTime: date().required('Stop date is required').min(defaults.startDateTime.format(formatString), 'Stop date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Stop date is outside acceptble range.'),
    Term: yup.string().required('Term is required'),
    timezone: yup.string().required('Timezone is required'),
    mwOn: yup.number().required('MW On is required').typeError('MW On must be a number.').min(0, 'MW On must be greater than or equal to 0'),
    priceOn: yup.number().required('Price On is required').typeError('Price On must be a number.'),
    mwOff: yup.number().required('MW Off is required').typeError('MW Off must be a number.').min(0, 'MW Off must be greater than or equal to 0'),
    priceOff: yup.number().required('Price Off is required').typeError('Price Off must be a number.'),
    adder: yup.string().test(
        'is-falsey-or-numeric',
        'Adder must be a number.',
        (value) => !value || !isNaN(value),
    ),
    indexType: yup.string().required('Index Type is required'),
    forecast: yup.string().required('Forecast is required'),
})

export const ProfileEditorDialog = ({ open, handleClose, closeDialog, dropdownOptions, editMode }) => {
    const formId = 'profile-editor-form';
    const { getValues: getDefaultValues, formState: { errors, }, } = useFormContext();
    const [
        dealProfile = [],
        defaultTimezone,
        defaultTerm,
        indexType = 'None',
        forecast = 'None',
        dynamic = false,
        adder = '',
        defaultStart = dayjs().add(1, 'day').startOf('day'),
        defaultStop = dayjs().add(2, 'day').startOf('day'),
    ] = getDefaultValues(['profile', 'timezone', 'Term', 'indexType', 'forecast', 'dynamic', 'adder', 'dealStartTime', 'dealStopTime']);
    const { enqueueSnackbar } = useSnackbar();

    const defaults = useMemo(() => {
        const firstStartDate = dealProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.startDateTime).isBefore(dayjs(acc))) {
                return dayjs(block.startDateTime);
            }
            return acc;
        }, null);

        const lastStopDate = dealProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.endDateTime).isAfter(dayjs(acc))) {
                return dayjs(block.endDateTime);
            }
            return acc;
        }, null);

        const timezone = !!defaultTimezone ? defaultTimezone : 'America/Los_Angeles';
        const tz = defaultTerm?.toLowerCase().includes('rockies') ? 'America/Denver' : timezone;

        return {
            startDateTime: firstStartDate ?? defaultStart,
            stopDateTime: lastStopDate ?? defaultStop,
            mwOn: 0,
            mwOff: 0,
            priceOn: 0,
            priceOff: 0,
            timezone: tz,
            Term: defaultTerm,
            indexType: ['null', 'undefined', null].includes(indexType) ? 'None' : indexType,
            forecast: ['null', 'undefined', null].includes(forecast) ? 'None' : forecast,
            dynamic: !!dynamic,
            adder: adder ?? '',
        }
    }, [dealProfile, defaultTerm, adder, defaultStart, defaultStop, defaultTimezone, indexType, forecast, dynamic,]);

    const { generateTransmissionBlocksUsingTemplate, } = useProfileTemplates();

    const [profile, setProfile] = useState(dealProfile);

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
        mode: 'onBlur',
    });

    const { handleSubmit, control, reset, clearErrors, setValue } = methods;

    useEffect(() => {
        clearErrors();
    }, [open, clearErrors]);

    useEffect(() => {
        if (!editMode && !dealProfile.length && !open) {
            reset(defaults);
            const profileDefault = generateTransmissionBlocksUsingTemplate(defaults, defaults.timezone, defaults.timezone);
            setProfile(profileDefault);
        }
    }, [editMode, setValue, generateTransmissionBlocksUsingTemplate, defaults, dealProfile, reset, open]);

    function handleCancel() {
        closeDialog();
    }

    function onSubmit(data) {
        handleClose({
            profile,
            ...data
        });
    }

    function onError(errors) {
        if (!!errors.profile) {
            enqueueSnackbar('No profile data - must have data in the grid before saving.', { variant: 'error' });
        }
    }

    return (
        <FormProvider {...methods}>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                component='form'
                id={formId}
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <DialogTitle sx={{ pb: 0, pt: 1, px: 2 }}>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' sx={{ flexGrow: 1, }}>Edit Profile</Typography>
                        <Box sx={{ flexGrow: 1, }} />
                        <FormControlLabel
                            control={<FormCheckbox
                                name='dynamic'
                                control={control}
                            />}
                            label="Dynamic"
                        />
                        <FormAutocomplete
                            control={control}
                            name={'indexType'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['indexType'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Index Type"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                    error={!!errors.indexType}
                                    helperText={errors.indexType?.message}
                                />
                            )}
                        />
                        <FormAutocomplete
                            control={control}
                            name={'forecast'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['forecast'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Forecast"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                    error={!!errors.forecast}
                                    helperText={errors.forecast?.message}
                                />
                            )}
                        />
                        <IconButton onClick={handleCancel} size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ height: '75vh', pb: 0, px: 0.5, }}>
                    <ProfileEditor
                        disabled={false}
                        profile={profile}
                        setProfile={setProfile}
                        editMode={editMode}
                        defaultTimezone={defaultTimezone}
                    />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                    <Button onClick={handleCancel} color='error'>Cancel</Button>
                    <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
