import React, { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { Box, Button } from '@mui/material';
import FileUploaderDialog from './FileUploaderDialog';
import axios from "axios";
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { DatabaseLayoutToolPanel } from "../ToolPanels/DatabaseLayoutToolPanel.js";
import { useRemoteLayout } from '../useRemoteLayout';
import { useData } from '../useData';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { ThemeContext } from '@emotion/react';
import useHeader from '../useHeader';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default React.memo(BidmanUtilityGrid);

function BidmanUtilityGrid({ node, oprDay, refreshAll, triggerRefresh }) {
  const { nodeId, gridDataApiEndpoint, submitApiEndpoint } = node;
  const guid = useLocalGuid();
  const gridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [fileUploaderOpen, setFileUploaderDialogOpen] = useState(false);
  const { rowData, getData } = useData();

  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  }


  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const headers = useHeader();

  const handleFileUploaderDialogOpen = () => {
    setFileUploaderDialogOpen(true);
  };

  const handleFileUploaderDialogClose = () => {
    setFileUploaderDialogOpen(false);
  };

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
  };

  const onBtCopyRange = () => {
    gridRef.current.api.copySelectedRangeToClipboard();
  };

  const onGetRowButtonClick = e => {
    const selectedNodes = gridRef.current.api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    enqueueSnackbar(`${JSON.stringify(selectedData)}`)
  }

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    enqueueSnackbar(`${JSON.stringify(rowData)}`)
  }

  // enables the fill handle
  const enableFillHandle = true;

  // enables undo / redo
  const undoRedoCellEditing = true;

  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;

  // enables flashing to help see cell changes
  const enableCellChangeFlash = true;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const denseGrid = 'dense-grid';
  const hourCells = 'hour-cells';

  const groupDefaultExpanded = -1;

  const onRefreshBtn = () => {
    getData(loadDataUri);
  };


  const gridOptions = {
    rowStyle: { p: '0', },
  };

  function onRowDataUpdated(params) {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    dynamicallyConfigureColumnsFromObject(rowData[0] ?? {});
  };

  function dynamicallyConfigureColumnsFromObject(anObject) {
    const keys = Object.keys(anObject)
    keys.forEach(key => colDefs.push({ field: key, editable: true }));
    gridRef.current.api.setColumnDefs(colDefs);
  }

  async function handleSaveButtonClick() {
    gridRef.current.api.stopEditing();

    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    //alert(`${submitApiEndpoint}userGuid=${accounts[0].localAccountId}&nodeId=${nodeId}`);

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`
    } else {
      url = `${apiUrlPrefix}/${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    }

    axios.post(
      url,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).then(response => {
      let message = 'Bids saved to database.';
      if (response.data !== 'Success') {
        message = response.data;
      } else {
        setDisableSaveButton(true);
      }
      enqueueSnackbar(message);
      triggerRefresh({ market: node.market });
    }).catch(err => enqueueSnackbar(`Error saving data in Bidman Utility Grid.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`));
  }

  const mockData = [{
    'Market': 'PJM',
    'Category': 'AEP',
    'SubAccount': 'PRE',
    'InternalName': 'AEP',
    'BidType': 'Demand',
    'HE1': {
      'toolTip': 'Hello Mark',
      'cellClass': 'problem-cell',
      'volume': '1',
      'price': '2.8'
    },
    'HE2': {
      'toolTip': 'Hello Mark',
      'cellClass': 'problem-cell',
      'volume': '1',
      'price': '2.8'
    },
    'HE3': {
      'toolTip': 'Hello Mark',
      'cellClass': 'problem-cell',
      'volume': '1',
      'price': '2.8'
    },
    'HE4': {
      'toolTip': 'Hello Mark',
      'cellClass': 'problem-cell',
      'volume': '1',
      'price': '2.8'
    },
  },]

  useEffect(() => {

    let abortController = new AbortController();
    // your async action is here  
    getData(loadDataUri);
    return () => {
      abortController.abort();
    }

  }, [oprDay]);

  useEffect(() => {
    if (refreshAll.market === node.market) { getData(loadDataUri); }
  }, [refreshAll]);

  function numberParser(params) {
    const newValue = params.newValue;
    let valueAsNumber;
    if (newValue === null || newValue === undefined || newValue === '') {
      valueAsNumber = null;
    } else {
      valueAsNumber = parseFloat(params.newValue);
    }
    return valueAsNumber;
  }

  function hourPivotComparator(a, b) {
    var requiredOrder = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
    return requiredOrder.indexOf(a) - requiredOrder.indexOf(b);
  }

  const aggFuncs = {
    // this overrides the grids built-in sum function
    'Float Sum': params => {
      let sum = 0;
      params.values.forEach(value => sum += parseFloat(value));
      return sum.toFixed(2);
    },
    'Bid Counts': params => {
      let count = 0;
      params.values.forEach((value) => {
        const groupNode =
          value !== null && value !== undefined && typeof value === 'object';
        if (groupNode) {
          count += value.count;
        } else {
          count++;
        }
      });

      return count / 24;
    },
    'Error Counts': params => {
      let count = 0;
      params.values.forEach((value) => {
        const groupNode =
          value !== null && value !== undefined && value === 'problem-cell';
        if (groupNode) {
          count += value.count;
        } else {
          count++;
        }
      });

      return count;
    },
  };

  function cellRenderer(params) {
    if (params.value) {
      if (params.value.cellClass == "problem-cell") { //'problem-cell') {
        return '<img title="' + params.value.cellToolTip + '" style="vertical-align:middle;" width="16px" height="16px" src="/redx.png"/><div style="vertical-align:middle; display:inline;">&nbsp' + params.value.Value + '</div>';
      } else {
        return '<img  title="' + params.value.cellToolTip + '" style="vertical-align:middle;" width="16px" height="16px" src="/greenCheck.jpg"/><div style="vertical-align:middle; display:inline;">&nbsp' + params.value.Value + '</div>';
      }
    }
  }

  const colDefs = [
    {
      editable: false,
      headerName: "Market",
      rowGroup: true,
      enableRowGroup: true,
      field: "Market",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Subaccount",
      rowGroup: true,
      enableRowGroup: true,
      field: "SubAccount",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Category",
      rowGroup: true,
      enableRowGroup: true,
      field: "Category",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Bid Type",
      rowGroup: true,
      enableRowGroup: true,
      field: "Bid_Type",
      sortable: true,
      filter: true,
    },
  ];

  const { dbLayoutPanel, loadLayout } = useRemoteLayout(gridRef, nodeId, colDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  const defaultColDef = useMemo(() => ({
    rowGroup: false,
    pivot: false,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout(params.api);
  }

  function onCellValueChanged(params) {
    setDisableSaveButton(false);
  }

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <FileUploaderDialog
          fileUploaderOpen={fileUploaderOpen}
          handleFileUploaderDialogClose={handleFileUploaderDialogClose}
          oprDay={oprDay}
          nodeId={nodeId}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <Button
            onClick={() => onBtCopyRows()}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Ctrl-c</Button>&nbsp;
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineOutlinedIcon />}
            id="undoBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={() => onBtCopyRange()}
          >
            Copy Range
          </Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetRowButtonClick}>Get Selected</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetGridDataButtonClick}>Get Data</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleSaveButtonClick}
            disabled={disableSaveButton}
          >Save All</Button>&nbsp;
        </Box>

        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          ref={gridRef}
          rowData={rowData}
          sideBar={sideBar}
          rowClass={denseGrid}
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          enableCellChangeFlash={enableCellChangeFlash}
          onCellValueChanged={onCellValueChanged}
          groupDefaultExpanded={groupDefaultExpanded}
          suppressCopyRowsToClipboard={true}
          aggFuncs={aggFuncs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onRowDataUpdated={onRowDataUpdated}
          onGridReady={onGridReady}
          rowGroupPanelShow={'always'}
          suppressAggFuncInHeader={true}
          enableRangeSelection={true}
          animateRows={true}
          rowSelection="multiple"
          components={{
            dbLayoutToolPanel: DatabaseLayoutToolPanel
          }}
        />
      </AgGridContainer>
    </div>
  );
};