import { useMemo, useCallback, useState, useEffect, memo, } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker, Polyline } from '@react-google-maps/api';
//import Marker from './FastPathPro/Dashboard/Marker';
import _ from 'lodash';
import { HubMarker } from './FastPathPro/Dashboard/HubMarker_v2';
import AddLabelDialog from './FastPathPro/Dashboard/AddLabelDialog';
import axios from 'axios';
import { apiUrlPrefix } from '../authConfig';
import useHeader from './useHeader';
import { useSnackbar } from 'notistack';

const PathMap = ({ data, pors, pods, selectedPath, setSelectedNodes, selectedNodes, hubs = [], allowAddNew = false, refreshHubs }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCL7pD9-yZH8UpCnXDSvJ3uC39YZCzeoR4"
  })

  const [map, setMap] = useState(null)
  const [openAddNew, setOpenAddNew] = useState(false);
  const [newMarker, setNewMarker] = useState({});
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const options = {
    //scrollwheel: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    //imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    //disableDoubleClickZoom: true,
  }

  const mapContainerStyle = {
    height: "100%",
    width: "100%"
  }

  const markerOptions = {
    // strokeColor: '#FF0000',
    // strokeOpacity: 0.8,
    // strokeWeight: 2,
    //fillColor: '#FF0000',
    //fillOpacity: 0.35,
    clickable: true,
    // draggable: false,
    // editable: false,
    visible: true,
    // radius: 30000,
    // zIndex: 1
  };

  const lineOptions = (line) => ({
    geodesic: true,
    strokeColor: pathContainsLeg(line) ? 'red' : 'grey',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    //fillColor: pathContainsLeg(line) ? 'red' : 'grey',
    fillOpacity: 0.8,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: 30000,
    // zIndex: 1,
    icons: [
      {
        icon: {
          path: 'M 0,0 0,1',
          strokeOpacity: 1,
          strokeWeight: 2,
          scale: 3,
        },
        repeat: '10px',
      },
    ],
  });

  const legs = useMemo(() => {
    return data.reduce((acc, path) => {
      const topoJson = JSON.parse(path.typoJson ?? '[]');
      return _.unionWith(acc, topoJson, _.isEqual)
    }
      , []);
  }, [data])

  const markers = useMemo(() => {
    const all = legs.reduce((acc, leg) =>
      _.unionWith(acc, [
        {
          name: leg.porName,
          lat: parseFloat(leg.porLat),
          lng: parseFloat(leg.porLng),
        },
        {
          name: leg.podName,
          lat: parseFloat(leg.podLat),
          lng: parseFloat(leg.podLng),
        },
      ], _.isEqual)
      , []);

    //filter out hubs
    //return all.filter(marker => !hubs.find(hub => hub.PNode === marker.name));
    return all;
  }, [legs, hubs]);

  const lines = useMemo(() => {
    return legs.map(leg => [
      {
        lat: parseFloat(leg.porLat),
        lng: parseFloat(leg.porLng),
        name: leg.porName,
      },
      {
        lat: parseFloat(leg.podLat),
        lng: parseFloat(leg.podLng),
        name: leg.podName,
      }
    ])
  }, [legs]);

  useEffect(() => {
    if (markers?.length && isLoaded) {
      var bounds = new window.google.maps.LatLngBounds();

      markers.forEach(marker => {
        bounds.extend(marker);
      });

      map?.fitBounds(bounds);
      map?.setZoom(5);
    }
  }, [markers, map])

  function getMarkerIcon(name) {
    if (pods.includes(name)) {
      return "http://maps.google.com/mapfiles/ms/icons/red.png"
    } else if (pors.includes(name)) {
      return "http://maps.google.com/mapfiles/ms/icons/green.png"
    } else if (selectedNodes.includes(name)) {
      return "http://maps.google.com/mapfiles/ms/icons/yellow.png"
    } else {
      return "http://maps.google.com/mapfiles/ms/icons/blue.png";
    }
  }

  function pathContainsLeg(leg) {
    const pathLegs = JSON.parse(selectedPath?.typoJson ?? '[]');
    return !!pathLegs.find(pathLeg =>
      (pathLeg.porName === leg[0].name && pathLeg.podName === leg[1].name)
    );
  }

  function handleMarkerClick(marker) {
    if (!pors.includes(marker.name) && !pods.includes(marker.name)) {
      if (selectedNodes.includes(marker.name)) {
        setSelectedNodes(selectedNodes.filter(node => marker.name !== node));
      } else {
        setSelectedNodes([...selectedNodes, marker.name]);
      }
    }
  }

  function handleAddNew(e) {
    if (!allowAddNew) return;

    const { lat, lng } = e.latLng;
    setNewMarker({
      lat: lat(),
      lng: lng(),
    });
    setOpenAddNew(true);
  }

  function handleDragEnd(name, e) {
    if (!allowAddNew) return;

    const { lat, lng } = e.latLng;
    saveNewMarker({
      label: name,
      lat: lat(),
      lng: lng(),
    })
  }

  async function saveNewMarker(data) {
    setOpenAddNew(false);
    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SavePnodeLocations&parm=${headers.userGuid}&parm=${data.label}&parm=${data.lat ?? newMarker.lat}&parm=${data.lng ?? newMarker.lng}`;

    const options = {
      headers: headers,
      url: saveUri,
    }

    return axios(options).then(response => {
      refreshHubs();
    }).catch(err => { enqueueSnackbar(`Error saving new hub marker.  ${err.response?.data ?? err.message}`, { variant: 'error', }) });
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      // center={center}
      zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
      onDblClick={handleAddNew}
    >
      <AddLabelDialog
        open={openAddNew}
        handleClose={() => setOpenAddNew(false)}
        handleSave={saveNewMarker}
        title={'Add New Marker'}
        contentText={'Enter a name for this marker.'}
        fieldName={'Name'}
      />
      {markers.map((marker, index) => (
        <Marker
          key={index}
          options={markerOptions}
          icon={getMarkerIcon(marker.name)}
          onClick={() => handleMarkerClick(marker)}
          title={marker.name}
          position={marker}
        />
      ))}
      {lines.map((line, index) => (
        <Polyline
          key={index}
          // path={[{ lat: segment.porLat, lng: segment.porLng},{ lat: segment.podLat, lng: segment.podLng}]}
          path={line}
          // label={path.typoJson}
          // title={path.typoJson}
          options={lineOptions(line)}
        />
      ))}
      {hubs.map((hub, index) => (
        <HubMarker
          position={{ lat: parseFloat(hub.Latitude), lng: parseFloat(hub.Longitude) }}
          hubInfo={hub}
          onDragEnd={(e) => handleDragEnd(hub.PNode, e)}
          draggable={allowAddNew}
        />
      ))}
    </GoogleMap>
  ) : <></>
}

export default memo(PathMap);