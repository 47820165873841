import { useState, useMemo, useRef, } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix } from '../../authConfig';
import useHeader from '../useHeader';
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact, } from 'ag-grid-react';
import useStalkerGridLayout from './Stalker/useStalkerGridLayout';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
import { Box, Button, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from './ConfirmationDialog';
import TriggerOnChangeDialog from './Stalker/TriggerOnChangeDialog';
import BugReportIcon from '@mui/icons-material/BugReport';
import { userGroups } from '../../authConfig.js';
import GroupGuard from '../Guards/GroupGuard.js';
import moment from 'moment';
import { stringIntComparator } from '../../utils/gridFunctions';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import useHubAction from '../HubContext/useHubAction_v2';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const gridRef = useRef();
  const newRows = useRef();
  const storageLocation = `watcher-grid-user-layout`;
  const [loaded, setLoaded] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [triggerInsert, setTriggerInsert] = useState(false);

  const { diffData, } = useHubAction({
    action: handleHubFetch,
    allowedMessages: ['trg_tsrDispatchQueueUpdate', 'PowerStationMetaData.WJ_TSR_DispatchQueueUpdate'],
    predicate: (gridRef.current?.api),
    callbackDependencies: [gridRef.current?.api],
  });

  async function handleHubFetch() {
    enqueueSnackbar('Trigger changes detected. Refreshing...', { key: 'hub-refresh-snackbar', preventDuplicate: true, });
    return fetch().then(response => {
      handleDataUpdate(response.data, gridRef.current.api);
    });
  }

  async function fetch() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_FetchWatchList&parm=${guid}`,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading trigger watchlist. ${error.message}`)
    });
  }

  function handleDataUpdate(newData, gridApi) {
    const timestamp = moment();
    const oldData = [];
    gridApi.forEachNode(node => oldData.push(node.data));

    const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, 'Id');

    console.log(`Watcher data diff complete. ${moment().diff(timestamp)}ms elapsed.`)

    gridApi.applyTransaction({
      addIndex: 0,
      add: toAdd,
      update: toUpdate,
      remove: toDelete,
    })

    setLoaded(true);
  }

  function formatData(trigger) {
    const tsrData = JSON.parse(trigger.CreateTSR_JSON);
    return {
      ...tsrData,
      Id: trigger.Id,
      PointOfReceipt: tsrData.POR,
      PointOfDelivery: tsrData.POD,
      PathName: tsrData.Path_Name,
      ServiceIncrement: tsrData.Service_Increment,
      TSClass: tsrData.TS_Class,
      TSPeriod: tsrData.TS_Period,
      TSSubclass: tsrData.TS_Subclass,
      TSType: tsrData.TS_Type,
      TSWindow: tsrData.TS_Window,
      timezone: trigger.TimeZone,
      CUSTOMER_COMMENTS: tsrData.Customer_Comments,
      CustomerCode: tsrData['Customer Code'] ?? tsrData.CustomerCode,
      SaleRef: tsrData['Sale Ref'],
      arefs: trigger.Trigger_Aref,
      statusChangeTime: trigger.Trigger_StatusChangeTimeUtc,
    }
  }

  function handleEditClick() {
    setTriggerInsert(false);
    setOpenEditDialog(true);
  }

  function handleCopyClick() {
    setTriggerInsert(true);
    setOpenEditDialog(true);
  }

  function handleDelete() {
    setOpenConfirmationDialog(true);
  }

  function handleTest() {

    const testRow = gridRef.current.api.getSelectedRows()[0];
    const testId = testRow.Id;
    enqueueSnackbar('Test Running...')
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_testSpyMonkeyRules&parm=${guid}&parm=${testId}`,
    }

    axios(options).then(respone => {
      enqueueSnackbar('Test Completed...')
    }).catch(error => {
      enqueueSnackbar(`Error testing selected trigger. ${error.message}`)
    });


  }

  const deleteMessage = useMemo(() => {
    const deleteId = gridRef.current?.api.getSelectedRows()[0]?.Id;
    return `You are about to delete the trigger with ID ${deleteId} forever. Are you sure you want to continue?`
  }, [selectedTrigger])

  function handleDeleteConfirmation() {
    const deleteRow = gridRef.current.api.getSelectedRows()[0];
    const deleteId = deleteRow.Id;

    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TSR_DispatchQueueTrigger_Delete&parm=${deleteId}&parm=${guid}`,
    }

    axios(options).then(respone => {
      gridRef.current.api.applyTransaction({
        remove: [deleteRow],
      })
    }).catch(error => {
      enqueueSnackbar(`Error deleting selected trigger. ${error.message}`)
    });

    setOpenConfirmationDialog(false);
  }

  async function handleTriggerEdit(triggerTsr, confirmed = 0) {
    const params = {
      userGuid: guid,
      timeZone: triggerTsr.timezone,
      dispatchQueueRuleID: triggerTsr.dispatchQueueRuleID,
      id: triggerTsr.Id,
      arefs: triggerTsr.arefs,
      label: triggerTsr.label,
      data: triggerTsr,
    }
    const url = triggerInsert
      ? `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TSR_DispatchQueueTrigger_Insert&parm=${guid}&parm=${params.timeZone}&parm=${params.dispatchQueueRuleID}&parm=${params.arefs}&parm=${params.label}&parm=${confirmed}`
      : `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TSR_DispatchQueueTrigger_Update&parm=${guid}&parm=${params.timeZone}&parm=${params.dispatchQueueRuleID}&parm=${params.id}&parm=${params.arefs}&parm=${params.label}&parm=${confirmed}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error saving TSR trigger. ${error.message}`)
    });
  }

  function dateComparator(date1, date2) {
    return moment(date1).diff(moment(date2));
  }

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      headerName: "ID",
      // enableRowGroup: true,
      field: "Id",
      initialWidth: 100,
      initialHide: false,
    },
    {
      headerName: "Batch",
      field: "batchKey",
      // enableRowGroup: true,
      // rowGroup: true,
      initialWidth: 200,
      initialHide: true,
      // valueFormatter: params => `${params.node.allLeafChildren[0].data.label ?? params.value}`
    },
    {
      headerName: "Label",
      field: "label",
      initialWidth: 200,
      initialHide: true,
    },
    {
      headerName: "Created By",
      field: "createdBy",
      initialWidth: 200,
      initialHide: false,
    },
    {
      headerName: "Trigger Aref",
      field: "Trigger_Aref",
      comparator: stringIntComparator,
      initialWidth: 120,
      initialHide: false,
    },
    {
      headerName: "Trigger TSR Status",
      field: "Status",
      initialWidth: 150,
      initialHide: false,
    },
    {
      headerName: "New Aref",
      field: "TSR_NewAssignmentRef",
      comparator: stringIntComparator,
      initialWidth: 120,
      initialHide: false,
    },
    {
      headerName: "New TSR Status",
      field: "NewTsrStatus",
      initialWidth: 120,
      initialHide: false,
    },
    {
      headerName: "Path Name",
      field: "PathName",
      initialWidth: 300,
      initialHide: false,
    },
    {
      headerName: "Rule Id",
      field: "dispatchQueueRuleID",
      initialWidth: 100,
      initialHide: true,
    },
    {
      headerName: "Rule Name",
      field: "DispatchRuleName",
      initialWidth: 300,
      initialHide: false,
    },
    {
      headerName: "Rule Description",
      field: "DispatchRuleDescription",
      initialWidth: 300,
      initialHide: true,
    },
    {
      headerName: "Queue Status",
      field: "DispatchQueueStatus",
      initialWidth: 150,
      initialHide: false,
    },
    {
      headerName: "Executed At",
      field: "ExecutedAtUTC",
      initialWidth: 200,
      initialHide: false,
    },
    {
      headerName: "Completed At",
      field: "CompletedAtUTC",
      initialWidth: 200,
      initialHide: false,
    },
    {
      headerName: "Expiration Time",
      field: "Trigger_ExpirationTimeUtc",
      initialWidth: 200,
      initialHide: false,
    },
    {
      headerName: "Status Change Time",
      field: "Trigger_StatusChangeTimeUtc",
      initialWidth: 200,
      initialHide: true,
    },
    {
      headerName: "Customer Email",
      field: "CUSTOMER_EMAIL",
    },
    {
      headerName: "Customer Name",
      field: "CUSTOMER_NAME",
    },
    {
      headerName: "Seller Comments",
      field: "SELLER_COMMENTS",
    },
    {
      headerName: "Time Of Last Update",
      field: "TimeOfLastUpdate",
    },
    {
      headerName: "Time Queued",
      field: "TimeQueued",
      comparator: dateComparator,
    },
    {
      headerName: "Timezone",
      field: "TimeZone",
    },
  ], []);

  const { layoutPanel, colDefs, loadLayout } = useStalkerGridLayout(storageLocation, gridRef, baseColDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        },
        layoutPanel,
      ],
      position: 'right',
      //defaultToolPanel: 'filters'
    }
  }, []);

  function onGridReady(params) {
    fetch().then(response => {
      handleDataUpdate(response?.data ?? [], params.api);
      gridRef.current.api?.hideOverlay();
    })
    loadLayout();
  }

  function onRowDataUpdated(params) {
    if (newRows.current?.length && loaded) {
      const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
      params.api.flashCells({
        rowNodes: nodes,
        flashDelay: 1000,
        fadeDelay: 2000,
      });
      newRows.current = undefined;
    }
  }

  function getRowId(params) {
    return params.data.Id;
  }

  function onSelectionChange(params) {
    const selected = gridRef.current.api.getSelectedRows() ?? [];
    const trigger = selected.length > 0 ? formatData(selected[0]) : false;
    setSelectedTrigger(trigger);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  const autoGroupColumnDef = useMemo(() => ({
    headerName: 'Batch',
    minWidth: 220,
    cellRendererParams: {
      //suppressCount: true,
      checkbox: true,
    }
  }), [])

  const gridOptions = useMemo(() => ({
    rowClassRules: {
      "row-triggered": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['QUEUED',].includes(params.data?.NewTsrStatus),
      "row-confirmed": params => ['Success', 'ATC Levels Triggered Rule'].includes(params.data?.DispatchQueueStatus) && ['CONFIRMED', 'STUDY'].includes(params.data?.NewTsrStatus),
      "row-counteroffer": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['COUNTEROFFER',].includes(params.data?.NewTsrStatus),
      "row-invalid": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['INVALID',].includes(params.data?.NewTsrStatus),
      "row-refused": params => ['Success', 'ATC Levels Triggered Rule'].includes(params.data?.DispatchQueueStatus) && ['REFUSED'].includes(params.data?.NewTsrStatus),
      "row-withdrawn": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['WITHDRAWN'].includes(params.data?.NewTsrStatus),
      "row-staged": params => !['CONFIRMED',].includes(params.data?.Status) && ['Staged for Trigger',].includes(params.data?.DispatchQueueStatus),
      "row-alert": params => ['Alert me to Competition',].includes(params.data?.DispatchRuleName) && ['Staged for Trigger',].includes(params.data?.DispatchQueueStatus),
      // "row-success": params => ['Success'].includes(params.data.DispatchQueueStatus) && ![''].includes(params.data.TSR_NewAssignmentRef),
      // "row-queued": params => ['Success', ].includes(params.data.DispatchQueueStatus) || ['Staged for Trigger', ].includes(params.data.Status),
      // "row-is-queued": params => params.data.Status === 'QUEUED'
    },
  }), []);

  const selectedTsrs = useMemo(() => [selectedTrigger], [selectedTrigger]);

  return (
    <Box
      sx={{
        p: '7px',
        flexGrow: 1,
      }}
    >
      <TriggerOnChangeDialog
        open={openEditDialog}
        closeDialog={() => setOpenEditDialog(false)}
        handleSave={handleTriggerEdit}
        tsrs={selectedTsrs}
        arefs={selectedTrigger.Trigger_Aref}
        enableChangeAref={triggerInsert}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={deleteMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleDeleteConfirmation}
      />
      <Box sx={{ display: 'flex', p: 1, }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={1}>
            <Button
              endIcon={<EditIcon />}
              fullWidth
              size={'small'}
              variant={'contained'}
              color={'primary'}
              onClick={handleEditClick}
              disabled={!selectedTrigger}
            >Edit</Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              endIcon={<ContentCopyIcon />}
              fullWidth
              size={'small'}
              variant={'contained'}
              color={'primary'}
              onClick={handleCopyClick}
              disabled={!selectedTrigger}
            >Copy</Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              endIcon={<DeleteForeverIcon />}
              fullWidth
              size={'small'}
              variant={'contained'}
              color={'primary'}
              onClick={handleDelete}
              disabled={!selectedTrigger}
            >Delete</Button>
          </Grid>
          <GroupGuard groups={[userGroups.admins]}>
            <Grid item xs={1}>
              <Button
                endIcon={<BugReportIcon />}
                fullWidth
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={handleTest}
                disabled={!selectedTrigger}
              >Test</Button>
            </Grid>
          </GroupGuard>
        </Grid>
      </Box>
      <AgGridContainer
        style={{
          width: "98%", height: '85vh'
        }}
      >
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          getRowId={getRowId}
          onRowDataUpdated={onRowDataUpdated}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          sideBar={sideBar}
          enableCellTextSelection={true}
          paginationAutoPageSize={true}
          groupDefaultExpanded={1}
          autoGroupColumnDef={autoGroupColumnDef}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          onSelectionChanged={onSelectionChange}
          enableRangeSelection={true}
          rowSelection='single'
          //onCellFocused={onCellFocused}
          animateRows={true}
          groupSelectsChildren={true}
          components={{
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </Box>
  )
}
