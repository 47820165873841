import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import { useData } from '../useData';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import Button from '@mui/material/Button';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { AgGridReact } from 'ag-grid-react';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import useGridLayout from '../useGridLayout';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import { defaultStatusBar } from '../AgGrid/defaultGridProps';

export default (props) => {
  const theme = useTheme();
  const ref = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const dataUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_WorkflowsAuditFetch&parm=${guid}`;
  const { rowData, getData } = useData();

  useEffect(() => {
    //getData();
    let abortController = new AbortController();
    // your async action is here  
    getData(dataUri);
    return () => {
      abortController.abort();
    }
  }, []);

  const onBtnExport = () => {
    ref.current.api.exportDataAsCsv();
  };

  const onRefreshBtn = () => {
    getData(dataUri);
    let message = 'Audit table refreshing...';
    enqueueSnackbar(message);
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },
  };

  const statusBar = useMemo(() => defaultStatusBar, []);

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      headerName: "ID",
      headerCheckboxSelection: true,
      field: "workflowAuditID",
      flex: 1,
    },
    {
      headerName: "Workflow",
      field: "name",
      flex: 3,
    },
    {
      headerName: "Time",
      field: "datetimeStamp",
      flex: 2,
    },
    {
      headerName: "Error",
      field: "error",
      flex: 2,
    },
    {
      headerName: "Message",
      field: "userMessage",
      flex: 4,
    },
  ], []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: "agMultiColumnFilter",
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  const storageLocation = 'workflow-audit-grid-layout';
  const { layoutPanel, loadLayout, colDefs } = useGridLayout(storageLocation, ref, baseColDefs, defaultColDef);

  const workflowAuditSideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        layoutPanel,
      ],
      position: 'right',
    }
  }, []);

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <AgGridContainer
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack direction="row" spacing={2} sx={{ paddingBottom: 1, }}>
        <Tooltip title="Refresh the grid of user groups." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>
        <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineIcon />}
            id="csv"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>
        </Tooltip>
      </Stack>
      <AgGridReact
        ref={ref}
        columnDefs={colDefs}
        rowData={rowData}
        onGridReady={onGridReady}
        statusBar={statusBar}
        rowSelection="multiple"
        rowDragMultiRow={true}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        defaultColDef={defaultColDef}
        sideBar={workflowAuditSideBar}
        gridOptions={gridOptions}
        tooltipShowDelay={0}
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        animateRows={true}
        groupSelectsChildren={true}
        components={{
          layoutToolPanel: LayoutToolPanel,
        }}
      />
    </AgGridContainer>
  );
};