import styled from '@mui/material/styles/styled';
import Collapse from '@mui/material/Collapse';

//wraps the MUI Collapse component and applies flex styles to the children.
const FlexCollapse = (props) => {
    return (
        <StyledCollapse {...props} sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: props.in ? 1 : 0,
        }} />
    )
}

const StyledCollapse = styled(Collapse)(({ theme, ...props }) => {
    return {
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        '& .MuiCollapse-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
    }
});

export default FlexCollapse;
