import { Tooltip, AppBar, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Table, TableContainer, TableHead, } from "@mui/material"
import setTSValues from "../../../utils/SetTSValues";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import ProfileDialog from "../../ProfileDialog";
import { Collapse, Typography, ListItemText, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Button, Toolbar, IconButton, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState, useEffect, useMemo, useRef, memo, useCallback, useReducer, useContext } from 'react'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { apiUrlPrefix, userGroups } from "../../../authConfig";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import useFilteredOptions from "../../TSRActivity/useFilteredOptions";
import useRequiredFields from "../../FastPathPro/useRequiredFields";
import { useSnackbar } from "notistack";
import { logAction } from "../../../utils/auditLogger";
import moment from "moment";
import { debounce } from 'lodash';
import axios from "axios";
import useHeader from "../../useHeader";
import { UserContext } from "../../../data/fetchUserGroups";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import momentTimezones from "../../momentTimezones";

function tryParseJSONObject(jsonString) {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }

  return false;
};

const RedirectDialog = (props) => {
  const { open, closeDialog, tsr, timezone, startDate, stopDate } = props;
  const configStorageKey = 'da-redirect-dialog-config';
  let savedValues = tryParseJSONObject(localStorage.getItem(configStorageKey));
  savedValues = savedValues ? savedValues : {};
  const defaultConfig = {
    timezone: timezone,
    requestType: 'REDIRECT',
    ...savedValues,
    scheduleDateTime: moment().add(2, 'minutes'),
  }
  const [redirect, setRedirect] = useState(defaultConfig);
  const [showMore, setShowMore] = useState(false);
  const { providers, filteredCombos, filteredTSCombos, customerCodes, statuses, } = useFilteredOptions({ ...redirect, POR: redirect.PointOfReceipt, POD: redirect.PointOfDelivery, });
  const { hasMissingFields, isMissing, hasMissing } = useRequiredFields({ Provider: 'Provider', PointOfReceipt: 'POR', PointOfDelivery: 'POD', CustomerCode: 'Customer Code', Service_Increment: 'Service Increment', timezone: "Timezone", TS_Type: 'TS Type', TS_Period: 'TS Period', TS_Window: 'TS Window', TS_Class: 'TS Class', profileInfo: 'Profile Info', Service: 'Service', })
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const headers = useHeader();
  const [serviceCombos, setServiceCombos] = useState([]);

  const groups = useContext(UserContext)
  const canScheduleFuture = groups.includes(userGroups.dayaheadscheduledredirects) || groups.includes(userGroups.admins);

  async function handleSave(tsr, confirmed = 0) {
    const params = {
      userGuid: guid,
      timeZone: timezone,
      TargetExecutionTime: tsr.scheduleDateTime.tz(momentTimezones[timezone]).format('MM/DD/YYYY HH:mm') ?? '',
      application: `Day Ahead Redirects`,
      data: formatEventJson(tsr),
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${guid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=redirect&parm=${confirmed}&parm=${params.application}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    axios(options).then(response => {
      if (confirmed) {
        enqueueSnackbar('Successfully submitted Redirect TSR for submission to OASIS.')
      } else {
        setOpenConfirmationDialog(true);
        setConfirmationMessage(response.data);
      }
    }).catch(error => {
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
    });

    if (confirmed) {
      setOpenConfirmationDialog(false);
    }
  }

  useEffect(() => {
    handleChange('timezone', timezone);
  }, [timezone])

  useEffect(() => {
    handleChange('scheduleDateTime', moment().add(2, 'minutes'));
  }, [redirect.scheduleFuture])

  useEffect(() => {
    if (tsr) {
      const options = {
        method: 'GET',
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ServiceTemplateProviderFetch&parm=${guid}&parm=Day Ahead Redirects&parm=${tsr.Provider}`,
      }

      axios(options).then(response => {
        setServiceCombos(response.data);
      }).catch(error => {
        enqueueSnackbar(`Error loading service template values. Message: ${error}`)
      });
    }
  }, [tsr])

  useEffect(() => {
    let serviceData = {};
    if (serviceCombos.length) {
      const defaultService = serviceCombos.find(svc => svc.default);
      serviceData = defaultService ? getServiceData(defaultService.Service) : {};
    }

    const newRedirect = {
      ...tsr,
      ...defaultConfig,
      PointOfReceipt: '',
      PointOfDelivery: '',
      Path_Name: '',
      Source: '',
      Sink: '',
      ...serviceData,
    }

    setRedirect(newRedirect);
  }, [tsr, serviceCombos, open])

  function saveConfig(key, value) {
    if (['CustomerCode', 'SaleRef'].includes(key)) {
      localStorage.setItem(configStorageKey, JSON.stringify({
        CustomerCode: redirect.CustomerCode,
        SaleRef: redirect.SaleRef,
        [key]: value,
      }))
    }
  }

  const capacityRemaining = useMemo(() => {
    return JSON.parse(redirect.availabilityData ?? '[]').map(block => ({
      capacityRequested: block.availability,
      startDateTime: moment(moment.parseZone(block.dateTime).format('MM/DD/YYYY HH:mm')),
    }))
  }, [redirect.availabilityData])

  function handleChange(key, value) {
    saveConfig(key, value);

    setRedirect({
      ...redirect,
      [key]: value
    })
  }

  function handleEditProfile() {
    setOpenProfileDialog(true);
  }

  function handleProfileUpdate(info, config) {
    setOpenProfileDialog(false);

    const newTsr = {
      ...redirect,
      profileInfo: info,
      profileConfig: config,
    }

    setRedirect(newTsr)
  }

  function handleSaveClick() {
    const hasMissing = hasMissingFields(redirect);
    if (hasMissing) {
      enqueueSnackbar(hasMissing);
    } else {
      setOpenConfirmationDialog(true);
      setLoadingConfirmation(true);
      handleSave(redirect).then(response => {
        if (response) {
          setConfirmationMessage(response.data);
          setLoadingConfirmation(false);
        }
      });
    }
  }

  const handleConfirmation = useCallback(debounce(() => {
    handleClose();
    handleSave(redirect, 1).then(response => {
      logAction('User confirmed redirect', 'Redirect Dialog', confirmationMessage);
      setOpenConfirmationDialog(false);
    })
  }, 2000, { leading: true, }), [confirmationMessage, redirect])

  function createProfileConfig(tsr) {
    const info = tsr.profileInfo;
    const startDates = info.map(block => moment(block.startDateTime));
    const endDates = info.map(block => moment(block.endDateTime));
    const startDate = startDates.sort((a, b) => a - b)[0];
    const endDate = endDates.sort((a, b) => b - a)[0];
    return {
      startDate: startDate.format('MM/DD/YYYY'),
      startHour: startDate.format('HH'),
      stopDate: endDate.format('MM/DD/YYYY'),
      stopHour: endDate.format('HH'),
      defaultPrice: info[0] ? info[0].price : 0,
      defaultCapacity: info[0] ? info[0].capacityRequested : 0,
    };
  }

  function formatEventJson(event) {
    return {
      Id: event.Id,
      arefs: event.arefs,
      label: event.label,
      'SequenceId': 1,
      'jsonVersion': 2,
      'Customer_Comments': event.CUSTOMER_COMMENTS,
      //'TargetExecutionTime': event.TargetExecutionTime,
      'Provider': event.Provider,
      'Customer Code': event.CustomerCode,
      'Sale Ref': event.SaleRef,
      //'Related Ref': '???',
      //'Deal Ref': '???',
      'Path_Name': event.Path_Name ?? event.PathName,
      'Source': event.Source,
      'Sink': event.Sink,
      'POR': event.PointOfReceipt,
      'POD': event.PointOfDelivery,
      //'Service': event.service,
      'Service_Increment': event.Service_Increment,
      'TS_Class': event.TS_Class,
      'TS_Type': event.TS_Type,
      'TS_Period': event.TS_Period,
      'TS_Window': event.TS_Window,
      'TS_Subclass': event.TS_Subclass,
      'Preconfirmed': event.Preconfirmed ? 'Yes' : 'No',
      'profileInfo': event.profileInfo,
      'profileConfig': event.profileConfig,
      'timezone': event.timezone,
      'dispatchQueueRuleID': event.dispatchQueueRuleID,
      'requestType': event.requestType,
      'Related Ref': event.relatedRef ?? event.AssignmentRef,
      //'ruleParams': event.ruleParams,
    }
  }

  const renderTimezones = {
    'Pacific': 'Pacific Standard Time',
    'Pacific Standard Time': 'Pacific',
    'Eastern': 'Eastern Standard Time',
    'Eastern Standard Time': 'Eastern',
    'Central': 'Central Standard Time',
    'Central Standard Time': 'Central',
    'Mountain': 'Mountain Standard Time',
    'Mountain Standard Time': 'Mountain',
  }

  function getServiceData(svc) {
    const combo = serviceCombos.find(combo => combo.Service === svc);
    const fields = ['Service', 'Service_Increment', 'TS_Class', 'TS_Period', 'TS_Subclass', 'TS_Type', 'TS_Window'];
    const data = {};
    fields.forEach(field =>
      data[field] = combo[field]
    );
    data.TS_Subclass = combo.TS_Subclass === '' ? setTSValues(combo.Service, redirect.Provider) : combo.TS_Subclass;
    return data;
  }

  function handleServiceChange(svc) {
    const data = getServiceData(svc);
    setRedirect({
      ...redirect,
      ...data,
    })
  }

  function handleTemplateSelect(template) {

  }

  function handleClose() {
    setRedirect({
      ...redirect,
      profileInfo: [],
    })
    closeDialog();
  }

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth>
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
      />
      <DialogTitle></DialogTitle>
      <DialogContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {openProfileDialog && <ProfileDialog
          open={openProfileDialog}
          closeDialog={() => setOpenProfileDialog(false)}
          currentEvent={redirect}
          handleProfileSave={handleProfileUpdate}
          pathData={[]}
          defaultInfo={redirect.profileInfo ?? []}
          maxInfo={capacityRemaining}
          defaultValues={{
            startDate: startDate,
            stopDate: stopDate,
            minDateTime: startDate,
            maxDateTime: stopDate,
          }}
          version={2}
        />}
        <ConfirmationDialog
          open={openConfirmationDialog}
          message={confirmationMessage}
          loading={loadingConfirmation}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirmation={handleConfirmation}
          cancelText={'CANCEL'}
          confirmText={'CONFIRM REDIRECT'}
        />
        <Box sx={{ p: 1, }}>
          {showMore
            ? (<Grid container justifyItems='start' alignItems='center' spacing={2}>
              <Grid item xs={12}>
                <Button
                  startIcon={<ChevronLeftIcon />}
                  variant="contained"
                  size='small'
                  onClick={() => setShowMore(false)}
                >Back</Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  placeholder="Customer Comments"
                  value={redirect.CUSTOMER_COMMENTS}
                  rows={5}
                  variant='outlined'
                  onChange={(e) => handleChange('CUSTOMER_COMMENTS', e.target.value)}
                  label='Customer Comments'
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.tsSubclasses}
                  value={redirect.TS_Subclass}
                  onChange={(_, newValue) => {
                    handleChange('TS_Subclass', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Subclass"
                      color="success"
                      placeholder="TS Subclass"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  options={filteredTSCombos.serviceIncrements}
                  value={redirect.Service_Increment}
                  onChange={(_, newValue) => {
                    handleChange('Service_Increment', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Service Increment"
                      size='small'
                      color="success"
                      placeholder="Service Increment"
                      error={hasMissing && isMissing(redirect, 'Service_Increment')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.tsClasses}
                  value={redirect.TS_Class}
                  onChange={(_, newValue) => {
                    handleChange('TS_Class', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Class"
                      color="success"
                      placeholder="TS Class"
                      error={hasMissing && isMissing(redirect, 'TS_Class')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.tsTypes}
                  value={redirect.TS_Type}
                  onChange={(_, newValue) => {
                    handleChange('TS_Type', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Type"
                      color="success"
                      placeholder="TS Type"
                      error={hasMissing && isMissing(redirect, 'TS_Type')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.tsPeriods}
                  value={redirect.TS_Period}
                  onChange={(_, newValue) => {
                    handleChange('TS_Period', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Period"
                      color="success"
                      placeholder="TS Period"
                      error={hasMissing && isMissing(redirect, 'TS_Period')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.tsWindows}
                  value={redirect.TS_Window}
                  onChange={(_, newValue) => {
                    handleChange('TS_Window', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="TS Window"
                      color="success"
                      placeholder="TS Window"
                      error={hasMissing && isMissing(redirect, 'TS_Window')}
                    />
                  )}
                />
              </Grid>
            </Grid>)
            : (<Grid container justifyContent="center" alignItems='center' spacing={2}>
              {/*<Grid item xs={3}>
              <TemplateAutocomplete 
                templateTypeId={3}
                handleChange={handleTemplateSelect}
                fullWidth
                textFieldProps={{
                  size: "small",
                  variant: "outlined", 
                  label: "Related Ref", 
                  color: "success", 
                }}
              />
            </Grid>*/}
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  //options={relatedRefs.map(obj => obj.AssignmentRef)}
                  options={[]}
                  disabled
                  value={redirect.AssignmentRef ?? ''}
                  onChange={(_, newValue) => {
                    handleChange('relatedRef', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Related Ref"
                      color="success"
                      //helperText={getRelatedRefHelperText()}
                      onChange={e => handleChange('relatedRef', e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  disabled
                  options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                  value={renderTimezones[redirect.timezone]}
                  onChange={(_, newValue) => {
                    handleChange('timezone', renderTimezones[newValue]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Timezone"
                      size='small'
                      color="success"
                      placeholder="Timezone"
                      error={hasMissing && isMissing(redirect, 'timezone')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  freeSolo
                  fullWidth
                  disabled
                  options={providers}
                  value={redirect.Provider}
                  onInputChange={(_, newValue) => {
                    handleChange('Provider', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Provider"
                      color="success"
                      placeholder="Provider"
                      error={hasMissing && isMissing(redirect, 'Provider')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  endIcon={<ChevronRightIcon />}
                  variant="contained"
                  fullWidth
                  onClick={() => setShowMore(true)}
                >More</Button>
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredCombos.pors}
                  value={redirect.PointOfReceipt}
                  onInputChange={(_, newValue) => {
                    handleChange('PointOfReceipt', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POR"
                      color="success"
                      placeholder="POR"
                      error={hasMissing && isMissing(redirect, 'PointOfReceipt')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  freeSolo
                  options={filteredCombos.pods}
                  value={redirect.PointOfDelivery}
                  onInputChange={(_, newValue) => {
                    handleChange('PointOfDelivery', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POD"
                      color="success"
                      placeholder="POD"
                      error={hasMissing && isMissing(redirect, 'PointOfDelivery')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  freeSolo
                  fullWidth
                  options={filteredCombos.sources}
                  value={redirect.Source}
                  onInputChange={(_, newValue) => {
                    handleChange('Source', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Source"
                      color="success"
                      placeholder="Source"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  freeSolo
                  fullWidth
                  options={filteredCombos.sinks}
                  value={redirect.Sink}
                  onInputChange={(_, newValue) => {
                    handleChange('Sink', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Sink"
                      color="success"
                      placeholder="Sink"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  fullWidth
                  options={filteredCombos.paths}
                  value={redirect.Path_Name}
                  freeSolo
                  onInputChange={(_, newValue) => {
                    handleChange('Path_Name', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Path Name"
                      color="success"
                      placeholder="Path Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  options={serviceCombos.map(combo => combo.Service)}
                  value={redirect.Service}
                  onChange={(_, newValue) => {
                    handleServiceChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Service"
                      size='small'
                      color="success"
                      placeholder="Service"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  autoComplete
                  autoSelect
                  freeSolo
                  fullWidth
                  options={customerCodes}
                  value={redirect.CustomerCode}
                  onInputChange={(_, newValue) => {
                    handleChange('CustomerCode', newValue);
                  }}
                  onChange={(_, newValue) => {
                    handleChange('CustomerCode', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="Customer Code"
                      color="success"
                      placeholder="Customer Code"
                      error={hasMissing && isMissing(redirect, 'CustomerCode')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <FormControlLabel label={'Preconfirmed'} control={
                    <Checkbox checked={redirect.Preconfirmed} onChange={(e) => handleChange('Preconfirmed', !redirect.Preconfirmed)} />
                  } />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label={'Sales Ref'}
                  size='small'
                  fullWidth
                  value={redirect.SaleRef || ''}
                  onChange={(e) => handleChange('SaleRef', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={handleEditProfile}
                  variant="contained"
                  fullWidth
                >Edit Profile</Button>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <FormControlLabel label={'Schedule Future'} control={
                    <Checkbox
                      disabled={!canScheduleFuture}
                      checked={redirect.scheduleFuture}
                      onChange={(e) => handleChange('scheduleFuture', !redirect.scheduleFuture)}
                    />
                  } />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    label='Schedule Date'
                    ampm={false}
                    value={redirect.scheduleDateTime}
                    onChange={(newValue) => handleChange('scheduleDateTime', newValue)}
                    disabled={!canScheduleFuture || !redirect.scheduleFuture}
                    disablePast
                    timezone={momentTimezones[timezone]}
                    slotProps={{ textField: { size: 'small', error: false, } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" onClick={handleSaveClick}>Redirect</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(RedirectDialog);