import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Box, Typography, IconButton } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string, } from "yup";
import CloseIcon from '@mui/icons-material/Close';

export const AddNewTemplateDialog = ({ open, close, saveTemplate }) => {
    const schema = object().shape({
        name: string().required('Please enter a name for the template').min(3, 'Name must be at least three characters.').max(50, 'Name must be less than 50 characters.'),
    })

    const { handleSubmit, formState: { errors, }, register, reset } = useForm({
        defaultValues: {
            name: '',
        },
        resolver: yupResolver(schema),
    });

    function handleClose() {
        close();
        reset();
    }

    return (
        <Dialog maxWidth="md" open={open}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Create New Template</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(saveTemplate)} id='deal-finder-add-new-template-form'>
                    <DialogContentText>
                        <p>Please enter a name for the template.</p>
                    </DialogContentText>
                    <TextField
                        {...register("name")}
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    form='deal-finder-add-new-template-form'
                    type='submit'
                >Save</Button>
            </DialogActions>
        </Dialog>
    );
}