import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { useActionAudit } from './useActionAudit';
import { debounce } from 'lodash';

export const useLocalLayout = (storageLocation, gridRef, colDefs=null, useColDefs=false, updateColDefs) => {
    const [currentLayout, setCurrentLayout] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();

    function saveLayoutLocal() {
      if(storageLocation) {
        const newSave = captureLayout();
        localStorage.setItem(storageLocation, JSON.stringify(newSave));
        enqueueSnackbar('Layout saved.');
        logAction(`Layout saved`, storageLocation, newSave);
        //putting the layout in as a query param causes a 404 -- ecz 5/23/22
        //logAction(`Layout saved ${JSON.stringify(newSave)}`, storageLocation);
      }
      else {
        enqueueSnackbar('Unable to save: no save location provided.');
      }
    };

    function loadLayoutLocal() {
      try {
        const storedLayout = localStorage.getItem(storageLocation);
        
        setCurrentLayout(JSON.parse(storedLayout ?? '{}'));
        
      } catch (err) {
        enqueueSnackbar(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`);
        logAction(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, storageLocation);
      }
    };

    const deleteLayoutLocal = () => {
      clearLayout();
      localStorage.removeItem(storageLocation);
      enqueueSnackbar('Local layout deleted.');
    };
  
    const clearLayout = () => {
      let defaultDefs;
      if(useColDefs) {
        defaultDefs = [...colDefs];
        defaultDefs.forEach(def => def.hide = def.initialHide ?? def.hide);
      }
      setCurrentLayout(newLayout(null, null, null, defaultDefs));
      //setCurrentLayout({});
      enqueueSnackbar('Layout reset.');
    };

    function newLayout(model, state, order, defs) {
      return({
        filters: model,
        colState: state,
        colOrder: order,
        colDefs: defs,
      });
    }
        
    function applyLayout(layout) {
      if(layout) {
        setCurrentLayout({ ...layout});
        logAction(`Layout applied`, 'useLocalLayout.js', layout);
      }
    };

    function captureLayout() {
      const model = gridRef.current.api.getFilterModel();
      const colState = gridRef.current.columnApi.getColumnState();
      const colOrder = gridRef.current.columnApi.getAllGridColumns().map(col => col.colId);
      const defs = gridRef.current.api.getColumnDefs();
      defs.forEach(def => { //explicitly set column visibility; default is undefined, which causes layout bug when used in conjunction with initialHide property
        if(!def.hide) { def.hide = false; }
      });
      return newLayout(model, colState, colOrder, defs);
    };

    function onRowDataChanged(params) {
      loadLayoutLocal();
      //const rowCount = params.api.rowModel.rowsToDisplay.length;
      const rowCount = gridRef.current.rowModel?.rowsToDisplay.length;
      if (rowCount === 0) {
        gridRef.current.api.showNoRowsOverlay();
      }
    }

    //udpate using current layout
    useEffect(() => {
      if(currentLayout && gridRef?.current?.columnApi && gridRef.current.api) {
        if(useColDefs) {
          let defs = currentLayout.colDefs?.length ? currentLayout.colDefs : colDefs;
          defs = updateColDefs ? updateColDefs(defs) : defs;
          gridRef.current.api.setColumnDefs(defs);
        }
        currentLayout.colState && gridRef.current.columnApi.applyColumnState({state: currentLayout.colState});
        gridRef.current.api.setFilterModel(currentLayout.filters);
        gridRef.current.api.onFilterChanged();
        if(currentLayout.colOrder && !useColDefs) {
          gridRef.current.columnApi.moveColumns(currentLayout.colOrder);
          moveColumnsDebounced(currentLayout.colOrder);
        }
      }
    }, [currentLayout]);

    const moveColumnsDebounced = useCallback(debounce((colOrder) => {
      gridRef.current && gridRef.current.columnApi.moveColumns(colOrder);
    }, 500), []);

    //save layout when window unloads
    useEffect(() => {
      window.addEventListener('beforeunload', saveLayoutLocal)
      return () => {
        window.removeEventListener('beforeunload', saveLayoutLocal)
      }
    }, []);

    const layoutPanel = {
      id: 'layout',
      labelDefault: 'Layout',
      labelKey: 'layout',
      toolPanel: 'layoutToolPanel',
      toolPanelParams: {
        onLayoutClear: clearLayout,
        onLayoutLoad: loadLayoutLocal,
        onLayoutSave: saveLayoutLocal,
        onLayoutDelete: deleteLayoutLocal
      },
      minWidth: 180,
      maxWidth: 400,
      width: 200
    }

    return {
        layoutPanel,
        loadLayoutLocal,
        saveLayoutLocal,
        deleteLayoutLocal,
        captureLayout,
        applyLayout,
        clearLayout,
        onRowDataChanged,
    };
}