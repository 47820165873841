import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import SendIcon from '@mui/icons-material/Send';
import { Typography, Box, Button, CircularProgress, Stack, TextField, IconButton, Tooltip, Collapse, } from '@mui/material';
import { useState, useCallback, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import { useAiAssistants } from '../useAiAssistants';
import { Message } from '../Messaging/Message';
import { useTheme } from '@mui/material';
import debounce from 'lodash/debounce';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from '@mui/material';

export const Help = () => {
    const theme = useTheme();
    const { control, handleSubmit, setValue, formState: { errors, }, } = useForm({
        resolver: yupResolver(schema),
    });

    const { submitPrompt, loading, setLoading, context, error, } = useAiAssistants({
        application: 'dealrizz',
        defaultModel: 'assistant',
    });

    const [response, setResponse] = useState({
        text: "Use the prompt above to get help with the Deal Rizz application.",
        html: null,
    });

    async function onSubmit(data) {
        setResponse({})
        setLoading(true);

        //if the prompt is in the context, return the answer after faking a wait time
        const contextPrompt = context.find(row => row.Question === data.prompt)
        if (contextPrompt) {
            setTimeout(() => {
                setLoading(false);
                setResponse({
                    text: contextPrompt.Answer,
                    html: contextPrompt.embeddedHTML,
                });
                setValue('prompt', '');
            }, 1500);
            return;
        }

        submitPrompt(data.prompt).then(text => {
            if (text) {
                setResponse({ text: text, });
                setValue('prompt', '');
            }
            setLoading(false);
        });
    }

    function handleEnterKeyDown(event) {
        if (event.key === 'Enter') {
            setValue('prompt', event.target.value);
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    }

    const prompts = context.filter(row => row.role === 'user' && row.show === 1).map(row => row.message).filter(msg => !!msg);
    const messages = context.filter(row => row.show === 1);

    const uniquePrompts = [...new Set(prompts)];

    return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', }} >
            <Box
                component='form'
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'flex',
                    width: '60%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    top={0}
                    position='sticky'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Stack
                        direction='row'
                        spacing={2}
                        sx={{
                            py: theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            backgroundColor: theme.palette.background.paper,
                        }}
                    >
                        <FormAutocomplete
                            name='prompt'
                            control={control}
                            freeSolo
                            options={uniquePrompts}
                            sx={{ width: '100%', }}
                            disabled={error}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Ask me anything!"
                                    onKeyDown={handleEnterKeyDown}
                                    multiline
                                    fullWidth
                                    maxRows={10}
                                    error={!!errors.prompt}
                                    helperText={errors.prompt?.message}
                                />
                            )}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            endIcon={<SendIcon />}
                            disabled={loading || error}
                            sx={{
                                minWidth: 'fit-content',
                                height: 'auto',
                            }}
                        >
                            ASK!
                        </Button>
                    </Stack>

                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <OpenAIErrorOverlay show={error} />

                    {loading && <CircularProgress sx={{ margin: '20px', }} />}

                    {!error && messages.map(ctx => {
                        const isUser = ctx.role === 'user';
                        const stamp = `${isUser ? ctx.userName + ' \n' : ''}${dayjs(ctx.timeStamptUtc).format('MM/DD/YYYY HH:mm:ss')}`;

                        return (
                            <Message
                                key={`help-message-${ctx.messageID}`}
                                content={ctx.message}
                                sender={ctx.role}
                                stamp={stamp}
                            />
                        );
                    })}
                </Box>

            </Box>
        </Box>
    );
};

const schema = object().shape({
    prompt: string().required('Please enter a message.'),
});


export const OpenAIErrorOverlay = ({ show, }) => {
    return (
        <OverlayContainer visible={show}>
            <Typography>Oops! Looks like Chat GPT is currently unavailable at this time. Please try again soon.</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ visible }) => ({
    position: 'absolute',
    top: '45%',
    left: 0,
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));
