import { ColorThemeContext } from './ColorThemeContext';
import { useState, useMemo } from 'react';
import { createTheme, ThemeProvider, } from '@mui/material/styles';

export const ColorThemeProvider = ({ children }) => {
  const defaultMode = localStorage.getItem('colorMode') ?? 'light';
  const [mode, setMode] = useState(defaultMode);

  const colorMode = useMemo(() => ({
    toggleColorMode: () => {
      setMode((prevMode) => {
        const newMode = prevMode === 'light' ? 'dark' : 'light';
        localStorage.setItem('colorMode', newMode);
        return newMode;
      });
    },
    mode,
  }), [mode]);

  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode,
        primary: {
          main: '#1C66B0',
          light: '#10A9FF',
          dark: '#272360',
          green: '#94cc6c',
          yellow: '#CEDA28',
          red: '#FF6666',
          white: '#FFFFFF',
          orange: '#fa865c',
          darkBackground: '#2d3436',
          ...(mode === 'light'
            ? {
              backgroundContrast: '#E9E9E9',
            } : {
              backgroundContrast: '#1E2026',
            }),
        }
      },
    }), [mode]);

  return (
    <ColorThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorThemeContext.Provider>
  )
}