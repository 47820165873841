import { useState, useEffect, useLayoutEffect, useMemo } from "react"
import moment from "moment";

export default (defName) => {
  const [colDefs, setColDefs] = useState([]);
  
  useLayoutEffect(() => {
    const newDefs = getDefsByName(defName);
    setColDefs(newDefs);
  }, [defName]);

  function getDefsByName(name) {
    switch(name) {
      case 'Availability Alerts':
        return availabilityAlertsColDefs;
        case 'Long Term Availability':
          return availabilityAlertsColDefs;
        case 'All Daily Segments':
        return allDailySegmentsColDefs;
      case 'Flowgates':
        return flowgatesColDefs;
      case 'Trans Offerings':
        return transOfferingsColDefs;
      case 'Summary Statistics':
        return summaryStatisticsColDefs;
      case 'Outages':
        return outagesColDefs;
      default: return [];
    }
  }

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      conte: yValue
    }
  }

  function dateComparator(date1, date2) {
    return moment(date1).diff(moment(date2));
  }

  const availabilityAlertsColDefs = useMemo(() => [
    {
      field: "POSTING_REF",
      headerName:"Posting Ref",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      //rowGroup: true,
      initialWidth: 100,
      initialHide: false,
      //enableRowGroup: true,
    },
    {
      field: "localDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "utcDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ELEMENT",
      headerName:"Full Path",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_VALUE",
      headerName:"Current ATC",
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "firmCapacityReserved",
      headerName:"Reserved Firm",
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "nonFirmCapacityReserved",
      headerName:"Reserved Non-Firm",
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_UNITS",
      headerName:"Units",
      initialWidth: 50,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ATTRIBUTE",
      headerName:"Type",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "START_TIME",
      headerName:"Start",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
      comparator: dateComparator,
    },
    {
      field: "STOP_TIME",
      headerName: "Stop",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
      comparator: dateComparator,
    },
    {
      field: "ANNOTATION",
      headerName: "Annotation",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TIME_OF_LAST_UPDATE",
      headerName: "Last Updated",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      //field: "atc",
      headerName: "History",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcHistory).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
        
      },
    },
    {
      //field: "atc",
      headerName: "Future",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcFuture).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
    },
    {
      field: 'atcHistory',
      header: "HistoryData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: 'atcFuture',
      header: "FutureData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
  ], [])

  const allDailySegmentsColDefs = useMemo(() => [
    {
      field: "POSTING_REF",
      headerName:"Posting Ref",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      //rowGroup: true,
      initialWidth: 100,
      initialHide: false,
      //enableRowGroup: true,
    },
    {
      field: "localDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "utcDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ELEMENT",
      headerName:"Full Path",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_VALUE",
      headerName:"Current Value",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_UNITS",
      headerName:"Units",
      initialWidth: 50,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ATTRIBUTE",
      headerName:"Type",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "START_TIME",
      headerName:"Start",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "STOP_TIME",
      headerName: "Stop",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ANNOTATION",
      headerName: "Annotation",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TIME_OF_LAST_UPDATE",
      headerName: "Last Updated",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      //field: "atc",
      headerName: "History",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcHistory).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
        
      },
    },
    {
      //field: "atc",
      headerName: "Future",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcFuture).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
    },
    {
      field: 'atcHistory',
      header: "HistoryData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: 'atcFuture',
      header: "FutureData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
  ], [])

  const flowgatesColDefs = useMemo(() => [
    {
      field: "POSTING_REF",
      headerName:"Posting Ref",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      //rowGroup: true,
      initialWidth: 100,
      initialHide: false,
      //enableRowGroup: true,
    },
    {
      field: "localDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "utcDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ELEMENT",
      headerName:"Full Path",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_VALUE",
      headerName:"Current Value",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ATTRIBUTE_UNITS",
      headerName:"Units",
      initialWidth: 50,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SYSTEM_ATTRIBUTE",
      headerName:"Type",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "START_TIME",
      headerName:"Start",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "STOP_TIME",
      headerName: "Stop",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TIME_OF_LAST_UPDATE",
      headerName: "Last Updated",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      //field: "atc",
      headerName: "History",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcHistory).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
        
      },
    },
    {
      //field: "atc",
      headerName: "Future",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcFuture).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
    },
    {
      field: 'atcHistory',
      header: "HistoryData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: 'atcFuture',
      header: "FutureData",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
  ], [])

  const transOfferingsColDefs = useMemo(() => [
    {
      field: "PostingRef",
      headerName:"Posting Ref",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      //rowGroup: true,
      initialWidth: 100,
      initialHide: false,
      //enableRowGroup: true,
    },
    {
      field: "localDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "utcDate",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "PathName",
      headerName:"Full Path",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "Provider",
      headerName:"Provider",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "PointOfReceipt",
      headerName:"POR",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "PointOfDelivery",
      headerName:"POD",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "Capacity",
      headerName:"Current Value",
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "ServiceIncrement",
      headerName:"Increment",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TSClass",
      headerName:"Class",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TSPeriod",
      headerName:"Period",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TSWindow",
      headerName:"Window",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TSSubClass",
      headerName:"Subclass",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "CeilingPrice",
      headerName:"Ceiling",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "OfferPrice",
      headerName:"Price",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "PriceUnit",
      headerName:"Units",
      initialWidth: 50,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "StartTime",
      headerName:"Start",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "StopTime",
      headerName: "Stop",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "TimeOfLastUpdate",
      headerName: "Last Updated",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "SERVICE_Description",
      headerName:"Notes",
      initialWidth: 150,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      //field: "atc",
      headerName: "History",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcHistory).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
        
      },
    },
    {
      //field: "atc",
      headerName: "Future",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        
        if (params.data.atcHistory) {
          const atcData = JSON.parse(params.data.atcFuture).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
    }
  ], [])

  const summaryStatisticsColDefs = useMemo(() => [
    {
      field: "Provider",
      headerName:"Provider",
      editable: false,
      sortable: true,
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
    },
    {
      field: "AvailabilityData",
      headerName:"Availability Data",
      editable: false,
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "OfferData",
      headerName:"Offer Data",
      editable: false,
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "TSRdata",
      headerName:"TSR Data",
      editable: false,
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Status",
      headerName:"Status",
      editable: false,
      initialWidth: 300,
      initialHide: false,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
  ], [])

  const outagesColDefs = useMemo(() => [
    {
      field: "EventId",
      headerName:"Event Id",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "OutageId",
      headerName:"Outage Id",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "OutageName",
      headerName:"Name",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "OutageType",
      headerName:"Type",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Status",
      headerName:"Status",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Company",
      headerName:"Company",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Facility",
      headerName:"Facility",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "OutageCategory",
      headerName:"Outage Category",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Segment",
      headerName:"Segment",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "PathImpact",
      headerName:"Path Impact",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Comments",
      headerName:"Comments",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "FileDate",
      headerName:"File Date",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "FlowGate",
      headerName:"FlowGate",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "CreationTimePS",
      headerName:"Creation Time",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "LastUpdatedPS",
      headerName:"Last Updated",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "New_TTC",
      headerName:"New TTC",
      editable: false,
      sortable: true,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      enableRowGroup: true,
    },
    {
      field: "Original_TTC_In",
      headerName:"Original TTC In",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "Original_TTC_Out",
      headerName:"Original_TTC_Out",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "ReportTPs",
      headerName:"Report TPs",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "StartTimePS",
      headerName:"Start Time",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
    {
      field: "StopTimePS",
      headerName:"Stop Time",
      editable: false,
      initialWidth: 100,
      filter: "agMultiColumnFilter",
      sortable: true,
      enableRowGroup: true,
    },
  ], [])

  return {
    colDefs, 
  }
}