import { Card, CardContent, CardActions, CardMedia, Button, Typography, CardActionArea, Box, } from "@mui/material"
import { userGroups } from "../../authConfig";
import { useCardContent } from "./useCardContent";
import { useUserGroups } from "../../data/useUserGroups";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default (props) => {
  const { content, handleSelection, handleTagClick, } = props;
  const { contentID, description, title, tags, } = content;
  const { userIsInGroup } = useUserGroups();
  const userIsAdmin = userIsInGroup(userGroups.admins);

  const {
    linkName,
    handleLinkClick,
    srcUrl,
    dateCreated,
  } = useCardContent(content);

  return (
    <Card raised className="update-card-container">
      <CardActionArea onClick={(e) => handleSelection(contentID, e)} sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
        <CardMedia
          component='img'
          image={srcUrl}
          title={title}
          className="update-card-image"
        />
        <CardContent sx={{ flexGrow: 1, width: '100%', pb: 0, pt: 2, }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p className="update-card-date">{dateCreated}</p>
            {userIsAdmin && !content.active && <VisibilityOffIcon color="error" />}
            {userIsAdmin && !!content.active && <VisibilityIcon color="success" />}
          </div>
          <h2 className="update-card-title">{title}</h2>
          <p className="update-card-description">{description}</p>
        </CardContent>
        <CardActions className="update-card-actions">
          {!!linkName && <Button onClick={handleLinkClick}>View In {linkName}</Button>}
          <Box sx={{ flexGrow: 1, }} />
          {tags.map(tag => (
            <Button onClick={(e) => handleTagClick(tag, e)} key={tag}>{tag}</Button>
          ))}
        </CardActions>
      </CardActionArea>
    </Card>
  )
}