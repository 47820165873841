import { Stack, Typography, Button, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';

export const NodeActionMenu = ({ selectedNodes, addLeafNodesToSelected, handleConnectClick, handleAddNewLeg, }) => {
    const theme = useTheme();

    return (
        <Stack spacing={1} sx={{ paddingTop: 1, display: 'flex', alignItems: 'flex-end', }}>
            <Typography variant='caption' color='text.secondary'>{selectedNodes.length} nodes selected</Typography>
            <Button
                variant='contained'
                size='small'
                onClick={addLeafNodesToSelected}
            >Add All PODs</Button>
            <Button
                variant='contained'
                size='small'
                onClick={handleAddNewLeg}
            >Add Legs</Button>
            <Tooltip arrow title="Add the shortest path that connect the two nodes." placement='top'>
                <Button
                    variant='contained'
                    size='small'
                    onClick={handleConnectClick}
                    disabled={selectedNodes.length !== 2}
                >Connect</Button>
            </Tooltip>
        </Stack>
    )
}