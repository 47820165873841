import { Stack, Typography, } from '@mui/material';
import { useTheme } from '@emotion/react';
import { totalPrice } from './UtilFunctions';

export const LegInfoPane = (props) => {
    const { leg = {}, } = props;
    const data = leg.data ?? {};
    const theme = useTheme();
    const fieldTitleColor = theme.palette.text.secondary;

    const fieldContent = ({ key, label }) => {
        return data[key] ? <>
            <Typography variant="caption">{label}: </Typography>
            <Typography variant="caption" color={fieldTitleColor}>{data[key]}</Typography>
        </> : null;
    }

    const fields = [
        { key: 'Provider', label: 'Provider' },
        { key: 'RequestType', label: 'Type' },
        { key: 'ServiceIncrement', label: 'Service Inc' },
        { key: 'NERCcurtailmentPriority', label: 'Priority' },
        { key: 'TSClass', label: 'TS Class' },
        { key: 'TSPeriod', label: 'TS Period' },
        { key: 'TSType', label: 'TS Type' },
        { key: 'TSWindow', label: 'TS Window' },
        { key: 'TSSubclass', label: 'TS Subclass' },
    ];

    const capacity = JSON.parse(data.Capacity ?? '[]');
    const price = JSON.parse(data.OfferPrice ?? '[]');

    return (
        <Stack spacing={1} direction='row'>
            {data.OfferPrice && data.Capacity && <>
                <Typography variant="caption">Total Price: </Typography>
                <Typography variant="caption" color={fieldTitleColor}>
                    {totalPrice(price, capacity)}
                </Typography>
            </>}
            {fields.map((field) => fieldContent(field))}
        </Stack >
    );
}