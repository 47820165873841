import { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/certificateGridStyles.css'
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import Certificates from './Certificates';
import UserCertificatesGrid from './UserCertificatesGrid';
import axios from 'axios';
import TemplateTable from '../TransNow/TemplateTable'
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import ResizingPane from 'react-resizing-pane';
import DivGuard from '../Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTemplateTable } from '../useTemplateTable';
import useHeader from '../useHeader';

const CertificateMapping = () => {

  const guid = useLocalGuid();
  const certificateGridRef = useRef();
  const userCertificateGridRef = useRef();
  const [warnings, setWarnings] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [userTemplates, setUserTemplates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const header = useHeader();
  let storageConfig = { name: 'ResizableCertificateGrid', type: localStorage };

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    loadTemplates();
    return () => {
      abortController.abort();
    }

  }, []);

  async function loadTemplates() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UsersFetch&parm=${guid}`

    let options = {
      headers: header,
      method: 'GET',
      url: url,
    };

    const userTemps = await axios(options).catch(err => { enqueueSnackbar(`Error loading groups.  URL: ${url} Error:  ${err}`) });

    if (userTemps) {
      setUserTemplates(userTemps.data.map(user => ({
        id: user.userGuid,
        data: user,
        name: user.userName
      })));
    }
  };

  function applyMapping(rowsToMap, template) {
    const toMap = [];
    const toWarn = [];

    rowsToMap.forEach(row => {
      const [first, last] = row.FriendlyName.split(' ');
      if (first === template.data.firstName && last == template.data.lastName) {
        toMap.push({ row: row, template: template })
      }
      else {
        toWarn.push({ row: row, template: template });
      }
    });

    setMappings(toMap);
    setWarnings(toWarn);
  }

  useEffect(() => {
    if (mappings.length > 0) {
      const newRows = mappings.map(mapping => {
        const { row, template } = mapping;
        return userTemplateRowTransform(row, template.data);
      })
      userCertificateGridRef.current.api.applyTransaction({ add: newRows, addIndex: 0, });
      setMappings([]);
    };

    if (warnings.length > 0) {
      setOpenWarningDialog(true);
    }
    else {
      setOpenWarningDialog(false);
    }
  }, [mappings, warnings]);

  function userTemplateRowTransform(row, template) {
    const newRow = { ...row }
    if (template) {
      newRow['userGuid'] = template.userGuid;
      newRow['userName'] = template.userName;
      newRow['firstName'] = template.firstName;
      newRow['lastName'] = template.lastName;
      newRow['user'] = template.user;
      newRow['encryptedPwd'] = template.encryptedPwd;
      newRow['url'] = template.url;
    }
    //newRow['userGuid'] = row['Guid'];
    //newRow['groupName'] = template.groupName;
    //newRow['powerStationGroupID'] = template.powerStationGroupID;
    newRow['Market'] = 'OASISTx';
    //newRow['url'] = 'https://demoapp.oasis.oati.com/';
    newRow['certificateThumbprint'] = row['ThumbPrint'];
    newRow['description'] = 'OASISTx Certificate';

    return newRow;
  }

  function handleCancelMapping() {
    const [_, ...remaining] = warnings;
    setWarnings(remaining);
  };

  function handleContinueMapping() {
    const [toMap, ...remaining] = warnings;
    setMappings([toMap, ...mappings]);
    setWarnings(remaining);
  }

  return (
    <DivGuard groups={[userGroups.certificatemapping]} >
      <Dialog open={openWarningDialog}>
        <DialogTitle id="alert-dialog-title">
          {"Warning: names do not match."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`You are assigning ${warnings[0]?.template.data.firstName} ${warnings[0]?.template.data.lastName} a certificate belonging to ${warnings[0]?.row.FriendlyName}.
              Are you sure you want to continue?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelMapping}>Cancel</Button>
          <Button onClick={handleContinueMapping}>Continue</Button>
        </DialogActions>
      </Dialog>
      <div className="cert-example-wrapper">
        <ResizingPane
          sides={['right']}
          storageId={28}
          storageConfig={storageConfig}
          height="100%"
          width={550}
          style={{
            border: '0px solid blue',
            paddingRight: '8px',
          }}
        >
          <Certificates ref={certificateGridRef} userTemplates={userTemplates} handleMapping={applyMapping} />
        </ResizingPane>
        <div className="cert-container">
          <UserCertificatesGrid ref={userCertificateGridRef} templates={userTemplates} />
        </div>
      </div>
    </DivGuard>
  );
};

export default function UserGridsExport() {
  return <CertificateMapping />;
}