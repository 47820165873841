import { useMemo, useEffect, useState, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { useSnackbar } from 'notistack';
import RealTimeTSR from './RealTimeTSR';
import RealTimeReassignments from './RealTimeReassignments';
import RealTimeRedirects from './RealTimeRedirects';
// import { Paper, TableBody, TableContainer, Table, TextField, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses } from "@mui/material";
// import Divider from '@mui/material/Divider';
import TemplateTable from './TemplateTable'
import ResizingPane from 'react-resizing-pane';
import { serviceRowValues } from '../../utils/RedirectServiceValues';
import DivGuard from '../../components/Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import { useActionAudit } from '../useActionAudit';
import { useTemplateTable } from '../useTemplateTable';
import { Collapse, Stack } from '@mui/material';

export default ({ visible }) => {

  const tsrGridRef = useRef();
  const reassignGridRef = useRef();
  const redirectGridRef = useRef();
  const reassignmentTemplateTypeId = 2;
  const redirectTemplateTypeId = 3;

  const { logAction } = useActionAudit();
  const { enqueueSnackbar } = useSnackbar();

  const [hideReassignments, setHideReassignments] = useState(!!JSON.parse(localStorage.getItem('HideRealTimeReassignments')));

  const redirectSourceGridRef = hideReassignments ? tsrGridRef : reassignGridRef;
  const redirectSourceGrid = hideReassignments ? 'TSR Grid' : 'Reassign Grid';

  const {
    templates: reassignTemplates,
    addTemplate: addReassignTemplate,
    deleteTemplate: deleteReassignTemplate,
    onTemplateClick: onReassignTemplateClick,
    addGridDropZone,
    addDropZone: addReassignDropZone,
    transformRows: transformReassignRows,
  } = useTemplateTable(reassignTemplateRowTransform, reassignmentTemplateTypeId, tsrGridRef, reassignGridRef, 'rt-reassign', 'TSR Grid', 'Reassign Grid');

  const {
    templates: redirectTemplates,
    addTemplate: addRedirectTemplate,
    deleteTemplate: deleteRedirectTemplate,
    onTemplateClick: onRedirectTemplateClick,
    addDropZone: addRedirectDropZone,
    transformRows: transformRedirectRows,
  } = useTemplateTable(redirectTemplateRowTransform, redirectTemplateTypeId, redirectSourceGridRef, redirectGridRef, 'rt-redirect', redirectSourceGrid, 'Redirect Grid');

  const {
    loadTemplates: reloadTsrDropZones,
  } = useTemplateTable(redirectTemplateRowTransform, redirectTemplateTypeId, tsrGridRef, redirectGridRef, 'rt-redirect', 'TSR Grid', 'Redirect Grid');

  let storageConfig = { name: 'ResizableTSRgrid', type: localStorage };

  useEffect(() => {
    if (addGridDropZone) {
      addGridDropZone('#real-time-reassign-grid', reassignTemplateRowTransform, tsrGridRef, reassignGridRef);
      addGridDropZone('#real-time-redirects-grid', redirectTemplateRowTransform, tsrGridRef, redirectGridRef);
      addGridDropZone('#real-time-redirects-grid', redirectTemplateRowTransform, reassignGridRef, redirectGridRef);
    }
  }, [reassignTemplates, redirectTemplates]);

  function toggleReassignments() {
    setHideReassignments(!hideReassignments);
    localStorage.setItem('HideRealTimeReassignments', !hideReassignments);
  }

  function addRedirectTemplateAndReload(newTemplate) {
    addRedirectTemplate(newTemplate);
    reloadTsrDropZones();
  }

  const copyTemplate = {
    id: 'rt-copy-template',
    data: {
      isCopyTemplate: true,
    },
    name: 'Add Copy',
    tooltipText: 'Copy selected TSR to work area',
  }

  function redirectCopyTransform(row) {
    const newRow = { ...row };

    if (row['defaultStartDate']) { newRow['Start Date'] = row['defaultStartDate']; }
    if (row['defaultStopDate']) { newRow['Stop Date'] = row['defaultStopDate']; }
    if (row['defaultStartTime']) { newRow['Start Time'] = row['defaultStartTime']; }
    if (row['defaultStopTime']) { newRow['Stop Time'] = row['defaultStopTime']; }
    newRow['Redirected Ref'] = row['New Aref'] ?? row['AssignmentRef'];
    newRow['New Aref'] = row['AssignmentRef'] ?? row['New Aref'];
    newRow["RequestType"] = 'REDIRECT';
    //newRow['Status'] = 'PRE-SUBMITTED';
    newRow['Preconfirmed'] = 'YES';
    //newRow['Price'] = newRow['Price']?.toString();
    //newRow['Price'] = '0'; //per TEA, default price of redirects to 0 -ecz 4/9/2022
    //newRow['Status'] = 'PRE-SUBMITTED';

    return newRow;
  }

  if (tsrGridRef.current && reassignGridRef) {
    addRedirectDropZone(copyTemplate.id, (params) => transformRedirectRows(copyTemplate, params))
    const dropTarget = document.querySelector(`#drop-zone-rt-redirectrt-copy-template`);
    const dropZone = {
      getContainer: function () {
        return dropTarget;
      },
      onDragStop: (params) => transformRedirectRows(copyTemplate, params),
    }

    try {
      tsrGridRef.current.api?.removeRowDropZone(dropZone);
    } finally {
      tsrGridRef.current.api?.addRowDropZone(dropZone);
    }
  }

  function redirectTemplateRowTransform(row, template) {
    const newRow = { ...row }

    if (template?.isCopyTemplate) {
      return redirectCopyTransform(row);
    }

    if (template) {
      newRow['PointOfDelivery'] = template.POD;
      newRow['PointOfReceipt'] = template.POR;
    }
    if (row['defaultStartDate']) { newRow['Start Date'] = row['defaultStartDate']; }
    if (row['defaultStopDate']) { newRow['Stop Date'] = row['defaultStopDate']; }
    if (row['defaultStartTime']) { newRow['Start Time'] = row['defaultStartTime']; }
    if (row['defaultStopTime']) { newRow['Stop Time'] = row['defaultStopTime']; }
    if (row['AssignmentRef']) {
      newRow['Redirected Ref'] = row['AssignmentRef'];
    }
    else {
      newRow['Redirected Ref'] = row['New Aref'];
      newRow['New Aref'] = '';
    }
    newRow['Status'] = 'PRE-SUBMITTED';
    newRow['Preconfirmed'] = 'YES';

    if (row.priceInfo) {
      newRow['Price'] = '0';
    } else {
      newRow['Price'] = '0'; //per TEA, default price of redirects to 0 -ecz 4/9/2022
    }


    if (row.profileInfo) {
      newRow.profileInfo = row.profileInfo.map(block => ({
        ...block,
        price: newRow.Price,
      }))
    }

    newRow['AssignmentRef'] = undefined;
    newRow['Sale Ref'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["OASISInstance"] = undefined;
    newRow["StartUTC"] = undefined;
    newRow["StopUTC"] = undefined;
    newRow["Start"] = undefined;
    newRow["Stop"] = undefined;
    newRow["defaultStartDate"] = undefined;
    newRow["defaultStartTime"] = undefined;
    newRow["defaultStopDate"] = undefined;
    newRow["defaultStopTime"] = undefined;
    newRow["Reassigned Ref"] = undefined;
    newRow["RequestType"] = 'REDIRECT';
    newRow["Related Ref"] = undefined;
    newRow["SellerRef"] = undefined;
    newRow["PriceUnit"] = undefined;
    newRow["CUSTOMER_NAME"] = undefined;
    newRow["CUSTOMER_EMAIL"] = undefined;
    newRow["Request Ref"] = undefined;
    newRow["Deal Ref"] = undefined;
    newRow["Posting Ref"] = undefined;

    //match the row values to a service on the following fields.
    const serviceFields = ['Service_Increment', 'TS_Class', 'TS_Type', 'TS_Period', 'TS_Window', 'TS_Subclass'];
    let matchingService = Object.keys(serviceRowValues).find(svc => {
      const values = serviceRowValues[svc];
      return serviceFields.every(field => values[field] === row[field]);
    });

    //if there is no match, default to NF-Secondary Hourly PTP - ecz 3/2/2022
    if (!matchingService) {
      const svc = serviceRowValues['NF-SECONDARY HOURLY PTP'];
      matchingService = svc.Service;
      serviceFields.forEach(field => {
        newRow[field] = svc[field];
      });
    }

    newRow.Service = matchingService;
    return newRow;
  }

  function reassignCopyTransform(row) {
    const newRow = { ...row };

    newRow['Reassigned Ref'] = row['AssignmentRef'];
    newRow['New Aref'] = '';
    newRow['AssignmentRef'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["RequestType"] = 'RESALE';
    newRow['Start Date'] = row['defaultStartDate'];
    newRow['Start Time'] = row['defaultStartTime'];
    newRow['Stop Date'] = row['defaultStopDate'];
    newRow['Stop Time'] = row['defaultStopTime'];
    newRow['Preconfirmed'] = 'YES';
    newRow['Price'] = row.defaultResalePrice;
    newRow['Status'] = 'PRE-SUBMITTED';

    return newRow;
  }

  if (reassignGridRef.current) {
    addReassignDropZone(copyTemplate.id, (params) => transformReassignRows(copyTemplate, params))
  }

  function reassignTemplateRowTransform(row, template) {
    const newRow = { ...row };

    if (template && !template.isCopyTemplate) {
      newRow['Customer Code'] = template.PUD;
      if (template['Sale Ref']) {
        newRow['Sale Ref'] = template['Sale Ref'];
      }
    }

    newRow['Status'] = 'PRE-SUBMITTED';
    if (row['AssignmentRef']) {
      newRow['Reassigned Ref'] = row['AssignmentRef'];
    }
    else {
      newRow['Reassigned Ref'] = row['New Aref'];
      newRow['New Aref'] = undefined;
    }

    newRow['Price'] = row.defaultResalePrice;

    newRow['Start Date'] = row['defaultStartDate'];
    newRow['Start Time'] = row['defaultStartTime'];
    newRow['Stop Date'] = row['defaultStopDate'];
    newRow['Stop Time'] = row['defaultStopTime'];
    newRow['Preconfirmed'] = 'YES';

    newRow['AssignmentRef'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["OASISInstance"] = undefined;
    newRow["StartUTC"] = undefined;
    newRow["StopUTC"] = undefined;
    newRow["Start"] = undefined;
    newRow["Stop"] = undefined;
    newRow["defaultStartDate"] = undefined;
    newRow["defaultStartTime"] = undefined;
    newRow["defaultStopDate"] = undefined;
    newRow["defaultStopTime"] = undefined;
    newRow["Redirected Ref"] = undefined;
    newRow["RequestType"] = 'RESALE';
    newRow["Related Ref"] = undefined;
    newRow["SellerRef"] = undefined;
    newRow["PriceUnit"] = undefined;
    newRow["CUSTOMER_NAME"] = undefined;
    newRow["CUSTOMER_EMAIL"] = undefined;
    newRow["Request Ref"] = undefined;
    newRow["Deal Ref"] = undefined;
    newRow["Posting Ref"] = undefined;

    return newRow;
  }

  const [timezone, setTimezone] = useState('Pacific Standard Time');

  const handleMasterTimezoneChange = (event) => {
    setTimezone(event.target.value);
    //getData();
    let message = `Time zone changed to ${event.target.value}.`;
    enqueueSnackbar(message);
    logAction('Changed Timezone', 'Real Time Grids');
  };

  return (
    <div className="real-time-grids-container">
      <ResizingPane
        sides={['right']}
        storageId={30}
        storageConfig={storageConfig}
        // height={tsrGridHeight}
        width={750}
        style={{
          border: '0px solid blue',
          minHeight: '86vh',
          position: 'static',
        }}
      >
        <RealTimeTSR ref={tsrGridRef} handleMasterTimezoneChange={handleMasterTimezoneChange} timezone={timezone} visible={visible} />
      </ResizingPane>
      <div className="real-time-inner-col vertical-toolbar">
        <Collapse in={!hideReassignments} sx={{ width: '100%' }}>
          <TemplateTable
            title='TransAssign'
            tableId='rt-reassign'
            tooltipText='These are TransAssign templates. To create new ones, use the Templates tool panel on the reassignments grid.'
            templates={[copyTemplate, ...reassignTemplates]}
            handleRowClick={onReassignTemplateClick}
            rowTooltip={function (template) {
              return (<span>Using this template will reassign a TSR to {template.PUD}</span>);
            }}
            panelParams={{
              fieldNames: ['PUD', 'Sale Ref', 'name'],
              currentTemplates: reassignTemplates,
              addTemplate: addReassignTemplate,
              deleteTemplate: deleteReassignTemplate,
            }}
          />
        </Collapse>
        <div className="real-time-divider"></div>
        <TemplateTable
          title='Redirect'
          tableId='rt-redirect'
          tooltipText='These are Redirects templates. To create new ones, use the Templates tool panel on the redirects grid.'
          templates={[copyTemplate, ...redirectTemplates]}
          handleRowClick={onRedirectTemplateClick}
          rowTooltip={function (template) {
            return (<span>Using this template will redirect a TSR from {template.POR} to {template.POD}</span>);
          }}
          panelParams={{
            fieldNames: ['POR', 'POD', 'name'],
            currentTemplates: redirectTemplates,
            addTemplate: addRedirectTemplateAndReload,
            deleteTemplate: deleteRedirectTemplate,
          }}
        />
      </div>
      <div className="real-time-container">
        <Stack spacing={2}>
          <Collapse in={!hideReassignments} sx={{ width: '100%', }}>
            <div id='real-time-reassign-grid'>
              <RealTimeReassignments ref={reassignGridRef} templates={reassignTemplates} toggle={toggleReassignments} addTemplate={addReassignTemplate} deleteTemplate={deleteReassignTemplate} timezone={timezone} />
            </div>
            <div className="real-time-divider" />
          </Collapse>
          <div id='real-time-redirects-grid'>
            <RealTimeRedirects ref={redirectGridRef} templates={redirectTemplates} hideReassignments={hideReassignments} toggle={toggleReassignments} addTemplate={addRedirectTemplateAndReload} deleteTemplate={deleteRedirectTemplate} timezone={timezone} />
          </div>
        </Stack>
      </div>
    </div>
  );

};