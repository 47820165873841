export const CheckboxCellRenderer = props => {

  // Prevents editing of non-editable cells; using the disabled prop affects the styling
  function handleEdit(e) {
    !props.colDef.editable && e.preventDefault()
  }

  return (
    <input
      type='checkbox'
      onClickCapture={handleEdit}
      checked={props.value}
      onChange={(e) => {
        if (props.colDef.editable) {
          const colId = props.column.colId;
          props.node.setDataValue(colId, e.target.checked);
        }
      }}
    />
  )
}