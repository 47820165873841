import { Tooltip } from "@mui/material";
import { useState, useCallback, } from "react";
import debounce from "lodash/debounce";
import { useApi } from "../../useApi";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { RibbonButton } from "../Ribbon";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const VoidScheduleButton = ({ expanded, selectedSchedule, gridRef, toVoid, ...buttonProps }) => {
    const voidButtonText = toVoid ? 'Void' : 'Unvoid';
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const { headers, apiUrlPrefix, enqueueSnackbar, logAction, get } = useApi();
    const buttonDebounce = 1500;

    function handleConfirmVoid(toVoid) {
        const action = toVoid ? 'void' : 'unvoid';
        setConfirmationDialogProps({
            open: true,
            message: `You are about to ${action} the selected schedule (${selectedSchedule.scheduleID}). Continue?`,
            onConfirmation: handleVoid,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleVoid = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_voidSchedule`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedSchedule.scheduleID}`
            + `&parm=${toVoid ? 1 : 0}`

        return get(url).then(response => {
            enqueueSnackbar(`Schedule ${toVoid ? '' : 'un'}voided successfully.`, { variant: 'success' });
            logAction(`User ${toVoid ? '' : 'un'}voided schedule with id ${selectedSchedule.scheduleID}`, 'Schedule Summary');
            gridRef?.current.api?.applyTransaction({ update: [{ ...selectedSchedule, Status: toVoid ? 'VOIDED' : 'DRAFT', Schedule_Status: toVoid ? 'VOIDED' : 'DRAFT' }] });
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, selectedSchedule]);

    return (
        <>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={`${toVoid ? 'Unv' : 'V'}oid the selected schedule.`}>
                <span>
                    <RibbonButton
                        icon={<HighlightOffIcon fontSize='small' />}
                        onMouseDown={() => handleConfirmVoid(toVoid)}
                        expanded={expanded}
                        label={voidButtonText}
                        disabled={!selectedSchedule?.scheduleID}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    );
}