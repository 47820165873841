import { useState, } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, TextField, Button, Box, Stack, CircularProgress, Select, MenuItem, } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { userGroups } from '../authConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import FormAutocomplete from './FormControls/FormAutocomplete';
import { useUserGroups } from '../data/useUserGroups';
import { useImageUploader } from './useImageUploader';
import { useChatBots } from './useChatBots';
import { useFileUploader } from './useFileUploader';

export const ChatQAndA = () => {
    const { control, handleSubmit, setValue, formState: { errors, }, } = useForm({
        resolver: yupResolver(schema),
    });
    const { userIsInGroup } = useUserGroups();
    const isAdmin = userIsInGroup(userGroups.admins);
    const { ImageUploader, images, ImageUploadButton, ImagePreview, } = useImageUploader();
    const { FileUploader, files, FileUploadButton, FilePreview, } = useFileUploader();
    const { submitPrompt, loading, setLoading, context, model, setModel, } = useChatBots({
        application: 'aboutPowerStation',
        images: images,
        defaultModel: 'assistant',
        files: files,
    });

    const [response, setResponse] = useState({
        text: "Power Station is a revolutionary platform tailored for energy traders and trading managers, designed to transform the way you operate in the energy market. Our cutting-edge suite of tools, from Fast Path's smart transmission route optimization to Trans Now's bulk automation for day-ahead and real-time trades, empowers traders like never before. There are multiple sample applications you can try for FREE! Start using Power Station today and supercharge your energy trading operations to be faster, smarter and more efficient.",
        html: null,
    });

    async function onSubmit(data) {
        setResponse({})
        setLoading(true);

        //if the prompt is in the context, return the answer after faking a wait time
        const contextPrompt = context.find(row => row.Question === data.prompt)
        if (contextPrompt) {
            setTimeout(() => {
                setLoading(false);
                setResponse({
                    text: contextPrompt.Answer,
                    html: contextPrompt.embeddedHTML,
                });
                setValue('prompt', '');
            }, 1500);
            return;
        }

        submitPrompt(data.prompt).then(text => {
            if (text) {
                setResponse({ text: text, });
                setValue('prompt', '');
            }
            setLoading(false);
        });
    }

    function handleEnterKeyDown(event) {
        if (event.key === 'Enter') {
            setValue('prompt', event.target.value);
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    }

    function handleModelChange(event) {
        setLoading(true);
        setModel(event.target.value);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const prompts = context.map(row => row.Question);

    return (
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', }}>
                <Stack direction='row' spacing={2} >
                    <ImageUploader />
                    {model.includes('vision') &&
                        <ImageUploadButton />
                    }
                    <FileUploader
                        accept='.c,.csv,.cpp,.css,.doc,.docx,.html,.java,.json,.md,.pdf,.php,.pptx,.py,.rb,.tex,.txt,.js,.sh,.ts'
                    />
                    {model.includes('assistant') &&
                        <FileUploadButton />
                    }
                    <FormAutocomplete
                        name='prompt'
                        control={control}
                        freeSolo
                        fullWidth
                        options={prompts}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Ask me anything!"
                                onKeyDown={handleEnterKeyDown}
                                fullWidth
                                multiline
                                maxRows={3}
                                error={!!errors.prompt}
                                helperText={errors.prompt?.message}
                            />
                        )}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        disabled={loading}
                        sx={{
                            minWidth: 'fit-content',
                            height: 'auto',
                        }}
                    >
                        ASK!
                    </Button>
                    {isAdmin &&
                        <Select
                            value={model}
                            label="Model"
                            onChange={handleModelChange}
                        >
                            <MenuItem selected value={'gpt'}>GPT</MenuItem>
                            <MenuItem value={'gemini'}>Gemini</MenuItem>
                            <MenuItem value={'gemini-vision'}>Gemini Vision</MenuItem>
                            <MenuItem value={'assistant'}>Assistant</MenuItem>
                        </Select>
                    }
                </Stack>
            </form>
            <ImagePreview />
            <FilePreview />
            {loading && <CircularProgress sx={{ marginTop: '20px', }} />}
            <Typography variant="h6" align="center" color="textSecondary" paragraph sx={{ whiteSpace: "pre-wrap", marginTop: '20px', }}>
                {response.text}
            </Typography>
            {!!response.html && <div dangerouslySetInnerHTML={{ __html: response.html }} />}
        </Box>
    );
};

const schema = object().shape({
    prompt: string().required('Please enter a message.'),
});