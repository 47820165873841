import React, { useState, useEffect, useMemo } from 'react';
// import { render } from 'react-dom';
// import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';
import useHeader from '../../../components/useHeader';
import { apiUrlPrefix } from "../../../authConfig";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useColorMode } from '../../../styles/ColorMode/useColorMode';

export default () => {
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const [data, setData] = useState([]);
  const { mode } = useColorMode();

  const options = useMemo(() => ({
    autoSize: true,
    data: data,
    theme: {
      baseTheme: mode === 'light' ? 'ag-material' : 'ag-material-dark',
      palette: {
        fills: ['#ec4d3d', '#4facf2', '#00FF00', '#29ab87', '#FFFDD0'],
        strokes: ['#ec4d3d', '#4facf2', '#00FF00', '#29ab87', '#FFFDD0'],
      },
      overrides: { area: { series: { fillOpacity: 0.5 } } },
    },
    title: {
      text: 'OASIS TSR Processing Speed',
      fontSize: 18,
    },
    series: [
      {
        type: 'area',
        xKey: 'time',
        yKey: 'prod',
        stacked: false,
        yName: 'Prod',
      },
      {
        type: 'area',
        xKey: 'time',
        yKey: 'test',
        stacked: false,
        yName: 'OASIS Test',
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'prodRedirect',
        stacked: false,
        yName: 'Prod Redirects',
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'prodResales',
        stacked: false,
        yName: 'Prod Resales',
      },
      {
        type: 'line',
        xKey: 'time',
        yKey: 'prodOriginals',
        stacked: false,
        yName: 'Prod Originals',
      },
    ],
    axes: [
      {
        type: 'time',
        position: 'bottom',
        nice: false,
      },
      {
        type: 'number',
        position: 'left',
        title: {
          text: 'TSRs Proccessed',
        },
        min: 0,
        max: 6,
      },
    ],
    legend: {
      position: 'bottom',
    },
  }), [data, mode]);

  async function getOasisData() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_getOASISusage`,
    }

    axios(options).then(response => {
      const parsedData = response.data.map((row) => ({
        ...row,
        time: new Date(row.time),
      }))

      //enqueueSnackbar(JSON.stringify(parsedData))

      setData(parsedData);
    }).catch(err => { enqueueSnackbar(`Error fetching chart data.  URL: ${options.url} Error:  ${err.response?.data ?? err.message}`) });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getOasisData();
    }, refreshRateInMilliseconds);

    return () => clearInterval(interval);
  }, [])

  return (
    <AgChartsReact options={options} />
  )
}

var systemLoad = 0;
var userLoad = 0;
var data = [];
var refreshRateInMilliseconds = 1000;
var millisecondsOfData = 30 * 1000;

function calculateRandomDelta(maxChange) {
  return maxChange / 2 - Math.floor(Math.random() * Math.floor(maxChange + 1));
}

function ensureBounds(load, max) {
  if (load > max) {
    return max;
  } else if (load < 0) {
    return 0;
  }
  return load;
}


function calculateCpuUsage() {
  systemLoad = ensureBounds(systemLoad + calculateRandomDelta(2), 30);
  userLoad = ensureBounds(userLoad + calculateRandomDelta(4), 70);



}



function getData() {

  var dataCount = millisecondsOfData / refreshRateInMilliseconds;

  data.shift();
  var timeDelta = (dataCount - data.length - 1) * refreshRateInMilliseconds;
  var now = Date.now();

  while (data.length < dataCount) {
    calculateCpuUsage();
    data.push({ time: now - timeDelta, system: systemLoad, user: userLoad });
    timeDelta -= refreshRateInMilliseconds;
  }

  return data;

}