import { useState, } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Select, MenuItem, FormControl, InputLabel, FormControlLabel, FormGroup, Grid, Checkbox, TextField, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default ({open, handleClose, handleSave}) => {
    const [subject, setSubject] = useState('');
    const [status, setStatus] = useState('');
    const [description, setDescription] = useState('');
    const [tenant, setTenant] = useState('');
    const [parentId, setParentId] = useState('');
    const [priority, setPriority] = useState('');
    const [release, setRelease] = useState(dayjs());
    const [approved, setApproved] = useState(false);
    const [analysis, setAnalysis] = useState('');
    const [designNotes, setDesignNotes] = useState('');

    const statusValues = ['Offering Identified', 'Analysis', 'Design', 'Development', 'Testing', 'UAT', 'Deployed'];
    const priorityValues = ['High', 'Medium', 'Low'];

    return(
        <Dialog open={open} fullWidth >
            <DialogTitle id="add-requirement-dialog-title">
                {`Add New Requirement`}
            </DialogTitle>
            <DialogContent>
                <Box sx={{display: 'flex', p:1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField 
                                fullWidth
                                variant='outlined' 
                                onChange={(e) => setSubject(e.target.value)}
                                label='Subject'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    variant='outlined' 
                                    onChange={(e) => setStatus(e.target.value)}
                                    label='Status'
                                >
                                    {statusValues.map(val => 
                                        <MenuItem value={val}>{val}</MenuItem>    
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant='outlined' 
                                onChange={(e) => setDescription(e.target.value)}
                                label='Description'
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    fullWidth
                                    variant='outlined' 
                                    onChange={(e) => setPriority(e.target.value)}
                                    label='Priority'
                                >
                                    {priorityValues.map(val => 
                                        <MenuItem value={val}>{val}</MenuItem>    
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Expected Release"
                                    fullWidth
                                    value={release}
                                    onChange={(e) => setRelease(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox checked={approved} onChange={() => setApproved(!approved)}/>} 
                                    label="Approved for Development" 
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth
                                variant='outlined' 
                                onChange={(e) => setTenant(e.target.value)}
                                label='Tenant ID'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                fullWidth
                                variant='outlined' 
                                onChange={(e) => setParentId(e.target.value)}
                                label='Parent ID'
                                placeholder="Parent ID"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant='outlined' 
                                multiline
                                rows={4}
                                placeholder="Analysis"
                                onChange={(e) => setAnalysis(e.target.value)}
                                label='Analysis'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                multiline
                                placeholder="Notes"
                                rows={4}
                                variant='outlined' 
                                onChange={(e) => setDesignNotes(e.target.value)}
                                label='Desgin Notes'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleSave(subject, status, description, priority, release, approved, tenant, analysis, designNotes, parentId)}>{`Save & Close`}</Button>
            </DialogActions>
        </Dialog>
    );
}