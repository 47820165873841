import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useColorMode } from '../styles/ColorMode/useColorMode';
import { IconButton } from '@mui/material';

export const ToggleColorModeButton = (props) => {
    const { mode, toggleColorMode } = useColorMode();

    return (
        <IconButton onMouseDown={toggleColorMode} {...props} sx={{ color: '#fff' }}>
            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
    );
}