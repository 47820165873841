import { StyledTableCell, StyledTableRow } from "../StyledTableComps";
import { Box, Button, Paper, TableBody, TableContainer, Table, TextField, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses, Tooltip } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect } from "react";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

export default function TemplateButtons({title, tooltipText, templates, handleRowClick, rowTooltip, tableId=''}) {

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  return(
   
          <Box sx={{display: 'flex', p:1}}>Template Buttons:&nbsp; 
            {templates.map((template) =>
              
                <Tooltip title={rowTooltip ? rowTooltip(template.data) : ''} arrow placement="top">
                  <Button 
                    sx={{mr: "4px"}}
                    endIcon={<AddCircleOutlinedIcon />} 
                    id="addReassignmentRow" 
                    variant={buttonVariant} 
                    //disabled={buttonsDisabled}
                    color={buttonColor}
                    size={buttonSize}
                    onClick={() => handleRowClick(template)}
                  >{template.name}</Button>
                </Tooltip>
                
            )}
        </Box>
  );
  }
