import { useCallback, useContext } from "react";
import { UserContext } from "./fetchUserGroups";
import { userGroups } from "../authConfig";

export function useUserGroups() {
  const groups = useContext(UserContext);

  const userIsInGroup = useCallback((...args) => {
    const inGroupOrAdmin = groups.find((group) => {
        const admin = group === userGroups.admins;
        return admin || args.includes(group);
    });
    return !!inGroupOrAdmin;
  }, [groups]);

  return {
    groups,
    userIsInGroup,
  };
}