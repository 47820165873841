import { Box } from "@mui/material";
import { CreateDealButton, ConfirmDealButton, RollbackDealButton, EditDealButton, VoidDealButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from "../../Ribbon";

export const DealActionsGroup = ({ expanded, formId, handleFetchData, selectedRow, gridRef }) => {
    const toConfirm = selectedRow?.Status?.toLowerCase() !== 'confirmed';
    const confirmButtonText = toConfirm ? 'Confirm' : 'Unconfirm';
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <RibbonGroupContainer spacing={1}>

            <CreateDealButton
                expanded={expanded}
                formId={formId}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />

            <RollbackDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            />

            <ConfirmDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
                label={confirmButtonText}
            />

            <VoidDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

        </RibbonGroupContainer>
    )
}