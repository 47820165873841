import { useMemo, useEffect, useState, useImperativeHandle, useRef, forwardRef, useCallback } from 'react';
import { TemplateToolPanel } from '../ToolPanels/TemplateToolPanel';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { useSnackbar } from 'notistack';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { TextField, Tooltip, Box, Button, IconButton, Collapse } from '@mui/material';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useServiceDropdown } from '../useServiceDropdown';
import { AutocompleteEditor } from '../AutocompleteEditor';
import { AgGridReact } from "ag-grid-react";
import { LayoutToolPanel } from "../ToolPanels/LayoutToolPanel";
import useHeader from '../useHeader';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';
import useGridLayout from '../useGridLayout';
import moment from 'moment';
import ProfileDetail from './ProfileDetail';
import AddIcon from '@mui/icons-material/Add';
import { logAction } from '../../utils/auditLogger';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';

export default forwardRef((props, ref) => {
  const { addTemplate, templates, deleteTemplate, timezone, hideReassignments, toggle } = props;

  const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',];
  const guid = useLocalGuid();
  const headers = useHeader();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [selectedRows, setSelectedRows] = useState([]);
  const [actOnConfirm, setActOnConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [userEditedRelinquish, setUserEditedRelinquish] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [setRowValuesWithService, serviceValues] = useServiceDropdown('Real Time Redirects');

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const storageLocation = 'real-time-redirect-layout';

  useEffect(() => {
    window.addEventListener('beforeunload', saveDataLocal);
    return () => {
      window.removeEventListener('beforeunload', saveDataLocal);
    }
  }, []);

  useEffect(() => {
    if (serviceValues.values.length > 0) {
      loadLayout();
    }
  }, [serviceValues]);

  function saveDataLocal() {
    const data = [];
    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      data.push(newData);
    })
    localStorage.setItem(storageLocation + 'Data', JSON.stringify(data ?? []));
  };

  function loadDataLocal() {
    try {
      const dataObject = localStorage.getItem(storageLocation + 'Data');
      const data = JSON.parse(dataObject);
      const rowData = data ?? [];
      ref.current.api.applyTransaction({
        add: rowData,
        addIndex: 0,
      });
    } catch (err) {
      enqueueSnackbar(`Error loading data from ${storageLocation ?? 'local storage'}. Message: ${err}`);
    }
  };

  function onGridReady(params) {
    loadDataLocal();
    if (serviceValues.length > 0) {
      loadLayout();
    }
  }

  const ProfileCellRenderer = (props) => {
    const buttonClicked = () => {
      props.node.setExpanded(!props.node.expanded)
    }

    return (
      <span>
        <button onClick={() => buttonClicked()}>Set Profile</button>
      </span>
    );
  }

  const baseColDefs = [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      initialWidth: 150,
      rowDrag: true,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      initialWidth: 100,
      tooltipField: "Result",
      field: "Status",
    },
    {
      editable: false,
      headerName: "RedirRef",
      initialWidth: 100,
      field: "Redirected Ref",
    },
    {
      headerName: "TP",
      initialHide: false,
      initialWidth: 80,
      field: "Provider",
    },
    {
      headerName: "Source",
      initialHide: true,
      initialWidth: 100,
      field: "Source",
    },
    {
      editable: false,
      headerName: "Sink",
      initialWidth: "150",
      initialHide: "true",
      field: "Sink",
    },
    {
      headerName: "POR",
      initialHide: false,
      initialWidth: 70,
      field: "PointOfReceipt",
      cellEditor: 'autocompleteEditor',
      //cellEditorPopup: true,
      //cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
        field: 'por'
      },
    },
    {
      headerName: "POD",
      initialHide: false,
      initialWidth: 70,
      field: "PointOfDelivery",
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
        field: 'pod'
      },
    },
    {
      headerName: "Start D",
      initialHide: false,
      initialWidth: 80,
      field: "Start Date",
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        valueFormat: 'MM/DD/YYYY',
        closeOnSelect: true,
      },
    },
    {
      headerName: "Start T",
      initialHide: false,
      initialWidth: 80,
      field: "Start Time",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: hours,
      },
    },
    {
      headerName: "Stop D",
      initialHide: false,
      initialWidth: 80,
      field: "Stop Date",
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        valueFormat: 'MM/DD/YYYY',
        closeOnSelect: true,
      },
    },
    {
      headerName: "Stop T",
      initialHide: false,
      initialWidth: 80,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: hours,
      },
      field: "Stop Time",
    },
    /*{
      headerName: 'Profile',
      editable: false,
      field: 'profileInfo',
      cellRenderer: 'profileCellRenderer',
    },*/
    {
      headerName: "Vol",
      initialWidth: 60,
      field: "Volume",
    },
    {
      headerName: "$$",
      initialWidth: 60,
      field: "Price",
    },
    {
      field: 'scheduleDate',
      headerName: 'Schedule Date',
      initialHide: true,
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        timeFormat: 'HH:mm',
        valueFormat: 'MM/DD/YYYY HH:mm',
        closeOnSelect: true,
        allowDelete: true,
      },
    },
    {
      headerName: "Svc",
      initialWidth: 180,
      field: "Service",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: serviceValues,
      //valueSetter: setRowValuesWithService,
    },
    {
      headerName: "Service_Increment",
      initialHide: true,
      initialWidth: 80,
      field: "Service_Increment",
    },
    {
      headerName: "TS_Class",
      initialHide: true,
      initialWidth: 80,
      field: "TS_Class",
    },
    {
      headerName: "TS_Type",
      initialHide: true,
      initialWidth: 80,
      field: "TS_Type",
    },
    {
      headerName: "TS_Period",
      initialHide: true,
      initialWidth: 80,
      field: "TS_Period",
    },
    {
      headerName: "TS_Window",
      initialHide: true,
      initialWidth: 80,
      field: "TS_Window",
    },
    {
      headerName: "TS_Subclass",
      initialHide: true,
      initialWidth: 80,
      field: "TS_Subclass",
    },
    {
      headerName: "Path Name",
      initialHide: true,
      initialWidth: 100,
      field: "Path_Name",
    },
    {
      headerName: "Pre",
      initialHide: false,
      initialWidth: 60,
      field: "Preconfirmed",
    },
    {
      headerName: "Seller",
      initialHide: true,
      initialWidth: 80,
      field: "Seller Code",
      filter: "agSetColumnFilter",
      filter: true,
    },
    {
      headerName: "Cust",
      initialHide: true,
      initialWidth: 80,
      field: "Customer Code",
    },
    {
      editable: false,
      headerName: "TSRStartUTC",
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
    },
    {
      editable: false,
      headerName: "TSRStopUTC",
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
    },
    {
      editable: false,
      headerName: "TSRSaleRef",
      initialWidth: "100",
      initialHide: "true",
      field: "Sale Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRRequestRef",
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRDealRef",
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRPostingRef",
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRRequestType",
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRRelatedRef",
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRSellerRef",
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRCustName",
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRCustEmail",
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
  ]

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

  const addRedirectItems = (addIndex) => {
    const newItems = [
      createNewRedirectRowData(),
    ];
    addRedirectRow(newItems);
  };

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  async function onSaveDataButtonClick() {
    enqueueSnackbar('Submitting redirect to OASIS...');
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkRedirectRealTime?UserGuid=${guid}&timeZone=${renderTimezone(timezone)}&Caller=PowerStation.RealTime`;
    handlePost(url).then(response => {
      enqueueSnackbar('Redirect sent to OASIS database.');
    });
  }

  function handleConfirm() {
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkConfirm?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`
    enqueueSnackbar('Submitting confirmation to OASIS...');
    handlePost(url).then(response => {
      enqueueSnackbar('Confirmation sent to OASIS database.');
    });
  }

  function handleWithdraw() {
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkWithdraw?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`
    enqueueSnackbar('Submitting withdrawal to OASIS...');
    handlePost(url).then(response => {
      enqueueSnackbar('Withdrawal sent to OASIS database.');
    });
  }

  function handleRelinquish() {
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkRelinquish?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`
    enqueueSnackbar('Submitting relinquish to OASIS...');
    handlePost(url).then(response => {
      enqueueSnackbar('Relinquish sent to OASIS database.');
    });
    setUserEditedRelinquish(false);
  }

  async function handlePost(url) {
    setSubmitButtonDisabled(true);

    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();


    const rowData = [];

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      newData['isExpanded'] = node.expanded;
      newData.priceInfo = undefined;
      newData.Price = Number(newData.Price ?? 0).toFixed(2);
      rowData.push(newData);
    })

    const options = {
      url: url,
      data: JSON.stringify(rowData),
      headers: {
        headers: headers,
      },
    }

    return axios.post(options.url, options.data, options.headers).then(response => {
      if (response?.data && !(typeof response.data === 'string')) {
        handleResponse(response.data);
        ref.current.api.hideOverlay();
      } else {
        throw new Error(response?.data ?? 'No response data');
      }
    }).catch(err => {
      enqueueSnackbar(`${err}`);
      logAction(`Real Time Redirect error. ${err}`, 'RealTimeRedirects', headers.userGuid);
      ref.current.api.hideOverlay();
    });
  }

  function handleResponse(newData) {
    const rowData = [];
    ref.current.api.forEachNode(function (node) {
      rowData.push(node.data);
    });
    ref.current.api.applyTransaction({
      remove: rowData,
      add: newData,
      addIndex: 0,
    });
    ref.current.api.forEachNode(function (node) {
      node.setSelected(node.data.isSelected);
      node.setExpanded(node.data.isExpanded);
    })
  }

  function addRedirectRow(newRowData) {
    const res = ref.current.api.applyTransaction({
      add: newRowData,
    });
  };

  const addRedirectRowCopy = (addIndex) => {
    ref.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = ref.current.api.getSelectedRows();
    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      newRow['New Aref'] = "";
      newRow['Status'] = "";
      newRow['Result'] = "";
      return newRow;
    })
    addRedirectRow(data);
  };

  const onRemoveRedirectSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      {
        id: 'redirectTemplates',
        labelDefault: 'Templates',
        labelKey: 'redirectTemplates',
        //iconKey: '',
        toolPanel: 'templateToolPanel',
        toolPanelParams: {
          fieldNames: ['POR', 'POD', 'name'],
          currentTemplates: templates,
          addTemplate: addTemplate,
          deleteTemplate: deleteTemplate
        },
        minWidth: 180,
        maxWidth: 400,
        width: 300
      },
      layoutPanel
    ],
    position: 'right',
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },

  };

  function onRowDataUpdated(params) {
    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);

    const toUpdate = [];
    ref.current.api.forEachNode(node => {
      const data = node.data;
      if (!data.profileInfo?.length && data['Start Date'] && data['Stop Date']) {
        node.data.profileInfo = generateHourlyBlocks({
          startDate: moment(data['Start Date']),
          stopDate: moment(data['Stop Date']),
          startHour: parseInt(data['Start Time'].split(':')[0]), //for example this takes '16:00' -> 16
          stopHour: parseInt(data['Stop Time'].split(':')[0]),
          capacity: data.Volume,
          price: data.Price,
        });
        toUpdate.push(node.data)
      }
    })

    toUpdate.length && ref.current.api.applyTransaction({
      update: toUpdate,
    })
  }

  const [disableConfirm, setDisableConfirm] = useState(true);
  const [disableWithdraw, setDisableWithdraw] = useState(true);
  const [disableRelinquish, setDisableRelinquish] = useState(true);
  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    const selectedRowCount = selected.length;
    setSubmitButtonDisabled(selectedRowCount === 0);
    setSelectedRows(selected);

    const statuses = selected.map(row => row.Status);
    if (statuses.length) {
      setDisableConfirm(!statuses.every(status => ['COUNTEROFFER', 'ACCEPTED'].includes(status)));
      setDisableWithdraw(!statuses.every(status => ['COUNTEROFFER', 'ACCEPTED', 'REBID', 'STUDY', 'QUEUED', 'RECEIVED'].includes(status)));
      setDisableRelinquish(!statuses.every(status => ['CONFIRMED'].includes(status))
        || !selected.every(row => row.RequestType !== 'RELINQUISH')
        || !userEditedRelinquish
      );
    } else {
      setDisableRelinquish(true);
      setDisableConfirm(true);
      setDisableWithdraw(true);
      setUserEditedRelinquish(false);
    }
  }

  useEffect(() => {
    if (ref.current?.api) {
      const selected = ref.current.api.getSelectedRows();
      const statuses = selected.map(row => row.Status);
      if (statuses.length) {
        setDisableRelinquish(!statuses.every(status => ['CONFIRMED'].includes(status))
          || !selected.every(row => row.RequestType === 'REDIRECT')
          || !userEditedRelinquish
        );
      } else {
        setDisableRelinquish(true);
      }
    }
  }, [userEditedRelinquish])

  function onRedirectCellClicked(e) {
    if (e.column.colDef.headerName === 'Profile') {
      ref.current.api.openToolPanel('profile');
    }
  }

  let newCount = 1;
  function createNewRedirectRowData() {
    const newData = {

    };
    newCount++;
    return newData;
  }

  function handleConfirmation() {
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/${confirmationAction}?userGuid=${guid}&aref=${selectedRows[0]['New Aref']}&primaryProvider=${selectedRows[0].Provider}`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      enqueueSnackbar(`Success`)
      setOpenConfirmationDialog(false);
    }).catch(error => {
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)

      // enqueueSnackbar(`${error.message}`)
      setOpenConfirmationDialog(false);
    });
  }


  const statusResult = (params) => {

    var status = "Success";
    if (params.data.Status === "Pre-Queued" & params.data.Result !== "") { status = "ERROR" }
    else if (params.data.Status === "" & params.data.Result !== "") { status = "ERROR" }
    else { status = params.data.Status }

    return status;
  };

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }

  function generateHourlyBlocks(config) {
    let blocks = [];
    let next = moment(config.startDate).hour(config.startHour).startOf('hour');
    const end = moment(config.stopDate).hour(config.stopHour, 'hours').startOf('hour');
    while (end.isAfter(next, 'hour')) {
      blocks.push({
        startDateTime: moment(next).format('MM/DD/YYYY HH:mm'),
        endDateTime: moment(next).add(1, 'hours').format('MM/DD/YYYY HH:mm'),
        capacityRequested: config.capacity,
      });
      next = moment(next).add(1, 'hour');
    }
    return blocks;
  }

  function onCellValueChanged(params) {
    setRowValuesWithService(params);
    const node = params.node;
    ref.current.api.redrawRows({ rowNodes: [node] });
    if (['Start Time', 'Start Date', 'Stop Date', 'Stop Time'].includes(params.colDef.field)) {
      const data = params.data;
      node.data.profileInfo = generateHourlyBlocks({
        startDate: moment(data['Start Date']),
        stopDate: moment(data['Stop Date']),
        startHour: parseInt(data['Start Time'].split(':')[0]), //for example this takes '16:00' -> 16
        stopHour: parseInt(data['Stop Time'].split(':')[0]),
        capacity: data.Volume,
        price: data.Price,
      })
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    }
  }

  function onCellEditingStopped(params) {
    const node = params.node;
    if (params.newValue !== params.oldValue && params.colDef.field === 'Volume') {
      node.data.profileInfo = node.data.profileInfo.map(block => ({
        ...block,
        capacityRequested: params.newValue,
      }))
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    } else if (params.newValue !== params.oldValue && params.colDef.field === 'Price') {
      node.data.profileInfo = node.data.profileInfo.map(block => ({
        ...block,
        price: params.newValue,
      }))
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    }
  }

  const height = hideReassignments ? '85vh' : '41vh';

  const defaultColDef = useMemo(() => ({
    editable: params => !params.data['New Aref'],
    filter: "agSetColumnFilter",
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
  }), [])

  // dynamically assigning detail row height
  const getRowHeight = params => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) { return undefined; }

    const detailDataArray = params.data.profileInfo;
    // otherwise return height based on number of rows in detail grid
    const detailPanelHeight = (70 + (detailDataArray.length * 32));
    return detailPanelHeight;
  };


  return (
    <AgGridContainer
      style={{
        height: height,
        width: "98%",
        padding: "5px",
        // clear: "both",
        // overflow: "hidden",
      }}
    >
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleConfirmation}
        confirmText={actOnConfirm ? 'YES' : 'OK'}
        singleAction={!actOnConfirm}
      />
      {/* <SaveTemplateDialog 
        templateData={templateData}
        open={saveTemplateOpen}
        handleClose={handleSaveTemplateClose}
        transmissionTemplateTypeId={transmissionTemplateTypeId}/> */}
      <Box sx={{ display: 'flex', p: 1, alignItems: 'stretch', }}>
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveRedirectSelected()}
            endIcon={<DeleteForeverIcon />}
            disabled={submitButtonDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Add a new blank row to the grid." arrow placement="top">
          <Button
            endIcon={<AddCircleOutlinedIcon />}
            id="addRedirectRow"
            variant={buttonVariant}
            //disabled={buttonsDisabled}
            disabled
            color={buttonColor}
            size={buttonSize}
            onClick={() => addRedirectItems()}
          >Add New</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            id="addRedirectRowCopy"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={submitButtonDisabled}
            //onClick={() => setOpenRowEditor(!openRowEditor)}
            onClick={addRedirectRowCopy}
          >Add Copy</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Submit all redirects without New TSR number and check status on TSRs with New TSR numbers." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={submitButtonDisabled}
            onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Select a TSR and click here to confirm it." arrow placement="top">
          <Button
            endIcon={<CheckIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={disableConfirm}
            onClick={handleConfirm}
          >CONFIRM</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Select a TSR and click here to withdraw it." arrow placement="top">
          <Button
            endIcon={<UndoIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={disableWithdraw}
            onClick={handleWithdraw}
          >WITHDRAW</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Select a TSR and click here to relinquish it." arrow placement="top">
          <Button
            endIcon={<UndoIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={disableRelinquish}
            onClick={handleRelinquish}
          >RELINQUISH</Button>
        </Tooltip>&nbsp;
        <Box sx={{ flexGrow: 1, }} />
        <Collapse in={hideReassignments}>
          <Tooltip title='Click to show the reassignments grid.' arrow placement='top'>
            <IconButton onClick={toggle} size="small">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Collapse>
      </Box>
      <AgGridReact
        // style={{height: '100%'}}
        ref={ref}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        storageLocation={storageLocation}
        onCellValueChanged={onCellValueChanged}
        onCellEditingStopped={onCellEditingStopped}
        rowSelection="multiple"
        enableFillHandle={true}
        undoRedoCellEditing={true}
        overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">No redirects queued... drag a TSR in to this area or use Redirect templates to queue up new redirects.  If you have no Redirect templates, you can create a new one using the Templates tool panel on the right hand side of this grid.</span>'}
        undoRedoCellEditingLimit={20}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        tooltipShowDelay={0}
        singleClickEdit
        enterMovesDownAfterEdit={true}
        gridOptions={gridOptions}
        onRowDataUpdated={onRowDataUpdated}
        onSelectionChanged={onSelectionChanged}
        onCellClicked={onRedirectCellClicked}
        enableRangeSelection={true}
        stopEditingWhenCellsLoseFocus
        suppressRowClickSelection={true}
        //master/detail
        masterDetail={true}
        detailRowAutoHeight
        //getRowHeight={getRowHeight}
        detailCellRenderer={'detailCellRenderer'}
        detailCellRendererParams={{
          includePrice: true,
          editable: data => !data['New Aref'],
          setUserEditedRelinquish: setUserEditedRelinquish,
        }}
        //deselect the next line to allow users to copy and paste text
        //enableCellTextSelection={true}
        animateRows={true}
        //rowData={redirectRowData}
        //groupSelectsChildren={true}
        components={{
          //profilePicker: ProfilePicker,
          autocompleteEditor: AutocompleteEditor,
          //templateToolPanel: RedirectTemplatePanel,
          templateToolPanel: TemplateToolPanel,
          layoutToolPanel: LayoutToolPanel,
          detailCellRenderer: ProfileDetail,
          profileCellRenderer: ProfileCellRenderer,
          dateEditor: DateCellEditor,
          // profileToolPanel: ProfileToolPanel,
          // serviceToolPanel: ServiceToolPanel,
        }}
      />
    </AgGridContainer>
  );
});
