import * as React from 'react';
import useHeader from './useHeader';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import TextField from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useMsal } from '@azure/msal-react';
import Slide from '@mui/material/Slide';
import { RegisterButton } from "./RegisterButton";
import { apiUrlPrefix, msalConfig } from '../authConfig.js';
import axios from "axios";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextField from './FormControls/FormTextField';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SignOnDialog = ({ open, onClose, redirectUri }) => {
  const defaultEmail = localStorage.getItem('DefaultUserName') ?? '';
  const headers = useHeader();

  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  })

  const { handleSubmit, formState: { errors }, control } = useForm({
    defaultValues: { email: defaultEmail, },
    resolver: yupResolver(schema),
  });

  //try to redirect users back to the page they were on before signing in
  //currently we can only redirect back to fastpath, transhunter, transnow, or the home page since these are the only ones configured in Azure
  //add URIs in azure before adding more here. -ecz 9/1/22
  function setRedirectUri() {
    const href = window.location.href;
    const base = msalConfig.auth.redirectUri;
    if (href.includes('/fastpath')) {
      return (`${base}/fastpath`);
    } else if (href.includes('transhunter')) {
      return (`${base}/transhunter`);
    } else if (href.includes('transnow')) {
      return (`${base}/transnow`);
    } else {
      return base;
    }
  }

  async function onSubmit(data) {
    const email = data.email;

    localStorage.setItem('DefaultUserName', email);

    let loginRequest = {
      loginHint: email,
      redirectUri: redirectUri ?? setRedirectUri(),
    };

    const sUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserAuthorityFetch&parm=${encodeURIComponent(email)}`

    const options = {
      method: 'GET',
      headers: headers,
      url: sUrl,
    }

    //await instance.handleRedirectPromise();

    axios(options).then(response => {
      loginRequest.authority = `https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/${response.data[0].authority}`;
      instance.loginRedirect(loginRequest);
      onClose();
    }).catch(err => {
      enqueueSnackbar(`Error retrieving authority for the user. Status: ${err.response?.status}. Message: ${err}  Url: ${sUrl}`);
    });
  }

  function onErrors() {
    enqueueSnackbar('Please enter a valid email.');
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      open={open}
      onClose={onClose}
    >
      <Box
        component="img"
        sx={{
          paddingTop: 10,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
        alt="Power Station Platform"
        src="./PowerStationLogo.png"
      />
      <DialogTitle>Power Station Sign In</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ paddingBottom: 5, paddingTop: 5, }}>
          <form id='signInForm' onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', }}>
            <FormTextField
              name='email'
              control={control}
              autoFocus
              label="Username/Email"
              fullWidth
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit(onSubmit)();
                }
              }}
              variant="outlined"
              error={errors?.email}
              helperText={errors?.email && 'Username must be a valid email.'}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 5 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Sign In</Button>
      </DialogActions>
      <Divider />
      <Stack direction='row' justifyContent='flex-end' spacing={2} sx={{
        paddingTop: 5,
        paddingBottom: 2,
        paddingRight: 1,
      }}>
        <RegisterButton
          color="primary"
          variant="outlined"
        >
          Don't have an Account?
        </RegisterButton>
        <Button onClick={onClose}>Cancel</Button>
      </Stack>
    </Dialog>
  );
};