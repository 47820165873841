import { Tab, Tabs, Box, Tooltip, Divider, List, ListItem, } from '@mui/material';
import { useParams, useNavigate } from 'react-router';
import { useDashboard } from './useDashboard';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

export const DashboardTabs = () => {
    const { views, dashboardKey, setOpenConfirmDelete, setOpenAddNew, } = useDashboard();
    const params = useParams();
    const viewId = params.id;
    const tabIndex = Math.max(views.findIndex(view => view.id.toString() === viewId), 0);
    const navigate = useNavigate();

    function handleAddNewClick() {
        setOpenAddNew(true);
    }

    function handleTabSelect(event, newIndex) {
        if (newIndex < views.length + 1) { //don't switch to the edit or delete tabs
            const id = views[newIndex]?.id ?? 'add-new';
            navigate(`../${id}`, { relative: 'path' });
            localStorage.setItem(`${dashboardKey}-last-view-id`, id);
        }
    }

    function handleViewUpdate() {
        setOpenAddNew(true);
    }

    return (
        <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            sx={{ maxHeight: '40px', minHeight: '40px' }}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
        >
            {views.map((view) => (
                <Tooltip title={<Box sx={{ pt: 2, }}>
                    <Divider>View Info</Divider>
                    <List dense sx={{ fontSize: '.75rem', }}>
                        <ListItem>Created by: {view.userName}</ListItem>
                        <ListItem>Is Shared: {!!view.shared ? 'True' : 'False'}</ListItem>
                    </List>
                    <Divider />
                </Box>} key={`${dashboardKey}-dashboard-tab-${view.id}`} placement='bottom' arrow>
                    <Tab
                        sx={{ maxHeight: '40px', minHeight: '40px' }}
                        label={view.label}
                    //key={`${dashboardKey}-dashboard-tab-${view.id}`}
                    />
                </Tooltip>
            ))}
            <Tab key='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
            <Tab key='editCurrentTab' label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleViewUpdate} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
            <Tab key='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
        </Tabs>
    );
}


