import { Box, Button, Paper, Stack, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@emotion/react';
import { AgChartsReact } from 'ag-charts-react';
import { AgCharts } from 'ag-charts-enterprise';
import { forwardRef, memo, useEffect, useState, } from 'react';
import styled from '@mui/material/styles/styled';
import dayjs from 'dayjs';
import { apiUrlPrefix } from '../../../authConfig';
import axios from 'axios';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';

AgCharts.setLicenseKey(process.env.REACT_APP_AG_CHARTS_ENTERPRISE_LICENSE_KEY);

const CorrelationsView = forwardRef(({ view, }, ref) => {
    const theme = useTheme();
    const headers = useHeader();
    const palette = theme.palette.primary;
    const { enqueueSnackbar } = useSnackbar();
    const [dailyData, setDailyData] = useState([]);
    const [hourlyData, setHourlyData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadAllData();
    }, [view]);

    function loadAllData() {
        setLoading(true);
        fetchData('daily').finally(() => {
            fetchData('hourly').finally(() => {
                setLoading(false);
            });
        });
    }

    async function fetchData(timePeriod) {
        const { inputs } = view;
        const formattedInputs = inputs.map(input => {
            const type = input.tags.find(tagObj => tagObj.category.toLowerCase() === 'type').tag;
            return {
                name: input.name,
                type: type,
            }
        });
        const url = `${apiUrlPrefix}/CrystalBall/Store/chain/JSON/Pushfetch?userGuid=${headers.userGuid}&chain=brain&name=fetchInputData`
            + `&parm=${headers.userGuid}`
            + `&parm=${timePeriod}`

        return axios.post(url, { inputs: formattedInputs, }, { headers: headers, }).then((response) => {
            if (response.data) {
                const newData = [...response.data?.reduce((acc, item) => {
                    const date = new Date(item.dateTimeValue);
                    const dateStr = date.toISOString();
                    if (acc.has(dateStr)) {
                        acc.set(dateStr, {
                            ...acc.get(dateStr),
                            [item.series]: item.value,
                        });
                    } else {
                        acc.set(dateStr, {
                            dateTimeValue: date,
                            [item.series]: item.value,
                        });
                    }
                    return acc;
                }, new Map()).values()];

                //order the data by date
                newData.sort((a, b) => a.dateTimeValue - b.dateTimeValue);

                if (timePeriod === 'daily') {
                    setDailyData(newData);
                } else {
                    setHourlyData(newData);
                }
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching input data: ${error}`, { variant: 'error', });
        });
    }

    function getWindowData(windowStart, windowEnd) {
        let data = dailyData;
        const oneDay = 1000 * 60 * 60 * 24; // ms/s * s/m * m/h * h/d = ms/d

        //if the difference between the start and end is less than 60 days, add hourly data
        if ((windowEnd - windowStart) < (oneDay * 60)) {
            const windowStartMinusADay = dayjs(windowStart).subtract(1, 'day').toDate();
            const windowEndPlusADay = dayjs(windowEnd).add(1, 'day').toDate();
            data = [...data, ...hourlyData.filter(item => item.dateTimeValue >= windowStartMinusADay && item.dateTimeValue <= windowEndPlusADay)].sort((a, b) => a.dateTimeValue - b.dateTimeValue);
        }
        return data;
    }

    const chartOptions = {
        dataSource: {
            getData: ({ windowStart, windowEnd }) => {
                return getWindowData(windowStart, windowEnd);
            },
        },
        zoom: {
            enabled: true,
            anchorPointX: 'pointer',
            anchorPointY: 'pointer',
            minVisibleItemsX: 2,
            enableSelecting: true,
        },
        navigator: {
            enabled: true,
            miniChart: {
                enabled: true,
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
        },
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
                strokes: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
            },
        },
        series: view.inputs.map(input => ({
            type: 'line',
            yName: input.name,
            xKey: 'dateTimeValue',
            yKey: input.name,
            strokeWidth: 2,
            marker: {
                size: 2,
            },
        })),
        autoSize: true,
        axes: [
            {
                position: 'bottom',
                type: 'time',
                keys: ['dateTimeValue',],
                nice: false,
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
                crosshair: {
                    enabled: true,
                },
            },
            {
                position: 'right',
                type: 'number',
                keys: view.inputs.map(input => input.name),
                title: {
                    text: 'Error',
                },
            },
        ],
        legend: {
            position: 'right',
        },
        overlays: {
            noData: {
                text: 'No data was returned for this view.',
            },
            noVisibleSeries: {
                text: 'Click on chart legend items on the right to show/hide series.',
            },
        },
    }

    return (
        <Box component={Paper} sx={{ width: '100%', height: '86vh', p: 1, position: 'relative', mt: 2, }}>
            <AgChartsReact options={chartOptions} />
            <NoDataOverlay visible={dailyData.length === 0} />
            <LoadingOverlay visible={loading} />
        </Box>
    );
});

export default memo(CorrelationsView);

const NoDataOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <Typography align='center' color='primary' variant='h6'>No data.</Typography>
        </OverlayContainer>
    );
}

const LoadingOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <CircularProgress />&nbsp;&nbsp;
            <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    display: visible ? 'flex' : 'none',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
}));