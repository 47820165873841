import { useMemo, useState } from "react";
import { Button, Grid, Stack, IconButton, Tooltip, } from "@mui/material"
import { SourceItemList } from "./SourceItemList";
import { TargetItemList } from "./TargetItemList";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import _ from 'lodash';
import { ListRefinement } from "./ListRefinement";

export const TransferList = ({ items = [], inputs = [], outputs = [], handleTransfer, maxInputs, maxOutputs, mode, }) => {
    const [sourceCheckedItems, setSourceCheckedItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const simpleMode = mode === 'simple';

    const typedItems = useMemo(() => {
        const withTypes = items.reduce((acc, item) => {
            let type = item.type;

            if (simpleMode && type !== 'output') { //only show output types in simple mode
                return acc;
            }

            if (!acc.has(item.name)) {
                acc.set(item.name, {
                    ...item,
                    tags: JSON.parse(item.tags),
                    types: [],
                });
            }
            //capitalize the first letter of the type
            type = type.charAt(0).toUpperCase() + type.slice(1);

            acc.get(item.name).types.push(type);
            const oldTags = acc.get(item.name).tags;
            const newTags = JSON.parse(item.tags);
            acc.get(item.name).tags = [...new Set([...oldTags, ...newTags])];
            return acc;
        }, new Map());
        return Array.from(withTypes.values());
    }, [items, simpleMode]);

    // Filter items based on searchText and selectedTags
    const filteredItems = useMemo(() => {
        return typedItems.filter((item) => {
            //first filter by tags
            const itemTags = item.tags.map((tagObj) => tagObj.tag);
            if (selectedTags.length && !selectedTags.some((tag) => itemTags.includes(tag)) && !item.types.some((type) => selectedTags.includes(type))) {
                return false;
            }
            //then filter by search text
            if (searchText.length > 0) {
                if (!item.name.toLowerCase().includes(searchText.toLowerCase())) {
                    return false;
                }
            }
            return true;
        });
    }, [typedItems, searchText, selectedTags]);

    const tags = useMemo(() => {
        const categorizedTags = typedItems.reduce((acc, item) => {
            item.tags.forEach((tag) => {
                if (!acc.has(tag.category)) {
                    acc.set(tag.category, new Set());
                }
                acc.get(tag.category).add(tag.tag);
            });
            return acc;
        }, new Map());
        //sort the tags in each category
        categorizedTags.keys().forEach((category) => {
            categorizedTags.set(category, Array.from(categorizedTags.get(category)).sort());
        });
        return categorizedTags;
    }, [typedItems]);

    function transferInputs() {
        const newItems = _.uniqBy([...inputs, ...sourceCheckedItems], 'name');
        handleTransfer('inputs', newItems);
        clearSelections();
    }

    function transferOutputs() {
        const newOutputs = sourceCheckedItems.filter(item => item.types.includes('Output'));
        const newItems = _.uniqBy([...outputs, ...newOutputs], 'name');
        handleTransfer('outputs', newItems);
        //all outputs must also be inputs
        transferInputs();
    }

    function clearSelections() {
        setSourceCheckedItems([]);
    }

    function removeInput(item) {
        const newItems = inputs.filter((input) => input.name !== item.name);
        handleTransfer('inputs', newItems);
        removeOutput(item);
    }

    function removeOutput(item) {
        const newItems = outputs.filter((output) => output.name !== item.name);
        handleTransfer('outputs', newItems);
    }

    function removeAllInputs() {
        handleTransfer('inputs', []);
        removeAllOutputs();
    }

    function removeAllOutputs() {
        handleTransfer('outputs', []);
    }

    const inputOverlayText = `Add any Inputs that would likely be useful to acheive an accurarte prediction of your Outputs. If you are running in trial mode, you are limited in the number of Inputs which may be used. Max number of inputs is ${maxInputs}.`;
    const outputOverlayText = `Add what you are trying to predict here. Any Output chosen must also be an Input. If you are running in trial mode, you are limited in the number of Outputs which may be used. Max number of outputs is ${maxOutputs}.`;

    return (
        <Grid item xs={12} container sx={{ height: 500, }}>
            <Grid item xs={3} sx={{ px: 1, height: 500 }}>
                <ListRefinement tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
            </Grid>
            <Grid item xs={5} sx={{ px: 1, height: 500 }}>
                <SourceItemList setSearchText={setSearchText} searchText={searchText} items={filteredItems} title='All Inputs/Outputs' checked={sourceCheckedItems} setChecked={setSourceCheckedItems} />
            </Grid>
            <Grid item xs={4} sx={{ height: 500 }} >
                {!simpleMode && <Stack spacing={1} direction='row' sx={{ width: '100%', height: '50%', pb: 1, }}>
                    <Tooltip title='Add checked items to the list of Inputs' placement="top" enterDelay={400}>
                        <IconButton
                            onClick={transferInputs}
                            sx={{ borderRadius: '4px', }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Tooltip>
                    <TargetItemList
                        items={inputs}
                        title='Inputs'
                        removeItem={removeInput}
                        removeAll={removeAllInputs}
                        overlayText={inputOverlayText}
                    />
                </Stack>}
                <Stack spacing={1} direction='row' sx={{ width: '100%', height: simpleMode ? '100%' : '50%', }}>
                    <Tooltip title='Add checked items to the list of Outputs and Inputs (all outputs must also be inputs).'>
                        <IconButton
                            onClick={transferOutputs}
                            sx={{ borderRadius: '4px', }}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </Tooltip>
                    <TargetItemList
                        items={outputs}
                        title='Outputs'
                        removeItem={removeOutput}
                        removeAll={removeAllOutputs}
                        overlayText={outputOverlayText}
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}