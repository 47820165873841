import { useLayoutEffect, useEffect, useState, useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Divider, ListItemIcon, ListItemText, MenuItem, Typography, ToggleButtonGroup, ToggleButton, Box, Stack } from '@mui/material';
import { useNavigate, useParams, } from 'react-router';
import PopoverNav from '../../../components/PopoverNav';
import { useDashboard } from './useDashboard';

export const ViewSelector = () => {
    const { views, dashboardKey, setOpenConfirmDelete, setOpenAddNew, } = useDashboard();
    const navigate = useNavigate();
    const storageKey = `${dashboardKey}-views`;
    const [sortMode, setSortMode] = useState('recent');
    const [recentlyUsed, setRecentlyUsed] = useState([]);
    const params = useParams();
    const viewId = params.id;

    useEffect(() => {
        if (viewId) {
            setRecentlyUsed((prev) => {
                const newRecent = prev.filter((id) => id !== viewId);
                newRecent.unshift(viewId);
                localStorage.setItem(storageKey, JSON.stringify({ recent: newRecent }));
                return newRecent;
            });
        }
    }, [viewId, storageKey]);

    useLayoutEffect(() => {
        const savedState = JSON.parse(localStorage.getItem(storageKey));
        if (savedState) {
            setRecentlyUsed(savedState.recent);
        }
    }, [storageKey]);

    function handleAddNewClick() {
        navigate(`../add-new`, { relative: 'path' });
        setOpenAddNew(true);
    }

    function handleEditClick() {
        setOpenAddNew(true);
    }

    function handleDeleteClick() {
        setOpenConfirmDelete(true);
    }

    function handleViewSelect(view) {
        navigate(`../${view.id}`, { relative: 'path' });
        localStorage.setItem(`${dashboardKey}-last-view-id`, view.id);
    }

    const sortedViews = useMemo(() => {
        if (sortMode === 'abc') {
            return views.toSorted((a, b) => a.label.localeCompare(b.label));
        } else if (sortMode === 'recent') {
            return views.toSorted((a, b) => {
                const aIndex = recentlyUsed.indexOf(a.id.toString());
                const bIndex = recentlyUsed.indexOf(b.id.toString());
                if (aIndex === -1) {
                    return 1;
                } else if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            });
        } else {
            return views;
        }
    }, [views, sortMode, recentlyUsed]);

    return (
        <PopoverNav
            icon={<MoreVertIcon />}
            popoverProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }
            }}
        >
            <Stack direction='row' spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 1, }}>
                <Typography>Sort:</Typography>&nbsp;&nbsp;
                <ToggleButtonGroup
                    exclusive
                    value={sortMode}
                    size="small"
                    color="primary"
                    sx={{ mt: .2, mr: 1.3, display: 'contents' }}
                    onChange={(_, newMode) => {
                        newMode && setSortMode(newMode)
                    }}
                >
                    <ToggleButton sx={{ mt: .5 }} value={'abc'}>
                        ABC
                    </ToggleButton>
                    <ToggleButton sx={{ mt: .5 }} value={'recent'}>
                        Recent
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>
            <Divider />
            {
                sortedViews.map((view) => (
                    <ListItem
                        key={`${dashboardKey}-dashboard-view-item-${view.id}`}
                        text={view.label}
                        onClick={() => handleViewSelect(view)}
                    />
                ))
            }
            < Divider />
            <ListItem text="Add New" onClick={handleAddNewClick} icon={<AddIcon />} />
            <ListItem text="Edit Current" onClick={handleEditClick} icon={<EditIcon />} />
            <ListItem text="Delete Current" onClick={handleDeleteClick} icon={<RemoveCircleOutlineIcon />} />
        </PopoverNav >
    );
}

const ListItem = ({ icon, text, onClick }) => {
    return (
        <MenuItem onClick={onClick}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>
                {text}
            </ListItemText>
        </MenuItem>
    );
};

