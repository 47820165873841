import { forwardRef, useContext, useState } from "react";
import { Box, Grid, Autocomplete, TextField, Collapse, DialogTitle, DialogContentText, Dialog, DialogActions, DialogContent, Alert, AlertTitle, } from "@mui/material"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import hours from '../TimedTSRs/Hours'
import { Toolbar, Button,  } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CriteriaContext } from "../CriteriaContext/criteriaContext";
import useFilteredOptions from "../useFilteredOptions";

export default (props) => {
  const { onRefresh, view, handleStatusChangeClick, handleSubmitDuplicateClick } = props;
  const [criteria, setCriteria] = useState({...view});
  const { providers, handleProviderChange, filteredCombos, customerCodes, filteredTSCombos, requestTypes } = useFilteredOptions(view.provider);

  const {
    requestType,
    provider,
    por,
    pod,
    customerCode,
    serviceIncrement,
    serviceClass,
    startDate,
    stopDate,
    startHour,
    stopHour,
  } = criteria;

  function handleChange(key, value) {
    setCriteria({
      ...criteria,
      [key]: value
    });
  }

  return(
    <Box sx={{display: 'flex', p:1, paddingTop:2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Button
            endIcon={<RefreshOutlinedIcon />} 
            size={'small'}
            variant={'contained'} 
            color={'primary'}
            onClick={() => onRefresh(criteria)}
          >Refresh</Button>&nbsp;&nbsp;
          <Button
            endIcon={<FilterAltIcon />} 
            size={'small'}
            variant={'contained'} 
            color={'primary'}
            onClick={handleSubmitDuplicateClick}
          >Submit Duplicate TSR</Button>&nbsp;&nbsp;
          <Button
            endIcon={<FilterAltIcon />} 
            size={'small'}
            variant={'contained'} 
            color={'primary'}
            onClick={handleStatusChangeClick}
          >Duplicate on Status Change</Button>&nbsp;&nbsp;
          <Box sx={{ flexGrow: 1, }} />
        </Grid>
        <Grid item container columns={60} spacing={1} alignItems='center' >
          <Grid item xs={5}>
            <Autocomplete
              fullWidth
              size="small"
              options={requestTypes}
              value={requestType}
              onChange={(_, newValue) => {
                handleChange('requestType', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Request Type"
                  color="success" 
                  placeholder="Request Type"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              id="Provider"
              fullWidth
              freeSolo
              size="small"
              options={providers}
              value={provider}
              onInputChange={(e, newValue) => {
                handleProviderChange(newValue);
                handleChange('provider', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Provider"
                  color="success" 
                  placeholder="Provider"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              id="POR"
              size="small"
              fullWidth
              freeSolo
              options={filteredCombos.pors}
              value={por}
              onInputChange={(e, newValue) => {
                handleChange('por', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="POR"
                  color="success" 
                  placeholder="POR"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              id="POD"
              fullWidth
              freeSolo
              options={filteredCombos.pods}
              value={pod}
              size="small"
              onInputChange={(e, newValue) => {
                handleChange('pod', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="POD"
                  color="success" 
                  placeholder="POD"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Time"
                fullWidth
                value={startDate ?? null}
                onChange={(newValue) => handleChange('startDate', newValue)} 
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              size="small"
              options={hours.map(hour => `${hour}:00`)}
              value={startHour}
              onChange={(_, newValue) => {
                handleChange('startHour', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Start Hour"
                  color="success" 
                  placeholder="Start Hour"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="End Time"
                fullWidth
                value={stopDate ?? null}
                onChange={(newValue) => handleChange('stopDate', newValue)} 
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              size="small"
              options={hours.map(hour => `${hour}:00`)}
              value={stopHour}
              onChange={(_, newValue) => {
                handleChange('stopHour', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Stop Hour"
                  color="success" 
                  placeholder="Stop Hour"
                />
              )}
            />
          </Grid>
          <Grid item xs={11}>
            <Autocomplete
              id="POD"
              fullWidth
              multiple
              size="small"
              options={customerCodes}
              value={customerCode}
              onChange={(_, newValue) => {
                handleChange('customerCode', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Customer Code"
                  color="success" 
                  placeholder="Customer Code"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              freeSolo
              size="small"
              options={filteredTSCombos.serviceIncrements}
              value={serviceIncrement}
              onInputChange={(_, newValue) => {
                handleChange('serviceIncrement', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Service Increment"
                  color="success" 
                  placeholder="Service Increment"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Autocomplete
              id="Class"
              fullWidth
              freeSolo
              size="small"
              options={[ 'FIRM', 'NON-FIRM', 'SECONDARY' ]}
              value={serviceClass}
              onInputChange={(_, newValue) => {
                handleChange('serviceClass', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Class"
                  color="success" 
                  placeholder="Class"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid> 
    </Box>
  )
}