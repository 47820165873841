import { useState, useEffect, } from 'react';
import { Box, Dialog, Button, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails, Typography, DialogActions, IconButton, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChatContent from './ChatContent';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import StartNewChatDialog from './StartNewChatDialog';
import { apiUrlPrefix } from '../../authConfig';
import useHeader from '../useHeader';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const ChatDialog = ({ open, handleClose }) => {
    const [chats, setChats] = useState([]);
    const [openStartNew, setOpenStartNew] = useState(false);
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        handleChatFetch();
    }, []);



    function handleChatFetch() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserChatFetch&parm=${headers.userGuid}`

        let options = {
            headers: headers,
            url: url,
        };

        axios(options).then((response) => {
            const filtered = response.data.filter((chat) => chat.other_user_name !== 'Samantha Marie');
            const result = filtered.map((chat) => {
                const history = JSON.parse(chat.message_history);
                const messages = history.map(message => ({
                    content: message.message_content,
                    timeStamp: new Date(message.dateCreated, 'YYYY-MM-DDTHH:mm:ss.SSS'),
                }));
                return {
                    user: chat.other_user_name,
                    messages: messages,
                }
            });
            setChats(result);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    function handleSendMessage(message, index) {
        const newChats = [...chats];
        newChats[index].messages.push(message);
        setChats(newChats);
    }

    function handleViewSettings() {
        console.log('View settings');
    }

    function handleStartNew(user) {
        const newChats = [...chats];
        newChats.push({ user: userLabel(user), messages: [] });
        setChats(newChats);
        setOpenStartNew(false);
    }

    const userLabel = (user) => {
        if (user.firstName && user.lastName) {
            return `${user.firstName} ${user.lastName}`;
        } else {
            return user.userName;
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <StartNewChatDialog open={openStartNew} handleClose={() => setOpenStartNew(false)} handleStartNew={handleStartNew} />
            <DialogTitle sx={{ paddingBottom: 0, }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Messages</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: '10px', }}>
                {chats.map((chat, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{chat.user}</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingBottom: '3px', }}>
                            <ChatContent history={chat.messages} handleSendMessage={(message) => handleSendMessage(message, index)} />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                <IconButton onClick={handleViewSettings} color='primary'>
                    <SettingsIcon />
                </IconButton>
                <Button
                    endIcon={<AddCircleOutlineIcon />}
                    onClick={() => setOpenStartNew(true)}
                >
                    Start New
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChatDialog;