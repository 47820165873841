import { IconButton, Stack, TextField, Box, Typography } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useCallback, } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { debounce } from "lodash";
import { useFormContext } from "react-hook-form";
import { RepublishViewButton } from "../../RibbonButtons/RepublishViewButton";
import { RibbonGroupContainer } from "../../Ribbon";
import { useTheme } from "@mui/material";

export const ViewForm = ({ onSubmit, formId, expanded, }) => {
    const { control, getValues, setValue, formState: { errors, }, handleSubmit } = useFormContext();
    const theme = useTheme();

    const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const stop = getValues('endDate');
        if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'endDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('endDate', value);
        } else {
            setValue(key, value);
        }
    }, 300), [setValue, getValues]);

    const handleIncrementDates = useCallback((days) => {
        const start = getValues('startDate');
        const stop = getValues('endDate');
        setValue('startDate', start.add(days, 'day'));
        setValue('endDate', stop.add(days, 'day'));
    }, []);

    const purchaseColorClass = theme.palette.mode === 'light' ? 'ag-row-purchase' : 'ag-row-purchase-dark';
    const saleColorClass = theme.palette.mode === 'light' ? 'ag-row-sale' : 'ag-row-sale-dark';

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <RibbonGroupContainer>
                <RepublishViewButton
                    expanded={expanded}
                />

                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Trade Date Start"
                            onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />&nbsp;
                        <FormDatePicker
                            name='endDate'
                            control={control}
                            label="Trade Date End"
                            onChange={(newValue) => handleDateUpdate('endDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.endDate,
                                    helperText: errors.endDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{ minWidth: '170px', }}
                        />
                    )}
                />

                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', pt: 1, }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={purchaseColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Purchases</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={saleColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Sales</Typography>
                    </Stack>
                </Stack>

            </RibbonGroupContainer>
        </form >
    );
}