import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useActionAudit } from "../useActionAudit";
import { FormControlLabel, FormGroup, Grid, Checkbox, TextField, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";

export default ({open, handleClose, postingRefs, handleSave}) => {
    const [thresholdLimit, setThresholdLimit] = useState();
    const [usePercent, setUsePercent] = useState(false);
    const { logAction } = useActionAudit();
    const refList = postingRefs.reduce((list, next, i) => `${list}${next}${i < postingRefs.length - 1 ? ', ' : ''}`, '');

    return(
        <Dialog open={open} fullWidth >
            <DialogTitle id="alert-criteria-dialog-title">
                {`Set alerts for posting ref(s) ${refList}`}
            </DialogTitle>
            <DialogContent>
              <Box sx={{display: 'flex', p:1}}>
                <TextField 
                  size='medium' 
                  variant='outlined' 
                  label="Target Value (MW)"
                  onChange={(e) => setThresholdLimit(e.target.value)}
                />
              </Box> 
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleSave(thresholdLimit, usePercent)}>{`Save & Close`}</Button>
            </DialogActions>
        </Dialog>
    );
}