import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef, useState, } from "react";
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps";
import { useColumnTypes } from "../../AgGrid/useColumnTypes";
import { columnPanel } from "../../ToolPanels/DefaultToolPanels";
import { tooltipRenderer, redPastMarkerFormatter, jsonOrCommaSeparatedFormatter, } from "../Utils";
import dayjs from "dayjs";
import { useTheme } from "@mui/material";

export const DealDetailCellRenderer = (props) => {
    const { data, node, api, setSelectedRow, } = props;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const theme = useTheme();

    const rowData = useMemo(() => {
        return JSON.parse(data.dealJson);
    }, [data.dealJson]);

    const colDefs = useMemo(() => [
        { field: "dealID", headerName: "Deal ID", checkboxSelection: true, },
        { field: "Deal_Number", initialWidth: "150px", headerName: "Deal Name" },
        {
            initialWidth: "150px",
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
        },
        { field: 'Type_F_P', headerName: 'Transaction', initialWidth: "150px", },
        { field: "Transaction_Type", initialWidth: "150px", headerName: "Transaction Type" },
        {
            headerName: "Schedule Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const profile = params.data?.dealProfileJson;
                if (profile) {
                    //const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.MW ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        { field: "Type_F_P", headerName: "Transaction" },
        { field: "Status", headerName: "Status" },
        { field: "confirmed", initialWidth: "75px", headerName: "Confirmed" },
        { field: "Trade_Date", initialWidth: "150px", headerName: "Trade Date" },
        {
            initialWidth: "150px",
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        { field: "Contract", headerName: "Contract" },
        { field: "Trader", initialWidth: "200px", headerName: "Trader" },
        { field: "Counterparty", initialWidth: "150px", headerName: "Counterparty" },
        { field: "Time_Zone", initialWidth: "150px", headerName: "Time Zone" },
        { field: "Start_Date", initialWidth: "150px", headerName: "Start Date", type: 'dateColumn', },
        { field: "End_Date", initialWidth: "150px", headerName: "End Date", type: 'dateColumn', },
        { field: "Term", headerName: "Term" },
        { field: "Financial_Type", initialWidth: "150px", headerName: "Financial Type" },
        { field: "Market", initialWidth: "150px", headerName: "Market" },
        { field: "Zone", headerName: "Zone" },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        { field: "index_name", headerName: "Index Name" },
        { field: "forecast", headerName: "Forecast" },
        {
            field: "Total_MWh",
            headerName: "Total MWh",
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined && !isNaN(value)) {
                    // Format value with commas and up to 3 decimal places
                    return parseFloat(value).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                    });
                }
                return value;
            }
        },
        { field: "Deal_Currency", initialWidth: "150px", headerName: "Deal Currency" },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            initialWidth: "150px",
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        // { field: "Creation_Time_MPT", initialWidth: "150px", headerName: "Creation Time MPT", type: 'dateColumn', },
        { field: "Creation_Time", initialWidth: "150px", headerName: "Creation Time", type: 'dateColumn', },
        { field: "Creator", initialWidth: "150px", headerName: "Creator" },
        // { field: "Modification_Time_MPT", initialWidth: "150px", headerName: "Modification Time MPT", type: 'dateColumn', },
        { field: "Modification_Time", initialWidth: "150px", headerName: "Modification Time", type: 'dateColumn', },
    ], []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        minWidth: 100,
        initialWidth: "100px",
        // flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
        ]
    }), []);

    const containerId = `${node.nodeId}-detail-grid-container`;
    const heightRef = useRef();
    //lock in the height of the div container to prevent resizing to fit the tool panel
    function onToolPanelVisibleChanged() {
        if (gridRef.current.api.isToolPanelShowing()) {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current + 220}px`);
            gridRef.current.api.setDomLayout('normal');
        } else {
            const container = document.getElementById(containerId);
            container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current - 220}px`);
            gridRef.current.api.setDomLayout('autoHeight');
        }
    };

    function onGridSizeChanged() {
        const container = document.getElementById(containerId);
        heightRef.current = container?.clientHeight;
    };

    function handleSelectionChanged() {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(selectedRows[0]);
    }

    return (
        <AgGridContainer id={containerId} style={{ padding: theme.spacing(2), }}>
            <AgGridReact
                {...defaultGridOptions}
                rowData={rowData}
                ref={gridRef}
                columnDefs={colDefs}
                sideBar={sideBar}
                defaultColDef={defaultColDef}
                columnTypes={columnTypes}
                domLayout='autoHeight'
                onGridSizeChanged={onGridSizeChanged}
                onToolPanelVisibleChanged={onToolPanelVisibleChanged}
                onSelectionChanged={handleSelectionChanged}
                rowSelection="single"
                rowMultiSelectWithClick
            />
        </AgGridContainer>
    );
};