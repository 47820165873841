import { useMemo } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import 'ag-grid-community/styles/ag-grid.css'
import { useTheme } from '@mui/material';
import { stringIntComparator } from '../../../utils/gridFunctions';
import { AgGridContainer } from '../../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, } = props;
  const theme = useTheme();
  const colors = theme.palette.primary;

  const rowData = useMemo(() => {
    const detailData = JSON.parse(data.childJson);
    return detailData ?? [];
  }, [data])

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      content: yValue
    }
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.yValue <= 0 ? colors.red : colors.green,
      stroke: params.yValue <= 0 ? colors.red : colors.green,
    };
  };

  const colDefs = useMemo(() => [
    {
      headerName: 'ID',
      initialHide: true,
      field: 'ID',
      flex: 1,
    },
    {
      headerName: 'ARef',
      field: 'AssignmentRef',
      comparator: stringIntComparator,
      flex: 1,
    },
    {
      headerName: 'Request Type',
      field: 'RequestType',
      flex: 1,
    },
    {
      headerName: 'Start',
      field: 'Start',
      flex: 1,
    },
    {
      headerName: 'Stop',
      field: 'Stop',
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'Status',
      tooltipField: "SELLER_COMMENTS",
      flex: 1,
    },
    {
      headerName: 'Volume',
      field: 'Volume',
      flex: 1,
      initialHide: true,
    },
    {
      headerName: "Profile",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      flex: 5,
      valueGetter: (params) => {
        if (params.data.profileJson) {
          const availabilityData = params.data.profileJson.map((data) => ({
            x: moment(moment.parseZone(data.dateTime).format('MM/DD/YYYY HH:mm')).toDate(),
            y: data.SegmentCapacityGranted,
          }));
          return availabilityData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    },
    {
      headerName: 'Comments',
      field: 'SELLER_COMMENTS',
      initialHide: true,
      flex: 1,
    },
    {
      headerName: 'POR',
      field: 'PointOfReceipt',
      flex: 1,
    },
    {
      headerName: 'POD',
      field: 'PointOfDelivery',
      flex: 1,
    },
  ], [])

  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
  }), [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, }}>
      <AgGridContainer
        style={{ width: "100%", fontSize: '11px' }}
      >
        <AgGridReact
          tooltipShowDelay={0}
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
        />
      </AgGridContainer>
    </Box>
  );
}