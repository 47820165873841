import React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
//import { showDirectoryPicker, showOpenFilePicker } from 'file-system-access'
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useMsal } from "@azure/msal-react";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import useHeader from '../useHeader';

const FileUploaderDialog = ({ fileUploaderOpen, handleFileUploaderDialogClose, handleFileUploaderDialogCancel, oprDay=null, nodeId=null }) => {
  //mts opDay and nodeId are context for bidman spreadsheet imports.
  //
  //const {accounts} = useMsal();
  const guid = useLocalGuid();
  
   //file unloading bool state
   const [isUploading, setIsUploading] = React.useState(false);
   // a local state to store the currently selected file.
   const [selectedFile, setSelectedFile] = React.useState(null);
   const [directoryHandleOrUndefined, setDirectoryHandleOrUndefined] = React.useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const [uploadDisabled, setUploadDisabled] = React.useState(false);
  const headers = useHeader('multipart/form-data')

  const handleSubmit = async(event) => {
    event.preventDefault()
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    //formData.append("selectedFile", selectedFile);
    setUploadDisabled(true);


    try {

      for (var i = 0; i < selectedFile.length; i++) 
      {
        const formData = new FormData();
        formData.append("file", selectedFile[i]);   
   
        const response = await axios({
          method: "post",
          url: `${apiUrlPrefix}/CrystalBall/Database/uploaddocuments?userGuid=${guid}&context=bidman&nodeId=${nodeId}&oprDay=${oprDay}`,
          data: formData,
          headers: headers,
        }).then(response => {
            if(response.data === 'Success') {
              enqueueSnackbar(selectedFile[i].name + ' imported successfully.');
          } else {
            enqueueSnackbar(response.data);
          }
        }).catch(err => { enqueueSnackbar(`Error importing file(s).  Status: ${err.response?.status}. Message: ${err}`) });
          ;


  // const response = await axios.get(
        //   `${queryMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`,
        //   JSON.stringify(rowData),
        //   { headers: header, }
        // ).then(response => {
        //    if(response.data === 'Success') {
        //     enqueueSnackbar('Bids queried from Market.');
        //   } else {
        //     enqueueSnackbar(response.data);
        //   }
        // }).catch(err => { enqueueSnackbar(`Error querying bids from market.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`) });
  


      }
      setIsUploading(false);
      handleFileUploaderDialogClose();
      setSelectedFile(null);
      //mts, moved this up to avoid triggering multiple imports at a time.
      //setUploadDisabled(true);
      //enqueueSnackbar(`File(s) successfully imported.`);
    } catch(error) {
      //console.log(error)
      setIsUploading(false);
      //alert(error);
      enqueueSnackbar(`Status: ${error.response?.status}. Message: ${error}`);
    }
    
 
  }

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files);
    setUploadDisabled(false);
  }

  // async function verifyPermission(fileHandle, readWrite) {
  //   const options = {};
  //   if (readWrite) {
  //     options.mode = 'readwrite';
  //   }
  //   // Check if permission was already granted. If so, return true.
  //   if ((await fileHandle.queryPermission(options)) === 'granted') {
  //     return true;
  //   }
  //   // Request permission. If the user grants permission, return true.
  //   if ((await fileHandle.requestPermission(options)) === 'granted') {
  //     return true;
  //   }
  //   // The user didn't grant permission, so return false.
  //   return false;
  // }

  // async function getDefaultFileLocation() {

  //   try {
  //     //const directoryHandleOrUndefined = await get('directory');
  //     // if (directoryHandleOrUndefined) {
  //     //   alert(`Retrieved directroy handle "${directoryHandleOrUndefined.name}" from IndexedDB.`);
  //     //   return;
  //     // }
  //     const directoryHandle = await window.showDirectoryPicker();
  //     //await set('directory', directoryHandle);
  //     setDirectoryHandleOrUndefined(directoryHandle);
  //     //alert(`Stored directory handle for "${directoryHandle.name}" in IndexedDB.`);
  //   } catch (error) {
  //     enqueueSnackbar(error.message);
  //     //alert("error from ken");
  //   }

  // }

  // async function getNewFileHandle() {
  //   const options = {
  //     startIn: directoryHandleOrUndefined,
  //     types: [
  //       {
  //         description: 'Excel Workbook',
  //         accept: {
  //           'text/plain': ['.xlsx'],
  //         },
  //       },
  //     ],
  //   };
  //   const handle = await window.showOpenFilePicker(options);
  //   return handle;
  // }

  // const handleFilePicker = (event) => {

  //   const [fileHandle] = await showOpenFilePicker({_preferPolyfill: boolean, ...sameOpts});
    
  // }

    return (
        <Dialog 
            open={fileUploaderOpen} 
            onClose={handleFileUploaderDialogClose}
            fullWidth
            maxWidth="sm"
          >
            {isUploading ? (
              <div style={{ 
                position: "fixed",
                top: "45%",
                left: "50%",
              }}>
                <CircularProgress />
              </div>
            ) : (
              <div/>
            )}
            <DialogTitle>Import bid spreadsheet(s)</DialogTitle>
            <DialogContent>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1},
                }}
                noValidate
                autoComplete="off"
              >
                <input type="file" onChange={handleFileSelect} multiple/>
                
              </Box>
              </DialogContent>
            <DialogActions>
              {/* <Button onClick={getDefaultFileLocation}>Set Default Path</Button>
              <Button onClick={getNewFileHandle}>Show File Picker</Button> */}
              <Button onClick={handleFileUploaderDialogClose}>Cancel</Button>
              <Button disabled={uploadDisabled} onClick={handleSubmit}>Upload</Button>
            </DialogActions>
          </Dialog>
    )
}


export default FileUploaderDialog;