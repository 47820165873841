import { LogLevel } from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: 'b2C_1_powerStationSignInSignUp',
        editProfile: 'b2c_1_powerStationProfileEditing',
        forgotPassword: 'b2c_1_powerStationResetPassword',
        signUp: 'b2c_1_powerStationSignUp',
        teaSignIn: 'b2c_1_TestSignIn'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2C_1_powerStationSignInSignUp',
        },
        teaSignIn: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2C_1_TestSignIn',
        },
        forgotPassword: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2c_1_powerStationResetPassword',
        },
        editProfile: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2c_1_powerStationProfileEditing',
        },
        signUp: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2c_1_powerStationSignUp'
        },
        engieSignIn: {
            authority: 'https://powerstationb2c.b2clogin.com/powerstationb2c.onmicrosoft.com/b2c_1_engie_okta_prod'
        },
    },
    authorityDomain: 'powerstationb2c.b2clogin.com'
}


const href = window.location.href;
let uri, apiPrefix, hubPrefix;

//https://crystalballsandbox.azurewebsites.net is the end point for /unlimited
//https://crystalballsandbox-development.azurewebsites.net is the endpoint for /test 
//https://crystalballsandbox-development.azurewebsites.net/swagger/v1/swagger.json is the swagger for development/test and therefore /test
//https://crystalballsandbox.azurewebsites.net/swagger/v1/swagger.json is the swagger for prod and therefore /unlimited
// update

switch (true) {

    case href.includes('www'):
        uri = 'https://www.powerstationplatform.com';
        apiPrefix = 'https://crystalballsandbox.azure-api.net/unlimited';
        hubPrefix = 'https://crystalballsandbox.azurewebsites.net';
        break;

    case href.includes('localhost'):
        uri = 'http://localhost:3000';
        //apiPrefix = 'https://crystalballsandbox.azure-api.net/unlimited';
        apiPrefix = 'https://crystalballsandbox-development.azurewebsites.net';
        hubPrefix = 'https://crystalballsandbox-development.azurewebsites.net';
        //hubPrefix = 'https://crystalballsandbox.azurewebsites.net';
        break;

    case href.includes('powerstation-client-development'):
        uri = 'https://powerstation-client-development.azurewebsites.net';
        apiPrefix = 'https://crystalballsandbox-development.azurewebsites.net';
        hubPrefix = 'https://crystalballsandbox-development.azurewebsites.net';
        break;

    case href.includes('powerstation-client-test'):
        uri = 'https://powerstation-client-test.azurewebsites.net';
        apiPrefix = 'https://crystalballsandbox.azure-api.net/unlimited';
        // apiPrefix = 'https://crystalballsandbox-test.azurewebsites.net';
        hubPrefix = 'https://crystalballsandbox.azurewebsites.net';
        break;

    case href.includes('powerstation-client'):
        uri = 'https://powerstation-client.azurewebsites.net';
        apiPrefix = 'https://crystalballsandbox.azure-api.net/unlimited';
        hubPrefix = 'https://crystalballsandbox.azurewebsites.net';
        break;

    default:
        apiPrefix = 'https://crystalballsandbox.azure-api.net/unlimited';
        uri = 'https://powerstationplatform.com';
        hubPrefix = 'https://crystalballsandbox.azurewebsites.net';
        break;
};

export const apiUrlPrefix = apiPrefix;
export const hubUrlPrefix = hubPrefix;
export const subscriptionKey = '8f3cb3d1fb014e6d8ec4740b7070f009';

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md 
 */
export const msalConfig = {
    auth: {
        clientId: '082c67fe-9558-4dc0-9ea0-a20b2bea01ff', // This is the ONLY mandatory field; everything else is optional.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: uri,
        postLogoutRedirectUri: uri,
        navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
        //storeAuthStateInCookie: true, //ecz 12/10/2021 don't use this: cookie is not cleared correctly, causes interaction_in_progress error
    },
    system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

// New Azure AD configuration for Microsoft Graph API access
export const msalConfigAzureAD = {
    auth: {
        clientId: '1eaf8b2e-be4f-4605-83da-1ec5353035ed', // Replace with your Azure AD client ID
        authority: 'https://login.microsoftonline.com/52b7c018-5a9e-46c1-93ff-601aed75d781', // Replace with your tenant ID
        redirectUri: uri,
        postLogoutRedirectUri: uri,
    },
    cache: {
        cacheLocation: 'localStorage',
    },
    system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                // ... your logger code
            },
        },
    },
};

// Token requests for Azure AD
export const tokenRequestAzureAD = {
    scopes: ['Mail.Read', 'Mail.Send'],
};

// New exports for email and OpenAI configuration
export const EMAIL_ADDRESS = 'samanthamarie@powersysops.com';
export const EMAIL_PASSWORD = 'Qum95904';
export const IMAP_HOST = 'outlook.office365.com';
export const IMAP_PORT = 993;
export const SMTP_HOST = 'smtp.office365.com';
export const SMTP_PORT = 465;
export const OPENAI_API_KEY = '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';

export const userGroups = {
    default: 'default',
    fastpath: 'fastpath',
    bidman: 'bidman',
    engie: 'engie',
    crystalball: 'crystalball',
    tsractivity: 'tsractivity',
    transnow: 'transnow',
    spymonkey: 'spymonkey',
    stalker: 'stalker',
    scheduledtsr: 'scheduledtsr',
    systemdata: 'systemdata',
    admins: 'admins',
    tea: 'tea',
    teaadvanced: 'teaadvanced',
    bulkoriginalrequestsview: 'bulkoriginalrequestsview',
    bulkoriginalrequests: 'bulkoriginalrequests',
    support: 'support',
    realtimegrids: 'realtimegrids',
    dayaheadgrids: 'dayaheadgrids',
    transnowauditgrid: 'transnowauditgrid',
    tsractivitygrid: 'tsractivitygrid',
    usergroupsview: 'usergroupsview',
    usergroups: 'usergroups',
    usermapping: 'usermapping',
    certificatemapping: 'certificatemapping',
    dayaheadspreadsheet: 'dayaheadspreadsheet',
    emailcomposer: 'emailcomposer',
    workflow: 'workflow',
    realtimeoriginals: 'realtimeoriginals',
    transhunter: 'transhunter',
    transhunterauditgrid: 'transhunterauditgrid',
    requirements: 'requirements',
    workflowinitiator: 'workflowinitiator',
    triggeredsubmission: 'triggeredsubmission',
    fastpathpro: 'fastpathpro',
    transhunterpro: 'transhunterpro',
    ppwtrans: 'ppwtrans',
    usermessaging: 'usermessaging',
    dayaheadredirects: 'dayaheadredirects',
    dayaheadscheduledredirects: 'dayaheadscheduledredirects',
    originalrequests: 'originalrequests',
    fastpathdashboard: 'fastpathdashboard',
    dealrizz: 'dealrizz',
    dealrizzplatinum: 'dealrizzplatinum',
    pathmonitor: 'pathmonitor',
    setlocation: 'setlocation',
    helpcomposer: 'helpcomposer',
    tariffaf: 'tariffaf',
    brain: 'brain',
    tenantAdmin: 'tenantadmin',
}

// Scopes you add here will be prompted for consent during login
export const tokenRequest = {
    scopes: ['openid', msalConfig.auth.clientId]
};
