import dayjs from '../../../dayjs-tz';
import { useMemo, useCallback, } from 'react';

export const useProfileTemplates = (formatString = 'MM/DD/YYYY HH:mm') => {

    const peakStart = 6;
    const peakEnd = 22;
    const isSunday = useCallback((date, offset = 0) => date.subtract(offset, 'hours').day() === 0, []);
    //
    //Need to use this function to handle negative numbers, as the % operator in JS is not a true modulo operator
    const mod = useCallback((n, m) => {
        return ((n % m) + m) % m;
    }, []);

    const rollUpBlocks = useCallback((blocks, getBlockSignature = defaultBlockSignature) => {
        return blocks.reduce((rolledUp, next) => {
            const last = rolledUp[rolledUp.length - 1];
            if (last && (last.endDateTime === next.startDateTime) && (getBlockSignature(last) === getBlockSignature(next))) {
                last.endDateTime = next.endDateTime;
            } else {
                rolledUp.push(next);
            }
            return rolledUp;
        }, [])
    }, []);


    const isOffPeak = useCallback((date, offset) => {
        const hour = mod(date.hour() - offset, 24);
        return hour < peakStart || hour >= peakEnd;
    }, [mod]);

    const isOnPeak = useCallback((date, offset = 0) => {
        const hour = mod(date.hour() - offset, 24);
        return hour >= peakStart && hour < peakEnd;
    }, [mod]);

    const createHourlyBlock = useCallback((start, end, config, llSundays = false) => {
        const { mwOn, mwOff, priceOn, priceOff, offset, } = config;
        const offPeak = isOffPeak(start, offset) || (llSundays && isSunday(start, offset));
        const mw = offPeak ? mwOff : mwOn;
        const price = offPeak ? priceOff : priceOn;

        return {
            startDateTime: start.format(formatString),
            endDateTime: end.format(formatString),
            capacityRequested: mw,
            price: price,
        }
    }, [formatString, isOffPeak, isSunday]);

    const defaultBlockSignature = (block) => `${block.capacityRequested}-${block.price}`;

    const generateBlocks = useCallback((config, selector, llSundays = false) => {
        const { startDateTime, stopDateTime, offset } = config;
        const blocks = [];
        let next = dayjs(startDateTime).startOf('hour');
        while (dayjs(stopDateTime).isAfter(next, 'hour')) {
            if (selector(next, offset)) {
                blocks.push(createHourlyBlock(next, next.add(1, 'hour'), config, llSundays));
            }
            next = next.add(1, 'hour');
        }
        return blocks;
    }, [createHourlyBlock]);

    const hourlyBlocks = useCallback((config) => {
        const selector = () => true;
        const llSundays = true;
        return generateBlocks(config, selector, llSundays);
    }, [generateBlocks]);

    const atcBlocks = useCallback((config) => {
        const selector = () => true;
        const llSundays = true;
        const getAtcBlockSignature = (block) => `${block.capacityRequested}-${isOffPeak(dayjs(block.startDateTime), config.offset)}`;
        const hourlyBlocks = generateBlocks(config, selector, llSundays);
        return rollUpBlocks(hourlyBlocks, getAtcBlockSignature);
    }, [generateBlocks, isOffPeak, rollUpBlocks]);

    const llhBlocks = useCallback((config) => {
        const llSundays = true;
        const selector = (date) => {
            return (isSunday(date, config.offset) || isOffPeak(date, config.offset));
        }
        const hourlyBlocks = generateBlocks(config, selector, llSundays);
        return rollUpBlocks(hourlyBlocks);
    }, [generateBlocks, isOffPeak, rollUpBlocks, isSunday]);

    const offPeakBlocks = useCallback((config) => {
        const hourlyBlocks = generateBlocks(config, isOffPeak);
        return rollUpBlocks(hourlyBlocks);
    }, [generateBlocks, isOffPeak, rollUpBlocks]);

    const hlhBlocks = useCallback((config) => {
        const selector = (date) => {
            return (!isSunday(date, config.offset) && isOnPeak(date, config.offset))
        }
        const hourlyBlocks = generateBlocks(config, selector);
        return rollUpBlocks(hourlyBlocks);
    }, [generateBlocks, isOnPeak, rollUpBlocks, isSunday]);

    const onPeakBlocks = useCallback((config) => {
        const hourlyBlocks = generateBlocks(config, isOnPeak);
        return rollUpBlocks(hourlyBlocks);
    }, [generateBlocks, isOnPeak, rollUpBlocks]);

    const profileOptions = useMemo(() => [
        {
            name: 'ATC',
            tooltip: 'Adds blocks covering all hours in the selected date range.',
            templateBlocks: atcBlocks,
        },
        {
            name: 'ATC Rockies',
            tooltip: 'Adds blocks covering all hours in the selected date range. Rockies hours are 7am-11pm MT.',
            templateBlocks: atcBlocks,
        },
        {
            name: 'Hourly',
            tooltip: 'Adds a block for each hour in the selected date range.',
            templateBlocks: hourlyBlocks,
        },
        {
            name: 'Term',
            tooltip: '',
        },
        /*{
            name: '1x8',
            tooltip: 'Adds blocks for off peak hours in the selected date range.',
        },*/
        {
            name: '6x8, 1x24',
            tooltip: 'LL hours including Sunday',
            templateBlocks: llhBlocks,
        },
        {
            name: '1x16',
            tooltip: 'Adds blocks for on peak hours in the selected date range.',
            templateBlocks: onPeakBlocks,
        },
        {
            name: '6x16',
            tooltip: 'HL hours excluding Sunday',
            templateBlocks: hlhBlocks,
        },
        {
            name: '7x16',
            tooltip: 'HL hours including Sunday',
            templateBlocks: onPeakBlocks,
        },
    ], [atcBlocks, hlhBlocks, llhBlocks, onPeakBlocks, hourlyBlocks]);

    const generateTransmissionBlocksUsingTemplate = useCallback((config, referenceTz = 'America/Los_Angeles', targetTz = referenceTz) => {
        const offset = (dayjs().tz(targetTz).utcOffset() - dayjs().tz(referenceTz).utcOffset()) / 60;
        const template = profileOptions.find(option => option.name === config.Term)?.templateBlocks;
        const offsetConfig = {
            ...config,
            //startDateTime: dayjs(config.startDateTime).add(offset, 'hours').startOf('hour'),
            //stopDateTime: dayjs(config.stopDateTime).add(offset, 'hours').startOf('hour'),
            offset,
        };
        if (template) {
            return template(offsetConfig);
        } else {
            return [];
        }
    }, [profileOptions]);

    const updateProfileDataWithTimezone = useCallback((config, profile, oldTz, newTz) => {
        const offset = (dayjs().tz(newTz).utcOffset() - dayjs().tz(oldTz).utcOffset()) / 60;
        const { startDateTime, stopDateTime, } = config;
        const startTimeMap = new Map();
        const endTimeMap = new Map();

        profile.forEach(block => {
            const start = dayjs(block.startDateTime).add(offset, 'hour');
            const end = dayjs(block.endDateTime).add(offset, 'hour');
            startTimeMap.set(block.startDateTime, start);
            endTimeMap.set(block.endDateTime, end);
        });

        const blocks = [];
        let next = dayjs(startDateTime).startOf('hour');
        while (dayjs(stopDateTime).isAfter(next, 'hour')) {
            const block = profile.find(block => {
                return startTimeMap.get(block.startDateTime).isSame(next) && endTimeMap.get(block.endDateTime).isSame(next.add(1, 'hour'));
            });
            blocks.push({
                startDateTime: next.format(formatString),
                endDateTime: next.add(1, 'hour').format(formatString),
                capacityRequested: block?.capacityRequested ?? 0,
                price: block?.price ?? 0,
            });
            next = next.add(1, 'hour');
        }
        return blocks;
    }, [formatString]);

    return {
        generateTransmissionBlocksUsingTemplate,
        updateProfileDataWithTimezone,
        profileOptions,
    }
}
