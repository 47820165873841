import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { ProfileEditor } from './ProfileEditor';
import dayjs from 'dayjs';

export const ProfilePicker = forwardRef((props, ref) => {
    const [profile, setProfile] = useState('');
    const [openEditor, setOpenEditor] = useState(false);
    const refInput = useRef(null);
 
    useEffect(() => {
      if (profile) {
        setOpenEditor(true);
      }
    }, [profile]);
 
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return 2; //TODO: Return Profile JSON
            },
 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            //isCancelBeforeStart() {
            //    return false;
            //},
 
            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            //isCancelAfterEnd() {
                // our editor will reject any value greater than 1000
            //    return value > 1000;
            //}
        };
    });

    const handleOpenEditor = (_, value) => {
        setProfile(profileOptions.find(profile => profile.name === value));
    };

    const handleEditorClose = () => {
        setOpenEditor(false);
    };

    const rowData = props.node.data;
    const defaultDate = dayjs().format('MM/DD/YYYY');
    const startDate = rowData?.Start ?? defaultDate;
    const stopDate= rowData?.Stop ?? defaultDate;
 
    return (<>
        <ProfileEditor 
            open={openEditor}
            handleEditorClose={handleEditorClose}
            profile={profile}
            initialStartDate={startDate}
            initialStopDate={stopDate}/>
        <Autocomplete
          value={profile}
          onChange={handleOpenEditor}
          autoHighlight
          fullWidth 
          size="small"
          options={profileOptions.map(profile => profile.name)}
          renderInput={(params) => <TextField {...params} variant='standard'/>}
        />
    </>);
 });

 const profileOptions = [
    {   
        name: 'HE 7-22',
        hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
    },
    {
        name: 'HE 7-22 (5 weekdays)',
        hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
    },
    {
        name: 'HE 7-22 (5 weekdays+Sat)',
        hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
    },
    {
        name: 'HE 7-22 (fullweek)',
        hours: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19 ,20, 21, 22],
    },
    {
        name: 'HE 1-6, 23-24',
        hours: [1, 2, 3, 4, 5, 6, 23, 24],
    },
    {
        name: 'HE 1-6, 23-24 (Sat+Sun)',
        hours: [1, 2, 3, 4, 5, 6, 23, 24],
    },
    {
        name: 'HE 1-6, 23-24 (fullweek)',
        hours: [1, 2, 3, 4, 5, 6, 23, 24],
    },
    {
        name: 'HE 1-6, 23-24 (5 weekdays+Sat), 1-24 (Sun)',
        hours: [1, 2, 3, 4, 5, 6, 23, 24],
    },
    {
        name: 'HE 1-6, 23-24 (5 weekdays+Sat), 1-24 (Sun) (Full Month)',
        hours: [1, 2, 3, 4, 5, 6, 23, 24],
    },
    {
        name: 'Custom',
        hours: [],
    }
];

