import * as React from 'react';
// import GoogleReactMap from "../components/GoogleReactMap"
import ComponentGuard from "../components/Guards/ComponentGuard"
import { userGroups } from "../authConfig";
import GoogleMapsApi from '../components/GoogleMapsApi';

function CrystalBall() {

  return (
    <ComponentGuard groups={[userGroups.crystalball]} >
      <GoogleMapsApi />       
    </ComponentGuard>
       
);
}

export default CrystalBall;
