import { Box, Dialog, DialogActions, DialogContent, Button } from "@mui/material"
import { apiUrlPrefix } from "../../../authConfig"
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid"
import useHeader from "../../useHeader"
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useSnackbar } from "notistack"
import axios from "axios"
import Scheduler from "../Scheduler/Scheduler"

function TSRCalendar (props) {
  const { events, handleClick } = props;
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const defaultView = localStorage.getItem('tsrCalendarDefaultView');
  const [triggerSubmissions, setTriggerSubmissions] = useState([]);
  const [alerts, setAlerts] = useState({
    open: false,
    color: "info",          // info | success | warning | error
    severity: "info",       // info | success | warning | error
    message: "🚀 TSR will be submitted at 10PM tonight ERCOTE-CSWS 31MW 🔥 🔥 🔥" ,
    showActionButton: true,
    showNotification: true,
    delay: 1500,
  });

  useEffect(() => {
    fetchTriggerSubmissions();
  }, [])

  const options = useMemo(() => { return {
    transitionMode: "zoom", // or fade
    startWeekOn: "sun",     // or mon
    defaultMode: defaultView ?? "month",    // or week | day | timeline
    minWidth: 800,
    minHeight: 1200,
    minHeight: 800,
    maxHeight: 1200
  }}, []);

  const toolbarProps = useMemo(() => { return {
    showSearchBar: false,
    showSwitchModeButtons: true,
    showDatePicker: true
  }}, [])
  
  /*function onAlertCloseButtonClicked() {
    setAlerts({
      ...alerts,
      open: false,
    })
  }*/

  function fetchTriggerSubmissions() {
    const options = {
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TriggerSubmissionsFetch&parm=${guid}`,
      headers: headers,
    }

    axios(options).then(response => {
      setTriggerSubmissions(response.data);
    }).catch(error => { 
      enqueueSnackbar(`${error.message} ${error.response?.data ?? ''}`) 
    });
  }
  
  const handleCellClick = (event, row, day) => {
    //handleClick({ TargetExecutionTime: day.date, });
  }
  
  const handleEventClick = useCallback((event, item) => {
    handleClick(item);
  }, []);

  return (
    <div>
      <Box sx={{ height: '80vh', width: '100%', }}>
        <Scheduler
          events={events}
          alertProps={alerts}
          options={options}
          toolbarProps={toolbarProps}
          //onCellClick={handleCellClick}
          onTaskClick={handleEventClick}
          //onAlertCloseButtonClicked={onAlertCloseButtonClicked}
        />
      </Box>
    </div>
  )
}

export default React.memo(TSRCalendar);