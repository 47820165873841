import { useRef, useMemo, useCallback, useState, } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import useGridLayout from "../../useGridLayout"
import { useApi } from "../../useApi"
import { useFormContext } from "react-hook-form"

export const DealHistoryGrid = ({ dealId, setSelectedRow, }) => {
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const layoutStorageKey = 'deal-history-layout';
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const [historyData, setHistoryData] = useState([]);
    const { get, headers, apiUrlPrefix, } = useApi();
    const { watch, } = useFormContext();
    const timezone = watch('timezone');

    async function fetchHistory() {
        gridRef.current?.api.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealHistory_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dealId}`

        return get(url).then(response => {
            gridRef.current?.api.deselectAll();
            const newData = !!response?.data ? response.data : [];
            setHistoryData(newData);
        });
    }

    const baseColDefs = useMemo(() => ([
        {
            field: "confirmed",
            headerName: "Confirmed",
            initialHide: true,
            cellRenderer: 'checkboxCellRenderer',
        },
        {
            field: 'DATE_TIME_STAMP',
            headerName: "Time Stamp",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'changeProcess',
            header: "Process"
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
            initialHide: true,
        },
        {
            field: 'Contract',
            initialHide: true,
        },
        {
            field: 'Counterparty',
            initialHide: true,
        },
        {
            headerName: "Deal Profile",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.sparklineJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.capacityRequested ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            headerName: "Price Curve",
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.sparklineJson;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                            { x: dayjs(next.endDateTime).startOf('hour').toDate(), y: next.price ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    }
                },
            },
        },
        {
            field: 'Creation_Time',
            headerName: 'Creation Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            initialHide: true,
        },
        {
            field: 'Creator',
            headerName: 'Creator',
            initialHide: true,
        },
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
            initialHide: true,
        },
        {
            field: 'Deal_Number',
            headerName: 'Deal Name',
            initialHide: true,
        },
        {
            field: 'Start_Date',
            headerName: 'Start Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'End_Date',
            headerName: 'End Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'FlowStartDate',
            headerName: 'Flow Start',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'FlowEndDate',
            headerName: 'Flow End',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Financial_Type',
            headerName: 'Firm/Non-Firm',
            initialHide: true,
        },
        {
            field: 'Formula',
            initialHide: true,
        },
        {
            field: 'por',
            headerName: 'POR',
            initialHide: true,
        },
        {
            field: 'pod',
            headerName: 'POD',
            initialHide: true,
        },
        {
            field: 'Zone',
            headerName: 'Zone',
            initialHide: true,
        },
        {
            field: 'index_name',
            headerName: 'Index',
        },
        // {
        //     field: 'Index_2',
        //     headerName: 'Index 2',
        //     initialHide: true,
        // },
        {
            field: 'Internal',
            headerName: 'Comments',
            initialHide: true,
        },
        {
            field: 'Market',
            initialHide: true,
        },
        {
            field: 'Modification_Time',
            headerName: 'Modification Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Modification_User',
            headerName: 'Modification User',
        },
        {
            field: 'Product',
            initialHide: true,
        },
        {
            field: 'Status',
            initialHide: true,
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
            initialHide: true,
        },
        {
            field: 'originalTerm',
            headerName: 'Term',
            initialHide: true,
        },
        {
            field: 'indexType',
            headerName: 'Index Type',
        },
        {
            field: 'dynamic',
            headerName: 'Dynamic',
            initialHide: true,
        },
        {
            field: 'forecast',
            headerName: 'Forecast', //test
            initialHide: true,
        },
        {
            field: 'Time_Zone',
            headerName: 'Time Zone',
            initialHide: true,
        },
        /*{
            field: "Total_MWh",
            headerName: "Total MWh",
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined && !isNaN(value)) {
                    // Format value with commas and up to 3 decimal places
                    return parseFloat(value).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                    });
                }
                return value;
            }
        },*/
        /*{
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            initialWidth: "150px",
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },*/
        {
            field: 'Trade_Date',
            headerName: 'Trade Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            initialHide: true,
        },
        {
            field: 'Trader',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
            initialHide: true,
        },
        {
            field: 'Type_F_P',
            headerName: 'Transaction',
            initialHide: true,
        },
        {
            field: 'Voiding_User',
            headerName: 'Voiding User',
            initialHide: true,
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
    }), []);

    const { applySavedFilters, loadLayout, colDefs, layoutPanel, } = useGridLayout(
        layoutStorageKey,
        gridRef,
        baseColDefs,
        defaultColDef,
    );

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        fetchHistory();
        loadLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applySavedFilters();
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
            "sale-row": params => params.data?.Transaction_Type === 'Sale',
            "purchase-row": params => params.data?.Transaction_Type === 'Purchase',
        },
    }), []);

    const handleSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current?.api.getSelectedRows();
        setSelectedRow(selectedRows?.[0]);
    }, []);


    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                rowData={historyData}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '99%', }}
                ref={gridRef}
                gridOptions={gridOptions}
                columnDefs={styledColDefs}
                rowSelection="single"
                rowMultiSelectWithClick
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                suppressAggFuncInHeader={true}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                onSelectionChanged={handleSelectionChanged}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    )
};
