import { RibbonGroupContainer } from "../../Ribbon"
import { RefreshButton, ClearSelectionsButton } from "../../RibbonButtons";

export const GridActionsGroup = ({ expanded, formId, handleFetchData, gridRef, }) => {
    return (
        <RibbonGroupContainer>
            {/*<ClearSelectionsButton
                expanded={expanded}
                gridRef={gridRef}
            />*/}

            <RefreshButton
                formId={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />
        </RibbonGroupContainer>
    );
}