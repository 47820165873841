import { useImperativeHandle, useRef, forwardRef, useState, useCallback, useMemo, } from "react"
import { Box, } from "@mui/material"
import { UnscheduledMWsGrid } from "./UnscheduledMWsGrid"
import { useApi } from "../../useApi"
import dayjs from "dayjs"
import * as yup from 'yup';
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { ViewContextProvider } from "../ViewContextProvider"
import { useDashboardFetch } from "../useDashboardFetch"
import { UnscheduledMWsRibbon } from "./Ribbon/UnscheduledMWsRibbon"
import { isLongDay } from "../Utils"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    //stopDate: yup.date().required('End Date is required'),
});

export const UnscheduledMWsView = forwardRef(({ view, }, ref) => {
    const gridRef = useRef();
    const [selectedRow, setSelectedRow] = useState();
    const { get, headers, apiUrlPrefix, enqueueSnackbar, logAction } = useApi();
    const { captureLayout } = useLayoutFunctions();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        ...view,
        startDate: dayjs(),
        //stopDate: dayjs().add(1, 'day'),
    }), [view]);

    const getRowId = useCallback(({ data }) => data.dealID, []);

    const dataFormatter = useCallback((data) => ({
        ...data,
        userGuid: headers.userGuid,
        startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
    }), [headers]);

    const { loadAndApplyData, silentUpdate, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchUnscheduledMW_v5',
        fetchParamKeys: ['userGuid', 'startDate', 'timezone'],//'stopDate', 'timezone'],
        gridRef,
        getRowId,
        dataFormatter,
    });

    function handleFetchData(data) {
        toggleExtraHourColBasedOnDate(data.startDate);
        logAction('User fetched Unscheduled MW Data', 'Unscheduled MWs', data);
        loadAndApplyData(data);
    }

    function toggleExtraHourColBasedOnDate(date) {
        const isLong = isLongDay(date);
        gridRef.current?.columnApi?.setColumnVisible('2*', isLong);
    }

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const toolbarFormId = `unscheduled-mws-toolbar-form-${view.id}`;

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column'>
                <UnscheduledMWsRibbon
                    toolbarFormId={toolbarFormId}
                    selectedRow={selectedRow}
                    gridRef={gridRef}
                    handleFetchData={handleFetchData}
                />
                <UnscheduledMWsGrid
                    ref={gridRef}
                    setSelectedRow={setSelectedRow}
                    getRowId={getRowId}
                />
            </Box>
        </ViewContextProvider>
    )
});
