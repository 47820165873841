import { useState, useRef, useMemo } from "react";
// import { Box } from "@mui/material";
// import { Star, StarOutline , CalendarToday } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
//import { useFastPathStyles } from "../styles";
//import CircularProgress from '@mui/material/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from "notistack";
import '../styles/fastPathStyles.css'
//components
import { Lookup, } from "../components";
import { apiUrlPrefix, subscriptionKey, userGroups } from "../authConfig";
import { useActionAudit } from '../components/useActionAudit.js';
import useHeader from "../components/useHeader";
import DivGuard from "../components/Guards/DivGuard";
// import { AgGridReact } from "ag-grid-react";
//import { useGridViewStyles } from "../styles";
import FastPathGridView from "../components/FastPath/fastPathGridView";
// const { logAction } = useActionAudit();
//logAction(`Changed header label date to: ${event}`, storageLocation);


function FastPath() {
  //const classes = useFastPathStyles();

  //Data loading bool state
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();

  //default date to today
  var today = new Date();
  var formattedDate = moment(today).format("YYYY-MM-DD"); //is technically a string but both docs on request object show date formatted like this

  // Fast Path Lookup Input values and setters
  // Default values to that of known working query for demo purposes
  const [date, setDate] = useState(formattedDate);
  const [por, setPor] = useState({ label: "BPAT.NWMT", value: "BPAT.NWMT" });
  const [pod, setPod] = useState({
    label: "PALOVERDE500",
    value: "PALOVERDE500",
  });
  const [startHE, setStartHE] = useState(13);
  const [endHE, setEndHE] = useState(13);
  const [minCapacity, setMinCapacity] = useState(1);
  const [serviceIncrement, setServiceIncrement] = useState(["Hourly"]);
  const [tsClass, setTsClass] = useState(["Non-Firm"]);
  const [tsType, setTsType] = useState(["Point_To_Point"]);
  const headers = useHeader();
  //  const [singleSeg, setSingleSeg] = useState(0);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  //const classes = useGridViewStyles();

  //lookup results
  const [lookupResults, setLookupResults] = useState([]);
  const [lookupError, setLookupError] = useState();

  //value of inputs in Lookup
  const inputVals = {
    date,
    por,
    pod,
    startHE,
    endHE,
    minCapacity,
    serviceIncrement,
    tsClass,
    tsType,
  };

  //set input value functions
  const setInputVals = {
    setDate,
    setPor,
    setPod,
    setStartHE,
    setEndHE,
    setMinCapacity,
    setServiceIncrement,
    setTsClass,
    setTsType,
  };

  const handleFastPathUpdate = async () => {
    setIsLoading(true);

    logAction(`Fetching fast path routes ${por?.value}-${pod?.value}@${minCapacity}MW`, 'FastPath');

    //hit API here with state values
    //test

    try {
      const { data } = await axios.post(
        `${apiUrlPrefix}/CrystalBall/Routes`,
        {
          PORlist: [por.value],
          PODlist: [pod.value],
          startDate: date,
          startHE: startHE,
          endDate: date,
          endHE: endHE,
          serviceIncrement: serviceIncrement,
          tsType: tsType,
          tsClass: tsClass,
          minMW: minCapacity,
        },
        {
          headers: headers,
        }
      );

      setLookupResults(data);
      setIsLoading(false);
    } catch (e) {
      logAction('Error fetching routes', 'Fast Path', e)
      setLookupError(e);
      setLookupResults([]);
      setIsLoading(false);
      e.message && enqueueSnackbar(e.message);
    }
  };

  const colDefs = useMemo(() => [
    {
      headerName: "Favorite",
      field: "favoriteCol",
    },
    {
      headerName: "Path",
      field: "pathName",
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      valueFormatter: params => {
        return `${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(params.value)}`;
      }
    },
  ], []);

  return (
    <DivGuard groups={[userGroups.default]}>
      <div style={{ display: "flex" }}>
        <Lookup
          handleFastPathUpdate={handleFastPathUpdate}
          inputVals={inputVals}
          setInputVals={setInputVals}
        />

        {isLoading ? (
          // <p>Loading...</p>
          // KJ added this circular progress bar instead of just saying loading... like a goof
          <div style={{
            position: "fixed",
            top: "45%",
            left: "50%",
            //topMargin: "-50px",
            //leftMargin: "-100px",
          }}>
            <CircularProgress />
          </div>
        ) : lookupError ? (
          <p>Unfortunately, the server is having trouble, please try again.  If the problem persists please contact support@powersysops.com.</p>
        ) : (
          <FastPathGridView
            lookupResults={lookupResults}
            lookupInputVals={inputVals}
          />
        )}
      </div>
    </DivGuard>
  );
}

export default FastPath;
