import { useCallback, useEffect, useState, } from 'react';
import {apiUrlPrefix, userGroups, } from '../authConfig.js';
import axios from "axios";
import {useSnackbar} from 'notistack';
import useHeader from './useHeader.js';
import UserMessageBox from './UserMessageBox.js';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid.js';
import { useHubMessages } from './HubContext/useHubMessages.js';
import moment from 'moment';
import { GlobalHotKeys } from 'react-hotkeys';
import GroupGuard from './Guards/GroupGuard.js';

export default () => {
  const headers = useHeader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const hubMessage = useHubMessages();
  const [enableMessaging, setEnableMessaging] = useState(true);

  useEffect(() => {
    if(guid && enableMessaging) {
      getUserMessages()
    }
  }, [guid, enableMessaging]) //check for messages once on startup without waiting for a message from the hub.

  useEffect(() => {
    if (hubMessage.message === 'PowerStationMetaData.userMessagesQueueUpdate' && guid) {
      window.removeEventListener('mousemove', getUserMessages)
      window.addEventListener('mousemove', getUserMessages)
      console.log(`Message ${hubMessage.message} received.`)
    }
  }, [hubMessage]);

  function handleClose(id) {
    closeSnackbar(id);
  }

  const getUserMessages = useCallback(async () => {
    if(!enableMessaging) return;
    window.removeEventListener('mousemove', getUserMessages)
    const timestamp = moment().format('HH:mm:ss.SSS')
    console.log(`Fetching user messages at ${timestamp}`);

    const sUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserMessagesFetch&parm=${encodeURIComponent(guid)}` 

    const options = {
      method: 'GET',
      headers: headers,
      url: sUrl,
    }

    axios(options).then( response => { 
      if (response.data.length) {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const dayTime = new Date(response.data[0].dateCreated).toLocaleDateString("en-US", options);
        const titleData = `Message from ${response.data[0].fromUser} to ${response.data[0].toUser} on ${dayTime} Pacific Time`;
        const message = response.data[0].textMessage;
        const persist = !!response.data[0].persist;
        enqueueSnackbar(message, snackbarOptions(message, titleData, persist));
      }
    }).catch(error => { 
      enqueueSnackbar(`User Messages error. ${error.message} ERR: ${error.response?.data ?? ''}`)
    });
  }, [guid, enableMessaging])

  const snackbarOptions= (message, title, persist) => {
    const options = {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      content: (key) => {
        return (
          <UserMessageBox
            id={key}
            title={title}
            handleClose={handleClose}
            message={message}
          />
        );
      },
    }

    persist
    ? options.persist = true
    : options.autoHideDuration = 10000
    
    return options;
  }

  const keymap = {
    TOGGLE_MESSAGES: {
        sequences: ['command+m', 'ctrl+m'],
    }
  }
  
  const handlers = {
      TOGGLE_MESSAGES: toggle,
  }

  function toggle() {
    setEnableMessaging(e => !e);
    console.log(`User messages ${enableMessaging ? 'disabled' : 'enabled'}.`);
  }

  return(
    <GroupGuard groups={[userGroups.admins]}>
      <GlobalHotKeys keyMap={keymap} handlers={handlers} allowChanges/>
    </GroupGuard>
  )
}