import { useEffect, useState, useRef } from 'react';
import { Profiles } from '../TransNow/Profiles';

export const useProfilePanel = (gridRef) => {
  const [currentRow, setCurrentRow] = useState();
 
  function updateCurrentRow(cellFocusEvent) {
    const i = cellFocusEvent.rowIndex;
    const row = cellFocusEvent.api.rowModel.rowsToDisplay[i];
    setCurrentRow({...row});
  }

  function onProfileSave(profile, volume, newVolumes, price, newPrices) {
    const id = currentRow.id;
    const node = gridRef.current.api.getRowNode(id);
    if (node) {
      node.setDataValue('Profile', profile);
      node.setDataValue('Capacity Requested', volume);
      node.setDataValue('Bid Price', price);
  
      for(let i = 0; i < 25; i++) {
        node.setDataValue(`HEC${i + 1}`, newVolumes[i]);
        node.setDataValue(`HEP${i + 1}`, newPrices[i]);
      };
    }
  };

  const profilePanel = {
    id: 'profile',
    labelDefault: 'Profile',
    labelKey: 'profile',
    toolPanel: 'profileToolPanel',
    toolPanelParams: {
      onProfileSave: onProfileSave,
      row: currentRow,
    },
    minWidth: 300,
    maxWidth: 400,
    width: 375
  }

  //profile column value getter
  function setRowFromProfile(params) {
    const profileName = params.newValue
    if (profileName) {
      params.data['Profile'] = profileName;
      if(profileName !== 'Custom') {
        const profile = Profiles[profileName];
        if(profile) {
          //default row capacity values using profile
          const vol = params.data['Capacity Requested'] ?? 0;
          const price = params.data['Bid Price'] ?? 0;
          const initialVolumes = profile.map(i => i * vol);
          const initialPrices = profile.map(i => i * price);
          for(let i = 0; i < 25; i++) {
            params.data[`HEC${i + 1}`] = initialVolumes[i];
            params.data[`HEP${i + 1}`] = initialPrices[i];
          }; 
        }
      }
      return true;
    } else {
      return false;
    }
  }

  return [
      updateCurrentRow,
      profilePanel,
      setRowFromProfile
  ];
};