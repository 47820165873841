import { Box } from "@mui/material";
import { RefreshButton } from '../../RibbonButtons';
import { ClearSelectionsButton } from '../../RibbonButtons/ClearSelectionsButton';
import { RibbonGroupContainer } from "../../Ribbon";

export const GridActionsGroup = ({ formId, expanded, handleFetchData, gridRef }) => {
    return (
        <RibbonGroupContainer sx={{ alignItems: 'start' }} spacing={1}>
            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />

            <ClearSelectionsButton
                expanded={expanded}
                gridRef={gridRef}
            />
        </RibbonGroupContainer>
    )
}