import { userGroups } from '../authConfig';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import EmailIcon from '@mui/icons-material/Email';
import GroupGuard from "./Guards/GroupGuard";
import MessageIcon from '@mui/icons-material/Message';
import SupportTicketDialog from './SupportTicketDialog';
import UserMessageDialog from './UserMessageDialog';
import AddEmailDialog from './AddEmailDialog';
import { useState } from 'react';
import { useIsAuthenticated, } from '@azure/msal-react';
import { useUserGroups } from '../data/useUserGroups';
import UserChatDialog from '../components/Messaging/UserChatDialog';

export default () => {
  const [supportTicketOpen, setSupportTicketOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [addEmailOpen, setAddEmailOpen] = useState(false);
  const [liveChatOpen, setLiveChatOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { userIsInGroup } = useUserGroups();
  const isAdmin = userIsInGroup(userGroups.admins);

  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <SupportTicketDialog open={supportTicketOpen} handleClose={() => setSupportTicketOpen(false)} />
      <UserMessageDialog open={messageOpen} handleClose={() => setMessageOpen(false)} />
      <AddEmailDialog open={addEmailOpen} handleClose={() => setAddEmailOpen(false)} />
      <UserChatDialog open={liveChatOpen} handleClose={() => setLiveChatOpen(false)} />
      <MenuList>
        {isAuthenticated && <MenuItem onClick={() => setSupportTicketOpen(o => !o)}>
          <ListItemIcon>
            <KeyboardIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Report an Issue</ListItemText>
        </MenuItem>}
        <GroupGuard groups={[userGroups.usermessaging]}>
          <MenuItem onClick={() => setMessageOpen(o => !o)}>
            <ListItemIcon>
              <MessageIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Send User Message</ListItemText>
          </MenuItem>
        </GroupGuard>
        <MenuItem disabled={!isAdmin} onClick={() => setLiveChatOpen(true)}>
          <ListItemIcon>
            <LiveHelpIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Live Chat</ListItemText>
        </MenuItem>
        <GroupGuard groups={[userGroups.admins]}>
          <MenuItem disabled>
            <ListItemIcon>
              <KeyboardIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Compose Email</ListItemText>
          </MenuItem>
        </GroupGuard>
        <GroupGuard groups={[userGroups.admins]}>
          <MenuItem onClick={() => setAddEmailOpen(o => !o)}>
            <ListItemIcon>
              <AddCircleIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Manage Emails</ListItemText>
          </MenuItem>
        </GroupGuard>
        <MenuItem component="a" href="mailto:support@powersysops.com">
          <ListItemIcon>
            <EmailIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Email Support</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  )
}