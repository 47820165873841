import { useEffect, } from "react";
import { useHubMessages } from "./useHubMessages";

/*
    Props:
    action: function called when an allowed message has been received, e.g. a data fetch.
    allowedMessages: an array of the hub messages to look for
    predicate: a function that returns a boolean value that if false, will stop the action from being called, e.g. could be false if there is a state variable that action depends on that has not been set
*/

const useHubObject = ({ action, allowedMessages, predicate = () => true, }) => {
    const { message: hubObj, diffData } = useHubMessages();

    useEffect(() => {
        if (allowedMessages.includes(hubObj?.hubMessage) && predicate(hubObj)) {
            action(hubObj);
        }
    }, [hubObj]);

    return {
        hubObj,
        diffData,
    };
}

export default useHubObject;
