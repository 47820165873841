import { GraphCanvas, lightTheme, darkTheme, } from 'reagraph';
import { Collapse, Alert, Box, Typography, Autocomplete, TextField, Button, Stack, Tooltip, Grid, IconButton, } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState, memo, } from 'react';
import { CloseOutlined, FileDownloadOutlined } from '@mui/icons-material';
import '../../../styles/reagraphStyles.css';
import { useSnackbar } from 'notistack';
import GraphControls from '../../DealRizz/PathBuilder/GraphControls';
import { apiUrlPrefix, userGroups } from '../../../authConfig';
import useHeader from '../../useHeader';
import axios from 'axios';
import Analysis from './Analysis';
import { useTheme } from '@emotion/react';
import NewBrainDialog from './AddNewBrainDialog';
import { PointStatistics } from './PointStatistics';
import TrainingConfirmationDialog from './TrainingConfirmationDialog';
import { useUserGroups } from '../../../data/useUserGroups';
import useHubObject from '../../HubContext/useHubObject';
import dayjs from 'dayjs';
import { debounce, uniqBy } from "lodash";

const BrainTopology = () => {
    const appTheme = useTheme();
    const defaultGraphTheme = appTheme.palette.mode === 'dark' ? darkTheme : lightTheme;
    const { enqueueSnackbar } = useSnackbar();
    const [layoutType, setLayoutType] = useState('treeLr3d');
    const [brains, setBrains] = useState([]);
    const [brain, setBrain] = useState({});
    const [modelNames, setModelNames] = useState(['']);
    const [modelName, setModelName] = useState('');
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const nodeValueRef = useRef();
    const [showAnalysis, setShowAnalysis] = useState(false);
    const [openAddNewDialog, setOpenAddNewDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const defaultInfoText = 'You can browse pre-made brains with the drop down. To create a new brain, click the Create button above.';
    const [infoText, setInfoText] = useState(defaultInfoText);
    const { userIsInGroup } = useUserGroups();
    const isAdmin = userIsInGroup(userGroups.admins);
    const [dailyData, setDailyData] = useState([]);
    const [hourlyData, setHourlyData] = useState([]);
    const [openBetaAlert, setOpenBetaAlert] = useState(true);

    const graphRef = useRef();
    const headers = useHeader();

    const brainName = brain.name ?? '';
    const brainNames = useMemo(() => [... new Set(brains.map((item) => item.name))], [brains]);
    const isUsersBrain = brains.find((item) => item.brainID === brain.brainID)?.userGuid === headers.userGuid || userIsInGroup(userGroups.admins);
    const distinctBrains = useMemo(() => uniqBy(brains, 'brainID'), [brains]);

    useEffect(() => {
        fetchBrainNameList().then((response) => {
            if (response.data) {
                const defaultBrain = response.data[0];
                const models = [...new Set(response.data.filter(item => item.name === defaultBrain.name).map((item) => item.modelName))];
                setBrains(response.data);
                setModelNames(models);
                setBrain(defaultBrain ?? {});
                setModelName(defaultBrain.modelName ?? '');
            }
        });
    }, []);

    useEffect(() => {
        fetchAndSetLatestStatus();
    }, [brainName]);

    useEffect(() => {
        if (brainName && modelName) {
            fetchData(brainName, modelName);
            fetchBrainError(brainName, modelName).then(() => {
                fetchBrainError(brainName, modelName, 'hourly');
            });
        }
    }, [brainName, modelName]);

    async function fetchBrainError(brainName, modelName, dateInterval = 'daily') {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainError&parm=${brainName}&parm=${dateInterval}&parm=${modelName}`;

        return axios.get(url, { headers: headers }).then((response) => {
            if (response.data) {
                const newData = response.data.map(item => ({
                    ...item,
                    dateTimeValue: new Date(item.dateTimeValue),//, 'YYYY-MM-DDTHH:mm:ss'),
                }));
                if (dateInterval === 'daily') {
                    setDailyData(newData);
                } else {
                    setHourlyData(newData);
                }
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching brain error: ${error}`, { variant: 'error', });
        });
    }

    useHubObject({
        action: async (obj) => {
            if (obj?.status && obj?.brainName) {
                enqueueSnackbar(`Brain ${obj.brainName} status update: ${obj.status}`, { variant: 'info', });
                if (obj.brainName === brainName) {
                    setInfoText(`${obj.status}`);
                    if (obj.status?.toLowerCase().includes('updated output values')) {
                        fetchBrainError(brainName, modelName).then(() => {
                            fetchBrainError(brainName, modelName, 'hourly');
                        });
                    }
                }

                //if the brain is not in the list, fetch until it is, then switch to it and the first model for it
                if (!brains.find((item) => item.name === obj.brainName)) {
                    fetchBrainNameList().then((response) => {
                        if (response.data) {
                            const newBrain = response.data.find((item) => item.name === obj.brainName);
                            if (newBrain) {
                                const brainModels = [...new Set(response.data.filter(item => item.name === newBrain.name).map((item) => item.modelName))];
                                setBrains(response.data);
                                if (obj.userGuid === headers.userGuid) { //only switch to the new brain if it is the user's brain
                                    setModelNames(brainModels);
                                    setBrain(obj);
                                    setModelName(brainModels[0] ?? '');
                                }
                            }
                        }
                    });
                }
            }
        },
        allowedMessages: ['BrainUpdate'],
        callbackDependencies: [headers, brainName],
        predicate: (obj) => {
            return isAdmin || obj.userGuid === headers.userGuid;
        },
        debounceOptions: { leading: true, trailing: true, maxWait: 1000, },
    });

    async function fetchAndSetLatestStatus() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainHistory&parm=${headers.userGuid}`;
        const response = await axios.get(url, { headers: headers }).catch(error => {
            enqueueSnackbar(`Error loading brain status data. ${error.response?.data ?? ''} Status: ${error.response?.status}. Message: ${error}`, { variant: 'error' })
        });
        if (response?.data) {
            const dataForBrain = response.data.filter((item) => item.brainName === brainName);
            //use the dateTimeStamp to get the latest status
            if (!!dataForBrain.length) {
                const latestStatus = dataForBrain.reduce((acc, item) => {
                    if (dayjs(item.dateTimeStamp).isAfter(dayjs(acc.dateTimeStamp))) {
                        acc = item;
                    }
                    return acc;
                }, { dateTimeStamp: 0, status: '' });
                setInfoText(latestStatus.status);
            }
        }
    }

    async function fetchData(brainName = brainName, modelName = modelName) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainTopology&parm=${headers.userGuid}&parm=${brainName}&parm=${modelName}`;

        axios.get(url, { headers: headers }).then((response) => {
            if (response.data) {
                const nodeMap = new Map();
                const edgeMap = new Map();
                nodeValueRef.current = response.data.reduce((acc, item) => {
                    acc.min = Math.min(acc.min, item.innerNeuronValue, item.outerNeuronValue);
                    acc.max = Math.max(acc.max, item.innerNeuronValue, item.outerNeuronValue);
                    return acc;
                }, { min: 0, max: 0, });
                response.data.forEach((item) => {
                    nodeMap.set(item.innerNeuronId, { id: item.innerNeuronId.toString(), label: item.innerNeuronName, fill: nodeFillColor(item.innerNeuronValue), });
                    nodeMap.set(item.outerNeuronId, { id: item.outerNeuronId.toString(), label: item.outerNeuronName, fill: nodeFillColor(item.outerNeuronValue), });
                    edgeMap.set(item.axionID, { source: item.innerNeuronId.toString(), target: item.outerNeuronId.toString(), label: Number(item.weight).toFixed(2), data: item, id: item.axionID.toString(), size: 3, });
                });
                const newNodes = [...nodeMap.values()];
                const newEdges = [...edgeMap.values()];
                setNodes(newNodes);
                setEdges(newEdges);
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching brain topology: ${error}`, { variant: 'error', });
        });
    }

    async function fetchBrainNameList() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainNames&parm=${headers.userGuid}`;

        return axios.get(url, { headers: headers }).catch((error) => {
            enqueueSnackbar(`Error fetching brain names: ${error}`, { variant: 'error', });
        });
    }

    const graphTheme = {
        ...defaultGraphTheme,
        edge: {
            ...defaultGraphTheme.edge,
            activeFill: defaultGraphTheme.edge.fill,
        }
    };

    function nodeFillColor(value) {
        const { min, max, } = nodeValueRef.current;
        const slope = 1.0 / (max - min);
        const intercept = -min / (max - min);
        const effectiveValue = Math.max(Math.min(value, max), min);
        const val = slope * effectiveValue + intercept;
        const h = (1.0 - val) * 240;
        return "hsl(" + h + ", 100%, 40%)";
    }

    const NoNodesOverlay = () => {
        return (
            <span style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h6' color='text.secondary'>No network topology to display. Please select a Brain Name and/or a model name to display. Try creating your own Brain by clicking on the Create Button/Link.</Typography>
            </span>
        );
    }

    function handleExportGraph() {
        const data = graphRef.current.exportCanvas();
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', 'graph.png');
        link.click();
    }

    const graphControlsSx = useMemo(() => ({
        position: 'absolute',
        top: 15,
        right: 1,
        display: 'flex',
    }), []);

    const handleNewBrainDialogClose = useCallback((newBrainName) => {
        setOpenAddNewDialog(false);
    }, []);

    async function insertBrainQueue(data, action) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/chain/JSON/Pushfetch?userGuid=${headers.userGuid}&chain=brain&name=insertBrainQueue`
            + `&parm=${action}&parm=${headers.userGuid}&parm=${data.brainName}&parm=${data.size ?? 0}&parm=${data.hours ?? 0}&parm=${data.autoTrain ? 1 : 0}&parm=${data.beta ?? 10}`;

        const body = { inputs: (data.inputs ?? []).map(io => io.name), outputs: (data.outputs ?? []).map(io => io.name), };

        const options = {
            headers: headers,
            url: url,
            method: 'POST',
            data: body,
        };

        return axios(options).catch((error) => {
            enqueueSnackbar(`Error inserting to brain queue: ${error}${error?.response?.data ? `. ${error.response.data}` : ''}`, { variant: 'error', });
        });
    }

    const handleAddNewBrain = useCallback(debounce(async (data) => {
        return insertBrainQueue(data, 'setUpNewBrain');
    }, 2000, { leading: true, trailing: false, }), [headers]);

    const setUpNewBrainEasy = useCallback(debounce(async (data) => {
        return insertBrainQueue(data, 'easySetUpNewBrain');
    }, 2000, { leading: true, trailing: false, }), [headers]);

    const trainBrain = useCallback(debounce((beta = 1.0, name = brainName) => {
        setOpenConfirmationDialog(false);
        enqueueSnackbar('Training started. We will alert you when it is complete.', { variant: 'info', });
        insertBrainQueue({ brainName: name, beta, }, 'syncTrainBrain');
    }, 2000, { leading: true, trailing: false, }), [brainName, headers]);

    function handleBrainChange(newBrain) {
        setBrain(newBrain);
        const models = [...new Set(brains.filter(item => item.name === newBrain.name).map((item) => item.modelName))];
        setModelNames(models);
        //don't change the model if the current model is still valid
        if (!models.includes(modelName)) {
            setModelName(models[0]);
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh', width: '100%', }}>
            <BetaAlert open={openBetaAlert} handleHide={() => setOpenBetaAlert(false)} />
            <TrainingConfirmationDialog
                open={openConfirmationDialog}
                message={`It may take a while to train the brain. You will receive text messages and application popups letting you know the progress.  Are you sure you want to proceed?`}
                cancelText='CANCEL'
                confirmText='TRAIN'
                title={`Train brain ${brainName}`}
                onCancel={() => setOpenConfirmationDialog(false)}
                onConfirmation={({ beta, }) => trainBrain(beta)}
                brainName={brainName}
            />
            <NewBrainDialog
                open={openAddNewDialog}
                onClose={handleNewBrainDialogClose}
                existingBrainNames={brainNames}
                handleAddNewBrain={handleAddNewBrain}
                setUpNewBrainEasy={setUpNewBrainEasy}
            />
            <Grid container spacing={2} sx={{ p: 1, flexGrow: 1, }}>
                <Grid item xs={4} sx={{ height: '50%', display: showAnalysis ? 'flex' : 'none' }}>
                    <PointStatistics brainName={brainName} modelName={modelName} />
                </Grid>
                <Grid item xs={showAnalysis ? 8 : 12} sx={{ height: showAnalysis ? '50%' : '100%', }}>
                    <Box sx={{ position: 'relative', height: '100%' }}>
                        <GraphCanvas
                            ref={graphRef}
                            animate={false}
                            theme={graphTheme}
                            nodes={nodes}
                            edges={edges}
                            labelType='all'
                            layoutType={layoutType}
                            edgeLabelPosition="natural"
                            maxNodeSize={6}
                            minNodeSize={6}
                            draggable
                        />
                        {nodes && !nodes.length && <NoNodesOverlay />}
                        <GraphControls
                            ref={graphRef}
                            layoutType={layoutType}
                            setLayoutType={setLayoutType}
                            containerSx={graphControlsSx}
                        />
                        <div style={{ position: 'absolute', bottom: 1, left: 1, display: 'flex', alignItems: 'center', }}>
                            <Button
                                endIcon={<FileDownloadOutlined />}
                                onClick={handleExportGraph}
                                sx={{ paddingRight: 3, }}
                            >Export</Button>
                            <Box>
                                {infoText}
                            </Box>
                        </div>

                        <div style={{ position: 'absolute', top: 15, left: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', }}>
                            <Stack direction='row' spacing={2} sx={{ order: showAnalysis ? 2 : 0, p: 1, }}>
                                <Tooltip title="Start your modeling journey here!  Click to create a new brain and start to project future prices of LMPs." arrow>
                                    <Button
                                        onClick={() => setOpenAddNewDialog(true)}
                                    >
                                        Create
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Train the brain.  After you train the brain, it may take some time before results are shown in the Analyze sections.  Your will receive updates in the lower left corner of the App as the brain progresses." arrow>
                                    <span style={{ display: 'flex' }}>
                                        <Button
                                            onClick={() => setOpenConfirmationDialog(true)}
                                            disabled={!isUsersBrain}
                                        >
                                            Train
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Open the Analysis pane to check out performance data, see graphs on Brain error, and see how your brain would work to generate alerts for upcoming price changes." arrow>
                                    <Button
                                        onClick={() => setShowAnalysis(e => !e)}
                                    >
                                        Analyze
                                    </Button>
                                </Tooltip>
                            </Stack>
                            {showAnalysis && <div style={{ order: 1, flexBasis: '100%', height: 0 }} />}
                            <Stack direction='row' spacing={2} sx={{ px: 1 }}>
                                <Autocomplete
                                    autoHighlight
                                    autoComplete
                                    autoSelect
                                    disableClearable
                                    options={distinctBrains}
                                    value={brain}
                                    getOptionLabel={(option) => option.name ?? ''}
                                    onChange={(_, newValue) => {
                                        handleBrainChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Brain Name"
                                            size='small'
                                            variant="standard"
                                            sx={{ width: '250px' }}
                                            helperText={(isAdmin && !!brain.userName) ? `${brain.userName}` : ''}
                                        />
                                    )}
                                />
                                <Autocomplete
                                    autoHighlight
                                    autoComplete
                                    autoSelect
                                    disableClearable
                                    options={modelNames}
                                    value={modelName}
                                    onChange={(_, newValue) => {
                                        setModelName(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Model Name"
                                            size='small'
                                            variant="standard"
                                            sx={{ width: '350px' }}
                                        />
                                    )}
                                />
                            </Stack>
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ height: '50%', display: showAnalysis ? 'flex' : 'none' }}>
                    <Analysis setShow={setShowAnalysis} dailyData={dailyData} hourlyData={hourlyData} />
                </Grid>
            </Grid>
        </Box >
    );
};

export default memo(BrainTopology);

const BetaAlert = ({ open, handleHide, }) => {
    const theme = useTheme();
    const { userIsInGroup } = useUserGroups();
    const brainUser = userIsInGroup(userGroups.brain);
    const showAlert = !brainUser && open;

    return (
        <Collapse in={showAlert}>
            <Alert severity="info" sx={{ display: 'flex', alignItems: 'center', m: 1, }} action={
                <IconButton size='small' onClick={handleHide}>
                    <CloseOutlined color='primary' />
                </IconButton>
            }>
                <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, }} spacing={2} direction={'row'}>
                    <Typography color={theme.palette.mode === 'dark' ? '' : 'primary'} sx={{ fontWeight: 'bold' }} >
                        BETA release! You are very limited in the number of inputs, outputs, and types of predictions you can make.  If your interest is piqued, please contact us at support@powersysops.com to discuss your options for a tailored forecast, just for you.
                    </Typography>
                </Stack>
            </Alert>
        </Collapse>
    );
}