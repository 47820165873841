import { useState, useEffect, useMemo, forwardRef, useContext, } from "react";
import moment from "moment";
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../../useActionAudit";
import { Box, Button, Grid } from '@mui/material';
import DetailCellRenderer from './PPWDetailGrid';
import TransmissionDialog from "./TransmissionDialog";
import PublishIcon from '@mui/icons-material/Publish';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { AgGridContainer } from "../../AgGrid/AgGridContainer";

export default forwardRef((props, ref) => {
  const { logAction } = useActionAudit();
  const { data } = props;
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState(false);
  const [openTransmissionDialog, setOpenTransmissionDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');

  useEffect(() => {
    setRowData(data);
  }, [data])

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({ ...props, });
  }, []);

  function onRowClicked(params) {
    //toggle detail grid
    params.node.setExpanded(!params.node.expanded)
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED'
    },
  };

  // all rows assigned CSS class 'default-class'
  const rowClass = 'default-class';

  // all even rows assigned 'my-shaded-effect'
  const getRowClass = params => {
    if (params.node.rowIndex % 2 === 0) {
      return 'my-shaded-effect';
    }
  };

  function configureProfileSparkline(params) {
    const info = JSON.parse(params.data.profileInfo ?? '[]');
    const atcData = info?.reduce((profile, block, idx) => {
      const endDate = new Date(block.StopTime);
      const startDate = new Date(block.StartTime);
      const capacity = parseInt(block.capacity);
      const nextBlockData = [];
      if (idx > 0) {
        const prevBlock = info[idx - 1]; //fill in gaps in blocks with 0 capacity
        if (Math.abs((new Date(prevBlock.StopTime)) - startDate) > 0) {
          nextBlockData.push({
            x: new Date(prevBlock.StopTime),
            y: 0,
          })
          nextBlockData.push({
            x: new Date(startDate - 1000),
            y: 0,
          })
        }
      }
      nextBlockData.push({
        x: startDate,
        y: capacity,
      })
      nextBlockData.push({
        x: new Date(endDate - 1000),
        y: capacity,
      })
      return [...profile, ...nextBlockData]
    }, []);
    return atcData;
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.yValue <= 0 ? palette.pastelRed : palette.green,
      stroke: params.yValue <= 0 ? palette.pastelRed : palette.green,
    };
  };

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      content: yValue,
    }
  }

  const palette = {
    blue: 'rgb(20,94,140)',
    lightBlue: 'rgb(182,219,242)',
    green: 'rgb(63,141,119)',
    lightGreen: 'rgba(75,168,142, 0.2)',
    pastelRed: 'rgb(255,102,102)',
  };

  const colDefs = useMemo(() => [
    {
      headerName: 'Path',
      field: 'PathName',
      flex: 3,
      checkboxSelection: true,
    },
    {
      headerName: 'Profile',
      field: 'atcFuture',
      flex: 2,
      cellRenderer: 'agSparklineCellRenderer',
      valueGetter: configureProfileSparkline,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    },
    /*{
      headerName: 'Legs',
      field: 'legs',
      flex: 1,
    },*/
    {
      headerName: 'Max Capacity',
      field: 'Capacity',
      flex: 1,
    },
    /*{
      headerName: 'Providers',
      field: 'TPs',
      flex: 1,
    },*/
    {
      headerName: 'Total Cost',
      field: 'OfferPrice',
      flex: 1,
      valueFormatter: params => `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(params.value)}`
    }
  ], [])

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ]
  }), []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    setSelectedRows(selected);
  }

  return (
    <Box
      sx={{ p: 2, flexGrow: 1, }}
      overflow='auto'
    >
      <TransmissionDialog
        open={openTransmissionDialog}
        pathData={selectedRows}
        action={dialogAction}
        setAction={setDialogAction}
        handleClose={() => setOpenTransmissionDialog(false)}
      />
      <Grid container columns={24} spacing={2} alignItems='center' sx={{ p: 1 }}>
        <Grid item xs={3}>
          <Button
            endIcon={<PublishIcon />}
            variant='contained'
            fullWidth
            onClick={() => {
              setOpenTransmissionDialog(true);
              setDialogAction('reservenow');
            }}
            disabled={!selectedRows?.length}
          >Reserve</Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            endIcon={<AddAlertIcon />}
            variant='contained'
            fullWidth
            onClick={() => {
              setOpenTransmissionDialog(true);
              setDialogAction('textalert');
            }}
            disabled={!selectedRows?.length}
          >Alert</Button>
        </Grid>
      </Grid>
      <AgGridContainer
        style={{ width: "98%", height: '75vh' }}
      >
        <AgGridReact
          ref={ref}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          // rowClass={rowClass}
          rowSelection={'multiple'}
          onSelectionChanged={onSelectionChanged}
          getRowClass={getRowClass}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          overlayNoRowsTemplate={'<span>Fetch routes to see data here.</span>'}
          animateRows={true}
          detailRowAutoHeight
        />
      </AgGridContainer>
    </Box>
  )
})