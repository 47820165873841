import { useRef, } from 'react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';

export default (props) => {
  const { gridRef, setRowData, ...buttonProps } = props;
  const fileInput = useRef();
  const { enqueueSnackbar } = useSnackbar();

  function handleFileSelect(event) {
    const file = event.target.files[0];
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setRowsFromData(results.data);
      },
    });

    function setRowsFromData(data) {
      const colDefs = gridRef.current.api.columnModel.columnDefs;
      const newRows = [];
      data.forEach(row => {//need to map header names in rowData to column fields
        const newRow = {};
        Object.keys(row).forEach(headerName => {
          const def = colDefs.find(col => col.headerName === headerName);
          if (def) {
            newRow[def.field] = row[headerName];
          } else {
            enqueueSnackbar(`Header name '${headerName}' does not match an existing grid column field.`)
          }
        });
        newRows.push(newRow);
      });
      setRowData(newRows);
      fileInput.current.value = null; //clear old value to allow for selection of the same file
    }
  }

  return (
    <>
      <Button
        onClick={() => fileInput.current.click()}
        {...buttonProps}
      />
      <input
        ref={fileInput}
        type="file"
        accept='.csv'
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />
    </>
  )
}