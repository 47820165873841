import { Button, Checkbox, Grid, TextField, FormControl, InputLabel, MenuItem, OutlinedInput, ListItemText, Select, } from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { useLocations } from "../../data/dataHooks";
import CircularProgress from '@mui/material/CircularProgress';
import '../../styles/lookupStyles.css';

function LookupFields({ inputVals, setInputVals, handleFastPathUpdate }) {
  const { isLoading, isFetching, data, isError } = useLocations();
  const { pod, por, date, startHE, endHE, minCapacity, serviceIncrement, tsClass, tsType, } = inputVals;

  //alert(JSON.stringify(data));

  const {
    setPod,
    setPor,
    setDate,
    setStartHE,
    setEndHE,
    setMinCapacity,
    setServiceIncrement,
    setTsClass,
    setTsType,
  } = setInputVals;

  const locations = data?.map((elem) => {
    return { label: elem, value: elem };
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };

  if (isLoading) {
    return (
      //// KJ added this circular progress bar instead of just saying loading... like a goof
      <div style={{
        position: "fixed",
        top: "48%",
        left: "50%",
        //topMargin: "-50px",
        //leftMargin: "-100px",
      }}>
        <CircularProgress />
      </div>
    )
  }

  const handleChange = (event, setValue) => {
    const value = event.target.value;
    setValue(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const serviceIncrements = [
    'Hourly',
    'Daily',
    'Weekly',
    'Monthly',
    'Yearly',
  ];

  const tsClasses = [
    'Firm',
    'Non-Firm',
    'Secondary',
    'Network',
  ];

  const tsTypes = [
    'Firm',
    'Network',
    'Grandfathered',
    'CF-NT',
    'CF-PTP',
    'Point_To_Point',
    'Network Modification',
    'Recallable',
  ];

  return (
    <Grid container justifyContent="center">
      <Grid container className={'textLabels'} item xs={11}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              color="success"
              size="small"
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          {/* <Grid item xs={2}>
            POR
          </Grid> */}
          <Grid item xs={12}>
            <Autocomplete
              id="POR"
              fullWidth
              options={locations}
              value={por}
              onChange={(event, newValue) => {
                setPor(newValue);
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option) => {
                return option.label === por.label;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //color="white"
                  size="small"
                  variant="outlined"
                  label="POR"
                  color="success"
                  placeholder="POR"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Autocomplete
              id="POD"
              fullWidth
              options={locations}
              value={pod}
              onChange={(event, newValue) => {
                setPod(newValue);
              }}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option) => {
                return option.label === pod.label;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //color="primary"
                  size="small"
                  variant="outlined"
                  label="POD"
                  color="success"
                  placeholder="POD"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                label="HE Start"
                color="success"
                value={startHE}
                onChange={(e) => setStartHE(e.target.value)}
                InputProps={{
                  inputProps: {
                    max: 24,
                    min: 1,
                  },
                }}
              />
            </Grid>
            <Grid container item xs={4} justifyContent="center">
              <span>to</span>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                label="HE End"
                color="success"
                value={endHE}
                onChange={(e) => setEndHE(e.target.value)}
                InputProps={{
                  inputProps: {
                    max: 24,
                    min: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <Grid item xs={10}>
            <TextField
              size="small"
              label="Minimum Capacity"
              variant="outlined"
              color="success"
              value={minCapacity}
              onChange={(e) => setMinCapacity(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <div>
            <FormControl sx={{ m: 0, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label">Service Increment</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={serviceIncrement}
                onChange={(e) => handleChange(e, setServiceIncrement)}
                input={<OutlinedInput label="Service Increment" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {serviceIncrements.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={serviceIncrement.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <div>
            <FormControl sx={{ m: 0, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label">TS Class</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={tsClass}
                onChange={(e) => handleChange(e, setTsClass)}
                input={<OutlinedInput label="TS Class" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {tsClasses.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={tsClass.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <div>
            <FormControl sx={{ m: 0, width: 200 }}>
              <InputLabel id="demo-multiple-checkbox-label">TS Type</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={tsType}
                onChange={(e) => handleChange(e, setTsType)}
                input={<OutlinedInput label="TS Type" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {tsTypes.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={tsType.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid
          style={{ marginTop: "20px" }}
          container
          justifyContent="space-between"
        >
          <Grid style={{ marginTop: "10px" }} item xs={8}>
            <Button className={'fetchButton'}
              onClick={handleFastPathUpdate}
              variant="contained"
              fullWidth
            >
              Fetch Routes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LookupFields;
