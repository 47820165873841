import { useEffect, useMemo, useState, } from 'react';
import { AgChartsReact } from 'ag-charts-react';
import { Box, useTheme, } from '@mui/material';

export default (props) => {
    const { targetData = [], chartData = new Map(), } = props;

    const theme = useTheme();
    const palette = theme.palette.primary;

    const targetProfile = useMemo(() => formatDataForChart(targetData), [targetData])

    function formatDataForChart(data) {
        return data?.reduce((profile, block, idx) => {
            const endDate = new Date(block.endDateTime);
            const startDate = new Date(block.startDateTime);
            const capacity = parseInt(block.capacityRequested);
            const nextBlockData = [];
            if (idx > 0) {
                const prevBlock = data[idx - 1]; //fill in gaps in blocks with 0 capacity
                if (prevBlock.endDateTime && Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
                    nextBlockData.push({
                        //x: new Date(prevBlock.endDateTime + 1000),
                        x: new Date(prevBlock.endDateTime),
                        y: 0,
                    })
                    nextBlockData.push({
                        //x: new Date(startDate - 1000),
                        x: new Date(startDate),
                        y: 0,
                    })
                }
            }
            block.startDateTime && nextBlockData.push({
                x: startDate,
                y: capacity,
            })
            block.endDateTime && nextBlockData.push({
                x: endDate,
                y: capacity,
            })
            return [...profile, ...nextBlockData]
        }, [])
    }

    function buildSeries(title, data) {
        return {
            data: data,
            type: 'line',
            title: title,
            xKey: 'x',
            yKey: 'y',
        }
    }

    const defaultOptions = {
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                    palette.green,
                    palette.white,
                    palette.dark,
                ],
                strokes: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                    palette.green,
                    palette.white,
                    palette.dark,
                ],
            },
            overrides: {
                line: { series: { strokeWidth: 3, marker: { enabled: true } } },
            },
        },
        autoSize: true,
        padding: {
            left: 40,
            right: 40,
        },
        axes: [
            {
                position: 'bottom',
                type: 'time',
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
            },
            {
                position: 'left',
                type: 'number',
                min: 0,
                title: {
                    text: 'Capacity',
                },
            },
        ],
        legend: {
            position: 'right',
        },
    }

    const [chartOptions, setChartOptions] = useState(defaultOptions);

    function updateChart() {
        const newOptions = {
            ...chartOptions,
            theme: {
                ...chartOptions.theme,
                baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            },
        };

        newOptions.series = [
            buildSeries('Target', targetProfile),
            ...[...chartData.keys()].map((key) => buildSeries(key, formatDataForChart(chartData.get(key)))),
        ];

        setChartOptions(newOptions);
    }

    useEffect(() => {
        updateChart();
    }, [chartData, targetProfile, theme.palette.mode])

    return (
        <Box sx={{ height: '100%', }}>
            <AgChartsReact options={chartOptions} />
        </Box>
    )
}