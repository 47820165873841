import { Box, Typography } from "@mui/material"

export const DetailCellRenderer = (props) => {
    let allData = props.data?.[props.hour] ?? {};
    const { value, flag, status } = allData;

    let flagClass = '';
    if (flag === 'yellow') {
        flagClass = 'corner-flag-yellow';
    } else if (flag === 'red') {
        flagClass = 'corner-flag-red';
    } else if (flag === 'green') {
        flagClass = 'corner-flag-green';
    } else if (flag === 'red/green') {
        flagClass = 'corner-flag-split';
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%' }} className={flagClass}>
            <Typography className='ag-cell-text'>{value}</Typography>
        </Box>
    )
}