import React, { useEffect, useState, useRef, useMemo } from 'react';
import { range } from 'lodash';
import { DatabaseLayoutToolPanel } from "../ToolPanels/DatabaseLayoutToolPanel.js";
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { Box, Button } from '@mui/material';
import FileUploaderDialog from './FileUploaderDialog';
import axios from "axios";
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { useRemoteLayout } from '../useRemoteLayout';
import { useData } from '../useData';
import { useGridButtons } from '../useGridButtons.js';
// import { truncateSync } from 'fs';
import useHeader from '../useHeader.js';
import { AgGridContainer } from '../AgGrid/AgGridContainer.js';

export default React.memo(BidmanGrid);

function BidmanGrid({ node, oprDay, refreshAll, triggerRefresh }) {
  //const header = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [fileUploaderOpen, setFileUploaderDialogOpen] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const { nodeId, gridDataApiEndpoint, submitApiEndpoint, queryMarketApiEndpoint, submitMarketApiEndpoint } = node;
  const gridRef = useRef();
  const guid = useLocalGuid();
  //const { rowData, getData } = useData();

  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  }

  const { CopyRangeButton, CopyRowsButton, ExportButton, RefreshButton } = useGridButtons({ gridRef });
  const [buttonsDisabled, setButtonsDisabled] = React.useState(true);
  const headers = useHeader();

  const onRowDataUpdated = (params) => {
    gridRef.current.api.hideOverlay();
    setButtonsDisabled(false);
  };

  const handleFileUploaderDialogClose = () => {
    setFileUploaderDialogOpen(false);
    triggerRefresh({ market: node.market });
  };

  async function HandleQueryMarketButtonClick() {
    gridRef.current.api.stopEditing();
    gridRef.current.api.showLoadingOverlay();
    const data = [];
    gridRef.current.api.forEachLeafNode(node => data.push(node.data));

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${queryMarketApiEndpoint}`;
    if (url.includes('http')) {
      url = `${queryMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`
    } else {
      url = `${apiUrlPrefix}/${queryMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    }

    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    }

    // enqueueSnackbar(JSON.stringify(options));

    const response = await axios(options).then(response => {
      if (response.data === 'Success') {
        enqueueSnackbar('Bids queried from Market.');
      } else {
        enqueueSnackbar(response.data);
      }
    }).catch(err => { enqueueSnackbar(`Error querying bids from market.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`) });


    enqueueSnackbar('Refreshing grid data...');
    //fetch();
    triggerRefresh({ market: node.market });
  }

  async function HandleSubmitMarketButtonClick() {
    gridRef.current.api.stopEditing();
    gridRef.current.api.showLoadingOverlay();

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitMarketApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`
    } else {
      url = `${apiUrlPrefix}/${submitMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    }

    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    }

    const data = [];
    gridRef.current.api.forEachLeafNode(node => data.push(node.data));

    const response = await axios(options).then(response => {
      if (response.data === 'Success') {
        enqueueSnackbar('Bids submitted to Market.');
      } else {
        enqueueSnackbar(response.data);
      }
    }).catch(err => { enqueueSnackbar(`Error submitting bids to market.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`) });

    enqueueSnackbar('Refreshing grid data...');
    //fetch();
    triggerRefresh({ market: node.market });
  }

  async function handleSaveButtonClick() {
    setButtonsDisabled(true);
    enqueueSnackbar('Saving bids...');
    gridRef.current.api.stopEditing();

    let message = 'Bids saved to database.';

    //ecz 5/3/22 remove row tooltip and cell classes before sending to db
    const flattened = [];
    gridRef.current.api.forEachLeafNode(node => {
      const data = node.data;
      const flatData = {};
      const cols = Object.keys(data);
      cols.forEach(col => {
        const num = parseInt(col.match(/\d+/)); //parse the column field for an int
        if (num) {
          flatData[`HEQ${num}`] = data[col].Quantity.value;
          flatData[`HEP${num}`] = data[col].Price.value;
        }
        else {
          flatData[col] = data[col];
        }
      });
      flattened.push(flatData);
      //return flatData;
    });


    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`
    } else {
      url = `${apiUrlPrefix}/${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    }

    axios.post(
      url,
      JSON.stringify(flattened),
      { headers: headers, }
    ).then(response => {
      setButtonsDisabled(false);
      setDisableSaveButton(true);
      gridRef.current.api.hideOverlay();
      enqueueSnackbar(message);
      triggerRefresh({ market: node.market });
    }).catch(err => function () { message = `Error saving Bidman Grid data.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}` });
  }

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const hourCells = 'hour-cells';

  const onRefreshBtn = () => {
    enqueueSnackbar('Refreshing grid data...');
    fetch();
  };

  const rowClassRules = {
    //'row-fail': 'data.rowClass === "problem-row"',
    'row-fail': (params) => {
      return params.data?.rowClass === 'problem-row';
    }
  };

  useEffect(() => {

    let abortController = new AbortController();
    // your async action is here  
    fetch();
    return () => {
      abortController.abort();
    }

  }, [oprDay]);

  useEffect(() => {
    if (refreshAll.market === node.market) { fetch(); }
  }, [refreshAll]);

  function fetch() {
    gridRef.current.api?.showLoadingOverlay();
    setButtonsDisabled(true);

    const options = {
      method: 'GET',
      headers: headers,
      url: loadDataUri,
    }

    axios(options).then(response => {

      if (typeof response.data === 'string') {
        enqueueSnackbar(response.data);
      } else {
        //setRowData(response.data);
        gridRef.current.api.setRowData(response.data);
      }

      //setButtonsDisabled(false);
      setDisableSaveButton(true)
      //gridRef.current && gridRef.current.api.hideOverlay();

    }).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${loadDataUri}. Status: ${error.response?.status}. Message: ${error}`)
    });
  };

  function editableCheck(params) {
    return (params.data.Version === "Scheduled");
  }

  function cellRenderer(params) {
    if (params.value) {
      const col = params.colDef.field.split('.'); //the field will look like HE5.Quantity.value.
      const cellClass = params.node.data[col[0]][col[1]]['cellClass']; //use the column def to parse the row data for the cell class
      return <div style={{ verticalAlign: 'middle', display: 'inline', }} className={cellClass === 'problem-cell' ? 'row-fail' : 'row-pass'}>
        {` ${params.value}`}
      </div>
    } else return null;
  }

  const colDefs = [
    {
      editable: false,
      headerName: "Market",
      enableRowGroup: true,
      field: "market",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Subaccount",
      enableRowGroup: true,
      field: "SubAccount",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Category",
      enableRowGroup: true,
      field: "category",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Seq",
      enableRowGroup: true,
      field: "Sequence",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Bid Type",
      enableRowGroup: true,
      hide: true,
      field: "Bid_Type",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Location",
      enableRowGroup: true,
      rowGroup: true,
      field: "InternalName",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Version",
      enableRowGroup: true,
      field: "Version",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Total Bids",
      field: "TotalBids",
      sortable: true,
      filter: true,
    },
    ...range(25).map(i => {
      i++; //1 based index} no HE0}
      const header = `HE${i}`;
      return (
        {
          cellClass: hourCells, suppressMenu: true, editable: editableCheck, headerName: header, field: header, initialWidth: 2, marryChildren: true, children: [
            { cellRenderer: cellRenderer, suppressMenu: true, editable: editableCheck, headerName: "Q", initialWidth: 2, tooltipField: `${header}.Quantity.toolTip`, field: `${header}.Quantity.value`, },
            { cellRenderer: cellRenderer, suppressMenu: true, editable: editableCheck, headerName: "P", initialWidth: 2, tooltipField: `${header}.Price.toolTip`, field: `${header}.Price.value`, }
          ]
        }
      );
    })
  ]

  function updateColDefs(defs) {
    defs.forEach(def => {
      const num = parseInt(def.field.match(/\d+/)); //parse the column field for an int
      if (num) {
        updateDef(def);
      }
    });

    return (defs);
  }

  function updateDef(def) {
    def.cellRenderer = cellRenderer;
    def.editable = (params) => editableCheck(params);
    if (def.children) {
      def.children.forEach(updateDef);
    }
  };

  const { dbLayoutPanel, loadLayout } = useRemoteLayout(gridRef, nodeId, colDefs, updateColDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  function onCellValueChanged(params) {
    setDisableSaveButton(false);
  }

  function onGridReady(params) {
    loadLayout(params.api);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: false,
    sortable: false,
    resizable: true,
    tooltipField: "rowToolTip"
  }), [])

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <FileUploaderDialog
          fileUploaderOpen={fileUploaderOpen}
          handleFileUploaderDialogClose={handleFileUploaderDialogClose}
          handleFileUploaderDialogCancel={() => setFileUploaderDialogOpen(false)}
          oprDay={oprDay}
          nodeId={nodeId}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <RefreshButton
            disabled={buttonsDisabled}
            onClick={onRefreshBtn}
          />&nbsp;
          <CopyRowsButton disabled={buttonsDisabled} />&nbsp;
          <ExportButton disabled={buttonsDisabled} />&nbsp;
          <CopyRangeButton disabled={buttonsDisabled} />&nbsp;
          <Button
            disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={HandleQueryMarketButtonClick}>Query Bids</Button>&nbsp;
          <Button
            disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={HandleSubmitMarketButtonClick}>Submit Bids</Button>&nbsp;
          <Button
            disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={() => setFileUploaderDialogOpen(true)}
          >IMPORT BIDS</Button>&nbsp;
          <Button
            disabled={buttonsDisabled || disableSaveButton}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleSaveButtonClick}
          >Save All</Button>&nbsp;
        </Box>

        <AgGridReact
          //rowData={rowData}
          style={{ width: '100%', height: '100%;' }}
          ref={gridRef}
          onGridReady={onGridReady}
          sideBar={sideBar}
          onRowDataUpdated={onRowDataUpdated}
          onCellValueChanged={onCellValueChanged}
          rowClassRules={rowClassRules}
          rowSelection="multiple"
          suppressCopyRowsToClipboard={true}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          groupDefaultExpanded={-1}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          rowGroupPanelShow={'always'}
          enableRangeSelection={true}
          animateRows={true}
          groupSelectsChildren={true}
          components={{
            dbLayoutToolPanel: DatabaseLayoutToolPanel
          }}
        />
      </AgGridContainer>
    </div>
  );
};