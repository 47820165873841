import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from "react";
import { useActionAudit } from './useActionAudit';
import { useLayoutFunctions } from "./useLayoutFunctions";

const useGridLayout = (storageLocation, gridRef, baseColDefs, defaultDef = {}) => {
    const [colDefs, setColDefs] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const { captureLayout, applyLayout: applyLayoutToDefs, } = useLayoutFunctions();

    const saveLayoutLocal = useCallback(() => {
        const layout = captureLayout(gridRef);
        localStorage.setItem(storageLocation, JSON.stringify(layout));
        enqueueSnackbar('Layout saved.', { variant: 'info' });
        logAction(`Layout saved`, storageLocation, layout);
    }, [gridRef, storageLocation, captureLayout, enqueueSnackbar, logAction]);

    function loadLayout() {
        try {
            updateFromStorage();
        } catch (err) {
            enqueueSnackbar(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, { variant: 'error' });
            logAction(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, storageLocation);
        }
    };

    const deleteLayoutLocal = () => {
        clearLayout();
        localStorage.removeItem(storageLocation);
        enqueueSnackbar('Local layout deleted.', { variant: 'info' });
    };

    const clearLayout = () => {
        gridRef.current.columnApi.resetColumnState();
        let defaultDefs = [...baseColDefs].map(def => ({ ...defaultDef, ...def }));

        //reset hidden columns to initial state.
        defaultDefs.forEach(def => def.hide = def.initialHide ?? false);

        //reset column widths to initial state.
        defaultDefs.forEach(def => def.width = def.initialWidth ?? def.width);

        //reset sorting on all columns
        gridRef.current.columnApi.applyColumnState({
            defaultState: { sort: null }
        });

        //reset filters
        gridRef.current.api.setFilterModel(null);

        setColDefs(defaultDefs);
        enqueueSnackbar('Layout reset.', { variant: 'info' });
    };

    function updateFromStorage() {
        try {
            const savedLayout = JSON.parse(localStorage.getItem(storageLocation) ?? '{}');
            const lyt = applyLayoutToDefs(savedLayout, baseColDefs, defaultDef);
            setColDefs(lyt);
        } catch (err) {
            enqueueSnackbar(`Error loading saved layout. ${err}`, { variant: 'error' });
            gridRef?.current && clearLayout();
        }
    }

    //the following code does not consistently apply filters on initial load
    //furthermore, it interferes with the ability to apply filters that are saved with a dashboard view when resetting the layout, as happens in deal rizz
    //the proper way to do this is to use the grid api and do api.setGridOption('onFirstDataRendered', applyFilters);
    //this will ensure that filters are applied after the grid is rendered with columns in place
    //unfortunately, this method is not available with our current version of ag-grid and will need to be applied after we update
    //useEffect(() => { //need to wait for colDefs to be set before applying filters
    //!!colDefs?.length && applySavedFilters();
    //}, [colDefs]);

    function applySavedFilters(layout) {
        if (gridRef.current?.api) {
            const lyt = layout ?? JSON.parse(localStorage.getItem(storageLocation) ?? '{}');
            if (lyt?.filters) {
                applyFilters(lyt.filters);
            }
        }
    }

    const applyFilters = useCallback((filters) => {
        gridRef.current.api.setFilterModel(filters);
        gridRef.current.api.onFilterChanged();
    }, []);

    //save layout when window unloads
    useEffect(() => {
        window.addEventListener('beforeunload', saveLayoutLocal)
        return () => {
            window.removeEventListener('beforeunload', saveLayoutLocal)
        }
    }, []);

    const layoutPanel = {
        id: 'layout',
        labelDefault: 'Layout',
        labelKey: 'layout',
        toolPanel: 'layoutToolPanel',
        toolPanelParams: {
            onLayoutClear: clearLayout,
            onLayoutLoad: loadLayout,
            onLayoutSave: saveLayoutLocal,
            onLayoutDelete: deleteLayoutLocal
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
    }

    const applyLayout = useCallback((layout) => {
        const lyt = applyLayoutToDefs(layout, baseColDefs, defaultDef);
        setColDefs(lyt);
    }, [baseColDefs, defaultDef, applyLayoutToDefs]);

    return {
        layoutPanel,
        colDefs,
        loadLayout,
        applyLayout,
        clearLayout,
        applySavedFilters,
        applyFilters,
        captureLayout,
        deleteLayoutLocal,
        saveLayoutLocal,
    };
}

export default useGridLayout;
