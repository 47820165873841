import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

export default ({workflow, open, handleLaunch, handleCancel}) => {
    const [context, setContext] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
  

    useEffect(() => {
      try {
        if(workflow) {
          if(workflow.context) { //if there is context, allow the chance to edit
              const ctx = JSON.parse(workflow.context);
              setContext(ctx);
          } else { //otherwise just launch the workflow
              handleLaunch(workflow);
          }
        }
      } catch {
        enqueueSnackbar(`An error occurred. You may have mal-formed JSON in your context JSON.`);
        handleCancel();
      }
        
    }, [workflow]);

    function handleClose() {
        //clone workflow
        const editedFlow = {...workflow};

        //update context
        const ctx = JSON.parse(editedFlow.context);
        ctx.forEach(field => {
            //id string must match the string in render method
            const newVal = document.getElementById(`${field.name}-workflow-context-field`).value;
            field.props.value = newVal;
            field.value = newVal;
        });
        editedFlow.context = JSON.stringify(ctx);

        handleLaunch(editedFlow);
    }

    return(open &&
      <div>
        <Dialog fullWidth maxWidth="lg" open={open}>
          <DialogTitle>Launch workflow {workflow?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>Edit workflow context before launching.</p>
            </DialogContentText>
            {context.map(field => <>       
              <TextField
                //id string must match the string in handleClose
                id={`${field.name}-workflow-context-field`}
                {...field.props}
              /><p/></>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleClose}>Launch</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}