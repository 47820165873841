import { AgGridReact } from "ag-grid-react";
import { forwardRef, useEffect, useMemo, useImperativeHandle, } from "react";
import _ from 'lodash';
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import useGridLayout from "../../useGridLayout";
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel";
import { useTheme } from "@emotion/react";
import { tooltipRenderer, configureSparkline, } from "./UtilFunctions";
import { defaultGridOptions } from "../../AgGrid/defaultGridProps";

export const WorkspaceGrid = forwardRef((props, ref) => {
    const { data, onSelectionChanged, tsrKey, setLegHighlight, } = props;
    const storageLocation = 'PathwayBuilderWorkspaceGrid';
    const theme = useTheme();

    const markerFormatter = (params) => {
        const { min, highlighted } = params;
        return {
            size: highlighted ? 3 : 1,
            enabled: true,
            fill: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
            stroke: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
        };
    };

    const baseColDefs = useMemo(() => [
        {
            headerName: 'TSR Key',
            valueGetter: (params) => {
                const { PointOfReceipt, Provider, PointOfDelivery } = params?.data ?? {};
                if (PointOfReceipt && Provider && PointOfDelivery) {
                    return `${params.data.PointOfReceipt}-${params.data.Provider}-${params.data.PointOfDelivery}`;
                } else {
                    return "";
                }
            },
            checkboxSelection: true,
            rowDrag: true,
        },
        {
            headerName: 'Provider',
            field: 'Provider',
        },
        {
            headerName: "PointOfReceipt",
            field: "PointOfReceipt",
        },
        {
            headerName: 'PointOfDelivery',
            field: 'PointOfDelivery',
        },
        {
            headerName: 'Path Name',
            field: 'PathName',
        },
        {
            headerName: 'Capacity',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data?.Capacity) {
                    return configureSparkline(JSON.parse(params.data.Capacity));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Offer Price',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data?.OfferPrice) {
                    return configureSparkline(JSON.parse(params.data.OfferPrice));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Price Unit',
            field: 'PriceUnit',
        },
        {
            headerName: 'Offer Increment',
            field: 'OFFER_INCREMENT',
        },
        {
            headerName: 'Start Time',
            field: 'OfferStartTimeUtc',
        },
        {
            headerName: 'Stop Time',
            field: 'OfferStopTimeUtc',
        },
        {
            headerName: 'Posting Ref',
            field: 'PostingRef',
        },
        {
            headerName: 'Related Ref',
            field: 'relatedRef',
        },
        {
            headerName: 'Other Curtailment Priority',
            field: 'OTHER_CURTAILMENT_PRIORITY',
        },
        {
            headerName: 'Request Type',
            field: 'RequestType',
        },
        {
            headerName: 'Sale Ref',
            field: 'SaleRef',
        },
        {
            headerName: 'Seller Code',
            field: 'SellerCode',
        },
        {
            headerName: 'Service Increment',
            field: 'ServiceIncrement',
        },
        {
            headerName: 'TS Class',
            field: 'TSClass',
        },
        {
            headerName: 'TS Period',
            field: 'TSPeriod',
        },
        {
            headerName: 'TS Type',
            field: 'TSType',
        },
        {
            headerName: 'TS Window',
            field: 'TSWindow',
        },
        {
            headerName: 'TS Subclass',
            field: 'TSSubclass',
        },
        {
            headerName: 'Time of Last Update',
            field: 'TimeOfLastUpdateUtc',
        },
        {
            headerName: 'NERC Priority',
            field: 'NERCcurtailmentPriority',
        },
        {
            headerName: 'Seller Comments',
            field: 'SELLER_COMMENTS',
        },
        {
            headerName: 'Seller DUNS',
            field: 'SELLER_DUNS',
        },
        {
            headerName: 'Seller Email',
            field: 'SELLER_EMAIL',
        },
        {
            headerName: 'Seller Fax',
            field: 'SELLER_FAX',
        },
        {
            headerName: 'Seller Name',
            field: 'SELLER_NAME',
        },
        {
            headerName: 'Seller Phone',
            field: 'SELLER_PHONE',
        },
        {
            headerName: 'Interface Type',
            field: 'INTERFACE_TYPE',
        },
        {
            headerName: 'ANC SVC REQ',
            field: 'ANC_SVC_REQ',
        },
    ], []);

    const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: "agMultiColumnFilter",
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
    }), []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
                layoutPanel,
            ],
            position: 'right',
        }
    }, []);

    const gridOptions = {
        rowClassRules: {
            "ag-row-selected": params => !!params.data._highlighted || params.node.selected,
            "ag-row-hover": params => !!params.data._highlighted,
        },
    };

    function onGridReady(params) {
        loadLayout();
    }

    const noRowsOverlayText = 'Drag rows here from the grid to the left, or select rows and click the Move Selected button.'

    function getRowId(params) {
        return tsrKey(params.data);
    }

    function handleMouseOver(params) {
        const node = params.node;
        const legKey = tsrKey(node.data);
        setLegHighlight(legKey, true);
    }

    function handleMouseOut(params) {
        const node = params.node;
        const legKey = tsrKey(node.data);
        setLegHighlight(legKey, false);
    }

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={ref}
            rowData={data}
            getRowId={getRowId}
            gridOptions={gridOptions}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
            onCellMouseOver={handleMouseOver}
            onCellMouseOut={handleMouseOut}
            rowSelection="multiple"
            overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">${noRowsOverlayText}</span>`}
            components={{
                layoutToolPanel: LayoutToolPanel,
            }}
        />
    );
});