import { CriteriaContext } from "../CriteriaContext/CriteriaContext";
import FormAutocomplete from "../FormControls/FormAutocomplete";
import { useMsal } from "@azure/msal-react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string, } from "yup";
import { Grid, Tooltip, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import useHeader from "../useHeader";
import axios from "axios";
import { apiUrlPrefix } from "../../authConfig";
import { useSnackbar } from "notistack";

export const TsrAlertForm = ({ defaultValues, onSubmit, }) => {
    const { statuses } = useContext(CriteriaContext);
    const { accounts } = useMsal();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [phoneNumber, setPhoneNumber] = useState('');

    const defaults = useMemo(() => ({
        ...defaultValues,
        contactInfo: phoneNumber,
    }), [defaultValues, phoneNumber])

    const { register, handleSubmit, reset, control, formState: { errors, }, setValue } = useForm({
        defaultValues: defaults,
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (headers?.userGuid) {
            userInfoFetch().then(response => {
                const userInfo = response?.data?.[0] ?? {};
                const account = accounts[0];
                const phoneNumber = account?.idTokenClaims?.extension_PhoneNumber;
                if (userInfo?.phone) {
                    setPhoneNumber(userInfo.phone);
                    setValue('contactInfo', userInfo.phone);
                } else if (phoneNumber) {
                    setPhoneNumber(phoneNumber);
                    setValue('contactInfo', phoneNumber);
                }
            })
        }
    }, [headers.userGuid, accounts])

    async function userInfoFetch() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchUserInformation&parm=${headers.userGuid}`,
        }

        return axios(options).catch(error => {
            enqueueSnackbar(`Error fetching user info. ${error.message}`);
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id='alert-dialog-form'>
            <Grid container spacing={1} sx={{ p: 1, }}>
                <Grid sx={{ p: 1, }} item xs={4}>
                    <TextField
                        label="Assignment Ref"
                        fullWidth
                        disabled
                        {...register('aref')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormAutocomplete
                        name={'alertType'}
                        control={control}
                        autoSelect
                        autoComplete
                        options={['Text', 'Email', 'Both']}
                        fullWidth
                        disabled
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Alert Type"
                                color="success"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Contact Info"
                        fullWidth
                        disabled
                        {...register('contactInfo')}
                        error={!!errors.contactInfo}
                        helperText={errors.contactInfo?.message}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Current Status"
                        fullWidth
                        disabled
                        {...register('currentStatus')}
                    />
                </Grid>
                <Tooltip title="Select the status you want to be alerted on." arrow placement="top">
                    <Grid item xs={4}>
                        <FormAutocomplete
                            name={'alertStatus'}
                            control={control}
                            autoSelect
                            autoComplete
                            options={['ANY', ...statuses]}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Alert Status"
                                    color="success"
                                    error={!!errors.alertStatus}
                                    helperText={errors.alertStatus?.message}
                                />
                            )}
                        />
                    </Grid>
                </Tooltip>
            </Grid>
        </form>
    );
};

const schema = object().shape({
    alertStatus: string().required('Alert Status is required'),
    contactInfo: string().required('Contact Info is required. Please update your profile under Settings if it is missing.'),
})