import { useEffect, useRef, useCallback } from "react";
import { useHubMessages } from "./useHubMessages";
import dayjs from "dayjs";
import { debounce } from "lodash";

/*
    Props:
    action: async function called when an allowed message has been received, e.g. a data fetch
    allowedMessages: an array of the hub messages to look for
    predicate: a boolean value that if false, will stop the action from being called, e.g. could be false if there is a state variable that action depends on that has not been set
    callbackDependencies: since 'action' 'is wrapped in a useCallback, include the dependencies of 'action'.
    wait: the number of milliseconds to wait after the last allowed message is received before calling the action
    actIntial: if true, the action will be called without waiting for a message to be received
*/

const useHubAction = ({ action, allowedMessages, predicate = true, callbackDependencies = [], wait = 2000, }) => {
  const hubData = useHubMessages();
  const messageQueueCount = useRef(0);
  const loading = useRef(false);

  useEffect(() => { //every time we get an accepted hub message, increment the queue count
    if (allowedMessages.includes(hubData.message) && predicate) {
      messageQueueCount.current++;
      console.log(`Message ${hubData.message} received. Queue count: ${messageQueueCount.current}. Timestamp: ${dayjs().format('HH:mm:ss.SSS')}`)
      if (!loading.current) {
        act();
      }
    }
  }, [hubData]);

  const act = useCallback(debounce(() => {
    console.log(`Starting hub action. Queue count: ${messageQueueCount.current}. Timestamp: ${dayjs().format('HH:mm:ss.SSS')}`)
    loading.current = true;
    messageQueueCount.current = 0;
    return action().then(_ => { //after we are done loading, set loading to false and if there are more messages in the queue, call act again
      if (messageQueueCount.current > 0) {
        act();
      } else {
        loading.current = false;
      }
    });
  }, wait, { leading: true }), callbackDependencies);


  return hubData;
}

export default useHubAction;