import { useMemo, useState, } from 'react';
import { Dialog, DialogActions, DialogContent, Box, Button, DialogTitle, Typography, IconButton, } from '@mui/material';
import ProfileEditor from './ProfileEditor';
import CloseIcon from '@mui/icons-material/Close';
import ProfileEditor_v2 from './ProfileEditor_v2';

export default (props) => {
  const { open, closeDialog, currentLeg, pathData = [], maxInfo, defaultInfo, handleProfileSave, disabled = false, defaultValues = {}, version = 1, } = props;

  const defaultData = useMemo(() => defaultInfo?.map(row => ({
    ...row,
    maxCapacity: row.capacityRequested,
  })), [])

  const [gridData, setGridData] = useState(defaultData ?? []);

  function handleSave() {
    handleProfileSave(gridData);
  }

  const Editor = version === 1 ? ProfileEditor : ProfileEditor_v2;

  return (
    <Dialog open={open} maxWidth={'xl'} fullWidth >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Configure Profile</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={closeDialog} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
        <Editor
          currentLeg={currentLeg}
          pathData={pathData}
          maxInfo={maxInfo}
          disabled={disabled}
          gridData={gridData}
          setGridData={setGridData}
          defaultValues={defaultValues}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeDialog}>Cancel</Button>&nbsp;
        <Box sx={{ flexGrow: 1 }} />
        <Button disabled={disabled} variant="contained" color="primary" onClick={handleSave}>Save & Close</Button>
      </DialogActions>
    </Dialog>
  )
}