import { BlobServiceClient, } from '@azure/storage-blob';
import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

export const useAzureBlobs = (containerName) => {
    const { enqueueSnackbar, } = useSnackbar();
    const [blobs, setBlobs] = useState([]);

    const account = process.env.REACT_APP_AZURE_BLOB_STORAGE_ACCOUNT_NAME;
    const sasToken = process.env.REACT_APP_AZURE_BLOB_SAS_TOKEN;
    const blobServiceClient = useMemo(() => new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sasToken}`), [account, sasToken]);

    const uploadBlob = useCallback(async (file, container = containerName) => {
        try {
            const blobName = `${file.name}`;
            const containerClient = blobServiceClient.getContainerClient(container);
            const blobClient = containerClient.getBlockBlobClient(blobName);
            return blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });
        } catch (error) {
            enqueueSnackbar(`Failed to upload blob: ${error}`, { variant: 'error' });
        }
    }, [blobServiceClient, containerName, enqueueSnackbar]);

    async function fetchBlobs(container = containerName) {
        try {
            const containerClient = blobServiceClient.getContainerClient(container);
            const blobItems = containerClient.listBlobsFlat();
            const urls = [];
            for await (const blob of blobItems) {
                const tempBlockBlobClient = containerClient.getBlockBlobClient(blob.name);
                urls.push({ name: blob.name, url: tempBlockBlobClient.url });
                console.log(tempBlockBlobClient.url);
            }
            setBlobs(urls);
        } catch (error) {
            console.log(`Failed to fetch blobs: ${error}`);
        }
    };

    const createContainer = useCallback(async (name) => {
        try {
            const containerClient = blobServiceClient.getContainerClient(name);
            //check if container exists
            const containerExists = await containerClient.exists();
            if (containerExists) {
                return containerClient;
            }
            return containerClient.create();
        } catch (error) {
            enqueueSnackbar(`Failed to create container: ${error}`, { variant: 'error' });
        }
    }, [blobServiceClient, enqueueSnackbar]);

    return {
        uploadBlob,
        fetchBlobs,
        blobs,
        createContainer,
        blobServiceClient,
    }
}