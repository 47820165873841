import { useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, } = props;

  const rowData = useMemo(() => [
    ...data.segments.map(segment => {
      const row = { tp: segment.tp, por: segment.por, pod: segment.pod };
      segment.heList.forEach(he => { //flatten the nested list data to make displaying it easier
        row[`HE${he.he}`] = he;
      });
      return row;
    })
  ], [data])

  function priceFormatter(params) {
    return `${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(params.value)}`
  }

  const colDefs = useMemo(() => [
    {
      headerName: 'TP',
      field: 'tp',
      initialWidth: 80,
    },
    {
      headerName: 'POR',
      field: 'por',
      initialWidth: 100,
    },
    {
      headerName: 'POD',
      field: 'pod',
      initialWidth: 100,
    },
    ...[...Array(24).keys()].map(function (i) {
      i++; //1 based index, no HE0,
      const header = `HE${i}`;
      return (
        {
          headerName: header, field: header,
          children: [
            { headerName: "MW", field: `${header}.minMW`, initialWidth: 70 },
            { headerName: "Price", field: `${header}.cost`, initialWidth: 70, valueFormatter: priceFormatter },
          ]
        }
      )
    })
  ], [])

  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
  }), [])

  return (
    <AgGridContainer
      style={{ width: "100%", height: '100%', fontSize: '10px' }}
    >
      <AgGridReact
        columnDefs={colDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        domLayout={'autoHeight'}
      />
    </AgGridContainer>
  );
}