import { useState, useEffect, useMemo, forwardRef, memo, } from "react";
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../../useActionAudit";
import DetailCellRenderer from './DetailGridEast';
import { Button } from "@mui/material";

const ProGrid = forwardRef((props, ref) => {
  const { logAction } = useActionAudit();
  const { data, setSelectedRow, handleCancelFetch, handleFetch, noRowsOverlayText, } = props;
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [data])

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({...props,});
  }, []);

  function onRowClicked(params) {
    //toggle detail grid
    params.node.setExpanded(!params.node.expanded)
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED'
    },
  };

  // all rows assigned CSS class 'default-class'
const rowClass = 'default-class';

// all even rows assigned 'my-shaded-effect'
const getRowClass = params => {
    if (params.node.rowIndex % 2 === 0) {
        return 'my-shaded-effect';
    }
};

  const colDefs = useMemo(() => [
    {
      headerName: 'Path',
      field: 'routeName',
      flex: 4,
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: true, 
      
    },
    {
      headerName: 'Pathway',
      field: 'pathway',
      flex: 4,
      cellRenderer: 'agGroupCellRenderer',
      initialHide: true,
    },
    {
      headerName: 'Legs',
      field: 'legs',
      flex: 1,
    },
    {
      headerName: 'Max Capacity',
      field: 'maxCapacity',
      flex: 1,
    },
    {
      headerName: 'Providers',
      field: 'TPs',
      flex: 1,
    },
    {
      headerName: 'Total Cost',
      field: 'totalPrice',
      flex: 1,
      valueFormatter: params => `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(params.value)}`
    }
  ], [])

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ]
  }), []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    setSelectedRow(
      selected.length ? selected[0] : undefined
    )
  }

  const loadingOverlay = props => {
    return <div style={{ position: 'relative', fontSize: '20px', top: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      Loading pathways...
      <Button onClick={handleCancelFetch} variant="contained" sx={{ maxWidth: '200px', mt: 2, }}>Cancel</Button>
    </div>
  }

  function onGridReady(params) {
    handleFetch && handleFetch();
  }

  const noRowsOverlayTextDefault = 'No results.  Use the search criteria on the left to seach for pathways.  Using an * in some of the criterion allows for a wildcard.  Hard limit will not return results unless all paths have the minimum capacity FOR THE ENTIRE DURATION.  Unchecking Hard limit will bring back any pathway that has at least the minimum capacity as of the start time.'

  return (
    <AgGridReact 
      ref={ref}
      rowData={rowData}
      onGridReady={onGridReady}
      columnDefs={colDefs}
      gridOptions={gridOptions}
      statusBar={statusBar}
      rowSelection={'single'}
      onRowDataUpdated={onSelectionChanged}
      onSelectionChanged={onSelectionChanged}
      defaultColDef={defaultColDef}
      sideBar={sideBar}
      overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">${noRowsOverlayText ?? noRowsOverlayTextDefault}</span>`}
      loadingOverlayComponent={loadingOverlay}
      animateRows={true}
      masterDetail={true}
      detailRowAutoHeight
      onRowClicked={onRowClicked}
      detailCellRenderer={'detailCellRenderer'}
      components={{
        detailCellRenderer: detailCellRenderer,
      }}
    />
  )
})

export default memo(ProGrid);