import { IconButton, Stack, TextField, Box, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormDatePicker from "../../../FormControls/FormDatePicker";
import { useFormContext } from "react-hook-form";

export const ViewForm = ({ onSubmit, formId, expanded }) => {
    const { control, handleSubmit, getValues, setValue, formState: { errors, }, } = useFormContext();

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        //const stop = getValues('stopDate');
        setValue('startDate', start.add(days, 'day'));
        //setValue('stopDate', stop.add(days, 'day'));
    }

    /*const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const stop = getValues('stopDate');
        if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'stopDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('stopDate', value);
        } else {
            setValue(key, value);
        }
    }, 400), [setValue, getValues]);*/

    return (
        <Box
            sx={{ width: '100%', height: '100%', borderRadius: '0px', display: 'flex', alignItems: 'center', }}
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
        >
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, pt: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date Start"
                            //onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />
                        {/*&nbsp;<FormDatePicker
                            name='stopDate'
                            control={control}
                            label="Flow Date End"
                            onChange={(newValue) => handleDateUpdate('stopDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.stopDate,
                                    helperText: errors.stopDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />*/}
                        <IconButton size='small' onMouseDown={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{
                                minWidth: '170px',
                            }}
                        />
                    )}
                />

            </Stack>
        </Box>
    );
}