import TSRCalendar from "./TSRCalendar"
import { debounce } from 'lodash';
import { useHubMessages } from "../../HubContext/useHubMessages";
// import CircularProgress from '@mui/material/CircularProgress';
// import ConfirmationDialog from "../ConfirmationDialog"
import EditScheduleDialog from "./EditScheduleDialog"
import TSRScheduler from "./TSRScheduler"
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from 'react'
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid"
import moment from "moment"
import useHeader from "../../useHeader"
import axios from "axios"
import { apiUrlPrefix, userGroups } from "../../../authConfig"
import { useSnackbar } from "notistack"
import DivGuard from "../../../components/Guards/DivGuard";
import _ from 'lodash';
import { useRef } from "react";

export default () => {
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [currentEvent, setCurrentEvent] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const formatString = 'YYYY-MM-DD HH:mm:ss.SSS';
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventCopy, setEventCopy] = useState();
  const hubMessage = useHubMessages();
  const loadingRef = useRef(false);
  const hubQueue = useRef(0);

  useEffect(() => {
    fetch().then(response => {
      handleSetEventData(response.data);
    })
  }, []);

  useEffect(() => {
    const message = hubMessage.message;
    //if ((message ==='trg_tsrDispatchQueueUpdate' || message === 'PowerStationMetaData.WJ_TSR_DispatchQueueUpdate')) 
    if (['trg_tsrDispatchQueueUpdate', 'PowerStationMetaData.WJ_TSR_DispatchQueueUpdate'].includes(message)) {
      //enqueueSnackbar(message)
      ++hubQueue.current;
      console.log(`Hub message received: ${message} Hub Queue count: ${hubQueue.current} Time: ${moment().format('HH:mm:ss.SSS')}`)
      if (!loadingRef.current) {
        handleHubMessage();
      }
    }
  }, [hubMessage]);

  const handleHubMessage = () => {
    console.log(`TSR Calendar refresh ${moment().format('HH:mm:ss.SSS')} Hub Queue count: ${hubQueue.current}`);
    loadingRef.current = true;
    hubQueue.current = 0;
    enqueueSnackbar('Calendar changes detected. Refreshing...', { key: 'hub-refresh-snackbar', preventDuplicate: true, });
    fetch().then(response => {
      console.log(`TSR Calendar fetch complete ${moment().format('HH:mm:ss.SSS')}`);
      if (hubQueue.current > 0) {
        handleHubMessage();
      } else {
        handleSetEventData(response.data);
        loadingRef.current = false;
      }
    });
  }

  async function fetch() {
    const options = {
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_DispatchQueueFetch&parm=${guid}`,
      headers: headers,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`${error.message} URL: ${options.url} ERR: ${error.response?.data ?? ''}`)
    });
  }

  // trigger build

  function handleSetEventData(data) {
    try {
      const eventData = data?.map(submission => {
        const tsrJson = JSON.parse(submission?.CreateTSR_JSON ?? '{}')
        const date = moment(submission.TargetExecutionTime).format('YYYY-MM-DD');
        return {
          //include all the data for the scheduled tsr
          ...submission,
          ...tsrJson,
          Preconfirmed: tsrJson.Preconfirmed === 'Yes',
          flash: false, //reset flash animation

          //the following are props specific to the scheduler component
          id: submission.Id,
          label: !(submission.label) ? `${tsrJson.Provider} Submission - ${moment(submission.TargetExecutionTime).format('HH:mm:ss.SSS')}` : submission.label,
          startHour: moment(submission.TargetExecutionTime).format('HH:mm aa'),
          endHour: moment(submission.TargetExecutionTime).add(1, 'hour').format('HH:mm aa'),
          //description: 'Buy LA Trans at 4PM Everyday',
          groupLabel: tsrJson.Provider,
          date: date,
        }
      });

      setEvents(eventData ?? []);
      /*setEvents(oldEvents => {
        console.log(`TSR Calendar event diff start ${moment().format('HH:mm:ss.SSS')} Old event count: ${oldEvents.length} New Event count: ${eventData.length}`);
        const newOrUpdated = oldEvents.length ? diffData(eventData, oldEvents) : [];
        console.log(`TSR Calendar event diff end ${moment().format('HH:mm:ss.SSS')}`);
        newOrUpdated.forEach(updated => {
          const event = eventData.find(e => e.Id === updated.Id);
          if(event) { event.flash = true; }
        });
        return (eventData ?? []);
      });*/
    } catch (error) {
      enqueueSnackbar(error.message);
      setEvents([]);
    }
  }

  function diffData(arr1, arr2) {
    return _.differenceWith(arr1, arr2, (obj1, obj2) => _.isEqual(
      { ...obj1, flash: false, batchCount: 0, }, //To ignore certain event properties set them to always be the same in these objects
      { ...obj2, flash: false, batchCount: 0 }
    ));
  }

  function formatEventJson(event) {
    return {
      //label: event.label,
      'SequenceId': 1,
      'jsonVersion': 2,
      'Customer_Comments': event.Customer_Comments,
      //'TargetExecutionTime': event.TargetExecutionTime,
      'Provider': event.Provider,
      'Customer Code': event['Customer Code'],
      'Sale Ref': event['Sale Ref'],
      //'Related Ref': '???',
      //'Deal Ref': '???',
      'Path_Name': event.Path_Name,
      'Source': event.Source,
      'Sink': event.Sink,
      'POR': event.POR,
      'POD': event.POD,
      //'Service': event.service,
      'Service_Increment': event.Service_Increment,
      'TS_Class': event.TS_Class,
      'TS_Type': event.TS_Type,
      'TS_Period': event.TS_Period,
      'TS_Window': event.TS_Window,
      'TS_Subclass': event.TS_Subclass,
      'Preconfirmed': event.Preconfirmed ? 'Yes' : 'No',
      'profileInfo': event.profileInfo,
      'profileConfig': event.profileConfig,
      'timezone': event.TimeZone,
      'dispatchQueueRuleID': event.dispatchQueueRuleID,
      'ruleParams': event.ruleParams,
      'requestType': event.requestType,
      'Related Ref': event.relatedRef ?? event['Related Ref'],
    }
  }

  const handleScheduleInsert = useCallback((event, confirmed = 0) => {
    const params = {
      userGuid: guid,
      timeZone: encodeURIComponent(event.TimeZone),
      dispatchQueueRuleID: event.dispatchQueueRuleID ?? '',
      targetExecutionTime: encodeURIComponent(moment(event.TargetExecutionTime).format(formatString)),
      label: event.label ? encodeURIComponent(event.label) : '',
    }

    const options = {
      method: 'POST',
      headers: headers,
      data: formatEventJson(event),
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TSR_DispatchQueue_Insert&parm=${params.userGuid}&parm=${params.timeZone}&parm=${params.dispatchQueueRuleID}&parm=${params.targetExecutionTime}&parm=${params.label}&parm=${confirmed}`,
    }

    setOpenConfirmationDialog(!confirmed);
    setLoadingConfirmation(!confirmed);

    axios(options).then(response => {
      if (confirmed) {
        enqueueSnackbar('TSR dispatch queue updated.');
      } else {
        setConfirmationMessage(response.data);
        setLoadingConfirmation(false);
      }
    }).catch(error => {
      //enqueueSnackbar(`${error.message} URL: ${options.url} ERR: ${error.response?.data ?? ''}`) 
      enqueueSnackbar(error.response?.data ?? '')
      setOpenConfirmationDialog(false);
    });
  }, [guid, headers])

  function handleScheduleUpdate(event, confirmed = 0, processBatch) {
    const params = {
      dispatchQueueId: event.id,
      userGuid: guid,
      timeZone: event.TimeZone,
      dispatchQueueRuleID: event.ruleId ?? undefined,
      targetExecutionTime: moment(event.TargetExecutionTime).format(formatString),
      label: event.label,
      json: formatEventJson(event),
    }

    const options = {
      method: 'POST',
      headers: headers,
      data: params.json,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TSR_DispatchQueue_BatchUpdate&parm=${params.dispatchQueueId}&parm=${params.userGuid}&parm=${encodeURIComponent(params.timeZone)}&parm=${params.dispatchQueueRuleID ?? ''}&parm=${encodeURIComponent(params.targetExecutionTime)}&parm=${encodeURIComponent(params.label)}&parm=${confirmed}&parm=${processBatch ? 1 : 0}`,
    }

    setOpenConfirmUpdate(!confirmed);
    setLoadingConfirmation(!confirmed);

    axios(options).then(response => {
      if (confirmed) {
        enqueueSnackbar('TSR dispatch queue updated.');
      } else {
        setConfirmationMessage(response.data);
        setLoadingConfirmation(false);
      }
    }).catch(error => {
      enqueueSnackbar(`${error.message} URL: ${options.url} ERR: ${error.response?.data ?? ''}`)
      setOpenConfirmUpdate(false);
    });
  }

  function handleScheduleDelete(id, deleteBatch) {
    const params = {
      dispatchQueueId: id,
      userGuid: guid,
    }

    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TSR_DispatchQueue_BatchDelete&parm=${params.dispatchQueueId}&parm=${params.userGuid}&parm=${deleteBatch ? 1 : 0}`,
    }

    axios(options).then(response => {
      enqueueSnackbar('TSR dispatch queue updated.');
      //setEvents(events.filter(e => e.id !== id));
    }).catch(error => {
      enqueueSnackbar(`${error.message} URL: ${options.url} ERR: ${error.response?.data ?? ''}`)
    });
  }

  function handleEdit(event, confirmed = 0, processBatch) {
    setOpenEditDialog(!confirmed);
    if (event.id) { //has id => update
      handleScheduleUpdate(event, confirmed, processBatch);
    } else { //no id => create new
      handleScheduleInsert(event, confirmed);
    }
  }

  const handleClick = useCallback((event) => {
    setCurrentEvent(event);
    setOpenEditDialog(true);
  }, []);

  function handleCopySchedule(event) {
    setOpenEditDialog(false);
    setEventCopy(event);
  }

  return (
    <DivGuard groups={[userGroups.scheduledtsr]}>
      <Box sx={{ display: 'flex' }}>
        {openEditDialog && <EditScheduleDialog
          open={openEditDialog}
          onClose={handleEdit}
          currentEvent={currentEvent}
          onCancel={() => setOpenEditDialog(false)}
          onDelete={handleScheduleDelete}
          confirmationMessage={confirmationMessage}
          openConfirm={openConfirmUpdate}
          loadingConfirmation={loadingConfirmation}
          setOpenConfirm={setOpenConfirmUpdate}
          handleCopy={handleCopySchedule}
        />}
        <TSRScheduler
          eventCopy={eventCopy}
          handleSchedule={handleScheduleInsert}
          confirmationMessage={confirmationMessage}
          openConfirmationDialog={openConfirmationDialog}
          loadingConfirmation={loadingConfirmation}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
        />
        <Box
          sx={{
            //flexGrow: 1,
            width: '100%',
            p: '7px',
          }}
          overflow='auto'
        >
          <TSRCalendar events={events} handleClick={handleClick} />
        </Box>
      </Box>
    </DivGuard>
  )
}
