import { useCallback, forwardRef, useEffect, useState, useRef, useMemo } from 'react';
import { useHubMessages } from './useHubMessages.js';
import moment from 'moment';
import { debounce } from 'lodash';

/*
    Props:
    action: function called when an allowed message has been received and the user moves their mouse, e.g. a data fetch
    allowedMessages: an array of the hub messages to look for
    predicate: a boolean value that if false, will stop the action from being called, e.g. could be false if there is a state variable that action depends on that has not been set
    callbackDependencies: since 'action' 'is wrapped in a useCallback, include the dependencies of 'action'.
*/
export default (action, allowedMessages, predicate=true, callbackDependencies) => {
  const hubMessage = useHubMessages();

  useEffect(() => {
    if (allowedMessages.includes(hubMessage.message) && predicate) {
      window.removeEventListener('mousemove', act)
      window.addEventListener('mousemove', act)
      console.log(`Message ${hubMessage.message} received.`)
    }
  }, [hubMessage]);

  const act = useCallback(debounce(() => {
    window.removeEventListener('mousemove', act)
    action();
  }, 10, { leading: true }), callbackDependencies);
}