import { useState, useCallback, } from 'react';
import QuickScheduleDealConfirmationDialog from './QuickScheduleDealConfirmationDialog';
import debounce from 'lodash/debounce';
import { useApi } from '../../useApi';
import { RibbonButton } from '../Ribbon';
import { useFormContext } from "react-hook-form";
import { Tooltip } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

export const QuickScheduleDealButton = ({ tag, disabled, formId, onRefresh, expanded, gridRef, ...buttonProps }) => {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false });
    const { apiUrlPrefix, headers, get, enqueueSnackbar, logAction, } = useApi();
    const buttonDebounce = 1500;
    const { handleSubmit } = useFormContext();

    const handleQuickScheduleDealCreation = useCallback(debounce(async (confirmed) => {
        setConfirmationDialogProps({ open: false });

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_quickScheduleDealInsert`
            + `&parm=${headers.userGuid}`
            + `&parm=${tag.tagIdx}`
            + `&parm=${tag.flowDateTimeZone}`
            + `&parm=${confirmed}` //string true or false

        return get(url).then(response => {
            if (response.status === 200) {
                if (confirmed === 'true') {
                    enqueueSnackbar(`Schedule and two deals created successfully.`, { variant: 'success' });
                    logAction(`Quick schedule deal creation with tag id ${tag.tagIdx}`, 'Quick Schedule Deal Button');
                    handleSubmit(onRefresh)();
                    gridRef?.current.api?.deselectAll();
                } else { //pop confimation dialog
                    const { data, message } = response; //Ken, here is where you use the response to determine what to show in the dialog
                    const action = 'create schedules and deals';
                    setConfirmationDialogProps({
                        open: true,
                        message: `You are about to ${action} for the selected tag (${tag.tagCode}). ${data[0].warningMessage}  Continue?`,
                        data: data?.[0],
                        onConfirmation: () => handleQuickScheduleDealCreation('true'),
                        onCancel: () => setConfirmationDialogProps({ open: false }),
                    });
                }
            }
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, tag]);

    return (
        <>
            <QuickScheduleDealConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={'Use this button to create a schedule and two deals from the tag. Machine learning is used to determine deal parameters. Deal/schedule profile will be set to the tag profile. (Deal Rizz Platinum)'}>
                <span>
                    <RibbonButton
                        id={`${formId}-refresh`}
                        onMouseDown={() => handleQuickScheduleDealCreation('false')}
                        label="Quick Deals"
                        icon={<RocketLaunchIcon fontSize='small' />}
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    );
}
