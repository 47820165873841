//We need these because Firefox hates us and doesn't support these functions natively for Sets

//calculate A - B
export const difference = (A, B) => {
    const diff = new Set(A);
    for (const elem of B) {
        diff.delete(elem);
    }
    return diff;
}

//calculate A U B
export const union = (A, B) => {
    const union = new Set(A);
    for (const elem of B) {
        union.add(elem);
    }
    return union;
}