import { useMemo, useEffect, useState, forwardRef, useCallback } from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { Button, Box, Select, Stack, MenuItem, Tooltip, } from '@mui/material';
import { useSnackbar } from 'notistack';
import CustomTooltip from './customTooltip.jsx';
import { SmallDetailsToolPanel } from '../ToolPanels/SmallDetailsToolPanel';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from 'ag-grid-react';
import { useData } from '../useData';
import moment from 'moment';
import useMultipleLayouts from '../useMultipleLayouts';
import { stringIntComparator } from '../../utils/gridFunctions';
import useHubAction from '../HubContext/useHubAction_v2';
import useHeader from '../useHeader';
import UndoIcon from '@mui/icons-material/Undo';
import RelinquishDialog from './RelinquishDialog';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const [tsrRowDetails, setTsrRowDetails] = useState({});
  const [showTsrDetails, setShowTsrDetails] = useState(false);
  const { handleMasterTimezoneChange, timezone, visible } = props;
  const { fetch, saveData } = useData();
  const grid = "real-time-tsr";
  const headers = useHeader();
  const [openRelinquishDialog, setOpenRelinquishDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();

  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TSRfetch&parm=${timezone}&parm=${guid}`;

  const handleTimezoneChange = (event) => {
    handleMasterTimezoneChange(event);
  };

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const onRefreshBtn = () => {
    getData(loadUri);
    let message = 'TSRs Refreshing...';
    enqueueSnackbar(message);
  };

  const hubData = useHubAction({
    action: getData,
    allowedMessages: ['tsrGridUpdateTrigger'],
    predicate: (visible && ref.current?.api),
    callbackDependencies: [ref.current?.api, headers],
  });

  function handleDataUpdate(data) {
    const oldData = [];
    ref.current.api.forEachNode(node => oldData.push(node.data));

    const { toAdd, toUpdate, toDelete, diffStart, } = hubData.diffData(data, oldData);

    console.log(`Real Time TSR Grid update diff ended. ${moment().diff(diffStart)}ms elapsed. ${toAdd.length} rows added, ${toUpdate.length} rows updated, ${toDelete.length} rows deleted.`)

    ref.current.api.applyTransaction({
      add: toAdd,
      addIndex: 0,
      update: toUpdate,
      remove: toDelete,
    });
  }

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-fail": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-pass": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-waiting": params => params.api.getValue("Status", params.node) === 'QUEUED'
    },
  };

  const onTsrComponentStateChanged = (params) => {
    if (showTsrDetails) { params.api.openToolPanel('smallDetailsToolPanel') }
  };

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }

  function onTsrCellFocused(e) {

    if (e.rowPinned) {

      //const cell = e.api.getFocusedCell();
      //navigator.clipboard.writeText(e.floating);
      //e.api.copySelectedRangeToClipboard();

      //var focusedCell = gridOptions.api.getFocusedCell();
      //enqueueSnackbar(`${focusedCell} copied to the clipboard`)
      setTsrRowDetails('');

    } else {

      const i = e.rowIndex;
      const row = e.api.rowModel.rowsToDisplay[i];
      if (row?.data) {
        //row.data has the fields mapped to cell values, but we want headerNames mapped to cell values
        //(to make it look nice.) So first we create a mapping of fields to headerNames,
        //then use this to create the headerName:value mapping.
        const colDefs = e.columnApi.columnModel.columnDefs;
        const headerNames = colDefs.reduce(function (names, current) {
          names[current.field] = current.headerName;
          return names;
        }, {}); //headerNames looks like { field: headerName }

        const data = Object.keys(row?.data).reduce(function (data, field) {
          data[headerNames[field]] = row.data[field];
          return data;
        }, {}); //data looks like { headerName: cellValue }

        setTsrRowDetails(data);
      }

      const show = e.api.isToolPanelShowing('smallDetailsToolPanel');
      setShowTsrDetails(show);
    }

  };

  // const detailCellRendererParams = useMemo(() => {
  //   return {
  //     detailGridOptions: {
  //       columnDefs: [
  //         { field: 'TS_Class' },
  //         { field: 'Service_Increment' },
  //         { field: 'TS_Period',  },
  //         { field: 'TS_Window', },
  //         { field: 'TS_Subclass',  },
  //       ],
  //       defaultColDef: {
  //         width: 50,
  //       },
  //     },
  //     getDetailRowData: function (params) {
  //       params.successCallback(params.data);
  //     },
  //   };
  // }, []);

  const baseColDefs = useMemo(() => [
    {
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      initialWidth: "170",
      initialHide: "false",
      cellRenderer: 'agGroupCellRenderer',
      headerName: "ARef",
      field: 'AssignmentRef',
      comparator: stringIntComparator,
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    // {
    //   editable: false,
    //   headerName: "Cnts",
    //   initialWidth: "80",
    //   initialHide: "true",
    //   enableRowGroup: true,
    //   field: "mostUsed" ,
    //   filter: "agMultiColumnFilter",
    //   sortable:  true ,
    // },
    {
      editable: false,
      headerName: "DB",
      enableRowGroup: true,
      initialWidth: "60",
      initialHide: "true",
      field: "OASISInstance",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Status",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      tooltipField: "status",
      field: "Status",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter"
      sortable: true,
    },
    {
      editable: false,
      headerName: "TP",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "Provider",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "SC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Seller Code",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "Customer Code",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Path Name",
      enableRowGroup: true,
      initialWidth: "170",
      initialHide: "true",
      field: "Path_Name",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POR",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfReceipt",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POD",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfDelivery",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Source",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Source",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Sink",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Sink",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Vol",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Volume",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    // {
    //   editable: false,
    //   headerName: "Avail",
    //   enableRowGroup: true,
    //   initialWidth: "80",
    //   initialHide: "true",
    //   field: "Availability" ,
    //   filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    //   sortable:  true ,
    // },
    // {
    //   editable: false,
    //   headerName: "Avail2",
    //   enableRowGroup: true,
    //   initialWidth: "80",
    //   initialHide: "true",
    //   field: "Availability2" ,
    //   filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    //   sortable:  true ,
    // },
    {
      editable: false,
      headerName: "Service Inc",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Service_Increment",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Class",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Class",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Type",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Period",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Period",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Window",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Window",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Subclass",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Subclass",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      //filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "StartUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "StopUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start D",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start Date",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start T",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start Time",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop D",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop Date",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop T",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop Time",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "$$",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Price",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Sale Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Sale Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Request Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Deal Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Posting Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Request Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Related Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Seller Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Pre",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Preconfirmed",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CustName",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CustEmail",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
  ], []);

  //const { viewPanel, saveLayoutLocal, loadLayoutLocal, } = useViewPanel(grid, ref, 'real-time-tsr-grid-layout', colDefs, true);
  const { viewPanel, colDefs, loadLayout, } = useMultipleLayouts(grid, ref, baseColDefs);

  const tsrSideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        {
          id: 'smallDetailsToolPanel',
          labelDefault: 'Details',
          labelKey: 'smallDetailsToolPanel',
          //iconKey: '',
          toolPanel: 'smallDetailsToolPanel',
          toolPanelParams: { rowData: tsrRowDetails },
          minWidth: 100,
          maxWidth: 300,
          width: 200
        },
        viewPanel
      ],
      position: 'right',
    }
  }, [tsrRowDetails, viewPanel]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  function getRowNodeId(params) {
    return params.data.ID;
  }

  async function getData() {
    return fetch(loadUri).then(response => {
      handleDataUpdate(response.data ?? []);
    });
  }

  useEffect(() => {
    getData();
  }, [timezone]);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  function onCellClicked(e) {
    if (e.colDef.colId === 'AssignmentRef') {
      navigator.clipboard.writeText(e.value);
      enqueueSnackbar(`${e.value} copied to the clipboard`)
    }
  }

  function onGridReady(params) {
    loadLayout();
  }

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    setSelectedRows(selected);
  }

  function onRowDataUpdated(params) {
    //we want to capture dynamic changes to the selected row if they are not already relinquishing it in the dialog
    if (!openRelinquishDialog) {
      const selected = params.api.getSelectedRows();
      setSelectedRows(selected);
    }
  }

  const closeRelinquishDialog = useCallback(() => {
    setOpenRelinquishDialog(false);
  }, [])

  return (
    <div className='real-time-grid' style={{ height: '86vh', width: "100%", padding: "5px", }}>
      <RelinquishDialog tsr={selectedRows[0]} open={openRelinquishDialog} closeDialog={closeRelinquishDialog} timezone={timezone} application='Real Time TSRs' />
      <Stack direction="row" spacing={2} sx={{ p: 1, }}>
        <Tooltip title="Refresh the grid of TSRs, retreiving the most recently quaeried TSRs from OASIS." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>
        <Tooltip title="Select the timezone of interest.  It will be used in filtering/formating TSRs in the list below." arrow placement="top">
          <Select
            labelId="timezoneLabel"
            id="timezone"
            value={timezone}
            size={buttonSize}
            color={buttonColor}
            label="Timezone"
            onChange={handleTimezoneChange}
          >
            <MenuItem selected value={'Pacific Standard Time'}>Pacific</MenuItem>
            <MenuItem value={'Eastern Standard Time'}>Eastern</MenuItem>
            <MenuItem value={'Central Standard Time'}>Central</MenuItem>
            <MenuItem value={'Mountain Standard Time'}>Mountain</MenuItem>
          </Select>
        </Tooltip>
        <Button
          endIcon={<UndoIcon />}
          onClick={() => setOpenRelinquishDialog(true)}
          disabled={(!selectedRows.length === 1) || (selectedRows[0]?.RequestType?.toLowerCase() !== 'redirect')}
          //disabled={(!selectedRows.length === 1)} //FOR TESTING ONLY
          size={buttonSize}
          variant={buttonVariant}
          color={buttonColor}
        >Relinquish</Button>
      </Stack>
      <AgGridContainer style={{ flexGrow: 1, }}>
        <AgGridReact
          ref={ref}
          columnDefs={colDefs}
          statusBar={statusBar}
          onGridReady={onGridReady}
          getRowId={getRowNodeId}
          onRowDataUpdated={onRowDataUpdated}
          onSelectionChanged={onSelectionChanged}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          overlayLoadingTemplate={'<span style="width: 70%; font-size: 20px">Please wait.  Loading...</span>'}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          groupDefaultExpanded={true}
          defaultColDef={defaultColDef}
          sideBar={tsrSideBar}
          gridOptions={gridOptions}
          onComponentStateChanged={onTsrComponentStateChanged}
          onCellFocused={onTsrCellFocused}
          onCellClicked={onCellClicked}
          animateRows={true}
          rowDragMultiRow
          rowSelection={"multiple"}
          tooltipShowDelay={0}
          components={{
            customTooltip: CustomTooltip,
            smallDetailsToolPanel: SmallDetailsToolPanel,
            viewToolPanel: GridViewToolPanel,
          }}
        />
      </AgGridContainer>
    </div>
  );
});