import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import AlertCriteriaDialog from './AlertCriteriaDialog';
import DetailCellRenderer from './AlertDetailGrid';
// import { range } from 'lodash';
import { AutocompleteEditor } from '../AutocompleteEditor';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AgGridReact } from 'ag-grid-react';
//import CustomNoRowsOverlay from './customNoRowsOverlay.js';
import { useServiceDropdown } from '../useServiceDropdown';
import 'ag-grid-enterprise';
import { TextField, Box, Grid, MenuList, MenuItem, ListItemIcon, ListItemText, Autocomplete, Checkbox, FormGroup, FormControlLabel, Stack } from '@mui/material';
import { useActionAudit } from '../useActionAudit';
import useHeader from '../useHeader';
import { useData } from '../useData';
import { AddAlert, Refresh } from '@mui/icons-material';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useHubMessages } from '../HubContext/useHubMessages';
import _ from 'lodash';
import moment from 'moment';
import { useIPContext } from '../IPContext/useIPContext';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const { timezone, view, selectedRows } = props;
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const storageLocation = 'TransHunterAlertGrid';
  const headers = useHeader();
  const hubMessage = useHubMessages();
  const ip = useIPContext();

  const [setRowValuesWithService, serviceValues] = useServiceDropdown('Real Time Originals');
  const { logAction } = useActionAudit();
  const { rowData, getData, saveData, setRowData } = useData([]);

  const [openAlertCriteriaDialog, setOpenAlertCriteriaDialog] = useState(false);
  const [postingRefs, setPostingRefs] = useState([])
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_AlertTriggerFetch&parm=${guid}&parm=${timezone}`;
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_AlertTriggerSave&parm=${guid}`;

  useEffect(() => {
    if (view === 'Availability Alerts' && ref.current?.api && ['trg_sysDataAlertTrigger'].includes(hubMessage.message)) {
      console.log(`Hub message received in Alert Grid: ${hubMessage.message} Time: ${moment().format('HH:mm:ss.SSS')}`)
      fetch();
    }
  }, [hubMessage])

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  function fetch() {
    if (ref.current?.api) {
      ref.current.api.showLoadingOverlay();
    }

    const options = {
      method: 'GET',
      headers: headers,
      url: loadUri,
    }

    axios(options).then(response => {
      const newData = response?.data ?? [];
      const oldData = [];
      ref.current.api.forEachNode(node => oldData.push(node.data));

      if (!oldData.length) {
        ref.current.api.applyTransaction({
          addIndex: 0,
          add: newData,
        })
        return;
      }

      const toAddOrUpdate = diffData(newData, oldData);
      const toDeleteOrUpdate = diffData(oldData, newData);
      const toAdd = diffData(toAddOrUpdate, toDeleteOrUpdate);
      const toUpdate = diffData(toAddOrUpdate, toAdd);
      const toDelete = diffData(toDeleteOrUpdate, toUpdate);

      ref.current.api.applyTransaction({
        addIndex: 0,
        add: toAdd,
        update: toUpdate,
        remove: toDelete,
      })
      ref.current && ref.current.api.hideOverlay();
    }).catch(error => {
      enqueueSnackbar(`Error loading data for the alert grid. ${error.message}`)
      ref.current && ref.current.api.hideOverlay();
    });
  };

  function diffData(arr1, arr2) {
    return _.differenceWith(arr1, arr2, _.isEqual);
  }

  function toggleRowActivation() {
    const newData = [];
    ref.current.api.forEachNode(node => {
      if (node.isSelected()) {
        const newRow = { ...node.data, active: !node.data.active };
        newData.push(newRow);
      } else {
        newData.push(node.data);
      }
    });

    save(newData);
  }

  const gridOptions = {
    rowClassRules: {
      "row-expired": params => params.data.status === 'Expired',
      "row-triggered": params => params.data.status === 'Triggered',
      "row-monitoring": params => params.data.status === 'Monitoring',
    },
  };

  function autoSizeAll(skipHeader) {
    const allColumnIds = [];
    ref.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    ref.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  function onRowDataUpdated(params) {
    // autoSizeAll(false);
  }

  function onSelectionChanged(params) {
    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);
  }

  function onCellValueChanged(params) {
    setRowValuesWithService(params);
    params.api.refreshCells();
  }

  const CheckboxCellRenderer = props => {
    return (
      <input
        type='checkbox'
        checked={props.value}
        onChange={(e) => {
          const colId = props.column.colId;
          props.node.setDataValue(colId, e.target.checked);
        }}
      />
    )
  }

  const colDefs = useMemo(() => [
    {
      field: "alertTriggerID",
      headerName: "Trigger ID",
      editable: false,
      sortable: true,
      checkboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      headerCheckboxSelection: true,
      //rowDrag: true,
      //rowGroup: true,
      initialWidth: 150,
      initialHide: false,
      //enableRowGroup: true,
    },
    /*{
      field: "posting_ref",
      headerName:"Posting Ref",
      editable: false,
      sortable: true,
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },*/
    {
      field: "SYSTEM_ELEMENT",
      headerName: "Path Name",
      editable: false,
      sortable: true,
      initialWidth: 300,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    /*{
      field: "SYSTEM_ATTRIBUTE",
      headerName:"Attribute",
      initialWidth: 50,
      editable: false,
      sortable: true,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },*/
    /*{
      field: "ATTRIBUTE_VALUE",
      headerName:"Current Value",
      editable: false,
      sortable: true,
      initialWidth: 50,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },*/
    {
      field: "previousValue",
      headerName: "Previous Value",
      editable: false,
      sortable: true,
      initialWidth: 100,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    {
      field: "targetValue",
      headerName: "Target Value",
      initialWidth: 150,
      sortable: true,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    /*{
      field: "ATTRIBUTE_UNITS",
      headerName:"Units",
      editable: false,
      initialWidth: 50,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },*/
    {
      field: "START_TIME",
      headerName: "Start",
      editable: false,
      sortable: true,
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    {
      field: "STOP_TIME",
      headerName: "Stop",
      editable: false,
      sortable: true,
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
    },
    // {
    //   field: "alertTriggerID",
    //   headerName: "Trigger ID",
    //   initialWidth: 150,
    //   initialHide: false,
    //   filter: "agMultiColumnFilter",
    // },
    {
      //field: "userGuid",
      field: 'userName',
      headerName: "User",
      editable: false,
      sortable: true,
      initialWidth: 200,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    // {
    //   field: "posting_ref",
    //   headerName: "Posting Ref",
    //   initialWidth: 150,
    //   initialHide: false,
    //   filter: "agMultiColumnFilter",
    // },
    // {
    //   field: "dateCreated",
    //   headerName: "Date Created",
    //   initialWidth: 150,
    //   initialHide: false,
    //   filter: "agMultiColumnFilter",
    // },
    {
      field: "active",
      headerName: "Active",
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      cellRenderer: 'checkboxCellRenderer',
      editable: false,
    },
    {
      field: "rolling",
      headerName: "Rolling",
      initialWidth: 100,
      initialHide: false,
      filter: "agMultiColumnFilter",
      cellRenderer: 'checkboxCellRenderer',
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      initialWidth: 150,
      initialHide: false,
      filter: "agMultiColumnFilter",
      editable: false,
    },
    {
      field: "TIME_OF_LAST_UPDATE_UTC",
      headerName: "Last Updated",
      editable: false,
      sortable: true,
      initialWidth: 100,
      initialHide: true,
      filter: "agMultiColumnFilter",
    },
    // {
    //   //field: "atc",
    //   headerName: "History",
    //   cellRenderer: 'agSparklineCellRenderer',
    //   minWidth: 400,
    //   //flex: 1,
    //   editable: false,
    //   cellRendererParams: {
    //     sparklineOptions: {
    //       type: 'area',
    //       axis: {
    //         type: 'time',
    //       },
    //       tooltip: {
    //         renderer: tooltipRenderer
    //       }
    //     },
    //   },
    //   valueGetter: (params) => {
    //     const atcData = JSON.parse(params.data.atcHistory).map(xyArray => [
    //       new Date(xyArray[0]),
    //       xyArray[1]
    //     ]);
    //     return atcData;
    //   },
    // },
    {
      //field: "atc",
      headerName: "Future",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      editable: false,
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
      valueGetter: (params) => {
        const atcData = JSON.parse(params.data?.atcFuture ?? '[]').map(xyArray => [
          new Date(xyArray[0]),
          xyArray[1]
        ]);
        return atcData;
      },
    },
  ], [serviceValues]);

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    return {
      title: xValue.toLocaleString('en-US', { timezone: timezone }),
      conte: yValue
    }
  }

  function onSaveButtonClick() {
    save();
  }

  function save(data) {
    let newData = [];
    if (!data) {
      ref.current.api.forEachNode(node => newData.push(node.data));
    } else {
      newData = data;
    }

    newData.forEach(row => {
      row.rolling = row.rolling ?? false;
    });

    saveData(saveUri, 'Alert triggers saved.', newData).then(response => { fetch(); })
  }

  const originalRequestSideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ],
    position: 'right',
  }), []);

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({ ...props, });
  }, []);

  function handleAddCriteria() {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Please select posting refs to apply alert criteria.');
    } else {
      setPostingRefs(selectedRows.map(path => path.POSTING_REF));
      setOpenAlertCriteriaDialog(true);
    }
  }

  function handleCriteriaSave(limit, usePercent) {
    setOpenAlertCriteriaDialog(false);
    const paths = selectedRows.map(row => row.SYSTEM_ELEMENT);
    const types = selectedRows.map(row => row.SYSTEM_ELEMENT_TYPE);
    const hasDifferentPath = !paths.every(path => path === paths[0]);
    const hasDifferentType = !types.every(type => type === types[0]);

    const newData = [];
    if (hasDifferentPath || hasDifferentType) {
      newData.push(selectedRows.map(function (rowData) {
        const newRow = { ...rowData }
        return newRow;
      }));
    } else {
      newData.push({ ...selectedRows[0], })
    }

    newData.forEach(path => {
      path.targetValue = limit;
      path.status = 'Queued';
      path.userGuid = guid;
      path.active = 1;
      path.posting_ref = path.POSTING_REF;
      path.previousValue = path.ATTRIBUTE_VALUE;
    });

    if (!hasDifferentPath && !hasDifferentType) {
      const refList = selectedRows.reduce((list, next, i) => `${list}${next.POSTING_REF}${i < selectedRows.length - 1 ? ', ' : ''}`, '');
      newData[0].posting_ref = refList;
    }

    logAction(`Alert criteria added`, 'TransAlerts', { IP: ip, newAlerts: newData.map(path => path.posting_ref) });

    let oldData = [];
    ref.current.api.forEachNode(node => oldData.push(node.data));

    //ref.current.api.setRowData([...newData, ...oldData]);
    save([...newData, ...oldData]);
  }

  function onGridReady(params) {
    fetch();
  }

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: "agMultiColumnFilter",
    floatingFilter: true,
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  return (
    <div>
      <AlertCriteriaDialog open={openAlertCriteriaDialog} handleClose={() => setOpenAlertCriteriaDialog(false)} postingRefs={postingRefs} handleSave={handleCriteriaSave} />
      <Stack direction="row" spacing={1} sx={{ p: 1, }}>
        <Tooltip title='Configure alert criteria for selected rows.' arrow placement='top'>
          <Button
            endIcon={<AddAlert />}
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={handleAddCriteria}
          >Add Alert</Button>
        </Tooltip>
        <Tooltip title="Toggle the activation of the currently selected rows." arrow placement="top">
          <Button
            onClick={toggleRowActivation}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Activate/Deactivate</Button>
        </Tooltip>
        <Tooltip title="Retrieve your personalized alert setup list." arrow placement="top">
          <Button
            endIcon={<Refresh />}
            variant={buttonVariant}
            color={buttonColor}
            //disabled={submitButtonDisabled}
            size={buttonSize}
            onClick={fetch}>Refresh</Button>
        </Tooltip>
        <Tooltip title="Save your personalized alert setup list." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            //disabled={submitButtonDisabled}
            size={buttonSize}
            onClick={onSaveButtonClick}>Save Alert Setup</Button>
        </Tooltip>
      </Stack>
      <AgGridContainer
        style={{
          height: "36vh",
          width: "98%",
        }}
      >
        <AgGridReact
          ref={ref}
          columnDefs={colDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          singleClickEdit
          enterMovesDownAfterEdit={true}
          overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">You have no alerts set up.  To set up an alert, choose a path from above and click the Add Alert button.</span>'}
          rowSelection="multiple"
          undoRedoCellEditing={true}
          //enable the next line to left them select the new aref number
          //enableCellTextSelection={true}
          undoRedoCellEditingLimit={20}
          defaultColDef={defaultColDef}
          sideBar={originalRequestSideBar}
          gridOptions={gridOptions}
          tooltipShowDelay={0}
          onRowDataUpdated={onRowDataUpdated}
          onRowSelected={onSelectionChanged}
          suppressRowClickSelection
          enableRangeSelection={true}
          detailCellRenderer={'detailCellRenderer'}
          masterDetail={true}
          detailRowAutoHeight
          //getRowHeight={getRowHeight}
          //onRowClicked={onRowClicked}
          components={{
            autocompleteEditor: AutocompleteEditor,
            checkboxCellRenderer: CheckboxCellRenderer,
            detailCellRenderer: detailCellRenderer,
          }}
        />
      </AgGridContainer>
    </div>
  );
});