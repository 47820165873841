import { Button, CssBaseline, Stack } from "@mui/material"
import Header from "./Header"
import SupportTicketDialog from "./SupportTicketDialog";
import { useState } from "react";

export const NotFound = () => {
    const [supportTicketOpen, setSupportTicketOpen] = useState(false);

    return (
        <div>
            <SupportTicketDialog open={supportTicketOpen} handleClose={() => setSupportTicketOpen(false)} />
            <CssBaseline />
            <Header />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', top: 50 }}>
                <h1>404 Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
                <Stack spacing={2} direction='row'>
                    <Button variant="contained" color="primary" href="/">Go Home</Button>
                    <Button variant="contained" color="primary" onClick={() => setSupportTicketOpen(true)}>Contact Us</Button>
                </Stack>
            </div>
        </div>
    )
}