import { useEffect, useState } from "react";
import { apiUrlPrefix } from "../authConfig";
import useHeader from "./useHeader";
import axios from "axios";
import { useActionAudit } from "./useActionAudit";

export const useUserInfo = () => {
    const headers = useHeader();
    const [userInfo, setUserInfo] = useState({});
    const { logAction } = useActionAudit();

    useEffect(() => {
        headers?.userGuid && userInfoFetch(headers.userGuid).then(response => {
            if (response?.data && response.data[0]) {
                setUserInfo(response.data[0]);
            }
        })
    }, [headers]);

    async function userInfoFetch(guid) {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchUserInformation&parm=${guid}`,
        }

        return axios(options).catch(error => {
            logAction(`Error fetching user info. ${error.message}`, 'User Info');
        })
    }

    return userInfo;
}