import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import { useMsal } from '@azure/msal-react';
import axios from "axios";
import { useState } from "react";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import {apiUrlPrefix, userGroups, subscriptionKey} from '../../authConfig.js';
import useHeader from '../useHeader';

export default function SaveTemplateDialog({templateData, open, handleClose, transmissionTemplateTypeId}) {
    const [templateName, setTemplateName] = useState();
    //const {accounts} = useMsal();
    const guid = useLocalGuid();
    const { enqueueSnackbar } = useSnackbar();
    const json = JSON.stringify(templateData);
    const [invalidName, setInvalidName] = useState(false);
    const headers = useHeader();
    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_TransmissionTemplatePost&parm=${guid}&parm=${transmissionTemplateTypeId}&parm=${templateName}`;

    function handleCloseClick() {
      if(!templateName) {
        setInvalidName(true);
        return;
      }


      axios.post(
        saveUri,
        JSON.stringify(templateData),
        {
          headers: headers,
        }
      ).then( enqueueSnackbar('Template saved.')).catch(error => { enqueueSnackbar(`${error}`) });

      handleClose();
    }

    function updateName(e) {
      setTemplateName(e.target.value);
    }

    return(
        templateData.length > 0
        ? <Dialog
            open={open} 
            onClose={handleClose}
            //fullWidth
            //maxWidth="sm"
          >
            <DialogTitle>Save New Template</DialogTitle>
            <DialogContent>
              <TextField
                value={templateName}
                onChange={updateName}
                error={invalidName}
                helperText={'Name can not be blank.'}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseClick}>{`Save & Close`}</Button>
            </DialogActions>
        </Dialog>
        : <Dialog
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>No Rows Selected</DialogTitle>
            <DialogContent>
              Please select those rows you would like to create a template from.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>OK</Button>
            </DialogActions>
          </Dialog>
    );
};