import { Tooltip } from "@mui/material"
import { useState, } from "react";
import { RibbonButton, RibbonGroupContainer } from "../../Ribbon";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { ScheduleDialog } from "../ScheduleDialog";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useApi } from "../../../useApi";
import { CreateDealButton, EditDealButton, RefreshButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import ConfirmationDialog from '../../../../components/TSRActivity/ConfirmationDialog';
import { useUserGroups } from "../../../../data/useUserGroups";
import { userGroups } from "../../../../authConfig";

export const ActionsGroup = ({ formId, expanded, selectedRows, sourceSinkOptions, scheduleFormId, handleFetchData, handleClearSelections, }) => {
    const { enqueueSnackbar, logAction, get, apiUrlPrefix, headers, } = useApi();
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const { userIsInGroup } = useUserGroups();
    const isPlatinumUser = userIsInGroup(userGroups.dealrizzplatinum);
    const allowSchedule = !!selectedRows.purchases?.length && !!selectedRows.sales?.length;
    const userSelectedOneOfEach = selectedRows.purchases?.length == 1 && selectedRows.sales?.length == 1 && !!selectedRows.tag;
    const allowQuickSchedule = userSelectedOneOfEach;
    const allowQuickScheduleAndDeal = userSelectedOneOfEach && isPlatinumUser;
    const onePurchase = selectedRows.purchases?.length === 1;
    const oneSale = selectedRows.sales?.length === 1;
    const allowEditDeal = !!onePurchase ^ !!oneSale; //exactly one purchase or one sale
    const dealToEdit = selectedRows.purchases[0] ?? selectedRows.sales[0];
    const { watch, handleSubmit } = useFormContext();
    const timezone = watch('timezone');
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false, });

    function confirmQuickSchedule() {
        // insertQuickSchedule();
        // return; //disabling the confirmation for now
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_QuickScheduleInsertConfirmation`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRows.purchases[0].dealID ?? ''}`
            + `&parm=${selectedRows.sales[0].dealID ?? ''}`
            + `&parm=${selectedRows.tag.firstPOR ?? ''}`
            + `&parm=${selectedRows.tag.lastPOD ?? ''}`
            + `&parm=${selectedRows.tag.Source ?? ''}`
            + `&parm=${selectedRows.tag.Sink ?? ''}`
            + `&parm=${selectedRows.tag.tagIdx ?? ''}`
            + `&parm=${timezone ?? ''}`

        get(url).then(response => {
            if (response.status !== 200) { return; }
            const warning = response.data[0];
            setConfirmationDialogProps({
                open: true,
                title: 'Warning',
                message: `${warning.warningMessage} Do you want to continue?`,
                onCancel: () => setConfirmationDialogProps({ open: false, }),
                onConfirmation: insertQuickSchedule,
            });
        });
    }

    async function insertQuickSchedule() {
        setConfirmationDialogProps({ open: false, });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_QuickScheduleInsert_v3`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRows.purchases[0].dealID ?? ''}`
            + `&parm=${selectedRows.sales[0].dealID ?? ''}`
            + `&parm=${selectedRows.tag.firstPOR ?? ''}`
            + `&parm=${selectedRows.tag.lastPOD ?? ''}`
            + `&parm=${selectedRows.tag.Source ?? ''}`
            + `&parm=${selectedRows.tag.Sink ?? ''}`
            + `&parm=${selectedRows.tag.tagIdx ?? ''}`
            + `&parm=${timezone ?? ''}`

        return get(url).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Quick schedule created successfully.', { variant: 'success' });
                logAction('User created quick schedule', 'Deal Rizz Scheduling', { deals: selectedRows });
                handleSubmit(handleFetchData)();
            }
        });
    }

    function confirmInsertFollowTagSchedule() {
        setConfirmationDialogProps({
            open: true,
            title: 'Are you sure?',
            message: `This will create a schedule based on the tag profile. Tag profile changes will effect schedule and deal profiles. Do you want to continue?`,
            onCancel: () => setConfirmationDialogProps({ open: false, }),
            onConfirmation: insertFollowTagSchedule,
        });
    }

    async function insertFollowTagSchedule() {

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_FollowTagScheduleInsert_v4`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRows.purchases[0].dealID ?? ''}`
            + `&parm=${selectedRows.sales[0].dealID ?? ''}`
            + `&parm=${selectedRows.tag.firstPOR ?? ''}`
            + `&parm=${selectedRows.tag.lastPOD ?? ''}`
            + `&parm=${selectedRows.tag.Source ?? ''}`
            + `&parm=${selectedRows.tag.Sink ?? ''}`
            + `&parm=${selectedRows.tag.tagIdx ?? ''}`
            + `&parm=${timezone ?? ''}`

        return get(url).then(response => {
            enqueueSnackbar('Schedule created successfully. Tag profile changes will effect schedule and deal profiles.', { variant: 'success' });
            logAction('User created follow tag schedule', 'Deal Rizz Scheduling', { deals: selectedRows });
        });
    }

    function handleCloseScheduleDialog() {
        setOpenScheduleDialog(false);
        handleClearSelections();
    }

    return (
        <RibbonGroupContainer spacing={1} sx={{ alignItems: 'start' }}>
            <ScheduleDialog
                open={openScheduleDialog}
                handleCancel={() => setOpenScheduleDialog(false)}
                selectedRows={selectedRows}
                formId={scheduleFormId}
                sourceSinkOptions={sourceSinkOptions}
                handleClose={handleCloseScheduleDialog}
                handleRefresh={handleFetchData}
            />

            <ConfirmationDialog
                {...confirmationDialogProps}
            />

            <Tooltip title='Refresh all data in the grids. '>
                <span>
                    <RefreshButton
                        formId={formId}
                        expanded={expanded}
                        label={'Refresh All'}
                        onRefresh={handleFetchData}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Clear the row selections in the grid.">
                <span>
                    <RibbonButton
                        icon={<RemoveDoneIcon fontSize='small' />}
                        label={'Clear Selections'}
                        expanded={expanded}
                        onMouseDown={handleClearSelections}
                    />
                </span>
            </Tooltip>

            <Tooltip title='Select a purchase and a sale below, then click here to create a schedule. '>
                <span>
                    <RibbonButton
                        disabled={!allowSchedule}
                        icon={<CalendarViewMonthIcon fontSize='small' />}
                        label={'Schedule'}
                        expanded={expanded}
                        onMouseDown={() => setOpenScheduleDialog(true)}
                    />
                </span>
            </Tooltip>

            <Tooltip title='Select a purchase, a sale, and a tag to create a quick schedule based on the tag profile. '>
                <span>
                    <RibbonButton
                        disabled={!allowQuickSchedule}
                        icon={<ScheduleSendIcon fontSize='small' />}
                        label={'Quick Schedule'}
                        expanded={expanded}
                        onMouseDown={() => confirmQuickSchedule()}
                    />
                </span>
            </Tooltip>

            <Tooltip title='Select a purchase, a sale, and a tag to create a schedule according to the tag profile.  Also sets the deal profile to the tag profile for the time range of the tag (leaves the rest of the deal profile alone).'>
                <span>
                    <RibbonButton
                        disabled={!allowQuickScheduleAndDeal}
                        icon={<LocalOfferIcon fontSize='small' />}
                        label={'Quick Schedule & Deal'}
                        expanded={expanded}
                        onMouseDown={confirmInsertFollowTagSchedule}
                    />
                </span>
            </Tooltip>

            <CreateDealButton
                expanded={expanded}
                formId={formId}
                label={'Create Deal'}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!allowEditDeal}
                deal={dealToEdit}
                fetchDealData
                timezone={timezone}
                formId={formId}
                label={'Edit Deal'}
            />
        </RibbonGroupContainer>
    )
}
