import { RibbonGroupContainer } from "../../Ribbon";
import { EditDealButton, DealHistoryButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";

export const DealActionsGroup = ({ expanded, formId, selectedRow, }) => {
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <RibbonGroupContainer>

            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />

            <DealHistoryButton
                expanded={expanded}
                deal={selectedRow}
            />

        </RibbonGroupContainer>
    )
}
