import { useNavigate } from 'react-router';
import { useActionAudit } from '../useActionAudit';

export const useCardContent = (content) => {
  const { imageUrl = 'power_people_logo.png', quickLinkUrl } = content;
  const navigate = useNavigate();
  const { logAction } = useActionAudit();
  const blobToken = process.env.REACT_APP_AZURE_BLOB_SAS_TOKEN;
  const storageAccount = process.env.REACT_APP_AZURE_BLOB_STORAGE_ACCOUNT_NAME;
  const blobContainer = 'product-update-images'

  //check if the imageUrl points to a blob file or a url
  let srcUrl;
  if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
    // It's a URL to an external image
    srcUrl = imageUrl;
  } else {
    // It's a blob file
    srcUrl = `https://${storageAccount}.blob.core.windows.net/${blobContainer}/${imageUrl}?${blobToken}`
  }

  const dateCreated = new Date(content.timeDateCreated).toLocaleDateString();

  function handleLinkClick(e) {
    e.stopPropagation();
    navigate('/' + quickLinkUrl);
    logAction(`Clicked link to ${quickLinkUrl}`, 'Product Updates');
  }

  const linkName = quickLinkUrl ?? 'app';

  return {
    linkName,
    handleLinkClick,
    srcUrl,
    dateCreated,
  }
}  