import React, { useState, useEffect, useCallback } from "react";
import ConfirmationDialog from "../ConfirmationDialog";
import renderTimezones from "../renderTimezones";
import Tooltip from '@mui/material/Tooltip';
import { Collapse, FormControl, Button, Toolbar, IconButton, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import RulesDialog from "./RulesDialog";
import ResizingPane from 'react-resizing-pane';
import ProfileDialog from "./ProfileDialog";
import useFilteredOptions from "../useFilteredOptions";
import useRequiredFields from "../useRequiredFields";
import Datetime from "../DateTimePicker";
import DuplicateDialog from "./DuplicateDialog";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useActionAudit } from "../../useActionAudit";
import { debounce } from 'lodash';

const TsrScheduler = (props) => {
  const { handleSchedule, confirmationMessage, openConfirmationDialog, setOpenConfirmationDialog, eventCopy, loadingConfirmation } = props;
  const { logAction } = useActionAudit();
  const [open, setOpen] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [openRulesDialog, setOpenRulesDialog] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const guid = useLocalGuid();
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const storageConfigLocation = 'tsrSchedulerConfig';
  const tsrSchedulerConfig = JSON.parse(localStorage.getItem(storageConfigLocation) ?? '{}');
  const defaultEventData = {
    TimeZone: tsrSchedulerConfig.TimeZone ?? defaultTimeZone(),
    TargetExecutionTime: moment(),
    dispatchQueueRuleID: undefined,
  }
  const [event, setEvent] = useState({
    ...tsrSchedulerConfig,
    ...defaultEventData,
  });
  const { providers, filteredCombos, filteredTSCombos, customerCodes, relatedRefs } = useFilteredOptions(event);
  const { hasMissingFields, isMissing, hasMissing } = useRequiredFields({ TargetExecutionTime: 'Schedule Date', Provider: 'Provider', POR: 'POR', POD: 'POD', 'Customer Code': 'Customer Code', Service_Increment: 'Service Increment', TimeZone: "Timezone", profileInfo: 'Profile', TS_Type: 'TS Type', TS_Period: 'TS Period', TS_Window: 'TS Window', TS_Class: 'TS Class', requestType: 'Request Type' })

  useEffect(handleCopyEvent, [eventCopy])

  function handleChange(name, value) {
    const newEvent = {
      ...event,
      [name]: value,
    }

    localStorage.setItem(storageConfigLocation, JSON.stringify(newEvent));
    setEvent(newEvent);
  }

  function handleCopyEvent() {
    if (eventCopy) {
      const newEvent = {
        ...defaultEventData,
        ...eventCopy,
        TargetExecutionTime: event.TargetExecutionTime, //don't update the scheduler execution time
      }
      setEvent(newEvent);
      localStorage.setItem(storageConfigLocation, JSON.stringify(newEvent));
    }
  }

  function hasInvalidDateTime() {
    const target = event.TargetExecutionTime
    return !(target && target.isValid());
  }

  async function scheduleTSR() {
    const hasMissing = hasMissingFields(event);
    const dateInvalid = hasInvalidDateTime();
    if (dateInvalid) {
      enqueueSnackbar('Schedule date is invalid. Fix date to continue.');
    } else if (hasMissing) {
      enqueueSnackbar(hasMissing);
    } else {
      handleSchedule(event);
    }
  }

  const handleConfirmation = useCallback(debounce(() => {
    setOpenConfirmationDialog(false);
    handleSchedule(event, 1);
    logAction('User confirmed TSR scheduler', 'Timed TSRs', confirmationMessage);
    handleChange('dispatchQueueRuleID', undefined); //reset this value after submitting 
  }, 2000, { leading: true, }), [event, openConfirmationDialog])

  function handleRulesDialogClose(rule) {
    rule && setEvent({
      ...event,
      dispatchQueueRuleID: rule.dispatchQueueRuleID,
      ruleParams: rule.ruleParams,
    })
    setOpenRulesDialog(false);
  }

  function handleProfileUpdate(info, config) {
    const newEvent = {
      ...event,
      profileInfo: info,
      profileConfig: config,
    }

    localStorage.setItem(storageConfigLocation, JSON.stringify(newEvent));
    setEvent(newEvent);
  }

  function handleDuplicateWithAref(tsr) {
    setEvent({
      ...defaultEventData,
      label: '',
      ...tsr,
      POR: tsr.POR ?? tsr.PointOfReceipt,
      POD: tsr.POD ?? tsr.PointOfDelivery,
      Path_Name: tsr.Path_Name ?? tsr.PathName,
      TS_Class: tsr.TS_Class ?? tsr.TSClass,
      TS_Subclass: tsr.TS_Subclass ?? tsr.TSSubclass,
      TS_Type: tsr.TS_Type ?? tsr.TSType,
      TS_Period: tsr.TS_Period ?? tsr.TSPeriod,
      TS_Window: tsr.TS_Window ?? tsr.TSWindow,
      Service_Increment: tsr.Service_Increment ?? tsr.ServiceIncrement,
      'Customer Code': tsr.CustomerCode,
    })
    setOpenDuplicateDialog(false);
  }

  let storageConfig = { name: 'ResizableTsrSchedulerPane', type: localStorage };

  function defaultTimeZone() {
    //const tzs = moment.tz.zonesForCountry('US');
    const guess = moment.tz.guess();
    const tz = moment.tz(moment().toString(), guess).format('z');
    switch (tz.substring(0, 1)) {
      case 'P': return 'US/Pacific';
      case 'M': return 'US/Mountain';
      case 'C': return 'US/Central';
      case 'E': return 'US/Eastern';
      default: return 'US/Pacific';
    }
  }

  function getRelatedRefHelperText() {
    const obj = relatedRefs.find(option => option.AssignmentRef === event.relatedRef);
    return obj ? `POR: ${obj.PointOfReceipt} - POD: ${obj.PointOfDelivery}` : '';
  }

  return (
    <div>
      <DuplicateDialog
        open={openDuplicateDialog}
        onCancel={() => setOpenDuplicateDialog(false)}
        onSubmit={handleDuplicateWithAref}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleConfirmation}
        loading={loadingConfirmation}
        cancelText={'CANCEL'}
        confirmText={'SCHEDULE'}
      />
      <RulesDialog open={openRulesDialog} onClose={handleRulesDialogClose} onCancel={() => setOpenRulesDialog(false)} ruleId={event.dispatchQueueRuleID} />
      {openProfileDialog && <ProfileDialog open={openProfileDialog} closeDialog={() => setOpenProfileDialog(false)} currentEvent={event} handleProfileUpdate={handleProfileUpdate} />}
      <Collapse in={open} collapsedSize='80px' orientation='horizontal'>
        <ResizingPane
          sides={['right']}
          storageId={'tsr-scheduler-resizingpane-config'}
          storageConfig={storageConfig}
          height="100%"
          width={500}
          style={{
            border: '0px solid blue',
            minWidth: 300
          }}
        >
          <Box sx={{ display: 'flex', p: 1 }}>
            <Grid container direction={'column'}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  px: [1],
                }}
              >{open && (<><h3>Schedule a TSR</h3><Box sx={{ flexGrow: 1, }} /></>)}
                <IconButton onClick={() => setOpen(!open)} size="large">
                  {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Toolbar>
              <Divider />
              {open && (
                showMore
                  ? (<Grid container justifyItems='start' alignItems='center' style={{ marginTop: '20px' }} spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        startIcon={<ChevronLeftIcon />}
                        variant="contained"
                        size='small'
                        onClick={() => setShowMore(false)}
                      >Back</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={customerCodes}
                        value={event['Customer Code'] ? event['Customer Code'] : event.CustomerCode}
                        onChange={(_, newValue) => {
                          handleChange('Customer Code', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Customer Code"
                            color="success"
                            placeholder="Customer Code"
                            onChange={(e) => handleChange('Customer Code', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        placeholder="Customer Comments"
                        value={event.Customer_Comments}
                        rows={5}
                        variant='outlined'
                        onChange={(e) => handleChange('Customer_Comments', e.target.value)}
                        label='Customer Comments'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={'Sales Ref'}
                        size='small'
                        fullWidth
                        value={event['Sale Ref'] ? event['Sale Ref'] : event.SaleRef}
                        onChange={(e) => handleChange('Sale Ref', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={relatedRefs.map(obj => obj.AssignmentRef)}
                        value={event.relatedRef}
                        onChange={(_, newValue) => {
                          handleChange('relatedRef', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Related Ref"
                            size='small'
                            color="success"
                            helperText={getRelatedRefHelperText()}
                            onChange={e => handleChange('relatedRef', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>)
                  : (<Grid container justifyContent="center" alignItems='center' style={{ marginTop: '20px' }} spacing={2}>
                    <Grid item xs={7}>
                      <Datetime
                        initialValue={event.TargetExecutionTime}
                        //value={event.TargetExecutionTime}
                        onChange={(newValue) => handleChange('TargetExecutionTime', newValue)}
                        error={hasMissing && isMissing(event, 'TargetExecutionTime')}
                        label='Schedule Date'
                        size='small'
                        fullWidth
                        placeholder={'Schedule Date'}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {/* <Box sx={{justifyContent: 'center'}}> */}
                      <Tooltip title="Click to set additional fields such as customer code." arrow placement="top">
                        <Button
                          startIcon={<ChevronRightIcon />}
                          variant="contained"
                          fullWidth
                          onClick={() => setShowMore(true)}
                        >More</Button>
                      </Tooltip>
                      {/* </Box> */}
                    </Grid>
                    <Grid item xs={2}>
                      <Tooltip title="Click to load a previous TSR" arrow placement="top">
                        <IconButton onClick={() => setOpenDuplicateDialog(true)} size="large" >
                          <ContentCopyIcon />
                        </IconButton>
                        {/*<Button
                          variant="outlined"
                          fullWidth
                          startIcon={<ContentCopyIcon />}
                          onClick={() => setOpenDuplicateDialog(true)}
                        />*/}
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip title="User friendly label for the TSR, optional." arrow placement="top">
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          //error={showErrors && stringIsEmptyOrUndefined(event.label)}
                          label="Label"
                          value={event.label}
                          onChange={(e) => handleChange('label', e.target.value)}
                          color="success"
                          placeholder="Label"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                        value={renderTimezones[event.TimeZone] ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TimeZone', renderTimezones[newValue]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            placeholder="Timezone"
                            error={hasMissing && isMissing(event, 'TimeZone')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="Provider"
                        fullWidth
                        freeSolo
                        options={providers}
                        value={event.Provider ?? ''}
                        onChange={(_, newVal) => { handleChange('Provider', newVal) }}
                        //onInputChange={(_, newVal) => { handleChange('Provider', newVal) }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            error={hasMissing && isMissing(event, 'Provider')}
                            label="Provider"
                            color="success"
                            placeholder="Provider"
                            onChange={(e) => handleChange('Provider', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="POR"
                        fullWidth
                        freeSolo
                        options={filteredCombos.pors}
                        value={event.POR ?? ''}
                        onChange={(e, newValue) => {
                          handleChange('POR', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="POR"
                            color="success"
                            error={hasMissing && isMissing(event, 'POR')}
                            placeholder="POR"
                            onChange={(e) => handleChange('POR', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="POD"
                        fullWidth
                        freeSolo
                        options={filteredCombos.pods}
                        value={event.POD ?? ''}
                        onChange={(e, newValue) => {
                          handleChange('POD', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="POD"
                            error={hasMissing && isMissing(event, 'POD')}
                            onChange={(e) => handleChange('POD', e.target.value)}
                            color="success"
                            placeholder="POD"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="Source"
                        freeSolo
                        fullWidth
                        options={filteredCombos.sources}
                        value={event.Source ?? ''}
                        onChange={(e, newValue) => {
                          handleChange('Source', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Source"
                            color="success"
                            placeholder="Source"
                            onChange={(e) => handleChange('Source', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        id="Sink"
                        freeSolo
                        fullWidth
                        options={filteredCombos.sinks}
                        value={event.Sink ?? ''}
                        onChange={(event, newValue) => {
                          handleChange('Sink', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Sink"
                            color="success"
                            placeholder="Sink"
                            onChange={(e) => handleChange('Sink', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        options={filteredCombos.paths}
                        value={event.Path_Name ?? ''}
                        freeSolo
                        onChange={(_, newValue) => {
                          handleChange('Path_Name', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Path Name"
                            color="success"
                            placeholder="Path Name"
                            onChange={(e) => handleChange('Path_Name', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} justifyContent='flex-start'>
                      <Autocomplete
                        options={filteredTSCombos.serviceIncrements}
                        value={event.Service_Increment ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('Service_Increment', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Service Increment"
                            size='small'
                            error={hasMissing && isMissing(event, 'Service_Increment')}
                            color="success"
                            placeholder="Service Increment"
                            onChange={(e) => handleChange('Service_Increment', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={filteredTSCombos.tsClasses}
                        value={event.TS_Class ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TS_Class', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Class"
                            color="success"
                            placeholder="TS Class"
                            error={hasMissing && isMissing(event, 'TS_Class')}
                            onChange={(e) => handleChange('TS_Class', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={filteredTSCombos.tsTypes}
                        value={event.TS_Type ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TS_Type', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Type"
                            color="success"
                            placeholder="TS Type"
                            error={hasMissing && isMissing(event, 'TS_Type')}
                            onChange={(e) => handleChange('TS_Type', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={filteredTSCombos.tsPeriods}
                        value={event.TS_Period ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TS_Period', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Period"
                            color="success"
                            placeholder="TS Period"
                            error={hasMissing && isMissing(event, 'TS_Period')}
                            onChange={(e) => handleChange('TS_Period', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={filteredTSCombos.tsWindows}
                        value={event.TS_Window ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TS_Window', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Window"
                            color="success"
                            placeholder="TS Window"
                            error={hasMissing && isMissing(event, 'TS_Window')}
                            onChange={(e) => handleChange('TS_Window', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        freeSolo
                        options={filteredTSCombos.tsSubclasses}
                        value={event.TS_Subclass ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('TS_Subclass', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Subclass"
                            color="success"
                            placeholder="TS Subclass"
                            onChange={(e) => handleChange('TS_Subclass', e.target.value)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        options={['Original', 'Redirect']}
                        value={event.requestType ?? ''}
                        onChange={(_, newValue) => {
                          handleChange('requestType', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Request Type"
                            color="success"
                            error={hasMissing && isMissing(event, 'requestType')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth size="small">
                        <FormControlLabel label={'Pre-confirmed'} control={
                          <Checkbox checked={event.Preconfirmed} onChange={() => handleChange('Preconfirmed', !event.Preconfirmed)} />
                        } />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => setOpenProfileDialog(true)}
                        variant="contained"
                        fullWidth
                      >Set Profile</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={() => setOpenRulesDialog(true)}
                        variant="contained"
                        fullWidth
                      >Set Rules</Button>
                    </Grid>
                    <Grid style={{ marginTop: "10px" }} item xs={12}>
                      <Button
                        onClick={scheduleTSR}
                        variant="contained"
                        fullWidth
                      >
                        Schedule TSR
                      </Button>
                    </Grid>
                  </Grid>)
              )}
            </Grid>
          </Box>
        </ResizingPane>
      </Collapse>
    </div>
  )
}

export default React.memo(TsrScheduler);