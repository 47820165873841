import { Button, Grid, InputAdornment, TextField, useTheme } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { renderDayjsTimezones } from '../../../../utils/renderTimezones';
import FormAutocomplete from '../../../FormControls/FormAutocomplete';
import ConfirmationDialog from '../../../TSRActivity/ConfirmationDialog';

export const ProfileEditingForm = ({ disabled, setProfile, handleUpdateProfile, }) => {
    const { register, formState: { errors, }, getValues, setValue, control, watch, trigger, } = useFormContext();
    const indexType = watch('indexType');
    const dynamic = watch('dynamic');
    const term = watch('Term');
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false, });
    const theme = useTheme();
    const [start, setStart] = useState(getValues('startDateTime'));
    const [stop, setStop] = useState(getValues('stopDateTime'));

    const dateRangeWarnings = (key) => [
        { //user truncated start date
            message: 'You are shortening your profile and will lose time blocks. The profile you are editing here will replace the ENTIRE previous profile so it is important that all hours of your profile are represented here. Continue?',
            condition: (oldValue, newValue) => key === 'startDateTime' && newValue.isAfter(oldValue),
        },
        { //user truncated stop date
            message: 'You are shortening your profile and will lose time blocks.  The profile you are editing here will replace the ENTIRE previous profile so it is important that all hours of your profile are represented here. Continue?',
            condition: (oldValue, newValue) => key === 'stopDateTime' && newValue.isBefore(oldValue),
        },
        { //user extended start date
            message: 'You are extending the length of your profile.  The profile you are editing here will replace the ENTIRE previous profile so it is important that all hours of your profile are represented here. Continue?',
            condition: (oldValue, newValue) => key === 'startDateTime' && newValue.isBefore(oldValue),
        },
        { //user extended stop date
            message: 'You are extending the length of your profile.  The profile you are editing here will replace the ENTIRE previous profile so it is important that all hours of your profile are represented here. Continue?',
            condition: (oldValue, newValue) => key === 'stopDateTime' && newValue.isAfter(oldValue),
        },
    ]

    function confirmDateRangeUpdate(key, newValue) {
        const oldValue = key === 'startDateTime' ? start : stop;
        const warning = dateRangeWarnings(key).find(warning => warning.condition(oldValue, newValue));

        if (warning) {
            setConfirmationDialogProps({
                open: true,
                message: warning.message,
                onConfirmation: () => {
                    handleDateUpdate(key, newValue);
                    setConfirmationDialogProps({ open: false });
                },
                onCancel: () => {
                    setConfirmationDialogProps({ open: false });
                },
            });
        } else {
            handleDateUpdate(key, newValue);
        }
    }

    async function updateProfile(formState) {
        const validated = await trigger();

        if (validated) {
            handleUpdateProfile(formState);
        }
    }

    function setStartValue(value) {
        setValue('startDateTime', value);
        setStart(value);
    }

    function setStopValue(value) {
        setValue('stopDateTime', value);
        setStop(value);
    }

    function handleDateUpdate(key, value) {
        //make sure start date is before stop date
        if ((key === 'startDateTime' && dayjs(stop).isBefore(value)) || (key === 'stopDateTime' && dayjs(start).isAfter(value))) {
            setStartValue(value);
            setStopValue(value);
            updateProfile({
                ...getValues(),
                startDateTime: value,
                stopDateTime: value,
            });
        } else {
            key === 'startDateTime' ? setStartValue(value) : setStopValue(value);
            updateProfile({
                ...getValues(),
                [key]: value,
            });
        }
    }

    function handleTimezoneUpdate(oldTz, newTz) {
        setValue('timezone', newTz);
        const offset = (dayjs().tz(newTz).utcOffset() - dayjs().tz(oldTz).utcOffset()) / 60;
        const newStart = dayjs(getValues('startDateTime')).add(offset, 'hours').startOf('hour');
        const newStop = dayjs(getValues('stopDateTime')).add(offset, 'hours').startOf('hour');
        setStartValue(newStart);
        setStopValue(newStop);
        updateProfile({
            ...getValues(),
            startDateTime: newStart,
            stopDateTime: newStop,
            oldTimezone: oldTz,
            timezone: newTz,
        });
    }

    const datePickerLayoutSlotProp = {
        sx: {
            ul: {
                '::-webkit-scrollbar': {
                    width: '10px',
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.dark,
                    borderRadius: '20px',
                    border: '3px solid transparent',
                    backgroundClip: 'padding-box',
                },
                '::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '::-webkit-scrollbar-thumb:hover': {
                    border: 0,
                },
            },
        },
    }

    return (
        <Grid container alignItems='center' sx={{ p: 2, }} spacing={2} columns={10}>
            <ConfirmationDialog {...confirmationDialogProps} />

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        value={start}
                        label="Profile Start"
                        ampm={false}
                        onChange={(e) => {
                            //prevent the date picker from updating the form state through the onChange event
                        }}
                        onAccept={(newValue) => confirmDateRangeUpdate('startDateTime', newValue?.startOf('hour'), setStartValue)}
                        disabled={disabled}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                size: 'small',
                                value: start,
                                error: !!errors.startDateTime,
                                helperText: errors.startDateTime?.message,
                                onBlur: (e) => {
                                    confirmDateRangeUpdate('startDateTime', dayjs(e.target.value).startOf('hour'), setStartValue);
                                }
                            },
                            layout: datePickerLayoutSlotProp,
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        value={stop}
                        label="Profile Stop"
                        ampm={false}
                        onChange={(e) => {
                            //prevent the date picker from updating the form state through the onChange event
                        }}
                        onAccept={(newValue) => confirmDateRangeUpdate('stopDateTime', newValue?.startOf('hour'), setStopValue)}
                        disabled={disabled}
                        views={['year', 'month', 'day', 'hours']}
                        format="MM/DD/YYYY HH:mm"
                        slotProps={{
                            textField: {
                                value: stop,
                                size: 'small',
                                error: !!errors.stopDateTime,
                                helperText: errors.stopDateTime?.message,
                                onBlur: (e) => {
                                    confirmDateRangeUpdate('stopDateTime', dayjs(e.target.value).startOf('hour'), setStopValue);
                                }
                            },
                            layout: datePickerLayoutSlotProp,
                        }}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item md={2}>
                <FormAutocomplete
                    autoHighlight
                    openOnFocus
                    disableClearable
                    disabled={disabled || term?.toLowerCase().includes('rockies')}
                    options={['America/Detroit', 'America/Chicago', 'America/Denver', 'America/Los_Angeles',]}
                    getOptionLabel={(option) => renderDayjsTimezones[option]}
                    onChange={(e, newValue) => {
                        const oldValue = getValues('timezone');
                        handleTimezoneUpdate(oldValue, newValue);
                    }}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Prevailing Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                        />
                    )}
                />
            </Grid>

            <Grid item md={2}>
                <TextField
                    {...register('adder')}
                    onBlur={e => {
                        const value = e.target.value;
                        setValue('adder', value ? parseFloat(value).toFixed(2) : '');
                    }}
                    label="Adder"
                    size='small'
                    fullWidth
                    disabled={(indexType === 'None') || !dynamic || disabled}
                    error={!!errors.adder}
                    helperText={errors.adder?.message}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">$</InputAdornment>,
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        setValue('Term', '');
                        setProfile([]);
                    }}
                    disabled={disabled}
                >Clear All</Button>
            </Grid>

        </Grid>
    );
}
