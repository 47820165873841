import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { LayoutToolPanel } from '../../ToolPanels/LayoutToolPanel'
import moment from 'moment'
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact } from 'ag-grid-react'
import useStalkerGridLayout from '../Stalker/useStalkerGridLayout'
import { AgGridContainer } from '../../AgGrid/AgGridContainer'

export default (props) => {
  const { events, options, searchResult, onTaskClick } = props
  const ref = useRef();
  const storageLocation = `tsr-scheduler-grid-mode-layout`;

  let filteredEvents = events;//rows?.sort((a, b) => -b?.startHour?.localeCompare(a?.startHour))
  if (searchResult) {
    filteredEvents = events?.filter(
      event => event?.groupLabel === searchResult?.groupLabel
    )
  }

  // alert(JSON.stringify(filteredEvents))

  function onCellClicked(event) {
    onTaskClick(event, event.data);
  }

  const autoSizeAll = useCallback((skipHeader) => {

    const allColumnIds = [];
    ref.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    ref.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);

  }, []);

  const baseColDefs = useMemo(() => [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'date',
      headerName: 'Schedule Date',
      //rowGroup: true,
      valueFormatter: params => `${moment(params.value).format('MM/DD/YYYY HH:mm:ss.ssss')}`
    },
    {
      field: 'TimeZone',
      headerName: 'Timezone',
    },
    {
      field: 'ExecutedAtUTC',
      headerName: 'Exected At',
    },
    {
      field: 'CompletedAtUTC',
      headerName: 'Completed At',
    },
    {
      field: 'timeQueued',
      headerName: 'Queued At',
    },
    {
      field: 'label',
      headerName: 'Label',
    },
    {
      field: 'userName',
      headerName: 'User Name',
    },
    {
      field: 'tsrStatus',
      headerName: 'TSR Status',
    },
    {
      field: 'DispatchQueueStatus',
      headerName: 'Q Status',
    },
    {
      field: 'Provider',
      headerName: 'Provider',
    },
    {
      field: 'POR',
      headerName: 'POR',
    },
    {
      field: 'POD',
      headerName: 'POD',
    },
    {
      field: 'Source',
      headerName: 'Source',
    },
    {
      field: 'Sink',
      headerName: 'Sink',
    },
    {
      field: 'Path_Name',
      headerName: 'Path',
    },
    {
      field: 'Preconfirmed',
      headerName: 'PreConfirmed',
    },
  ], [])

  const { layoutPanel, colDefs, loadLayout } = useStalkerGridLayout(storageLocation, ref, baseColDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        },
        layoutPanel,
      ],
      position: 'right',
    }
  }, [colDefs]);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: "agMultiColumnFilter",
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  function onRowDataUpdated(params) {
    loadLayout();
    autoSizeAll(false);
  }

  return (
    <AgGridContainer
      style={{
        width: "100%",
        height: '82vh',
      }}
    >
      <AgGridReact
        ref={ref}
        rowData={filteredEvents}
        columnDefs={colDefs}
        sideBar={sideBar}
        //groupDisplayType='groupRows'
        groupDefaultExpanded
        onCellClicked={onCellClicked}
        onRowDataUpdated={onRowDataUpdated}
        defaultColDef={defaultColDef}
        components={{
          layoutToolPanel: LayoutToolPanel,
        }}
      />
    </AgGridContainer>
  )
}