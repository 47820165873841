import { useEffect, useState, useRef, useMemo } from 'react';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/conditionalGridStyles.css'
import Box from '@mui/material/Box';
import { ProfileToolPanel } from '../ToolPanels/ProfileToolPanel';
import { ServiceToolPanel } from '../ToolPanels/ServiceToolPanel';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { ProfilePicker } from '../profiles/ProfilePicker';
import { useSnackbar } from 'notistack';
import RowDataTemplatePanel from '../ToolPanels/RowDataTemplatePanel'
import SaveTemplateDialog from './SaveTemplateDialog';
import Tooltip from '@mui/material/Tooltip';
import DivGuard from '../../components/Guards/DivGuard.js';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { MenuItem, Select, } from "@mui/material";
import { Profiles } from './Profiles';
import { useProfilePanel } from '../ToolPanels/useProfilePanel'
import { useServiceDropdown } from '../useServiceDropdown';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import { useLocalData } from '../useLocalData';
import { useViewPanel } from '../ToolPanels/useViewPanel';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { useGridButtons } from '../useGridButtons';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels'
import { AgGridReact } from 'ag-grid-react';
import useHeader from '../useHeader';
import GroupGuard from '../Guards/GroupGuard.js';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';


const BulkOriginalRequests = () => {

  const guid = useLocalGuid();
  const storageLocation = 'bulk-original-request-grid-saved-layout';
  const [timezone, setTimezone] = useState('Pacific Standard Time');
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const gridRef = useRef();
  useLocalData(storageLocation, gridRef)
  const [updateCurrentRow, profilePanel, setRowFromProfile] = useProfilePanel(gridRef);
  const [setRowValuesWithService, serviceValues] = useServiceDropdown('Real Time Originals');
  const transmissionTemplateTypeId = 1;
  const [templateData, setTemplateData] = useState([]);
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const gridName = 'bulk-original-requests'
  const { viewPanel, loadLayoutLocal } = useViewPanel(gridName, gridRef);
  const { ExportButton, NewRowButton, RemoveSelectedButton } = useGridButtons({ gridRef });
  const headers = useHeader();

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  function onGridReady(params) {
    const rowCount = params.api.rowModel.rowsToDisplay.length;
    if (rowCount === 0) {
      params.api.showNoRowsOverlay();
    }
    loadLayoutLocal();
  }

  function onRowDataUpdated(params) {
    const rowCount = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowCount === 0);
  }

  function onSelectionChanged(params) {
    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);
  }

  async function onSaveDataButtonClick() {
    gridRef.current.api.stopEditing();
    gridRef.current.api.showLoadingOverlay();
    let message = 'Submitting original request to OASIS...';
    enqueueSnackbar(message);

    const rowData = [];

    gridRef.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      newData['New Aref'] = "";
      newData['Status'] = "";
      newData['Result'] = "";
      rowData.push(newData);
    })

    axios.post(
      `${apiUrlPrefix}/CrystalBall/TSR/BulkCreateDayAhead?UserGuid=${guid}&timeZone=${renderTimezone(timezone)}&Caller=PowerStation.BulkOriginals`,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).then(response => {
      if (typeof response.data === 'string') { //assume error
        message = `Failed to submit. ${response.data}`;
        enqueueSnackbar(message);
      } else {
        gridRef.current.api.setRowData(response.data);
        gridRef.current.api.forEachNode(function (node) {
          node.setSelected(node.data.isSelected);
        })
        message = 'Original request sent to OASIS database...';
        enqueueSnackbar(message);
      }

    }).catch(err => function () { message = `Error saving the original request grid data.  Status: ${err.response?.status}. Message: ${err}` });

    gridRef.current.api.hideOverlay();

  }

  async function onTestCertClick() {

    let message = 'Submitting original request to OASIS...';
    //enqueueSnackbar(message);

    const rowData = `Version=2.2
TEMPLATE=transassign
OUTPUT_FORMAT=Data
PRIMARY_PROVIDER_CODE=BPAT
PRIMARY_PROVIDER_DUNS=959010968
RETURN_TZ=PS
DATA_ROWS=3
COLUMN_HEADERS=CONTINUATION_FLAG,SELLER_CODE,SELLER_DUNS,CUSTOMER_CODE,PATH_NAME,POINT_OF_RECEIPT,POINT_OF_DELIVERY,SOURCE,SINK,PRECONFIRMED,CAPACITY_REQUESTED,CAPACITY_GRANTED,SERVICE_INCREMENT,TS_CLASS,TS_TYPE,TS_PERIOD,TS_WINDOW,TS_SUBCLASS,STATUS_NOTIFICATION,START_TIME,STOP_TIME,BID_PRICE,OFFER_PRICE,ANC_SVC_LINK,POSTING_NAME,REASSIGNED_REF,REASSIGNED_CAPACITY,REASSIGNED_START_TIME,REASSIGNED_STOP_TIME,SELLER_COMMENTS,SALE_REF,REQUEST_TYPE,RELATED_REF,DEAL_REF,POSTING_REF,REQUEST_REF,PREEMPTION_WAIVED
"N","GHPD","960506822","LEWI","","CENTRALIA","GHPUD","","","No","0","0","DAILY","FIRM","POINT_TO_POINT","FULL_PERIOD","EXTENDED","MONTHLY","","20220303000000PS","20220303060000PS","0","0","","TEA_OATI","96197693","0","20220303000000PS","20220303060000PS","","14701","RESALE","","","","",""
"Y","","","","","","","","","","15","15","","","","","","","","20220303060000PS","20220303220000PS","2","2","","","96197693","15","20220303060000PS","20220303220000PS","","","","","","","",""
"Y","","","","","","","","","","0","0","","","","","","","","20220303220000PS","20220304000000PS","0","0","","","96197693","0","20220303220000PS","20220304000000PS","","","","","","","",""`;

    const passwd = 'Transit2020>>'
    //const url = `https://demoapp.oasis.oati.com/OASIS/BPAT/data/transassign?user=DemoPOsoasis&passwd=${encodeURIComponent(passwd)}&primary_provider_code=BPAT&primary_provider_duns=959010968&fmt=data&Return_TZ=PS&TEMPLATE=transassign`

    //const url = 'https://app.oasis.oati.com/OASIS/MISO/data/transStatus?fmt=data&Return_TZ=PS&version=2.0&template=transStatus&primary_provider_code=BPAT&primary_provider_duns=959010968&ASSIGNMENT_REF=12345&User=DemoPOsoasis&Passwd=${encodeURIComponent(passwd)}'  
    const url = 'https://www.google.com'

    enqueueSnackbar(url);

    //https://app.oasis.oati.com/OASIS/BPAT/data/transrequest?user=xxxx&passwd=yyyy&primary_provider_code=BPAT&primary_provider_duns=959010968&fmt=data&Return_TZ=PS&version=2.0&template=TransRequest&Seller_code=BPAT&Customer_code=CORP&Source=&Sink=&Point_of_receipt=SLATT230&Point_of_delivery=SNOHOMISH&Request_Type=Redirect&Preconfirmed=YES&Service_Increment=HOURLY&TS_Class=SECONDARY&TS_SubClass=&TS_Type=POINT_TO_POINT&TS_Period=FULL_PERIOD&TS_Window=FIXED&Nerc_Curtailment_Priority=1&Sale_Ref=10286&Start_Time=20211207230000PS&Stop_Time=20211208230000PS&Capacity_Requested=0&Bid_Price=0&Related_Ref=89965615


    const myHeaders = new Headers({
      //'Content-Type': 'text/plain',
      'Content-Type': 'text/x-oasis-csv',
    });

    const myOptions = {
      method: "GET",
      //body: rowData,
      mode: 'no-cors',
      //credentials: 'include',
      //headers: myHeaders,
    }

    // fetch(
    //   url, 
    //   myOptions
    // ).then(response => response.json())
    // .then(data => {
    //     enqueueSnackbar(`Success: ${JSON.stringify(data)}`);
    // })
    // .catch(err => {
    //   enqueueSnackbar(`Errpr: ${err}`);
    //   enqueueSnackbar(`Input: ${rowData}`);
    // });


    axios.post(
      url,
      rowData,
      {
        headers: {
          'Content-Type': 'text/x-oasis-csv',
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization",
        }
      }
    ).then(response => {
      enqueueSnackbar(JSON.stringify(response.data));
      message = 'Original request sent to OASIS database...';
      enqueueSnackbar(message);
    }).catch(err => function () {
      message = `Error submitting the original request.  Status: ${err.response?.status}. Message: ${err}`;
      enqueueSnackbar(message);
    });



  }

  function addRow(data) {
    const res = gridRef.current.api.applyTransaction({
      add: data,
    });
  };

  const addRowCopy = (addIndex) => {
    gridRef.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = gridRef.current.api.getSelectedRows();
    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      return newRow;
    });
    const res = gridRef.current.api.applyTransaction({
      add: data,
    });
  };

  function onSaveTemplateClick() {
    const api = gridRef.current.api;
    const data = api.getSelectedNodes().map(node => node.data);
    setTemplateData(data);
    setSaveTemplateOpen(true);
  };

  function handleSaveTemplateClose() {
    setSaveTemplateOpen(false);
  };

  const sideBar = {
    toolPanels: [
      columnPanel,
      profilePanel,
      {
        id: 'templates',
        labelDefault: 'Templates',
        labelKey: 'templates',
        //iconKey: '',
        toolPanel: 'templateToolPanel',
        toolPanelParams: {
          addGridRows: addRow,
          transmissionTemplateTypeId: transmissionTemplateTypeId,
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
      },
      viewPanel
    ],
    position: 'right',
  };

  // enables the fill handle
  const enableFillHandle = true;

  // enables undo / redo
  const undoRedoCellEditing = true;

  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-fail": params => params.api.getValue("status", params.node) === 'REFUSED',
      "row-pass": params => params.api.getValue("status", params.node) === 'CONFIRMED',
      "row-waiting": params => params.api.getValue("status", params.node) === 'QUEUED'
    },
  };

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const colDefs = [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      tooltipField: "Result",
      field: "Status",
    },
    {
      headerName: "Provider",
      initialHide: false,
      field: "Provider",
    },
    {
      headerName: "Customer Code",
      initialHide: false,
      field: "Customer Code",
    },
    {
      headerName: "Sale Ref",
      initialHide: false,
      field: "Sale Ref",
    },
    {
      headerName: "Related Ref",
      initialHide: true,
      field: "Related Ref",
    },
    {
      headerName: "Deal Ref",
      initialHide: true,
      field: "Deal Ref",
    },
    {
      headerName: "Posting Ref",
      initialHide: true,
      field: "Posting Ref",
    },
    {
      headerName: "Request Ref",
      initialHide: true,
      field: "Request Ref",
    },
    {
      headerName: "Path_Name",
      initialHide: true,
      field: "Path_Name",
    },
    {
      headerName: "Source",
      field: "Source",
    },
    {
      headerName: "Sink",
      field: "Sink",
    },
    {
      headerName: "POR",
      field: "POR",
    },
    {
      headerName: "POD",
      field: "POD",
    },
    {
      headerName: "Service",
      field: "Service",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: () => { return serviceValues; },
      cellEditorPopup: true,
      //valueSetter: setRowValuesWithService, 
    },
    {
      headerName: "Service_Increment",
      field: "Service_Increment",
    },
    {
      headerName: "TS_Class",
      field: "TS_Class",
    },
    {
      headerName: "TS_Type",
      field: "TS_Type",
    },
    {
      headerName: "TS_Period",
      field: "TS_Period",
    },
    {
      headerName: "TS_Window",
      field: "TS_Window",
    },
    {
      headerName: "TS_Subclass",
      field: "TS_Subclass",
    },
    {
      headerName: "Preconfirmed",
      enableRowGroup: true,
      field: "Preconfirmed",
    },
    {
      headerName: "Profile",
      field: "Profile",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: [...Object.keys(Profiles), 'Custom']
      },
      cellEditorPopup: true,
      valueSetter: setRowFromProfile,
    },
    {
      cellEditor: "datePicker",
      cellEditorPopup: true,
      headerName: "Start",
      initialHide: false,
      field: "Start",
    },
    {
      headerName: "Stop",
      cellEditor: "datePicker",
      cellEditorPopup: true,
      initialHide: false,
      field: "Stop",
    },
    {
      headerName: "Volume",
      enableRowGroup: true,
      field: "Capacity Requested",
    },
    {
      headerName: "Bid Price",
      enableRowGroup: true,
      field: "Bid Price",
    },
    {
      headerName: "Hour Ending",
      enableRowGroup: true,
      field: "Hour Ending",
    },
    ...[...Array(25).keys()].map(idx => {
      idx++; //1 based index, no HE0,
      return { initialHide: true, headerName: `HEP${idx}`, field: `HEP${idx}` };
    }),
    ...[...Array(25).keys()].map(idx => {
      idx++; //1 based index, no HE0,
      return { initialHide: true, headerName: `HEC${idx}`, field: `HEC${idx}` };
    })
  ]

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: false,
    sortable: false,
    resizable: true,
  }), [])

  return (
    <DivGuard groups={[userGroups.bulkoriginalrequests]} >
      <AgGridContainer
        style={{
          height: "82vh",
          width: "98%"
        }}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title="Select the timezone of interest.  It will be used when submitting original requests to OASIS." arrow placement="top">
            <Select
              labelId="timezoneLabel"
              id="timezone"
              value={timezone}
              size={buttonSize}
              color={buttonColor}
              label="Timezone"
              onChange={handleTimezoneChange}
            >
              <MenuItem selected value={'Pacific Standard Time'}>Pacific</MenuItem>
              <MenuItem value={'Eastern Standard Time'}>Eastern</MenuItem>
              <MenuItem value={'Central Standard Time'}>Central</MenuItem>
              <MenuItem value={'Mountain Standard Time'}>Mountain</MenuItem>
            </Select>
          </Tooltip>&nbsp;&nbsp;
          <Tooltip title="Add a new blank row to the grid." arrow placement="top">
            <NewRowButton />
          </Tooltip>&nbsp;
          <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
            <Button
              endIcon={<AddCircleOutlinedIcon />}
              id="addRowCopy"
              disabled={submitButtonDisabled}
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
              onClick={addRowCopy}
            >Add New Copy</Button>
          </Tooltip>&nbsp;
          <Tooltip title="Delete the currently selected row." arrow placement="top">
            <RemoveSelectedButton disabled={submitButtonDisabled} />
          </Tooltip>&nbsp;
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <ExportButton disabled={buttonsDisabled} />
          </Tooltip>&nbsp;
          <Tooltip title="Create a template from the currently selected row." arrow placement="top">
            <Button
              variant={buttonVariant}
              endIcon={<SaveIcon />}
              disabled={submitButtonDisabled}
              color={buttonColor}
              size={buttonSize}
              onClick={onSaveTemplateClick}>Save Template</Button>
          </Tooltip>&nbsp;
          <Tooltip title="Submit all rows to OASIS for processing." arrow placement="top">
            <Button
              variant={buttonVariant}
              endIcon={<PublishIcon />}
              disabled={submitButtonDisabled}
              color={buttonColor}
              size={buttonSize}
              onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
          </Tooltip>&nbsp;
          <GroupGuard groups={[userGroups.support]}>
            <Tooltip title="Test the OASIS API with Certs" arrow placement="top">
              <Button
                variant={buttonVariant}
                endIcon={<PublishIcon />}
                //disabled={submitButtonDisabled}
                color={buttonColor}
                size={buttonSize}
                onClick={onTestCertClick}>Test</Button>
            </Tooltip>&nbsp;
          </GroupGuard>
        </Box>
        <SaveTemplateDialog
          templateData={templateData}
          open={saveTemplateOpen}
          handleClose={handleSaveTemplateClose}
          transmissionTemplateTypeId={transmissionTemplateTypeId} />
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onRowDataUpdated={onRowDataUpdated}
          onCellValueChanged={setRowValuesWithService}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          overlayLoadingTemplate={overlayLoadingTemplate}
          //columnDefs={colDefs}
          rowSelection="multiple"
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          gridOptions={gridOptions}
          enterMovesDownAfterEdit={true}
          onCellFocused={updateCurrentRow}
          enableRangeSelection={true}
          tooltipShowDelay={0}
          animateRows={true}
          groupSelectsChildren={true}
          components={{
            templateToolPanel: RowDataTemplatePanel,
            profileToolPanel: ProfileToolPanel,
            serviceToolPanel: ServiceToolPanel,
            viewToolPanel: GridViewToolPanel,
            datePicker: DateCellEditor,
          }}
        />
      </AgGridContainer>
    </DivGuard>
  );
};

export default function BulkOriginalRequestsExport() {
  return <BulkOriginalRequests />;
}

const overlayNoRowsTemplate = '<span style="font-size: 20px">' +
  '<p>This space is used to queue up original requests you want to send to OASIS.</p>' +
  '<p>To initiate a new original request, you can click the add new button and complete the required fields.</p>' +
  '<p>If you have an original request that is a good example of one you would use frequently, click the save template button to save it for future use.</p>' +
  'Once you have at least one template, you can quickly queue up new original requests using the Template tool panel on the right side of the grid.' +
  '</span>';

const overlayLoadingTemplate = '<span>Loading data... please wait.</span>';