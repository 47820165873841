import { useState, useMemo, useRef, useCallback, } from "react";
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../../useActionAudit";
import { Box, Button, Switch, Stack, Tooltip, Select, MenuItem, FormControlLabel, FormGroup, } from '@mui/material';
import DetailCellRenderer from "./RedirectsDetailGrid";
import 'ag-grid-community/styles/ag-grid.css'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DirectionsIcon from '@mui/icons-material/Directions';
import { useSnackbar } from "notistack";
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import useHeader from "../../useHeader";
import RedirectDialog from "./RedirectDialog";
import { useTheme } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { isEqual } from 'lodash';
import useGridLayout from "../../useGridLayout";
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel";
import { stringIntComparator } from "../../../utils/gridFunctions";
import UndoIcon from '@mui/icons-material/Undo';
import RelinquishDialog from "../RelinquishDialog";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import useHubAction from "../../HubContext/useHubAction_v2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin
import { AgGridContainer } from "../../AgGrid/AgGridContainer";

export default (props) => {
  const { view, visible, handleEdit } = props;
  const { logAction } = useActionAudit();
  const gridRef = useRef();
  const headers = useHeader();
  const dateFormat = 'MM/DD/YYYY HH:mm';
  const [searchCriteria, setSearchCriteria] = useState({
    startDateTime: dayjs().startOf('day'),
    stopDateTime: dayjs().add(1, 'day').startOf('day'),
  });
  const storageKey = `day-ahead-redirects-grid-${view.id ?? view.label}`
  const [selectedRow, setSelectedRow] = useState();
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [openRelinquishDialog, setOpenRelinquishDialog] = useState(false);
  const [timezone, setTimezone] = useState('Pacific Standard Time');
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = theme.palette.primary;
  const [autoRefresh, setAutoRefresh] = useState(true);

  dayjs.extend(utc);
  dayjs.extend(tz);

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({ ...props, });
  }, []);

  async function fetch() {
    console.log(`Fetching redirect TSRs for ${view.label} tab... ${dayjs().format('HH:mm:ss.SSS')}`)

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TSRRedirectsFetch`
      + `&parm=${timezone ?? ''}`
      + `&parm=${headers.userGuid ?? ''}`
      + `&parm=${view.justConfirmed ? 1 : 0}`
      + `&parm=${searchCriteria.startDateTime?.format(dateFormat) ?? ''}`
      + `&parm=${searchCriteria.stopDateTime?.format(dateFormat) ?? ''}`
      + `&parm=${view.POR ?? ''}`
      + `&parm=${view.POD ?? ''}`
      + `&parm=${view.Provider ?? ''}`
      + `&parm=${view.requestType ?? ''}`
      + `&parm=${view.customerCode ?? ''}`
      + `&parm=${view.serviceIncrement ?? ''}`
      + `&parm=${view.serviceClass ?? ''}`

    const options = {
      headers: headers,
      url: url,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading tsrs. ${error.message}`)
      logAction(`Redirect fetch failed: ${error.message}`, 'Day Ahead Redirects')
      gridRef.current?.api && gridRef.current.api.hideOverlay();
    })
  }

  async function fetchSilent() {
    const timestamp = dayjs();
    return fetch().then(response => {
      if (gridRef.current?.api) {
        console.log(`Success fetching redirect TSRs for ${view.label} tab. ${response?.data?.length ?? 0} rows returned. ${dayjs().diff(timestamp)} ms elapsed.`, 'Day Ahead Redirects')
        logAction(`Success fetching redirect TSRs for ${view.label} tab. ${response?.data?.length ?? 0} rows returned. ${dayjs().diff(timestamp)} ms elapsed.`, 'Day Ahead Redirects')
        response && handleDataUpdate(response.data ?? []);
      }
    })
  }

  const hubData = useHubAction({
    action: fetchSilent,
    allowedMessages: ['PowerStationMetaData.tsr_v2_insertUpdate'],
    predicate: (visible && autoRefresh && gridRef.current?.api),
    callbackDependencies: [view, searchCriteria, timezone, headers],
    wait: 15000,
  });

  function handleFetch() {
    const timestamp = dayjs();
    gridRef.current?.api && gridRef.current.api.showLoadingOverlay();
    fetch().then(response => {
      console.log(`Success fetching redirect TSRs for ${view.label} tab. ${response?.data?.length ?? 0} rows returned. ${dayjs().diff(timestamp)} ms elapsed.`, 'Day Ahead Redirects')
      logAction(`Success fetching redirect TSRs for ${view.label} tab. ${response?.data?.length ?? 0} rows returned. ${dayjs().diff(timestamp)} ms elapsed.`, 'Day Ahead Redirects')
      response && handleDataUpdate(response.data ?? []);
      gridRef.current?.api && gridRef.current.api.hideOverlay();
    })
  }

  function handleDataUpdate(newData) {
    const oldData = [];
    gridRef.current.api.forEachNode(node => oldData.push(node.data));

    const { toAdd, toUpdate, toDelete, diffStart } = hubData.diffData(newData, oldData);

    console.log(`Redirect grid ${view.label} data diff complete. ${dayjs().diff(diffStart)}ms elapsed.`)

    gridRef.current.api.applyTransaction({
      add: toAdd,
      addIndex: 0,
      update: toUpdate,
      remove: toDelete,
    });

    console.log(`Redirect grid ${view.label} update complete. ${dayjs().diff(diffStart)}ms elapsed.`)
    gridRef.current.api.refreshCells({ columns: ['AssignmentRef'], force: true })
  }

  const filterParams = {
    // provide comparator function
    comparator: (filterDate, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      //  dates are stored as MM/DD/YYYY HH:mm
      // We create a Date object for comparison against the filter date
      const cellDate = dayjs(dateAsString).startOf('day').toDate();

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterDate) {
        return -1;
      } else if (cellDate > filterDate) {
        return 1;
      }
      return 0;
    }
  }

  function tooltipRenderer(params) {
    const { xValue, availability } = params;
    const tzDate = dayjs(xValue).format(dateFormat);
    return {
      title: tzDate,
      content: availability,
    }
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.availability <= 0 ? colors.red : colors.green,
      stroke: params.availability <= 0 ? colors.red : colors.green,
    };
  };


  function cellRendererSelector(params) {
    if (params.data.childJson) {
      return ({
        component: 'agGroupCellRenderer',
      })
    } else return null;
  }

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const baseColDefs = useMemo(() => [
    {
      headerName: "ID",
      initialWidth: 60,
      field: "ID",
      initialHide: true,
    },
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      initialWidth: 170,
      cellRendererSelector: cellRendererSelector,
      headerName: "ARef",
      field: "AssignmentRef",
      //colId: 'AssignmentRef',
      comparator: stringIntComparator,
    },
    {
      headerName: "Children Cnt",
      initialHide: true,
      initialWidth: 100,
      field: "childrenCount",
    },
    {
      headerName: "DB",
      initialHide: true,
      initialWidth: 60,
      field: "OASISInstance",
    },
    {
      headerName: "Request Type",
      initialWidth: 120,
      field: "RequestType",
    },
    {
      headerName: "Related Ref",
      initialWidth: 100,
      field: "Related Ref",
      initialHide: true,
    },
    {
      headerName: "Status",
      initialWidth: 100,
      tooltipField: "SELLER_COMMENTS",
      field: "Status",
    },
    {
      headerName: "Comments",
      initialWidth: 300,
      initialHide: true,
      field: "SELLER_COMMENTS",
    },
    {
      headerName: "TP",
      initialWidth: 100,
      field: "Provider",
    },
    {
      headerName: "SC",
      initialWidth: 100,
      field: "Seller Code",
    },
    {
      headerName: "CC",
      initialWidth: 100,
      field: "Customer Code",
    },
    {
      headerName: "Availability",
      cellRenderer: 'agSparklineCellRenderer',
      equals: isEqual,
      initialHide: false,
      minWidth: 400,
      valueGetter: (params) => {
        const availabilityData = JSON.parse(params.data.availabilityData ?? '[]').map((data) => ({
          //Don't convert to local time.  We want to display the UTC time in the tooltip.
          x: dayjs(dayjs(data.dateTime).utcOffset(data.dateTime.slice(-6)).format('MM/DD/YYYY HH:mm')).toDate(),
          y: data.availability,
        }));
        return availabilityData;
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    },
    {
      headerName: "Path Name",
      initialWidth: 170,
      field: "Path_Name",
    },
    {
      headerName: "POR",
      initialWidth: 100,
      field: "PointOfReceipt",
    },
    {
      headerName: "POD",
      initialWidth: 100,
      field: "PointOfDelivery",
    },
    {
      headerName: "Source",
      initialWidth: 150,
      field: "Source",
    },
    {
      headerName: "Sink",
      initialWidth: 150,
      field: "Sink",
    },
    {
      headerName: "Vol",
      initialWidth: 80,
      field: "Volume",
    },
    {
      headerName: "Service",
      initialWidth: 80,
      field: "Service",
    },
    {
      headerName: "Service Inc",
      initialWidth: 100,
      field: "Service_Increment",
    },
    {
      headerName: "Class",
      initialWidth: 100,
      field: "TS_Class",
    },
    {
      headerName: "Type",
      initialWidth: 100,
      field: "TS_Type",
    },
    {
      headerName: "Period",
      initialWidth: 100,
      field: "TS_Period",
    },
    {
      headerName: "Window",
      initialWidth: 100,
      field: "TS_Window",
    },
    {
      headerName: "Subclass",
      initialWidth: 100,
      field: "TS_Subclass",
    },
    {
      headerName: "Start",
      initialWidth: 100,
      field: "Start",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "StartUTC",
      initialWidth: 100,
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "Stop",
      initialWidth: 100,
      field: "Stop",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "StopUTC",
      initialWidth: 100,
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "Start D",
      initialWidth: 100,
      field: "Start Date",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "Start T",
      initialWidth: 100,
      field: "Start Time",
    },
    {
      headerName: "Stop D",
      initialWidth: 100,
      field: "Stop Date",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
    },
    {
      headerName: "Stop T",
      initialWidth: 100,
      field: "Stop Time",
    },
    {
      headerName: "$$",
      initialWidth: 80,
      field: "Price",
    },
    {
      headerName: "Price Unit",
      initialWidth: 60,
      field: "PriceUnit",
    },
    {
      headerName: "Affiliate Flag",
      initialHide: true,
      initialWidth: 60,
      field: "AffiliateFlag",
    },
    {
      headerName: "Bid Price",
      initialWidth: 60,
      field: "BidPrice",
    },
    {
      headerName: "Ceiling Price",
      initialWidth: 60,
      field: "CeilingPrice",
    },
    {
      headerName: "Sale Ref",
      initialWidth: 100,
      field: "Sale Ref",
    },
    {
      headerName: "Request Ref",
      initialWidth: 100,
      field: "Request Ref",
    },
    {
      headerName: "ChildJSON",
      initialWidth: 500,
      initialHide: true,
      field: "childJson",
    },
    {
      headerName: "NERC Curtailment Priority",
      initialHide: true,
      initialWidth: 60,
      field: "NERCcurtailmentPriority",
    },
    {
      headerName: "Reassigned Ref",
      initialWidth: 60,
      field: "ReassignedRef",
    },
    {
      headerName: "Reassigned Capacity",
      initialWidth: 60,
      field: "ReassignedCapacity",
    },
    {
      headerName: "Reassigned Start Time",
      initialWidth: 60,
      field: "ReassignedStartTime",
    },
    {
      headerName: "Reassigned Stop Time",
      initialWidth: 60,
      field: "ReassignedStopTime",
    },
    {
      headerName: "Redirected Ref",
      initialWidth: 60,
      field: "RedirectedRef",
    },
    {
      headerName: "Deal Ref",
      initialWidth: 100,
      field: "Deal Ref",
    },
    {
      headerName: "Posting Ref",
      initialWidth: 100,
      field: "Posting Ref",
    },
    {
      headerName: "Seller Ref",
      initialWidth: 100,
      field: "SellerRef",
    },
    {
      headerName: "Pre",
      initialWidth: 80,
      field: "Preconfirmed",
    },
    {
      headerName: "Reservation Profile Flag",
      initialWidth: 100,
      field: "ReservationProfileFlag",
    },
    {
      headerName: "CustName",
      initialWidth: 150,
      field: "CUSTOMER_NAME",
    },
    {
      headerName: "CustEmail",
      initialWidth: 150,
      field: "CUSTOMER_EMAIL",
    },
  ], []);

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageKey, gridRef, baseColDefs);

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ]
  }), []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    enableRowGroup: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    setSelectedRow(
      selected.length ? selected[0] : undefined
    )
  }

  function onRowDataUpdated(params) {
    //we want to capture dynamic changes to the selected row if they are not already doing a redirect on it in the dialog
    if (!openRedirectDialog) {
      const selected = params.api.getSelectedRows();
      if (selected) {
        setSelectedRow(
          selected.length ? selected[0] : undefined
        )
      }
    }
  }

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
    let message = `Time zone changed to ${event.target.value}.`;
    enqueueSnackbar(message);
  };

  function onGridReady(params) {
    handleFetch();
    loadLayout();
  }

  function handleCriteriaChange(key, value) {
    const newCriteria = {
      ...searchCriteria,
      [key]: value,
    }

    setSearchCriteria(newCriteria);
  }

  function getRowId(params) {
    return params.data.ID;
  }

  const closeRedirectDialog = useCallback(() => {
    setOpenRedirectDialog(false);
  }, [])

  const closeRelinquishDialog = useCallback(() => {
    setOpenRelinquishDialog(false);
  }, [])

  return (
    <AgGridContainer
      style={{ height: "76vh", width: "100%", fontSize: '10px' }}
    >
      <RedirectDialog tsr={selectedRow} open={openRedirectDialog} closeDialog={closeRedirectDialog} timezone={timezone} startDate={searchCriteria.startDateTime} stopDate={searchCriteria.stopDateTime} />
      <RelinquishDialog tsr={selectedRow} open={openRelinquishDialog} closeDialog={closeRelinquishDialog} timezone={timezone} />
      <div style={{ display: 'flex', flexDirection: 'row', }}>
        <Stack direction={'row'} spacing={1} p={1} alignItems={'center'} justifyContent={'flex-start'}>
          <Button
            endIcon={<DirectionsIcon />}
            onClick={() => setOpenRedirectDialog(true)}
            disabled={!selectedRow}
            variant={'contained'}
          >Redirect</Button>
          <Button
            endIcon={<UndoIcon />}
            onClick={() => setOpenRelinquishDialog(true)}
            disabled={!selectedRow || (selectedRow.RequestType?.toLowerCase() !== 'redirect')}
            variant={'contained'}
          >Relinquish</Button>
          <Tooltip title='Set the current filter criteria as default.' arrow placement='top'>
            <Button
              endIcon={<EditIcon />}
              variant={'contained'}
              color={'primary'}
              onClick={handleEdit}
              disabled={view.label === 'All Tsrs'}
            >Edit View</Button>
          </Tooltip>
          <FormGroup>
            <FormControlLabel control={<Switch checked={autoRefresh} onChange={() => setAutoRefresh(!autoRefresh)} />} label="Auto-refresh" />
          </FormGroup>
        </Stack>
        <Box sx={{ flexGrow: 1, }} />
        <Stack direction={'row'} spacing={1} p={1} alignItems={'center'} justifyContent={'flex-end'}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            onClick={handleFetch}
            variant={'contained'}
          >Refresh</Button>
          <Tooltip title="Select the timezone of interest.  It will be used in filtering/formating TSRs in the list below." arrow placement="top">
            <Select
              value={timezone}
              label="Timezone"
              onChange={handleTimezoneChange}
              size='small'
              sx={{ minWidth: '150px', }}
            >
              <MenuItem selected value={'Pacific Standard Time'}>Pacific</MenuItem>
              <MenuItem value={'Eastern Standard Time'}>Eastern</MenuItem>
              <MenuItem value={'Central Standard Time'}>Central</MenuItem>
              <MenuItem value={'Mountain Standard Time'}>Mountain</MenuItem>
            </Select>
          </Tooltip>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={searchCriteria.startDateTime}
              ampm={false}
              onChange={(newValue) => handleCriteriaChange('startDateTime', newValue)}
              label='Start Date'
              slotProps={{ textField: { size: 'small', } }}
            />
            <DateTimePicker
              value={searchCriteria.stopDateTime}
              ampm={false}
              onChange={(newValue) => handleCriteriaChange('stopDateTime', newValue)}
              label='Stop Date'
              slotProps={{ textField: { size: 'small', } }}
            />
          </LocalizationProvider>
        </Stack>
      </div>
      <AgGridReact
        tooltipShowDelay={0}
        ref={gridRef}
        statusBar={statusBar}
        getRowId={getRowId}
        components={{
          detailCellRenderer: detailCellRenderer,
          layoutToolPanel: LayoutToolPanel,
        }}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        rowSelection={'single'}
        onSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onRowDataUpdated}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        animateRows={true}
        masterDetail={true}
        detailRowAutoHeight
        detailCellRenderer={'detailCellRenderer'}
        enableCellTextSelection
      />
    </AgGridContainer>
  )
}