import { Autocomplete, Box, Table, TableContainer, TableHead, TableCell, ListItem, TableRow, TableBody, Divider, List, MenuItem, TextField, Tooltip } from "@mui/material"

export const PhysicalSegmentsPicker = ({ segmentOptions, tsrList, handleUpdateSegment, }) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>TSR</TableCell>
                    <TableCell>Physical Segment</TableCell>
                </TableHead>
                <TableBody>
                    {tsrList.map((tsr) => (
                        <TableRow key={tsr.ID} >
                            <Tooltip placement='left' title={
                                <Box sx={{ pt: 2, }}>
                                    <Divider>TSR Info</Divider>
                                    <List dense sx={{ fontSize: '1rem', }}>
                                        <ListItem>ARef: {tsr.AssignmentRef}</ListItem>
                                        <ListItem>Capacity: {tsr.Capacity}</ListItem>
                                        <ListItem>POR: {tsr.PointOfReceipt}</ListItem>
                                        <ListItem>POD: {tsr.PointOfDelivery}</ListItem>
                                        <ListItem>Provider: {tsr.Provider}</ListItem>
                                        <ListItem>Related Ref: {tsr.RelatedRef}</ListItem>
                                        <ListItem>Preconfirmed: {tsr.Preconfirmed}</ListItem>
                                        <ListItem>Request Type: {tsr.RequestType}</ListItem>
                                        <ListItem>Seller Code: {tsr.SellerCode}</ListItem>
                                        <ListItem>Status: {tsr.Status}</ListItem>
                                        <ListItem>TS Class: {tsr.TSClass}</ListItem>
                                        <ListItem>TS Period: {tsr.TSPeriod}</ListItem>
                                        <ListItem>TS Subclass: {tsr.TSSubclass}</ListItem>
                                        <ListItem>TS Type: {tsr.TSType}</ListItem>
                                        <ListItem>TS Window: {tsr.TSWindow}</ListItem>
                                    </List>
                                    <Divider />
                                </Box>
                            }>
                                <TableCell component="th" scope="row">
                                    {tsr.AssignmentRef}
                                </TableCell>
                            </Tooltip>
                            <TableCell align="right">
                                <SegmentAutocomplete
                                    segmentOptions={segmentOptions}
                                    handleChange={(value) => handleUpdateSegment(tsr.ID, value)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const getOptionLabel = (option) => {
    return `${option.POR} - ${option.POD} - ${option.TP}`;
}

const SegmentAutocomplete = ({ segmentOptions, handleChange, }) => {
    return (
        <Autocomplete
            options={segmentOptions}
            getOptionLabel={getOptionLabel}
            onChange={(event, value) => {
                handleChange(value);
            }}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Physical Segment"
                    variant="outlined"
                    size="small"
                />
            }
            renderOption={(props, option) => {
                return (
                    <div>
                        <Tooltip placement='right' title={
                            <Box sx={{ pt: 2, }}>
                                <Divider>Segment Info</Divider>
                                <List dense sx={{ fontSize: '1rem', }}>
                                    <ListItem>POR: {option.POR}</ListItem>
                                    <ListItem>POD: {option.POD}</ListItem>
                                    <ListItem>TP: {option.TP}</ListItem>
                                    {/*<ListItem>GCA Contact: {option.GCA_Contact}</ListItem>
                                    <ListItem>GCA Fax: {option.GCA_Fax}</ListItem>
                                    <ListItem>GCA Phone: {option.GCA_Phone}</ListItem>
                                    <ListItem>GCA Profile Ref: {option.GCA_ProfileRef}</ListItem>
                                    <ListItem>MO: {option.MO}</ListItem>*/}
                                </List>
                                <Divider />
                            </Box>
                        }>
                            <div>
                                <MenuItem {...props} dense>{getOptionLabel(option)}</MenuItem>
                            </div>
                        </Tooltip>
                    </div>
                );
            }}
        />
    )
}