import { useState } from 'react';
import { Box, Typography, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

export const ConfirmSubmitChangesDialog = ({ tagId, open, onConfirmation, onCancel, }) => {
    const [notes, setNotes] = useState('');

    return (
        <Dialog open={!!open} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Submit Changes for Tag {tagId}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={onCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`You are about to submit changes for tag ${tagId}. Are you sure?`}
                </DialogContentText>
                <TextField
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    autoFocus
                    margin="dense"
                    label="Notes"
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    error={notes.length > 128}
                    helperText={notes.length > 128 ? 'Notes must not exceed 128 characters.' : ''}
                />
                <DialogActions sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button size="small" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant='contained' size="small" onClick={() => onConfirmation(notes)} disabled={notes.length > 128}>
                        Submit Changes
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}
