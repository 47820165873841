import styled from '@mui/system/styled';
import Datetime from 'react-datetime';

export const StyledDatetime = styled(Datetime)(({ theme }) => ({
    /*!
    * https://github.com/arqex/react-datetime
    */

    '& .rdt': {
        position: 'relative',
    },
    '& .rdtPicker': {
        display: 'none',
        position: 'absolute',
        minWidth: '250px',
        padding: '4px',
        marginTop: '1px',
        zIndex: 99999,
        background: theme.palette.mode === 'dark' ? '#121212' : '#fff',
        boxShadow: '0 1px 3px rgba(0,0,0,.1)',
        border: '1px solid #f9f9f9',
        '.rdtTimeToggle': {
            textAlign: 'center',
        },
        'table': {
            width: '100%',
            margin: 0,
        },
        'td, th': {
            textAlign: 'center',
            height: '28px',
        },
        'td': {
            cursor: 'pointer',
        },
        'td.rdtDay:hover, td.rdtHour:hover, td.rdtMinute:hover, td.rdtSecond:hover, .rdtTimeToggle:hover': {
            background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#eeeeee',
            cursor: 'pointer',
        },
        'td.rdtOld, td.rdtNew': {
            color: '#999999',
        },
        'td.rdtToday': {
            position: 'relative',
        },
        'td.rdtToday:before': {
            content: '""',
            display: 'inline-block',
            borderLeft: '7px solid transparent',
            borderBottom: '7px solid #428bca',
            borderTopColor: 'rgba(0, 0, 0, 0.2)',
            position: 'absolute',
            bottom: '4px',
            right: '4px',
        },
        'td.rdtActive, td.rdtActive:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            textShadow: '0-1px 0 rgba(0, 0, 0, 0.25)',
        },
        'td.rdtActive.rdtToday:before': {
            borderBottomColor: '#fff',
        },
        'td.rdtDisabled, td.rdtDisabled:hover': {
            background: 'none',
            color: '#999999',
            cursor: 'not-allowed',
        },
        '& .rdtPicker td span.rdtOld': {
            color: '#999999',
        },
        'td span.rdtDisabled, td span.rdtDisabled:hover': {
            background: 'none',
            color: '#999999',
            cursor: 'not-allowed',
        },
        '.dow': {
            width: '14.2857%',
            borderBottom: 'none',
            cursor: 'default',
        },
        'th.rdtSwitch': {
            width: '100px',
        },
        'th.rdtNext, th.rdtPrev': {
            fontSize: '21px',
            verticalAlign: 'top',
        },
        'button': {
            border: 'none',
            background: 'none',
            cursor: 'pointer',
        },
        'th.rdtDisabled, th.rdtDisabled:hover': {
            background: 'none',
            color: '#999999',
            cursor: 'not-allowed',
        },
        'thead': {
            'button': {
                width: '100%',
                height: '100%',
            },
            'tr': {
                'th, td': {
                    cursor: 'pointer',
                },
                'th:hover, td:hover': {
                    background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#eeeeee',
                },
            },
        },
        'tfoot': {
            borderTop: '1px solid #f9f9f9',
        },
        'button:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#eeeeee',
        },
    },
    '&.rdtOpen .rdtPicker': {
        display: 'block',
    },
    '&.rdtStatic .rdtPicker': {
        boxShadow: 'none',
        position: 'static',
    },
    '& .rdtPrev span, .rdtNext span': {
        display: 'block',
        '-webkit-touch-callout': 'none', /* iOS Safari */
        '-webkit-user-select': 'none',   /* Chrome/Safari/Opera */
        '-khtml-user-select': 'none',    /* Konqueror */
        '-moz-user-select': 'none',      /* Firefox */
        '-ms-user-select': 'none',       /* Internet Explorer/Edge */
        'user-select': 'none',
    },
    '& td.rdtMonth, td.rdtYear': {
        height: '50px',
        width: '25%',
        cursor: 'pointer',
    },
    '& td.rdtMonth:hover, td.rdtYear:hover': {
        background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#eeeeee',
    },
    '& .rdtCounters': {
        display: 'inline-block',
    },
    '& .rdtCounters > div': {
        float: 'left',
    },
    '& .rdtCounterSeparator': {
        lineHeight: '100px',
    },
    '& .rdtCounter': {
        width: '40px',
        height: '100px',
        '.rdtBtn': {
            height: '40%',
            lineHeight: '40px',
            cursor: 'pointer',
            display: 'block',
            '-webkit-touch-callout': 'none', /* iOS Safari */
            '-webkit-user-select': 'none',   /* Chrome/Safari/Opera */
            '-khtml-user-select': 'none',    /* Konqueror */
            '-moz-user-select': 'none',      /* Firefox */
            '-ms-user-select': 'none',       /* Internet Explorer/Edge */
            'user-select': 'none',
        },
        '& .rdtBtn:hover': {
            background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : '#eeeeee',
        },
        '& .rdtCount': {
            height: '20%',
            fontSize: '1.2em',
        },
    },
    '& .rdtMilli': {
        verticalAlign: 'middle',
        paddingLeft: '8px',
        width: '48px',
    },
    '& .rdtMilli input': {
        width: '100%',
        fontSize: '1.2em',
        marginTop: '37px',
    },
    '& .rdtTime td': {
        cursor: 'default',
    },
})
);