import { Box } from "@mui/material";
import ChatContent from "../Messaging/ChatContent";
import { useState } from "react";
import { useChatBots } from "../useChatBots";

export const TariffChat = () => {
    const { loading, setLoading, submitPrompt, } = useChatBots({
        application: 'tariffaf',
        defaultModel: 'gemini',
        useStreaming: true,
    });

    const [history, setHistory] = useState([{
        sender: 'bot',
        content: 'Welcome to the Tariff Chat! How can I help you?',
        timeStamp: new Date(),
    }]);

    function handlePrompt(message) {
        setLoading(true);
        setHistory(h => [...h, message]);
        submitPrompt(message.content).then(text => {
            setLoading(false);
            setHistory(h => [...h, {
                sender: 'bot',
                content: text,
                timeStamp: new Date(),
            }]);
        });
    }

    return (
        <Box sx={{ p: 1, }}>
            <ChatContent history={history} handleSendMessage={handlePrompt} isTyping={loading} />
        </Box>
    );
}