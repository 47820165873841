import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { Typography, IconButton, FormControl, FormControlLabel, Checkbox, Button, Tooltip, Dialog, DialogContent, DialogActions, Box, DialogTitle, Grid, Autocomplete, TextField, } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from 'react';

export default (props) => {
  const { open, handleClose, tsr, } = props;
  const { enqueueSnackbar } = useSnackbar();

  function dateFormatter(val) {
    return moment(val).startOf('hour').format('MM/DD/YYYY HH:mm');
  }

  return(
    <Dialog maxWidth={'lg'} open={open}>
      <DialogTitle>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography>Posting Details</Typography>
              <Box sx={{ flexGrow: 1, }} />
              <IconButton onClick={handleClose} size='large'>
                  <CloseIcon />
              </IconButton>
          </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{display: 'flex', p:1}}>
          <Grid container spacing={2} justifyContent='center' alignItems='center'>
              
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>Close</Button>
        <Box sx={{ flexGrow: 1, }}/>
      </DialogActions>
    </Dialog>
  )
}