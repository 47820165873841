import { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function NotAuthorized(props) {
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        setOpen(true);
    }, [])


    const handleClose = () => {
        setOpen(false);
    };
    
    return(
        
                <Dialog 
                    open={open}
                    onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">
                        {"Not Part of Your Subscription"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Unfortunately, your subscription level does not include this part of Power Station.  Please contact support@powersysops.com to obtain more information."}
                    </DialogContentText>
                    </DialogContent>
                </Dialog>
            
    );
} 