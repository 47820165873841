import { RibbonButton } from "../Ribbon";
import { Tooltip } from "@mui/material";
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

export const ClearSelectionsButton = ({ expanded, gridRef, ...buttonProps }) => {
    function handleClearSelections() {
        gridRef.current?.api?.deselectAll();
    }

    return (
        <Tooltip title="Clear the row selections in the grid.">
            <span>
                <RibbonButton
                    icon={<RemoveDoneIcon fontSize='small' />}
                    label={'Clear Selections'}
                    expanded={expanded}
                    onMouseDown={handleClearSelections}
                    {...buttonProps}
                />
            </span>
        </Tooltip>
    )
}