import { apiUrlPrefix, userGroups } from '../../../authConfig';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useHeader from '../../useHeader';
import { useEffect, useRef, useState, useMemo, useCallback, } from 'react';
import debounce from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin
import { useMsal } from '@azure/msal-react';
import { useActionAudit } from '../../useActionAudit';
import momentTimezones from '../../momentTimezones';
import { Box, Stack, Button, FormControl, RadioGroup, Radio, FormControlLabel, } from '@mui/material';
import { TransmissionDialog } from '../TransmissionDialog';
import PublishIcon from '@mui/icons-material/Publish';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import RefreshIcon from '@mui/icons-material/Refresh';
import MapIcon from '@mui/icons-material/Map';
import ProGrid from '../East/ProGridEast';
import PathMap from '../../PathMap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, date, } from 'yup';
import FormDateTimePicker from '../../FormControls/FormDateTimePicker';
import ResizingPane from 'react-resizing-pane';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DefaultDatePopover from './DefaultDatePopover';
import { useUserGroups } from '../../../data/useUserGroups';
import { AgGridContainer } from '../../AgGrid/AgGridContainer';

const DashboardView = (props) => {
  const [gridData, setGridData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { logAction } = useActionAudit();
  const { view, handleUpdate, getDefaultDates, } = props;
  const [selectedPath, setSelectedPath] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [openTransmissionDialog, setOpenTransmissionDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [showMap, setShowMap] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const gridRef = useRef();
  const abortControllerRef = useRef(new AbortController());
  const { accounts } = useMsal();
  const formatString = 'MM/DD/YYYY HH:mm';
  dayjs.extend(utc);
  dayjs.extend(tz);
  const storageConfig = { name: 'ResizableFastPathDashboardPane', type: localStorage };
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const { userIsInGroup } = useUserGroups();
  const isInSetLocationGroup = userIsInGroup(userGroups.setlocation)

  //hub state
  const savedHubType = localStorage.getItem('fast-path-dashboard-hub-type');
  const [hubType, setHubType] = useState(savedHubType ?? 'none');
  const [DAHubs, setDAHubs] = useState([]);
  const [RTHubs, setRTHubs] = useState([]);

  const defaultValues = useMemo(() => ({
    startDate: dayjs(view.startDate).tz(momentTimezones[view.timezone], true),
    stopDate: dayjs(view.stopDate).tz(momentTimezones[view.timezone], true),
  }), [view])

  const schema = object().shape({
    startDate: date().required('Start Date is required'),
    stopDate: date().required('Start Date is required').when('startDate', (startDate, schema) => {
      return startDate && schema.min(startDate, 'Stop Date cannot be before Start Date.');
    }),
  })

  const { control, handleSubmit, formState: { errors, }, reset, getValues, setValue, watch, } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    if (gridRef.current?.api && !loading) {
      fetch(view);
    }
  }, [view]);

  useEffect(() => {
    fetchHubs();
  }, []);

  async function fetchHubs() {
    const loadDAUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchTradingHubData&parm=${headers.userGuid}&parm=DA&parm=${view.timezone}`;
    const loadRTUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchTradingHubData&parm=${headers.userGuid}&parm=RT&parm=${view.timezone}`;
    const options = {
      headers: headers,
      url: loadDAUri,
    }
    axios(options).then(response => {
      setDAHubs(response.data ?? [])
    }).catch(err => {
      enqueueSnackbar(`Error fetching DA LMP data.  ${err}`, { variant: 'error', });
    });

    options.url = loadRTUri;
    axios(options).then(response => {
      setRTHubs(response.data ?? [])
    }).catch(err => {
      enqueueSnackbar(`Error fetching RT LMP data.  ${err}`, { variant: 'error', });
    });
  }

  const gridWidth = useMemo(() => showMap ? '40%' : '100%', [showMap])

  //Takes an array, returns a comma separated string
  function collapseArray(arr) {
    return arr?.length ? arr.reduce((current, next, i) => `${current}${(i > 0) ? ',' : ''}${next}`) : '';
  }

  function handleCancelFetch() {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  }

  function fetch(criteria = view) {
    setLoading(true);
    gridRef.current?.api && gridRef.current.api.showLoadingOverlay();
    const startDate = criteria.startDate ?? getValues('startDate').format(formatString);
    const stopDate = criteria.stopDate ?? getValues('stopDate').format(formatString);
    //const startDate = dayjs(view.startDate).tz(momentTimezones[view.timezone]).format(formatString);
    //const stopDate = dayjs(view.stopDate).tz(momentTimezones[view.timezone]).format(formatString);

    const timestamp = dayjs();
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fastPathProFetch`
      + `&parm=${headers.userGuid ?? ''}`
      + `&parm=${view.maxLegs ?? ''}`
      + `&parm=${view.por ?? ''}`
      + `&parm=${view.pod ?? ''}`
      + `&parm=${collapseArray(view.excludeTp)}`
      + `&parm=${view.minCapacity ?? ''}`
      + `&parm=${view.timezone ?? ''}`
      + `&parm=${startDate}`
      + `&parm=${stopDate}`
      + `&parm=${collapseArray(view.tsClass)}`
      + `&parm=${collapseArray(view.tsIncrement)}`
      + `&parm=${collapseArray(view.tsType)}`
      + `&parm=${collapseArray(view.tsPeriod)}`
      + `&parm=${collapseArray(view.tsWindow)}`
      + `&parm=${collapseArray(view.tsSubclass)}`
      + `&parm=${collapseArray(view.excludePoints)}`
      + `&parm=${view.hardLimit ? 1 : 0}`

    const options = {
      headers: headers,
      url: url,
      signal: abortControllerRef.current.signal,
    }

    const logMessage = `${accounts[0]?.username} used Fast Path Dashboard to find ${view.minCapacity}MW of `
      + `${view.tsClass ?? ''} ${view.tsIncrement ?? ''} ${view.tsType ?? ''} ${view.tsPeriod ?? ''} ${view.tsWindow ?? ''} ${view.tsSubclass ?? ''}`
      + ` trans ${view.por}-${view.pod} ${view.excludePoints ? ` excluding points ${view.excludePoints}` : ''}`
      + `${view.excludeTp ? ` excluding providers ${view.excludeTp}` : ''}`

    logAction(logMessage, 'Fast Path Dashboard', view)

    axios(options).then(response => {
      setGridData(response?.data ?? []);
      console.log(`Success fetching paths for dashboard view ${view.label}. ${response.data.length} rows returned. Fetch took ${dayjs().diff(timestamp)}ms.`)
      logAction(`Success fetching paths for dashboard view ${view.label}. ${response.data.length} rows returned. Fetch took ${dayjs().diff(timestamp)}ms.`, 'Fast Path Dashboard', view)
      setLoading(false);
    }).catch(error => {
      if (error.message !== 'canceled') { //don't show an error if the user canceled the fetch 
        const message = (error.response?.data?.includes('Crystal')) ? error.response?.data?.split('.')[0] : error.message;
        enqueueSnackbar(`Error fetching paths for dashboard view ${view.label}. ${message}`, { variant: 'error' });
        logAction(`Fast path dashboard fetch failed for dashboard view ${view.label}: ${message}. Status: ${error.response?.status}`, 'Fast Path Pro', view)
      } else {
        logAction('Fetch canceled by user.', 'Fast Path Dashboard', view)
      }
      gridRef.current?.api && gridRef.current.api.hideOverlay();
      setGridData([]);
      setLoading(false);
      gridRef.current?.api && gridRef.current.api.showNoRowsOverlay();
    })
  }

  function onSubmit(data) {
    const { startDate, stopDate } = data;
    const formatted = {
      startDate: dayjs(startDate).tz(momentTimezones[view.timezone]).format(formatString),
      stopDate: dayjs(stopDate).tz(momentTimezones[view.timezone]).format(formatString),
    }
    fetch(formatted);
    handleUpdate({
      ...view,
      ...formatted,
    });
  }

  //calculates 60% of the screen width in pixels
  function calculateDefaultGridWidth() {
    const width = window.innerWidth;
    const gridWidth = width * .6;
    return gridWidth;
  }

  function handleApplyDefault(value) {
    setDatePickerAnchor(null);
    const { startDate, stopDate } = getDefaultDates(value);
    reset({
      startDate: dayjs(startDate).tz(momentTimezones[view.timezone], true),
      stopDate: dayjs(stopDate).tz(momentTimezones[view.timezone], true),
    });
    localStorage.setItem('fast-path-dashboard-default-date', value);
  }

  function handleHubTypeChange(e) {
    setHubType(e.target.value);
    localStorage.setItem('fast-path-dashboard-hub-type', e.target.value);
  }

  const hubs = useMemo(() => {
    switch (hubType) {
      case 'DA':
        return DAHubs;
      case 'RT':
        return RTHubs;
      default:
        return [];
    }
  }, [hubType, DAHubs, RTHubs]);

  return (
    <Box
      sx={{ p: 2, width: '100%', }}
      overflow='auto'
    >
      {openTransmissionDialog && <TransmissionDialog
        open={openTransmissionDialog}
        pathData={selectedPath}
        action={dialogAction}
        setAction={setDialogAction}
        handleClose={() => setOpenTransmissionDialog(false)}
        searchCriteria={view}
      />}
      <form id={`fast-path-dashboard-${view.label}-toolbar`} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} direction='row' sx={{ p: 1 }}>
          <Button
            endIcon={<PublishIcon />}
            variant='contained'
            onClick={() => {
              setOpenTransmissionDialog(true);
              setDialogAction('reservenow');
            }}
            disabled={!selectedPath}
          >Reserve</Button>
          <Button
            endIcon={<AddAlertIcon />}
            variant='contained'
            onClick={() => {
              setOpenTransmissionDialog(true);
              setDialogAction('textalert');
            }}
            disabled={!selectedPath}
          >Alert</Button>
          <Box sx={{ flexGrow: 1, }} />
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            variant='contained'
            onClick={e => {
              setDatePickerAnchor(e.currentTarget);
            }}
          >Set Dates</Button>
          <DefaultDatePopover
            open={!!datePickerAnchor}
            anchorEl={datePickerAnchor}
            handleClose={() => setDatePickerAnchor(null)}
            handleApplyDefault={handleApplyDefault}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ m: 1, }}
          />
          <FormDateTimePicker
            name='startDate'
            control={control}
            disablePast
            label="Start Date"
            format="MM/DD/YYYY HH:mm"
            views={['year', 'month', 'day', 'hours']}
            //timezone={momentTimezones[timezone]}
            slotProps={{
              textField: {
                size: 'small',
                error: !!errors.startDate,
                helperText: errors.startDate?.message,
                onBlur: () => {
                  const startOfHour = dayjs(watch('startDate')).startOf('hour');
                  setValue('startDate', startOfHour);
                },
              }
            }}
          />
          <FormDateTimePicker
            name='stopDate'
            control={control}
            disablePast
            label="Stop Date"
            format="MM/DD/YYYY HH:mm"
            views={['year', 'month', 'day', 'hours']}
            //timezone={momentTimezones[timezone]}
            slotProps={{
              textField: {
                size: 'small',
                error: !!errors.stopDate,
                helperText: errors.stopDate?.message,
                onBlur: () => {
                  const startOfHour = dayjs(watch('stopDate')).startOf('hour');
                  setValue('stopDate', startOfHour);
                },
              }
            }}
          />
          <Button
            endIcon={<RefreshIcon />}
            variant='contained'
            type='submit'
            form={`fast-path-dashboard-${view.label}-toolbar`}
          >Reload</Button>
          {showMap && <FormControl>
            <RadioGroup
              row
              value={hubType}
              onChange={handleHubTypeChange}
            >
              <FormControlLabel control={<Radio />} value={'DA'} label="DA" />
              <FormControlLabel control={<Radio />} value={'RT'} label="RT" />
              <FormControlLabel control={<Radio />} value={'HA'} label="HA" />
              <FormControlLabel control={<Radio />} value={'none'} label="None" />
            </RadioGroup>
          </FormControl>}
          <Button
            endIcon={<MapIcon />}
            variant='contained'
            onClick={() => {
              setShowMap(show => !show);
            }}
          >{showMap ? 'Hide Map' : 'View Map'}</Button>
        </Stack>
      </form>
      <Stack direction={'row'} spacing={2}>
        <ResizingPane
          sides={['right']}
          storageId={'fast-path-dashboard-resizingpane-config'}
          storageConfig={storageConfig}
          width={calculateDefaultGridWidth()}
          style={{
            border: '0px solid blue',
            minWidth: showMap ? 300 : '100%',
            minHeight: '78vh',
            position: 'static',
          }}
        >
          <AgGridContainer
            style={{ width: gridWidth, height: '78vh', width: '100%', }}
          >
            <ProGrid
              data={gridData}
              ref={gridRef}
              setSelectedRow={setSelectedPath}
              handleCancelFetch={handleCancelFetch}
              noRowsOverlayText={'No results.  Click the Edit Current button above to change your search criteria.  Using an * in some of the criterion allows for a wildcard.  Hard limit will not return results unless all paths have the minimum capacity FOR THE ENTIRE DURATION.  Unchecking Hard limit will bring back any pathway that has at least the minimum capacity as of the start time.'}
              handleFetch={fetch}
            />
          </AgGridContainer>
        </ResizingPane>
        <div style={{ width: '60%', height: '78vh', display: showMap ? 'block' : 'none' }}>
          <PathMap
            data={gridData}
            pors={view.por}
            pods={view.pod}
            selectedPath={selectedPath}
            setSelectedNodes={setSelectedNodes}
            selectedNodes={selectedNodes}
            hubs={hubs}
            allowAddNew={isInSetLocationGroup}
            refreshHubs={fetchHubs}
          />
        </div>
      </Stack>
    </Box>
  )
}

export default DashboardView;