import { IconButton, Tooltip, Card, CardHeader, Divider, ListItemButton, ListItemIcon, InputAdornment, ListItemText, Checkbox, Box, Typography, ListItem, TextField, Stack } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SearchIcon from '@mui/icons-material/Search';
import styled from "@emotion/styled";

export const SourceItemList = ({ items = [], title, checked, setChecked, setSearchText, searchText, }) => {
    function handleToggle(value) {
        const newChecked = [...checked];
        if (newChecked.includes(value)) {
            setChecked(newChecked.filter((item) => item !== value));
        } else {
            setChecked([...newChecked, value]);
        }
    }

    function renderRow(props) {
        const { index, style } = props;
        const item = items[index];

        return (
            <ListItemButton
                key={JSON.stringify(item)}
                role="listitem"
                onClick={() => handleToggle(item)}
                style={style}
            >
                <ListItemIcon>
                    <Checkbox
                        checked={checked.includes(item)}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <Box component='li' sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, }}>
                    <Typography fontSize={12} width='100%'>{item.name}</Typography>
                    {
                        item.types && (
                            <Typography fontSize={10} color='text.secondary'>
                                {item.types.join('/')}
                            </Typography>
                        )
                    }
                </Box>
            </ListItemButton>
        );
    }

    return (
        <Card elevation={5} sx={{
            width: '100%',
            height: 500,
            position: 'relative',
        }}>
            <CardHeader
                sx={{ px: 2, height: 55, display: 'flex', alignItems: 'center', }}
                title={title}
                subheader={`${checked.length}/${items.length} selected`}
                avatar={
                    <Stack direction='row' spacing={1}>
                        <IconButton
                            onClick={() => setChecked(items)}
                            size="small"
                        >
                            <Tooltip title="Select all">
                                <DoneAllIcon />
                            </Tooltip>
                        </IconButton>
                        <IconButton
                            onClick={() => setChecked([])}
                            size="small"
                        >
                            <Tooltip title="Clear Selections">
                                <ClearIcon />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                }
            />
            <Box sx={{ height: 50, display: 'flex', flexDirection: 'column' }}>
                <Divider />
                <TextField
                    size="small"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    fullWidth
                    sx={{ p: 0.5, }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {searchText && (
                                    <IconButton
                                        onClick={() => setSearchText('')}
                                        edge="end"
                                        size="small"
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <NoDataOverlay visible={items.length === 0} />
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        height={500 - 55 - 50}
                        width={width}
                        itemSize={20}
                        itemCount={items.length}
                        overscanCount={5}
                    >
                        {renderRow}
                    </List>
                )}
            </AutoSizer>
        </Card>
    );
}

const NoDataOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <Typography align='center' variant='p'>No items to show. Choose tags on the left to show inputs and outputs here. Then you can add inputs and outputs to the model on the right.</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div')(({ theme, visible }) => ({
    position: 'absolute',
    top: '45%',
    padding: theme.spacing(2),
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));