import { useState, useMemo, useRef, } from "react";
import { columnPanel, filterPanel } from "../ToolPanels/DefaultToolPanels";
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../useActionAudit";
import { Button, Stack } from "@mui/material";
import useGridLayout from "../useGridLayout";
import { LayoutToolPanel } from "../ToolPanels/LayoutToolPanel";
import axios from "axios";
import useHeader from "../useHeader";
import { apiUrlPrefix, userGroups } from "../../authConfig";
import { useSnackbar } from "notistack";
import { CheckboxCellRenderer } from '../AgGrid/CheckboxCellRenderer';
import { useColumnTypes } from "../AgGrid/useColumnTypes";
import dayjs from "../dayjs-tz";
import useHubAction from "../HubContext/useHubAction_v2";
import '../../styles/transactionQueueStyles.css';
import { EditDialog } from "./EditDialog";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import momentTimezones from "../momentTimezones";

export const PathMonitor = ({ application, }) => {
  const { logAction } = useActionAudit();
  const abortControllerRef = useRef(new AbortController());
  const gridRef = useRef();
  const storageLocation = 'pathMonitorGridLayout';
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { columnTypes } = useColumnTypes({ dateFormat: 'MM/DD/YYYY HH:mm', });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  function handleCancelFetch() {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  }

  const { diffData, } = useHubAction({
    action: fetchData,
    allowedMessages: ['trg_TransQueueUpdateTrigger'],
    predicate: !!gridRef.current?.api,
    callbackDependencies: [gridRef.current?.api, headers],
  });

  async function fetchData() {
    gridRef.current.api.showLoadingOverlay();
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_transactionQueueFetch&parm=${headers.userGuid}&parm=${application}`,
      signal: abortControllerRef.current.signal,
    }

    return axios(options).then(response => {
      const data = response?.data ?? [];
      handleDataUpdate(data);
      gridRef.current.api.hideOverlay();
      console.log(`Path monitor data fetched. Timestamp: ${dayjs().format('HH:mm:ss.SSS')}. Rows returned: ${data.length}`)
    }).catch(error => {
      if (error.message !== 'canceled') {
        logAction(`Error fetching data. Message: ${error}`, `${application} - PathMonitor`);
        enqueueSnackbar(`Error fetching data. Message: ${error}`)
        gridRef.current.api.hideOverlay();
      }
    });
  }

  function handleDataUpdate(data) {
    const oldData = [];
    gridRef.current.api.forEachNode(node => oldData.push(node.data));

    const { toAdd, toUpdate, toDelete, } = diffData(data, oldData, 'alertTriggerID');

    gridRef.current.api.applyTransaction({
      add: toAdd,
      addIndex: 0,
      update: toUpdate,
      remove: toDelete,
    });
  }

  async function handleSaveChanges(active, timeZone, triggerId, data, actionInfo) {
    const tz = momentTimezones[timeZone];
    const target = actionInfo?.targetExecutionTime ?? data.targetExecutionTime;
    const targetExecutionTime = dayjs(target).tz(tz).format('MM/DD/YYYY HH:mm');
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_TransactionQueueUpdate_v2`
      + `&parm=${headers.userGuid}`
      + `&parm=${triggerId}`
      + `&parm=${targetExecutionTime ?? ''}`
      + `&parm=${timeZone}`
      + `&parm=${!!active ? 1 : 0}`

    const options = {
      method: 'POST',
      headers: headers,
      url: url,
      data: data,
    }

    return axios(options).catch(error => {
      logAction(`An error occurred. Message: ${error}`, `${application} - PathMonitor`);
      enqueueSnackbar(`An error occurred. Message: ${error}`, { variant: 'error' })
    });
  }

  const baseColDefs = useMemo(() => [
    {
      headerName: 'ID',
      field: 'alertTriggerID',
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: 'Label',
      headerName: 'Label',
      flex: 3,
    },
    {
      field: "active",
      headerName: "Active",
      cellRenderer: 'checkboxCellRenderer',
      initialWidth: 80,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      initialHide: true,
    },
    {
      field: 'dateCreated',
      headerName: 'Date Created',
      type: 'dateColumn',
    },
    {
      field: 'scheduleDateTime',
      headerName: 'Schedule Date/Time',
      type: 'dateColumn',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'tsrAref',
      headerName: 'TSR Aref',
    },
    {
      field: 'tsrStatus',
      headerName: 'TSR Status',
    }
  ], [])

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
    flex: 2,
  }), [])

  const { colDefs, layoutPanel, loadLayout, } = useGridLayout(storageLocation, gridRef, baseColDefs, defaultColDef);

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ]
  }), []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const loadingOverlay = props => {
    return <div style={{ position: 'relative', fontSize: '20px', top: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      Loading...
      <Button onClick={handleCancelFetch} variant="contained" sx={{ maxWidth: '200px', mt: 2, }}>Cancel</Button>
    </div>
  }

  function onGridReady(params) {
    fetchData();
    loadLayout();
  }

  function getRowId(params) {
    return params.data.alertTriggerID;
  }

  function handleToggleActivation() {
    gridRef.current.api.showLoadingOverlay();
    const selectedRows = gridRef.current.api.getSelectedRows();
    const newRows = selectedRows.map(row => ({ ...row, active: !row.active }));
    const savePromises = newRows.map(row => handleSaveChanges(row.active, row.timeZone, row.alertTriggerID, row.tsrJson));
    Promise.all(savePromises).then(() => {
      gridRef.current.api.applyTransaction({ update: newRows, });
      gridRef.current.api.hideOverlay();
      gridRef.current.api.deselectAll();
      enqueueSnackbar('Changes saved.', { variant: 'success' });
      logAction(`User activated/deactivated ${selectedRows.length} rows`, `${application} - PathMonitor`, selectedRows);
    });
  }

  const gridOptions = useMemo(() => ({
    rowClassRules: {
      "row-triggered": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['QUEUED',].includes(params.data?.NewTsrStatus),
      "row-confirmed": params => ['Success', 'ATC Levels Triggered Rule'].includes(params.data?.DispatchQueueStatus) && ['CONFIRMED', 'STUDY'].includes(params.data?.NewTsrStatus),
      "row-counteroffer": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['COUNTEROFFER',].includes(params.data?.NewTsrStatus),
      "row-invalid": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['INVALID',].includes(params.data?.NewTsrStatus),
      "row-refused": params => ['Success', 'ATC Levels Triggered Rule'].includes(params.data?.DispatchQueueStatus) && ['REFUSED'].includes(params.data?.NewTsrStatus),
      "row-withdrawn": params => ['Success'].includes(params.data?.DispatchQueueStatus) && ['WITHDRAWN'].includes(params.data?.NewTsrStatus),
      "row-staged": params => !['CONFIRMED',].includes(params.data?.Status) && ['Staged for Trigger',].includes(params.data?.DispatchQueueStatus),
      "row-alert": params => ['Alert me to Competition',].includes(params.data?.DispatchRuleName) && ['Staged for Trigger',].includes(params.data?.DispatchQueueStatus),
    },
  }), []);

  function handleUpdate(data, actionInfo) {
    const selectedRow = gridRef.current.api.getSelectedRows()[0];
    handleSaveChanges(selectedRow.active, data.timezone, selectedRow.alertTriggerID, data, actionInfo).then(response => {
      enqueueSnackbar('Changes saved.', { variant: 'success' });
      logAction(`User saved changes to TSR Alert`, `${application} - PathMonitor`, data);
      gridRef.current.api.deselectAll();
      fetchData();
    });
  }

  function onSelectionChanged() {
    const selected = gridRef.current?.api?.getSelectedRows();
    setSelectedRows(selected);
  }

  const tsrData = gridRef.current?.api?.getSelectedRows()[0] ?? {};
  const parsed = JSON.parse(tsrData.tsrJson ?? '{}');
  const formattedData = {
    ...parsed,
    scheduleDateTime: dayjs(tsrData.scheduleDateTime),
  };

  return (
    <AgGridContainer
      style={{ width: '100%', height: '78vh', paddingRight: '5px', paddingLeft: '5px', }}
    >
      <EditDialog
        open={openEditDialog}
        closeDialog={() => setOpenEditDialog(false)}
        data={formattedData}
        application={application}
        action={tsrData.action}
        scheduleDateTime={formattedData.scheduleDateTime}
        handleUpdate={handleUpdate}
      />
      <Stack direction="row" spacing={2} sx={{ p: 1, }}>
        <Button
          variant="contained"
          sx={{ maxWidth: '200px', }}
          disabled={selectedRows.length !== 1 || selectedRows[0].action !== 'reservefuture'}
          onClick={() => setOpenEditDialog(true)}
        >
          Edit Selected
        </Button>
        <Button
          onClick={handleToggleActivation}
          variant="contained"
          sx={{ maxWidth: '200px', }}
          disabled={!selectedRows.length}
        >
          Activate/Deactivate
        </Button>
      </Stack>
      <AgGridReact
        ref={gridRef}
        getRowId={getRowId}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        rowSelection='multiple'
        columnDefs={colDefs}
        columnTypes={columnTypes}
        statusBar={statusBar}
        sideBar={sideBar}
        overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">No rows to show.</span>`}
        loadingOverlayComponent={loadingOverlay}
        animateRows={true}
        gridOptions={gridOptions}
        components={{
          layoutToolPanel: LayoutToolPanel,
          checkboxCellRenderer: CheckboxCellRenderer,
        }}
      />
    </AgGridContainer>
  )
}