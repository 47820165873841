import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@mui/material';


export default (props) => {
    const {
        open,
        data = {},
        message,
        onConfirmation,
        onCancel,
        cancelText = 'NO',
        confirmText = 'YES',
        title = 'Are you sure?',
    } = props;

    const {
        profileJson,
    } = data;

    const profile = profileJson ? JSON.parse(profileJson)[0] : null;

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={!!open}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent id="alert-dialog-description">
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>

                {profile && (
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {Object.entries(profile).map(([key, value], index) => (
                                    <TableRow key={index}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{String(value)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <DialogActions>
                    <Button color="inherit" size="small" onClick={onCancel}>
                        {cancelText}
                    </Button>
                    &nbsp;&nbsp;
                    <Button color="inherit" size="small" onClick={onConfirmation}>
                        {confirmText}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
