import { Dialog, IconButton, DialogTitle, DialogContent, Button, Box, TextField, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import { useForm } from 'react-hook-form';

export const NewsletterDialog = ({ open, handleClose, handleAddEmail }) => {
    const schema = object().shape({
        email: string().email('Please enter a valid email.').required('Please enter an email.'),
    });

    const { handleSubmit, formState: { errors, }, reset, register, } = useForm({
        defaultValues: {
            email: '',
        },
        resolver: yupResolver(schema),
    });

    function onSubmit(data) {
        handleAddEmail(data);
        reset();
        handleClose();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Subscribe to our Newsletter</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <Typography variant="h6">
                        Sign up for our newsletter to stay updated with our latest news and offers!
                    </Typography>
                    <Box component="form" sx={{ display: 'flex', gap: 2, width: '100%' }} id='subscribe-to-newsletter-form' onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            {...register('email')}
                            label="Email Address"
                            variant="outlined"
                            fullWidth
                            helperText={errors.email?.message}
                            error={!!errors.email}
                            autoFocus
                        />
                        <Button variant="contained" color="primary" type="submit">
                            Subscribe
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};