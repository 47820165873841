import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Stack, Tooltip } from '@mui/material';
import { RibbonButton, RibbonGroupContainer } from '../../Ribbon';

export const TransmissionActionsRibbonGroup = ({ selectedTsr, expanded }) => {
    const enableClone = !!selectedTsr;

    return (
        <RibbonGroupContainer>

            <Tooltip title="Create a new TSR">
                <span>
                    <RibbonButton
                        label="New"
                        expanded={expanded}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<AddCircleOutlineIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Clone the selected TSR">
                <span>
                    <RibbonButton
                        label="Clone"
                        expanded={expanded}
                        // disabled={!enableClone}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<ContentCopyIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Redirect the selected TSR">
                <span>
                    <RibbonButton
                        label="Redirect"
                        expanded={expanded}
                        // disabled={!enableAdjust}
                        // disabled
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<EditIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Resale the selected TSR">
                <span>
                    <RibbonButton
                        label="Resale"
                        expanded={expanded}
                        // disabled={!enableWithdraw}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<UndoIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Zero the selected TSR">
                <span>
                    <RibbonButton
                        label="Zero"
                        expanded={expanded}
                        // disabled={!enableCorrect}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<CheckIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Withdraw the selected TSR">
                <span>
                    <RibbonButton
                        label="Withdraw"
                        expanded={expanded}
                        // disabled={!enableCancel}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<HighlightOffIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

            <Tooltip title="Terminate the selected TSR">
                <span>
                    <RibbonButton
                        label="Accept"
                        expanded={expanded}
                        // disabled={!enableTerminate}
                        disabled={true} //disabled until we hook up functions from TSR Activity
                        icon={<NotInterestedIcon
                            fontSize='small'
                        />}
                    />
                </span>
            </Tooltip>

        </RibbonGroupContainer>
    )
};