import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/dayAheadStyles.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SaveTemplateDialog from './SaveTemplateDialog';
import { ProfilePicker } from '../profiles/ProfilePicker';
import { ProfileToolPanel } from '../ToolPanels/ProfileToolPanel';
import { ServiceToolPanel } from '../ToolPanels/ServiceToolPanel';
import VolumeAggregationComponent from './volumeAggregationComponent.jsx';
import VolumeUsedComponent from './volumeUsedComponent.jsx';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TemplateToolPanel } from '../ToolPanels/TemplateToolPanel';
import { Typography } from '@mui/material';
import CustomNoRowsOverlay from './customNoRowsOverlay.js';
import { useProfilePanel } from '../ToolPanels/useProfilePanel';
import { Profiles } from './Profiles';
import { useLocalData } from '../useLocalData';
import useHeader from '../useHeader';
import { AgGridReact } from 'ag-grid-react';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import useGridLayout from '../useGridLayout';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';

export default forwardRef((props, ref) => {
  const { addTemplate, templates, deleteTemplate, timezone } = props;
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const [updateCurrentRow, profilePanel, setRowFromProfile] = useProfilePanel(ref);
  const storageLocation = 'day-ahead-reassignments-layout';
  useLocalData(storageLocation, ref);
  const [templateData, setTemplateData] = useState([]);
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const transmissionTemplateTypeId = 2;
  const headers = useHeader();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  //const [reassignmentRowData, setReassignmentRowData] = useState([]);

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";


  const onProfileSave = () => {

  }

  const addReassignmentItems = (addIndex) => {
    const newItems = [
      createNewReassignmentRowData(),
    ];
    addReassignmentRow(newItems);

  };

  const addReassignmentRowCopy = (addIndex) => {
    ref.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = ref.current.api.getSelectedRows();
    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      newRow['New Aref'] = "";
      newRow['Status'] = "";
      newRow['Result'] = "";
      return newRow;
    })
    addReassignmentRow(data);
  };

  const onRemoveReassignmentSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
  };

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    ref.current.api.forEachNode(node => rowData.push(node.data));
    let message = 'JSON for entire grid generated.';

    alert(`Selected nodes: ${JSON.stringify(rowData)}`);
    message = `Selected nodes: ${JSON.stringify(rowData)}`;
    enqueueSnackbar(message);

  }

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  async function onSaveDataButtonClick() {

    setButtonsDisabled(true);
    setSubmitButtonDisabled(true);

    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();
    let message = 'Submitting reassignment to OASIS...';
    enqueueSnackbar(message);

    message = 'Reassignment sent to OASIS database...';

    const rowData = [];
    //ref.current.api.forEachNode(node => rowData.push(node.data));

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      rowData.push(newData);
    })

    //enqueueSnackbar(JSON.stringify(rowData));

    const response = await axios.post(
      `${apiUrlPrefix}/CrystalBall/TSR/BulkTransAssignDayAhead?UserGuid=${guid}&timeZone=${renderTimezone(timezone)}&Caller=PowerStation.DayAhead`,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).catch(err => function () { message = `Error day ahead reassignments.  Status: ${err.response?.status}. Message: ${err}` });

    //setReassignmentRowData(response.data);
    ref.current.api.setRowData(response.data);

    //if (ref.current.api && reassignmentRowData.length > 0) {
    ref.current.api.forEachNode(function (node) {
      //enqueueSnackbar(JSON.stringify(node.data.isSelected));
      node.setSelected(node.data.isSelected);
    })
    //}

    ref.current.api.hideOverlay();

    enqueueSnackbar(message);
    setButtonsDisabled(false);
  }

  let newCount = 1;
  function createNewReassignmentRowData() {
    const newData = {

    };
    newCount++;
    return newData;
  }

  function addReassignmentRow(newRowData) {
    const res = ref.current.api.applyTransaction({
      add: newRowData,
    });
  };

  function handleSaveTemplateClose() {
    setSaveTemplateOpen(false);
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },

  };



  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const statusResult = (params) => {

    var status = "Success";
    if (params.data.Status === "" & params.data.Result !== "") { status = "ERROR" }
    else if (params.data.Status === "Pre-Queued" & params.data.Result !== "") { status = "ERROR" }
    else { status = params.data.Status }

    return status;
  };

  var hourValues = {
    values: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
  }

  function onRowDragStart(event) {
    event.rowNode.setSelected(true);
    const rowData = ref.current.api.getSelectedRows();
    const jsonData = JSON.stringify(rowData);
    event.dragEvent.dataTransfer.setData('application/json', jsonData);
  };

  function onRowDataUpdated(params) {
    const rowNum = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowNum === 0);

  }

  function onSelectionChanged(params) {

    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);

  }

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  // const noRowsOverlayComponentParams = useMemo(() => {
  //   return {
  //     noRowsMessageFunc: () => 'Sorry - no rows! at: ' + new Date(),
  //   };
  // }, []);

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }


  const baseColDefs = [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      rowDrag: true,
      //dndSource: true,
      //dndSourceOnRowDrag: onRowDragStart,
      initialWidth: 150,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      initialWidth: 100,
      field: "Status",
      tooltipField: "Result",
    },
    {
      editable: false,
      headerName: "TransAref",
      enableRowGroup: true,
      initialWidth: 100,
      field: "Reassigned Ref",
      filter: "agSetColumnFilter",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "TP",
      initialWidth: 80,
      field: "Provider",
    },
    {
      editable: true,
      headerName: "Cust",
      initialHide: false,
      initialWidth: 80,
      field: "Customer Code",
    },
    {
      cellEditor: "datePicker",
      cellEditorPopup: true,
      editable: true,
      headerName: "Start D",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 80,
      field: "Start Date",
    },
    // {
    //   editable: true,
    //   headerName: "Start T",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
    //   initialHide: false,
    //   initialWidth: 80,
    //   field: "Start Time",
    // },
    {
      cellEditor: "datePicker",
      cellEditorPopup: true,
      editable: true,
      headerName: "Stop D",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 80,
      field: "Stop Date",
    },
    // {
    //   editable: true,
    //   headerName: "Stop T",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
    //   initialHide: false,
    //   initialWidth: 80,
    //   field: "Stop Time",
    // },
    {
      editable: true,
      headerName: "Vol",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 60,
      field: "Volume",
    },
    {
      editable: true,
      headerName: "$$",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 60,
      field: "Price",
    },
    {
      headerName: "Profile",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 60,
      field: "Profile",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...Object.keys(Profiles), 'Custom'],
      },
      valueSetter: setRowFromProfile,
    },
    {
      editable: true,
      headerName: "Seller",
      enableRowGroup: true,
      initialHide: false,
      initialWidth: 80,
      field: "Seller Code",
      filter: "agSetColumnFilter",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "SRef",//Provider	Seller Code	Customer Code	Sale Ref	Preconfirmed	Start	Stop	Profile	Capacity Requested,
      initialHide: false,
      initialWidth: 80,
      field: "Sale Ref",
    },
    {
      editable: true,
      headerName: "Pre",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 60,
      field: "Preconfirmed",
    },
    { editable: true, headerName: "V1", initialHide: true, initialWidth: 40, field: "HEC1" },
    { editable: true, headerName: "V2", initialHide: true, initialWidth: 40, field: "HEC2" },
    { editable: true, headerName: "V3", initialHide: true, initialWidth: 40, field: "HEC3" },
    { editable: true, headerName: "V4", initialHide: true, initialWidth: 40, field: "HEC4" },
    { editable: true, headerName: "V5", initialHide: true, initialWidth: 40, field: "HEC5" },
    { editable: true, headerName: "V6", initialHide: true, initialWidth: 40, field: "HEC6" },
    { editable: true, headerName: "V7", initialHide: true, initialWidth: 40, field: "HEC7" },
    { editable: true, headerName: "V8", initialHide: true, initialWidth: 40, field: "HEC8" },
    { editable: true, headerName: "V9", initialHide: true, initialWidth: 40, field: "HEC9" },
    { editable: true, headerName: "V10", initialHide: true, initialWidth: 40, field: "HEC10" },
    { editable: true, headerName: "V11", initialHide: true, initialWidth: 40, field: "HEC11" },
    { editable: true, headerName: "V12", initialHide: true, initialWidth: 40, field: "HEC12" },
    { editable: true, headerName: "V13", initialHide: true, initialWidth: 40, field: "HEC13" },
    { editable: true, headerName: "V14", initialHide: true, initialWidth: 40, field: "HEC14" },
    { editable: true, headerName: "V15", initialHide: true, initialWidth: 40, field: "HEC15" },
    { editable: true, headerName: "V16", initialHide: true, initialWidth: 40, field: "HEC16" },
    { editable: true, headerName: "V17", initialHide: true, initialWidth: 40, field: "HEC17" },
    { editable: true, headerName: "V18", initialHide: true, initialWidth: 40, field: "HEC18" },
    { editable: true, headerName: "V19", initialHide: true, initialWidth: 40, field: "HEC19" },
    { editable: true, headerName: "V20", initialHide: true, initialWidth: 40, field: "HEC20" },
    { editable: true, headerName: "V21", initialHide: true, initialWidth: 40, field: "HEC21" },
    { editable: true, headerName: "V22", initialHide: true, initialWidth: 40, field: "HEC22" },
    { editable: true, headerName: "V23", initialHide: true, initialWidth: 40, field: "HEC23" },
    { editable: true, headerName: "V24", initialHide: true, initialWidth: 40, field: "HEC24" },
    { editable: true, headerName: "V25", initialHide: true, initialWidth: 40, field: "HEC25" },
    { editable: true, headerName: "P1", initialHide: true, initialWidth: 40, field: "HEP1" },
    { editable: true, headerName: "P2", initialHide: true, initialWidth: 40, field: "HEP2" },
    { editable: true, headerName: "P3", initialHide: true, initialWidth: 40, field: "HEP3" },
    { editable: true, headerName: "P4", initialHide: true, initialWidth: 40, field: "HEP4" },
    { editable: true, headerName: "P5", initialHide: true, initialWidth: 40, field: "HEP5" },
    { editable: true, headerName: "P6", initialHide: true, initialWidth: 40, field: "HEP6" },
    { editable: true, headerName: "P7", initialHide: true, initialWidth: 40, field: "HEP7" },
    { editable: true, headerName: "P8", initialHide: true, initialWidth: 40, field: "HEP8" },
    { editable: true, headerName: "P9", initialHide: true, initialWidth: 40, field: "HEP9" },
    { editable: true, headerName: "P10", initialHide: true, initialWidth: 40, field: "HEP10" },
    { editable: true, headerName: "P11", initialHide: true, initialWidth: 40, field: "HEP11" },
    { editable: true, headerName: "P12", initialHide: true, initialWidth: 40, field: "HEP12" },
    { editable: true, headerName: "P13", initialHide: true, initialWidth: 40, field: "HEP13" },
    { editable: true, headerName: "P14", initialHide: true, initialWidth: 40, field: "HEP14" },
    { editable: true, headerName: "P15", initialHide: true, initialWidth: 40, field: "HEP15" },
    { editable: true, headerName: "P16", initialHide: true, initialWidth: 40, field: "HEP16" },
    { editable: true, headerName: "P17", initialHide: true, initialWidth: 40, field: "HEP17" },
    { editable: true, headerName: "P18", initialHide: true, initialWidth: 40, field: "HEP18" },
    { editable: true, headerName: "P19", initialHide: true, initialWidth: 40, field: "HEP19" },
    { editable: true, headerName: "P20", initialHide: true, initialWidth: 40, field: "HEP20" },
    { editable: true, headerName: "P21", initialHide: true, initialWidth: 40, field: "HEP21" },
    { editable: true, headerName: "P22", initialHide: true, initialWidth: 40, field: "HEP22" },
    { editable: true, headerName: "P23", initialHide: true, initialWidth: 40, field: "HEP23" },
    { editable: true, headerName: "P24", initialHide: true, initialWidth: 40, field: "HEP24" },
    { editable: true, headerName: "P25", initialHide: true, initialWidth: 40, field: "HEP25" },
    {
      editable: false,
      headerName: "Path Name",
      enableRowGroup: true,
      initialWidth: "170",
      initialHide: "true",
      field: "Path_Name",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POR",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfReceipt",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POD",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfDelivery",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Source",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Source",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Sink",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Sink",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Service Inc",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Service_Increment",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Class",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Class",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Type",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Period",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Period",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Window",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Window",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Subclass",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Subclass",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRStartUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRStopUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRequestRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRDealRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRPostingRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRequestType",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRelatedRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRSellerRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRCustName",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRCustEmail",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
  ];

  const { colDefs, loadLayout, } = useGridLayout(storageLocation, ref, baseColDefs)

  const reassignmentSideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      {
        id: 'reassignmentTemplates',
        labelDefault: 'Templates',
        labelKey: 'reassignmentTemplates',
        //iconKey: '',
        toolPanel: 'templateToolPanel',
        toolPanelParams: {
          fieldNames: ['PUD', 'Name'],
          currentTemplates: templates,
          addTemplate: addTemplate,
          deleteTemplate: deleteTemplate
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
      },
      profilePanel,
    ],
    position: 'right',
  };


  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: "agSetColumnFilter",
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <AgGridContainer
      style={{
        height: "40vh",
        width: "98%",
        padding: "5px",
        // clear: "both",
        // overflow: "hidden",
      }}
    >
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveReassignmentSelected()}
            endIcon={<DeleteForeverIcon />}
            id="delete"
            disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Add a new blank row to the grid." arrow placement="top">
          <Button
            endIcon={<AddCircleOutlinedIcon />}
            id="addReassignmentRow"
            variant={buttonVariant}
            //disabled={buttonsDisabled}
            color={buttonColor}
            size={buttonSize}
            onClick={() => addReassignmentItems()}
          >Add New</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            id="addReassignmentRowCopy"
            variant={buttonVariant}
            disabled={buttonsDisabled}
            color={buttonColor}
            size={buttonSize}
            onClick={addReassignmentRowCopy}
          >Add Copy</Button>
        </Tooltip>&nbsp;

        <Tooltip title="Submit all reassignments without New TSR number and check status on TSRs with New TSR numbers." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            disabled={submitButtonDisabled}
            size={buttonSize}
            onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
        </Tooltip>&nbsp;

      </Box>
      {/* <SaveTemplateDialog 
          templateData={templateData}
          open={saveTemplateOpen}
          handleClose={handleSaveTemplateClose}
          transmissionTemplateTypeId={transmissionTemplateTypeId}/> */}
      <AgGridReact
        ref={ref}
        columnDefs={colDefs}
        rowData={[]}
        onGridReady={onGridReady}
        singleClickEdit
        //enterMovesDownAfterEdit={true}
        statusBar={statusBar}
        onCellFocused={updateCurrentRow}
        //noRowsOverlayComponent={noRowsOverlayComponent}
        //noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">No reassignments queued... drag a TSR in to this area or use Trans Assign templates to queue up new reassignments.</span>'}
        rowSelection="multiple"
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        defaultColDef={defaultColDef}
        sideBar={reassignmentSideBar}
        gridOptions={gridOptions}
        tooltipShowDelay={0}
        onSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onRowDataUpdated}
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        animateRows={true}
        //rowData={reassignmentRowData}
        groupSelectsChildren={true}
        components={{
          volumeAggregationComponent: VolumeAggregationComponent,
          volumeUsedComponent: VolumeUsedComponent,
          profilePicker: ProfilePicker,
          //templateToolPanel: ReassignmentTemplatePanel,
          templateToolPanel: TemplateToolPanel,
          profileToolPanel: ProfileToolPanel,
          serviceToolPanel: ServiceToolPanel,
          datePicker: DateCellEditor,
        }}
      />
    </AgGridContainer>
  );
});