import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { useTheme } from '@mui/material/styles';

export default ({ name, control, ...inputProps }) => {
  const theme = useTheme();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            value={value}
            onChange={onChange}
            ampm={false}
            {...inputProps}
            slotProps={{
              ...inputProps.slotProps,
              layout: {
                sx: {
                  ul: {
                    '::-webkit-scrollbar': {
                      width: '10px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: '20px',
                      border: '3px solid transparent',
                      backgroundClip: 'padding-box',
                    },
                    '::-webkit-scrollbar-track': {
                      background: 'transparent',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      border: 0,
                    },
                  },
                },
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};