import { Box } from "@mui/material"
import { CreateDealButton, EditDealButton, ConfirmDealButton, RollbackDealButton, VoidDealButton, ExtendDealButton } from "../../RibbonButtons"
import { RibbonGroupContainer } from "../../Ribbon"

export const DealActionsGroup = ({ expanded, formId, selectedRow, handleFetchData, gridRef, }) => {

    return (
        <RibbonGroupContainer spacing={1}>
            <CreateDealButton
                expanded={expanded}
                formId={formId}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
            />

            <ConfirmDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

            <RollbackDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            />

            <VoidDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                gridRef={gridRef}
            />

            {/* <ExtendDealButton
                expanded={expanded}
                deal={selectedRow}
                disabled={!selectedRow}
                formId={formId}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            /> */}

        </RibbonGroupContainer>
    )
}