import axios from "axios";
import { useEffect, useState } from "react";
import { IPContext } from "./IPContext";

export function IPProvider({ children }) {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json")
      .then((response) => {
        setIp(response.data.ip);
      }).catch(console.log);
  }, []);

  return (
    <IPContext.Provider value={ip}>
      {children}
    </IPContext.Provider>
  )
}