import { Box } from '@mui/material';
import { DashboardTabs } from './DashboardTabs';
import { DashboardViewContainerLazy } from './DashboardViewContainerLazy';
import { DashboardProvider } from './useDashboard';
import { LoadingOverlay, NoDataOverlay } from './ViewOverlays';
import { ViewSelector } from './ViewSelector';

export const DashboardLazy = ({ dashboardKey, useShared = false, ViewComponent, AddNewOrUpdateDialog, visible, }) => {
    return (
        <DashboardProvider visible={visible} dashboardKey={dashboardKey} useShared={useShared} AddNewOrUpdateDialog={AddNewOrUpdateDialog}>
            <Box
                className="flex-column"
                sx={{
                    p: '7px',
                    width: '100%',
                }}
            >
                <Box className="flex-column" >
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <ViewSelector />
                        < DashboardTabs />
                    </Box>
                    <DashboardViewContainerLazy
                        ViewComponent={ViewComponent}
                    />
                </Box>
                <NoDataOverlay />
                <LoadingOverlay />
            </Box>
        </DashboardProvider >
    );
}

