import React, {useMemo} from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useSnackbar } from "notistack";
import { useState, useEffect } from 'react';
import { apiUrlPrefix } from "../authConfig";
import useHeader from "./useHeader";
import { Marker, Circle, MarkerClusterer, Polyline } from '@react-google-maps/api';
import { useHubMessages } from './HubContext/useHubMessages';
import { Grid, Dialog, Autocomplete, DialogActions, DialogContent, Box, Button, DialogTitle, TextField, } from '@mui/material';
import axios from 'axios';

const center = {
  lat: 39.099724,
  lng: -94.578331
};


function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCL7pD9-yZH8UpCnXDSvJ3uC39YZCzeoR4"
  })

  const [map, setMap] = React.useState(null)
  const hubMessage = useHubMessages(); 
  const [servicePoints, setServicePoints] = useState([]);
  const [paths, setPaths] = useState([]);
  const [time, setTime] = useState(Date.now());
  const [seconds, setSeconds] = useState(0);
  const header = useHeader();
  const [scale, setSclae] = useState(1);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [newMarker, setNewMarker] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    if(hubMessage.message === 'PorPodPointsUpdate') {
      // enqueueSnackbar('Changes detected.  Map updating...')
      getData()
      // getPolylineData()
    }
  }, [hubMessage]);

  async function getData() {
    const response = await fetch(`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchPorLocations`,
    {
      headers: header,
    });
    const data = await response.json();
    setServicePoints(data);
  }

    useEffect(() => {
        getData();
        let abortController = new AbortController();  
        return () => {  
          abortController.abort();  
        }  

    }, []); // <- you may need to put the setBooks function in this array

    async function getPolylineData() {
      const response = await fetch(`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchPathLocations`,
      {
        headers: header,
      });
      const data = await response.json();
      setPaths(data);
    }
  
      useEffect(() => {
          getData();
          let abortController = new AbortController();  
          return () => {  
            abortController.abort();  
          }  
  
      }, []); // <- you may need to put the setBooks function in this array

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const options = {
    //imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
    disableDoubleClickZoom: true,
  }

  const mapContainerStyle = {
    height: "94vh",
    width: "100%"
  }
  
  const markerOptions = {
    // strokeColor: '#FF0000',
    // strokeOpacity: 0.8,
    // strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    // clickable: false,
    // draggable: false,
    // editable: false,
    visible: true,
    // radius: 30000,
    // zIndex: 1
  };

  const lineOptions = {
    strokeColor: 'grey',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: 'lightgrey',
    fillOpacity: 0.8,
  };

  function handleAddNew(e) {
    const { lat, lng } = e.latLng;
    setNewMarker({
      lat: lat(),
      lng: lng(),
    });
    setOpenAddNew(true);
  }

  function handleSave(marker=newMarker) {
    setOpenAddNew(false);
    const { name, lat, lng } = marker;

    const options = {
      headers: header,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SavePorLocations&parm=${encodeURIComponent(name)}&parm=${lat}&parm=${lng}`,
    }

    axios(options).then(response => { 
      getData();
    }).catch(err => { 
      enqueueSnackbar(`Failed to save. ${err.message}`); 
    });
  }

  function handleDragEnd(name, e) {
    const { lat, lng } = e.latLng;
    handleSave({
      name: name,
      lat: lat(),
      lng: lng(),
    })
  }

  const names = useMemo(() => servicePoints.map(point => point.Name), [servicePoints])

  return isLoaded ? (<>
      <Dialog open={openAddNew}  maxWidth={'xs'} fullWidth >
        <DialogTitle>Add New</DialogTitle>
        <DialogContent style={{ height: '100%', display: 'flex', }}>
          <Box sx={{ p:1, height: '100%', width: '100%'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  value={newMarker.name ?? ''}
                  options={names}
                  onChange={(_, newValue) => setNewMarker({
                    ...newMarker,
                    name: newValue,
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Name"
                    />
                  )}
                /> 
              </Grid>
              {/*<Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  options={['POR', 'POD', 'POR/POD']}
                  onChange={(_, newValue) => setNewMarker({
                    ...newMarker,
                    type: newValue,
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type"
                    />
                  )}
                />
                  </Grid>*/}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpenAddNew(false)}>Cancel</Button>&nbsp;
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="primary" onClick={() => handleSave()}>Save & Close</Button>
        </DialogActions>
      </Dialog>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDblClick={handleAddNew}
        options={options}
      >
        {servicePoints && (servicePoints.filter(servicePoint => servicePoint.Latitude ).map((servicePoint, index) => (
          <Marker
            // options={markerOptions}
            icon={servicePoint.fixed ? "http://maps.google.com/mapfiles/ms/icons/green.png" : "http://maps.google.com/mapfiles/ms/icons/blue.png"}
            draggable
            onDragEnd={(e) => handleDragEnd(servicePoint.Name, e)}
            // clusterer="averageCenter"
            label={servicePoint.Name}
            // labelStyle={{color: 'grey'}}
            title={servicePoint.Name}
            // style={servicePoint.color}
            position={{ lat: servicePoint.Latitude, lng: servicePoint.Longitude }}
          />
        )))}
        {paths && (paths.map((path, index) => (
          <Polyline
            key={index}
            path={[{ lat: path.porLatitude, lng: path.porLongitude},{lat: path.podLatitude, lng: path.podLongitude}]}
            options={lineOptions}
          />
        )))}
      </GoogleMap>
  </>) : <></>
}

export default React.memo(MyComponent)
