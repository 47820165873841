import { useState, useEffect } from 'react';
import PPWTrans from '../components/TransNow/PPW/PPWTrans';
import TSRActivity from './TSRActivity';
import Box from '@mui/material/Box';
import BulkReassignmentGrid from '../components/TransNow/BulkOriginalRequests';
import TransNowAuditGrid from '../components/TransNow/TransNowAuditGrid';
import RealTimeGrids from '../components/TransNow/RealTimeGrids';
import DayAheadGrids from '../components/TransNow/DayAheadGrids';
import TransNowAppMenu from '../components/TransNow/TransNowAppMenu';
import { userGroups } from "../authConfig";
import RealTimeOriginalRequests from '../components/TransNow/RealTimeOriginalRequests';
import TariffPrices from '../components/TransNow/TariffPrices';
import TransNowHelp from '../components/Help';
import '../styles/transnowStyles.css'
import DayAheadRedirects from '../components/TransNow/DayAhead/Redirects';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import DivGuard from '../components/Guards/DivGuard';
import { PathMonitor } from '../components/PathMonitor/PathMonitor';
import { useParams } from 'react-router';
import FlexCollapse from '../components/FlexCollapse';
import { Outlet, useNavigate } from 'react-router';

const viewStorageKey = 'transNowView';

export const TransNow = () => {
    const savedView = localStorage.getItem(viewStorageKey);
    const params = useParams();
    const view = params.view;
    const navigate = useNavigate();

    useEffect(() => {
        if (!view) {
            const newView = savedView ?? 'transnowhelp';
            navigate(`/transnow/${newView}`);
        }
    }, [view, savedView, navigate]);

    return (
        <CriteriaProvider>
            <Box sx={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden', }}>
                <TransNowAppMenu selected={view} />
                <Outlet />
            </Box>
        </CriteriaProvider>
    )
}

export const TransNowViews = () => {
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    const MenuOptions = [
        {
            name: 'tsractivity',
            component: <TSRActivity visible={view === 'tsractivity'} application='transnow' />
        },
        {
            name: 'audit',
            component: <TransNowAuditGrid />
        },
        {
            name: 'reassignments',
            component: <BulkReassignmentGrid />
        },
        {
            name: 'dayahead',
            component: <DayAheadGrids />
        },
        {
            name: 'originalrequests',
            component: <BulkReassignmentGrid />
        },
        {
            name: 'redirects',
            component: <BulkReassignmentGrid />
        },
        {
            name: 'realtime',
            component: <DivGuard groups={[userGroups.transnow, userGroups.realtimegrids, userGroups.realtimeoriginals, userGroups.tea, userGroups.teaadvanced]}>
                <RealTimeGrids visible={view === 'realtime'} />
            </DivGuard>
        },
        {
            name: 'realtimeoriginalrequests',
            component: <RealTimeOriginalRequests />
        },
        {
            name: 'tariffprices',
            component: <TariffPrices />
        },
        {
            name: 'transnowhelp',
            component: <TransNowHelp appName={'TransNow'} />
        },
        {
            name: 'ppwtrans',
            component: <PPWTrans />
        },
        {
            name: 'dayaheadredirects',
            component: <CriteriaProvider>
                <DayAheadRedirects visible={view === 'dayaheadredirects'} />
            </CriteriaProvider>
        },
        {
            name: 'transactionqueue',
            component: <CriteriaProvider>
                <PathMonitor application={'transnow'} />
            </CriteriaProvider>
        }
    ]

    return (
        <Box
            className='flex-column'
            sx={{
                p: '0px',
                overflowY: 'auto',
            }}
        >
            {MenuOptions.map(option =>
                loadedViews[option.name] && <FlexCollapse in={view === option.name}>
                    {option.component}
                </FlexCollapse>
            )}
        </Box>
    )
}
