import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { useDashboardFetch } from "../useDashboardFetch"
import { useDashboardLayout } from "../useDashboardLayout"
import { TransDealSummaryRibbon } from "./Ribbon/TransDealSummaryRibbon"
import * as yup from 'yup';
import { ViewContextProvider } from "../ViewContextProvider"
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { useUserInfo } from "../../UserInfoContext"

const schema = yup.object().shape({
    startDate: yup.date().required('Start Date is required'),
    endDate: yup.date().required('End Date is required'),
});


export const TransDealSummaryView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-trans-deal-summary-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const headers = useHeader();
    const { logAction } = useActionAudit();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const [selectedRow, setSelectedRow] = useState();
    const { captureLayout } = useLayoutFunctions();
    const userInfo = useUserInfo();
    const defaults = useMemo(() => ({
        ...view,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
    }), [view]);

    const baseColDefs = useMemo(() => ([
        { field: "dealID", initialHide: true, headerName: "Deal ID" },
        { field: "scheduleID", initialHide: true, headerName: "Schedule ID" },
        { field: "TagIdx", initialHide: true, headerName: "Tag Idx" },
        { field: "Old_Deal_ID", headerName: "Deal Name" },
        { field: "Trade_Date", initialHide: true, headerName: "Trade Date" },
        { field: "AssignmentRef", headerName: "Assignment Ref" },
        { field: "PriceUnit", headerName: "Price Units" },
        { field: "ServiceIncrement", headerName: "Service Inc" },
        { field: "Provider", headerName: "Provider" },
        { field: "CustomerCode", headerName: "Owner" },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            initialHide: true,
            headerName: 'Book',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            initialHide: true,
            headerName: 'Strategy',
        },
        // { field: "MW", headerName: "MW" },
        // {
        //     field: "Total_MWh",
        //     headerName: "Total MWh",
        //     valueFormatter: (params) => {
        //         const value = params.value;
        //         if (value !== null && value !== undefined && !isNaN(value)) {
        //             // Format value with commas and up to 3 decimal places
        //             return parseFloat(value).toLocaleString('en-US', {
        //                 minimumFractionDigits: 0,
        //                 maximumFractionDigits: 3
        //             });
        //         }
        //         return value;
        //     }
        // },
        // {
        //     field: "DealMWh",
        //     headerName: "Deal MWh",
        //     valueFormatter: (params) => {
        //         const value = params.value;
        //         if (value !== null && value !== undefined && !isNaN(value)) {
        //             // Format value with commas and up to 3 decimal places
        //             return parseFloat(value).toLocaleString('en-US', {
        //                 minimumFractionDigits: 0,
        //                 maximumFractionDigits: 3
        //             });
        //         }
        //         return value;
        //     }
        // },
        // {
        //     field: 'DealSettlement',
        //     headerName: 'Deal Cost',
        //     initialHide: true,
        //     valueFormatter: (params) => {
        //         const value = params.value;
        //         if (value !== null && value !== undefined) {
        //             // Format value as USD currency with commas
        //             return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        //         }
        //         return value;
        //     }
        // },
        {
            field: 'TransmissionPrice',
            headerName: 'Trans Cost',
            initialHide: false,
            enableValue: true,    // Enable value aggregation for these columns
            aggFunc: 'sum',       // Set 'sum' as the default aggregation function
            allowedAggFuncs: ['sum', 'min', 'max', 'avg',],
            enableRowGroup: false,
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            field: "TransAllocationMWh",
            headerName: "Allocation MWh",
            enableValue: true,    // Enable value aggregation for these columns
            aggFunc: 'sum',       // Set 'sum' as the default aggregation function
            allowedAggFuncs: ['sum', 'min', 'max', 'avg',],
            enableRowGroup: false,
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined && !isNaN(value)) {
                    // Format value with commas and up to 3 decimal places
                    return parseFloat(value).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3
                    });
                }
                return value;
            }
        },
        { field: "PointOfReceipt", headerName: "TSR POR" },
        { field: "PointOfDelivery", headerName: "TSR POD" },
        // {
        //     field: 'por',
        //     headerName: 'Deal POR',
        // },
        // {
        //     field: 'pod',
        //     headerName: 'Deal POD',
        // },
        { field: "RequestType", headerName: "Request Type" },
        { field: "Service", headerName: "Service" },
        { field: "NERCcurtailmentPriority", headerName: "NERC CP" },
        { field: "Trader", headerName: "Trader" },
        { field: "Modification_User", headerName: "Modification User" },
        { field: "Column1", headerName: "Column 1" },
        { field: "RelatedRef", headerName: "Related Ref" },
        { field: "Counterparty", headerName: "Counterparty" },
        // { field: "Owner", headerName: "Owner" },
        { field: "Creator", headerName: "Creator" },
        // { field: "Buying_Trader", headerName: "Buying Trader" },
        // { field: "Executing_Trader", headerName: "Executing Trader" },
        // { field: "Selling_Trader", headerName: "Selling Trader" },
        { field: 'Type_F_P', headerName: 'Transaction', },
        { field: "Transaction_Type", headerName: "Transaction Type" },
        // { field: "Trans_Price", headerName: "Transaction Price" },
        {
            field: "Deal_Status",
            headerName: "Deal Status",
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED', 'DRAFT']
            },
        }
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const getRowId = useCallback(({ data }) => `${data.dealID} + ${data.TagIdx} + ${data.ScheduleID} + ${data.AssignmentRef}`, []);

    const { loadAndApplyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchTransmissionDealSummary_v5',
        fetchParamKeys: ['userGuid', 'startDate', 'endDate', 'timezone', 'tenantId'],
        gridRef,
        getRowId,
    });

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef,
        layoutStorageKey,
        context: view,
        baseColDefs,
        defaultColDef,
    });

    function handleFetchData(data) {
        logAction('User fetched Trans Deal Summary Data', 'Trans Deal Summary', data);
        loadAndApplyData({
            ...data,
            userGuid: headers.userGuid,
            startDate: dayjs(data.startDate).format('MM/DD/YYYY'),
            endDate: dayjs(data.endDate).format('MM/DD/YYYY'),
            tenantId: userInfo.tenantId,
        });
    }

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        // if (firstVisibleColumn) {
        //     firstVisibleColumn.checkboxSelection = true;
        // }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const handleSelectionChanged = useCallback(() => {
        const selected = gridRef.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    const toolbarFormId = `deal-rizz-trans-deal-summary-toolbar-${view.id}`;

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column'>
                <TransDealSummaryRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                    selectedRow={selectedRow}
                />
                <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                    <AgGridReact
                        {...defaultGridOptions}
                        containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                        ref={gridRef}
                        enableCharts
                        getRowId={getRowId}
                        columnDefs={styledColDefs}
                        rowSelection="single"
                        rowMultiSelectWithClick
                        onSelectionChanged={handleSelectionChanged}
                        onFirstDataRendered={handleFirstDataRendered}
                        onCellMouseOver={handleCellMouseOverHighlight}
                        groupTotalRow={"bottom"}
                        suppressAggFuncInHeader={true}
                        onGridReady={onGridReady}
                        columnTypes={columnTypes}
                        statusBar={defaultStatusBar}
                        sideBar={sideBar}
                        components={{
                            layoutToolPanel: LayoutToolPanel,
                        }}
                    />
                </AgGridContainer>
            </Box>
        </ViewContextProvider>
    )
});
