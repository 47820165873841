import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { AgGridContainer } from "../../../AgGrid/AgGridContainer";
import { ProfileChart } from "./ProfileChart";
import { ProfileCreationForm } from "./ProfileCreationForm";
import { ProfileEditingForm } from './ProfileEditingForm';
import { ProfileGrid } from "./ProfileGrid";
import { useProfileTemplates } from "./useProfileTemplates_v2";

const ProfileEditor = (props) => {
    const { disabled = false, profile, setProfile, editMode, defaultTimezone } = props;
    const chartRef = useRef();

    function refreshChart() {
        chartRef.current?.refresh();
    }

    const { generateTransmissionBlocksUsingTemplate, updateProfileDataWithTimezone } = useProfileTemplates();

    const handleUpdateProfile = (formData) => {
        let targetTz = editMode ? formData.timezone : defaultTimezone;
        let referenceTz = (editMode && formData.oldTimezone) ? formData.oldTimezone : defaultTimezone;

        const term = formData.Term;
        if (term?.toLowerCase().includes('rockies')) {
            targetTz = 'America/Denver';
            referenceTz = 'America/Los_Angeles';
        }

        let unsortedData = [];
        if (editMode) {
            //in edit mode we don't want to overwrite the profile, just update the data to fit the start and stop times and apply the new timezone
            unsortedData = updateProfileDataWithTimezone(formData, profile, referenceTz, targetTz);
        } else {
            unsortedData = generateTransmissionBlocksUsingTemplate(formData, referenceTz, targetTz);
        }

        const newData = unsortedData.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)));
        setProfile(newData);
    }

    return (
        <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Accordion
                defaultExpanded
                disableGutters
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Profile Chart</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ position: 'relative', height: 200, }}>
                        <ProfileChart ref={chartRef} profileData={profile} />
                    </Box>
                </AccordionDetails>
            </Accordion>
            {editMode
                ? <ProfileEditingForm disabled={disabled} setProfile={setProfile} handleUpdateProfile={handleUpdateProfile} />
                : <ProfileCreationForm disabled={disabled} setProfile={setProfile} handleUpdateProfile={handleUpdateProfile} />
            }
            <AgGridContainer style={{ flexGrow: 1, }} >
                <ProfileGrid refreshChart={refreshChart} data={profile} />
            </AgGridContainer>
        </Box >
    )
}

export default ProfileEditor;
