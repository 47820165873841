import { Box } from "@mui/material";
import { EditDealButton } from "../../RibbonButtons";
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from "../../Ribbon";

export const DealActionsGroup = ({ expanded, formId, selectedRow, }) => {
    const { watch } = useFormContext();
    const timezone = watch('timezone');

    return (
        <RibbonGroupContainer>
            <EditDealButton
                expanded={expanded}
                disabled={!selectedRow}
                deal={selectedRow}
                formId={formId}
                fetchDealData={true}
                timezone={timezone}
            />
        </RibbonGroupContainer>
    )
}