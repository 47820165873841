import React, { useEffect, useState, useRef, useMemo } from 'react';
import { DatabaseLayoutToolPanel } from "../ToolPanels/DatabaseLayoutToolPanel.js";
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useRemoteLayout } from '../useRemoteLayout';
import { useData } from '../useData';
import { AgGridReact } from 'ag-grid-react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { Box, Button } from '@mui/material';
import FileUploaderDialog from './FileUploaderDialog';
import axios from "axios";
import { sideBar } from './bidmanUtilFunctions.js'
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import useHeader from '../useHeader.js';
import { AgGridContainer } from '../AgGrid/AgGridContainer.js';

export default React.memo(BidmanPivot);

function BidmanPivot({ node, oprDay, refreshAll, triggerRefresh }) {
  const { nodeId, gridDataApiEndpoint, submitApiEndpoint } = node;
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const [fileUploaderOpen, setFileUploaderDialogOpen] = useState(false);
  const gridRef = useRef();
  const { rowData, getData } = useData();

  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  }


  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const header = useHeader();

  const handleFileUploaderDialogOpen = () => {
    setFileUploaderDialogOpen(true);
  };

  const handleFileUploaderDialogClose = () => {
    setFileUploaderDialogOpen(false);
  };

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
  };

  const onBtCopyRange = () => {
    gridRef.current.api.copySelectedRangeToClipboard();
  };

  const onGetRowButtonClick = e => {
    const selectedNodes = gridRef.current.api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    enqueueSnackbar(`Selected nodes: ${JSON.stringify(selectedData)}`)
  }

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    enqueueSnackbar(`Selected nodes: ${JSON.stringify(rowData)}`)

  }

  async function handleSaveButtonClick() {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    } else {
      url = `${apiUrlPrefix}/${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    }


    axios.post(
      url,
      JSON.stringify(rowData),
      {
        headers: header,
      }
    ).then(response => {
      let message = 'Bids saved to database.';
      if (response.data !== 'Success') {
        message = response.data;
      } else {
        setDisableSaveButton(true);
      }
      enqueueSnackbar(message);
      triggerRefresh({ market: node.market });
    }).catch(err => enqueueSnackbar(`Error saving Bidman Pivot grid.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`));
  }


  // enables the fill handle
  const enableFillHandle = true;

  // enables undo / redo
  const undoRedoCellEditing = true;

  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;

  // enables flashing to help see cell changes
  const enableCellChangeFlash = true;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const denseGrid = 'dense-grid';
  const hourCells = 'hour-cells';

  const groupDefaultExpanded = -1;

  const onRefreshBtn = () => {
    getData(loadDataUri);
  };

  const rowClassRules = {
    //'row-fail': 'data.rowClass === "problem-row"',
    'row-fail': (params) => {
      return params.data?.rowClass === 'problem-row';
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    getData(loadDataUri);
    return () => {
      abortController.abort();
    }
  }, [oprDay]);

  useEffect(() => {
    if (refreshAll.market === node.market) { getData(loadDataUri); }
  }, [refreshAll]);

  function editableCheck(params) {
    if (params.data.Version === "Scheduled") {
      return true; // true/false based on params (or some other criteria) value
    } else {
      return false;
    }
  }

  const colDefs = [
    {
      editable: false,
      headerName: "Market",
      enableRowGroup: true,
      field: "market",
      sortable: true,
      filter: true,
      rowGroup: true,
    },
    {
      editable: false,
      headerName: "Subaccount",
      enableRowGroup: true,
      field: "SubAccount",
      sortable: true,
      filter: true,
      rowGroup: true,
    },
    {
      editable: false,
      headerName: "Category",
      enableRowGroup: true,
      field: "category",
      sortable: true,
      filter: true,
      rowGroup: true,
    },
    {
      editable: false,
      headerName: "Bid Type",
      enableRowGroup: true,
      initialHide: true,
      field: "Bid_Type",
      sortable: true,
      filter: true,
      rowGroup: true,
    },
    {
      editable: false,
      headerName: "Location",
      enableRowGroup: true,
      rowGroup: true,
      field: "InternalName",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Version",
      enableRowGroup: true,
      pivot: true,
      field: "Version",
    },
    {
      suppressMenu: true,
      editable: false,
      headerName: "Total Bids",
      field: "TotalBids",
    },
    {
      filter: true,
      enableValue: true,
      aggFunc: "count",
      cellClass: hourCells,
      suppressMenu: true,
      editable: editableCheck,
      headerName: "HE1",
      initialWidth: 2,
      field: "HEQ1",
    },
    {
      filter: true,
      enableValue: true,
      aggFunc: "count",
      cellClass: hourCells,
      suppressMenu: true,
      editable: editableCheck,
      headerName: "HE2",
      initialWidth: 2,
      field: "HEQ2",
    },
    {
      filter: true,
      enableValue: true,
      aggFunc: "count",
      cellClass: hourCells,
      suppressMenu: true,
      editable: editableCheck,
      headerName: "HE3",
      initialWidth: 2,
      field: "HEQ3",
    },
    {
      cellClass: hourCells,
      suppressMenu: true,
      editable: editableCheck,
      headerName: "HE4",
      initialWidth: 2,
      field: "HEQ4",
    },
  ];

  function updateColDefs(defs) {
    defs.forEach(def => {
      const num = parseInt(def.field.match(/\d+/)); //parse the column field for an int
      if (num) {
        def.editable = (params) => editableCheck(params);
      }
    });

    return (defs);
  }


  const { dbLayoutPanel, loadLayout, } = useRemoteLayout(gridRef, nodeId, colDefs, updateColDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  function onCellValueChanged(params) {
    setDisableSaveButton(false);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: false,
    flex: 1,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout(params.api);
  }

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <FileUploaderDialog
          fileUploaderOpen={fileUploaderOpen}
          handleFileUploaderDialogClose={handleFileUploaderDialogClose}
          oprDay={oprDay}
          nodeId={nodeId}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <Button
            onClick={() => onBtCopyRows()}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Ctrl-c</Button>&nbsp;
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineOutlinedIcon />}
            id="undoBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={() => onBtCopyRange()}
          >
            Copy Range
          </Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetRowButtonClick}>Get Selected</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetGridDataButtonClick}>Get Data</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleFileUploaderDialogOpen}
          >IMPORT BIDS</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleSaveButtonClick}
            disabled={disableSaveButton}
          >Save All</Button>&nbsp;
        </Box>

        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          rowData={rowData}
          sideBar={sideBar}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          ref={gridRef}
          rowClassRules={rowClassRules}
          rowSelection="multiple"
          pivotMode={true}
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          enableCellChangeFlash={enableCellChangeFlash}
          groupDefaultExpanded={groupDefaultExpanded}
          defaultColDef={defaultColDef}
          singleClickEdit
          rowGroupPanelShow={'always'}
          enableRangeSelection={true}
          animateRows={true}
          groupSelectsChildren={true}
          components={{
            dbLayoutToolPanel: DatabaseLayoutToolPanel
          }}
        />
      </AgGridContainer>
    </div>
  );


};

// export default function BidmanGridExport({ nodeId, gridDataAPIendpoint }) {
//   return <BidmanGrid />;
// }

