import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import {Box, Paper, Typography, Divider, Tooltip, Chip, List, ListItem, } from "@mui/material"
import { TooltipProps, tooltipClasses } from "@mui/material"
import EventItemTooltip from "./EventItemTooltip";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import QueueIcon from '@mui/icons-material/Queue';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BookIcon from '@mui/icons-material/Book';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import _ from 'lodash';

function EventItem(props) {
  const [event, setEvent] = useState(props.event);

  const increment = event.Service_Increment?.toLowerCase() ?? 'hourly';
  const incrementClass = `${increment}-increment-event`;
  const [cellClass, setCellClass] = useState(incrementClass);

  const {
    rowId,
    sx,
    boxSx,
    elevation,
    isMonthMode,
    onClick,
    onDragStart
  } = props

  useEffect(() => {
    const hasChange =  !_.isEqual(
      {...props.event, flash: false, batchCount: 0,}, //To ignore certain event properties set them to always be the same in these objects
      {...event, flash: false, batchCount: 0}
    );
    if(hasChange) {
      setEvent({
        ...props.event,
      })
      setCellClass(`${incrementClass} flashing-event`);
    } else {
      setCellClass(incrementClass)
    }
  }, [props.event])

  const statusIcon = (status) => {
    const iconSx = { fontSize: 'small' };

    switch (status?.toLowerCase()) {
      case 'confirmed':
        return <CheckCircleIcon sx={iconSx}/>
      case 'invalid':
        return <FmdBadIcon sx={iconSx}/>
      case 'withdrawn':
        return <RemoveDoneIcon sx={iconSx}/>
      case 'queued':
        return <QueueIcon sx={iconSx}/>
      case 'received':
        return <CallReceivedIcon sx={iconSx}/>
      case 'refused':
        return <ThumbDownIcon sx={iconSx}/>
      case 'retracted':
        return <RemoveCircleIcon sx={iconSx}/>
      case 'study':
        return <BookIcon sx={iconSx}/>
      case 'queued for staging':
        return <AlarmOnIcon sx={iconSx}/>
      case 'success':
        return <RunCircleIcon sx={iconSx}/>
      case 'staged':
        return <RunCircleIcon sx={iconSx}/>
      default:
        return <QuestionMarkIcon sx={iconSx}/>;
    }
  }

  return (
    <EventItemTooltip event={event}>
      <Paper
        sx={sx}
        draggable
        onClick={onClick}
        onDragStart={onDragStart}
        elevation={elevation || 0}
        key={`item-d-${event?.id}-${rowId}`}
        className={cellClass}
      >
        <Box sx={{ ...boxSx, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {/*isMonthMode &&
          <Typography variant="caption" sx={{fontSize: 8}}>
            {event?.startHour} - {event?.endHour}
          </Typography>*/}
          {statusIcon(event.tsrStatus??event.DispatchQueueStatus)}
          <Typography variant="body2" sx={{padding: .5, fontSize: 11}}>
            {event?.label}
          </Typography>
        </Box>
      </Paper>
    </EventItemTooltip>

  )
}

EventItem.propTypes = {
  sx: PropTypes.object,
  boxSx: PropTypes.object,
  event: PropTypes.object.isRequired,
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isMonthMode: PropTypes.bool,
  onClick: PropTypes.func,
  handleTaskClick: PropTypes.func,
  onCellDragStart: PropTypes.func
}

export default EventItem