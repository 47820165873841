import { APIProvider, Map as GoogleMap, MapControl, ControlPosition, } from '@vis.gl/react-google-maps';
import { Box, } from "@mui/material";
import { Marker } from "./Marker";
import { LocationAutocomplete } from "./LocationAutocomplete";
import { useEffect, useState } from 'react';

export const WeatherMap = ({ locations, defaultLocation, id }) => {
    const [center, setCenter] = useState();
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    useEffect(() => {
        if (center) {
            setCenter(undefined);
        }
    }, [center]);

    return (
        <APIProvider apiKey={apiKey}>
            <Box sx={{ height: '88vh', width: '100%', position: 'relative', }}>
                {defaultLocation && <GoogleMap
                    id={id}
                    mapId={'739af084373f96fe'}
                    center={center}
                    defaultCenter={{
                        lng: defaultLocation.longitude,
                        lat: defaultLocation.latitude
                    }}
                    defaultZoom={13}
                >
                    {[...locations.keys()].map(key => (
                        <Marker
                            key={key}
                            position={{
                                lng: locations.get(key).temp.longitude,
                                lat: locations.get(key).temp.latitude
                            }}
                            locationInfo={locations.get(key)}
                        />
                    ))}
                    <MapControl position={ControlPosition.TOP_LEFT}>
                        <LocationAutocomplete
                            locations={locations}
                            setCenter={setCenter}
                        />
                    </MapControl>
                </GoogleMap>}
            </Box>
        </APIProvider>
    );
}