import { useRef, useMemo, useCallback, } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { handleUpdateTotalRows } from "../Utils"
import { heatmapStyle, } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useDashboardFetch } from "../useDashboardFetch"
import { useDashboardLayout } from "../useDashboardLayout"
import { ForecastRibbon } from "./Ribbon/ForecastRibbon"
import * as yup from 'yup';
import { ViewContextProvider } from "../ViewContextProvider"
import { useGridCrossHighlight } from "../useGridCrossHighlight"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    date: yup.date().required('Date is required'),
});

export const Forecast = () => {
    const layoutStorageKey = `deal-rizz-forecast-grid-layout`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const headers = useHeader();
    const { logAction } = useActionAudit();
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        date: dayjs(),
    }), []);

    const baseColDefs = useMemo(() => ([
        { field: 'name', headerName: 'Name', cellDataType: 'text' },
        { field: 'description', headerName: 'Description', cellDataType: 'text' },
        { field: 'source', headerName: 'Source', cellDataType: 'text' },
        { field: 'type', headerName: 'Type', cellDataType: 'text' },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 75,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const getRowId = useCallback(({ data }) => data.name, []);

    const { loadAndApplyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchTenantForecast',
        fetchParamKeys: ['userGuid', 'date', 'timezone'],
        gridRef,
        getRowId,
    });

    const { applyFilters, applyLayout, colDefs, layoutPanel, captureLayout, } = useDashboardLayout({
        gridRef,
        layoutStorageKey,
        //context: view,
        baseColDefs,
        defaultColDef,
    });

    function handleFetchData(data) {
        logAction('User fetched Forecast Data', 'Forecast', data);
        loadAndApplyData({
            ...data,
            userGuid: headers.userGuid,
            date: dayjs(data.date).format('MM/DD/YYYY'),
        });
    }

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'deal-rizz-forecast-palette'),
        ]
    }), []);

    const toolbarFormId = `deal-rizz-forecast-toolbar`;

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, baseColDefs[0].field);
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column' sx={{ p: 1, }}>
                <ForecastRibbon
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                />
                <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                    <AgGridReact
                        {...defaultGridOptions}
                        containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        enableCharts
                        getRowId={getRowId}
                        columnDefs={styledColDefs}
                        onFilterChanged={updateTotalRows}
                        onRowDataUpdated={updateTotalRows}
                        onFirstDataRendered={handleFirstDataRendered}
                        groupTotalRow={"bottom"}
                        suppressAggFuncInHeader={true}
                        onGridReady={onGridReady}
                        columnTypes={columnTypes}
                        statusBar={defaultStatusBar}
                        sideBar={sideBar}
                        onCellMouseOver={handleCellMouseOverHighlight}
                        components={{
                            layoutToolPanel: LayoutToolPanel,
                            paletteToolPanel: PaletteToolPanel,
                        }}
                    />
                </AgGridContainer>
            </Box>
        </ViewContextProvider>
    )
};
