import { useRef, useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { Box, } from '@mui/material';
import _ from 'lodash';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, includePrice = false, allowRelinquish = true, setUserEditedRelinquish, } = props;
  const formatString = 'MM/DD/YYYY HH:mm'

  const defaultData = useMemo(() => {
    const info = data.profileInfo ?? [];
    if (info.length) {
      return info;
    } else {
      const config = {
        startDate: moment(data['Start Date']),
        stopDate: moment(data['Stop Date']),
        startHour: parseInt(data['Start Time'].split(':')[0]), //for example this takes '16:00' -> 16
        stopHour: parseInt(data['Stop Time'].split(':')[0]),
        capacity: data.Volume,
      }
      includePrice && (config.price = data.Price);
      return generateHourlyBlocks(config);
    }
  }, [data])

  const gridRef = useRef();
  const [gridData, setGridData] = useState(defaultData);

  useEffect(() => {
    if (_.differenceWith(defaultData, gridData, _.isEqual).length) {
      setGridData(defaultData)
    }
  }, [data])

  const colDefs = useMemo(() => [
    {
      field: 'startDateTime',
      headerName: 'Start',
      editable: false,
      initialWidth: 200,
      //cellEditorPopup: true,
      cellEditorParams: {
        timeFormat: 'HH',
        valueFormat: formatString,
        closeOnSelect: true,
      },
      valueFormatter: params => {
        return params.value ? moment(params.value).format(formatString) : '';
      }
    },
    {
      field: 'endDateTime',
      headerName: 'Stop',
      editable: false,
      initialWidth: 200,
      //cellEditorPopup: true,
      cellEditorParams: {
        timeFormat: 'HH',
        valueFormat: formatString,
        closeOnSelect: true,
      },
      valueFormatter: params => {
        return params.value ? moment(params.value).format(formatString) : '';
      }
    },
    {
      field: 'capacityRequested',
      headerName: 'Volume',
      editable: () => {
        return (
          !data['New Aref'] && ['REDIRECT', 'RESALE'].includes(data['RequestType'])
        )
      },
      initialWidth: 200,
      flex: 1,
    },
    {
      field: 'capacityRelinquish',
      headerName: 'Relinquish',
      editable: () => {
        return (
          (['CONFIRMED', 'ERROR'].includes(data['Status'])) && (data['RequestType'] === 'REDIRECT')
        )
      },
      initialWidth: 200,
      hide: !allowRelinquish,
      flex: 1,
    },
    {
      field: 'capacityRemaining',
      headerName: 'Remaining',
      editable: false,
      initialHide: false,
      initialWidth: 200,
      hide: !allowRelinquish,
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      initialWidth: 200,
      flex: 1,
      editable: () => {
        return (
          !data['New Aref'] && (data['RequestType' === 'REDIRECT'])
        )
      },
      hide: !includePrice,
    },
  ], [])

  function generateHourlyBlocks(config) {
    let blocks = [];
    let next = moment(config.startDate).hour(config.startHour).startOf('hour');
    const end = moment(config.stopDate).hour(config.stopHour, 'hours').startOf('hour');
    while (end.isAfter(next, 'hour')) {
      const block = {
        startDateTime: moment(next).format(formatString),
        endDateTime: moment(next).add(1, 'hours').format(formatString),
        capacityRequested: config.capacity,
      }
      includePrice && (block.price = config.price);
      blocks.push(block);
      next = moment(next).add(1, 'hour');
    }
    return blocks;
  }

  function onCellValueChanged(params) {
    if (params.colDef.headerName === 'Relinquish') {
      setUserEditedRelinquish(true);
    }

    node.data.profileInfo = gridData;

    if (node.data.profileInfo?.length) {
      const firstProfileCapacity = gridData[0].capacityRequested;
      const firstPrice = gridData[0].price;
      if (node.data.Volume !== firstProfileCapacity) {
        node.setDataValue('Volume', firstProfileCapacity);
      }
      if (includePrice && node.data.Price !== firstPrice) {
        node.setDataValue('Price', firstPrice);
      }
    }

    node.setDataValue('profileInfo', gridData);
  }

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }), [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, }}>
      <AgGridContainer
        style={{
          width: "100%",
          // height: '200px', 
          fontSize: '11px'
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          // style={{ height: '100%'}}
          domLayout={'autoHeight'}
          columnDefs={colDefs}
          overlayNoRowsTemplate={'<span>Add blocks to view and edit profile information here.</span>'}
          enableRangeSelection
          enableFillHandle
          enterMovesDown
          enterMovesDownAfterEdit
          stopEditingWhenCellsLoseFocus
          onCellValueChanged={onCellValueChanged}
          singleClickEdit
          defaultColDef={defaultColDef}
        />
      </AgGridContainer>
    </Box>
  );
}