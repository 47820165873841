import dayjs from 'dayjs';

export const stringIntComparator = (a, b) => {
  //use regex to check if the values only contain the digits 0-9; 
  //if they do, compare them as integers; if they don't compare them as string
  //if only one is a number, the string should come first
  const regex = new RegExp('^[0-9]+$');
  if (regex.test(a) && regex.test(b)) {
    return parseInt(a) - parseInt(b);
  } else if (regex.test(a) || regex.test(b)) {
    return regex.test(a) ? 1 : -1;
  } else if (a && b) {
    return a.toString().localeCompare(b.toString());
  } else {
    return !!a ? 1 : -1;
  }
}

export const dateComparator = (date1, date2) => {
  return dayjs(date1).diff(dayjs(date2));
}