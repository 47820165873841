import { Dashboard } from "../../DealRizz/Dashboard/Dashboard";
import AddNewOrUpdateDialog from "./AddNewOrUpdateDialog";
import CorrelationsView from "./CorrelationsView";

export const CorrelationsDashboard = ({ visible, }) => {
    const dashboardKey = 'correlations';
    const useShared = true;

    return (
        <Dashboard
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CorrelationsView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}