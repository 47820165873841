import { useState, useEffect, useRef } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { apiUrlPrefix } from "../../../authConfig";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import { useTheme } from "@emotion/react";
import useHubObject from "../../HubContext/useHubObject";
import styled from "@emotion/styled";

export const PointStatistics = ({ brainName, modelName }) => {
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const headers = useHeader();
    const theme = useTheme();
    const brainRef = useRef();
    brainRef.current = brainName;

    const { cancelHubAction } = useHubObject({
        action: fetchPointStats,
        allowedMessages: ['NeuronHistoryUpdate'],
        predicate: (obj) => {
            return obj.brainName === brainName;
        },
        callbackDependencies: [brainName, headers, modelName],
        debounceOptions: { maxWait: 5000, },
        wait: 5000,
    });

    useEffect(() => {
        if (brainName && modelName) {
            cancelHubAction();
            fetchPointStats();
        }
    }, [brainName, modelName]);

    async function fetchPointStats() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchPointStats&parm=${brainName}&parm=${modelName}`;

        if (brainRef.current !== brainName) return; //this can happen due to the debounce

        return axios.get(url, { headers: headers }).then(response => {
            setData(response.data);
        }).catch((error) => {
            enqueueSnackbar(`Error fetching point stats for brain ${brainName}: ${error}`, { variant: 'error', });
        });
    }

    const dataWithoutTooltip = data.filter(item => item.Header !== 'tooltip');
    const tooltips = data.find(item => item.Header === 'tooltip');

    // Transform data for table rows
    const tableRows = dataWithoutTooltip.reduce((acc, curr) => {
        Object.entries(curr).forEach(([key, value]) => {
            if (key !== 'Header') {
                if (!acc[key]) acc[key] = { label: key };
                acc[key][curr.Header] = value;
            }
        });
        return acc;
    }, {});

    const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white;

    const sortedRowArray = Object.values(tableRows).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <TableContainer component={Paper} elevation={5} sx={{ position: 'relative', height: '100%', background: backgroundColor, pt: 1, borderRadius: 0, }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell key={'blank-header'} sx={{ py: 0.5, }}></TableCell>
                        <TableCell key={`table-header-training`} sx={{ py: 0.5, }}>Training</TableCell>
                        <TableCell key={`table-header-testing`} sx={{ py: 0.5, }}>Testing</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRowArray.map((row, index) => (
                        <Tooltip
                            key={index}
                            title={<Typography fontSize={'1.5em'}>{tooltips[row.label]}</Typography>}
                            placement="right"
                            arrow
                        >
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" sx={{ py: 0.5, }}>{row.label}</TableCell>
                                <TableCell key={`row-${row.label}-training`} sx={{ py: 0.5, }}>{row['Training']}</TableCell>
                                <TableCell key={`row-${row.label}-testing`} sx={{ py: 0.5, }}>{row['Testing']}</TableCell>
                            </TableRow>
                        </Tooltip>
                    ))}
                </TableBody>
            </Table>
            <NoDataOverlay visible={sortedRowArray.length === 0} />
        </TableContainer>
    )
}

const NoDataOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <Typography align='center' variant='p'>Once the brain has been trained, you will receive an alert and check your performance statistics here.</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div')(({ theme, visible }) => ({
    position: 'absolute',
    top: '45%',
    padding: theme.spacing(2),
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));