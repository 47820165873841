import { useEffect, useState, useRef, } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import AddNewDialog from './AddNewDialog'
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import StalkerGrid from './StalkerGrid';
import "react-tabs/style/react-tabs.css"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';
import useHeader from '../../useHeader';
import DivGuard from "../../../components/Guards/DivGuard";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../ConfirmationDialog';

export default ({ visible, }) => {
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const [tabIndex, setTabIndex] = useState(0);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [views, setViews] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  useEffect(() => {
    fetchGridViews();
  }, []);

  async function fetchGridViews() {
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch&parm=${guid}&parm=stalker`;
    const options = {
      headers: headers,
      url: loadUri,
    }
    axios(options).then(response => {
      setViews(response.data.map(viewData => {
        const view = JSON.parse(viewData.json ?? '{}');
        return {
          ...view,
          id: viewData.userLayoutID,
        }
      }));
    }).catch(err => { enqueueSnackbar(`Error getting view data in real time tsrs.  ${err}`) });
  }

  function saveNewGridView(criteria) {
    const newView = {
      grid: 'stalker',
      json: {
        ...criteria,
      },
      template: criteria.label,
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate&parm=${guid}&parm=${newView.grid}&parm=${newView.template}`;

    const options = {
      method: 'POST',
      headers: headers,
      data: newView.json,
      url: saveUri,
    }

    axios(options).then(response => {
      fetchGridViews()
    }).catch(err => { enqueueSnackbar(`Error saving Stalker grid data.  ${err.response?.data}`) });
  }

  function handleTabSelect(event, newIndex) {
    if (newIndex <= views.length) { //last tab handles deletes, don't switch to it
      setTabIndex(newIndex);
    }
  }

  function handleDeleteCurrentTab() {
    const current = views[tabIndex];
    const remaining = views?.filter(v => v.id !== current.id);
    if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
      setOpenAddNew(true);
    }
    setViews(remaining ?? []);
    setTabIndex(0);
    setOpenConfirmDelete(false);

    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete&parm=${guid}&parm=stalker&parm=${current.label}`;
    const options = {
      headers: headers,
      url: deleteUri,
    }

    axios(options).catch(err => { enqueueSnackbar(`Error deleting view(s) in real time tsrs.  ${err}`) });
  }

  function handleAddNew(criteria) {
    setViews([...views, {
      id: `new-stalker-grid-${views.length}`,
      ...criteria
    }]);
    setTabIndex(views.length);
    setOpenAddNew(false);
    saveNewGridView(criteria);
  }

  return (
    <DivGuard groups={[userGroups.stalker]}>
      <Box
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        <AddNewDialog
          open={openAddNew}
          handleCancel={() => setOpenAddNew(false)}
          handleAddNew={handleAddNew}
        />
        <ConfirmationDialog
          open={openConfirmDelete}
          message={`You are about to delete the current tab. Continue?`}
          onCancel={() => setOpenConfirmDelete(false)}
          onConfirmation={handleDeleteCurrentTab}
        />
        <Box
          sx={{
            borderBottom: 1,
            bgcolor: 'background.paper',
            p: '0px',
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            //minHeight="80px"
            maxHeight="10px"
            color="primary"
            backgroundColor="transparent"
          //variant="fullWidth"
          >
            {views.map((view) => (
              <Tab
                backgroundColor="transparent"
                label={view.label}
                id={`stalker-tab-${view.id}`} />
            ))}
            <Tab id='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={() => setOpenAddNew(true)} />
            <Tab id='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} />
          </Tabs>
          {views.map((view, i) => (
            <Collapse in={tabIndex === i} orientation={'vertical'}>
              <StalkerGrid
                visible={(tabIndex === i) && visible}
                view={view}
                handleOverwriteCriteria={saveNewGridView}
              />
            </Collapse>
          ))}
        </Box>
      </Box>
    </DivGuard>
  );
};