import { Alert, Collapse, Stack, Typography, } from "@mui/material";
import { useMemo } from "react";

export const MismatchAlert = ({ selectedRows = {}, }) => {
    let message = useMemo(() => {
        if (!selectedRows.tag) return '';

        const tagPor = selectedRows.tag.firstPOR;
        const tagPod = selectedRows.tag.lastPOD;
        const purchasePORs = selectedRows.purchases?.map(p => p.por);
        const salePODs = selectedRows.sales?.map(s => s.pod);
        const mismatchedPOR = purchasePORs.find(p => p !== tagPor);
        const mismatchedPOD = salePODs.find(s => s !== tagPod);
        let message = '';
        if (mismatchedPOR && mismatchedPOD) {
            message = `Be advised: you are about to link a purchase with POR ${mismatchedPOR}`
                + ` to a tag with ${tagPor ? 'POR ' + tagPor : 'no POR'},`
                + ` and a sale with POD ${mismatchedPOD} to a tag with ${tagPod ? 'POD ' + tagPod : 'no POD'}.`;
        } else if (mismatchedPOR) {
            message = `Be advised: you are about to link a purchase with POR ${mismatchedPOR} to a tag with ${tagPor ? 'POR ' + tagPor : 'no POR'}.`;
        } else if (mismatchedPOD) {
            message = `Be advised: you are about to link a sale with POD ${mismatchedPOD} to a tag with ${tagPod ? 'POD ' + tagPod : 'no POD'}.`;
        }
        return message;
    }, [selectedRows]);

    return (
        <Collapse in={!!message}>
            <Alert severity="warning" sx={{ display: 'flex', alignItems: 'center', m: 1, }}>
                <Stack sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1, }} spacing={2} direction={'row'}>
                    <Typography sx={{ fontWeight: 'bold' }} >
                        {message}
                    </Typography>
                </Stack>
            </Alert>
        </Collapse>
    );
}