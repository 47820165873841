import { Box, Typography, Stack, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfoBox, } from '@react-google-maps/api';
import { useState, } from 'react';
import dayjs from '../../dayjs-tz.js';
import { CustomMarker } from './CustomMarker.js';

export const HubMarker = ({
  position,
  hubInfo,
  markerProps,
}) => {

  const [showInfoBox, setShowInfoBox] = useState(false);

  const handleMouseEnter = () => {
    setShowInfoBox(true);
  };

  const handleMouseOut = () => {
    setShowInfoBox(false);
  }

  const colorforLmpValue = () => {
    const normalizedVal = Math.min(hubInfo.Total_LMP / 100, 1.0);
    const h = (1.0 - normalizedVal) * 240
    return "hsl(" + h + ", 100%, 50%)";
  }

  return(
    <CustomMarker
      position={position}
      style={{
        backgroundColor: colorforLmpValue(),
        height: 22,
        width: 22,
        borderRadius: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid black',
        position: 'relative',
        zIndex: 2, 
      }}
      onMouseLeave={handleMouseOut}
      onMouseEnter={handleMouseEnter}
      elevate={showInfoBox}
    >
      <Typography variant="caption" component="p" style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>$</Typography>
      {showInfoBox &&<StyledBox sx={{ p: 1, position: 'absolute', top: 0, left: 0, zIndex: 9999999, }} > 
        <h3>
          {`${hubInfo.PNode} (${hubInfo.ISO})`}
        </h3>
        <Stack spacing={1}>
          <Typography variant="caption" component="p">
            End: {dayjs(hubInfo.EndTime).format('MM/DD/YYYY HH:mm')}
          </Typography>
          <Typography variant="caption" component="p">
            MCE: {Number(hubInfo.MarginalEnergy).toFixed(2)}
          </Typography>
          <Typography variant="caption" component="p">
            MCL: {Number(hubInfo.Marginal_Loss).toFixed(2)}
          </Typography>
          <Typography variant="caption" component="p">
            MCC: {Number(hubInfo.Congestion).toFixed(2)}
          </Typography>
          <Typography variant="caption" component="p">
            LMP: {Number(hubInfo.Total_LMP).toFixed(2)}
          </Typography>
        </Stack>
      </StyledBox>}
    </CustomMarker>
  )
}

const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  border: `3px solid ${theme.palette.common.main}`,
  borderRadius: 10,
  padding: 10,
}));