import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useSnackbar } from 'notistack';


const truncate = (str) => {
  return str.length > 25 ? str.substring(0, 7) + "..." : str;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



export function RowDetailsToolPanel(props) {
  const rowData = props.rowData;
  const headers = rowData ? Object.keys(rowData) : [];
  const {enqueueSnackbar} = useSnackbar();

  function handleClick(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`${text} copied to the clipboard`)
  }

  return(
    headers.length > 0 
    ? <TableContainer component={Paper}>
        <Table sx={{ width:450 }} size="small" aria-label="customized table">
          <colgroup>
            <col style={{width:'25%'}}/>
            <col style={{width:'70%'}}/>
          </colgroup>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">Info</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'light' }} align="right">Detail</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
          {headers.map((header) =>
            <StyledTableRow onClick={() => handleClick(rowData[header])}>
              <StyledTableCell sx={{ fontWeight: 'light' }} component="th" scope="row">
                {header}
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'light' }} align="right">{rowData[header]}</StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    : <Grid sx={{ p:2 }} container>
        <Grid item>
            <Typography>
                Select a cell to view its row details.
            </Typography>
        </Grid>
    </Grid>
  );
};