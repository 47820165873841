import { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TariffPrices from '../TransNow/TariffPrices';
import { useLazyRender } from '../useLazyRender';
import { Menu } from './Menu';
import { TariffChat } from './TariffChat';
import { AuctionCalendar } from './AuctionCalendar';

export const TariffAF = () => {
    const defaultView = localStorage.getItem('tariffAFView') ?? 'tariffchat';
    const [view, setView] = useState(defaultView);
    const { loaded, } = useLazyRender(view);

    const handleSelection = (viewName) => {
        localStorage.setItem('tariffAFView', viewName);
        setView(viewName);
    };

    const MenuOptions = useMemo(() => [
        {
            name: 'tariffprices',
            component: <TariffPrices />
        },
        {
            name: 'tariffchat',
            component: <TariffChat />
        },
        {
            name: 'auctions',
            component: <AuctionCalendar />
        },
    ], []);

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Menu handleSelection={handleSelection} selected={view} />
            <Box
                sx={{
                    p: '7px',
                    flexGrow: 1,
                }}
            >
                {MenuOptions.map(option =>
                    loaded[option.name] && <Collapse in={view === option.name}>
                        {option.component}
                    </Collapse>
                )}
            </Box>
        </Box>
    );
}