import { useEffect, useMemo, useState, } from "react";
import { Box, Button, } from "@mui/material"
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import axios from "axios";
import { apiUrlPrefix } from "../../authConfig";
import { useActionAudit } from "../useActionAudit";
import { UpdatesList } from "./UpdatesList";
import { ContentDialog } from "./ContentDialog";
import '../../styles/productUpdatesStyles.css';
import { Menu } from "./Menu";
import { useMsal } from "@azure/msal-react";
import { useUserInfo } from "../useUserInfo";

export const ProductUpdates = () => {
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [updates, setUpdates] = useState([]);
  const { logAction } = useActionAudit();
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [selectedContent, setSelectedContent] = useState({});
  const userInfo = useUserInfo();

  useEffect(() => {
    fetchProductUpdates();
  }, []);

  useEffect(() => {
    userInfo.userName && logAction(`User ${userInfo.userName} viewed Product Updates`, 'Product Updates');
  }, [userInfo]);

  async function fetchProductUpdates() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchContent&parm=${headers.userGuid}`,
    }

    axios(options).then(response => {
      const data = response?.data ?? [];
      data.sort((a, b) => {
        const aDate = new Date(a.timeDateCreated);
        const bDate = new Date(b.timeDateCreated);
        return bDate - aDate;
      }).forEach(update => {
        const tagArray = update.tags.split(',') ?? []; //tags are a comma separated string
        update.tags = tagArray.map(tag => tag.toLowerCase());
      })
      setUpdates(data);
    }).catch(error => {
      logAction(`Error loading product updates. Message: ${error}`, 'Product Updates');
      enqueueSnackbar(`Error loading product updates. Message: ${error}`, { variant: 'error' })
    });
  }

  function handleCardSelection(id, event) {
    const update = updates.find(update => update.contentID === id);
    logAction(`User ${userInfo.userName} selected Product Update ${id}`, 'Product Updates', {
      type: update.type, title: update.title, id: update.contentID,
    });
    setSelectedContent(update);
    setOpenContentDialog(true);
  }

  const tags = useMemo(() => {
    const tags = new Set();
    updates.forEach(update => {
      update.tags.forEach(tag => {
        tags.add(tag);
      })
    });
    return Array.from(tags);
  }, [updates]);

  function handleAddNew() {
    setSelectedContent({});
    setOpenContentDialog(true);
  }

  return (
    <Box className='product-update-container'>
      <Menu tags={tags} handleAddNew={handleAddNew} />
      <Box
        sx={{ p: 2, flexGrow: 1, }}
      >
        <ContentDialog open={openContentDialog} closeDialog={() => setOpenContentDialog(false)} content={selectedContent} usedTags={tags} handleFetch={fetchProductUpdates} />
        <UpdatesList updates={updates} handleCardSelection={handleCardSelection} />
      </Box>
    </Box>
  )
}