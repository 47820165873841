import { Stack, Slider, Typography } from "@mui/material";
import Input from '@mui/material/Input';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export const InputSlider = forwardRef(({ label, defaultValue, onSubmit, min, max, }, ref) => {
    const [value, setValue] = useState(defaultValue);

    function handleChange(newValue) {
        setValue(newValue);
        onSubmit(newValue);
    }

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useImperativeHandle(ref, () => ({
        reset() {
            setValue(defaultValue);
        },
        getValue() {
            return value;
        },
    }));

    return (
        <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center', minWidth: '70px' }}>
            <Typography variant="p" fontSize='0.6rem'>{label}</Typography>
            <Slider
                value={value}
                onChange={(_, data) => handleChange(data)}
                orientation="vertical"
                valueLabelDisplay="auto"
                step={0.001}
                min={min}
                max={max}
            />
            <Input
                value={value}
                size="small"
                onChange={(e) => handleChange(e.target.value)}
                inputProps={{
                    step: 0.001,
                    min: min,
                    max: max,
                    type: 'number',
                }}
            />
        </Stack>
    );
});