import { Tooltip, IconButton, } from "@mui/material";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FlipMove from 'react-flip-move';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { useFileUploader } from "./useFileUploader";

export const useImageUploader = () => {
    const {
        handleUpdateFiles,
        inputRef,
        FileUploader,
        handleFileUpload: handleImageUpload,
        removeFile: removeImage,
        clearFiles: clearImages,
        fileUrls: imageUrls,
        files: images,
    } = useFileUploader();

    const ImageUploader = (propsOverrides) => {
        return (
            <FileUploader
                accept='image/*'
                {...propsOverrides}
            />
        )
    }

    const ImageUploadButton = () => {
        return (
            <Tooltip title="Upload Image" placement="top" arrow>
                <IconButton onClick={handleImageUpload} color='primary' size='large'>
                    <AddPhotoAlternateIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
        )
    }

    const UploadPictureContainer = styled('div')(({ theme }) => ({
        width: '25%',
        margin: '5%',
        padding: '10px',
        background: '#edf2f6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
        boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
        border: '1px solid #d0dbe4',
        position: 'relative',
    }));

    const DeleteImageButton = styled(IconButton)(({ theme }) => ({
        position: 'absolute',
        top: '-9px',
        right: '-9px',
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '30px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }));

    const ImagePreview = () =>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', }}>
            <FlipMove enterAnimation="fade" leaveAnimation="fade" style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                width: "100%"
            }}>
                {imageUrls.map((imageUrl, index) => (
                    <UploadPictureContainer key={index} >
                        <DeleteImageButton onClick={() => removeImage(index)}>
                            <CloseIcon />
                        </DeleteImageButton>
                        <img src={imageUrl} alt="preview" style={{ width: '100%', }} />
                    </UploadPictureContainer>
                ))}
            </FlipMove>
        </div>

    return {
        ImageUploader,
        inputRef,
        images,
        handleImageUpload,
        ImageUploadButton,
        handleUpdateFiles,
        removeImage,
        ImagePreview,
        imageUrls,
        clearImages,
    }
}