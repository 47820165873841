import useHeader from "./useHeader";
import { useSnackbar } from "notistack";
import { useActionAudit } from "./useActionAudit";
import { useState, useMemo, useEffect, } from "react";
import { GoogleGenerativeAI } from '@google/generative-ai'
import { apiUrlPrefix } from "../authConfig";
import axios from "axios";
import OpenAI from "openai";

export const useChatBots = ({ application, defaultModel = 'gemini', images = [], useStreaming = false, files = [], }) => {
    const header = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();

    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState([]);
    const [model, setModel] = useState(defaultModel);

    const [geminiApiKey, setGeminiApiKey] = useState(null);
    const [chatGptApiKey, setChatGptApiKey] = useState(null);

    useEffect(() => {
        fetchContext();
        apiKeyFetch('GeminiAPIKey').then(response => {
            setGeminiApiKey(response.data?.[0].value);
        });
        apiKeyFetch('ChatGPTKey').then(response => {
            setChatGptApiKey(response.data?.[0].value);
        });
    }, []);

    /**** GEMINI ****/
    const genAI = useMemo(() => {
        if (geminiApiKey) {
            return new GoogleGenerativeAI(geminiApiKey)
        }
    }, [geminiApiKey]);

    const geminiModel = useMemo(() => {
        if (model.includes('vision')) {
            // For text-and-images input (multimodal), use the gemini-pro-vision model
            return genAI?.getGenerativeModel({ model: "gemini-pro-vision" });
        } else {
            // For text-only input, use the gemini-pro model
            return genAI?.getGenerativeModel({ model: "gemini-pro" });
        }
    }, [genAI, model]);

    // Converts a File object to a GoogleGenerativeAI.Part object.
    async function fileToGenerativePart(file) {
        const base64EncodedDataPromise = new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.readAsDataURL(file);
        });
        return {
            inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
        };
    }

    const geminiChat = useMemo(() => {
        //vision model doesn't support chat at this time
        return model.includes('vision') ? null : geminiModel?.startChat({
            history: context.reduce((acc, ctx) => {
                acc.push({
                    role: 'user',
                    parts: ctx.Question,
                });
                acc.push({
                    role: 'model',
                    parts: ctx.Answer,
                });
                return acc;
            }, []),
        });
    }, [geminiModel, context]);

    async function geminiQuery(prompt) {
        return geminiChat.sendMessage(prompt).then(async (result) => {
            const response = await result.response;
            const text = response.text();
            logAction('geminiQuery', 'Chat Q and A', {
                prompt: prompt,
                response: text,
            });
            return text;
        });
    };

    async function geminiVisionQuery(prompt) {
        const imageParts = await Promise.all(
            [...images].map(fileToGenerativePart)
        );

        return geminiModel.generateContent([prompt, ...imageParts]).then(async (result) => {
            const response = await result.response;
            const text = response.text();
            logAction('geminiVisionQuery', 'Chat Q and A', {
                prompt: prompt,
                response: text,
            });
            return text;
        });
    }

    /**** GPT ****/
    const [openai, setOpenai] = useState(null);
    const [assistant, setAssistant] = useState(null);
    const [thread, setThread] = useState(null);

    useEffect(() => {
        if (chatGptApiKey && context) {
            initOpenAI();
        }
    }, [chatGptApiKey, context]);

    async function initOpenAI() {
        const openai = new OpenAI({
            apiKey: chatGptApiKey,
            dangerouslyAllowBrowser: true,
        });

        //log list of availabile models
        /*openai.models.list().then(models => {
            console.log(models);
        });*/

        // Create an assistant
        const assistant = await openai.beta.assistants.create({
            name: "Assistant",
            description: "You are an expert in power and energy trading and a representitive of the Power Station Platform support team. You have been asked to answer questions about the Power Station platform.",
            model: "gpt-4o",
            tools: [{ type: "file_search" }],
            //file_ids: [file.id]
        })

        // enqueueSnackbar("You are an expert in power and energy trading and a representitive of the Power Station Platform support team. You have been asked to answer questions about the Power Station platform.");
        
        // Create a thread
        const thread = await openai?.beta.threads.create({
            messages: context.reduce((acc, ctx) => {
                //Only 'user' role is currently supported ):
                /*acc.push({
                    role: 'user',
                    content: ctx.Question,
                    //file_ids: [file.id],
                });*/
                acc.push({
                    //role: 'assistant',
                    role: 'user',
                    content: ctx.Answer,
                    //file_ids: [file.id],
                });
                return acc;
            }, []),
        });

        // enqueueSnackbar(JSON.stringify(thread));

        setOpenai(openai);
        setAssistant(assistant);
        setThread(thread);
    };

    async function gptQuery(prompt) {
        const url = `${apiUrlPrefix}/CrystalBall/ChatGPTConversation?`
            + `query=${encodeURIComponent(prompt)}`
            + `&contextUrl=${encodeURIComponent(`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_Get_AI_Context&parm=${application}`)}`
            + `&actor=${'marketer'}`
            + `&temperature=${'1.0'}`
            + `&maxResponseTokens=${'500'}`

        let options = {
            headers: header,
            url: url,
        };

        return axios(options).then(response => {
            return response.data;
        });
    };

    async function assistantQuery(prompt) {
        //upload all files to the assistant
        const uploadedFiles = await Promise.all(
            [...files].map(file => openai.files.create({
                file: file,
                purpose: "assistants",
            }))
        );

        // Send a message to the thread, attaching the files
        await openai.beta.threads.messages.create(thread.id, {
            role: "user",
            content: prompt,
            attachments: uploadedFiles.map((file) => ({ file_id: file.id, tools: [{ type: "file_search" }] })),
        });

        // Run the assistant
        const run = await openai.beta.threads.runs.create(thread.id, {
            assistant_id: assistant.id,
        });

        // Create a response
        let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

        // Wait for the response to be ready
        while (response.status === "in_progress" || response.status === "queued") {
            await new Promise((resolve) => setTimeout(resolve, 500));
            response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        }

        // Get the messages for the thread
        const messageList = await openai.beta.threads.messages.list(thread.id);

        // Find the last message for the current run
        const lastMessage = messageList.data
            .filter((message) => message.run_id === run.id && message.role === "assistant")
            .pop();

        // Return the last message
        return lastMessage.content[0]["text"].value;
    };

    function handleApiError(err, prompt) {
        const message = `Error submitting query. Error: ${err}`;
        enqueueSnackbar(message, { variant: 'error', });
        setLoading(false);
        logAction(`The chat ${model} model threw an error while handling a prompt`, 'Chat Q and A', {
            prompt: prompt,
        });
    }

    async function submitPrompt(prompt) {
        let query;
        if (model === 'gemini') {
            query = geminiQuery;
        } else if (model === 'gemini-vision') {
            query = geminiVisionQuery;
        } else if (model === 'assistant') {
            query = assistantQuery;
        } else {
            query = gptQuery;
        }

        // enqueueSnackbar(prompt);

        const timestamp = new Date()
        return query(prompt).then(text => {
            const difference = new Date() - timestamp;
            console.log(`It took ${difference} milliseconds to get a response from the ${model} API.`);
            return text;
        }).catch((err) => handleApiError(err, prompt));
    }

    async function fetchContext() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_Get_AI_Context&parm=${application}`

        
        let options = {
            headers: header,
            url: url,
        };

        axios(options).then(response => {
            setContext(response.data);
            // enqueueSnackbar(JSON.stringify(response.data));
        }).catch(err => {
            enqueueSnackbar(`Error fetching context. Error: ${err}`, { variant: 'error', });
        });
    }

    async function apiKeyFetch(keyName) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TenantAppSettingsFetch&parm=${keyName}&parm=1`

        let options = {
            headers: header,
            url: url,
        };

        return axios(options).catch(err => {
            enqueueSnackbar(`Error fetching API key. Error: ${err}`, { variant: 'error', });
        });
    }

    return {
        loading,
        context,
        setLoading,
        submitPrompt,
        model,
        setModel,
    }

}