import { Box } from "@mui/material";
import { ConfirmScheduleButton } from "../../RibbonButtons/ConfirmScheduleButton";
import { VoidScheduleButton } from "../../RibbonButtons/VoidScheduleButton";
import { RibbonGroupContainer } from "../../Ribbon";
import { ScheduleHistoryButton } from "../../RibbonButtons/ScheduleHistoryButton";

export const ScheduleActionsGroup = ({ expanded, selectedSchedule, gridRef }) => {
    const toConfirm = !selectedSchedule || selectedSchedule.Schedule_Status !== 'CONFIRMED';
    const toVoid = !selectedSchedule || selectedSchedule.Schedule_Status !== 'VOIDED';

    return (
        <RibbonGroupContainer>
            <ConfirmScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                toConfirm={toConfirm}
                gridRef={gridRef}
            />

            <VoidScheduleButton
                expanded={expanded}
                selectedSchedule={selectedSchedule}
                gridRef={gridRef}
                toVoid={toVoid}
            />

            <ScheduleHistoryButton
                expanded={expanded}
                schedule={selectedSchedule}
            />
        </RibbonGroupContainer>
    );
}
