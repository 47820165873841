import { Dialog, DialogTitle, Alert, DialogContent, DialogContentText, DialogActions, Button, Box, AlertTitle, CircularProgress, } from '@mui/material'

export default (props) => {
    const { open, message, onConfirmation, onCancel, cancelText='NO', confirmText='YES', singleAction=false, loading=false, } = props;

    return (
        <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open={open}  maxWidth={'sm'} fullWidth>
          <DialogTitle id="alert-dialog-title">
            {"Are you sure?"}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">
            {loading ? <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress/></Box> :
              <DialogContentText id="alert-dialog-description">
                {message}
              </DialogContentText>}
              {props.children}
              <DialogActions>
                <Button color="inherit" size="small" onClick={onCancel}>
                  {cancelText} 
                </Button>&nbsp;&nbsp;
                {(!singleAction && !loading) && <Button color="inherit" size="small" onClick={onConfirmation}>
                  {confirmText} 
                </Button>}
              </DialogActions>
          </DialogContent>
        </Dialog>
      )
}