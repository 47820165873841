import { MessageChip } from './MessageChip';
import '../../styles/chatStyles.css';

export const UserIsTypingChip = ({ sender, }) => {
    return (
        <MessageChip
            sender={sender}
            label={<>
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
            </>}
        >
        </MessageChip>
    );
}