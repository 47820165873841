import { useEffect, useState, } from 'react';
import { Divider, Grid, InputLabel, TextField, Button, Tooltip, ListItem, Box, List, MenuItem } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix, } from '../../authConfig';
import useHeader from '../useHeader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, array, date, number } from 'yup';
import { useAccount } from '@azure/msal-react';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import { logAction } from '../../utils/auditLogger';
import dayjs from '../dayjs-tz';
import { useMsal } from '@azure/msal-react';

const schema = object().shape({
  system: string().required('Environment is required.'),
  OASISUser: string().required('OASIS Username is required.'),
  OASISPassword: string().required('OASIS Password is required.'),
});

export default function OASIScredentials() {
  const { accounts } = useMsal();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const header = useHeader();
  const [userCertificates, setUserCertificates] = useState();
  const [userInfo, setUserInfo] = useState({});

  const noOptionsText = userCertificates ? 'No certificates found.' : 'Loading, please wait...';

  const { register, handleSubmit, control, formState: { errors, }, setValue, } = useForm({
    resolver: yupResolver(schema),
  });

  const defaultUserCertificate = userCertificates?.find(c => c.ThumbPrint === userInfo.ThumbPrint);

  useEffect(() => {
    defaultUserCertificate && setValue('userCertificate', defaultUserCertificate);
  }, [defaultUserCertificate]);

  useEffect(() => {
    fetchUserInfo();
    fetchUserCertificates();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    enqueueSnackbar('Saving OASIS credentials...', { variant: 'info', });
    const url = `${apiUrlPrefix}/CrystalBall/TSR/UpdateOASISCredentials?`
      + `system=${data.system}`
      + `&userGuid=${header.userGuid}`
      + `&OASISUser=${encodeURIComponent(data.OASISUser)}`
      + `&OASISPassword=${encodeURIComponent(data.OASISPassword)}`
      + `&certThumbprint=${data.userCertificate?.ThumbPrint ?? ''}`

    let options = {
      headers: header,
      url: url,
    };

    axios(options).then(response => {
      if (JSON.stringify(response.data) !== '"Credentials Saved. Credentials Validated."') { //error
        const message = `Your credentials have been saved but could not be validated against OASIS.  Bad username and password combination.`;
        enqueueSnackbar(message, { variant: 'error', });
        logAction('Error saving user OASIS creds', 'OASIScredentials', `${message} URL: ${url}`);
      } else { //success
        const message = `OASIS credentials saved.`;
        enqueueSnackbar(message, { variant: 'success', });
        logAction('User saved OASIS credetials', 'OASIScredentials', `${message} URL: ${url}`);
      }
    }).catch(err => {
      const message = `Error saving OASIS credentials. Error: ${err} URL: ${url}`;
      enqueueSnackbar(message, { variant: 'error', });
      logAction('Error saving user OASIS creds', 'OASIScredentials', message);
    });
  }

  function fetchUserCertificates() {
    const options = {
      headers: header,
      url: `${apiUrlPrefix}/CrystalBall/TSR/CertificateInfo?UserGuid=${header.userGuid}`,
    }

    axios(options).then(response => {
      setUserCertificates(response.data);
    }).catch(error => {
      enqueueSnackbar(`Error loading user certificates. ${error.response?.data} Message: ${error}`, { variant: 'error', });
      setUserCertificates([]);
    });
  }

  function fetchUserInfo() {
    const options = {
      headers: header,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchUserInformation&parm=${header.userGuid}`,
    }

    axios(options).then(response => {
      setUserInfo(response.data[0] ?? {});
    }).catch(error => {
      enqueueSnackbar(`Error fetching user info. ${error.message}`, { variant: 'error', });
    })
  }

  const disableSave = !!Object.keys(errors).length;

  return (
    <form style={{ padding: "30px", justifyContent: 'center' }} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        item
        xs={12}
        style={{ margin: "10px", p: 10, }}
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={1}>
          <InputLabel>Name</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled variant="outlined" size="small" value={accounts[0]?.name} />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel>Email</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled variant="outlined" size="small" value={accounts[0]?.username} />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel>User GUID</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField fullWidth disabled name="userGuid" variant="outlined" size="small" value={header.userGuid} />
        </Grid>
        <Grid item xs={8} />
        <Divider />
        <Grid item xs={1}>
          <InputLabel id="instructions"></InputLabel>
        </Grid>
        <Grid item xs={3}>
          <p>For security reasons, your OASIS credentials will not be shown here... but you can enter your credentials, select save, and they will be used by TransNow to execute transactions with your OASIS user certificate.</p>
          <p>You can use either PROD or DEMO at one time.  Use DEMO when you are testing.  Use PROD when its time to conduct live trading operations against OASIS.</p>
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel>Certificate</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <FormAutocomplete
            name='userCertificate'
            control={control}
            fullWidth
            options={userCertificates ?? []}
            noOptionsText={noOptionsText}
            getOptionLabel={o => o.FriendlyName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Certificate"
                size='small'
                error={!!errors.userCertificate}
                helperText={errors.userCertificate?.message}
              />
            )}
            renderOption={(props, option) => {
              return (
                <div>
                  <Tooltip placement='right' title={
                    <Box sx={{ pt: 2, }}>
                      <Divider>Cert Info</Divider>
                      <List dense sx={{ fontSize: '1rem', }}>
                        <ListItem>Name: {option.Name}</ListItem>
                        <ListItem>Subject: {option.Subject}</ListItem>
                        <ListItem>ThumbPrint: {option.ThumbPrint}</ListItem>
                        <ListItem>Good On: {dayjs(option.GoodOn).format('MM/DD/YYYY HH:mm')}</ListItem>
                        <ListItem>Expiration: {dayjs(option.ExpiresOn).format('MM/DD/YYYY HH:mm')}</ListItem>
                      </List>
                      <Divider />
                    </Box>
                  }>
                    <div>
                      <MenuItem {...props} dense>{option.FriendlyName}</MenuItem>
                    </div>
                  </Tooltip>
                </div>
              );
            }}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel id="environment-label">OASIS Environment</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <FormAutocomplete
            name="system"
            control={control}
            options={["PROD", "DEMO"]}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Environment"
                size='small'
                error={!!errors.system}
                helperText={errors.system?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel>OASIS Username</InputLabel>
        </Grid>
        <Grid item xs={3}>
          <TextField
            {...register('OASISUser')}
            fullWidth
            helperText={errors.OASISUser?.message}
            error={!!errors.OASISUser}
            variant="outlined"
            size="small"
            label='Username'
          />
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1}>
          <InputLabel>OASIS Password</InputLabel>
        </Grid>
        <Grid item xs={3} >
          <TextField
            {...register('OASISPassword')}
            helperText={errors.OASISPassword?.message}
            error={!!errors.OASISPassword}
            fullWidth
            label='Password'
            size="small"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ margin: "30px", }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableSave}
          >Save OASIS Credentials</Button>
        </Grid>
      </Grid>
    </form>
  );
}