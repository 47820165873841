import React, { useEffect, useState, useRef, useMemo } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import PublishIcon from '@mui/icons-material/Publish';
import { AgGridReact } from 'ag-grid-react';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { useData } from '../useData';
import { useRemoteLayout } from '../useRemoteLayout';
import { apiUrlPrefix } from '../../authConfig';
import { Box, Button } from '@mui/material';
import useHeader from '../useHeader';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, refreshAll, triggerRefresh } = props;
  const rowId = node.id;
  const gridRef = useRef();
  const [treeNode, setTreeNode] = useState();
  const guid = useLocalGuid();
  const errorsOnly = 1;

  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${treeNode?.gridDataApiEndpoint}userGuid=${guid}&nodeId=${treeNode?.nodeId}&OprDay=${api.context.oprDay}&parm=${errorsOnly}`;
  var loadDataUri = `${treeNode?.gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${treeNode?.gridDataApiEndpoint}userGuid=${guid}&nodeId=${treeNode?.nodeId}&OprDay=${api.context.oprDay}&parm=${errorsOnly}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${treeNode?.gridDataApiEndpoint}userGuid=${guid}&nodeId=${treeNode?.nodeId}&OprDay=${api.context.oprDay}&parm=${errorsOnly}`;
  }


  const [colDefs, setColDefs] = useState([]);
  const { rowData, getData } = useData([]);
  const [buttonsDisabled, setButtonsDisabled] = React.useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const header = useHeader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (api.context.treeNode) {
      setTreeNode(api.context.treeNode);
    }
  }, [api.context.treeNode]);

  useEffect(() => {
    treeNode && fetch();
  }, [treeNode]);

  useEffect(() => {
    updateColDefs(rowData);
  }, [rowData]);

  useEffect(() => {
    if (colDefs.length > 0) {
      gridRef.current?.api && loadLayout();
    }
  }, [colDefs]);

  useEffect(() => {
    return () => {
      api.removeDetailGridInfo(rowId);
    };
  }, []);

  function editableCheck(params) {
    return (params.data.Version === "Scheduled");
  }

  function updateDef(def) {
    def.cellRenderer = cellRenderer;
    def.editable = (params) => editableCheck(params);
    if (def.children) {
      def.children.forEach(updateDef);
    }
  };

  const rowClassRules = {
    'row-fail': (params) => {
      return params.data?.rowClass === 'problem-row';
    }
  };

  function cellRenderer(params) {
    if (params.value) {
      const col = params.colDef.field.split('.'); //the field will look like HE5.Quantity.value.
      const cellClass = params.node.data[col[0]][col[1]]['cellClass']; //use the column def to parse the row data for the cell class
      return <div
        style={{ verticalAlign: 'middle', display: 'inline' }}
        className={(cellClass === 'problem-cell') ? 'row-fail' : 'row-pass'}
      >
        {` ${params.value}`}
      </div>;
    } else return null;
  }

  function injectColDefFunctions(defs) {
    defs.forEach(def => {
      const num = parseInt(def.field.match(/\d+/)); //parse the column field for an int
      if (num) {
        updateDef(def);
      }
    });

    return (defs);
  }

  const { loadFromDatabase: loadLayout } = useRemoteLayout(gridRef, treeNode?.nodeId, colDefs, injectColDefFunctions, false);

  function fetch() {
    gridRef.current.api?.showLoadingOverlay();
    setButtonsDisabled(true);

    getData(loadDataUri).then(() => {
      setDisableSaveButton(true)
      setButtonsDisabled(false);
      gridRef.current && gridRef.current.api.hideOverlay();
    })
  };

  function updateColDefs(data) {
    let defs = [];
    if (data.length > 0) {
      const fields = Object.keys(data[0]);
      defs = fields.map(field => {
        return {
          editable: true,
          headerName: field,
          field: field,
          filter: "agMultiColumnFilter",
          sortable: true,
        }
      });
    }
    setColDefs(defs);
  }

  const onRefreshBtn = () => {
    enqueueSnackbar('Refreshing grid data...');
    fetch();
  };

  async function handleSaveButtonClick() {
    setButtonsDisabled(true);
    enqueueSnackbar('Saving bids...');
    gridRef.current.api.stopEditing();

    let message = 'Bids saved to database.';

    //ecz 5/3/22 remove row tooltip and cell classes before sending to db
    const flattened = rowData.map(data => {
      const flatData = {};
      const cols = Object.keys(data);
      cols.forEach(col => {
        const num = parseInt(col.match(/\d+/)); //parse the column field for an int
        if (num) {
          flatData[`HEQ${num}`] = data[col].Quantity.value;
          flatData[`HEP${num}`] = data[col].Price.value;
        }
        else {
          flatData[col] = data[col];
        }
      });
      return flatData;
    });

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${treeNode.submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${treeNode.submitApiEndpoint}userGuid=${guid}&nodeId=${treeNode.nodeId}`;
    } else {
      url = `${apiUrlPrefix}/${treeNode.submitApiEndpoint}userGuid=${guid}&nodeId=${treeNode.nodeId}`;
    }

    axios.post(
      url,
      JSON.stringify(flattened),
      {
        headers: header,
      }
    ).then(response => {
      setButtonsDisabled(false);
      setDisableSaveButton(true);
      gridRef.current.api.hideOverlay();
      enqueueSnackbar(message);
      triggerRefresh({ market: treeNode.market });
    }).catch(err => function () { message = `Error saving Bidman Grid data.  NodeId: ${treeNode.nodeId} Status: ${err.response?.status}. Message: ${err}` });
  }

  const onGridReady = (params) => {
    const gridInfo = {
      id: node.id,
      api: params.api,
      columnApi: params.columnApi,
    };

    api.addDetailGridInfo(rowId, gridInfo);
    treeNode?.nodeId && loadLayout(params.api)
  };

  function onCellValueChanged(params) {
    setDisableSaveButton(false);
  }

  function onCopyRows() {
    gridRef.current.api.copySelectedRowsToClipboard();
  };

  const heightRef = useRef();
  const sideBar = useMemo(() => {
    return ({
      toolPanels: [
        {
          id: 'dashboard-detail-grid-column-panel',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        },
        {
          id: 'dashboard-detail-grid-filter-panel',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 180,
          maxWidth: 400,
          width: 250
        }
      ],
      position: 'left',
    })
  });

  const containerId = 'detail-grid-display-container';
  //lock in the height of the div container to prevent resizing to fit the tool panel
  function onToolPanelVisibleChanged() {
    if (gridRef.current.api.isToolPanelShowing()) {
      const container = document.getElementById(containerId);
      container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current + 220}px`);
      gridRef.current.api.setDomLayout('normal');
    } else {
      const container = document.getElementById(containerId);
      container.setAttribute('style', `width: 100%; font-size: 10px; height: ${heightRef.current - 220}px`);
      gridRef.current.api.setDomLayout('autoHeight');
    }
  };

  function onGridSizeChanged(params) {
    const container = document.getElementById(containerId);
    heightRef.current = container?.clientHeight;
  }

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  return (
    <div>
      <AgGridContainer
        style={{ width: "100%", fontSize: '10px' }}
        id={containerId}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            disabled={buttonsDisabled}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <Button
            onClick={onCopyRows}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={buttonsDisabled}
          >Ctrl-c</Button>
          <Button
            disabled={buttonsDisabled || disableSaveButton}
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleSaveButtonClick}
          >Save Changes</Button>&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          rowClassRules={rowClassRules}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          sideBar={sideBar}
          rowData={rowData}
          singleClickEdit
          onCellValueChanged={onCellValueChanged}
          onGridSizeChanged={onGridSizeChanged}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          //alwaysShowVerticalScroll
          domLayout={'autoHeight'}
          groupDefaultExpanded={-1}
          rowGroupPanelShow={'always'}
          rowSelection="multiple"
          tooltipShowDelay={0}
          groupSelectsChildren={true}
        />
      </AgGridContainer>
    </div>
  );
}