import { Tooltip, Button, Stack, Box, IconButton, } from "@mui/material";
import { ArrowForwardIos, Search } from "@mui/icons-material";
import { useMemo, useState } from 'react';
import { SearchDialog } from "./SearchDialog";
import ExpandIcon from '@mui/icons-material/Expand';

export const AvailabilityToolbar = ({ moveSelected, fetch, setShowAvailability, gridRef }) => {
    const [openSearchDialog, setOpenSearchDialog] = useState(false);

    function unselectAll() {
        gridRef.current.api.deselectAll();
    }

    return (
        <Stack spacing={2} direction="row" >
            <SearchDialog open={openSearchDialog} close={() => setOpenSearchDialog(false)} fetch={fetch} />
            <Button
                type='submit'
                variant="contained"
                endIcon={<Search />}
                onClick={() => setOpenSearchDialog(true)}
                size='small'
            >
                New Search
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title='Unselect all rows' arrow placement='right'>
                <Button
                    variant='contained'
                    onClick={unselectAll}
                    size='small'
                >Unselect</Button>
            </Tooltip>
            <Tooltip title="Move selected rows to the Workspace grid." arrow placement="right">
                <Button
                    endIcon={<ArrowForwardIos />}
                    onClick={moveSelected}
                    variant="contained"
                    size='small'
                >
                    Move
                </Button>
            </Tooltip>
            <Tooltip title="Toggle the visibility of the availability grid." arrow placement="right">
                <Button
                    onClick={() => setShowAvailability((prev) => !prev)}
                    variant="contained"
                    size='small'
                ><ExpandIcon sx={{ transform: 'rotate(90deg)' }} /></Button>
            </Tooltip>
        </Stack>
    );
}
