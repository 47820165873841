// import * as React from "react";
//import { useSpyMonkeyTimelineStyles } from "../styles";
//import axios from "axios";
//import { moment } from "moment";

import { useState, useEffect } from 'react';
//import Zoom from '@mui/material/Zoom';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
//import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMac';
//import HotelIcon from '@mui/icons-material/Hotel';
//import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { PinDropSharp, ThumbDown } from "@mui/icons-material";
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUp';
import QueueOutlinedIcon from '@mui/icons-material/Queue';
import UpdateOutlinedIcon from '@mui/icons-material/Update';
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocation';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { apiUrlPrefix } from "../authConfig";
import useHeader from './useHeader';

const moment = require('moment-timezone');

const icons = {
  "CONFIRMED": ThumbUpOutlinedIcon,
  "ACCEPTED": ThumbUpOutlinedIcon,
  "REJECTED": ThumbDownOutlinedIcon,
  "REFUSED": ThumbDownOutlinedIcon,
  "QUEUED": QueueOutlinedIcon,
  "INVALID": WrongLocationOutlinedIcon,
  "RECEIVED": LaptopMacOutlinedIcon,
  "COUNTEROFFER": UpdateOutlinedIcon,
  "ANNULLED": DeleteForeverOutlinedIcon,
};

const FieldIcon = ({ name }) => {
  const Icon = icons[name];
  return Icon ? (<Icon />) : null;
};


function SpyMonkeyTimeline({disabled}) {
    //const classes = useSpyMonkeyTimelineStyles();

    const [tsrs, setTsrs] = useState(null);
    const [time, setTime] = useState(Date.now());
    const [seconds, setSeconds] = useState(0);
    const headers = useHeader();

    useEffect(() => {
        
        getData();
                
            //const interval = setInterval(() => setTime(Date.now()), 1000);
            const interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
                setTime(Date.now());
                getData();
              }, 1000);
            
            return () => {
                clearInterval(interval);
            };
        
        // we will use async/await to fetch this data
        async function getData() {
        const response = await fetch(`${apiUrlPrefix}/CrystalBall/TSR/TransactionFeed?desiredCount=10`,
        {
          headers: headers,
        });
        const data = await response.json();
        // store the data into our books variable
        setTsrs(data.tsrs);
        }

    }, []); // <- you may need to put the setBooks function in this array

    return (
    <Timeline align="alternate">
        <TimelineItem>
        <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary">
            <Typography>ARef | {moment(time).format('hh:mm:ss A')} ET</Typography>
            <Typography>POR | POD | Status</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <PinDropSharp />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h10" component="span">
            MW Requested | MW Granted @ Price
          </Typography>
          <Typography>Customer Name | Email</Typography>
        </TimelineContent>
      </TimelineItem>
       {/* display offerings from the API */}
        {tsrs && (
        <div className="tsrs">

            {/* loop over the books */}
            {tsrs.map((tsr, index) => (
            <TimelineItem key={index}>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary">
                    <Typography>aRef {tsr.assignmentRef} @ {moment.tz(tsr.timeOfLastUpdate,"America/New_York").utcOffset(-8).format('hh:mm:ss A')} ET</Typography>
                    <Typography>{tsr.pointOfReceipt} | {tsr.pointOfDelivery} | {tsr.requestType} {tsr.status}</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                    <FieldIcon name={tsr.status} />
                </TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                {tsr.capacityGranted > 0 && 
                    <Typography variant="h10" component="span">{tsr.capacity} MW Requested | {tsr.capacityGranted} MW Granted @ ${tsr.bidPrice}</Typography>
                    || <Typography variant="h10" component="span">{tsr.capacity} MW Requested</Typography>
                }
                <Typography>{tsr.customerName} | {tsr.customerEmail}</Typography>
                </TimelineContent>
            </TimelineItem>
            ))}

        </div>
        )}
    
      </Timeline>

    );
  
  
}


export default SpyMonkeyTimeline;