import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import Analysis from "../BrainTopology/Analysis";
import axios from "axios";
import useHeader from "../../useHeader";
import { useSnackbar } from "notistack";
import { apiUrlPrefix } from "../../../authConfig";
import { Autocomplete, Stack, Box, TextField, CircularProgress, Typography, Button, } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import { useUserGroups } from "../../../data/useUserGroups";
import { userGroups } from "../../../authConfig";
import { InputSlider } from "./InputSlider";
import styled from '@mui/material/styles/styled';
import debounce from 'lodash/debounce';
import data from "../../../data/dummyData";

export const Adjustments = () => {
    const headers = useHeader();
    const { userIsInGroup } = useUserGroups();
    const isAdmin = userIsInGroup(userGroups.admins);
    const { enqueueSnackbar } = useSnackbar();
    const [brains, setBrains] = useState([]);
    const [brain, setBrain] = useState({});
    const [modelNames, setModelNames] = useState(['']);
    const [modelName, setModelName] = useState('');
    const [dailyData, setDailyData] = useState([]);
    const [hourlyData, setHourlyData] = useState([]);
    const [weights, setWeights] = useState([]);
    const [loading, setLoading] = useState(true);
    const slidersRef = useRef({});

    const brainName = brain.name ?? '';
    const distinctBrains = useMemo(() => uniqBy(brains, 'brainID'), [brains]);

    useEffect(() => {
        if (brainName && modelName) {
            setLoading(true);
            loadBrainData();
            fetchWeights(brainName, modelName).finally(() => {
                setLoading(false);
            });
        }
    }, [brainName, modelName]);

    async function loadBrainData() {
        fetchBrainError(brainName, modelName).then(() => {
            fetchBrainError(brainName, modelName, 'hourly');
        });
    }

    useEffect(() => {
        fetchBrainNameList().then((response) => {
            if (response.data) {
                const defaultBrain = response.data[0];
                const models = [...new Set(response.data.filter(item => item.name === defaultBrain.name).map((item) => item.modelName))];
                setBrains(response.data);
                setModelNames(models);
                setBrain(defaultBrain ?? {});
                setModelName(defaultBrain.modelName ?? '');
            }
        });
    }, []);

    async function fetchBrainNameList() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainNames&parm=${headers.userGuid}`;

        return axios.get(url, { headers: headers }).catch((error) => {
            enqueueSnackbar(`Error fetching brain names: ${error}`, { variant: 'error', });
        });
    }

    function handleBrainChange(newBrain) {
        setBrain(newBrain);
        const models = [...new Set(brains.filter(item => item.name === newBrain.name).map((item) => item.modelName))];
        setModelNames(models);
        //don't change the model if the current model is still valid
        if (!models.includes(modelName)) {
            setModelName(models[0]);
        }
    }

    async function fetchBrainError(brainName, modelName, dateInterval = 'daily') {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainError&parm=${brainName}&parm=${dateInterval}&parm=${modelName}`;

        return axios.get(url, { headers: headers }).then((response) => {
            if (response.data) {
                const newData = response.data.map(item => ({
                    ...item,
                    dateTimeValue: new Date(item.dateTimeValue),
                }));
                if (dateInterval === 'daily') {
                    setDailyData(newData);
                } else {
                    setHourlyData(newData);
                }
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching brain error: ${error}`, { variant: 'error', });
        });
    }

    async function fetchWeights(brainName, modelName) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchSimpleBrainWeights&parm=${headers.userGuid}&parm=${brainName}&parm=${modelName}`;

        return axios.get(url, { headers: headers }).then((response) => {
            if (response.data) {
                setWeights(response.data);
            }
        }).catch((error) => {
            enqueueSnackbar(`Error fetching brain error: ${error}`, { variant: 'error', });
        });
    }

    const updateChart = useCallback(debounce(async (inputName, weight) => {
        fetchInputXvalues(inputName, weight, 'daily').then((response) => {
            setDailyData(addDelta(response.data, dailyData));
            fetchInputXvalues(inputName, weight, 'hourly').then((response) => {
                setHourlyData(addDelta(response.data, hourlyData));
            });
        });
    }, 200), [headers, brainName, modelName, dailyData, hourlyData]);

    const addDelta = (deltaData, data) => {
        return data.map((item, index) => {
            const delta = deltaData[index].predictedChange;
            const key = 'Predicted Value (Overall)'
            return {
                ...item,
                [key]: item[key] + delta,
            };
        });
    }

    const fetchInputXvalues = useCallback(async (inputName, weight, dateInterval) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchInputXvalues&parm=${brainName}&parm=${modelName}&parm=${inputName}&parm=${dateInterval}&parm=${weight}`;

        return axios.get(url, { headers: headers }).catch((error) => {
            enqueueSnackbar(`Error fetching x values for input: ${error}`, { variant: 'error', });
        });
    }, [headers, brainName, modelName]);

    const sortFunction = (sortMethod) => (a, b) => {
        const aVal = slidersRef.current[a.inputName].getValue();
        const bVal = slidersRef.current[b.inputName].getValue();
        if (sortMethod.value === 'alphabetical') {
            return a.inputName.localeCompare(b.inputName);
        } else if (sortMethod.value === 'weightHighest') {
            return bVal - aVal;
        } else if (sortMethod.value === 'weightLowest') {
            return aVal - bVal;
        } else if (sortMethod.value === 'weightAbsolute') {
            return Math.abs(bVal) - Math.abs(aVal);
        }
    }

    const onSliderChange = useCallback((inputName) => {
        return (newValue) => {
            updateChart(inputName, newValue);
        }
    }, [updateChart]);

    function handleReset() {
        Object.keys(slidersRef.current).forEach(key => {
            slidersRef.current[key]?.reset();
        });
        loadBrainData();
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '92vh', py: 1, }}>
            <Stack direction='row' spacing={3} sx={{ px: 1 }}>
                <Autocomplete
                    autoHighlight
                    autoComplete
                    autoSelect
                    disableClearable
                    options={distinctBrains}
                    value={brain}
                    getOptionLabel={(option) => option.name ?? ''}
                    onChange={(_, newValue) => {
                        handleBrainChange(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Brain Name"
                            size='small'
                            variant="standard"
                            sx={{ width: '250px' }}
                            helperText={(isAdmin && !!brain.userName) ? `${brain.userName}` : ''}
                        />
                    )}
                />
                <Autocomplete
                    autoHighlight
                    autoComplete
                    autoSelect
                    disableClearable
                    options={modelNames}
                    value={modelName}
                    onChange={(_, newValue) => {
                        setModelName(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Model Name"
                            size='small'
                            variant="standard"
                            sx={{ width: '350px' }}
                        />
                    )}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    onClick={handleReset}
                    variant='contained'
                    sx={{ height: '70%' }}
                >
                    Reset
                </Button>
                <Autocomplete
                    autoHighlight
                    autoComplete
                    autoSelect
                    options={sortMethods}
                    getOptionLabel={(option) => option.label}
                    onChange={(_, newValue) => {
                        setWeights(prev => [...prev].sort(sortFunction(newValue)));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Sort Method"
                            size='small'
                            variant="standard"
                            sx={{ width: '250px' }}
                        />
                    )}
                />
            </Stack>
            <Box sx={{ position: 'relative', flexGrow: 1, }}>
                <Stack direction='row' spacing={2} sx={{ position: 'absolute', overflow: 'auto', width: '100%', height: '100%', py: 2 }}>
                    {weights.map(({ inputName, weight }, i) => (
                        <InputSlider
                            label={inputName}
                            key={inputName}
                            defaultValue={weight}
                            onSubmit={onSliderChange(inputName)}
                            ref={el => slidersRef.current[inputName] = el}
                            min={-1}
                            max={1}
                        />
                    ))}
                </Stack>
                <LoadingOverlay visible={loading} />
            </Box>
            <Box sx={{ height: '40%', position: 'relative', }}>
                <Analysis dailyData={dailyData} hourlyData={hourlyData} seriesKeys={['Predicted Value (Overall)', 'Target Value']} />
            </Box>
        </Box>
    );
}

const sortMethods = [
    { label: 'ABC', value: 'alphabetical' },
    { label: 'Weight (Highest)', value: 'weightHighest' },
    { label: 'Weight (Lowest)', value: 'weightLowest' },
    { label: 'Weight (Absolute)', value: 'weightAbsolute' },
];

const LoadingOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <CircularProgress />&nbsp;&nbsp;
            <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    display: visible ? 'flex' : 'none',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
}));