import { IconButton, Typography, Stack, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useMemo, } from 'react';
import Close from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import FormDateTimePicker from '../../FormControls/FormDateTimePicker';

export const TagTerminationDialog = (props) => {
    const { open, onTerminate, onCancel, selectedTag, } = props;

    const schema = yup.object().shape({
        endDate: yup.date().required('End Date is required.').typeError('End Date must be a date.'),
    });

    const defaultValues = useMemo(() => {
        const tagStartTime = dayjs(selectedTag?.['Start Time']);
        const startOfNextHour = dayjs().add(1, 'hour').startOf('hour');
        const endDate = tagStartTime.isAfter(startOfNextHour) ? tagStartTime : startOfNextHour;
        return {
            endDate: endDate,
        }
    }, [selectedTag]);

    const { handleSubmit, formState: { errors }, control, register, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <Dialog open={!!open} maxWidth={'md'} fullWidth>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Select Date for Termination</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={onCancel} size='large'>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select the date and time you would like to terminate the tag, and add notes if desired.
                </DialogContentText>
                <Stack component='form' id='select-date-for-termination-dialog-form' onSubmit={handleSubmit(onTerminate)} spacing={2} sx={{ p: 2, }}>
                    <FormDateTimePicker
                        name='endDate'
                        control={control}
                        label="End Date/Time"
                        disablePast
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.endDate,
                                helperText: errors.endDate?.message,
                                sx: { width: '30%' }
                            }
                        }}
                    />
                    <TextField
                        {...register('notes')}
                        multiline
                        maxRows={5}
                        fullWidth
                        label="Notes"
                    />
                </Stack>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button color="inherit" size="small" onClick={onCancel}>
                        CANCEL
                    </Button>
                    <Button color="inherit" size="small" type='submit' form='select-date-for-termination-dialog-form'>
                        TERMINATE
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}