import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

const FormDatePicker = ({ name, control, ...inputProps }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker 
            value={value} 
            onChange={onChange} 
            {...inputProps}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormDatePicker;