import { AgChartsReact } from 'ag-charts-react';
import { forwardRef, useMemo, useState, useImperativeHandle, useEffect } from 'react';
import { useTheme } from '@mui/material';

export const ProfileChart = forwardRef(({ profileData, }, ref) => {
    const theme = useTheme();
    const palette = theme.palette.primary;

    function formatDataForChart(data) {
        return data?.reduce((profile, block, idx) => {
            const endDate = new Date(block.endDateTime);
            const startDate = new Date(block.startDateTime);
            const capacity = parseInt(block.capacityRequested);
            const nextBlockData = [];
            if (idx > 0) {
                const prevBlock = data[idx - 1]; //fill in gaps in blocks with 0 capacity
                if (prevBlock.endDateTime && Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
                    nextBlockData.push({
                        //x: new Date(prevBlock.endDateTime + 1000),
                        x: new Date(prevBlock.endDateTime),
                        y: 0,
                    })
                    nextBlockData.push({
                        //x: new Date(startDate - 1000),
                        x: new Date(startDate),
                        y: 0,
                    })
                }
            }
            block.startDateTime && nextBlockData.push({
                x: startDate,
                y: capacity,
            })
            block.endDateTime && nextBlockData.push({
                x: endDate,
                y: capacity,
            })
            return [...profile, ...nextBlockData]
        }, [])
    }

    const series = (data) => [
        {
            data: formatDataForChart(data),
            type: 'line',
            title: 'Capacity',
            xKey: 'x',
            yKey: 'y',
        }
    ]

    const defaultOptions = useMemo(() => ({
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                ],
                strokes: [
                    palette.yellow,
                    palette.main,
                    palette.red,
                ],
            },
            overrides: {
                line: { series: { strokeWidth: 3, marker: { enabled: true } } },
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? palette.darkBackground : palette.white,
        },
        autoSize: true,
        padding: {
            left: 40,
            right: 40,
        },
        axes: [
            {
                position: 'bottom',
                type: 'time',
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
            },
            {
                position: 'left',
                type: 'number',
                min: 0,
                title: {
                    text: 'Capacity',
                },
            },
        ],
        legend: {
            position: 'right',
        },
        series: series(profileData),
    }), [profileData, theme.palette.mode]);

    const [chartOptions, setChartOptions] = useState(defaultOptions);

    function refreshChart() {
        setChartOptions({
            ...chartOptions,
            series: series(profileData),
        });
    }

    useEffect(() => {
        refreshChart();
    }, [profileData]);

    useImperativeHandle(ref, () => {
        return {
            refresh() {
                refreshChart();
            },
        };
    });

    return (
        <AgChartsReact options={chartOptions} style={{ height: '100%' }} />
    );
});