import { useEffect, useState, useRef, forwardRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { TextField, Tooltip, Box, Button, Grid, MenuList, MenuItem, ListItemIcon, ListItemText, } from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';

export const TemplateToolPanel = ({ fieldNames, currentTemplates, addTemplate, deleteTemplate, }) => {
  const [fields, setFields] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  function updateField(fieldName, e) {
    const val = e.target.value;
    const newFields = { ...fields };
    newFields[fieldName] = val;
    setFields(newFields);
  }

  function handleAddTemplateClick() {
    const errors = fieldNames.filter(name => !fields[name]);
    if (errors.length > 0) {
      errors.map(err => enqueueSnackbar(`Field '${err}' must not be blank.`));
      const newErrors = {};
      errors.forEach(err => newErrors[err] = true);
      setErrorFields(newErrors);
      return;
    }

    const template = {
      data: fields,
      name: fields['name'] ?? fields['Name'],
    };

    addTemplate(template)
  }

  return (
    <div className="template-table">
      {fieldNames.map(field =>
        <p><TextField
          label={field}
          size='small'
          fullWidth
          variant='outlined'
          onChange={(e) => updateField(field, e)}
          error={errorFields[field]}
        /></p>
      )}
      <Button variant='contained' fullWidth onClick={handleAddTemplateClick}>Save</Button>
      <MenuList>
        {currentTemplates.map(template =>
          <MenuItem>
            <ListItemIcon>
              <DeleteIcon onClick={() => deleteTemplate(template.name)} color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>{template.name}</ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </div>
  );
}