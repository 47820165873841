import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix } from '../../../authConfig';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useHeader from '../../useHeader';
import 'ag-grid-community/styles/ag-grid.css'
import useStalkerGridLayout from '../Stalker/useStalkerGridLayout';
import { RowDetailsToolPanel } from '../../ToolPanels/DetailsToolPanel';
import { LayoutToolPanel } from '../../ToolPanels/LayoutToolPanel.js';
import { AgGridReact, } from 'ag-grid-react';
import React, { forwardRef, useImperativeHandle, useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import SystemDataToolbar from './SystemDataToolbar';
import { stringIntComparator } from '../../../utils/gridFunctions';
import { AgGridContainer } from '../../AgGrid/AgGridContainer';

const CustomTooltip = forwardRef((props, ref) => {
  const [data, setData] = useState(
    props.api.getDisplayedRowAtIndex(props.rowIndex).data
  );

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip'];
      },
    };
  });

  alert();

  return (
    <div className="custom-tooltip" style={{ backgroundColor: props.color || 'white' }}>
      <p>ARef: {data.assignmentRef}</p>

    </div>
  );

});

export default (props) => {
  const { view, } = props;
  const [data, setData] = useState([])
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_tsrActivityWithAtc&parm=${guid}`;
  const gridRef = useRef();

  const storageLocation = `stalker-grid-${view.id}`;
  const [rowDetails, setRowDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState(false);
  const [openSubmitDuplicateDialog, setOpenSubmitDuplicateDialog] = useState(false);
  const [selectedTsr, setSelectedTsr] = useState();

  useEffect(() => retrieveTsrs(view), []);

  function onCellFocused(e) {
    const i = e.rowIndex;
    const row = e.api.rowModel.rowsToDisplay[i];
    //row.data has the fields mapped to cell values, but we want headerNames mapped to cell values
    //(to make it look nice.) So first we create a mapping of fields to headerNames,
    //then use this to create the headerName:value mapping.
    if (row) {
      const colDefs = e.columnApi.columnModel.columnDefs;
      const headerNames = colDefs.reduce(function (names, current) {
        names[current.field] = current.headerName;
        return names;
      }, {}); //headerNames looks like { field: headerName }

      const data = Object.keys(row.data).reduce(function (data, field) {
        data[headerNames[field]] = row.data[field];
        return data;
      }, {}); //data looks like { headerName: cellValue }

      setRowDetails(data);
      const show = e.api.isToolPanelShowing('details');
      setShowDetails(show);
    }
  };

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      conte: yValue
    }
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED'
    },
  };

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      rowDrag: true,
      headerName: "ARef",
      enableRowGroup: true,
      field: "AssignmentRef",
      comparator: stringIntComparator,
      tooltipComponent: CustomTooltip,
      tooltipField: "assignmentRef",
      tooltipComponentParams: { color: '#ececec' },
    },
    {
      headerName: "Customer Code",
      enableRowGroup: true,
      initialHide: false,
      field: "CustomerCode",
    },
    {
      headerName: "Provider",
      enableRowGroup: true,
      field: "Provider",
    },
    {
      headerName: "POR",
      enableRowGroup: true,
      field: "PointOfReceipt",
    },
    {
      headerName: "POD",
      enableRowGroup: true,
      field: "PointOfDelivery",
    },
    {
      headerName: "Request Type",
      enableRowGroup: true,
      field: "RequestType",
    },
    {
      headerName: "Status",
      enableRowGroup: true,
      tooltipField: "Status",
      field: "Status",
    },
    {
      headerName: "Volume",
      enableRowGroup: true,
      field: "Capacity",
    },
    {
      headerName: "Start",
      enableRowGroup: true,
      field: "StartTime",
    },
    {
      headerName: "Stop",
      enableRowGroup: true,
      field: "StopTime",
    },
    {
      headerName: "Current NFATC",
      enableRowGroup: true,
      field: "currentNFATC",
    },
    {
      //field: "atc",
      headerName: "Profile",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.capacityProfile) {
          const atcData = JSON.parse(params.data.capacityProfile).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      //field: "atc",
      headerName: "NFATC",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.NFATC) {
          const atcData = JSON.parse(params.data.NFATC).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      //field: "atc",
      headerName: "FATC",
      cellRenderer: 'agSparklineCellRenderer',
      minWidth: 400,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.FATC) {
          const atcData = JSON.parse(params.data.FATC).map(xyArray => [
            new Date(xyArray[0]),
            xyArray[1]
          ]);
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          }
        },
      },
    },
    {
      headerName: "Seller Code",
      enableRowGroup: true,
      initialHide: false,
      field: "SellerCode",
    },
    {
      headerName: "Comments",
      enableRowGroup: true,
      initialHide: false,
      field: "SELLER_COMMENTS",
    },
    {
      headerName: "Preconfirmed",
      enableRowGroup: true,
      initialHide: true,
      field: "Preconfirmed",
    },
    {
      headerName: "Source",
      enableRowGroup: true,
      field: "Source",
      initialHide: true,
    },
    {
      headerName: "Sink",
      enableRowGroup: true,
      initialHide: true,
      field: "Sink",
    },
    {
      headerName: "Path Name",
      enableRowGroup: true,
      field: "PathName",
      initialHide: false,
    },
    {
      headerName: "System Element",
      enableRowGroup: true,
      field: "SYSTEM_ELEMENT",
      initialHide: true,
    },
    {
      headerName: "Service Inc",
      enableRowGroup: true,
      initialHide: true,
      field: "ServiceIncrement",
    },
    {
      headerName: "Class",
      enableRowGroup: true,
      initialHide: true,
      field: "TSClass",
    },
    {
      headerName: "Type",
      enableRowGroup: true,
      initialHide: true,
      field: "TSType",
    },
    {
      headerName: "Period",
      enableRowGroup: true,
      initialHide: true,
      field: "TSPeriod",
    },
    {
      headerName: "Window",
      enableRowGroup: true,
      initialHide: true,
      field: "TSWindow",
    },
    {
      headerName: "Subclass",
      enableRowGroup: true,
      initialHide: true,
      field: "TSSubclass",
    },
    {
      headerName: "Ceiling Price",
      enableRowGroup: true,
      field: "CeilingPrice",
    },
    {
      headerName: "Offer Price",
      enableRowGroup: true,
      field: "OfferPrice",
    },
    {
      headerName: "Bid Price",
      enableRowGroup: true,
      initialHide: true,
      field: "BidPrice",
    },
    {
      headerName: "Price Unit",
      enableRowGroup: true,
      initialHide: true,
      field: "PriceUnit",
    },
    {
      headerName: "Sale Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "SaleRef",
    },
    {
      headerName: "Request Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "RequestRef",
    },
    {
      headerName: "Deal Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "DealRef",
    },
    {
      headerName: "PostingRef",
      enableRowGroup: true,
      initialHide: true,
      field: "PostingRef",
    },
    {
      headerName: "Reassigned Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedRef",
    },
    {
      headerName: "Reassigned Capacity",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedCapacity",
    },
    {
      headerName: "Reassigned Start",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedStartTime",
    },
    {
      headerName: "Reassigned Stop",
      enableRowGroup: true,
      initialHide: true,
      field: "ReassignedStopTime",
    },
    {
      headerName: "Related Ref",
      enableRowGroup: true,
      field: "RelatedRef",
    },
    {
      headerName: "Seller Ref",
      enableRowGroup: true,
      initialHide: true,
      field: "SellerRef",
    },
    {
      headerName: "Reservation Profile Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "ReservationProfileFlag",
    },
    {
      headerName: "Affiliate Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "AffiliateFlag",
    },
    {
      headerName: "NP Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "NegotiatedPriceFlag",
    },
    {
      headerName: "Time Queued",
      enableRowGroup: true,
      initialHide: true,
      field: "TimeQueued",
    },
    {
      headerName: "Response Time Limit",
      enableRowGroup: true,
      initialHide: true,
      field: "ResponseTimeLimit",
    },
    {
      headerName: "Last Update",
      enableRowGroup: true,
      initialHide: true,
      field: "TimeOfLastUpdate",
    },
    {
      headerName: "Capacity Granted",
      enableRowGroup: true,
      initialHide: true,
      field: "CapacityGranted",
    },
    {
      headerName: "Impacted",
      enableRowGroup: true,
      initialHide: true,
      field: "Impacted",
    },
    {
      headerName: "Competing Request Flag",
      enableRowGroup: true,
      initialHide: true,
      field: "CompetingRequestFlag",
    },
    {
      headerName: "primaryProviderApproval",
      enableRowGroup: true,
      initialHide: true,
      field: "PrimaryProviderApproval",
    },
    {
      headerName: "primaryProviderProvisions",
      enableRowGroup: true,
      initialHide: true,
      field: "PrimaryProviderProvisions",
    },
    {
      headerName: "Rollover Waived",
      enableRowGroup: true,
      initialHide: true,
      field: "RolloverWaived",
    },
    {
      headerName: "CGStatus",
      enableRowGroup: true,
      initialHide: true,
      field: "CGStatus",
    },
    {
      headerName: "CGDeadline",
      enableRowGroup: true,
      initialHide: true,
      field: "CGDeadline",
    },
  ], []);

  const { layoutPanel, colDefs, loadLayout } = useStalkerGridLayout(storageLocation, gridRef, baseColDefs);

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 180,
        maxWidth: 400,
        width: 250
      },
      layoutPanel,
      {
        id: 'details',
        labelDefault: 'Details',
        labelKey: 'details',
        //iconKey: '',
        toolPanel: 'detailsToolPanel',
        toolPanelParams: { rowData: rowDetails },
        minWidth: 470,
        maxWidth: 490,
        width: 490
      }
    ],
    position: 'right',
    //defaultToolPanel: 'filters'
  };

  function onRowDataUpdated() {
    loadLayout();
  }

  function handleCloseStatusChangeDialog(triggerTsr) {
    setOpenStatusChangeDialog(false);
  }

  function retrieveTsrs(criteria) {
    gridRef.current.api.showLoadingOverlay();

    const uri = `${loadUri}&parm=${criteria.timezone ?? 'US/Pacific'}`
      + `&parm=${criteria.requestType ?? ''}`
      + `&parm=${criteria.provider ?? ''}`
      + `&parm=${criteria.por ?? ''}`
      + `&parm=${criteria.pod ?? ''}`
      + `&parm=${criteria.customerCode ?? ''}`
      + `&parm=${criteria.serviceIncrement ?? ''}`
      + `&parm=${criteria.serviceClass ?? ''}`
      + `&parm=${criteria.startDate ?? ''}`
      + `&parm=${criteria.startHour ?? ''}`
      + `&parm=${criteria.stopDate ?? ''}`
      + `&parm=${criteria.stopHour ?? ''}`

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      if (typeof response.data === 'string') {
        enqueueSnackbar(response.data)
      } else {
        setData(response.data);
        enqueueSnackbar(`${view.label} TSR data retrieved.`);
      }
      gridRef.current.api.hideOverlay();
    }).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
      gridRef.current?.api.hideOverlay();
    });
  }

  function handleStatusChangeClick() {
    const row = gridRef.current.api.getSelectedRows(); //there can only be one
    setSelectedTsr(row[0]);
    setOpenStatusChangeDialog(true);
  }

  function handleSubmitDuplicateClick() {
    const row = gridRef.current.api.getSelectedRows(); //there can only be one
    setSelectedTsr(row[0]);
    setOpenSubmitDuplicateDialog(true);
  }

  function scheduleDuplicateTsr() {
    //TODO
    setOpenSubmitDuplicateDialog(false);
    enqueueSnackbar('This part still needs a backend');
  }

  return (
    <AgGridContainer
      style={{
        width: "100%", height: '73vh'
      }}
    >
      <SystemDataToolbar
        onRefresh={retrieveTsrs}
        view={view}
        handleStatusChangeClick={handleStatusChangeClick}
        handleSubmitDuplicateClick={handleSubmitDuplicateClick}
      />
      <AgGridReact
        ref={gridRef}
        rowData={data}
        columnDefs={colDefs}
        onRowDataUpdated={onRowDataUpdated}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        sideBar={sideBar}
        paginationAutoPageSize={true}
        groupDefaultExpanded={1}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        enableCellChangeFlash={true}
        rowSelection='single'
        //enableRangeSelection={true}
        onCellFocused={onCellFocused}
        animateRows={true}
        tooltipShowDelay={0}
        groupSelectsChildren={true}
        components={{
          customTooltip: CustomTooltip,
          detailsToolPanel: RowDetailsToolPanel,
          layoutToolPanel: LayoutToolPanel,
        }}
      />
    </AgGridContainer>
  )
}