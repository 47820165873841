import { useForm } from 'react-hook-form';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import { AgGridReact } from 'ag-grid-react';
import { apiUrlPrefix } from '../../authConfig';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import { Button, Stack, TextField, createFilterOptions } from '@mui/material';
import { useEffect, useMemo, useState, useRef, } from 'react';
import axios from 'axios';
import useHeader from '../useHeader';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

const filter = createFilterOptions();

export const ChatbotContextManagement = () => {
    const header = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [contextOptions, setContextOptions] = useState([]);
    const gridRef = useRef();
    const { control, handleSubmit, formState: { errors, }, setValue, watch } = useForm({
        resolver: yupResolver(schema),
    });

    const application = watch('application');

    useEffect(() => {
        //remove the old rows
        let rowData = [];
        gridRef.current?.api?.forEachNode(node => rowData.push(node.data));
        gridRef.current?.api?.applyTransaction({ remove: rowData, });

        //add the new rows
        gridRef.current?.api?.applyTransaction({ add: contextOptions.filter(row => row.application === application) });
    }, [application]);

    useEffect(() => {
        fetchContext();
    }, []);

    async function onSubmit(data) {
        gridRef.current.api.stopEditing();
        let rowData = [];
        gridRef.current?.api?.forEachNode(node => rowData.push({
            ...node.data,
            userGuid: node.data.userGuid ?? header.userGuid,
            application: data.application,
        }));

        const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.AI_ContextSave&parm=${header.userGuid}`;

        const options = {
            method: 'POST',
            headers: header,
            data: rowData,
            url: saveUri,
        }

        axios(options).then(response => {
            enqueueSnackbar('Context saved.', { variant: 'success' });
        }).catch(err => {
            enqueueSnackbar(`Error saving context. ${err.response.data}`, { variant: 'error' });
        });
    }

    async function fetchContext() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.AI_ContextFetch&parm=${header.userGuid}`

        let options = {
            headers: header,
            url: url,
        };

        axios(options).then(response => {
            setContextOptions(response.data);
            gridRef.current?.api?.showNoRowsOverlay();
        }).catch(err => {
            enqueueSnackbar(`Error fetching context. Error: ${err}`, { variant: 'error', });
        });
    }

    const colDefs = useMemo(() => [
        {
            editable: false,
            headerName: "ID",
            field: "contextID",
            flex: 1,
        },
        {
            headerName: 'Question',
            field: 'question',
            flex: 6,
        },
        {
            headerName: 'Answer',
            field: 'answer',
            flex: 24,
        }
    ], []);

    const defaultColDefs = useMemo(() => ({
        editable: true,
        floatingFilter: true,
        filter: "agMultiColumnFilter",
        resizable: true,
    }), [])

    function getRowNodeId(params) {
        return params.data.contextID;
    }

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    function addBlankRow() {
        gridRef.current.api.applyTransaction({
            add: [{}],
        });
    };

    const applicationOptions = [...new Set(contextOptions.map(row => row.application))];

    return (
        <form onSubmit={handleSubmit(onSubmit)} id='chatbot-management-context-form'>
            <Stack direction='row' spacing={2} sx={{ paddingBottom: 1, }}>
                <FormAutocomplete
                    name='application'
                    control={control}
                    handleHomeEndKeys
                    selectOnFocus
                    freeSolo
                    sx={{ minWidth: '300px', }}
                    options={applicationOptions}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Regular option
                        return option.application;
                    }}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setValue('application', newValue);
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue('application', newValue.inputValue);
                        } else {
                            setValue('application', newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                application: `Add "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Application"
                            error={!!errors.application}
                            helperText={errors.application?.message}
                        />
                    )}
                />
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    endIcon={<SaveIcon />}
                    sx={{ marginTop: '20px', }}
                >Save</Button>
                <Button
                    endIcon={<AddCircleOutlinedIcon />}
                    variant='contained'
                    color='primary'
                    onClick={addBlankRow}
                >Add New</Button>
            </Stack>
            <AgGridContainer
                style={{
                    height: "43vh",
                    width: "98%",
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={colDefs}
                    getRowId={getRowNodeId}
                    defaultColDef={defaultColDefs}
                    statusBar={statusBar}
                    overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">No rows. Either select an application or add a new row.</span>`}
                />
            </AgGridContainer>
        </form >
    )
}

const schema = object().shape({
    application: string().required('Please create or select an application.'),
});