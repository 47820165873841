import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import moment from 'moment';
import { range } from 'lodash';
import { AutocompleteEditor } from '../AutocompleteEditor';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeOriginals.css'
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AgGridReact } from 'ag-grid-react';
import CustomNoRowsOverlay from './customNoRowsOverlay.js';
import { useLocalData } from '../useLocalData';
import { useServiceDropdown } from '../useServiceDropdown';
import 'ag-grid-enterprise';
import '../../styles/realTimeStyles.css'
import { TextField, Box, Grid, MenuList, MenuItem, ListItemIcon, ListItemText, Autocomplete, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useActionAudit } from '../useActionAudit';
import useHeader from '../useHeader';
import { DateTimeCellEditor } from '../AgGrid/DateTimeCellEditor';
import useGridLayout from '../useGridLayout';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const { addTemplate, templates, deleteTemplate, timezone, headerLabelDate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',];
  const storageLocation = 'real-time-original-request-grid-layout';
  const headers = useHeader();
  const [selectedRows, setSelectedRows] = useState([]);
  const [actOnConfirm, setActOnConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');

  const [setRowValuesWithService, serviceValues] = useServiceDropdown('Real Time Originals');
  const { logAction } = useActionAudit();
  useLocalData(storageLocation, ref);

  //const [colDefs, setColDefs] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const transmissionTemplateTypeId = 4;

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";


  useEffect(() => {
    autoSizeAll(false);
    //enqueueSnackbar(JSON.stringify(colDefs));
    //ref.current.api.setColumnDefs(colDefs);
    //ref.current.api.refreshHeader();
  }, [headerLabelDate,]);

  useEffect(() => {
    if (serviceValues.values.length > 0) {
      loadLayout();
    }
  }, [serviceValues]);

  const addOriginalRequestItems = (addIndex) => {
    const newItems = [{}];
    addOriginalRequestRow(newItems);
    logAction(`Created a blank row`, storageLocation);
  };

  const addOriginalRequestRowCopy = (addIndex) => {
    ref.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = ref.current.api.getSelectedRows();

    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      newRow['New Aref'] = "";
      newRow['Status'] = "";
      newRow['Result'] = "";
      return newRow;
    })

    addOriginalRequestRow(data);
    logAction(`Copied a row`, storageLocation);
  };

  const onRemoveOriginalRequestSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
    logAction(`Deleted a row`, storageLocation);
  };

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    ref.current.api.forEachNode(node => rowData.push(node.data));
    let message = 'JSON for entire grid generated.';

    alert(`Selected nodes: ${JSON.stringify(rowData)}`);
    message = `Selected nodes: ${JSON.stringify(rowData)}`;
    enqueueSnackbar(message);

  }

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  async function onSaveDataButtonClick() {

    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();

    setButtonsDisabled(true);
    setSubmitButtonDisabled(true);

    let message = 'Submitting requests to OASIS...';
    enqueueSnackbar(message);

    message = 'Request sent to OASIS database...';

    const rowData = [];
    //ref.current.api.forEachNode(node => rowData.push(node.data));

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      //make sure that the prices are always have 2 decimal places. Blank cells go in as 0.00.
      newData.HLHPrice = Number(newData.HLHPrice ?? 0).toFixed(2);
      newData.LLHPrice = Number(newData.LLHPrice ?? 0).toFixed(2);
      rowData.push(newData);
    })

    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkCreateHourAhead?UserGuid=${guid}&caller=PowerStationRealTimeRequest&timezone=${encodeURIComponent(renderTimezone(timezone))}&headerLabelDate=${encodeURIComponent(headerLabelDate)}`;
    const startTime = Date.now();
    const response = await axios.post(
      //`${apiUrlPrefix}/CrystalBall/BulkCreateHourAhead?caller=PowerStationRealTimeRequest&timezone=${encodeURIComponent(renderTimezone(timezone))}&headerLabelDate=${encodeURIComponent(headerLabelDate)}`,
      //`${apiUrlPrefix}/Crystal/BulkCreateHourAhead?caller=PowerStationRealTimeRequest&timezone=${encodeURIComponent(renderTimezone(timezone))}&headerLabelDate=${encodeURIComponent(headerLabelDate)}`,
      //`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_AvailabilityFetch&parm=${encodeURIComponent(timezone)}&parm=${guid}`,
      //`${apiUrlPrefix}/CrystalBall/TSR/BulkCreateHourAhead?UserGuid=${guid}&caller=PowerStationRealTimeRequest&timezone=${encodeURIComponent(renderTimezone(timezone))}&headerLabelDate=${encodeURIComponent(headerLabelDate)}`,
      url,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).catch(err => function () {
      //this block catches errors like you are not connected to the internet... or a 500 error caused by a bad stored proc or sp parameters or API is down
      //erik has this working better in useData which actually catches 500's... this doesn't seem to but could be made to
      enqueueSnackbar(`Status: ${err.response?.status}. Message: ${err}`);
      logAction(`Status: ${err.response?.status}. Message: ${err}`, storageLocation);
    });

    const timeElapsed = Date.now() - startTime;
    logAction('Response received after submit', 'RT Original requests', `Time elapsed: ${timeElapsed}`);

    try { //this tries to do what it is supposed to do but it is possible axios did not catch the error and axios just completed with response being undefined

      if (JSON.stringify(response.data).includes('Crystal Ball API error') || JSON.stringify(response.data).includes('One or more errors occurred') || typeof response?.data === 'string') {

        //this block catches errors returned from Mark... when the API decided it couldn't run, it returns a string that tells the front end what happened
        enqueueSnackbar(JSON.stringify(response.data));
        setButtonsDisabled(false);
        setSubmitButtonDisabled(false);
        ref.current.api.hideOverlay();
        logAction(`Crystal Ball Error using URL: ${url}.  ${response.error}`, storageLocation, JSON.stringify(response.data)); //added data field logging

      } else {
        //this block is what happens if there were no exceptions
        ref.current.api.setRowData(response.data);
        ref.current.api.forEachNode(function (node) {
          //enqueueSnackbar(JSON.stringify(node.data.isSelected));
          node.setSelected(node.data.isSelected);
        })
        message = "Sent to OASIS...";
        logAction(`Sent original requests to OASIS`, storageLocation);
        enqueueSnackbar(message);
        setButtonsDisabled(false);
        setSubmitButtonDisabled(false);
      }
    } catch (error) {
      //this block catches exceptions in the ui... like a react ui error usually because the developer didn't get the url right or something didn't get set by axios
      enqueueSnackbar(`An exception occured: ${error} using URL: ${url}`);
      logAction(`An exception occured: ${error} using URL: ${url}`, storageLocation);
    }


  }

  function addOriginalRequestRow(newRowData) {
    const res = ref.current.api.applyTransaction({
      add: newRowData,
    });
  };

  function handleSaveTemplateClose() {
    setSaveTemplateOpen(false);
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },

  };


  function autoSizeAll(skipHeader) {
    const allColumnIds = [];
    ref.current.columnApi?.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    ref.current.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
  }


  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const statusResult = (params) => {

    var status = "Success";
    if (params.data.Status === "" & params.data.Result !== "") { status = "ERROR" }
    else if (params.data.Status === "Pre-Queued" & params.data.Result !== "") { status = "ERROR" }
    else { status = params.data.Status }

    return status;
  };

  function onRowDataUpdated(params) {
    const rowNum = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowNum === 0);
    autoSizeAll(false);
    //updateLayout();
  }

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    const selectedRowCount = selected.length;
    setSubmitButtonDisabled(selectedRowCount === 0);
    setSelectedRows(selected);
  }

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);


  function checkForHourColumns(key) {
    //key may look like 'H11', try to grab the number from the end
    const hoursToAdd = parseInt(key.replace('H', '')) - 2;

    //alert(hoursToAdd);
    if (isNaN(hoursToAdd)) {
      return key.value;
    }
    else {
      const d = new Date(headerLabelDate); //figure out timezone implications for this
      //let textDate = d.toLocaleString();
      //setHeaderLabelDate(textDate);
      let hour = d.getHours(); //start with current hour
      hour += hoursToAdd; //add hours according to key
      if ((hour % 24) + 1 === 0) {
        hour = 24;
      } else {
        hour = (hour % 24) + 1; //hours wrap around at 24
      }

      return hour.toString();
    }
  };

  function onCellValueChanged(params) {
    setRowValuesWithService(params);
    params.api.refreshCells();
  }

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      tooltipField: "Result",
      field: "Status",
    },
    {
      editable: true,
      headerName: "TP",
      initialHide: false,
      field: "TP",
    },
    {
      editable: true,
      headerName: "Customer Code",
      initialHide: false,
      field: "Customer Code",
    },
    {
      editable: true,
      headerName: "Sale Ref",
      initialHide: false,
      field: "Sale Ref",
    },
    {
      editable: true,
      headerName: "Related Ref",
      initialHide: true,
      field: "Related Ref",
    },
    {
      editable: true,
      headerName: "Deal Ref",
      initialHide: true,
      field: "Deal Ref",
    },
    {
      editable: true,
      headerName: "Posting Ref",
      initialHide: true,
      field: "Posting Ref",
    },
    {
      editable: true,
      headerName: "Request Ref",
      initialHide: true,
      field: "Request Ref",
    },
    {
      editable: true,
      headerName: "Path_Name",
      initialHide: true,
      field: "Path_Name",
    },
    {
      editable: true,
      headerName: "Source",
      field: "Source",
    },
    {
      editable: true,
      headerName: "Sink",
      field: "Sink",
    },
    {
      editable: true,
      headerName: "POR",
      field: "POR",
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
        field: 'por'
      }
    },
    {
      editable: true,
      headerName: "POD",
      field: "POD",
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
        field: 'pod'
      }
    },
    {
      headerName: "Service",
      field: "Service",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: serviceValues,
    },
    {
      editable: true,
      initialHide: true,
      headerName: "Service_Increment",
      field: "Service_Increment",
    },
    {
      editable: true,
      initialHide: true,
      headerName: "TS_Class",
      field: "TS_Class",
    },
    {
      editable: true,
      initialHide: true,
      headerName: "TS_Type",
      field: "TS_Type",
    },
    {
      editable: true,
      headerName: "TS_Period",
      initialHide: true,
      field: "TS_Period",
    },
    {
      editable: true,
      initialHide: true,
      headerName: "TS_Window",
      field: "TS_Window",
    },
    {
      editable: true,
      initialHide: true,
      headerName: "TS_Subclass",
      field: "TS_Subclass",
    },
    {
      editable: true,
      headerName: "Preconfirmed",
      enableRowGroup: true,
      field: "Preconfirmed",
    },
    {
      field: 'scheduleDate',
      headerName: 'Schedule Date',
      editable: true,
      cellEditorPopup: true,
      initialHide: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        timeFormat: 'HH:mm',
        valueFormat: 'MM/DD/YYYY HH:mm',
        allowDelete: true,
      },
    },
    {
      editable: true,
      headerName: "HLH $",
      enableRowGroup: false,
      field: "HLHPrice",
    },
    {
      editable: true,
      headerName: "LLH $",
      enableRowGroup: false,
      field: "LLHPrice",
    },
    ...range(12).map(idx => {
      idx++; //1 based index, no HE0,
      return {
        headerValueGetter: checkForHourColumns(`H${idx}`),
        initialHide: false,
        editable: true,
        headerName: `H${idx}`,
        field: `H${idx}`
      };
    })
  ], [serviceValues]);

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

  const originalRequestSideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          minWidth: 225,
          maxWidth: 225,
          width: 225
        },
        {
          id: 'originalRequestTemplates',
          labelDefault: 'Templates',
          labelKey: 'originalRequestTemplates',
          //iconKey: '',
          toolPanel: 'templateToolPanel',
          toolPanelParams: {
            serviceValues: serviceValues,
            //fieldNames: ['Customer Code', 'Sale Ref', 'Service', 'Preconfirmed', 'name'],
            currentTemplates: templates,
            addTemplate: addTemplate,
            deleteTemplate: deleteTemplate
          },
          minWidth: 180,
          maxWidth: 400,
          width: 200
        },
        layoutPanel
      ],
      position: 'right',
    }
  }, [serviceValues, templates]);

  function onGridReady(params) {
    if (serviceValues.values.length > 0) {
      loadLayout();
    }
  }

  function handleConfirmAction(action) {
    const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ConfirmApplicationAction&parm=${guid}&parm=${selectedRows[0]['New Aref']}&parm=${action}&parm=transnowredirect&parm=`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      if (response.data && response.data[0]) {
        const { goForward, userMessage } = response.data[0];
        setActOnConfirm(!!goForward);
        setConfirmationMessage(userMessage);
        setConfirmationAction(action);
        setOpenConfirmationDialog(true);
      } else {
        enqueueSnackbar('Error- api fetch returned no data.')
      }
    }).catch(error => {
      enqueueSnackbar(`Error confirming TSR. ${error.message}`)
    });
  }

  function handleConfirmation() {
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/${confirmationAction}?userGuid=${guid}&aref=${selectedRows[0]['New Aref']}&primaryProvider=${selectedRows[0].TP ?? ''}`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      enqueueSnackbar(`Success`)
      setOpenConfirmationDialog(false);
    }).catch(error => {
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)

      // enqueueSnackbar(`${error.message}`)
      setOpenConfirmationDialog(false);
    });
  }

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: "agSetColumnFilter",
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  return (
    <div>
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleConfirmation}
        confirmText={actOnConfirm ? 'YES' : 'OK'}
        singleAction={!actOnConfirm}
      />
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveOriginalRequestSelected()}
            endIcon={<DeleteForeverIcon />}
            id="delete"
            disabled={submitButtonDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        {/*<Tooltip title="Add a new blank row to the grid." arrow placement="top">
            <Button 
              endIcon={<AddCircleOutlinedIcon />} 
              id="addOriginalRequestRow" 
              variant={buttonVariant} 
              //disabled={buttonsDisabled}
              color={buttonColor}
              size={buttonSize}
              onClick={() => addOriginalRequestItems()}
            >Add New</Button>
          </Tooltip>&nbsp;*/}
        <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            id="addOriginalRequestRowCopy"
            variant={buttonVariant}
            disabled={submitButtonDisabled}
            color={buttonColor}
            size={buttonSize}
            onClick={addOriginalRequestRowCopy}
          >Add Copy</Button>
        </Tooltip>&nbsp;

        <Tooltip title="Submit all Original Requests without New TSR number and check status on TSRs with New TSR numbers." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            disabled={submitButtonDisabled}
            size={buttonSize}
            onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
        </Tooltip>&nbsp;
        <Button
          endIcon={<CheckIcon />}
          variant={buttonVariant}
          color={buttonColor}
          size={buttonSize}
          disabled={!(selectedRows.length === 1 && selectedRows[0]['New Aref'])}
          onClick={() => handleConfirmAction('confirm')}
        >CONFIRM</Button>&nbsp;
        <Button
          endIcon={<UndoIcon />}
          variant={buttonVariant}
          color={buttonColor}
          size={buttonSize}
          disabled={!(selectedRows.length === 1 && selectedRows[0]['New Aref'])}
          onClick={() => handleConfirmAction('withdraw')}
        >WITHDRAW</Button>
      </Box>
      <AgGridContainer
        style={{
          height: "36vh",
          width: "98%",
        }}
      >
        <AgGridReact
          ref={ref}
          columnDefs={colDefs}
          onCellValueChanged={onCellValueChanged}
          singleClickEdit
          onGridReady={onGridReady}
          enterMovesDownAfterEdit={true}
          overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">No original requests queued... use your template buttons to queue up new requests.  If you have no template buttons, make a new one with the templates tool panel on the right side of this grid.</span>'}
          rowSelection="multiple"
          undoRedoCellEditing={true}
          //enable the next line to left them select the new aref number
          //enableCellTextSelection={true}
          undoRedoCellEditingLimit={20}
          defaultColDef={defaultColDef}
          sideBar={originalRequestSideBar}
          gridOptions={gridOptions}
          tooltipShowDelay={0}
          onRowDataUpdated={onRowDataUpdated}
          onRowSelected={onSelectionChanged}
          suppressRowClickSelection
          enableRangeSelection={true}
          stopEditingWhenCellsLoseFocus
          components={{
            autocompleteEditor: AutocompleteEditor,
            templateToolPanel: TemplateToolPanel,
            dateEditor: DateTimeCellEditor,
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </div>
  );
});

const TemplateToolPanel = ({ serviceValues, currentTemplates, addTemplate, deleteTemplate }) => {
  const [fields, setFields] = useState({});

  function updateField(fieldName, val) {
    const newFields = { ...fields };
    newFields[fieldName] = val;
    setFields(newFields);
  }

  function handleAddTemplateClick() {
    const template = {
      data: fields,
      name: fields['name'],
    };

    addTemplate(template)

  }

  return (
    <div className="template-table">
      <p><TextField
        label={'Customer Code'}
        fullWidth
        size='small'
        variant='outlined'
        onChange={(e) => updateField('Customer Code', e.target.value)}
      /></p>
      <p><TextField
        label={'Sale Ref'}
        fullWidth
        size='small'
        variant='outlined'
        onChange={(e) => updateField('Sale Ref', e.target.value)}
      /></p>
      <p><TextField
        label={'name'}
        fullWidth
        size='small'
        variant='outlined'
        onChange={(e) => updateField('name', e.target.value)}
      /></p>
      <p><Autocomplete
        options={serviceValues.values}
        onChange={(e) => updateField('Service', e.target.innerText)}
        renderInput={(params) => <TextField {...params} label="Service" variant='outlined' fullWidth size='small' />}
      /></p>
      <FormGroup>
        <p><FormControlLabel control={
          <Checkbox
            onChange={(e) => updateField('Preconfirmed', e.target.checked ? 'YES' : 'NO')}
          />} label="Preconfirmed" /></p>
      </FormGroup>
      <Button variant='contained' fullWidth onClick={handleAddTemplateClick}>Save</Button>
      <MenuList>
        {currentTemplates.map(template =>
          <MenuItem>
            <ListItemIcon>
              <DeleteIcon color="primary" fontSize="small" onClick={() => deleteTemplate(template.name)} />
            </ListItemIcon>
            <ListItemText>{template.name}</ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </div>
  );
}