import { Stack, Button, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const SubmissionFooter = ({ handleCancel, formId, formDefaults }) => {
    const { reset } = useFormContext();

    function handleClear() {
        localStorage.removeItem(formId);
        reset(formDefaults);
    }

    return (
        <Stack direction='row' spacing={2} fullWidth sx={{ pt: 1, px: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
                variant='contained'
                onClick={handleClear}
            >
                Clear
            </Button>
            <Button
                variant='contained'
                onClick={handleCancel}
            >
                Close
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant='contained'
                type='submit'
            >
                Submit
            </Button>
        </Stack>
    );
}