import { Grid, TextField, } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array, } from 'yup';
import FormAutocomplete from '../../FormControls/FormAutocomplete';

export default (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, allLocations, } = props;

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    const schema = object().shape({
        label: string().required('Label is required.').max(50, 'Label must be less than 50 characters.'),
        locations: array().min(1, 'Please select at least one location.'),
    });

    const { register, control, handleSubmit, formState: { errors }, reset, } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    function onSubmit(data) {
        handleAddNew(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} id='add-new-forecast-view-form'>
            <Grid container spacing={2} alignItems='center' sx={{ p: 1, }}>
                <Grid item xs={12}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <FormAutocomplete
                        name={'locations'}
                        control={control}
                        fullWidth
                        multiple
                        options={allLocations}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                label="Locations"
                                color="success"
                                error={!!errors.locations}
                                helperText={errors.locations?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </form>
    )
}