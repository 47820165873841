import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Slide, } from "@mui/material"
import { memo, useContext, } from "react";
import { CriteriaContext } from "../CriteriaContext/CriteriaContext";
import { useSnackbar } from "notistack";
import axios from "axios";
import { apiUrlPrefix } from "../../authConfig";
import useHeader from "../useHeader";
import { TsrAlertForm } from "../Forms/TsrAlertForm";

const AlertDialog = ({ open, closeDialog, tsr = {}, }) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { application } = useContext(CriteriaContext);

    const defaultValues = {
        aref: tsr.AssignmentRef,
        alertType: 'Text',
        currentStatus: tsr.Status,
        alertStatus: 'ANY',
        locationOrigin: application,
    }

    function onSubmit(data) {
        const params = {
            userGuid: headers.userGuid,
            action: `createTsrAlert`,
            timeZone: '',
            TargetExecutionTime: '',
            application: application,
            data: { ...data, ...tsr, }, //formatJson(data),
            confirmed: 1,
        }

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${headers.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=${params.confirmed}&parm=${params.application}`

        const options = {
            method: 'POST',
            headers: headers,
            data: params.data,
            url: url,
        }

        axios(options).then(response => {
            enqueueSnackbar('Alert created.')
        }).catch(error => {
            var partsArray = error.response?.data.split('More info follows:');
            enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
        });

        closeDialog();
    }

    return (
        <Dialog open={open} TransitionComponent={Slide} maxWidth={'md'}>
            <DialogTitle>Alert On Status Change</DialogTitle>
            <DialogContent sx={{ p: 1, }}>
                <TsrAlertForm defaultValues={defaultValues} onSubmit={onSubmit} />
            </DialogContent>
            <DialogActions sx={{ p: 1, }}>
                <Button onClick={closeDialog} variant="contained">Cancel</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button type='submit' form='alert-dialog-form' variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default memo(AlertDialog);
