import { Button, Grid, Box, MenuItem, TextField, Autocomplete, FormControl, InputLabel, Select, OutlinedInput } from '@mui/material'
import hours from '../TimedTSRs/Hours'
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useFilteredOptions from '../useFilteredOptions';

export default (props) => {
  const { open, handleCancel, handleAddNew, } = props;
  const [criteria, setCriteria] = useState({});
  const { providers, filteredCombos, customerCodes, filteredTSCombos, requestTypes } = useFilteredOptions(criteria);

  const {
    requestType,
    Provider,
    POR,
    POD,
    customerCode,
    Service_Increment,
    serviceClass,
    startDate,
    stopDate,
    startHour,
    stopHour,
    label,
  } = criteria;

  function handleChange(key, value) {
    setCriteria({
      ...criteria,
      [key]: value
    });
  }

  return (
    <Dialog maxWidth="lg" open={open}>
      <DialogTitle>Add New View</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the desired filter criteria for this view.
        </DialogContentText>
          <Grid container columns={36} spacing={2} alignItems='center' >
            <Grid item xs={36}>
              <Tooltip title="Label for the Stalker tab." arrow placement="top">
                <TextField 
                  value={label}
                  color="primary"
                  label="Label"
                  placeholder='Label'
                  onChange={(e) => handleChange('label', e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={requestTypes}
                value={requestType}
                onChange={(_, newValue) => {
                  handleChange('requestType', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Request Type"
                    color="success" 
                    placeholder="Request Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="Provider"
                freeSolo
                fullWidth
                options={providers}
                value={Provider}
                onInputChange={(e, newValue) => {
                  handleChange('Provider', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Provider"
                    color="success" 
                    placeholder="Provider"
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="POR"
                fullWidth
                freeSolo
                options={filteredCombos.pors}
                value={POR}
                onInputChange={(e, newValue) => {
                  handleChange('POR', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="POR"
                    color="success" 
                    placeholder="POR"
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="POD"
                fullWidth
                freeSolo
                options={filteredCombos.pods}
                value={POD}
                onInputChange={(e, newValue) => {
                  handleChange('POD', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="POD"
                    color="success" 
                    placeholder="POD"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                multiple
                options={customerCodes}
                value={customerCode}
                onChange={(_, newValue) => {
                  handleChange('customerCode', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size='small'
                    label="Customer Code"
                    color="success" 
                    placeholder="Customer Code"
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  fullWidth
                  value={startDate ?? null}
                  onChange={(newValue) => handleChange('startDate', newValue)} 
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={hours.map(hour => `${hour}:00`)}
                value={startHour}
                onChange={(_, newValue) => {
                  handleChange('startHour', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Start Hour"
                    color="success" 
                    placeholder="Start Hour"
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Stop Date"
                  fullWidth
                  value={stopDate ?? null}
                  onChange={(newValue) => handleChange('stopDate', newValue)} 
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                options={hours.map(hour => `${hour}:00`)}
                value={stopHour}
                onChange={(_, newValue) => {
                  handleChange('stopHour', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Stop Hour"
                    color="success" 
                    placeholder="Stop Hour"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                fullWidth
                freeSolo
                options={filteredTSCombos.serviceIncrements}
                value={Service_Increment}
                onInputChange={(_, newValue) => {
                  handleChange('Service_Increment', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Service Increment"
                    color="success" 
                    placeholder="Service Increment"
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Autocomplete
                id="Class"
                fullWidth
                freeSolo
                options={[ 'FIRM', 'NON-FIRM', 'SECONDARY' ]}
                value={serviceClass}
                onInputChange={(_, newValue) => {
                  handleChange('serviceClass', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Class"
                    color="success" 
                    placeholder="Class"
                  />
                )}
              />
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" onClick={() => handleAddNew(criteria)}>Add New</Button>
      </DialogActions>
    </Dialog>
  )
}