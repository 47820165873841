import { useEffect, useRef, useMemo } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { userGroups } from "../../authConfig";
import DivGuard from '../Guards/DivGuard.js';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, } from '../../authConfig';
import { AgGridReact } from 'ag-grid-react';
import { useGridButtons } from '../useGridButtons.js';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels'
import { useData } from '../useData.js';
import { useSnackbar } from 'notistack';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { Stack, useTheme } from '@mui/material';

const TariffPrices = () => {
  const guid = useLocalGuid();
  const gridRef = useRef();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const { RedoButton, UndoButton, ExportButton, CopyRowsButton, RefreshButton, NewRowButton, SaveButton } = useGridButtons({ gridRef });

  const { rowData, getData, saveData } = useData();

  //gridRef.current.api.setRowData([{field: 'Test'}]);

  //const { colDefs, } = useDynamicColDefs(rowData);

  const onRefreshBtn = () => {
    const loadUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TariffPricesFetch&parm=${guid}`;
    gridRef.current.api.showLoadingOverlay();
    getData(loadUrl);
    //gridRef.current.api.setRowData(null);
    gridRef.current.api.hideOverlay();
  };

  const colDefs = [
    {
      editable: false,
      headerName: "ID",
      headerCheckboxSelection: true,
      field: "tariffPriceID",
    },
    {
      editable: true,
      headerName: "Request Type",
      enableRowGroup: true,
      field: "RequestType",
    },
    {
      editable: true,
      headerName: "Provider",
      enableRowGroup: true,
      field: "Provider",
    },
    {
      editable: true,
      headerName: "Service Increment",
      enableRowGroup: true,
      field: "ServiceIncrement",
    },
    {
      editable: true,
      headerName: "TSClasee",
      enableRowGroup: true,
      field: "TSClass",
    },
    {
      editable: true,
      headerName: "TSType",
      enableRowGroup: true,
      field: "TSType",
    },
    {
      editable: true,
      headerName: "TSPeriod",
      enableRowGroup: true,
      field: "TSPeriod",
    },
    {
      editable: true,
      headerName: "TSWindow",
      enableRowGroup: true,
      field: "TSWindow",
    },
    {
      editable: true,
      headerName: "TSSubclass",
      enableRowGroup: true,
      field: "TSSubclass",
    },
    {
      editable: true,
      headerName: "Peak Cost",
      enableRowGroup: true,
      field: "peakPrice",
    },
    {
      editable: true,
      headerName: "Off Peak Cost",
      enableRowGroup: true,
      field: "offPeakPrice",
    },
    {
      editable: true,
      headerName: "OASIS Peak Price",
      enableRowGroup: true,
      field: "oasisPeakPrice",
    },
    {
      editable: true,
      headerName: "OASIS Off Peak Price",
      enableRowGroup: true,
      field: "oasisOffPeakPrice",
    },
  ];


  useEffect(() => {

    const loadUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TariffPricesFetch&parm=${guid}`;

    let abortController = new AbortController();
    // your async action is here  
    getData(loadUrl);
    return () => {
      abortController.abort();
    }

  }, []);

  function autoSizeAll(skipHeader) {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  async function handleSaveButtonClick() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_TariffPricesSave&parm=${guid}`;
    const message = 'Tariff Prices saved to database.';

    gridRef.current.api.stopEditing();
    //enqueueSnackbar(JSON.stringify(rowData));

    saveData(url, message)
  }

  function onRowDataUpdated(e) {
    autoSizeAll(false);
  }

  const sideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
    ],
    position: 'right',
  };

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: true,
    floatingFilter: true,
    filter: "agMultiColumnFilter",
    sortable: true,
    resizable: true,
  }), [])

  return (
    <DivGuard groups={[userGroups.realtimegrids, userGroups.bulkoriginalrequests, userGroups.realtimeoriginals, userGroups.dayaheadgrids, userGroups.spymonkey, userGroups.fastpathpro]} >
      <AgGridContainer
        style={{
          height: "80vh",
          width: "100%",
          padding: theme.spacing(1),
        }}
      >
        <Stack direction="row" spacing={2} sx={{ pb: 1 }}>
          <Tooltip title="Refresh the list of tariff prices." arrow placement="top">
            <RefreshButton onClick={onRefreshBtn} />
          </Tooltip>
          <Tooltip title="Undo the last edit made." arrow placement="top">
            <UndoButton />
          </Tooltip>
          <Tooltip title="Redo the last edit made." arrow placement="top">
            <RedoButton />
          </Tooltip>
          <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
            <CopyRowsButton />
          </Tooltip>
          <Tooltip title="Add a new blank row to the grid." arrow placement="top">
            <NewRowButton />
          </Tooltip>
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <ExportButton />
          </Tooltip>
          <SaveButton onClick={handleSaveButtonClick} />
        </Stack>

        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          onRowDataUpdated={onRowDataUpdated}
          storageLocation='powerstation-tariff-prices-grid-layout'
          suppressRowClickSelection
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enterMovesDownAfterEdit={true}
          enableRangeSelection={true}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          animateRows={true}
        />
      </AgGridContainer>
    </DivGuard>
  );
};

export default function TariffPricesExport() {
  return <TariffPrices />;
}