// import { useEffect, useState, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import WorkflowGrid from './WorkflowGrid';
import WorkflowAuditGrid from './WorkflowAuditGrid';
import ResizingPane from 'react-resizing-pane';
import DivGuard from '../Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import { Box } from '@mui/material';

const WorkflowGrids = () => {
  let storageConfig = { name: 'ResizableWorkflowGrid', type: localStorage };

  return (
    <DivGuard groups={[userGroups.workflow, userGroups.workflowinitiator]} >
      <Box sx={{ height: '89vh', display: 'flex', }}>
        <ResizingPane
          sides={['right']}
          storageId={32}
          storageConfig={storageConfig}
          height="100%"
          width={700}
          style={{
            border: '0px solid blue',
          }}
        >
          <WorkflowGrid />
        </ResizingPane>
        <WorkflowAuditGrid />
      </Box>
    </DivGuard>
  );
};

export default function WorkflowGridsExport() {
  return <WorkflowGrids />;
}