import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { GlobalHotKeys } from "react-hotkeys";
import { userGroups } from "../authConfig";
import GroupGuard from "./Guards/GroupGuard";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 500,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}

export default () => {
    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState();
  
    const getInstance = useCallback((instance) => {
      refAnimationInstance.current = instance;
    }, []);
  
    const nextTickAnimation = useCallback(() => {
      if (refAnimationInstance.current) {
        refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
        refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
      }
    }, []);
  
    const startAnimation = useCallback(() => {
      if (!intervalId) {
        setIntervalId(setInterval(nextTickAnimation, 400));
      }
    }, [intervalId, nextTickAnimation]);
  
    const pauseAnimation = useCallback(() => {
      clearInterval(intervalId);
      setIntervalId(null);
    }, [intervalId]);
  
    const stopAnimation = useCallback(() => {
      clearInterval(intervalId);
      setIntervalId(null);
      refAnimationInstance.current && refAnimationInstance.current.reset();
    }, [intervalId]);

    function toggle() {
        intervalId ? pauseAnimation() : startAnimation();
    }
  
    useEffect(() => {
      return () => {
        clearInterval(intervalId);
      };
    }, [intervalId]);
  
    const keymap = {
        LAUNCH_CONFETTI: {
            sequences: ['command+8', 'shift+8'],
        }
    }
    
    const handlers = {
        LAUNCH_CONFETTI: toggle,
    }

    return <>
      <GroupGuard groups={[userGroups.admins]}>
        <GlobalHotKeys keyMap={keymap} handlers={handlers} allowChanges/>
      </GroupGuard>
      <div style={{zIndex: 999}}>
         <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      </div>
    </>
}