import { Stack, Typography, Button, } from "@mui/material"

export const EdgeActionMenu = (props) => {
    const { selectedEdges, removeSelectedEdges, removeParallelEdges } = props;

    return (
        <Stack direction={'row'} spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography variant='caption' color='text.secondary'>{selectedEdges.length} edges selected</Typography>
            <Button variant='contained' size='small' onClick={removeSelectedEdges}>Remove</Button>
            <Button variant='contained' size='small' onClick={() => removeParallelEdges(selectedEdges)}>Remove Parallel</Button>
        </Stack>
    )
}