import { styled } from '@mui/material/styles';
import { Message } from './Message';
import { Card, TextField, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import SendIcon from '@mui/icons-material/Send';
import { useEffect } from 'react';
import '../../styles/chatStyles.css';
import { UserIsTypingChip } from './UserIsTypingChip';

export default (props) => {
  const { history, handleSendMessage, isTyping } = props;

  const { register, handleSubmit, formState: { errors, }, reset, } = useForm({
    resolver: yupResolver(schema),
  });

  function onSubmit(data) {
    handleSendMessage({
      sender: 'user',
      content: data.message,
      timeStamp: new Date(),
    })

    reset({ message: '', });
  }

  useEffect(() => {
    scrollToBottom();
  }, [history.length]);

  function scrollToBottom() {
    // Scroll to the bottom of the messages container
    const messageContainer = document.getElementById('message-container');
    messageContainer.scrollTop = messageContainer.scrollHeight;
  }

  function handleEnterKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  }

  return (
    <ChatContainer>
      <MessagesContainer id='message-container'>
        {history.map(message =>
          <Message message={message} />
        )}
        {isTyping && <UserIsTypingChip sender='bot' />}
      </MessagesContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', alignItems: 'center', }}>
        <StyledTextField
          {...register('message')}
          multiline
          autoFocus
          maxRows={4}
          placeholder='Type a message...'
          error={!!errors.message}
          helperText={errors.message?.message}
          onKeyDown={handleEnterKeyDown}
        />
        <IconButton type='submit'>
          <SendIcon />
        </IconButton>
      </form>
    </ChatContainer>
  )
}

const schema = object().shape({
  message: string().required('Please enter a message.'),
});

const StyledTextField = styled(TextField)({
  width: '100%', // Full width of the parent container
  margin: '10px 0', // Add some margin at the top and bottom
  '& .MuiOutlinedInput-root': { // Style the input field itself
    borderRadius: '20px', // Rounded corners
  },
});

const MessagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '500px',
  padding: '10px',
  backgroundColor: theme.palette.background.default,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ChatContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.background.default,
}));