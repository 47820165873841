import FlexCollapse from '../FlexCollapse';
import { useMsal } from "@azure/msal-react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { columnPanel, filterPanel, } from "../ToolPanels/DefaultToolPanels"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, InputLabel, accordionClasses, accordionDetailsClasses, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from '@mui/material/styles';
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { apiUrlPrefix, b2cPolicies } from "../../authConfig";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { defaultColumnDef, defaultGridOptions } from "../AgGrid/defaultGridProps";
import { useData } from "../useData";
import useHeader from "../useHeader";
import { useUserInfo } from "../useUserInfo";

const Profile = () => {
    const headers = useHeader();
    const { instance, } = useMsal();
    const { enqueueSnackbar } = useSnackbar();
    const guid = useLocalGuid();
    const dataUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsFetchForUser&parm=${guid}`;
    const { rowData, getData } = useData([]);
    const [userInfo, setUserInfo] = useState({});
    const defaultUserInfo = useUserInfo();
    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        getData(dataUri);
    }, []);

    useEffect(() => {
        setUserInfo(defaultUserInfo);
    }, [defaultUserInfo]);

    const {
        firstName,
        lastName,
        userName,
        phone,
        carrier,
    } = userInfo;

    function handleChange(key, value) {
        setUserInfo({
            ...userInfo,
            [key]: value,
        })
    }

    const name = () => {
        if (firstName && lastName) return `${firstName} ${lastName}`;
        else return '';
    }

    function handleProfileEdit() {
        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_saveUserPhoneCarrier&parm=${guid}&parm=${phone}&parm=${encodeURIComponent(carrier)}`,
        }

        axios(options).then(response => {
            enqueueSnackbar('User info successfully updated.')
        }).catch(error => {
            enqueueSnackbar(`Error fetching user info. ${error.message}`);
        })
    }

    async function handlePasswordReset() {
        await instance.handleRedirectPromise();
        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
    }

    const colDefs = useMemo(() => [
        {
            field: 'supportRequestID',
            headerName: 'ID',
            type: 'numberColumn'
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 3,
            tooltipValueGetter: params => params.value,
        },
        {
            field: 'status',
            headerName: 'Status',
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 2,
            tooltipValueGetter: params => params.value,
        },
        {
            field: 'resolution',
            headerName: 'Resolution',
            flex: 2,
            tooltipValueGetter: params => params.value,
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
        ]
    }), []);

    return (
        <Box sx={{ height: '100%', }} className='flex-column' >
            <Grid container sx={{ padding: "30px" }} spacing={3}>

                <Grid size={1} display='flex' alignItems='center'>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={3}>
                    <TextField fullWidth disabled variant="outlined" size="small" value={name()} />
                </Grid>
                <Grid size={8} />

                <Grid size={1} display='flex' alignItems='center'>
                    <InputLabel>Username</InputLabel>
                </Grid>
                <Grid size={3}>
                    <TextField fullWidth disabled variant="outlined" size="small" value={userName ?? ''} />
                </Grid>
                <Grid size={8} />

                <Grid size={1} display='flex' alignItems='center'>
                    <InputLabel>User GUID</InputLabel>
                </Grid>
                <Grid size={3}>
                    <TextField fullWidth disabled variant="outlined" size="small" value={guid ?? ''} />
                </Grid>
                <Grid size={8} />

                <Grid size={1} display='flex' alignItems='center'>
                    <InputLabel>Phone Number</InputLabel>
                </Grid>
                <Grid size={3}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        onChange={(e) => handleChange('phone', e.target.value)}
                        size="small"
                        value={phone ?? ''}
                    />
                </Grid>
                <Grid size={8} />

                {/*<Grid  size={1}>
          <Autocomplete
            fullWidth
            options={['Verizon', 'AT&T', 'Virgin', 'T-Mobile']}
            onChange={(_, newValue) => handleChange('carrier', newValue)}
            value={carrier ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Carrier"
                color="success"
                placeholder="Carrier"
              />
            )}
          />
        </Grid>*/}

                <Grid size={2}>
                    <Button
                        sx={{ margin: "5px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleProfileEdit}
                    >Save Changes</Button>
                </Grid>
                <Grid size={10} />

                <Grid size={2} sx={{ marginTop: '20px' }} alignItems='center'>
                    <Button
                        sx={{ margin: "5px" }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePasswordReset}
                    >
                        Reset Password
                    </Button>
                </Grid>
                <Grid size={10} />

            </Grid>

            <StyledAccordion
                disableGutters
                slots={{ transition: FlexCollapse }}
                expanded={expanded}
                onChange={handleExpansion}
            >
                <StyledAccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography>User Submitted Support Requests</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0, display: 'flex', flexGrow: 1, }}>
                    <AgGridContainer style={{ flexGrow: 1 }}>
                        <AgGridReact
                            {...defaultGridOptions}
                            rowData={rowData}
                            columnDefs={colDefs}
                            sideBar={sideBar}
                            defaultColDef={defaultColDef}
                            tooltipShowDelay={500}
                            tooltipHideDelay={100000}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </StyledAccordion>

        </Box >
    );
}

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));

const StyledAccordion = styled(Accordion)(({ theme, expanded }) => {
    return expanded
        ? {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            [`& .${accordionClasses.region}`]: {
                display: 'flex',
                height: '100%',
            },
            [`& .${accordionDetailsClasses.root}`]: {
                display: 'flex',
            },
        }
        : {
            [`& .${accordionClasses.region}`]: {
                height: 0,
            },
            [`& .${accordionDetailsClasses.root}`]: {
                display: 'none',
            },
        };
});

export default Profile;
