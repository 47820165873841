import { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import Label from '@mui/icons-material/Label';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const drawerWidth = 300;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 5,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));


StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

function BidmanAppMenu(props) {
  const { handleSelection, selected, treeData, date, handleDateChange } = props;

  const storageKey = 'bidman-menu-open';
  const defaultOpen = JSON.parse(localStorage.getItem(storageKey) ?? 'true');
  const [open, setOpen] = useState(defaultOpen);
  const [timezone, setTimezone] = useState();

  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem(storageKey, !open);
  };

  let icon;
  if (open) {
    icon = <ChevronLeftIcon />;
  } else {
    icon = <ChevronRightIcon />;
  }

  const handleDateAccepted = useCallback(debounce((newDateValue) => {
    //Check that newDateValue is correctly formatted. This prevents errors when a user manually types the date, 
    //which causes the onChange event to fire with a partial date value. - ecz 7/11/22
    const year = dayjs(newDateValue).year();
    if(year > 1900) {
      handleDateChange(newDateValue);
    }
  }, 500), []);

  const variant = "contained";
  const color = "primary";
  const size = "small";

  function handleTreeClick(node) {
    setTimezone(node.timezone);
    handleSelection(node.gridName);
  };

  const renderTree = (node) => (
    <StyledTreeItem 
      nodeId={node.nodeId} 
      selected={selected === node.gridName}
      onClick={() => handleTreeClick(node)}
      color="#1a73e8" 
      bgColor="#e8f0fe" 
      labelInfo={node.info}
      labelText={node.label}
      labelIcon={Label}
    >
      {Array.isArray(node.children)
        ? node.children.map(renderTree)
        : null}
    </StyledTreeItem>
  );

  function renderTreeComponents () {
    return (
      <Box sx={{ p: 1 }}>
        <Box sx={{ p: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              value={dayjs(date)}
              //onAccept={handleDateAccepted}
              onChange={handleDateAccepted}
              autoOk={true}
              renderInput={(params) => <TextField color={color} size={size} varient={variant} {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <TreeView
          aria-label="gmail"
          defaultExpanded={[0]}
          //expanded 
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
          sx={{ 
            flexGrow: 1, 
            overflowY: 'auto' }}
        >
            {treeData.map(rootNode => renderTree(rootNode))} 
        </TreeView>
      
      </Box>
      );
  }

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >{timezone}&nbsp;&nbsp;&nbsp;
        <IconButton onClick={toggleDrawer} size="large">
          {icon}
        </IconButton>
      </Toolbar>

        {
          open ?
            renderTreeComponents()
          :
            null
        }
      
    </Drawer>
  );
}

export default memo(BidmanAppMenu);