// import { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import AutoModeIcon from '@mui/icons-material/AutoMode';

const buttonVariant = "contained";
const buttonColor = "primary";
const buttonSize = "small";

export const useGridButtons = ({gridRef}) => {

    function undo() {
      gridRef.current.api.undoCellEditing();
    };

    function redo() {
      gridRef.current.api.redoCellEditing();
    };

    function onCopyRows() {
      gridRef.current.api.copySelectedRowsToClipboard();
    };

    function autoSizeAll() {
      gridRef.current.columnApi.autoSizeAllColumns();;
    };

    function addBlankRow() {
        const res = gridRef.current.api.applyTransaction({
          add: [{}],
        });
    };

    function onExport() {
      gridRef.current.api.exportDataAsCsv();
    };
    
    function onRemoveSelected() {
      const selectedData = gridRef.current.api.getSelectedRows();
      const res = gridRef.current.api.applyTransaction({ remove: selectedData });
    };

    const onCopyRange = () => {
      gridRef.current.api.copySelectedRangeToClipboard();
    };

    const CopyRangeButton = (props) => {
      return(
        <Button
        id='copyrange'
          variant={buttonVariant} 
          color={buttonColor}
          size={buttonSize}
          onClick={onCopyRange}
          {...props}
        >Copy Range</Button>
      );
    };

    const RefreshButton = (props) => {
        return(
            <Button 
            endIcon={<RefreshOutlinedIcon />} 
            id="refresh" 
            size={buttonSize}
            variant={buttonVariant} 
            color={buttonColor}
            {...props}
          >Refresh</Button>
        );
    };

    const UndoButton = (props) => {
        return(
            <Button 
              endIcon={<UndoOutlinedIcon />} 
              id="undoBtn" 
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
              onClick={undo}
              {...props}
            >Ctrl-z</Button>
        );
    };

    const RedoButton = (props) => {
        return(
            <Button 
              endIcon={<RedoOutlinedIcon />} 
              id="redoBtn" 
              className="redo-btn"
              size={buttonSize}
              variant={buttonVariant} 
              color={buttonColor}
              onClick={redo}
              {...props}
            >Ctrl-y</Button>
        );
    };

    const CopyRowsButton = (props) => {
        return(
            <Button 
              onClick={onCopyRows}
              endIcon={<ContentCopyOutlinedIcon />} 
              id="copyBtn" 
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
              {...props}
            >Ctrl-c</Button>
        );
    };

    const NewRowButton = (props) => {
        return (
            <Button 
              endIcon={<AddCircleOutlinedIcon />} 
              id="addItems" 
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
              onClick={addBlankRow}
              {...props}
            >Add New</Button>
        );
    };

    const ExportButton = (props) => {
        return(
            <Button 
              onClick={onExport}
              endIcon={<DownloadForOfflineOutlinedIcon />} 
              id="csv" 
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
              {...props}
            >CSV</Button>
        );
    }

    const SaveButton = (props) => {
        return(
            <Button
              endIcon={<PublishIcon/>}
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
              {...props}
            >Save All</Button>
        );
    }

    const AutoSizeButton = (props) => {
      return(
          <Button
            endIcon={<AutoModeIcon/>}
            variant={buttonVariant} 
            color={buttonColor}
            size={buttonSize}
            onClick={autoSizeAll}
            {...props}
          >Autosize All</Button>
      );
  }

    const RemoveSelectedButton = (props) => {
      return(
        <Button 
          onClick={onRemoveSelected}
          endIcon={<DeleteIcon/>} 
          id="delete" 
          variant={buttonVariant} 
          color={buttonColor}
          size={buttonSize}
          {...props}
        >Remove</Button>
      );
    }

    return {
        RefreshButton,
        UndoButton,
        RedoButton,
        CopyRowsButton,
        NewRowButton,
        ExportButton,
        SaveButton,
        RemoveSelectedButton,
        CopyRangeButton,
        AutoSizeButton,
    };
}