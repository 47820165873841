import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import { useApi } from "../useApi";

const schema = yup.object().shape({
    lookupField: yup.string().required('Lookup Field is required'),
    value: yup.string().required('Value is required'),
});

export const AddLookupValueDialog = ({ open, onClose, onAdd }) => {
    const [fields, setFields] = useState([]);
    const { get, enqueueSnackbar, logAction, apiUrlPrefix, headers } = useApi();
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm({
        resolver: yupResolver(schema),
    });

    const fetchFields = useCallback(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchLookupFields&parm=${headers.userGuid}`

        get(url).then((response) => {
            if (response.status === 200) {
                setFields(response.data.map((field) => field.lookupField));
            }
        });
    }, [get, apiUrlPrefix, headers.userGuid]);

    useEffect(() => {
        fetchFields();
    }, [fetchFields]);

    const onSubmit = (data) => {
        onAdd(data);
        reset();
        onClose();
    }

    return (
        <Dialog open={!!open} maxWidth={'sm'} fullWidth>
            <DialogTitle id="add-lookup-value-dialog-title">
                Add Lookup Value
            </DialogTitle>
            <DialogContent id="add-lookup-value-dialog-description">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <FormAutocomplete
                        options={fields}
                        control={control}
                        name='lookupField'
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                margin="dense"
                                label="Lookup Field"
                                fullWidth
                                error={!!errors.lookupField}
                                helperText={errors.lookupField?.message}
                            />

                        )}
                    />

                    <TextField
                        {...register('value')}
                        margin="dense"
                        label="Value"
                        fullWidth
                        error={!!errors.value}
                        helperText={errors.value?.message}
                    />

                    <TextField
                        {...register('notes')}
                        margin="dense"
                        label="Notes"
                        fullWidth
                        multiline
                        maxRows={5}
                    />

                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Button color="inherit" size="small" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="inherit" size="small" type="submit">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}
