import { forwardRef, useImperativeHandle, useRef, } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, StaticDateTimePicker, DigitalClock, } from "@mui/x-date-pickers";
import dayjs from '../dayjs-tz';

export const DateTimeCellEditor = forwardRef((props, ref) => {
    const { formatString = 'MM/DD/YYYY HH:mm', } = props;
    const value = useRef(dayjs(props.value));

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value.current.format(formatString);
            },
        };
    });

    const handleValueChange = (newValue) => {
        value.current = newValue;
        props.stopEditing();
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
                value={value.current}
                onChange={handleValueChange}
                ampm={false}
            />
        </LocalizationProvider>
    );
});