import { forwardRef } from 'react';
import { Stack, Button, } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import minMax from 'dayjs/plugin/minMax';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { isInWindow, totalPrice, } from './UtilFunctions';

export const ProfileDataToolbar = forwardRef((props, ref) => {
    const { workspaceData, setWorkspaceData, startDateTime, endDateTime, tsrKey, targetProfile } = props;
    const { enqueueSnackbar, } = useSnackbar();
    dayjs.extend(minMax);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    const defaultButtonProps = {
        variant: 'contained',
        size: 'small',
    };

    function zeroSelectedRows(e) {
        e.stopPropagation();
        const selectedNodes = ref.current.api.getSelectedNodes();
        if (!selectedNodes.length) {
            enqueueSnackbar('No rows selected.', { variant: 'info' });
            return;
        }

        const newData = [...workspaceData];
        selectedNodes.forEach(selected => {
            const row = newData.find(row => tsrKey(row) === selected.id);
            Object.keys(row.ProfileInfo).forEach(key => {
                row.ProfileInfo[key].Q = 0;
            });
        })
        setWorkspaceData(newData);
    }

    function totalWindowPrice(row) {
        //truncate the OfferPrice and Capacity data to the target window
        const windowCapacity = JSON.parse(row.Capacity).filter(block => isInWindow(block, startDateTime, endDateTime));
        const windowPrice = JSON.parse(row.OfferPrice).filter(block => isInWindow(block, startDateTime, endDateTime));
        return totalPrice(windowPrice, windowCapacity);
    }

    function maxProfileForSelectedRows(e) {
        e.stopPropagation();

        const selectedNodes = ref.current.api.getSelectedNodes();
        if (!selectedNodes.length) {
            enqueueSnackbar('No rows selected.', { variant: 'info' });
            return;
        }

        //sort the rows by total price so we can use the cheapest first
        const sortedSelections = [...selectedNodes.map(node => node.data)].sort((a, b) => totalWindowPrice(a) - totalWindowPrice(b));

        //group rows according to POR and POD; we want to maximize the profile for each unique POR/POD pair
        const groupedSelections = sortedSelections.reduce((acc, row) => {
            const key = `${row.PointOfReceipt}-${row.PointOfDelivery}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(row);
            return acc;
        }, {});

        maximizeRowGroupProfiles(groupedSelections);
    }

    function maximizeAllProfiles(e) {
        e.stopPropagation();
        //sort workspace data
        const sortedData = [...workspaceData].sort((a, b) => totalWindowPrice(a) - totalWindowPrice(b));

        const groupedSelections = sortedData.reduce((acc, row) => {
            const key = `${row.PointOfReceipt}-${row.PointOfDelivery}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(row);
            return acc;
        }, {});

        maximizeRowGroupProfiles(groupedSelections);
    }

    function maximizeRowGroupProfiles(groups) {
        const newData = [...workspaceData];
        Object.keys(groups).forEach(key => {
            const rowGroup = groups[key];
            const totalProfile = {};
            rowGroup.forEach(selected => {
                const row = newData.find(row => tsrKey(row) === tsrKey(selected));
                const rowProfile = maxProfileForRow(row, totalProfile);
                //update the total profile with the row profile
                Object.keys(rowProfile).forEach(key => {
                    if (!totalProfile[key]) {
                        totalProfile[key] = 0;
                    }
                    totalProfile[key] += rowProfile[key].Q;
                });
                row.ProfileInfo = rowProfile;
            })
        });

        setWorkspaceData(newData);
    }

    function maxProfileForRow(row, runningTotalProfile) {
        let start = dayjs(startDateTime);
        const end = dayjs(endDateTime);
        const totalCapacity = JSON.parse(row.Capacity);

        //get blocks in the start/end time frame and truncate the ends to fit the window
        const blocksInWindow = totalCapacity.filter(block => {
            const blockStart = dayjs(block.OfferStartTimeUtc);
            const blockEnd = dayjs(block.OfferStopTimeUtc);
            return blockStart.isSameOrBefore(end) && blockEnd.isSameOrAfter(start);
        }).map(block => ({
            start: dayjs.max(dayjs(block.OfferStartTimeUtc), start),
            end: dayjs.min(dayjs(block.OfferStopTimeUtc), end),
            capacity: block.Capacity,
        }));

        //now split the blocks into hourly blocks
        const hourlyBlocks = [];
        blocksInWindow.forEach(block => {
            const blockEnd = dayjs(block.end).startOf('hour');
            let next = dayjs(block.start).startOf('hour');
            while (blockEnd.isAfter(next, 'hour')) {
                hourlyBlocks.push({
                    key: next.add(1, 'hour'),
                    capacity: block.capacity,
                });
                next = next.add(1, 'hour');
            }
        });

        //now iterate over the hourly block data and take the minimum capacity between the available and the target
        const newProfile = {};
        hourlyBlocks.forEach(block => {
            const blockEnd = block.key.format('MM/DD/YY HH:mm');
            const targetBlock = targetProfile.find(targetBlock => dayjs(targetBlock.endDateTime).isSameOrAfter(blockEnd));
            const maxAddable = targetBlock.capacityRequested - (runningTotalProfile[blockEnd] ?? 0);
            newProfile[blockEnd] = {
                ...row.ProfileInfo[blockEnd],
                Q: Math.min(Math.min(block.capacity, targetBlock.capacityRequested), maxAddable),
            };
        });

        return newProfile;
    }

    return (
        <Stack direction="row" spacing={2} sx={{ paddingBottom: '4px', }}>
            <Button
                {...defaultButtonProps}
                onClick={zeroSelectedRows}
            >Zero</Button>
            <Button
                {...defaultButtonProps}
                onClick={maxProfileForSelectedRows}
            >Max</Button>
            <Button
                {...defaultButtonProps}
                onClick={maximizeAllProfiles}
            >Max All</Button>
        </Stack>
    );
});