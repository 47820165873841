import React from "react";
import { GoogleMap, useJsApiLoader, LoadScript, Polyline, Marker, InfoBox } from '@react-google-maps/api';

//import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { PinDropSharp } from "@mui/icons-material";
import RoomIcon from '@mui/icons-material/Room';
import { apiUrlPrefix } from "../authConfig";
//import { useHistory } from "react-router";
import useHeader from "./useHeader";

//const ScriptLoaded = require("../../docs/ScriptLoaded").default;


const onLoad = polyline => {
  console.log('polyline: ', polyline)
};

const path = [
  { lat: 37.772, lng: -122.214 },
  { lat: 21.291, lng: -157.821 },
  { lat: -18.142, lng: 178.431 },
  { lat: -27.467, lng: 153.027 }
];

const options = {
  strokeColor: '#A9A9A9',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  paths: [
    { lat: 37.772, lng: -122.214 },
    { lat: 21.291, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 }
  ],
  zIndex: 1
};

const porOptions = {
  fillColor: '#00FF00',
  color: '#00FF00',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

const podOptions = {
  strokeColor: 'red',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: 'red',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

const moment = require('moment-timezone');

const TSRCard = ({ text }) => <Card sx={{ minWidth: 100 }}>
  <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      {text}
    </Typography>
  </CardContent>
</Card>;

const OfferingPin = ({ text }) =>
  <RoomIcon color="primary">{text}</RoomIcon>;

const AnyReactComponent = ({ text }) => <Card sx={{ minWidth: 275 }}>
  <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      crystalball
    </Typography>
    <Typography sx={{ mb: 1.5 }} color="text.secondary">
      Central API hub for Power Station
    </Typography>
    <Typography variant="body2">
      Located near Seattle WA - Hosted in Microsoft Azure
    </Typography>
  </CardContent>
  <CardActions>
    <Button href="https://crystalballsandbox.developer.azure-api.net/" size="small">Learn More</Button>
  </CardActions>
</Card>;


// const handleApiLoaded = (map, maps) => {
//   // use map and maps objects
//   let marker = new maps.Marker({
//     position: myLatLng,
//     map,
//     title: 'Hello World!'
//   });
// };



const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 39.0997,
  lng: -94.578331
};

function MyComponent() {

  //let history = useHistory();

  //const handleLearnMoreClick = () => {
  //history.push("https://crystalballsandbox.developer.azure-api.net/");
  //};

  //const [tsrs, setTsrs] = useState(null);
  //const [substations, setSubstations] = useState(null);

  const [servicePoints, setServicePoints] = useState(null);
  const [time, setTime] = useState(Date.now());
  const [seconds, setSeconds] = useState(0);
  const header = useHeader();

  useEffect(() => {


    // we will use async/await to fetch this data
    async function getData() {
      const response = await fetch(`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=OASISTx.fetchSubstations`,
        {
          headers: header,
        });
      const data = await response.json();
      setServicePoints(data);
    }

    let abortController = new AbortController();
    getData();
    return () => {
      abortController.abort();
    }

  }, []); // <- you may need to put the setBooks function in this array

  // const servicePointArray = servicePoints.map(item => {
  //   const positions = {};

  //   positions.lat = item.porLatitude;
  //   positions.lng = item.porLongitude;

  //   return positions;
  // })


  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "yZH8UpCnXDSvJ3uC39YZCzeoR4"
  // })

  // const [map, setMap] = React.useState(null)

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const centers = [{
    lat: 53.0,
    lng: -136.0
  },
  {
    lat: 37.672,
    lng: -122.219
  },
  {
    lat: 53,
    lng: -136
  }];

  return (

    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
      <LoadScript googleMapsApiKey="AIzaSyCL7pD9-yZH8UpCnXDSvJ3uC39YZCzeoR4">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5}
        >
          {/* {servicePoints && (
                  servicePoints.map((servicePoint, index) => (
                    <Marker 
                      icon={PinDropSharp}
                      //lat={servicePoint.porLatitude}
                      //lng={servicePoint.porLongitude} 
                      position={{ lat: servicePoint.porLatitude, lng: servicePoint.porLongitude }}
                      label={servicePoint.por} 
                      key={index} 
                      options={porOptions}
                    />
                    ))
                )}  */}

          {/* {servicePoints && (
                  servicePoints.map((servicePoint, index) => (
                    <Marker 
                      icon={PinDropSharp}
                      //lat={servicePoint.porLatitude}
                      //lng={servicePoint.porLongitude} 
                      position={{ lat: servicePoint.podLatitude, lng: servicePoint.podLongitude }}
                      label={servicePoint.pod} 
                      key={index}
                      options={podOptions} 
                    />
                    ))
                )}  */}

          {servicePoints && (
            servicePoints.map((servicePoint, index) => (
              <Marker
                icon={PinDropSharp}
                //lat={servicePoint.porLatitude}
                //lng={servicePoint.porLongitude} 
                position={{ lat: servicePoint.latitude, lng: servicePoint.longitude }}
                //label={servicePoint.name} 
                //title={servicePoint.name} 
                key={index}
                options={podOptions}
              />
            ))
          )}

          {/*  Use this to map lines like transmission line */}
          {/* {servicePoints && (
                  servicePoints.map((servicePoint, index) => (
                    <Polyline 
                      icon={PinDropSharp}
                      onLoad={onLoad}
                      options={options} 
                      path={[{ lat: servicePoint.porLatitude, lng: servicePoint.porLongitude },{ lat: servicePoint.podLatitude, lng: servicePoint.podLongitude }]}
                      //path={path}
                      key={index} 
                    />
                    ))
                )}  */}


          <InfoBox
            //onLoad={onLoad}
            //options={options}
            position={centers[1]}
          >
            <AnyReactComponent
              text="My Marker"
            />
          </InfoBox>

          <></>
        </GoogleMap>
      </LoadScript>

    </div>
  );
}

export default React.memo(MyComponent);