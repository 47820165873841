import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useRef, useState } from 'react';
import CornerRibbon from 'react-corner-ribbon';
import { useNavigate } from "react-router";
import { userGroups } from '../../authConfig.js';
import GroupGuard from '../Guards/GroupGuard.js';
import { Drawer } from '../StyledDrawer.js';

const Menu = ({ selected }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const timerRef = useRef(null);

    function handleSelection(view) {
        navigate(`/transhunter/${view}`);
    }

    const handleMouseEnter = () => {
        timerRef.current = setTimeout(() => {
            setOpen(true);
        }, 200);
    };

    const handleMouseLeave = () => {
        clearTimeout(timerRef.current); // Clear the timer if the mouse leaves
        setOpen(false);
    };


    return (
        <Drawer
            variant="permanent"
            open={open}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <List>
                <GroupGuard authenticatedOnly>
                    <Tooltip title="View information about TransHunter." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'transhunterhelp'}
                            onClick={() => handleSelection('transhunterhelp')}>
                            <ListItemIcon>
                                <QuestionMarkIcon />
                            </ListItemIcon>
                            <ListItemText primary="TransHunter Help" />
                        </ListItemButton>
                    </Tooltip>
                    <Divider />
                </GroupGuard>
                <Tooltip title={`${open ? '' : 'Availability Alerts'}`} arrow placement="right">
                    <ListItemButton
                        selected={selected === 'availabilityalerts'}
                        onClick={() => handleSelection('availabilityalerts')}>
                        <ListItemIcon>
                            <AddAlarmIcon />
                        </ListItemIcon>
                        <ListItemText primary="Availability Alerts" />
                    </ListItemButton>
                </Tooltip>
                <GroupGuard groups={[userGroups.admins]}>
                    <div style={{ position: 'relative' }}>
                        {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
                        <Tooltip title={`${open ? '' : 'Long Term Availability'}`} arrow placement="right">
                            <ListItemButton
                                selected={selected === 'longtermavailability'}
                                onClick={() => handleSelection('longtermavailability')}>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Long Term Availability" />
                            </ListItemButton>
                        </Tooltip>
                    </div>
                </GroupGuard><GroupGuard groups={[userGroups.admins]}>
                    <div style={{ position: 'relative' }}>
                        {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
                        <Tooltip title={`${open ? '' : 'All Daily Segments'}`} arrow placement="right">
                            <ListItemButton
                                selected={selected === 'alldailysegments'}
                                onClick={() => handleSelection('alldailysegments')}>
                                <ListItemIcon>
                                    <DateRangeIcon />
                                </ListItemIcon>
                                <ListItemText primary="All Daily Segments" />
                            </ListItemButton>
                        </Tooltip>
                    </div>
                </GroupGuard>
                <GroupGuard groups={[userGroups.admins]}>
                    <div style={{ position: 'relative' }}>
                        {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
                        <Tooltip title={`${open ? '' : 'Flowgates'}`} arrow placement="right">
                            <ListItemButton
                                selected={selected === 'flowgates'}
                                onClick={() => handleSelection('flowgates')}>
                                <ListItemIcon>
                                    <ForkLeftIcon />
                                </ListItemIcon>
                                <ListItemText primary="Flowgates" />
                            </ListItemButton>
                        </Tooltip>
                    </div>
                </GroupGuard>
                <GroupGuard groups={[userGroups.admins]}>
                    <div style={{ position: 'relative' }}>
                        {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
                        <Tooltip title={`${open ? '' : 'Trans Offerings'}`} arrow placement="right">
                            <ListItemButton
                                selected={selected === 'transofferings'}
                                onClick={() => handleSelection('transofferings')}>
                                <ListItemIcon>
                                    <LocalOfferIcon />
                                </ListItemIcon>
                                <ListItemText primary="Trans Offerings" />
                            </ListItemButton>
                        </Tooltip>
                    </div>
                </GroupGuard>
                <GroupGuard groups={[userGroups.admins]}>
                    <div style={{ position: 'relative' }}>
                        {open && <CornerRibbon style={{ fontSize: 7 }}>ADMIN</CornerRibbon>}
                        <Tooltip title={`${open ? '' : 'Summary Statistics'}`} arrow placement="right">
                            <ListItemButton
                                selected={selected === 'summarystatistics'}
                                onClick={() => handleSelection('summarystatistics')}>
                                <ListItemIcon>
                                    <SummarizeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Summary Statistics" />
                            </ListItemButton>
                        </Tooltip>
                    </div>
                </GroupGuard>
                <Tooltip title={`${open ? '' : 'Outages'}`} arrow placement="right">
                    <ListItemButton
                        selected={selected === 'outages'}
                        onClick={() => handleSelection('outages')}>
                        <ListItemIcon>
                            <AppsOutageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Outages" />
                    </ListItemButton>
                </Tooltip>
                <Divider />
                {/*<GroupGuard groups={[userGroups.pathmonitor]}>
          <Tooltip title="Get alerts on activity on paths." arrow placement="right">
            <ListItemButton 
              selected={selected === 'pathmonitor'}
              onClick={() => handleSelection('pathmonitor')}>
              <ListItemIcon>
                <TrackChangesIcon />
              </ListItemIcon>
              <ListItemText primary="Path Monitor" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>*/}
                <GroupGuard authenticatedOnly>
                    <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'tsractivity'}
                            onClick={() => handleSelection('tsractivity')}>
                            <ListItemIcon>
                                <LocalActivityIcon />
                            </ListItemIcon>
                            <ListItemText primary="TSR Activity" />
                        </ListItemButton>
                    </Tooltip>
                </GroupGuard>
                <GroupGuard groups={[userGroups.transhunter]}>
                    <Tooltip title="View and edit your transaction alerts." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'transactionqueue'}
                            onClick={() => handleSelection('transactionqueue')}>
                            <ListItemIcon>
                                <TrackChangesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Trans Q" />
                        </ListItemButton>
                    </Tooltip>
                    <Divider />
                </GroupGuard>
            </List>
            <List>
                <GroupGuard groups={[userGroups.transhunter]}>
                    <Tooltip title="See the audit log." arrow placement="right">
                        <ListItemButton
                            selected={selected === 'audit'}
                            onClick={() => handleSelection('audit')}>
                            <ListItemIcon>
                                <ReceiptLongIcon />
                            </ListItemIcon>
                            <ListItemText primary="Audit Log" />
                        </ListItemButton>
                    </Tooltip>
                </GroupGuard>
            </List>
        </Drawer>
    );
}

export default Menu;
