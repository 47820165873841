import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { Drawer } from './StyledDrawer'
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import axios from 'axios';
import { apiUrlPrefix, } from '../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import useHeader from './useHeader';
import { useActionAudit } from './useActionAudit';

export default ({ appName }) => {
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('Getting Started');
  const [openTableOfContents, setOpenTableOfContents] = useState(true);
  const header = useHeader();
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();

  useEffect(() => {
    loadTopics();
  }, []);

  const selectedTopicHtml = topics.find(top => top.name === selectedTopic)?.html;

  async function loadTopics() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_HelpTopicFetch&parm=${guid}&parm=${appName}`

    let options = {
      headers: header,
      method: 'GET',
      url: url,
    };

    const tops = await axios(options).catch(err => { enqueueSnackbar(`Error loading ${appName} help topics.  URL: ${url} Error:  ${err}`) });

    setTopics(tops?.data ?? []);
  };

  function handleTopicClick(topicName) {
    setSelectedTopic(topicName);
    logAction(`User is viewing the ${appName} help topic "${topicName}"`, `${appName} Help`, topicName);
  }

  return (
    <div style={{ width: "98%" }}>
      <Box sx={{ display: 'flex' }}>
        <Drawer variant="permanent" open={openTableOfContents}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            {openTableOfContents ? 'Table of Contents' : ''}&nbsp;&nbsp;
            <IconButton onClick={() => setOpenTableOfContents(!openTableOfContents)} size="large">
              {openTableOfContents ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            {topics.map(top =>
              <ListItemButton
                selected={selectedTopic === top.name}
                onClick={() => handleTopicClick(top.name)}
              >
                <ListItemText
                  primary={openTableOfContents ? top.sequence + '.0 - ' + top.name : top.sequence + '.0'}>
                </ListItemText>
              </ListItemButton>
            )}
          </List>
        </Drawer>
        <Box
          sx={{
            p: '7px',
            flexGrow: 1,
          }}
        >
          {selectedTopicHtml &&
            <div dangerouslySetInnerHTML={{ __html: selectedTopicHtml }} />
          }
        </Box>
      </Box>
    </div>
  );
};