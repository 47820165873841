import { useEffect, useState, useRef, forwardRef, useMemo } from 'react';
import { TemplateToolPanel } from '../ToolPanels/TemplateToolPanel';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/dayAheadStyles.css'
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
import { useSnackbar } from 'notistack';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import SaveTemplateDialog from './SaveTemplateDialog';
import { ProfilePicker } from '../profiles/ProfilePicker';
import { ProfileToolPanel } from '../ToolPanels/ProfileToolPanel';
// import { ServiceToolPanel } from '../ToolPanels/ServiceToolPanel';
import { TextField, Tooltip, Box, Button, } from '@mui/material';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import axios from 'axios';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { serviceRowValues } from '../../utils/RedirectServiceValues';
import { Profiles } from './Profiles';
import { useProfilePanel } from '../ToolPanels/useProfilePanel';
import { AutocompleteEditor } from '../AutocompleteEditor';
import { useLocalData } from '../useLocalData';
import useHeader from '../useHeader';
import useGridLayout from '../useGridLayout';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';

export default forwardRef((props, ref) => {
  const { addTemplate, templates, deleteTemplate, timezone } = props;
  const [templateData, setTemplateData] = useState([]);
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const [porChoices, setPorChoices] = useState([]);
  const [podChoices, setPodChoices] = useState([]);

  const transmissionTemplateTypeId = 2;

  const guid = useLocalGuid();
  const [updateCurrentRow, profilePanel, setRowFromProfile] = useProfilePanel(ref);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  const [redirectProfile, setRedirectProfile] = useState({});
  const [redirectService, serRedirectService] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();

  //const [redirectRowData, setRedirectRowData] = useState([]);

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const storageLocation = 'day-ahead-redirect-layout';
  useLocalData(storageLocation, ref);

  const addRedirectItems = (addIndex) => {
    const newItems = [
      createNewRedirectRowData(),
    ];
    addRedirectRow(newItems);
  };

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  async function onSaveDataButtonClick() {

    setButtonsDisabled(true);
    setSubmitButtonDisabled(true);

    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();

    let message = 'Submitting redirect to OASIS...';
    enqueueSnackbar(message);

    const rowData = [];
    //ref.current.api.forEachNode(node => rowData.push(node.data));

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      rowData.push(newData);
    })

    message = 'Redirect sent to OASIS database...';

    ////https://crystalballsandbox-development.azurewebsites.net/CrystalBall/TSR/BulkRedirectDayAhead?userGuid=07a87c1f-5533-43a0-86ba-c9c9a5aff141&timeZone=US/Pacific&caller=MarkTest

    const response = await axios.post(
      `${apiUrlPrefix}/CrystalBall/TSR/BulkRedirectDayAhead?UserGuid=${guid}&timeZone=${renderTimezone(timezone)}&Caller=PowerStation.DayAhead`,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).catch(err => function () { message = `Status: ${err.response?.status}. Message: ${err}` });

    //setRedirectRowData(response.data);
    ref.current.api.setRowData(response.data);

    //if (ref.current.api && reassignmentRowData.length > 0) {
    ref.current.api.forEachNode(function (node) {
      //enqueueSnackbar(JSON.stringify(node.data.isSelected));
      node.setSelected(node.data.isSelected);
    })
    //}

    ref.current.api.hideOverlay();

    enqueueSnackbar(message);
    setButtonsDisabled(false);

  }

  function addRedirectRow(newRowData) {
    const res = ref.current.api.applyTransaction({
      add: newRowData,
    });
  };

  const addRedirectRowCopy = (addIndex) => {
    ref.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = ref.current.api.getSelectedRows();
    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      newRow['New Aref'] = "";
      newRow['Status'] = "";
      newRow['Result'] = "";
      return newRow;
    })
    addRedirectRow(data);
  };

  const onRemoveRedirectSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
  };

  function handleSaveTemplateClose() {
    setSaveTemplateOpen(false);
  };

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    ref.current.api.forEachNode(node => rowData.push(node.data));
    let message = 'JSON for entire grid generated.';
    alert(`Selected nodes: ${JSON.stringify(rowData)}`);
    message = `Selected nodes: ${JSON.stringify(rowData)}`;
    enqueueSnackbar(message);
  }

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },

  };

  function onRowDataUpdated(params) {
    const rowNum = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowNum === 0);

  }

  function onSelectionChanged(params) {

    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);

  }

  let newCount = 1;
  function createNewRedirectRowData() {
    const newData = {

    };
    newCount++;
    return newData;
  }

  const serviceValues = { values: Object.keys(serviceRowValues), };

  function setServiceValues(params) {
    const data = params.data;
    if (data.Service !== '') {

      const rowValues = serviceRowValues[params.newValue];
      const fields = Object.keys(rowValues);
      fields.forEach(field =>
        data[field] = rowValues[field]
      );

      // return true to tell grid that the value has changed, so it knows
      // to update the cell
      return true;
    } else {
      // return false, the grid doesn't need to update
      return false;
    }
  }

  const statusResult = (params) => {

    var status = "Success";
    if (params.data.Status === "Pre-Queued" & params.data.Result !== "") { status = "ERROR" }
    else if (params.data.Status === "" & params.data.Result !== "") { status = "ERROR" }
    else { status = params.data.Status }

    return status;
  };

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }


  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: "agSetColumnFilter",
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  const baseColDefs = [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      initialWidth: 150,
      rowDrag: true,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      initialWidth: 100,
      tooltipField: "Result",
      field: "Status",
    },
    {
      editable: false,
      headerName: "RedirRef",
      enableRowGroup: true,
      initialWidth: 100,
      field: "Redirected Ref",
      sortable: true,
    },
    {
      editable: true,
      headerName: "TP",
      initialHide: false,
      initialWidth: 80,
      field: "Provider",
    },
    {
      editable: true,
      headerName: "Seller",
      enableRowGroup: true,
      initialHide: false,
      initialWidth: 80,
      field: "Seller Code",
      filter: "agSetColumnFilter",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "Cust",
      initialHide: true,
      initialWidth: 80,
      field: "Customer Code",
    },
    {
      editable: true,
      headerName: "Path Name", //Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: true,
      initialWidth: 100,
      field: "Path_Name",
    },
    {
      editable: true,
      headerName: "Source", //Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: true,
      initialWidth: 100,
      field: "Source",
    },
    {
      editable: true,
      headerName: "Sink", //POR	POD	Service	Preconfirmed	Start	Stop	Profile	Capacity Requested,
      initialHide: true,
      initialWidth: 100,
      field: "Sink",
    },
    {
      editable: true,
      headerName: "POR", //POR	POD	Service	Preconfirmed	Start	Stop	Profile	Capacity Requested,
      initialHide: false,
      initialWidth: 70,
      field: "PointOfReceipt",
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
        field: 'por'
      },
    },
    {
      headerName: "POD", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: false,
      initialWidth: 70,
      field: "PointOfDelivery",
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
        field: 'pod'
      },
    },
    {
      cellEditor: "datePicker",
      cellEditorPopup: true,
      editable: true,
      headerName: "Start D",
      initialHide: false,
      initialWidth: 80,
      field: "Start Date",
    },
    // {
    //   editable: true,
    //   headerName: "Start T",
    //   initialHide: false,
    //   initialWidth: 80,
    //   field: "Start Time",
    // },
    {
      editable: true,
      headerName: "Stop D",
      cellEditor: "datePicker",
      cellEditorPopup: true,
      initialHide: false,
      initialWidth: 80,
      field: "Stop Date",
    },
    // {
    //   editable: true,
    //   headerName: "Stop T",
    //   initialHide: false,
    //   initialWidth: 80,
    //   field: "Stop Time",
    // },
    {
      editable: true,
      headerName: "Svc", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialWidth: 180,
      field: "Service",
      cellEditor: 'agSelectCellEditor',
      valueSetter: setServiceValues,
      cellEditorParams: serviceValues,
    },
    {
      editable: true,
      headerName: "Service_Increment", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "Service_Increment",
    },
    {
      editable: true,
      headerName: "TS_Class", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "TS_Class",
    },
    {
      editable: true,
      headerName: "TS_Type", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "TS_Type",
    },
    {
      editable: true,
      headerName: "TS_Period", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "TS_Period",
    },
    {
      editable: true,
      headerName: "TS_Window", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "TS_Window",
    },
    {
      editable: true,
      headerName: "TS_Subclass", //Service	Service_Increment	TS_Class	TS_Type	TS_Period	TS_Window	TS_Subclass,
      initialHide: true,
      initialWidth: 80,
      field: "TS_Subclass",
    },
    {
      editable: true,
      headerName: "Pre", //POR	POD	Service	Preconfirmed	Start	Stop	Profile	Capacity Requested,
      enableRowGroup: true,
      initialHide: false,
      initialWidth: 60,
      field: "Preconfirmed",
    },
    {
      editable: true,
      headerName: "Vol",
      field: 'Vol',
      enableRowGroup: true,
      initialHide: true,
      initialWidth: 60,
    },
    {
      editable: true,
      headerName: "$$",
      enableRowGroup: true,
      initialHide: true,
      initialWidth: 60,
      field: "Price",
    },
    {
      editable: true,
      headerName: "Profile",//Provider	Customer Code	Sale Ref	Related Ref	Deal Ref	Posting Ref	Request Ref	Path_Name	Source	Sink,
      initialHide: false,
      initialWidth: 60,
      field: "Profile",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: [...Object.keys(Profiles), 'Custom'],
      },
      valueSetter: setRowFromProfile,
    },
    { editable: true, headerName: "V1", initialHide: true, initialWidth: 40, field: "HEC1" },
    { editable: true, headerName: "V2", initialHide: true, initialWidth: 40, field: "HEC2" },
    { editable: true, headerName: "V3", initialHide: true, initialWidth: 40, field: "HEC3" },
    { editable: true, headerName: "V4", initialHide: true, initialWidth: 40, field: "HEC4" },
    { editable: true, headerName: "V5", initialHide: true, initialWidth: 40, field: "HEC5" },
    { editable: true, headerName: "V6", initialHide: true, initialWidth: 40, field: "HEC6" },
    { editable: true, headerName: "V7", initialHide: true, initialWidth: 40, field: "HEC7" },
    { editable: true, headerName: "V8", initialHide: true, initialWidth: 40, field: "HEC8" },
    { editable: true, headerName: "V9", initialHide: true, initialWidth: 40, field: "HEC9" },
    { editable: true, headerName: "V10", initialHide: true, initialWidth: 40, field: "HEC10" },
    { editable: true, headerName: "V11", initialHide: true, initialWidth: 40, field: "HEC11" },
    { editable: true, headerName: "V12", initialHide: true, initialWidth: 40, field: "HEC12" },
    { editable: true, headerName: "V13", initialHide: true, initialWidth: 40, field: "HEC13" },
    { editable: true, headerName: "V14", initialHide: true, initialWidth: 40, field: "HEC14" },
    { editable: true, headerName: "V15", initialHide: true, initialWidth: 40, field: "HEC15" },
    { editable: true, headerName: "V16", initialHide: true, initialWidth: 40, field: "HEC16" },
    { editable: true, headerName: "V17", initialHide: true, initialWidth: 40, field: "HEC17" },
    { editable: true, headerName: "V18", initialHide: true, initialWidth: 40, field: "HEC18" },
    { editable: true, headerName: "V19", initialHide: true, initialWidth: 40, field: "HEC19" },
    { editable: true, headerName: "V20", initialHide: true, initialWidth: 40, field: "HEC20" },
    { editable: true, headerName: "V21", initialHide: true, initialWidth: 40, field: "HEC21" },
    { editable: true, headerName: "V22", initialHide: true, initialWidth: 40, field: "HEC22" },
    { editable: true, headerName: "V23", initialHide: true, initialWidth: 40, field: "HEC23" },
    { editable: true, headerName: "V24", initialHide: true, initialWidth: 40, field: "HEC24" },
    { editable: true, headerName: "V25", initialHide: true, initialWidth: 40, field: "HEC25" },
    { editable: true, headerName: "P1", initialHide: true, initialWidth: 40, field: "HEP1" },
    { editable: true, headerName: "P2", initialHide: true, initialWidth: 40, field: "HEP2" },
    { editable: true, headerName: "P3", initialHide: true, initialWidth: 40, field: "HEP3" },
    { editable: true, headerName: "P4", initialHide: true, initialWidth: 40, field: "HEP4" },
    { editable: true, headerName: "P5", initialHide: true, initialWidth: 40, field: "HEP5" },
    { editable: true, headerName: "P6", initialHide: true, initialWidth: 40, field: "HEP6" },
    { editable: true, headerName: "P7", initialHide: true, initialWidth: 40, field: "HEP7" },
    { editable: true, headerName: "P8", initialHide: true, initialWidth: 40, field: "HEP8" },
    { editable: true, headerName: "P9", initialHide: true, initialWidth: 40, field: "HEP9" },
    { editable: true, headerName: "P10", initialHide: true, initialWidth: 40, field: "HEP10" },
    { editable: true, headerName: "P11", initialHide: true, initialWidth: 40, field: "HEP11" },
    { editable: true, headerName: "P12", initialHide: true, initialWidth: 40, field: "HEP12" },
    { editable: true, headerName: "P13", initialHide: true, initialWidth: 40, field: "HEP13" },
    { editable: true, headerName: "P14", initialHide: true, initialWidth: 40, field: "HEP14" },
    { editable: true, headerName: "P15", initialHide: true, initialWidth: 40, field: "HEP15" },
    { editable: true, headerName: "P16", initialHide: true, initialWidth: 40, field: "HEP16" },
    { editable: true, headerName: "P17", initialHide: true, initialWidth: 40, field: "HEP17" },
    { editable: true, headerName: "P18", initialHide: true, initialWidth: 40, field: "HEP18" },
    { editable: true, headerName: "P19", initialHide: true, initialWidth: 40, field: "HEP19" },
    { editable: true, headerName: "P20", initialHide: true, initialWidth: 40, field: "HEP20" },
    { editable: true, headerName: "P21", initialHide: true, initialWidth: 40, field: "HEP21" },
    { editable: true, headerName: "P22", initialHide: true, initialWidth: 40, field: "HEP22" },
    { editable: true, headerName: "P23", initialHide: true, initialWidth: 40, field: "HEP23" },
    { editable: true, headerName: "P24", initialHide: true, initialWidth: 40, field: "HEP24" },
    { editable: true, headerName: "P25", initialHide: true, initialWidth: 40, field: "HEP25" },
    {
      editable: false,
      headerName: "TSRStartUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRStopUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRSaleRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Sale Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRequestRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRDealRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRPostingRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRequestType",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRRelatedRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRSellerRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRCustName",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "TSRCustEmail",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
  ]

  const { colDefs, loadLayout, } = useGridLayout(storageLocation, ref, baseColDefs)

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      // {
      //   id: 'redirectService',
      //   labelDefault: 'Service',
      //   labelKey: 'redirectService',
      //   toolPanel: 'serviceToolPanel',
      //   toolPanelParams: {
      //     //onReassignmentServiceSave: onReassignmentServiceSave,
      //     service: redirectService,
      //   },
      //   minWidth: 300,
      //   maxWidth: 400,
      //   width: 375
      // },
      profilePanel,
      {
        id: 'redirectTemplates',
        labelDefault: 'Templates',
        labelKey: 'redirectTemplates',
        //iconKey: '',
        toolPanel: 'templateToolPanel',
        toolPanelParams: {
          fieldNames: ['POD', 'POR', 'Name'],
          currentTemplates: templates,
          addTemplate: addTemplate,
          deleteTemplate: deleteTemplate
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
      },
    ],
    position: 'right',
  };

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <AgGridContainer
      style={{
        height: "40vh",
        width: "98%",
        padding: "5px",
        // clear: "both",
        // overflow: "hidden",
      }}
    >
      {/* <SaveTemplateDialog 
          templateData={templateData}
          open={saveTemplateOpen}
          handleClose={handleSaveTemplateClose}
          transmissionTemplateTypeId={transmissionTemplateTypeId}/> */}
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveRedirectSelected()}
            endIcon={<DeleteForeverIcon />}
            id="delete"
            disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Add a new blank row to the grid." arrow placement="top">
          <Button
            endIcon={<AddCircleOutlinedIcon />}
            id="addRedirectRow"
            variant={buttonVariant}
            //disabled={buttonsDisabled}
            color={buttonColor}
            size={buttonSize}
            onClick={() => addRedirectItems()}
          >Add New</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            id="addRedirectRowCopy"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={buttonsDisabled}
            //onClick={() => setOpenRowEditor(!openRowEditor)}
            onClick={addRedirectRowCopy}
          >Add Copy</Button>
        </Tooltip>&nbsp;

        <Tooltip title="Submit all redirects without New TSR number and check status on TSRs with New TSR numbers." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={submitButtonDisabled}
            onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
        </Tooltip>&nbsp;
      </Box>
      <AgGridReact
        // style={{height: '100%'}}
        ref={ref}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        rowSelection="multiple"
        enableFillHandle={true}
        undoRedoCellEditing={true}
        overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">No redirects queued... drag a TSR in to this area or use Redirect templates to queue up new redirects.</span>'}
        undoRedoCellEditingLimit={20}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        tooltipShowDelay={0}
        onCellFocused={updateCurrentRow}
        singleClickEdit
        //enterMovesDownAfterEdit={true}
        gridOptions={gridOptions}
        onSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onRowDataUpdated}
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        animateRows={true}
        //rowData={redirectRowData}
        groupSelectsChildren={true}
        components={{
          profilePicker: ProfilePicker,
          autocompleteEditor: AutocompleteEditor,
          //templateToolPanel: RedirectTemplatePanel,
          templateToolPanel: TemplateToolPanel,
          profileToolPanel: ProfileToolPanel,
          datePicker: DateCellEditor,
          // serviceToolPanel: ServiceToolPanel,
        }}
      />
    </AgGridContainer>
  );
});