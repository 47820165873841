import { Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, Button, TextField } from "@mui/material";
import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Profiles } from "../TransNow/Profiles";
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/conditionalGridStyles.css'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AgGridContainer } from "../AgGrid/AgGridContainer";

export function ProfileToolPanel(props) {
  const { onProfileSave, row } = props;
  const gridRef = useRef();
  const [rows, setRows] = useState(mapRowDataToGrid(row?.data ?? {}));
  const [volume, setVolume] = useState(row?.data['Capacity Requested'] ?? 0);
  const [price, setPrice] = useState(row?.data['Bid Price'] ?? 0);
  const [profileName, setProfileName] = useState(row?.data.Profile ?? 'Custom');

  useEffect(() => {
    if (price > 0 || volume > 0) {
      updateWithProfile(profileName);
      onSave();
    }
  }, [price, volume, profileName]);

  function mapRowDataToGrid(rowData) {
    const rows = [];
    for (let i = 1; i < 26; i++) {
      rows.push({
        'HE': i,
        'Volume': rowData[`HEC${i}`] ?? 0,
        'Price': rowData[`HEP${i}`] ?? 0,
      });
    }
    return rows;
  }

  function updateWithProfile(profile) {
    if (profile !== 'Custom') {
      const hours = Profiles[profile];
      let i = 0;
      gridRef.current.api.forEachNode(node => {
        node.setDataValue('Volume', hours[i] * volume);
        node.setDataValue('Price', hours[i] * price);
        i++;
      });
    }
    setProfileName(profile);
  }

  const [columnDefs] = useState([
    { field: 'HE', width: 50, editable: false },
    { field: 'Volume', cellClassRules: { 'red-cell': 'x <= 0', 'red-cell': 'x === 0', 'green-cell': 'x > 0' }, width: 150, editable: true },
    { field: 'Price', width: 150, editable: true }
  ])

  function onSave() {
    const newVolumes = [];
    const newPrices = [];
    gridRef.current.api.forEachNode(node => {
      newVolumes.push(node.data['Volume']);
      newPrices.push(node.data['Price']);
    });
    onProfileSave(profileName, volume, newVolumes, price, newPrices);
  }

  function onCellEditingStopped(params) {
    if (params.newValue !== params.oldValue) {
      setProfileName('Custom');
      onSave();
    }
  }

  function handleVolumeChange(event) {
    setVolume(event.target.value);
  }

  function handlePriceChange(event) {
    setPrice(event.target.value);
  }

  const handleProfileChange = (event) => {
    setProfileName(event.target.value);
  };

  return (
    row ?
      <AgGridContainer style={{ height: '100%', width: '350px' }}>
        <Box sx={{ p: 2, }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label='Volume'
                variant='outlined'
                value={volume}
                onChange={handleVolumeChange} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label='Bid Price'
                variant='outlined'
                value={price}
                onChange={handlePriceChange} />
            </Grid>
          </Grid>
          <p />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Profile</InputLabel>
            <Select
              value={profileName}
              size="small"
              label="Profile"
              onChange={handleProfileChange}
            >
              {Object.keys(Profiles).map(profileName =>
                <MenuItem value={profileName}>{profileName}</MenuItem>
              )}
              <MenuItem value={'Custom'}>Custom</MenuItem>
            </Select>
          </FormControl>
          <p />
        </Box>
        <AgGridReact
          singleClickEdit
          ref={gridRef}
          rowData={rows}
          columnDefs={columnDefs}
          onCellEditingStopped={onCellEditingStopped}
        />
      </AgGridContainer>
      : <Grid sx={{ p: 2 }} container>
        <Grid item>
          <Typography>
            Select a cell to view its row details.
          </Typography>
        </Grid>
      </Grid>
  );
};