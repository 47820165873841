import { Pricing, Album, Footer } from "../components";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";

function AboutPowerStation() {
  const theme = useTheme();
  const imgSrc = theme.palette.mode === "light" ? "./PowerStationLogo.png" : "./PowerStationLogo_Light.png";

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        component="img"
        sx={{
          paddingTop: 6,
          paddingLeft: 5,
          paddingRight: 5,
          width: '30%'
        }}
        alt="Power Station Platform"
        src={imgSrc}
      />
      <Album />
      <Pricing />
      <Footer />
    </div>
  );
}

export default AboutPowerStation;
