import { Box } from "@mui/material"
import { AgGridReact } from "ag-grid-react"
import dayjs from "dayjs"
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react"
import * as yup from 'yup'
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useActionAudit } from "../../useActionAudit"
import useHeader from "../../useHeader"
import { useLayoutFunctions } from "../../useLayoutFunctions"
import { useDashboardFetch } from "../useDashboardFetch"
import { useDashboardLayout } from "../useDashboardLayout"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { handleUpdateTotalRows, heatmapStyle, isLongDay, redPastMarkerFormatter, tooltipRenderer } from "../Utils"
import { ViewContextProvider } from "../ViewContextProvider"
import { TransPositionRibbon } from "./Ribbon/TransPositionRibbon"

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    timeType: yup.string().required('Time Type is required'),
});

export const TransmissionPositionView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-transmission-position-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const headers = useHeader();
    const { logAction } = useActionAudit();
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [selectedRow, setSelectedRow] = useState([]);
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const { captureLayout } = useLayoutFunctions();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        book: '',
        timeType: 'daily',
        ...view,
        date: dayjs(),
    }), [view]);

    const baseColDefs = useMemo(() => ([
        {
            field: "AssignmentRef",
            headerName: "Aref",
            // checkboxSelection: true, 
            cellDataType: 'text'
        },
        { field: "ID", headerName: "ID", initialHide: true },
        {
            field: "Status",
            headerName: "Status",
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['ACCEPTED', 'ANNULLED', 'CONFIRMED', 'COUNTEROFFER', 'CR_COUNTEROFFER', 'DECLINED', 'DISPLACED', 'INVALID', 'QUEUED', 'REBID', 'RECEIVED', 'REFUSED', 'RETRACTED', 'STUDY', 'SUPERSEDED', 'WITHDRAWN']
            },
        },
        { field: "Provider", headerName: "Provider" },
        { field: "SellerCode", headerName: "Seller Code" },
        { field: "CustomerCode", headerName: "Customer Code" },
        { field: "PathName", enableRowGroup: "true", headerName: "Path Name" },
        { field: "PointOfReceipt", headerName: "TSR POR" },
        { field: "PointOfDelivery", headerName: "TSR POD" },
        {
            field: 'por',
            headerName: 'Deal POR',
        },
        {
            field: 'pod',
            headerName: 'Deal POD',
        },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        {
            field: "StartTime",
            headerName: "Start Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "StopTime",
            headerName: "Stop Time",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            headerName: 'Position Graph',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            field: "2*",
            headerName: "2*",
            enableValue: true,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            // cellStyle: params => heatmapStyle(params, paletteRef),
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            enableValue: true,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            field: "Capacity",
            headerName: "Capacity",
            cellDataType: 'number',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
        },
        { field: "ServiceIncrement", headerName: "Service Increment" },
        { field: "TSClass", headerName: "TS Class" },
        { field: "TSType", headerName: "TS Type" },
        { field: "TSPeriod", headerName: "TS Period" },
        { field: "TSWindow", headerName: "TS Window" },
        { field: "TSSubclass", headerName: "TS Subclass" },
        { field: "SaleRef", headerName: "Sale Reference" },
        { field: "RequestRef", headerName: "Request Reference" },
        { field: "DealRef", headerName: "Deal Reference" },
        { field: "PostingRef", headerName: "Posting Reference" },
        { field: "ReassignedStopTime", headerName: "Reassigned Stop Time" },
        { field: "RequestType", headerName: "Request Type" },
        { field: "RelatedRef", headerName: "Related Reference" },
        { field: "SellerRef", headerName: "Seller Reference" },
        { field: "NERCcurtailmentPriority", headerName: "NERC Curtailment Priority" },
        { field: "Preconfirmed", headerName: "Preconfirmed" },
        {
            field: "TimeQueued",
            headerName: "Time Queued",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "ResponseTimeLimit",
            headerName: "Response Time Limit",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: "TimeOfLastUpdate",
            headerName: "Time Of Last Update",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        { field: "STATUS_COMMENTS", headerName: "Status Comments" },
        { field: "PRIMARY_PROVIDER_COMMENTS", headerName: "Primary Provider Comments" },
        { field: "SELLER_COMMENTS", headerName: "Seller Comments" },
        { field: "CUSTOMER_COMMENTS", headerName: "Customer Comments" },
        { field: "SELLER_NAME", headerName: "Seller Name" },
        { field: "SELLER_PHONE", headerName: "Seller Phone" },
        { field: "SELLER_FAX", headerName: "Seller Fax" },
        { field: "SELLER_EMAIL", headerName: "Seller Email" },
        { field: "CUSTOMER_NAME", headerName: "Customer Name" },
        { field: "CUSTOMER_PHONE", headerName: "Customer Phone" },
        { field: "CUSTOMER_FAX", headerName: "Customer Fax" },
        { field: "CUSTOMER_EMAIL", headerName: "Customer Email" }
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        flex: 1,
    }), []);

    const getRowId = useCallback(({ data }) => {
        return `${data.AssignmentRef}-${data.Provider}-${data.PointOfReceipt}-${data.PointOfDelivery}`;
    }, []);

    const { loadAndApplyData, } = useDashboardFetch({
        fetchProcName: 'dealrizz.UI_fetchTransmissionPosition_v4',
        fetchParamKeys: ['userGuid', 'date', 'timezone', 'book'],
        gridRef,
        getRowId,
    });

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef,
        layoutStorageKey,
        context: view,
        baseColDefs,
        defaultColDef,
    });

    function toggleExtraHourColBasedOnDate(date) {
        const isLong = isLongDay(date);
        gridRef.current?.columnApi?.setColumnVisible('2*', isLong);
    }

    const handleFetchData = useCallback((data) => {
        logAction('User fetched Transmission Position Data', 'Transmission Position', data);
        setDate(dayjs(date).format('MM/DD/YYYY'));
        loadAndApplyData({
            ...data,
            userGuid: headers.userGuid,
            date: dayjs(data.date).format('MM/DD/YYYY'),
        });
        toggleExtraHourColBasedOnDate(data.date);
    }, [headers]);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'transmission-position-palette'),
        ]
    }), []);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const toolbarFormId = `transmission-position-toolbar-form-${view.id}`;

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'AssignmentRef');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    function handleSelectionChanged() {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(selectedRows[0])
    }

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData}>
            <Box className='flex-column'>
                <TransPositionRibbon
                    selectedRow={selectedRow}
                    gridRef={gridRef}
                    toolbarFormId={toolbarFormId}
                    handleFetchData={handleFetchData}
                />
                <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                    <AgGridReact
                        {...defaultGridOptions}
                        containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                        ref={gridRef}
                        getRowId={getRowId}
                        columnDefs={styledColDefs}
                        gridOptions={gridOptions}
                        onFilterChanged={updateTotalRows}
                        onRowDataUpdated={updateTotalRows}
                        enableCharts
                        rowSelection="single"
                        rowMultiSelectWithClick
                        //popupParent={popupParent}
                        //createChartContainer={createChartContainer}
                        onSelectionChanged={handleSelectionChanged}
                        onFirstDataRendered={handleFirstDataRendered}
                        onCellMouseOver={handleCellMouseOverHighlight}
                        suppressAggFuncInHeader={true}
                        // groupDisplayType={"groupRows"}
                        groupTotalRow={"bottom"}
                        groupSuppressBlankHeader={true}
                        onGridReady={onGridReady}
                        columnTypes={columnTypes}
                        statusBar={defaultStatusBar}
                        sideBar={sideBar}
                        components={{
                            layoutToolPanel: LayoutToolPanel,
                            paletteToolPanel: PaletteToolPanel,
                        }}
                    />
                </AgGridContainer>
            </Box>
        </ViewContextProvider>
    )
});
