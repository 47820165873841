import { GraphCanvas, lightTheme, darkTheme, useSelection, } from 'reagraph';
import { Box, Typography, IconButton, Stack, Button, Tooltip, } from '@mui/material';
import { useTheme } from '@mui/material';
import { useMemo, useRef, forwardRef, useState, } from 'react';
import { Add, Remove, FileDownloadOutlined } from '@mui/icons-material';
import { ContextMenu } from './ContextMenu';
import '../../../styles/reagraphStyles.css';
import Graph from 'graphology';
import { dijkstra, } from 'graphology-shortest-path';
import { allSimpleEdgeGroupPaths } from 'graphology-simple-path';
import { NodeActionMenu } from './NodeActionMenu';
import { AddNewLegsDialog } from './AddNewLegsDialog';
import { willCreateCycle } from 'graphology-dag'
import { useSnackbar } from 'notistack';
import { AutoBuildPathsDialog } from './AutoBuildPathsDialog';
import GraphControls from './GraphControls';
import { LegInfoPane } from './LegInfoPane';
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import { difference, union, } from '../../../utils/setPolyfills';
import { EdgeActionMenu } from './EdgeActionMenu';

export const PathGraph = forwardRef((props, ref) => {
    const { data, setData, gridRef, availableTsrs, defaultNodes, tsrKey, setRowHighlight, handleEdgeClick, selections, setSelections, clearSelections, actives, setActives, } = props;
    const appTheme = useTheme();
    const defaultGraphTheme = appTheme.palette.mode === 'dark' ? darkTheme : lightTheme;
    const [openAddNewLegsDialog, setOpenAddNewLegsDialog] = useState(false);
    const [openAutoBuildPathsDialog, setOpenAutoBuildPathsDialog] = useState(false);
    const [highlightedEdge, setHighlightedEdge] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [confirmDialogProps, setConfirmDialogProps] = useState({ open: false, });
    const [layoutType, setLayoutType] = useState('treeLr2d');

    const serviceAbbreviation = {
        'YEARLY': 'YLY',
        'MONTHLY': 'MLY',
        'WEEKLY': 'WLY',
        'DAILY': 'DLY',
        'HOURLY': 'HLY',
    }

    const typeAbbreviation = {
        'ORIGINAL': 'O',
        'REDIRECT': 'RED',
        'RESERVED': 'RES'
    }

    const priorityLabelMap = row => {
        if (row.TSClass === 'FIRM') {
            return '7-F';
        } else {
            return `${row.NERCcurtailmentPriority}-N${row.ServiceIncrement[0]}`;
        }
    }

    const availableNodes = useMemo(() => {
        return [...new Set(availableTsrs.reduce((nodes, next) => {
            nodes.add(next.PointOfReceipt);
            nodes.add(next.PointOfDelivery);
            return nodes;
        }, new Set()))].map(node => ({ key: node, }))
    }, [availableTsrs]);

    const availableEdges = useMemo(() => {
        return availableTsrs.map(tsr => ({
            source: tsr.PointOfReceipt,
            target: tsr.PointOfDelivery,
            key: tsrKey(tsr),
            attributes: tsr,
        }));
    }, [availableTsrs]);

    const availabilityGraph = useMemo(() => {
        const graph = new Graph({ type: 'directed', multi: true, });
        graph.import({
            nodes: availableNodes,
            edges: availableEdges,
        });
        return graph;
    }, [availableEdges, availableNodes]);

    const graphTheme = {
        ...defaultGraphTheme,
    };

    const pors = useMemo(() => new Set(data.map(row => row.PointOfReceipt)), [data]);
    const pods = useMemo(() => new Set(data.map(row => row.PointOfDelivery)), [data]);

    const initialNodes = useMemo(() => difference(pors, pods), [pors, pods]);
    const terminalNodes = useMemo(() => difference(pods, pors), [pors, pods]);

    const nodeNames = useMemo(() => [...union(pors, pods)], [pors, pods]);

    const nodes = useMemo(() => {
        return [
            ...defaultNodes,
            ...nodeNames.map(name => ({
                id: name,
                label: name,
                fill: nodeFillColor(name),
            })),
        ]
    }, [nodeNames, defaultNodes]);

    const edges = useMemo(() => data.map(newEdgeFromRow), [data]);

    function nodeFillColor(nodeId) {
        if (initialNodes.has(nodeId)) {
            return appTheme.palette.primary.green;
        } else if (terminalNodes.has(nodeId)) {
            return appTheme.palette.primary.red;
        } else {
            return appTheme.palette.primary.main;
        }
    }

    function newEdgeFromRow(row) {
        return {
            id: tsrKey(row),
            source: row.PointOfReceipt,
            target: row.PointOfDelivery,
            label: `${serviceAbbreviation[row.ServiceIncrement]} ${typeAbbreviation[row.RequestType]} ${priorityLabelMap(row)}`,
            data: row,
            size: 5,
        }
    }

    const {
        //actives,
        onNodeClick,
        onCanvasClick,
        onLasso,
        onLassoEnd,
    } = useSelection({
        ref: ref,
        nodes: nodes,
        edges: edges,
        type: 'multi'
    });

    function handleBuildPaths(criteria) {
        try {
            const { requestTypes, maxLegs, nercPriority, } = criteria;
            const source = selectedNodes[0];
            const sink = selectedNodes[1];
            const types = requestTypes.map(type => type.toLowerCase());

            let max = maxLegs;
            if (max === 'Any Length') {
                max = undefined;
            } else if (max === 'Minimum') {
                const shortest = dijkstra.bidirectional(availabilityGraph, source, sink);
                max = shortest.length - 1;
            } else {
                max = parseInt(max);
            }

            const edgeSelector = edge => {
                const hasRequestType = types.includes('*') || types.includes(edge.attributes.RequestType.toLowerCase());
                const hasPriority = nercPriority.includes('*') || nercPriority.includes(edge.attributes.NERCcurtailmentPriority);
                return hasRequestType && hasPriority;
            }

            const includedEdges = availableEdges.filter(edgeSelector);
            const searchGraph = new Graph({ type: 'directed', multi: true, });
            searchGraph.import({
                nodes: availableNodes,
                edges: includedEdges,
            });

            const edgePaths = allSimpleEdgeGroupPaths(searchGraph, source, sink, { maxDepth: max, });

            if (!edgePaths.length) {
                enqueueSnackbar('No paths found between the selected nodes.', { variant: 'error' });
                return;
            }

            const edgeKeys = edgePaths.flatMap(groupEdgeKeyArray => {
                return groupEdgeKeyArray.flatMap(groupEdgeKeys => groupEdgeKeys);
            });
            const uniqueKeys = [...new Set(edgeKeys)];
            const toAdd = uniqueKeys.map(key => {
                return availabilityGraph.getEdgeAttributes(key);
            });

            setData([...data, ...toAdd]);

            clearSelections();
        } catch (error) {
            enqueueSnackbar(`An error occurred while building paths. ${error.message ? error.message : error}.`, { variant: 'error' });
            console.error(error);
        }
    }

    const selectedEdges = Array.from(selections).filter(id => id.includes('-'));
    const selectedNodes = Array.from(selections).filter(id => !id.includes('-'));

    const removeSelectedEdges = () => {
        const toRemove = [];
        gridRef.current.api.forEachNode(node => {
            const nodeData = node.data;
            const isSelected = selectedEdges.find(edgeId => tsrKey(nodeData) === edgeId);
            if (isSelected) {
                toRemove.push(tsrKey(nodeData));
            }
        })

        const remaining = data.filter(row => !toRemove.includes(tsrKey(row)));
        setData(remaining);
        clearSelections();
    }

    function addLeafNodesToSelected() {
        addAllChildren(selectedNodes);
        clearSelections();
    }

    function addAllChildren(nodes) {
        const currentData = [];
        gridRef.current.api.forEachNode(node => {
            currentData.push(node.data);
        })

        const toAdd = availableTsrs
            .filter(tsr => nodes.includes(tsr.PointOfReceipt))
            .filter(tsr => !currentData.find(row => row.PointOfReceipt === tsr.PointOfDelivery))

        setData([...data, ...toAdd]);
    }

    function addChildNode(nodeId) {

    }

    function addLegs(legs) {
        const toAdd = [];
        const graph = ref.current.getGraph();
        legs.forEach(leg => {
            const createsCycle = willCreateCycle(graph, leg.PointOfReceipt, leg.PointOfDelivery);
            if (createsCycle) {
                enqueueSnackbar(`Row from ${leg.PointOfReceipt} to ${leg.PointOfDelivery} cannot be moved to the workspace because it forms a loop back.`, { variant: 'error' });
            } else {
                toAdd.push(leg);
                const newEdge = newEdgeFromRow(leg);
                graph.mergeNode(newEdge.source);
                graph.mergeNode(newEdge.target);
                graph.addEdgeWithKey(newEdge.id, newEdge.source, newEdge.target, newEdge.data);
            }
        });
        setData([...data, ...toAdd]);
        setOpenAddNewLegsDialog(false);
        clearSelections();
    }

    const NoNodesOverlay = () => {
        return (
            <span style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h6' color='text.secondary'>No nodes to show. Perform a search to add source and sink nodes.</Typography>
            </span>
        );
    }

    function handleExportGraph() {
        const data = ref.current.exportCanvas();
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', 'graph.png');
        link.click();
    }

    const newLegsData = useMemo(() => {
        if (!availableTsrs || !selectedNodes?.length) return [];
        return availableTsrs.filter(tsr => {
            return selectedNodes.some(node => {
                return node === tsr.PointOfReceipt;
            }) && !willCreateCycle(ref.current.getGraph(), tsr.PointOfReceipt, tsr.PointOfDelivery);
        });
    }, [availableTsrs, selectedNodes.length]);

    function handleEdgePointerOver(edge) {
        setRowHighlight(edge.id, true);
        setHighlightedEdge(edge);
    }

    function handleEdgePointerOut(edge) {
        setRowHighlight(edge.id, false);
        setHighlightedEdge({});
    }

    function removeParallelEdges(edgeIds) {
        const selectedEdges = edgeIds.map(id => gridRef.current.api.getRowNode(id).data);
        const sources = selectedEdges.map(edge => edge.PointOfReceipt);
        const targets = selectedEdges.map(edge => edge.PointOfDelivery);
        const newData = data.filter(row => {
            return !(sources.includes(row.PointOfReceipt) && targets.includes(row.PointOfDelivery) && !edgeIds.includes(tsrKey(row)));
        });
        setData(newData);
        clearSelections();
    }

    function handleRemoveLeg(edge) {
        const node = gridRef.current.api.getRowNode(edge.id);
        const key = tsrKey(node.data);
        const remaining = data.filter(row => tsrKey(row) !== key);
        setData(remaining);
        clearSelections();
    }

    function confirmRemoveNode(data) {
        const message = `Removing this node will also remove all legs it is a part of. Are you sure you want to remove ${data.label}?`;
        setConfirmDialogProps({ open: true, message, onConfirmation: () => handleRemoveNode(data), });
    }

    function handleRemoveNode(node) {
        setConfirmDialogProps({ open: false, });
        const toRemove = [];
        gridRef.current.api.forEachNode(row => {
            if (row.data.PointOfReceipt === node.id || row.data.PointOfDelivery === node.id) {
                toRemove.push(tsrKey(row.data));
            }
        });

        const remaining = data.filter(row => !toRemove.includes(tsrKey(row)));
        setData(remaining);

        clearSelections();
    }

    function handleNodeClick(params) {
        const newSelections = new Set(selections);
        if (selections.has(params.id)) {
            newSelections.delete(params.id);
        } else {
            newSelections.add(params.id);
        }
        setSelections(new Set(newSelections));
        onNodeClick(params);
    }

    const layoutOverrides = {
        getNodePosition: (id, params) => {
            const { nodes, } = params;
            const node = nodes.find(node => node.id === id);
            if (node.label.includes('Source')) {
                return { x: -300, y: 0, };
            } else if (node.label.includes('Sink')) {
                return { x: 300, y: 0, };
            }
        }
    }

    const graphControlsContainerSx = useMemo(() => ({
        position: 'absolute',
        top: 1,
        left: 1,
        display: 'flex',
    }), []);

    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            <AddNewLegsDialog
                open={openAddNewLegsDialog}
                onClose={() => setOpenAddNewLegsDialog(false)}
                onAddLegs={addLegs}
                rowData={newLegsData}
            />
            <AutoBuildPathsDialog
                open={openAutoBuildPathsDialog}
                onClose={() => setOpenAutoBuildPathsDialog(false)}
                handleBuildPaths={handleBuildPaths}
            />
            <GraphCanvas
                ref={ref}
                theme={graphTheme}
                nodes={nodes}
                edges={edges}
                labelType='all'
                layoutType={layoutType}
                //layoutOverrides={layoutOverrides}
                edgeLabelPosition="natural"
                defaultNodeSize={5}
                draggable
                selections={[...selections]}
                actives={actives}
                onNodeClick={handleNodeClick}
                onEdgePointerOver={handleEdgePointerOver}
                onEdgePointerOut={handleEdgePointerOut}
                onEdgeClick={handleEdgeClick}
                onCanvasClick={e => {
                    onCanvasClick(e);
                    clearSelections();
                }}
                lassoType="edge"
                onLasso={onLasso}
                onLassoEnd={onLassoEnd}
                contextMenu={props => <ContextMenu
                    {...props}
                    addAllChildren={addAllChildren}
                    addChild={addChildNode}
                    handleRemoveLeg={handleRemoveLeg}
                    handleRemoveNode={confirmRemoveNode}
                    removeParallel={removeParallelEdges}
                />}
            />
            {!nodes.length && <NoNodesOverlay />}
            {!selections?.size && <Typography variant='caption' color='text.secondary' sx={{ position: 'absolute', top: 2, right: 2, display: 'flex', alignItems: 'center' }}>Hold shift and drag to select edges, or click a node.</Typography>}
            {!!selections.size && <div style={{ position: 'absolute', top: 2, right: 2, display: 'flex', alignItems: 'flex-end', flexDirection: 'column', }}>
                {!!selectedEdges.length && <EdgeActionMenu
                    selectedEdges={selectedEdges}
                    removeSelectedEdges={removeSelectedEdges}
                    removeParallelEdges={removeParallelEdges}
                />}
                {!!selectedNodes.length && <NodeActionMenu
                    selectedNodes={selectedNodes}
                    addLeafNodesToSelected={addLeafNodesToSelected}
                    handleConnectClick={() => setOpenAutoBuildPathsDialog(true)}
                    handleAddNewLeg={() => setOpenAddNewLegsDialog(true)}
                />}
            </div>}
            <GraphControls
                ref={ref}
                layoutType={layoutType}
                setLayoutType={setLayoutType}
                containerSx={graphControlsContainerSx}
            />
            <div style={{ position: 'absolute', bottom: 1, left: 1, display: 'flex', alignItems: 'center', }}>
                <Button
                    endIcon={<FileDownloadOutlined />}
                    onClick={handleExportGraph}
                    sx={{ paddingRight: 3, }}
                >Export</Button>
                <LegInfoPane leg={highlightedEdge} />
            </div>
            <ConfirmationDialog
                {...confirmDialogProps}
                onCancel={() => setConfirmDialogProps({ open: false, })}
            />
        </Box>
    );
});