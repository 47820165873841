import { SEO } from './SEO.js';
import CssBaseline from "@mui/material/CssBaseline";
import Header from './Header.js';
import { Outlet } from 'react-router';
import { SoundsProvider } from './Sound/SoundsProvider.js';

export const Main = () => {
    return (
        <main style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <SEO />
            <CssBaseline />
            <SoundsProvider>
                <Header />
                <Outlet />
            </SoundsProvider>
        </main>
    )
}
