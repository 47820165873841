import * as React from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import styled from '@mui/system/styled';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Tooltip from '@mui/material/Tooltip';
import GroupGuard from '../Guards/GroupGuard.js';
import {userGroups} from '../../authConfig.js';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ScheduleIcon from '@mui/icons-material/Schedule';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import VisibilityIcon from '@mui/icons-material/Visibility';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default ({handleSelection, selected}) => {
  const [open, setOpen] = React.useState(false);
  let icon;
  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (open) {
    icon = <ChevronLeftIcon />;
  } else {
    icon = <ChevronRightIcon />;
  }

  return (
     <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer} size="large">
          {icon}
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <Tooltip title="View information about spy Monkey." arrow placement="right">
          <ListItemButton 
            selected={selected === 'spymonkeyhelp'}
            onClick={() => handleSelection('spymonkeyhelp')}>
            <ListItemIcon>
              <QuestionMarkIcon />
            </ListItemIcon>
            <ListItemText primary="Spy Monkey Help" />
          </ListItemButton>
        </Tooltip>
      </List>
      <Divider />
      <List>
        <GroupGuard groups={[userGroups.scheduledtsr,]}>
          <ListItemButton 
            selected={selected === 'timedtsrs'}
            onClick={() => handleSelection('timedtsrs')}>
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="Scheduler" />
          </ListItemButton>
        </GroupGuard>
        <GroupGuard groups={[userGroups.stalker]}>
          <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
            <ListItemButton 
              selected={selected === 'stalker'}
              onClick={() => handleSelection('stalker')}>
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Stalker" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        <GroupGuard groups={[userGroups.triggeredsubmission]}>
          <Tooltip title="View and edit TSR status change triggers" arrow placement="right">
            <ListItemButton 
              selected={selected === 'watcher'}
              onClick={() => handleSelection('watcher')}>
              <ListItemIcon>
                <VisibilityIcon />
              </ListItemIcon>
              <ListItemText primary="Watcher" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
        {/* <ListItemButton 
          selected={selected === 'systemdata'}
          onClick={() => handleSelection('systemdata')}>
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="System Data" />
        </ListItemButton> */}
      </List>
      <Divider />
      <List>
        <Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
          <ListItemButton
            selected={selected === 'tariffprices'}
            onClick={() => handleSelection('tariffprices')}>
            <ListItemIcon>
              <AttachMoneyIcon /> 
            </ListItemIcon> 
            <ListItemText primary="Tariff Prices" />
          </ListItemButton>
        </Tooltip>
        <GroupGuard groups={[userGroups.admins]}>
          <Tooltip title="See the audit log." arrow placement="right">
            <ListItemButton 
              selected={selected === 'audit'}
              onClick={() => handleSelection('audit')}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Audit Log" />
            </ListItemButton>
          </Tooltip>
        </GroupGuard>
      </List>
    </Drawer>
  );
}