import { useState, useEffect } from "react";

export const useLazyRender = (componentName) => {
    const [loaded, setLoaded] = useState({});

    useEffect(() => {
        updateLoaded(componentName);
    }, [componentName]);

    function updateLoaded(name) {
        if (name && !loaded[name]) {
            setLoaded({
                ...loaded,
                [name]: true
            })
        }
    }

    return {
        loaded,
    }
}