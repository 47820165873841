import { useState, useEffect } from 'react';
import { Box, Dialog, Button, DialogTitle, DialogContent, Typography, DialogActions, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useHeader from '../useHeader';
import axios from 'axios';
import { apiUrlPrefix } from '../../authConfig';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, } from 'yup';

const StartNewChatDialog = ({ open, handleClose, handleStartNew }) => {
    const [userChoices, setUserChoices] = useState([]);
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, formState: { errors, }, } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        fetchUserSelectChoices();
    }, []);

    async function fetchUserSelectChoices() {
        const options = {
            method: 'GET',
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch&parm=${headers.userGuid}`,
        }

        axios(options).then(response => {
            const users = [...new Set(response.data.filter(name => name?.userName))]; //filter out falsey and duplicate values
            setUserChoices(users);
        }).catch(error => {
            enqueueSnackbar(`Error fetching user options. Message: ${error} URL: ${`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch`}`)
        });

    };

    function onSubmit(data) {
        handleStartNew(data.user);
    }

    const userOptionLabel = (option) => {
        if (option.firstName && option.lastName) {
            return `${option.firstName} ${option.lastName}`;
        } else {
            return option.userName;
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Select User to Message</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)} id='start-new-chat-dialog-form' style={{ paddingTop: '10px', }}>
                    <FormAutocomplete
                        name='user'
                        control={control}
                        options={userChoices}
                        getOptionLabel={userOptionLabel}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="User"
                                fullWidth
                                error={!!errors.user}
                                helperText={errors.user?.message}
                            />
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', display: 'flex' }}>
                <Button
                    onClick={handleClose}
                >Cancel</Button>
                <Button
                    type='submit'
                    form='start-new-chat-dialog-form'
                >Start Chat
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const schema = object().shape({
    user: object().required('Please select a user.'),
});

export default StartNewChatDialog;