import { TextField, Autocomplete } from "@mui/material";
import { useTheme } from "@emotion/react";

export const LocationAutocomplete = (props) => {
    const { locations, setCenter, } = props;
    const theme = useTheme();

    return (
        <div style={{ padding: '10px', }}>
            <Autocomplete
                autoComplete
                autoHighlight
                autoSelect
                options={[...locations.keys()]}
                onChange={(event, newValue) => {
                    const location = locations.get(newValue);
                    setCenter({ lat: location.temp.latitude, lng: location.temp.longitude });
                }}
                sx={{ width: 300, }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        placeholder="Snap To Location"
                        sx={{
                            opacity: 0.9,
                            backgroundColor: theme.palette.background.paper,
                        }}
                    />
                )}
            />
        </div>
    );
}