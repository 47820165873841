import { useEffect } from 'react';
// import { serviceRowValues } from '../utils/RedirectServiceValues';
import { subscriptionKey, apiUrlPrefix } from '../authConfig';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import setTSValues from '../utils/SetTSValues';
import useHeader from './useHeader';

export const useServiceDropdown= (userInterface) => {
  const [services, setServices] = useState();
  //const [userInterface, setUserInterface] = useState(userInterface);
  const [serviceValues, setServiceValues] = useState({values: []});
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();

  useEffect(() => {
    
    let abortController = new AbortController();  
    // your async action is here  
    retrieveServiceValues();
    return () => {  
      abortController.abort();  
    }  

  }, []);

  useEffect(() => {
    if (services) {
      const values = services.map(svc => svc.Service);
      setServiceValues({values: values});
    }
  }, [services]);

  function retrieveServiceValues() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ServiceTemplateFetch&parm=${guid}&parm=${userInterface}`,
    }
  
    axios(options).then(response => {
      setServices(response.data);
    }).catch(error => {
      enqueueSnackbar(`Error loading service template values. Message: ${error}`)
    });
  };

  function setRowValuesWithService(params) {
    if(params.column.colId === 'Service') {
      const service = services.find(svc => svc.Service === params.newValue);
      const data = params.data;
      const fields = ['Service', 'Service_Increment', 'TS_Class', 'TS_Period', 'TS_Subclass', 'TS_Type', 'TS_Window'];
      if(service) {
        fields.forEach(field => 
          data[field] = service[field]
        );
        if(userInterface === 'Real Time Originals') {
          data.TS_Subclass = service.TS_Subclass === '' ? setTSValues(service.Service, data.TP) : service.TS_Subclass;
        }
      }
    }
  }

  return [
    setRowValuesWithService,
    serviceValues
  ];
};