import { useCallback, useEffect, useState, } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import AddNewOrUpdateDialog from './AddNewDialog';
import { apiUrlPrefix, } from '../../../authConfig';
import "react-tabs/style/react-tabs.css"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Collapse from '@mui/material/Collapse';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../../../components/TSRActivity/ConfirmationDialog';
import DashboardView from './DashboardView';
import { Typography, CircularProgress, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useHeader from '../../../components/useHeader';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

export default ({ dashboardViews, handleSave, handleDelete, }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [viewToUpdate, setViewToUpdate] = useState({});
  const [views, setViews] = useState();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const defaultDateType = localStorage.getItem('fast-path-dashboard-default-date') ?? 'nextDay';
    setViews(dashboardViews?.map(v => ({
      ...v,
      ...getDefaultDates(defaultDateType),
    })));
  }, [dashboardViews]);

  function handleTabSelect(event, newIndex) {
    if (newIndex <= views.length - 1) { //last tab handles deletes, don't switch to it, or the edit tab
      setTabIndex(newIndex);
    }
  }

  function handleAddNew(criteria) {
    if (viewToUpdate.id) {
      handleUpdate(criteria);
    } else {
      setTabIndex(views.length); //switch to new tab
      handleSave(criteria)
    }
    setOpenAddNew(false);
  }

  function handleAddNewClick() {
    setViewToUpdate({});
    setOpenAddNew(true);
  }

  function handleEditClick() {
    setViewToUpdate(views[tabIndex]);
    setOpenAddNew(true);
  }

  const handleDeleteCurrent = useCallback(debounce(async (views, tabIndex, headers) => {
    const current = views[tabIndex];
    setOpenConfirmDelete(false);

    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete_v2&parm=${headers.userGuid}&parm=fastPathDashboard&parm=${current.id}`;
    const options = {
      headers: headers,
      url: deleteUri,
    }

    return axios(options).then(response => {
      setViews(views.filter(view => view.id !== current.id));
    }).then(response => {
      const remaining = views?.filter(v => v.id !== current.id);
      if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
        setOpenAddNew(true);
      }
      setTabIndex(0);
    }).catch(err => { enqueueSnackbar(`Error deleting view(s).  ${err}`, { variant: 'error', }) });
  }, 2000, { leading: true, trailing: false, }), []);

  function handleUpdate(criteria) {
    setViews(views.map(view => view.id === criteria.id ? criteria : view));
    handleSave(criteria, false);
  }

  function handleConfirmDelete() {
    handleDeleteCurrent(views, tabIndex, headers);
  }

  function getDefaultDates(value) {
    const formatString = 'MM/DD/YYYY HH:mm';
    switch (value) {
      case 'nextHour':
        const startDate = dayjs().add(1, 'hour').startOf('hour').format(formatString);
        const stopDate = dayjs().add(2, 'hour').startOf('hour').format(formatString);
        return ({ startDate, stopDate });
      case 'nextDay':
        const startDate2 = dayjs().add(1, 'day').add(1, 'hour').startOf('hour').format(formatString);
        const stopDate2 = dayjs().add(1, 'day').add(2, 'hour').startOf('hour').format(formatString);
        return ({ startDate: startDate2, stopDate: stopDate2 });
      case 'nextWeek':
        const startDate3 = dayjs().add(1, 'week').add(1, 'hour').startOf('hour').format(formatString);
        const stopDate3 = dayjs().add(1, 'week').add(2, 'hour').startOf('hour').format(formatString);
        return ({ startDate: startDate3, stopDate: stopDate3 });
      default:
        break;
    }
  }

  return (
    <Box
      sx={{
        p: '7px',
        flexGrow: 1,
      }}
    >
      <AddNewOrUpdateDialog
        open={openAddNew}
        viewToUpdate={viewToUpdate}
        handleCancel={() => setOpenAddNew(false)}
        handleAddNew={handleAddNew}
      />
      <ConfirmationDialog
        open={openConfirmDelete}
        message={`You are about to delete the current tab${views?.[tabIndex]?.label ? ' ' + views?.[tabIndex].label : ''}. Continue?`}
        onCancel={() => setOpenConfirmDelete(false)}
        onConfirmation={handleConfirmDelete}
      />
      {views ? (
        <Box
          sx={{
            borderBottom: 1,
            bgcolor: 'background.paper',
            p: '0px',
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            color="primary"
            sx={{ maxHeight: '40px', minHeight: '40px' }}
            backgroundColor="transparent"
          >
            {views?.map((view) => (
              <Tab
                sx={{ maxHeight: '40px', minHeight: '40px' }}
                backgroundColor="transparent"
                label={view.label}
                id={`tsr-activity-tab-${view.id}`}
              />
            ))}
            <Tab id='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
            <Tab id='editCurrentTab' disabled={!views?.[tabIndex]?.id} label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleEditClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
            <Tab id='deleteCurrentTab' disabled={!views?.[tabIndex]?.id} label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} />
          </Tabs>
          {views?.map((view, i) => (
            <Collapse in={tabIndex === i} orientation={'vertical'}>
              <DashboardView view={view} handleUpdate={handleUpdate} getDefaultDates={getDefaultDates} />
            </Collapse>
          ))}
          {views?.length === 0 &&
            <div style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='h6'>
                No views found. Click Add New to create a new view.
              </Typography>
            </div>
          }
        </Box>
      ) : (<div style={{ height: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>)}
    </Box>
  );
}