import { useState, useCallback, } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Box, IconButton, Tooltip, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DealHistoryGrid } from "./DealHistoryGrid";
import { useApi } from '../../useApi';
import debounce from 'lodash/debounce';
import { useFormContext } from 'react-hook-form';
import RestoreIcon from '@mui/icons-material/Restore';

export const DealHistoryDialog = ({ open, onClose, dealId, }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const { watch, } = useFormContext();
    const timezone = watch('timezone');
    const { post, enqueueSnackbar, headers, apiUrlPrefix } = useApi();

    function handleClose() {
        onClose();
        setSelectedRow(null);
    }

    const handleRollback = useCallback(debounce(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_RollbackDealProfile`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRow.dealID}`
            + `&parm=${timezone}`

        return post(url, selectedRow.sparklineJson).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar('Rollback successful', { variant: 'success' });
            }
        });
    }, 1000), [selectedRow, timezone]);

    function rollbackAndClose() {
        handleClose();
        handleRollback();
    }

    return (
        <Dialog
            open={!!open}
            onClose={handleClose}
            fullWidth
            maxWidth='xl'
        >
            <DialogTitle sx={{ py: 0.25 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Deal History for {dealId}</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '75vh', py: 0, px: 1, }}>
                <DealHistoryGrid
                    dealId={dealId}
                    setSelectedRow={setSelectedRow}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Box sx={{ flexGrow: 1, }} />
                <Tooltip title="Replace the deal's current profile with the selected profile from the past. Price will also be rolled back." arrow position="bottom">
                    <span>
                        <Button
                            startIcon={<RestoreIcon />}
                            onClick={rollbackAndClose}
                            color="primary"
                            variant='contained'
                            disabled={!selectedRow}
                        >
                            Rollback Profile & Price
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
};
