import { IconButton, TextField, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { StyledDatetime } from './StyledDatePicker';
import moment from 'moment';

export default (props) => {
  const { initialValue, onChange, disabled, ...textFieldProps } = props;
  const [open, setOpen] = useState(false);
  const [value, setDateValue] = useState('');
  const formatString = 'MM/DD/YYYY HH:mm:ss.SSS';

  useEffect(() => {
    initialValue && setDateValue(moment(initialValue).format(formatString));
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, alignItems: 'center' }}>
      <StyledDatetime
        initialValue={moment(initialValue)}
        closeOnSelect
        onClose={() => setOpen(false)}
        closeOnClickOutside={true}
        timeFormat='HH:mm:ss.SSS'
        onChange={(newVal) => {
          const strVal = newVal.format(formatString);
          setDateValue(strVal);
          onChange(newVal);
        }}
        renderInput={(props, openCalendar, closeCalendar) =>
          <IconButton
            disabled={disabled}
            onClick={() => {
              open ? closeCalendar() : openCalendar();
              setOpen(!open);
            }}
          >
            <CalendarMonthIcon />
          </IconButton>
        }
      />
      <TextField
        {...textFieldProps}
        value={value}
        disabled={disabled || open}
        onChange={(e) => {
          const newValue = e.target.value;
          if (moment(newValue).isValid()) {
            onChange(moment(newValue));
          }
          setDateValue(newValue)
        }}
      />
    </Box>

  )
}