import { AgGridReact } from "ag-grid-react";
import moment from 'moment';
import { Box, } from "@mui/material";
import { useMemo, } from 'react'
import momentTimezones from "../momentTimezones";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { ReservationActionForm } from "../Forms/ReservationActionForm";
import { useTheme } from '@mui/material/styles';
import { useUserGroups } from "../../data/useUserGroups";
import { userGroups } from "../../authConfig";
import { object, string, array, date, } from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import dayjs from '../dayjs-tz';
import { useSnackbar } from "notistack";

export default (props) => {
  const { data, visible, onSubmit, timezone, tabNum, defaultDateTime, defaultAction = 'reservenow', disableChangeAction, } = props;
  const { userIsInGroup } = useUserGroups();
  const proUser = userIsInGroup(userGroups.fastpathpro)
  const theme = useTheme();
  const palette = theme.palette.primary;
  const twoMinutesFromNow = dayjs().tz(momentTimezones[timezone]).add(2, 'minute').startOf('minute');
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(() => ({
    action: defaultAction,
    targetExecutionTime: defaultDateTime ? dayjs(defaultDateTime) : twoMinutesFromNow,
  }), []);

  const schema = object().shape({
    action: string().required('Please select an action.'),
    targetExecutionTime: date().when(['action', 'timezone'], (dependencies, schema) => {
      const [action, timezone] = dependencies;
      const tzone = momentTimezones[timezone];
      const minDate = dayjs().tz(tzone).add(1, 'minute');
      return (action === 'reservefuture')
        ? schema
          .typeError('Must be a valid date')
          .required('Target Execution Time is required')
          .test('isInFuture', 'Date must be at least 1 minute in the future', date => {
            return dayjs(date).tz(tzone).isAfter(minDate);
          })
        : schema
    }),
  });

  const { control, formState: { errors, }, watch, setValue, handleSubmit, } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  function handleUpdateAction(action) {
    if (!defaultDateTime) {
      setValue('targetExecutionTime', twoMinutesFromNow);
    }
    setValue('action', action);
  }

  const formFields = useMemo(() => [
    { name: 'Provider', key: 'TP' },
    { name: 'POR', key: 'PointOfReceipt' },
    { name: 'POD', key: 'PointOfDelivery' },
    { name: 'Path Name', key: 'PathName' },
    { name: 'Source', key: 'Source' },
    { name: 'Sink', key: 'Sink' },
    { name: 'Service Increment', key: 'ServiceIncrement' },
    { name: 'TS Class', key: 'TSClass' },
    { name: 'TS Type', key: 'TSType' },
    { name: 'TS Period', key: 'TSPeriod' },
    { name: 'TS Window', key: 'TSWindow' },
    { name: 'TS Subclass', key: 'TSSubclass' },
    { name: 'Preconfirmed', key: 'Preconfirmed' },
    { name: 'Profile (MW)', key: 'profileInfo' },
    { name: 'Profile ($)', key: 'profileInfo' },
  ], [])

  function cellRendererSelector(params) {
    if (params.data.name.includes('Profile')) {
      return {
        component: 'agSparklineCellRenderer',
      }
    } else {
      return null;
    }
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.yValue <= 0 ? palette.red : palette.green,
      stroke: params.yValue <= 0 ? palette.red : palette.green,
    };
  };

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      content: yValue,
    }
  }

  function configureProfileSparkline(params) {
    const info = params.data[params.column.colId];
    const atcData = info?.reduce((profile, block, idx) => {
      const endDate = new Date(block.endDateTime);
      const startDate = new Date(block.startDateTime);
      const capacity = parseInt(block.capacityRequested);
      const price = block.price;
      const nextBlockData = [];
      if (idx > 0) {
        const prevBlock = info[idx - 1]; //fill in gaps in blocks with 0 capacity
        if (Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
          nextBlockData.push({
            x: new Date(prevBlock.endDateTime),
            y: {
              price: 0,
              capacity: 0,
            },
          })
          nextBlockData.push({
            x: new Date(startDate - 1000),
            y: {
              price: 0,
              capacity: 0,
            },
          })
        }
      }
      nextBlockData.push({
        x: startDate,
        y: {
          price: price,
          capacity: capacity,
        },
      })
      nextBlockData.push({
        x: new Date(endDate - 1000),
        y: {
          price: price,
          capacity: capacity,
        },
      })
      return [...profile, ...nextBlockData]
    }, []);
    return atcData ?? [];
  }

  const colDefs = useMemo(() => [
    {
      field: 'name',
      headerName: '',
      flex: 1,
    },
    ...[...Object.keys(data)].map(idx => ({
      field: idx,
      headerName: `${data[idx].PointOfReceipt} -> ${data[idx].PointOfDelivery}`,
      cellRendererSelector: cellRendererSelector,
      valueGetter: (params) => {
        if (params.data.name.includes('Profile ($)')) {
          return configureProfileSparkline(params).map((point) => ({
            ...point,
            y: parseFloat(point.y.price),
          }));
        } else if (params.data.name.includes('Profile (MW)')) {
          return configureProfileSparkline(params).map((point) => ({
            ...point,
            y: point.y.capacity,
          }));
        } else {
          return params.data[params.column.colId];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: params => tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    }))
  ], [visible])

  const rowData = useMemo(() => formFields.map(field => ({
    ...[...Object.keys(data)].reduce((row, idx) => ({
      ...row,
      [idx]: data[idx][field.key],
    }), { name: field.name })
  })), [visible])

  const defaultColDef = useMemo(() => ({
    resizable: true,
    flex: 2,
  }), [])

  function onError(errors) {
    const firstError = Object.keys(errors)[0];
    enqueueSnackbar(errors[firstError].message, { variant: 'error' });
  }

  return (
    <Box sx={{ display: 'flex', p: 1, flexDirection: 'column', }}>
      <AgGridContainer
        style={{ width: "100%", height: '100%', fontSize: '10px' }}
      >
        <AgGridReact
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
        />
      </AgGridContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)} id={`tsr-transmission-form-${tabNum}`}>
        <ReservationActionForm
          timezone={timezone}
          disabled={disableChangeAction || !proUser}
          handleActionChange={handleUpdateAction}
          control={control}
          errors={errors}
          action={watch('action')}
        />
      </form>
    </Box>
  )
}