import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Collapse, } from "@mui/material"
import ConfirmationDialog from '../ConfirmationDialog';
import renderTimezones from "../renderTimezones";
import ProfileDialog from "../TimedTSRs/ProfileDialog";
import { FormControl, Button, Box, TextField, Grid, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, useCallback, } from 'react'
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { apiUrlPrefix } from "../../../authConfig";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import useFilteredOptions from "../useFilteredOptions";
import RulesDialog from "../TimedTSRs/RulesDialog";
import { useSnackbar } from "notistack";
import { logAction } from "../../../utils/auditLogger";
import moment from "moment";
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, date, array, number } from 'yup';
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormCheckbox from "../../FormControls/FormCheckbox";

export default (props) => {
  const { open, closeDialog, tsrs, handleSave, arefs, enableChangeAref = false, configStorageKey, } = props;
  const [showMore, setShowMore] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openRulesDialog, setOpenRulesDialog] = useState(false);
  const guid = useLocalGuid();
  const fetchRulesUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_DispatchQueueTriggerRulesFetch&parm=${guid}`
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const storedCustomerCode = configStorageKey ? localStorage.getItem(configStorageKey) : ''
  const [disableAll, setDisableAll] = useState(false);

  const defaultLabel = (tsr) => {
    if (tsr.label) {
      return tsr.label;
    } else if (tsr.PathName) {
      return tsr.PathName;
    } else {
      return `${tsr.Provider}-${tsr.PointOfReceipt}-${tsr.PointOfDelivery}`
    }
  }

  const defaultValues = useMemo(() => {
    let newTsr = {
      timezone: defaultTimeZone(),
    }
    if (tsrs?.length === 1) {
      const tsr = tsrs[0];
      newTsr = {
        arefs: arefs,
        ...newTsr,
        ...tsr,
        label: defaultLabel(tsr),
        relatedRef: tsr['Related Ref'] ?? tsr.relatedRef,
        profileConfig: tsr.profileConfig ?? createProfileConfig(tsr),
        Path_Name: tsr.Path_Name ?? tsr.PathName,
        RequestType: tsr.RequestType ?? tsr.requestType,
        Preconfirmed: tsr.Preconfirmed === 'Yes' ? true : false,
      }
    } else {
      newTsr.arefs = arefs;
    }
    if (configStorageKey) { newTsr.CustomerCode = storedCustomerCode; }
    return newTsr;
  }, [tsrs, arefs]);

  const schema = object().shape({
    CustomerCode: string().required('Customer Code is required'),
    RequestType: string().required('Request Type is required.'),
    timezone: string().required('Timezone is required.'),
    Provider: string().required('Provider is required.'),
    PointOfReceipt: string().required('POR is required.'),
    PointOfDelivery: string().required('POD is required.'),
    ServiceIncrement: string().required('Service Increment is required.'),
    TSClass: string().required('TS Class is required.'),
    TSPeriod: string().required('TS Period is required.'),
    TSWindow: string().required('TS Window is required.'),
    TSType: string().required('TS Type is required.'),
    profileInfo: array().min(1, 'Please create a profile.'),
    dispatchQueueRuleID: number().required('Please select a rule.'),
  });

  const { control, handleSubmit, setValue, formState: { errors, }, reset, getValues, register, watch, } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const watchFields = ['Provider', 'PointOfReceipt', 'PointOfDelivery', 'Source', 'Sink', 'Path_Name',];
  const formValues = watchFields.reduce((acc, field) => {
    acc[field] = getValues(field);
    return acc;
  }, {});

  const { providers, filteredCombos, filteredTSCombos, customerCodes, statuses, relatedRefs } = useFilteredOptions(formValues);

  useEffect(() => {
    reset(defaultValues);
  }, [tsrs, arefs]);

  useEffect(() => {
    if (tsrs?.length === 1) {
      const tsr = tsrs[0];
      setDisableAll(!!tsr.statusChangeTime && !enableChangeAref);
    }
  }, [tsrs, enableChangeAref]);

  function handleEditProfile() {
    setOpenProfileDialog(true);
  }

  function handleSetRule() {
    setOpenRulesDialog(true);
  }

  function handleProfileUpdate(info, config) {
    setValue('profileInfo', info);
    setValue('profileConfig', config);
  }

  function handleRulesDialogClose(rule) {
    setValue('dispatchQueueRuleID', rule.dispatchQueueRuleID);
    setOpenRulesDialog(false);
  }

  function onSubmit(data) {
    configStorageKey && localStorage.setItem(configStorageKey, data.CustomerCode);
    const tsr = formatEventJson(data);
    setOpenConfirmationDialog(true);
    setLoadingConfirmation(true);
    handleSave(tsr).then(response => {
      if (response) {
        setConfirmationMessage(response.data);
        setLoadingConfirmation(false);
      }
    });
  }

  function onError(errors) {
    if (errors.profileInfo) {
      enqueueSnackbar(`${errors.profileInfo.message}`, { variant: 'error' });
    } else if (errors.dispatchQueueRuleID) {
      enqueueSnackbar(`${errors.dispatchQueueRuleID.message}`, { variant: 'error' });
    } else {
      const keys = Object.keys(errors);
      const firstError = keys[0];
      enqueueSnackbar(`${errors[firstError].message}`, { variant: 'error' });
    }
  }

  const handleConfirmation = useCallback(debounce((data) => {
    closeDialog();
    const tsr = formatEventJson(data);
    handleSave(tsr, 1).then(response => {
      enqueueSnackbar('TSR trigger set successfully.');
      logAction('User confirmed TSR status trigger', 'Trigger Dialog', confirmationMessage);
      setOpenConfirmationDialog(false);
    })
  }, 2000, { leading: true, }), [openConfirmationDialog])

  function createProfileConfig(tsr) {
    const info = tsr.profileInfo;
    if (info) {
      const startDates = info.map(block => moment(block.startDateTime));
      const endDates = info.map(block => moment(block.endDateTime));
      const startDate = startDates.sort((a, b) => a - b)[0];
      const endDate = endDates.sort((a, b) => b - a)[0];
      return {
        startDate: startDate.format('MM/DD/YYYY'),
        startHour: startDate.format('HH'),
        stopDate: endDate.format('MM/DD/YYYY'),
        stopHour: endDate.format('HH'),
        defaultPrice: info[0] ? info[0].price : 0,
        defaultCapacity: info[0] ? info[0].capacityRequested : 0,
      };
    }
  }

  function defaultTimeZone() {
    //const tzs = moment.tz.zonesForCountry('US');
    const guess = moment.tz.guess();
    const tz = moment.tz(moment().toString(), guess).format('z');
    switch (tz.substring(0, 1)) {
      case 'P': return 'US/Pacific';
      case 'M': return 'US/Mountain';
      case 'C': return 'US/Central';
      case 'E': return 'US/Eastern';
      default: return 'US/Pacific';
    }
  }

  function formatEventJson(event) {
    return {
      Id: event.Id,
      arefs: event.arefs,
      label: event.label,
      'SequenceId': 1,
      'jsonVersion': 2,
      'Customer_Comments': event.CUSTOMER_COMMENTS,
      //'TargetExecutionTime': event.TargetExecutionTime,
      'Provider': event.Provider,
      'Customer Code': event.CustomerCode,
      'Sale Ref': event.SaleRef,
      //'Related Ref': '???',
      //'Deal Ref': '???',
      'Path_Name': event.Path_Name ?? event.PathName,
      'Source': event.Source,
      'Sink': event.Sink,
      'POR': event.PointOfReceipt,
      'POD': event.PointOfDelivery,
      //'Service': event.service,
      'Service_Increment': event.ServiceIncrement,
      'TS_Class': event.TSClass,
      'TS_Type': event.TSType,
      'TS_Period': event.TSPeriod,
      'TS_Window': event.TSWindow,
      'TS_Subclass': event.TSSubclass,
      'Preconfirmed': event.Preconfirmed ? 'Yes' : 'No',
      'profileInfo': event.profileInfo,
      'profileConfig': event.profileConfig,
      'timezone': event.timezone,
      'dispatchQueueRuleID': event.dispatchQueueRuleID,
      'requestType': event.RequestType,
      'Related Ref': event.relatedRef,
      //'ruleParams': event.ruleParams,
    }
  }

  function getRelatedRefHelperText() {
    const relatedRef = getValues('relatedRef');
    const obj = relatedRefs.find(option => option.AssignmentRef === relatedRef);
    return obj ? `POR: ${obj.PointOfReceipt} - POD: ${obj.PointOfDelivery}` : '';
  }

  function handleCancel() {
    closeDialog();
    setShowMore(false);
  }

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth>
      <DialogTitle></DialogTitle>
      <DialogContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {openProfileDialog && <ProfileDialog
          open={openProfileDialog}
          closeDialog={() => setOpenProfileDialog(false)}
          currentEvent={{
            profileInfo: getValues('profileInfo'),
            profileConfig: getValues('profileConfig'),
            Provider: getValues('Provider'),
            Service_Increment: getValues('Service_Increment'),
          }}
          handleProfileUpdate={handleProfileUpdate}
          disabled={disableAll}
        />}
        <RulesDialog
          open={openRulesDialog}
          onClose={handleRulesDialogClose}
          onCancel={() => setOpenRulesDialog(false)}
          ruleId={getValues('dispatchQueueRuleID')}
          fetchUri={fetchRulesUri}
          disabled={disableAll}
        />
        <ConfirmationDialog
          open={openConfirmationDialog}
          message={confirmationMessage}
          loading={loadingConfirmation}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirmation={handleSubmit(handleConfirmation)}
          cancelText={'CANCEL'}
          confirmText={'SET TRIGGER'}
        />
        <form onSubmit={handleSubmit(onSubmit, onError)} id='stalker-trigger-on-change-dialog'>
          <Box sx={{ p: 1, }}>
            <Collapse in={showMore} timeout="auto">
              <Grid container justifyItems='start' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<ChevronLeftIcon />}
                    variant="contained"
                    size='small'
                    onClick={() => setShowMore(false)}
                  >Back</Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register('CUSTOMER_COMMENTS')}
                    fullWidth
                    multiline
                    placeholder="Customer Comments"
                    rows={5}
                    variant='outlined'
                    label='Customer Comments'
                    disabled={disableAll}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...register('SaleRef')}
                    label={'Sales Ref'}
                    size='small'
                    fullWidth
                    disabled={disableAll}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name='TSSubclass'
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsSubclasses}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Subclass"
                        color="success"
                        placeholder="TS Subclass"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormAutocomplete
                    name='relatedRef'
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={relatedRefs.map(obj => obj.AssignmentRef)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Related Ref"
                        color="success"
                        helperText={getRelatedRefHelperText()}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={!showMore} timeout="auto">
              <Grid container justifyContent="center" alignItems='center' spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    {...register('arefs')}
                    label={'Arefs'}
                    size='small'
                    disabled={!enableChangeAref || disableAll}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    endIcon={<ChevronRightIcon />}
                    variant="contained"
                    fullWidth
                    onClick={() => setShowMore(true)}
                  >More</Button>
                </Grid>
                <Grid item xs={12}>
                  <Tooltip title="User friendly label for the TSR, optional." arrow placement="top">
                    <TextField
                      {...register('label')}
                      size="small"
                      fullWidth
                      variant="outlined"
                      label="Label"
                      color="success"
                      placeholder="Label"
                      disabled={disableAll}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'timezone'}
                    control={control}
                    fullWidth
                    disabled={disableAll}
                    getOptionLabel={(option) => renderTimezones[option]}
                    options={Object.keys(renderTimezones).filter(tz => tz.includes('US'))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Timezone"
                        size='small'
                        color="success"
                        placeholder="Timezone"
                        error={!!errors.timezone}
                        helperText={errors.timezone?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormAutocomplete
                    name={'Provider'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={providers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Provider"
                        color="success"
                        placeholder="Provider"
                        error={!!errors.Provider}
                        helperText={errors.Provider?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'PointOfReceipt'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredCombos.pors}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POR"
                        color="success"
                        placeholder="POR"
                        error={!!errors.PointOfReceipt}
                        helperText={errors.PointOfReceipt?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'PointOfDelivery'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredCombos.pods}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POD"
                        color="success"
                        placeholder="POD"
                        error={!!errors.PointOfDelivery}
                        helperText={errors.PointOfDelivery?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'Source'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={filteredCombos.sources}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Source"
                        color="success"
                        placeholder="Source"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'Sink'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={filteredCombos.sinks}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Sink"
                        color="success"
                        placeholder="Sink"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormAutocomplete
                    name={'Path_Name'}
                    control={control}
                    fullWidth
                    options={filteredCombos.paths}
                    freeSolo
                    disabled={disableAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Path Name"
                        color="success"
                        placeholder="Path Name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormAutocomplete
                    name={'ServiceIncrement'}
                    control={control}
                    options={filteredTSCombos.serviceIncrements}
                    disabled={disableAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Service Increment"
                        size='small'
                        color="success"
                        placeholder="Service Increment"
                        error={!!errors.ServiceIncrement}
                        helperText={errors.ServiceIncrement?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormAutocomplete
                    name={'RequestType'}
                    control={control}
                    options={['Original', 'Redirect']}
                    disabled={disableAll}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Request Type"
                        size='small'
                        color="success"
                        error={!!errors.RequestType}
                        helperText={errors.RequestType?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'TSClass'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsClasses}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Class"
                        color="success"
                        placeholder="TS Class"
                        error={!!errors.TSClass}
                        helperText={errors.TSClass?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'TSType'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Type"
                        color="success"
                        placeholder="TS Type"
                        error={!!errors.TSType}
                        helperText={errors.TSType?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'TSPeriod'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsPeriods}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Period"
                        color="success"
                        placeholder="TS Period"
                        error={!!errors.TSPeriod}
                        helperText={errors.TSPeriod?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormAutocomplete
                    name={'TSWindow'}
                    control={control}
                    fullWidth
                    freeSolo
                    disabled={disableAll}
                    options={filteredTSCombos.tsWindows}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Window"
                        color="success"
                        placeholder="TS Window"
                        error={!!errors.TSWindow}
                        helperText={errors.TSWindow?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth size="small">
                    <FormControlLabel label={'Preconfirmed'} control={
                      <FormCheckbox name='Preconfirmed' control={control} disabled={disableAll} />
                    } />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormAutocomplete
                    name={'CustomerCode'}
                    control={control}
                    freeSolo
                    fullWidth
                    disabled={disableAll}
                    options={customerCodes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Customer Code"
                        color="success"
                        placeholder="Customer Code"
                        error={!!errors.CustomerCode}
                        helperText={errors.CustomerCode?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={handleEditProfile}
                    variant="contained"
                    fullWidth
                  >Edit Profile</Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={handleSetRule}
                    variant="contained"
                    fullWidth
                  >Set Rule</Button>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" disabled={disableAll} type="submit" form='stalker-trigger-on-change-dialog'>Save & Close</Button>
      </DialogActions>
    </Dialog>
  )
}