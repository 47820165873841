import { useEffect, useState } from "react";
import { apiUrlPrefix, subscriptionKey } from "../authConfig";
import { useLocalGuid } from "../data/UserGuidContext/useLocalGuid";
import { useActionAudit } from './useActionAudit';
import axios from "axios";
// import { StyledTableCell, StyledTableRow } from "./StyledTableComps";
// import { Paper, TableBody, TableContainer, Table, TextField, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses, Tooltip } from "@mui/material";
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSnackbar } from "notistack";
import useHeader from "./useHeader";

export const useTemplateTable = (transform, templateTypeId, gridRef, targetGridRef, tableId='', sourceGridName, targetGridName,) => {
    const [templates, setTemplates] = useState([]);
    const headers = useHeader();
    const guid = useLocalGuid();
    const { logAction } = useActionAudit();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      

      let abortController = new AbortController();  
      // your async action is here  
      
      if(templateTypeId && guid) {
        loadTemplates();
      }
      
      return () => {  
      abortController.abort();  
      }  
      
    }, [guid]);

    useEffect(() => {
      if(templates.length > 0 && gridRef) {
        templates.forEach(temp => {
          addDropZone(temp.id, (params) => transformRows(temp, params))
        });
      }
    }, [templates, gridRef]);

    function transformRows(template, dragParams) {
      const node = dragParams.node;
      if(node.selected) {
        const rows = gridRef.current.api.getSelectedRows();
        let newRows = rows.map(row => transform(row, template.data));
        targetGridRef.current.api.applyTransaction({
          add: newRows,
          addIndex: 0,
        });
      }
      else {
        const newRow = transform(node.data, template.data);
        targetGridRef.current.api.applyTransaction({
          add: [newRow],
          addIndex: 0,
        });
      }
    }
  
    function addDropZone(id, onDragStop) {
      const dropTarget = document.querySelector(`#drop-zone-${tableId}${id}`);
      const dropZone = {
        getContainer: function () {
          return dropTarget;
        },
        onDragStop: onDragStop,
      }

      try {
        gridRef.current.api?.removeRowDropZone(dropZone);
      } finally {
        gridRef.current.api?.addRowDropZone(dropZone);
      }
    }

    function addGridDropZone(selector, rowTransform, ref, targetRef) {
      const target = document.querySelector(selector);
      const dropZone = {
        getContainer: function () {
          return target;
        },
        onDragStop: function(params) {
          const newRow = rowTransform(params.node.data, undefined);
          targetRef.current.api.applyTransaction({
            add: [newRow],
          });
        }
      };
      
      ref.current.api?.addRowDropZone(dropZone);
    }

    async function loadTemplates() {
      const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateFetch&parm=${guid}&parm=${templateTypeId}`
      let options = {
        headers: headers,
        method: 'GET',
        url: url, 
      };

      axios(options).then(response => {
        setTemplates(response.data.map(temp => 
          newTemplate(temp.transmissionTemplateID, JSON.parse(temp.json), temp.name)
        ));
      }).catch(err => { 
          enqueueSnackbar(`Error loading templates. ID: ${templateTypeId}  URL: ${url} Error:  ${err}`) 
      });
      
    };
  
    async function saveTemplate(template) {
      const json = JSON.stringify(template.data);
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateInsert&parm=${guid}&parm=${encodeURIComponent(json)}&parm=${templateTypeId}&parm=${template.name}`, 
      };
      const response = axios(options).catch(error => { enqueueSnackbar(`Error saving a template. ${error}`) });
      
      return(response);

    }
  
    function deleteTemplate(tempName) {
      let message = `Template ${tempName} deleted.`
      const options = {
        headers: headers,
        url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateDelete&parm=${guid}&parm=${templateTypeId}&parm=${tempName}`, 
      };
  
      axios(options).then(() => enqueueSnackbar(message)).catch(error => { enqueueSnackbar(error.message) });
  
      setTemplates(templates.filter(temp => temp.name !== tempName));
    }

    function addTemplate(newTemplate) {
      saveTemplate(newTemplate).then(response => {
          enqueueSnackbar('Template added/saved.');
          logAction(JSON.stringify(newTemplate), `${targetGridName} template saved`);
          loadTemplates();
        }
      );
    }

    function onTemplateClick(temp) {
        const rows = gridRef.current.api.getSelectedRows();
        
        if(!rows?.length > 0) {
          enqueueSnackbar(`Please select rows from ${sourceGridName}.`);
          return;
        }
    
        let newRows = rows.map(row => transform(row, temp.data));
        targetGridRef.current.api.applyTransaction({add: newRows});
    
        logAction(`${temp.name} template clicked.`, `Template Type Id: ${templateTypeId}`);
    };

    function newTemplate(id, data, name) {
      return {
        id: id,
        data: data,
        name: name,
      };
    }

    return {
        templates,
        loadTemplates,
        saveTemplate,
        addTemplate,
        deleteTemplate,
        onTemplateClick,
        newTemplate,
        addDropZone,
        addGridDropZone,
        transformRows
    };
}