import { useMemo } from "react";
import { AgChartsReact } from "ag-charts-react";
import moment from 'moment';
import dayjs from "dayjs";
import { useTheme } from "@mui/material";

export const ProfileChart = ({ curtailmentProfile, selectedPaths, }) => {
    const theme = useTheme();
    const palette = theme.palette.primary;

    const chartData = useMemo(() => {
        const points = curtailmentProfile.map(block => ({ date: block.startDateTimeUtc, curtailment: block.MWLevel }));
        const dataPoints = points.reduce((data, point) => {
            const dataPoint = { ...point };
            selectedPaths.forEach(profile => {
                const capacity = profile.profileMap.get(point.date);
                if (capacity) {
                    dataPoint[profile.key] = capacity;
                }
            });
            return [...data, dataPoint];
        }, []);
        const allData = dataPoints.reduce((acc, next) => {
            const startDate = dayjs(next.date).startOf('hour');
            const endDate = startDate.add(1, 'hour');
            acc.push({ ...next, date: startDate.toDate(), });
            acc.push({ ...next, date: endDate.toDate(), });
            return acc;
        }, []);
        return allData;
    }, [curtailmentProfile, selectedPaths]);

    const tooltipRenderer = (params) => {
        return { content: `Time: ${dayjs(params.datum.date).format('MM/DD/YYYY HH:mm')}<br/>Capacity: ${params.datum[params.yKey]}` };
    }

    const chartOptions = useMemo(() => ({
        data: chartData,
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
                strokes: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? '#2d3436' : theme.palette.primary.white,
        },
        series: [{
            xKey: 'date',
            yKey: 'curtailment',
            yName: 'Curtailment',
            //type: 'area',
            type: 'line',
            marker: {
                enabled: true,
            },
            tooltip: {
                renderer: tooltipRenderer,
            },
        },
        ...selectedPaths.map(path => ({
            xKey: 'date',
            yKey: `${path.key}`,
            yName: path.name,
            type: 'area',
            stacked: true,
            tooltip: {
                renderer: tooltipRenderer,
            },
        })),
        ],
        axes: [
            {
                type: 'time',
                position: 'bottom',
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: 'Capacity Requested',
                },
            },
        ],
        legend: {
            position: 'right',
        },
    }), [chartData, theme.palette.mode]);

    return (
        <AgChartsReact options={chartOptions} />
    );
}