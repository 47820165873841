import { QuickScheduleDealButton } from "../../RibbonButtons/QuickScheduleDealButton";
import { RibbonGroupContainer } from "../../Ribbon";
import { useUserGroups } from "../../../../data/useUserGroups";
import { userGroups } from "../../../../authConfig.js";

export const TagActionsGroup = ({ expanded, selectedTag, gridRef, handleFetchData }) => {
    const { userIsInGroup } = useUserGroups();
    const isPlatinumUser = userIsInGroup(userGroups.dealrizzplatinum);
    const type = selectedTag?.type;
    const allowQuickScheduleDeal = isPlatinumUser && (type === 'Unscheduled Tag');

    return (
        <RibbonGroupContainer>

            <QuickScheduleDealButton
                expanded={expanded}
                tag={selectedTag}
                disabled={!allowQuickScheduleDeal}
                onRefresh={handleFetchData}
                gridRef={gridRef}
            />

        </RibbonGroupContainer>
    );
}
