import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Slide, } from "@mui/material"
import { memo, } from "react";
import { TsrAlertForm } from "../Forms/TsrAlertForm";
import CriteriaProvider from "../CriteriaContext/CriteriaProvider";

export const AlertDialog = ({ open, closeDialog, tsr = {}, application, handleUpdate, }) => {
    const defaultValues = {
        aref: tsr.aref,
        alertType: 'Text',
        currentStatus: tsr.currentStatus,
        alertStatus: tsr.alertStatus ?? 'ANY',
        locationOrigin: application,
    }

    function onSubmit(data) {
        handleUpdate(data);
        closeDialog();
    }

    return (
        <CriteriaProvider>
            <Dialog open={open} TransitionComponent={Slide} maxWidth={'md'}>
                <DialogTitle>Edit Alert</DialogTitle>
                <DialogContent sx={{ p: 1, }}>
                    <TsrAlertForm defaultValues={defaultValues} onSubmit={onSubmit} />
                </DialogContent>
                <DialogActions sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={closeDialog} variant="contained">Cancel</Button>
                    <Button type='submit' form='alert-dialog-form' variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
        </CriteriaProvider>
    )
}

export const EditTsrAlertDialog = memo(AlertDialog);