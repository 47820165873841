import { OverlayView,  } from "@react-google-maps/api";

export const CustomMarker = ({
  children,
  position,
  elevate,
  ...markerProps
}) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={elevate ? OverlayView.FLOAT_PANE : OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div {...markerProps}>
        {children}
      </div>
    </OverlayView>
  );
};