// import { subscriptionKey } from "../authConfig";
import { useSnackbar } from "notistack";
import { useState, useCallback } from "react";
import axios from "axios";
// import { debounce } from 'lodash';
import useHeader from "./useHeader";

export const useData = (defaultRowData=null) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rowData, setRowData] = useState(defaultRowData);
  const headers = useHeader();

  async function getData (url) {
    if(!url) {
      enqueueSnackbar('No url provided to getData function.');
    }
    
    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    }
  
    const response = axios(options).then(response => {
      
      //enqueueSnackbar(JSON.stringify(response));

      if(typeof response.data === 'string') {
        enqueueSnackbar(response.data)
      } else {
        setRowData(response.data);
      }

    }).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${url}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
      //enqueueSnackbar(JSON.stringify(response))
    });


    return(response); 
  }
    
  async function saveData(url, message='Grid data successfully saved.', data=rowData) {
    const response = axios.post(
      url,
      JSON.stringify(data),
      {
        headers: headers,
      }
    ).then( response => { 
      enqueueSnackbar(message); 
    }).catch(err => { enqueueSnackbar(`Error saving data. Status: ${err.response?.status}. Message: ${err}`); });
    
    //enqueueSnackbar(JSON.stringify(data));

    return(response); 
  };

  async function fetch(url) {
    if(!url) {
      enqueueSnackbar('No url provided to getData function.');
    }
    
    const options = {
      method: 'GET',
      headers: headers,
      url: url,
    }
  
    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${url}. ${error.message}`)
    });
  }

  return {
    rowData,
    setRowData,
    getData,
    saveData,
    fetch,
  };
};
