import { IconButton, Tooltip, Card, CardHeader, Divider, List, Box, Typography } from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever";
import styled from "@emotion/styled";

export const TargetItemList = ({ items = [], title, removeItem, removeAll, overlayText, }) => {
    return (
        <Card elevation={5} sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CardHeader
                sx={{ px: 2, height: 55, }}
                title={title}
                action={
                    <IconButton
                        onClick={() => removeAll()}
                        size="small"
                    >
                        <Tooltip title="Remove All">
                            <DeleteForever />
                        </Tooltip>
                    </IconButton>
                }
            />
            <Divider />
            <List
                sx={{
                    overflow: 'auto',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                dense
                component="div"
                role="list"
            >
                <NoDataOverlay visible={items.length === 0} text={overlayText} />
                {items.map((item) =>
                    <Box key={`item-${JSON.stringify(item)}`} component='li' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1, px: 1, maxHeight: 34 }}>
                        <Typography fontSize={12} width='100%'>{item.name}</Typography>
                        <IconButton size='small' onClick={() => removeItem(item)}>
                            <DeleteForever />
                        </IconButton>
                    </Box>
                )}
            </List>
        </Card >
    );
}

const NoDataOverlay = ({ visible, text }) => {
    return (
        <OverlayContainer visible={visible}>
            <Typography align='center' variant='p'>{text}</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div')(({ theme, visible }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));