import { useState, useEffect, } from 'react';
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import { apiUrlPrefix } from "../../authConfig";
import axios from "axios";
import { Box, } from "@mui/material";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import MarketplaceMenu from './MarketplaceMenu';
import Postings from './Postings';
import Help from '../Help';

export default () => {
  const [postingData, setPostingData] = useState([]);
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [criteria, setCriteria] = useState({});
  const [openAddNewDialog, setOpenAddNewDialog] = useState(false);

  const viewKey = 'marketplace-saved-view';
  const savedView = localStorage.getItem(viewKey);
  const [view, setView] = useState(savedView ?? 'browseall')
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (view) {
      if (!loadedViews[view]) {
        setLoadedViews({
          ...loadedViews,
          [view]: true
        })
      }
      localStorage.setItem(viewKey, view);
    }
  }, [view]);

  useEffect(() => {
    if (guid) {
      fetch().then(response => {
        setPostingData(response.data);
      });
    }
  }, [guid])

  async function fetch() {
    const uri = ['timezone', 'provider', 'por', 'pod', 'sellerCode', 'serviceIncrement', 'serviceClass', 'startDate', 'startHour', 'stopDate', 'stopHour'].reduce((url, next) => {
      return url + `&parm=${criteria[next] ?? ''}`
    }, `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_transPostingFetch&parm=${guid}`)

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
    });
  }

  const collapseView = (views) => {
    return views.includes(view) ? 'block' : 'hidden';
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <MarketplaceMenu selected={view} handleSelection={(selected) => setView(selected)} />
      {(loadedViews['marketplacehelp']) && <div style={{ display: collapseView(['marketplacehelp']) }}>
        <Help appName={'Marketplace'} />
      </div>}
      {(loadedViews['browseall'] || loadedViews['mypostings']) && <div style={{ display: collapseView(['browseall', 'mypostings']), width: '100%' }}>
        <Postings allData={postingData} handleAddNew={() => setOpenAddNewDialog(true)} view={view} />
      </div>}
    </Box>
  )
}