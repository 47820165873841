import { useTheme } from '@emotion/react';
import { Box, Stack, Typography, IconButton, Autocomplete, TextField, Button } from '@mui/material'
import { Add, Remove, } from '@mui/icons-material';
import { forwardRef, memo } from 'react';
import GroupGuard from '../../Guards/GroupGuard';
import { userGroups } from '../../../authConfig';

const GraphControls = forwardRef((props, ref) => {
    const { containerSx, layoutType, setLayoutType, } = props;
    const theme = useTheme();
    const layoutTypes = ['forceDirected2d', 'circular2d', 'hierarchicalTd', 'hierarchicalLr', 'nooverlap', 'forceatlas2', 'radialOut2d', 'treeLr2d', 'treeTd2d', 'forceDirected3d', 'radialOut3d', 'treeLr3d', 'treeTd3d',];

    const ZoomControl = () => {
        return (
            <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='caption' color='text.secondary'>Zoom: </Typography>
                <IconButton
                    onClick={() => ref.current?.zoomIn()}
                    size='small'
                    sx={{ borderRadius: '4px' }}
                ><Add /></IconButton>
                <IconButton
                    onClick={() => ref.current.zoomOut()}
                    size='small'
                    sx={{ borderRadius: '4px' }}
                ><Remove /></IconButton>
                <Button
                    variant='outlined'
                    size='small'
                    onClick={() => ref.current?.centerGraph()}
                >Center</Button>
                <GroupGuard groups={[userGroups.admins]}>
                    <Autocomplete
                        autoHighlight
                        autoComplete
                        autoSelect
                        options={layoutTypes}
                        value={layoutType}
                        onChange={(_, newValue) => {
                            setLayoutType(newValue);
                        }}
                        groupBy={(option) => !option.includes('3d') ? '2D' : '3D'}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Layout Type"
                                size='small'
                                variant="standard"
                                sx={{ width: '200px' }}
                            />
                        )}
                    />
                </GroupGuard>
            </Stack >
        );
    };

    return (
        <Box sx={containerSx}>
            <ZoomControl />
        </Box>
    );
});

export default memo(GraphControls);