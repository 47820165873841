import { useRef } from "react";

export const useAbortController = () => {
    const abortControllerRef = useRef(new AbortController());

    function abort() {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();
    }

    const signal = abortControllerRef.current.signal;

    return {
        abortControllerRef,
        abort,
    }
}