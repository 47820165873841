import { CheckoutWorksheetView } from "./CheckoutWorksheetView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy";

export const CheckoutWorksheetDashboard = ({ visible, }) => {
    const dashboardKey = 'checkout-worksheet';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CheckoutWorksheetView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
