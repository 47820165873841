import { useState } from 'react';
import moment from 'moment';
import { useMemo, } from 'react'
import axios from "axios";
import { apiUrlPrefix, userGroups } from "../../authConfig";
import { useSnackbar } from "notistack";
import useHeader from "../useHeader";
import { renderStandardTimezones } from "../../utils/renderTimezones";
import dayjs from '../dayjs-tz'
import momentTimezones from "../momentTimezones";
import { MultiLegTransmissionDialog } from "../MultiLegTransmissionDialog";
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';

export const TransmissionDialog = (props) => {
  const { open, pathData, handleClose, searchCriteria = {}, } = props;
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const storageKey = `fast-path-transmission-dialog-east`;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmation, setOnConfirmation] = useState(() => () => { });

  const defaults = useMemo(() => {
    const newConfigs = {};
    const detailData = JSON.parse(pathData?.detailJson ?? pathData?.childPaths ?? '[]');
    const storedValueString = localStorage.getItem(storageKey);
    const storedValues = JSON.parse(storedValueString ?? '{}');
    const profile = JSON.parse(pathData?.maxAvailability ?? '[]').map(block => ({
      capacityRequested: parseInt(block.maxAvailability),
      startDateTime: moment(moment.parseZone(block.StartTime).format('MM/DD/YYYY HH:mm')),
      endDateTime: moment(moment.parseZone(block.StopTime).format('MM/DD/YYYY HH:mm')),
    }));
    detailData.forEach((leg, i) =>
      newConfigs[i] = {
        ...storedValues,
        ...leg,
        Provider: leg.TP,
        SaleRef: storedValues.SaleRef ?? leg.SaleRef ?? '',
        Service_Increment: storedValues.Service_Increment ?? leg.ServiceIncrement,
        timezone: renderStandardTimezones[searchCriteria.timezone],
        profileInfo: [],
        maxProfile: profile,
        profileConfig: {
          startDate: dayjs(profile[0]?.startDateTime),
          stopDate: dayjs(profile[profile.length - 1]?.endDateTime),
          defaultCapacity: pathData?.maxCapacity,
          defaultPriceData: leg.atcFuture,
          timezone: momentTimezones[searchCriteria.timezone],
          minDateTime: dayjs(profile[0]?.startDateTime),
          maxDateTime: dayjs(profile[profile.length - 1]?.endDateTime),
        }
      }
    );

    return {
      timezone: searchCriteria.timezone,
      action: 'reservenow',
      legs: newConfigs,
    };
  }, [pathData]);

  function handleSave(confirmed = 0, data, reservationInfo) {
    const params = {
      timeZone: searchCriteria.timezone,
      TargetExecutionTime: dayjs(reservationInfo.targetExecutionTime).tz(momentTimezones[searchCriteria.timezone]).format('MM/DD/YYYY HH:mm:ss') ?? '',
      application: `fastpathpro`,
      action: reservationInfo.action,
      data: data,
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${headers.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=${confirmed}&parm=${params.application}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    axios(options).then(response => {
      if (confirmed) {
        enqueueSnackbar('Success submitting TSR(s) to the queue for processing by OASIS.')
      } else {
        setConfirmationMessage(response.data);
        setOpenConfirmationDialog(true);
        setOnConfirmation(() => () => handleSave(1, data, reservationInfo));
      }
    }).catch(error => {
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
    });

    if (confirmed) {
      handleClose();
      setOpenConfirmationDialog(false);
    }
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={onConfirmation}
      />
      <MultiLegTransmissionDialog
        handleClose={handleClose}
        defaultData={defaults}
        open={open}
        handleSave={(data, actionInfo) => handleSave(0, data, actionInfo)}
        storageKey={storageKey}
        requireConfirmation
      />
    </>
  )
}