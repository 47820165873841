import { useState, useEffect, useMemo } from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { Button, Tooltip, Autocomplete, TextField, MenuItem, Stack,  } from '@mui/material';
import useHeader from '../useHeader';
import axios from 'axios';
import { apiUrlPrefix } from '../../authConfig';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { object, string, } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import FormDatePicker from '../FormControls/FormDatePicker';
import momentTimezones from '../momentTimezones';
import { useHubMessages } from '../HubContext/useHubMessages';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin

export default (props) => {
  const { handleRefresh, storageKey } = props;
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const hubMessage = useHubMessages();

  dayjs.extend(utc);
  dayjs.extend(tz);

  const schema = object().shape({
    timezone: string().required('Timezone is required.'),
    startDate: string().required('Start Date is required.'),
    hourEnding: string().required('Hour Ending is required.'),
  })

  const storedValues = useMemo(() => {
    return JSON.parse(localStorage.getItem(storageKey) ?? '{}');
  }, []);

  const defaultValues = useMemo(() => ({
    provider: 'BPAT',
    por: 'NOB',
    pod: 'BIGEDDY',
    attribute: 'All',
    timezone: 'Pacific Standard Time',
    duration: 'month',
    hourEnding: `1:00`,
    ...storedValues,
    startDate: dayjs().add(1, 'days').startOf('day'),
  }), []);

  const [porChoices, setPorChoices] = useState([defaultValues.por]);
  const [podChoices, setPodChoices] = useState([defaultValues.pod]);
  const [providerChoices, setProviderChoices] = useState([defaultValues.provider]);
  const [attributeChoices, setAttributeChoices] = useState([defaultValues.attribute]);

  const { handleSubmit, control, formState: { errors, }, } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  function onSubmit(withLog, data) {
    const { startDate, ...rest } = data;
    localStorage.setItem(storageKey, JSON.stringify(rest));
    handleRefresh(withLog, {
      startDate: dayjs(startDate).format('MM/DD/YYYY'),
      ...rest,
      duration: rest.duration,
      //timezone: renderStandardTimezones[rest.timezone],
    });
  }

  function submitWithoutLog(data) {
    onSubmit(false, data);
  }

  function submitWithLog(data) {
    onSubmit(true, data);
  }

  useEffect(() => {
    handleSubmit(submitWithLog)(); 
  }, [storageKey]);

  useEffect(() => {
    if(storageKey.includes('Availability Alerts') && ['trg_sysDataAlertTrigger'].includes(hubMessage.message)) {
      //console.log(`Hub message received in TransAlerts: ${hubMessage.message} Time: ${dayjs().format('HH:mm:ss.SSS')}`)
      handleSubmit(submitWithoutLog)(); 
    }
  }, [hubMessage])

  useEffect(() => {
    fetchPodSelectChoices();
    fetchPorSelectChoices();
    fetchProviderChoices();
    fetchAttributeChoices();
  }, [])

  const buttonVariant = "outlined";
  //const buttonColor = "primary";
  const buttonSize = "small";  

  async function fetchPorSelectChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_porChoiceFetch`,
    }
  
    axios(options).then(response => { 
      setPorChoices(response.data.map(choice => choice['por']));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });
  };

  async function fetchAttributeChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_attributeChoiceFetch`,
    }
  
    axios(options).then(response => { 
      setAttributeChoices(response.data.map(choice => choice.attribute));
    }).catch(error => {
      enqueueSnackbar(`Error fetching attribute options. Message: ${error}`)
    });
  };

  async function fetchProviderChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_providerChoiceFetch`,
    }
  
    axios(options).then(response => { 
      setProviderChoices(response.data.map(choice => choice['provider']));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });
  };

  async function fetchPodSelectChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_podChoiceFetch`,
    }
  
    axios(options).then(response => { 
      setPodChoices(response.data.map(choice => choice['pod']));
    }).catch(error => {
      enqueueSnackbar(`Error fetching autocomplete options. Message: ${error}`)
    });
  };

  const hourChoices = [...Array(24).keys()].map(idx => {
    const hour = idx+1;
    const hourValue = `${hour}:00`;
    return hourValue;
  });

  const durationOptions = {
    'hour': '1 Hour',
    'day': '1 Day',
    'week': '1 Week',
    'month': '1 Month',
    'year': '1 Year',
  };

  return(
    <form id='transhunter-availability-toolbar' onSubmit={handleSubmit(submitWithLog)}>
      <Stack direction="row" spacing={1} alignItems='center' sx={{ p: 1, }}>
        <Tooltip title="Select the timezone of interest.  It will be used in when submitting TSRs in the list below." arrow placement="top"><span>
          <FormAutocomplete
            getOptionLabel={(option) => option.split(' ')[0]}
            options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
            control={control}
            name='timezone'
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ minWidth: '150px' }}
                variant="outlined"
                label="Timezone"
                size={buttonSize}
                error={!!errors.timezone}
                helperText={errors.timezone?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Select system data attribute for available transmission capacity." arrow placement="left"><span>
          <FormAutocomplete
            control={control}
            name={'attribute'}
            size={buttonSize}
            options={['All', ...attributeChoices]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Attribute"
                sx={{ minWidth: '150px' }}
                size={buttonSize}
                error={!!errors.attribute}
                helperText={errors.attribute?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Optional filter on provider. Leave blank for all rows." arrow placement="top"><span>
          <FormAutocomplete
            control={control}
            name={'provider'}
            freeSolo
            options={providerChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Provider"
                sx={{ minWidth: '130px' }}
                size={buttonSize}
                variant={buttonVariant} 
                error={!!errors.provider}
                helperText={errors.provider?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Select your POR. Leave it blank for all PORs." arrow placement="top"><span>
          <FormAutocomplete
            control={control}
            name={'por'}
            freeSolo
            options={porChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="POR"
                sx={{ minWidth: '200px' }}
                size={buttonSize}
                variant={buttonVariant} 
                error={!!errors.por}
                helperText={errors.por?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Select your POD. Leave it blank for all PODs." arrow placement="top"><span>
          <FormAutocomplete
            control={control}
            name={'pod'}
            freeSolo
            options={podChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="POD"
                sx={{ minWidth: '200px' }}
                size={buttonSize}
                variant={buttonVariant} 
                error={!!errors.pod}
                helperText={errors.pod?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Select the start date for viewing available transmission." arrow placement="top"><span>
          <FormDatePicker
            name={'startDate'}
            control={control}
            label="Start Date"
            format="MM/DD/YYYY"
            slotProps={{ textField: { 
              size: 'small', 
              error: !!errors.startDate, 
              helperText: errors.startDate?.message, 
            }}}
          />
        </span></Tooltip>
        <Tooltip title="Select the starting hour ending for viewing available transmission." arrow placement="top"><span>
          <FormAutocomplete
            control={control}
            name={'hourEnding'}
            options={hourChoices}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Hour Ending"
                sx={{ minWidth: '130px' }}
                size={buttonSize}
                variant={buttonVariant} 
                error={!!errors.hourEnding}
                helperText={errors.hourEnding?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Select the number of hours in to the future we will be working with." arrow placement="top"><span>
          <FormAutocomplete
            control={control}
            name={'duration'}
            getOptionLabel={(option) => durationOptions[option]}
            options={Object.keys(durationOptions)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Duration"
                sx={{ minWidth: '200px' }}
                size={buttonSize}
                variant={buttonVariant} 
                error={!!errors.duration}
                helperText={errors.duration?.message}
              />
            )}
          />
        </span></Tooltip>
        <Tooltip title="Query/requery the grid of transmission segments available for purchase." arrow placement="top">
          <Button 
            endIcon={<RefreshOutlinedIcon />} 
            type='submit' 
            form='transhunter-availability-toolbar'
            variant={'contained'} 
            color='primary'
          >Get Segments</Button>
        </Tooltip>
      </Stack>
    </form>
  )
}