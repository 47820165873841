import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import { Box, Button, Stack, } from '@mui/material';
import useHeader from '../useHeader';
import { useSnackbar } from 'notistack';
import { useState, useMemo, useEffect, useRef, } from 'react';
import { apiUrlPrefix } from '../../authConfig';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import { AgGridReact } from 'ag-grid-react';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export const ChatbotTrainingManagement = () => {
    const header = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [trainingMessages, setTrainingMessages] = useState([]);
    const gridRef = useRef();

    useEffect(() => {
        fetchTrainingMessages();
    }, []);

    async function handleSave(data) {
        gridRef.current.api.stopEditing();
        let rowData = [];
        gridRef.current?.api?.forEachNode(node => rowData.push({
            ...node.data,
            userGuid: node.data.userGuid ?? header.userGuid,
        }));

        const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.AI_TrainingMessagesSave&parm=${header.userGuid}`;

        const options = {
            method: 'POST',
            headers: header,
            data: rowData,
            url: saveUri,
        }

        axios(options).then(response => {
            enqueueSnackbar('Messages saved.', { variant: 'success' });
        }).catch(err => {
            enqueueSnackbar(`Error saving training messages. ${err.response.data}`, { variant: 'error' });
        });
    }

    async function fetchTrainingMessages() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.AI_TrainingMessagesFetch&parm=${header.userGuid}`

        let options = {
            headers: header,
            url: url,
        };

        axios(options).then(response => {
            setTrainingMessages(response.data);
        }).catch(err => {
            enqueueSnackbar(`Error fetching training messages. Error: ${err}`, { variant: 'error', });
        });
    }

    const colDefs = useMemo(() => [
        {
            editable: false,
            headerName: "ID",
            field: "messageId",
            flex: 1,
        },
        {
            headerName: 'Role',
            field: 'role',
            flex: 6,
        },
        {
            headerName: 'Question',
            field: 'question',
            flex: 6,
        },
        {
            headerName: 'Answer',
            field: 'answer',
            flex: 24,
        },
    ], []);

    const defaultColDefs = useMemo(() => ({
        editable: true,
        floatingFilter: true,
        filter: "agMultiColumnFilter",
        resizable: true,
    }), [])

    function getRowNodeId(params) {
        return params.data.messageId;
    }

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    function addBlankRow() {
        gridRef.current.api.applyTransaction({
            add: [{}],
        });
    };


    return (
        <Box>
            <Stack direction='row' spacing={2} sx={{ paddingBottom: 1, }}>
                <Button
                    variant='contained'
                    color='primary'
                    endIcon={<SaveIcon />}
                    onClick={handleSave}
                >Save</Button>
                <Button
                    endIcon={<AddCircleOutlinedIcon />}
                    variant='contained'
                    color='primary'
                    onClick={addBlankRow}
                >Add New</Button>
            </Stack>
            <AgGridContainer
                style={{
                    height: "43vh",
                    width: "98%",
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={trainingMessages}
                    columnDefs={colDefs}
                    getRowId={getRowNodeId}
                    defaultColDef={defaultColDefs}
                    statusBar={statusBar}
                />
            </AgGridContainer>
        </Box>
    )
}

const schema = object().shape({
    question: string().required('Please enter a message.'),
});