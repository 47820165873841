import { Stack, IconButton, Dialog, DialogActions, DialogContent, Tabs, Tab, DialogTitle, } from "@mui/material"
import CornerRibbon from 'react-corner-ribbon';
import { Collapse, Typography, Button, Box, } from "@mui/material";
import { useState, useEffect, useMemo, useRef, } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from "./TSRActivity/ConfirmationDialog";
import { userGroups } from "../authConfig";
import SummaryView from "./FastPathPro/SummaryView";
import { useTheme } from '@mui/material/styles';
import { useUserGroups } from "../data/useUserGroups";
import { TransmissionForm } from "./Forms/TransmissionForm";
import dayjs from './dayjs-tz'
import momentTimezones from "./momentTimezones";

export const MultiLegTransmissionDialog = (props) => {
    const { open, defaultData, handleClose, handleSave, storageKey, disableChangeAction, } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const persistedValues = ['SaleRef', 'requestType', 'CustomerCode', 'Service_Increment', 'TSClass', 'TSType', 'TSPeriod', 'TSWindow', 'TSSubclass'];
    const theme = useTheme();
    const { userIsInGroup } = useUserGroups();
    const proUser = userIsInGroup(userGroups.fastpathpro);
    const navigationRef = useRef(0);
    const [data, setData] = useState(defaultData);
    const tz = defaultData.timezone ?? 'Pacific Standard Time';
    const tzone = momentTimezones[tz ?? 'Pacific Standard Time'];
    const [actionInfo, setActionInfo] = useState({
        action: 'reservefuture',
        targetExecutionTime: defaultData.scheduleDateTime && dayjs(defaultData.scheduleDateTime).tz(tzone, true),
    });
    const legs = useMemo(() => data.legs ?? {}, [data]);
    const legKeys = useMemo(() => [...Object.keys(legs)], [legs]);
    const onLastTab = (tabIndex === legKeys.length);

    useEffect(() => {
        setData(defaultData);
    }, [defaultData]);

    function handleTabSelect(event, newIndex) {
        if (onLastTab) {
            setTabIndex(newIndex);
        }
        navigationRef.current = newIndex;
    }

    function handleSubmit(data = actionInfo) {
        const formatted = legKeys.map(key => formatJson(legs[key]));
        handleSave(formatted, data);
        setTabIndex(0);
    }

    function formatJson(config) {
        return {
            leg: config.leg,
            Provider: config.Provider,
            PointOfReceipt: config.PointOfReceipt,
            PointOfDelivery: config.PointOfDelivery,
            Source: config.Source,
            Sink: config.Sink,
            ServiceIncrement: config.Service_Increment,
            TSClass: config.TSClass,
            TSPeriod: config.TSPeriod,
            TSSubclass: config.TSSubclass,
            TSType: config.TSType,
            TSWindow: config.TSWindow,
            profileInfo: config.profileInfo,
            profileConfig: config.profileConfig,
            PathName: config.PathName,
            Preconfirmed: config.Preconfirmed ? 'Yes' : 'No',
            'Customer Code': config.CustomerCode,
            timezone: config.timezone,
            'Sale Ref': config.SaleRef,
            Customer_Comments: config.Customer_Comments,
        }
    }

    function handleUpdate(data, configKey) {
        const valuesToStore = persistedValues.reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});

        localStorage.setItem(storageKey, JSON.stringify(valuesToStore));

        const newConfigs = {
            ...legs,
            [configKey]: data,
        }

        Object.keys(newConfigs).forEach(key => {
            //set the profile info for any other legs that don't have it yet
            if (!newConfigs[key].profileInfo?.length) {
                const atcFuture = legs[key].atcFuture;
                const timezone = momentTimezones[defaultData.timezone];
                const profile = [...data.profileInfo].map(block => ({
                    ...block,
                    price: atcFuture?.find(atcBlock => {
                        const blockStart = dayjs(block.startDateTime).tz(timezone, true);
                        const priceStart = dayjs(atcBlock.StartTime).tz(timezone, true);
                        return blockStart.isSame(priceStart, 'hour');
                    })?.OfferPrice ?? block.price,
                }));
                newConfigs[key].profileInfo = profile;
            }
        })

        setData({
            ...data,
            legs: newConfigs,
        });
    }

    return (
        <Dialog maxWidth={'lg'} open={open}>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Multiple TSRs Wizard</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: 1, }}>
                    {!proUser && <CornerRibbon style={{ fontSize: 14 }}>
                        <Stack>
                            <Typography>Upgrade Required to Transact!</Typography>
                            <Button
                                sx={{ color: theme.palette.primary.white }}
                                href="https://webforms.pipedrive.com/f/5X4yAVMERh0y8mGszS6ntpwcPhTToxMyqwqNXhrZXfn7xl0R187lTPeBzlp0B4jxCP"
                            >Go here to request upgrade or email support@powersysops.com</Button>
                        </Stack>
                    </CornerRibbon>}
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabSelect}
                        indicatorColor="primary"
                        textColor="primary"
                        color="primary"
                    >
                        {legKeys.map(key => (
                            <Tab
                                label={`${legs[key].PointOfReceipt} -> ${legs[key].PointOfDelivery}`}
                                key={`${legs[key].PostingRef}`}
                                {...(onLastTab ? {} : {
                                    type: 'submit',
                                    form: `tsr-transmission-form-${tabIndex}`
                                })}
                            />
                        ))}
                        <Tab
                            label='Finish'
                            id='completeTsrTab'
                            type="submit"
                            form={`tsr-transmission-form-${tabIndex}`}
                        />
                    </Tabs>
                    {legKeys.map((key, i) => (
                        <Collapse in={tabIndex === i} orientation={'vertical'} key={`transmission-form-page-${legs[key].PostingRef}`}>
                            <TransmissionForm
                                defaultValues={legs[key]}
                                resetDependency={!!legs[key]}
                                disabled={!proUser}
                                allowActions={false}
                                formNumber={key}
                                onSubmit={data => {
                                    handleUpdate(data, key);
                                    setTabIndex(navigationRef.current);
                                }}
                                fixedTimezone
                                visible={tabIndex === i}
                                version={2}
                            />
                        </Collapse>
                    ))}
                    <Collapse in={onLastTab} orientation='vertical'>
                        <SummaryView
                            data={legs}
                            visible={onLastTab}
                            onSubmit={(data) => {
                                setActionInfo(data);
                                handleSubmit(data);
                            }}
                            timezone={defaultData.timezone}
                            tabNum={legKeys.length}
                            defaultAction={defaultData.action ?? 'reservefuture'}
                            defaultDateTime={actionInfo.targetExecutionTime}
                            disableChangeAction={disableChangeAction}
                        />
                    </Collapse>
                </Box>
            </DialogContent>
            <DialogActions>
                {!!tabIndex && <Button variant="contained" color="primary" onClick={() => setTabIndex(i => i - 1)}>Back</Button>}
                <Box sx={{ flexGrow: 1, }} />
                <Button
                    variant="contained"
                    disabled={onLastTab && !proUser}
                    color="primary"
                    type='submit'
                    form={`tsr-transmission-form-${tabIndex}`}
                    onClick={() => navigationRef.current = tabIndex + 1}
                >{onLastTab ? 'Submit Reservations' : 'Next'}</Button>
            </DialogActions>
        </Dialog>
    )
}