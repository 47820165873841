import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material"
import { Typography, Button, Box, } from "@mui/material";
import { useState, useMemo, memo } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from "../TSRActivity/ConfirmationDialog";
import axios from "axios";
import { apiUrlPrefix } from "../../authConfig";
import { useSnackbar } from "notistack";
import useHeader from "../useHeader";
import { userGroups } from "../../authConfig";
import momentTimezones from "../momentTimezones";
import { renderStandardTimezones } from "../../utils/renderTimezones";
import dayjs from '../dayjs-tz';
import { TransmissionForm } from "../Forms/TransmissionForm";
import { useUserGroups } from "../../data/useUserGroups";

export const TransDialog = (props) => {
  const { open, data = {}, closeDialog, application = 'transnow', } = props;
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [handleConfirmation, setHandleConfirmation] = useState();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const storageKey = `tsr-activity-transmission-dialog`;
  const persistedValues = ['timezone', 'SaleRef', 'requestType', 'CustomerCode', 'Service_Increment', 'TSClass', 'TSType', 'TSPeriod', 'TSWindow', 'TSSubclass'];
  const { userIsInGroup } = useUserGroups();
  const proUser = userIsInGroup(userGroups.admins, userGroups.originalrequests);
  const formatString = 'MM/DD/YYYY HH:mm:ss';

  const storedValues = useMemo(() => {
    const storedValueString = localStorage.getItem(storageKey);
    const storedValues = JSON.parse(storedValueString ?? '{}');
    return storedValues;
  }, [open]);

  const defaultValues = useMemo(() => {
    const tz = storedValues.timezone ?? 'Pacific Standard Time';
    const tzone = momentTimezones[tz ?? 'Pacific Standard Time'];
    const timezone = tz.includes('Standard') ? renderStandardTimezones[tz ?? 'Pacific Standard Time'] : tz ?? 'Pacific';
    const profile = [] ?? JSON.parse(data?.ProfileInfo ?? '[]').map(block => {
      const offset = dayjs(block.StartTime_UTC).utcOffset();
      return {
        //price: leg.OfferPrice,
        capacityRequested: parseInt(block.SegmentCapacity),
        startDateTime: dayjs.utc(block.StartTime_UTC).utcOffset(offset).format(formatString),
        endDateTime: dayjs.utc(block.EndTime_UTC).utcOffset(offset).format(formatString),
      }
    });
    return {
      ...storedValues,
      ...data,
      Customer_Comments: data.CUSTOMER_COMMENTS ?? '',
      CustomerCode: storedValues.CustomerCode,
      Service_Increment: storedValues.Service_Increment ?? '',
      SaleRef: storedValues.SaleRef ?? '',
      timezone: timezone,
      profileInfo: [],
      maxProfile: profile,
      action: 'reservenow',
      targetExecutionTime: dayjs().tz(tzone).add(2, 'minute').startOf('minute'),
      profileConfig: {
        startDate: dayjs(profile[0]?.startDateTime),
        startHour: dayjs(profile[0]?.startDateTime).hour(),
        stopDate: dayjs(profile[profile.length - 1]?.endDateTime),
        stopHour: dayjs(profile[profile.length - 1]?.endDateTime).hour(),
        defaultPrice: data.OfferPrice ?? 0,
        defaultCapacity: data.maxCapacity ?? 0,
      }
    }
  }, [data, storedValues])

  function handleSave(confirmed = 0, data) {
    const params = {
      userGuid: headers.userGuid,
      action: data.action,
      timeZone: renderStandardTimezones[data.timezone],
      TargetExecutionTime: (data.action === 'reservefuture') ? dayjs(data.targetExecutionTime).tz(momentTimezones[data.timezone]).format(formatString) : '',
      application: application,
      data: formatJson(data),
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${headers.userGuid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${params.action}&parm=${confirmed}&parm=${params.application}`

    const options = {
      method: 'POST',
      headers: headers,
      data: params.data,
      url: url,
    }

    axios(options).then(response => {
      if (confirmed) {
        enqueueSnackbar('Success submitting TSR(s) to the queue for processing by OASIS.')
      } else {
        setOpenConfirmationDialog(true);
        setConfirmationMessage(response.data);
        setHandleConfirmation(() => () => handleSave(1, data));
      }
    }).catch(error => {
      // enqueueSnackbar(`Error saving TSR trigger. ${error.message}`) 
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
    });

    if (confirmed) {
      setOpenConfirmationDialog(false);
      closeDialog();
    }
  }

  function formatJson(config) {
    return {
      ...config,
      ServiceIncrement: config.Service_Increment,
      Preconfirmed: config.Preconfirmed ? 'Yes' : 'No',
      'Customer Code': config.CustomerCode,
      ProfileInfo: undefined,
    }
  }

  function onSubmit(data) {
    const toSave = persistedValues.reduce((acc, field) => {
      acc[field] = data[field];
      return acc;
    }, {});

    localStorage.setItem(storageKey, JSON.stringify(toSave));

    handleSave(0, data);
  }

  return (
    <Dialog maxWidth={'lg'} open={open}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Reserve TSR</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={closeDialog} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ConfirmationDialog
          open={openConfirmationDialog}
          message={confirmationMessage}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirmation={handleConfirmation}
        />
        <TransmissionForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          disabled={!proUser}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1, }} />
        <Button variant="contained" type='submit' form='tsr-transmission-form-0' disabled={!proUser} color="primary" >{'Submit Reservation'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export const TransmissionDialog = memo(TransDialog);