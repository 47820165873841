import { useEffect, useRef, useCallback } from "react";
import { useHubMessages } from "./useHubMessages";
import dayjs from "dayjs";
import { debounce } from "lodash";

/*
    Props:
    action: async function called when an allowed message has been received, e.g. a data fetch. Should return a promise
    allowedMessages: an array of the hub messages to look for
    predicate: a function that returns a boolean value that if false, will stop the action from being called, e.g. could be false if there is a state variable that action depends on that has not been set
    callbackDependencies: since 'action' 'is wrapped in a useCallback, include the dependencies of 'action'.
    wait: the number of milliseconds to wait after the last allowed message is received before calling the action
    debounceOptions: options for the debounce function, see lodash documentation for more information
*/

const useHubObject = ({ action, allowedMessages, predicate = () => true, callbackDependencies = [], wait = 2000, debounceOptions = { leading: true, }, }) => {
    const { message: hubObj, diffData } = useHubMessages();
    const messageQueueCount = useRef(0);
    const loading = useRef(false);
    const objRef = useRef(hubObj);
    objRef.current = hubObj;

    useEffect(() => { //every time we get an accepted hub message, increment the queue count
        if (allowedMessages.includes(hubObj?.hubMessage) && predicate(hubObj)) {
            messageQueueCount.current++;
            console.log(`Message ${hubObj.hubMessage} received. Queue count: ${messageQueueCount.current}. Timestamp: ${dayjs().format('HH:mm:ss.SSS')}`)
            if (!loading.current) {
                act(hubObj);
            }
        }
    }, [hubObj]);

    const act = useCallback(debounce(async (obj) => {
        console.log(`Starting hub action. Queue count: ${messageQueueCount.current}. Timestamp: ${dayjs().format('HH:mm:ss.SSS')}`)
        loading.current = true;
        messageQueueCount.current = 0;
        return action(obj).then(_ => { //after we are done loading, set loading to false and if there are more messages in the queue, call act again
            if (messageQueueCount.current > 0) {
                act(objRef.current); //use ref to get the latest message
            } else {
                loading.current = false;
            }
        });
    }, wait, debounceOptions), callbackDependencies);

    const cancelHubAction = () => {
        loading.current = false;
        messageQueueCount.current = 0;
        act.cancel();
    }

    return {
        hubObj,
        diffData,
        cancelHubAction,
    };
}

export default useHubObject;