import useHeader from "../useHeader";
import axios from "axios";
import { TextField, MenuItem } from "@mui/material";
import { useMemo, useEffect, useState, useRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DivGuard from '../Guards/DivGuard.js';
import { useGridButtons } from '../useGridButtons'
import { AgGridReact, } from 'ag-grid-react';
import { useData } from '../useData';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useSnackbar } from "notistack";
import { apiUrlPrefix, userGroups } from "../../authConfig";
import { AgGridContainer } from "../AgGrid/AgGridContainer";

export default (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const [table, setTable] = useState();
  const [tableList, setTableList] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  const tableListFetchUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.tableListFetch`;
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.tableFetch&parm=${table}`;
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.tableInsertUpdate&parm=${table}`;

  const gridRef = useRef();
  const { rowData, getData, saveData, setRowData } = useData([]);
  const { RefreshButton, UndoButton, RedoButton, ExportButton, SaveButton, CopyRowsButton } = useGridButtons({ gridRef });

  useEffect(fetchTableList, []);

  useEffect(updateColDefs, [rowData]);

  useEffect(() => {
    if (table) {
      gridRef.current.api.showLoadingOverlay();
      getData(loadUri).then(() => {
        gridRef.current.api.hideOverlay();
      });
    }
  }, [table]);

  function fetchTableList() {
    const options = {
      method: 'GET',
      headers: headers,
      url: tableListFetchUri,
    }

    axios(options).then(response => {
      if (typeof response.data === 'string') {
        enqueueSnackbar(response.data)
      } else {
        setTableList(response.data.map(table => table.table_name));
      }
    }).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${tableListFetchUri}. Status: ${error.response?.status}. Message: ${error}`)
    });
  }

  function updateColDefs() {
    if (rowData?.length > 0) {
      const fields = Object.keys(rowData[0]);
      const defs = fields.map(field => {
        return {
          editable: true,
          //checkboxSelection: true,
          headerName: field,
          //enableRowGroup: true,
          field: field,
          filter: "agMultiColumnFilter",
          sortable: true,
        }
      });

      setColDefs(defs);
    }
  }

  function updateRowData(params) {
    setTable(params.target.value);
  }

  function onRefreshBtn() {
    getData(loadUri);
    enqueueSnackbar('Grid refreshed.');
  };

  async function handleSaveButtonClick() {
    const message = 'Grid saved to database.';
    gridRef.current.api.stopEditing();
    saveData(saveUri, message);
  }

  function onRowDataUpdated(params) {
    params.columnApi.autoSizeAllColumns();
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
      ],
      position: 'right',
    }
  }, [rowData]);

  const defaultColDef = useMemo(() => ({
    editable: false,
    floatingFilter: true,
    filter: "agMultiColumnFilter",
    sortable: true,
    resizable: true,
  }), [])

  return (
    <DivGuard groups={[userGroups.admins]} >
      <AgGridContainer
        style={{
          height: "83vh",
          width: "98%",
        }}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title="Refresh the grid data." arrow placement="top">
            <RefreshButton onClick={onRefreshBtn} />
          </Tooltip>&nbsp;
          <Tooltip title="Undo the last edit made." arrow placement="top">
            <UndoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Redo the last edit made." arrow placement="top">
            <RedoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
            <CopyRowsButton />
          </Tooltip>&nbsp;
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <ExportButton />
          </Tooltip>&nbsp;

          <SaveButton onClick={handleSaveButtonClick} />&nbsp;
          <Tooltip title="Select either firm or non-firm available transmission capacity." arrow placement="top">
            <TextField
              id="tableListSelect"
              value={table}
              select
              minWidth={200}
              color={'primary'}
              label="Table Name"
              onChange={updateRowData}
            >
              {tableList.map(tableName => (<MenuItem value={tableName}>{tableName}</MenuItem>))}
            </TextField>
          </Tooltip>&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          onRowDataUpdated={onRowDataUpdated}
          //suppressRowClickSelection
          enableRangeSelection={true}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          //gridOptions={gridOptions}
          enterMovesDownAfterEdit={true}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          //rowHeight={50}
          animateRows={true}
        //components={{
        //  layoutToolPanel: LayoutToolPanel,
        //}}
        />
      </AgGridContainer>
    </DivGuard>
  );
}