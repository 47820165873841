import { Box } from '@mui/material';
import { DashboardTabs } from './DashboardTabs';
import { DashboardViewContainer } from './DashboardViewContainer';
import { DashboardProvider } from './useDashboard';
import { LoadingOverlay, NoDataOverlay } from './ViewOverlays';
import { ViewSelector } from './ViewSelector';

export const Dashboard = ({ dashboardKey, useShared = false, ViewComponent, AddNewOrUpdateDialog, visible, }) => {
    return (
        <DashboardProvider visible={visible} dashboardKey={dashboardKey} useShared={useShared} AddNewOrUpdateDialog={AddNewOrUpdateDialog}>
            <Box
                sx={{
                    p: '7px',
                    flexGrow: 1,
                }}
            >
                <Box>
                    <Box sx={{
                        display: 'flex',
                        flexGrow: 1,
                    }}>
                        <ViewSelector />
                        < DashboardTabs />
                    </Box>
                    <DashboardViewContainer
                        ViewComponent={ViewComponent}
                    />
                </Box>
                <NoDataOverlay />
                <LoadingOverlay />
            </Box>
        </DashboardProvider>
    );
}

