import FormDatePicker from "../FormControls/FormDateTimePicker";
import { Grid, Tooltip, Checkbox, FormGroup, FormControlLabel, } from "@mui/material";
import momentTimezones from "../momentTimezones";

export const ReservationActionForm = (props) => {
    const { timezone, disabled, handleActionChange, control, errors, action, } = props;
    return (
        <Grid container spacing={2} sx={{ paddingLeft: 2, paddingTop: 3, }}>
            <Grid item xs={8}>
                <FormGroup row>
                    <Tooltip title='Immediately reserve the configured transmission.' arrow placement='top'>
                        <FormControlLabel control={<Checkbox disabled={disabled} checked={action === 'reservenow'} onClick={() => handleActionChange('reservenow')} />} label="Reserve Now" />
                    </Tooltip>
                    <Tooltip title='Reserve the configured transmission for a future date and time.' arrow placement='top'>
                        <FormControlLabel control={<Checkbox disabled={disabled} checked={action === 'reservefuture'} onClick={() => handleActionChange('reservefuture')} />} label="Reserve Future" />
                    </Tooltip>
                    <Tooltip title='Wait for capacity to become available to reserve transmission.' arrow placement='top'>
                        <FormControlLabel control={<Checkbox disabled={disabled} checked={action === 'awaitavailability'} onClick={() => handleActionChange('awaitavailability')} />} label="Reserve When Available" />
                    </Tooltip>
                    <Tooltip title='Only reserve transmission if capacity is available for all legs.' arrow placement='top'>
                        <FormControlLabel control={<Checkbox disabled={true || disabled} checked={action === 'allornone'} onClick={() => handleActionChange('allornone')} />} label="All or None" />
                    </Tooltip>
                    <Tooltip title='Receive a text message when capacity becomes available.' arrow placement='top'>
                        <FormControlLabel control={<Checkbox disabled={disabled} checked={action === 'textalert'} onClick={() => handleActionChange('textalert')} />} label="Alert Me When Available" />
                    </Tooltip>
                </FormGroup>
            </Grid>
            <Grid item xs={4}>
                {(action === 'reservefuture') &&
                    <FormDatePicker
                        name='targetExecutionTime'
                        control={control}
                        label='Reservation Date/Time'
                        disablePast
                        timezone={timezone ? momentTimezones[timezone] : 'default'}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.targetExecutionTime,
                                helperText: errors.targetExecutionTime?.message,
                            }
                        }}
                    />
                }
            </Grid>
        </Grid>
    );
}