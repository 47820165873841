import { apiUrlPrefix, } from '../authConfig.js';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import useHeader from './useHeader';
import { useCallback } from 'react';

export const useActionAudit = () => {
    const { enqueueSnackbar } = useSnackbar();
    const headers = useHeader();

    const logAction = useCallback(async (action, userInterface, data = {}) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UseabilityAuditInsert`
            + `&parm=${headers.userGuid ?? 'NULL'}&parm=${action}&parm=${userInterface}`

        const options = {
            method: 'POST',
            headers: headers,
            data: data,
            url: url,
        }

        return axios(options).catch(error => { enqueueSnackbar(`${error}`) });
    }, [headers, enqueueSnackbar]);

    return { logAction };
}
