import Grid from './PPWTransGrid';
import CriteriaPanel from './PPWCriteria';
import { useState, useEffect, useRef } from 'react';
import useHeader from "../../useHeader";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import moment from "moment";
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import { Box } from "@mui/material";
import { useActionAudit } from '../../useActionAudit';
import { useMsal } from '@azure/msal-react';
import CriteriaProvider from '../CriteriaContext/criteriaProvider';

export default () => {
  const [gridData, setGridData] = useState([]);
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();
  const { accounts } = useMsal(); 
  const gridRef = useRef();
  const guid = useLocalGuid();
  const defaultCriteria = {
    legs: 3,
    minCapacity: 100,
    endHour: 1,
    timezone: 'Pacific Standard Time',
  }
  const criteriaStorageKey = 'ppw-trans-criteria';
  const storedCriteria = JSON.parse(localStorage.getItem(criteriaStorageKey) ?? '{}');
  const [criteria, setCriteria] = useState({
    ...defaultCriteria,
    ...storedCriteria,
    startDate: moment().format('MM/DD/YYYY'),
  });

  useEffect(() => {
    fetch();
  }, [])

  function updateCriteria(key, value) {
    const newCriteria = {
      ...criteria,
      [key]: value,
    }

    setCriteria(newCriteria);
    localStorage.setItem(criteriaStorageKey, JSON.stringify(newCriteria));
  }

  //Takes an array, returns a comma separated string
  function collapseArray(arr) {
    return arr?.length ? arr.reduce((current, next, i) => `${current}${(i > 0) ? ',' : ''}${next}`) : '';
  }

  const logMessage = `${accounts[0]?.username} used PPW Trans to find ${criteria.minCapacity}MW of `
                    + `${criteria.tsClass ?? ''} ${criteria.tsIncrement ?? ''} ${criteria.tsType ?? ''} ${criteria.tsPeriod ?? ''} ${criteria.tsWindow ?? ''} ${criteria.tsSubclass ?? ''}`
                    + ` trans ${criteria.por}-${criteria.pod} ${criteria.excludePoints ? ` excluding points ${criteria.excludePoints}` : ''}`
                    + `${criteria.excludeTp ? ` excluding providers ${criteria.excludeTp}` : ''}` 
  
  function fetch() {
    gridRef.current?.api && gridRef.current.api.showLoadingOverlay();
    const start = moment(criteria.startDate).startOf('day').hours(criteria.endHour ?? 0).format('MM/DD/YYYY HH:mm:ss.SSS')

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ppwTransFetch`
      + `&parm=${guid ?? ''}`
      + `&parm=${criteria.legs ?? ''}`
      + `&parm=${criteria.por ?? ''}`
      + `&parm=${criteria.pod ?? ''}`
      + `&parm=${criteria.minCapacity ?? ''}`
      + `&parm=${criteria.timezone ?? ''}`
      + `&parm=${start}`
      + `&parm=${collapseArray(criteria.tsClass)}`
      + `&parm=${collapseArray(criteria.tsIncrement)}`
      + `&parm=${collapseArray(criteria.tsType)}`
      + `&parm=${collapseArray(criteria.tsPeriod)}`
      + `&parm=${collapseArray(criteria.tsWindow)}`
      + `&parm=${collapseArray(criteria.tsSubclass)}`

    const options = {
      headers: headers,
      url: url,
    }

    axios(options).then(response => {
      setGridData(response?.data ?? []);
      gridRef.current?.api && gridRef.current.api.hideOverlay();
      logAction(logMessage, 'PPWTrans', criteria)
    }).catch(error => {
      enqueueSnackbar(`Error fetching paths. ${error.message}`)
      gridRef.current?.api && gridRef.current.api.hideOverlay();
    })
  }

  return (
    <Box sx={{ display: 'flex', }}>
      <CriteriaProvider>
        <CriteriaPanel criteria={criteria} handleChange={updateCriteria} handleFetch={fetch} />
        <Grid data={gridData} ref={gridRef}/>
      </CriteriaProvider>
    </Box>
  )
}