import { TransmissionPositionView } from "./TransmissionPositionView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy.js";

export const TransmissionPositionDashboard = ({ visible, }) => {
	const dashboardKey = 'transmission-position';
	const useShared = true;

	return (
		<DashboardLazy
			dashboardKey={dashboardKey}
			useShared={useShared}
			ViewComponent={TransmissionPositionView}
			AddNewOrUpdateDialog={AddNewOrUpdateDialog}
			visible={visible}
		/>
	);
}
