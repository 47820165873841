import { useState, useEffect } from "react";
import NotAuthorized from "../NotAuthorized";
import { useUserGroups } from "../../data/useUserGroups";

export default function RouteGuard(props) {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { userIsInGroup, groups } = useUserGroups();

    useEffect(() => {
        const inGroupOrAdmin = (props.groups.length === 0) || userIsInGroup(...props.groups);
        setIsAuthorized(inGroupOrAdmin);
    }, [groups, window.location.href])

    if(isAuthorized) {
        return <>{props.children}</>;
    }
    
    return(
        <div>
            <NotAuthorized/>
        </div>
    );
} 