import { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/conditionalGridStyles.css'
import Box from '@mui/material/Box';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DivGuard from '../../components/Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import Tooltip from '@mui/material/Tooltip';
import { useActionAudit } from '../useActionAudit';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import useGridLayout from '../useGridLayout';
import { useData } from '../useData';
import { stringIntComparator } from '../../utils/gridFunctions';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default () => {
  const gridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const { logAction } = useActionAudit();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_AuditFetch&parm=${guid}`;
  const storageLocation = 'transnow-audit-grid-layout'
  const { rowData, getData } = useData();

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
    let message = 'CSV generated. See your downloads folder to retrieve the file.';

    enqueueSnackbar(message);
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
    let message = 'Rows copied to clipboard.';

    enqueueSnackbar(message);
  };

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const groupDefaultExpanded = 1;

  const onRefreshBtn = () => {
    getData(loadUri);
    let message = 'Audit log refreshed.';
    logAction('Refresh', 'TransNowAuditGrid');
    enqueueSnackbar(message);
  };

  function successTrap(params) {
    if (params.api.getValue("Result", params.node)) {
      return params.api.getValue("Result", params.node).includes('Success')
    }
    else {
      return false
    }
  }

  const gridOptions = useMemo(() => ({
    rowClassRules: {
      "row-pass": params => successTrap(params),
      "row-waiting": params => params.api.getValue("Action", params.node) === 'CheckStatus',
      "row-fail": params => params.api.getValue("Result", params.node),
    },
  }), []);

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    getData(loadUri);
    return () => {
      abortController.abort();
    }
  }, []);

  const baseColDefs = useMemo(() => [
    {
      editable: false,
      checkboxSelection: true,
      headerName: "Batch",
      enableRowGroup: true,
      field: "AuditId",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Time Stamp",
      enableRowGroup: true,
      field: "TimestampUtc",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "User Name",
      enableRowGroup: true,
      field: "userName",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Action",
      enableRowGroup: true,
      field: "Action",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Inbound Aref",
      enableRowGroup: true,
      field: "InboundAref",
      comparator: stringIntComparator,
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Outbound Aref",
      enableRowGroup: true,
      field: "OutboundAref",
      comparator: stringIntComparator,
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Result",
      enableRowGroup: true,
      hide: true,
      field: "Result",
      tooltipField: "Result",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Long Result",
      field: "LongResult",
      wrapText: true,
      tooltipField: "LongResult",
    },
    {
      editable: false,
      headerName: "User GUID",
      enableRowGroup: true,
      hide: true,
      field: "userGuid",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
  ], []);

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, gridRef, baseColDefs);

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ],
    position: 'right',
  }), [layoutPanel]);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: true,
    floatingFilter: true,
    flex: 1,
    sortable: true,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <DivGuard groups={[userGroups.transnowauditgrid]} >
      <AgGridContainer
        style={{ height: '83vh', width: "98%" }}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title="Refresh the grid of audit records, retreiving the most recent audit log records from Power Station." arrow placement="top">
            <Button
              endIcon={<RefreshOutlinedIcon />}
              id="refresh"
              size={buttonSize}
              variant={buttonVariant}
              color={buttonColor}
              onClick={() => onRefreshBtn()}
            >Refresh</Button>
          </Tooltip>&nbsp;
          <Tooltip title="Copy row data to the clip board for pasting in other applications." arrow placement="top">
            <Button
              onClick={() => onBtCopyRows()}
              endIcon={<ContentCopyOutlinedIcon />}
              id="copyBtn"
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
            >Ctrl-c</Button>
          </Tooltip>&nbsp;
          <Tooltip title="Download a copy of this audit log to a CSV format that can be opened in MS Excel." arrow placement="top">
            <Button
              onClick={() => onBtnExport()}
              endIcon={<DownloadForOfflineOutlinedIcon />}
              id="undoBtn"
              variant={buttonVariant}
              color={buttonColor}
              size={buttonSize}
            >CSV</Button>
          </Tooltip>&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          groupDefaultExpanded={groupDefaultExpanded}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          gridOptions={gridOptions}
          animateRows={true}
          rowData={rowData}
          groupSelectsChildren={true}
          components={{
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </DivGuard>
  );
};