import { dateComparator } from "../../utils/gridFunctions";
import { useColumnTypes } from "../AgGrid/useColumnTypes";
import { defaultStatusBar, defaultColumnDef, } from "../AgGrid/defaultGridProps";
import { useEffect, useRef, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { Box } from "@mui/material";
import { apiUrlPrefix } from "../../authConfig";
import axios from "axios";
import useHeader from "../useHeader";
import useHubAction from "../HubContext/useHubAction_v2";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import _ from "lodash";

export const Status = () => {
    const gridRef = useRef();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { columnTypes } = useColumnTypes();
    const newRows = useRef();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        fetchBrainStatusAndUpdate();
    }, []);

    const { diffData, } = useHubAction({
        action: fetchBrainStatusAndUpdate,
        allowedMessages: ['brainInsertUpdateDelete'],
        predicate: !!gridRef.current?.api,
    });

    function handleDataUpdate(newData) {
        const timestamp = dayjs();

        const id = (obj) => JSON.stringify(obj);
        const oldData = [];
        gridRef.current.api.forEachNode(node => {
            !node.group && oldData.push(node.data);
        });

        //const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData);

        const newIdHash = newData.reduce((hash, next) => {
            hash[id(next)] = next;
            return hash;
        }, {})

        const oldIdHash = oldData.reduce((hash, next) => {
            hash[id(next)] = next;
            return hash;
        }, {})

        const toAdd = newData.reduce((adding, next) => {
            if (!oldIdHash[id(next)]) { //if the old data doesn't have this ID, it's a new row
                adding.push(next);
            }
            return adding;
        }, [])

        const toUpdate = newData.reduce((updating, next) => {
            if (oldIdHash[id(next)] && !_.isEqual(newIdHash[id(next)], oldIdHash[id(next)])) { //if the old data also has this ID and the data changed, update it
                updating.push(next);
            }
            return updating;
        }, [])

        const toDelete = oldData.reduce((deleting, next) => {
            if (!newIdHash[id(next)]) { //if the new data doesn't have this ID, delete the row
                deleting.push(next);
            }
            return deleting;
        }, [])

        gridRef.current.api.applyTransaction({
            add: toAdd,
            addIndex: 0,
            update: toUpdate,
            remove: toDelete,
        });

        console.log(`Brain status grid update complete. ${dayjs().diff(timestamp)}ms elapsed.`)
    }

    async function fetchBrainStatus() {
        const guid = headers.userGuid; //'70e58865-e483-4864-af11-53e80e3a1b80' //headers.userGuid;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchBrainHistory&parm=${guid}`;
        return axios.get(url, { headers: headers }).catch(error => {
            enqueueSnackbar(`Error loading brain status data. ${error.response?.data ?? ''} Status: ${error.response?.status}. Message: ${error}`, { variant: 'error' })
        });
    }

    async function fetchBrainStatusAndUpdate() {
        return fetchBrainStatus().then(response => {
            setLoaded(true);
            handleDataUpdate(response?.data ?? []);
        });
    }

    const colDefs = useMemo(() => ([
        {
            headerName: 'ID',
            field: 'brainID',
            initialHide: true,
        },
        {
            headerName: 'Brain Name',
            field: 'name',
        },
        {
            headerName: 'Timestamp',
            field: 'dateTimeStamp',
            type: 'dateColumn',
            sort: 'desc',
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 6,
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        resizable: true,
        flex: 1,
    }), []);

    function onRowDataUpdated(params) {
        if (newRows.current?.length && loaded) {
            const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
            params.api.flashCells({
                rowNodes: nodes,
                flashDelay: 1000,
                fadeDelay: 2000,
            });
            newRows.current = undefined;
        }
    }

    return (
        <Box sx={{ height: '90vh', p: 1, }}>
            <AgGridContainer style={{ height: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={colDefs}
                    enableRangeSelection
                    enableFillHandle
                    enableCellChangeFlash
                    defaultColDef={defaultColDef}
                    statusBar={defaultStatusBar}
                    columnTypes={columnTypes}
                    onRowDataUpdated={onRowDataUpdated}
                    overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">Welcome to Brain Status! Create and train a brain to see information here.</span>'}
                />
            </AgGridContainer>
        </Box>
    );
}