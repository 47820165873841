import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { AdvancedMarker, } from "@vis.gl/react-google-maps";
import styled from '@mui/material/styles/styled';
import dayjs from "dayjs";

export const Marker = (props) => {
    const [hovered, setHovered] = useState(false);
    const isHovered = useRef(false); // to keep track of the hover state while the transition is happening

    const transitionDuration = 300;

    function onMouseEnter(e) {
        isHovered.current = true;
        setTimeout(() => {
            isHovered.current && setHovered(true);
        }, transitionDuration); //match the speed of the transition in the styled component
    }

    function onMouseLeave() {
        isHovered.current = false;
        setHovered(false);
    }

    //note the empty onClick prop to enable the hover effect
    return (
        <AdvancedMarker {...props} onClick={() => { }}>
            <StyledMarker onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {hovered && <MarkerInfo data={props.locationInfo} />}
            </StyledMarker>
        </AdvancedMarker>
    );
};

const MarkerInfo = ({ data, }) => {
    const tempData = data['temp'];
    const windData = data['windspeed'];
    const cloudData = data['cloudcover'];

    return (
        <Box sx={{ p: 2, }}>
            <Typography variant="caption" component="p">
                Temp: {tempData.value}° F
            </Typography>
            <Typography variant="caption" component="p">
                Wind: {windData.value} mph
            </Typography>
            <Typography variant="caption" component="p">
                Clouds: {cloudData.value}%
            </Typography>
            <Typography variant="caption" component="p">
                Forecasted For: {dayjs(tempData.ForecastForUtc).format('MM/DD/YYYY hh:mm A')}
            </Typography>
        </Box>
    );

}

const StyledMarker = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.red,
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    zIndex: 100,
    transition: '0.3s',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        width: 200, // adjust as needed
        height: 100, // adjust as needed
    },
}));