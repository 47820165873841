import { AgGridReact } from "ag-grid-react"
import dayjs from "dayjs"
import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { useSearchParams } from "react-router"
import { logAction } from "../../../utils/auditLogger"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import useHubObject from "../../HubContext/useHubObject"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useApi } from "../../useApi"
import { useUserInfo } from "../../UserInfoContext"
import { useDashboardLayout } from "../useDashboardLayout"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useRowDebounce } from "../useRowDebounce"
import { colorForValue, jsonOrCommaSeparatedFormatter, redPastMarkerFormatter, tooltipRenderer } from "../Utils"
import { PaletteToolPanel } from "./PaletteToolPanel"

export const DealPositionGrid = forwardRef(({ setSelectedRow, getRowId, layoutStorageKey, silentUpdate, columnToggles, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteStorageKey = 'position-report-palette';
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { post, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();
    const { getValues } = useFormContext();
    const [search] = useSearchParams();

    const userInfo = useUserInfo();
    const { layout, } = getValues();

    const heatmapStyle = (params, paletteRef, key) => {
        let styles = {};
        if (!!paletteRef.current?.[key] && !!params.value) {
            styles = {
                backgroundColor: colorForValue(params.value),
                textShadow: 'black 1px 1px 4px',
                color: 'white',
            }
        }
        return styles;
    };

    const handleModifyDeal = useCallback(async (updatedRow) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDeal_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.dealID}`
            + `&parm=${updatedRow.flowDate}`
            + `&parm=${updatedRow.flowDateTimeZone}`

        if (updatedRow[27]) {
            enqueueSnackbar(`HE 27 detected and removed. MW: ${updatedRow[27].MW}, Price: ${updatedRow[27].MW}`, { variant: 'warning' });
            console.log('HE 27 detected and removed. MW: ', updatedRow[27].MW, 'Price: ', updatedRow[27].Price);
            logAction('HE 27 detected and removed', 'Deal Position', headers.userGuid, updatedRow);
            delete updatedRow[27];
        }

        return post(url, JSON.stringify(updatedRow)).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar("Deal Profile updated successfully", { variant: "success" });
            }
        });
    }, [enqueueSnackbar, post, headers]);

    const { batchRowUpdate } = useRowDebounce({ onTimeExpired: handleModifyDeal });

    function handleCellValueChanged(event) {
        batchRowUpdate(event);
    }

    const baseHourColDef = useMemo(() => ({
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        cellDataType: 'number',
        editable: params => !params.node.isRowPinned(),
        width: 60,
        aggFunc: 'sum',
        enableRowGroup: false,
        resizable: true,
    }), []);

    const defaultHourColChildren = useCallback((i) => ([
        {
            ...baseHourColDef,
            headerName: 'MW',
            colId: `${i}.MW`,
            cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
            valueGetter: (params) => {
                return params.data?.[i]?.MW;
            },
            valueSetter: (params) => {
                //if Transaction_Type is Sale, make sure that the value is negative
                if (!params.data[i]) return false;

                const value = params.newValue;
                const Transaction_Type = params.data?.Transaction_Type;
                const saleAndPositive = Transaction_Type === 'Sale' && value > 0;
                const purchaseAndNegative = Transaction_Type === 'Purchase' && value < 0;
                if (saleAndPositive || purchaseAndNegative) {
                    params.data[i].MW = -value;
                } else {
                    params.data[i].MW = value;
                }
                return true;
            },
        },
        {
            ...baseHourColDef,
            field: `${i}.Price`,
            colId: `${i}.Price`,
            headerName: '$',
            cellStyle: params => heatmapStyle(params, paletteRef, 'showPriceHeatmap'),
        },
    ]), []);

    function toTwoDecimalPlaces(value) {
        const truncated = value ? parseFloat(parseFloat(value).toFixed(2)) : null;
        return truncated;
    }

    const rowMWTotal = (rowData) => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc += rowData[i]?.MW ?? 0;

            if (i === 2 && rowData['2*']) {
                acc += rowData['2*']?.MW ?? 0;
            }

            return toTwoDecimalPlaces(acc);
        }, 0);
    }

    const rowSettlementTotal = (rowData) => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            // acc += rowData[i]?.MW ?? 0;
            const mw = rowData[i]?.MW ?? 0;
            const price = rowData[i]?.Price ?? 0;
            acc += mw * price;

            if (i === 2 && rowData['2*']) {
                // acc += rowData['2*']?.MW ?? 0;
                const mw = rowData['2*']?.MW ?? 0;
                const price = rowData['2*']?.Price ?? 0;
                acc += mw * price;
            }

            return toTwoDecimalPlaces(acc);
        }, 0);
    }

    const baseColDefs = useMemo(() => ([
        {
            field: 'dealID',
            headerName: 'Deal ID',
            cellDataType: 'text',
        },
        { field: 'Deal_Number', headerName: 'Deal Name', },
        { field: 'Deal_Currency', headerName: 'Deal Currency', },
        {
            field: 'Total_MWh',
            initialHide: true,
            headerName: 'Total MWh',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            }
        },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
            initialHide: true,
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellDataType: 'number',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            }
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
        },
        {
            field: 'Contract',
            headerName: 'Contract',
        },
        {
            field: 'Status',
            headerName: 'Status',
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: ['VOIDED', 'CONFIRMED', 'DRAFT']
            },
        },
        {
            field: 'Counterparty',
            headerName: 'Counterparty',
        },
        {
            field: 'Market',
            headerName: 'Market',
        },
        {
            field: 'Term',
            headerName: 'Term',
        },
        {
            field: 'Start_Date',
            headerName: 'Start',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'End_Date',
            headerName: 'End',
            filter: 'agDateColumnFilter',
        },
        { field: 'Type_F_P', headerName: 'Transaction', },
        { field: 'Transaction_Type', headerName: 'Transaction Type', },
        { field: 'index_name', headerName: 'Index', },
        { field: 'Creator', headerName: 'Creator', },
        { field: 'indexType', headerName: 'RT/DA', },
        {
            field: 'por',
            headerName: 'POR',
        },
        {
            field: 'pod',
            headerName: 'POD',
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                const date = getValues('date');
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.MW ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Price Curve',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from(Object.keys(data))].reduce((acc, hour) => {
                    const date = getValues('date');
                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour]?.Price ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push({
                headerName: `${i}`,
                field: i,
                minWidth: 20,
                initialWidth: 50,
                children: defaultHourColChildren(i),
            });

            if (i === 2) {
                acc.push({
                    headerName: '2*',
                    field: '2*',
                    colId: '2*',
                    minWidth: 20,
                    initialWidth: 50,
                    children: defaultHourColChildren('2*').map(colDef => ({
                        ...colDef,
                        //hide: true,
                    })),
                });
            }

            return acc;
        }, []),
        {
            headerName: '24hr MW',
            valueGetter: params => {
                if (!!params.data) {
                    return rowMWTotal(params.data);
                }
            },
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
            aggFunc: 'sum',
            editable: false,
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
            minWidth: 100,
            filter: 'agNumberColumnFilter',
            colId: 'totalMW_agg',
        },
        {
            headerName: '24hr Settlement',
            valueGetter: params => {
                if (params.node.isRowPinned()) {
                    return params.getValue();
                } else if (!!params.data) {
                    return rowSettlementTotal(params.data);
                } else { //it's a group row, so we need to calculate the total of all the children
                    let total = 0;
                    params.node.allLeafChildren.forEach(child => {
                        total += rowSettlementTotal(child.data);
                    });
                    return total;
                }
            },
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                }
                return value;
            },
            editable: false,
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
            minWidth: 100,
            filter: 'agNumberColumnFilter',
        },
    ]), [defaultHourColChildren, getValues]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const silentRefresh = useCallback(async () => {
        enqueueSnackbar('Deal changes detected. Updating...', { variant: 'info' });
        return silentUpdate(getValues());
    }, [getValues, silentUpdate, enqueueSnackbar]);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['dealUpdate'],
        callbackDependencies: [headers],
        predicate: (obj) => {
            return (obj.tenantID.toString() === userInfo?.tenantId?.toString())
        },
        debounceOptions: { maxWait: 1000, leading: true, },
        wait: 500,
    });

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        colDefs.forEach(colDef => {
            if (colDef.children && !colDef.hide) {
                colDef.children.forEach(child => {
                    if (child.colId.includes('MW')) {
                        child.hide = !columnToggles.showMWColumns;
                    } else if (child.colId.includes('Price')) {
                        child.hide = !columnToggles.showPriceColumns;
                    }
                });
            }
        });

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules, columnToggles]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, paletteStorageKey),
        ]
    }), []);

    function onGridReady() {
        applyLayout();
    }

    const handleUpdateTotalRows = (api, totalColKey) => {
        const visibleRows = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            visibleRows.push(node.data);
        });

        if (!visibleRows?.length) return [];
        const totalRows = [visibleRows.reduce((acc, next) => {
            if (typeof next !== 'object') return acc;
            Array.from({ length: 26 }, (_, i) => i + 1).forEach(hour => {
                const mwSum = parseFloat(acc[hour]?.MW ?? 0) + parseFloat(next[hour]?.MW ?? 0);
                const priceSum = parseFloat(acc[hour]?.Price ?? 0) + parseFloat(next[hour]?.Price ?? 0);
                acc[hour] = {
                    MW: parseFloat(mwSum.toFixed(2)),
                    Price: parseFloat(priceSum.toFixed(2)),
                }
            });
            return acc;
        }, { [totalColKey]: 'Total' })];
        api.setPinnedBottomRowData(totalRows);
    };

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, styledColDefs[0]?.field ?? 'dealID');
    }, [styledColDefs]);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
            "sale-row": params => params.data?.Transaction_Type === 'Sale',
            "purchase-row": params => params.data?.Transaction_Type === 'Purchase',
        },
    }), []);

    const handleSelectionChanged = useCallback(() => {
        const selected = ref.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    useEffect(() => {
        ref.current.api?.onFilterChanged();
    }, [search]);

    const handleRowDataUpdated = useCallback((params) => {
        updateTotalRows(params);
        params.api.refreshCells({ columns: ['totalMW_agg'], force: true })
        params.api.onFilterChanged();
    }, [updateTotalRows]);

    function isExternalFilterPresent() {
        return search.get('filterNetZeros') === 'true';
    }

    function doesExternalFilterPass(node) {
        //recurse to find the most ancestral group node
        //if the group node has a totalMW of 0, return false
        const findGroupNode = (node) => {
            if (node.parent?.key) {
                return findGroupNode(node.parent);
            } else {
                return node;
            }
        }

        const groupNode = findGroupNode(node);
        if (groupNode.aggData) {
            const total = groupNode.aggData['totalMW_agg'];
            return total !== 0;
        } else {
            return true;
        }
    }

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '99%', }}
                ref={ref}
                getRowId={getRowId}
                gridOptions={gridOptions}
                enableCharts
                columnDefs={styledColDefs}
                onSelectionChanged={handleSelectionChanged}
                rowSelection="single"
                editType="fullRow"
                //onRowValueChanged={handleRowValueChanged}
                onCellValueChanged={handleCellValueChanged}
                stopEditingWhenCellsLoseFocus={true}
                rowMultiSelectWithClick
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={handleRowDataUpdated}
                onFirstDataRendered={handleFirstDataRendered}
                onCellMouseOver={handleCellMouseOverHighlight}
                groupTotalRow={"bottom"}
                suppressAggFuncInHeader={true}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                suppressLastEmptyLineOnPaste
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    )
});
