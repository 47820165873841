import { AgGridReact } from "ag-grid-react";
import { forwardRef, useCallback, useEffect, useMemo } from "react";
import _ from 'lodash';
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import useGridLayout from "../../useGridLayout";
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel";
import { Button, } from "@mui/material";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { tooltipRenderer, configureSparkline } from "./UtilFunctions";
import { defaultGridOptions } from "../../AgGrid/defaultGridProps";

export const AvailableTsrGrid = forwardRef((props, ref) => {
    const { rowData, handleCancelFetch, dropZoneParams, } = props;
    const storageLocation = 'PathwayBuilderAvailableTsrGrid';
    const theme = useTheme();

    const markerFormatter = (params) => {
        const { min, highlighted } = params;
        return {
            size: highlighted ? 3 : 1,
            enabled: true,
            fill: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
            stroke: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
        };
    };

    const baseColDefs = useMemo(() => [
        {
            headerName: 'TSR Key',
            valueGetter: (params) => {
                const { PointOfReceipt, Provider, PointOfDelivery } = params?.data ?? {};
                if (PointOfReceipt && Provider && PointOfDelivery) {
                    return `${params.data.PointOfReceipt}-${params.data.Provider}-${params.data.PointOfDelivery}`;
                } else {
                    return "";
                }
            },
            checkboxSelection: true,
            rowDrag: true,
        },
        {
            headerName: 'Provider',
            field: 'Provider',
        },
        {
            headerName: "PointOfReceipt",
            field: "PointOfReceipt",
        },
        {
            headerName: 'PointOfDelivery',
            field: 'PointOfDelivery',
        },
        {
            headerName: 'Path Name',
            field: 'PathName',
        },
        {
            headerName: 'Capacity',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data?.Capacity) {
                    return configureSparkline(JSON.parse(params.data.Capacity));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Offer Price',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data?.OfferPrice) {
                    return configureSparkline(JSON.parse(params.data.OfferPrice));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Price Unit',
            field: 'PriceUnit',
        },
        {
            headerName: 'Offer Increment',
            field: 'OFFER_INCREMENT',
        },
        {
            headerName: 'Start Time',
            field: 'OfferStartTimeUtc',
        },
        {
            headerName: 'Stop Time',
            field: 'OfferStopTimeUtc',
        },
        {
            headerName: 'Posting Ref',
            field: 'PostingRef',
        },
        {
            headerName: 'Related Ref',
            field: 'relatedRef',
        },
        {
            headerName: 'Other Curtailment Priority',
            field: 'OTHER_CURTAILMENT_PRIORITY',
        },
        {
            headerName: 'Request Type',
            field: 'RequestType',
            rowGroup: true,
            hide: true,
        },
        {
            headerName: 'Sale Ref',
            field: 'SaleRef',
        },
        {
            headerName: 'Seller Code',
            field: 'SellerCode',
        },
        {
            headerName: 'Service Increment',
            field: 'ServiceIncrement',
        },
        {
            headerName: 'TS Class',
            field: 'TSClass',
        },
        {
            headerName: 'TS Period',
            field: 'TSPeriod',
        },
        {
            headerName: 'TS Type',
            field: 'TSType',
        },
        {
            headerName: 'TS Window',
            field: 'TSWindow',
        },
        {
            headerName: 'TS Subclass',
            field: 'TSSubclass',
        },
        {
            headerName: 'Time of Last Update',
            field: 'TimeOfLastUpdateUtc',
        },
        {
            headerName: 'NERC Priority',
            field: 'NERCcurtailmentPriority',
        },
        {
            headerName: 'Seller Comments',
            field: 'SELLER_COMMENTS',
        },
        {
            headerName: 'Seller DUNS',
            field: 'SELLER_DUNS',
        },
        {
            headerName: 'Seller Email',
            field: 'SELLER_EMAIL',
        },
        {
            headerName: 'Seller Fax',
            field: 'SELLER_FAX',
        },
        {
            headerName: 'Seller Name',
            field: 'SELLER_NAME',
        },
        {
            headerName: 'Seller Phone',
            field: 'SELLER_PHONE',
        },
        {
            headerName: 'Interface Type',
            field: 'INTERFACE_TYPE',
        },
        {
            headerName: 'ANC SVC REQ',
            field: 'ANC_SVC_REQ',
        },
    ], []);

    const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

    useEffect(() => {
        loadLayout();
    }, []);

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: "agMultiColumnFilter",
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
    }), []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
                layoutPanel,
            ],
            position: 'right',
        }
    }, []);

    function onGridReady(params) {
        params.api.addRowDropZone(dropZoneParams);
    }

    useEffect(() => {
        const gridApi = ref.current?.api;
        if (gridApi) {
            gridApi.removeRowDropZone(dropZoneParams);
            gridApi.addRowDropZone(dropZoneParams);
        }
    });


    const noRowsOverlayText = 'No rows to display. Click "New Search" above to find transmission.'

    const loadingOverlay = props => {
        return <div style={{ position: 'relative', fontSize: '20px', top: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            Loading available TSRs...
            <Button onClick={handleCancelFetch} variant="contained" sx={{ maxWidth: '200px', mt: 2, }}>Cancel</Button>
        </div>
    }

    const initialGroupOrderComparator = useCallback((params) => {
        // sort groups by RequestType in desc order
        const a = params.nodeA.key || "";
        const b = params.nodeB.key || "";
        return a < b ? 1 : a > b ? -1 : 0;
    }, []);

    return (
        <AgGridReact
            {...defaultGridOptions}
            ref={ref}
            columnDefs={colDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            statusBar={statusBar}
            sideBar={sideBar}
            onGridReady={onGridReady}
            rowDragMultiRow
            rowSelection={"multiple"}
            groupDisplayType='groupRows'
            groupDefaultExpanded={1}
            initialGroupOrderComparator={initialGroupOrderComparator}
            overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">${noRowsOverlayText}</span>`}
            loadingOverlayComponent={loadingOverlay}
            components={{
                layoutToolPanel: LayoutToolPanel,
            }}
        />
    );
});