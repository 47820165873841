import React, { useMemo, useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/dayAheadStyles.css'
import Box from '@mui/material/Box';
// import { ReassignDialog } from './ReassignDialog';
// import { RedirectDialog } from './RedirectDialog';
import { useSnackbar } from 'notistack';
import CustomTooltip from './customTooltip.jsx';
import Tooltip from '@mui/material/Tooltip';
import { SmallDetailsToolPanel } from '../ToolPanels/SmallDetailsToolPanel';
// import axios from 'axios';
import { MenuItem, Paper, TableBody, TableContainer, Table, TableHead, TableRow, Grid, Typography, TextField } from "@mui/material";
import Select from '@mui/material/Select';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
// import ResizingPane from 'react-resizing-pane';
import { useData } from '../useData';
import { useViewPanel } from '../ToolPanels/useViewPanel'
import useHeader from '../useHeader';
import { AgGridReact } from 'ag-grid-react';
import { stringIntComparator } from '../../utils/gridFunctions';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const [tsrRowDetails, setTsrRowDetails] = useState({});
  const [showTsrDetails, setShowTsrDetails] = useState(false);
  const header = useHeader();
  const { handleMasterTimezoneChange, timezone } = props;
  const grid = "day-ahead-tsr";
  const { rowData, getData, saveData } = useData([]);
  const guid = useLocalGuid();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TSRfetch&parm=${timezone}&parm=${guid}`;
  const storageLocation = 'day-ahead-tsr-layout'

  const { enqueueSnackbar } = useSnackbar();
  //reassignments
  const [reassignOpen, setReassignOpen] = useState(false);
  const [reassignArefs, setReassignArefs] = useState(null);
  const [reassignValue, setReassignValue] = useState(null);

  const handleReassignClose = () => {
    setReassignOpen(false);
  };

  //redirects
  const [redirectOpen, setRedirectOpen] = useState(false);
  const [redirectArefs, setRedirectArefs] = useState(null);
  const [redirectValue, setRedirectValue] = useState(null);

  const handleRedirectClose = () => {
    setRedirectOpen(false);
  };

  const handleTimezoneChange = (event) => {
    handleMasterTimezoneChange(event);
  };

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const onRefreshBtn = () => {
    getData(loadUri);
    let message = 'TSRs Refreshing...';
    enqueueSnackbar(message);
  };

  useEffect(() => {
    getData(loadUri);

    const interval = setInterval(() => {

      let newDate = new Date()
      let minute = newDate.getMinutes();
      let second = newDate.getSeconds() + 1;

      if (minute === 1 & second === 1) { getData(loadUri) }

    }, 800)

    return () => clearInterval(interval)

  }, [timezone]);

  function onRowDataChanged(params) {
    const rowData = params.api.rowModel.rowsToDisplay;
    ref.current.api.setPinnedBottomRowData(rowData.filter((tsrs) => tsrs.pinned.includes("1")));
  }

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-fail": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-pass": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-waiting": params => params.api.getValue("Status", params.node) === 'QUEUED'
    },
  };

  const onTsrComponentStateChanged = (params) => {
    if (showTsrDetails) { params.api.openToolPanel('smallDetailsToolPanel') }
  };

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }



  function onTsrCellFocused(e) {

    if (e.rowPinned) {

      //const cell = e.api.getFocusedCell();
      //navigator.clipboard.writeText(e.floating);
      //e.api.copySelectedRangeToClipboard();

      //var focusedCell = gridOptions.api.getFocusedCell();
      //enqueueSnackbar(`${focusedCell} copied to the clipboard`)
      setTsrRowDetails('');

    } else {

      const i = e.rowIndex;
      const row = e.api.rowModel.rowsToDisplay[i];
      if (row?.data) {
        //row.data has the fields mapped to cell values, but we want headerNames mapped to cell values
        //(to make it look nice.) So first we create a mapping of fields to headerNames,
        //then use this to create the headerName:value mapping.
        const colDefs = e.columnApi.columnModel.columnDefs;
        const headerNames = colDefs.reduce(function (names, current) {
          names[current.field] = current.headerName;
          return names;
        }, {}); //headerNames looks like { field: headerName }

        const data = Object.keys(row?.data).reduce(function (data, field) {
          data[headerNames[field]] = row.data[field];
          return data;
        }, {}); //data looks like { headerName: cellValue }

        setTsrRowDetails(data);
      }

      const show = e.api.isToolPanelShowing('smallDetailsToolPanel');
      setShowTsrDetails(show);
    }

  };

  function onRowDragStart(event) {
    event.rowNode.setSelected(true);
    const rowData = ref.current.api.getSelectedRows();
    const jsonData = JSON.stringify(rowData);
    event.dragEvent.dataTransfer.setData('application/json', jsonData);
  };

  // const detailCellRendererParams = useMemo(() => {
  //   return {
  //     detailGridOptions: {
  //       columnDefs: [
  //         { field: 'TS_Class' },
  //         { field: 'Service_Increment' },
  //         { field: 'TS_Period',  },
  //         { field: 'TS_Window', },
  //         { field: 'TS_Subclass',  },
  //       ],
  //       defaultColDef: {
  //         width: 50,
  //       },
  //     },
  //     getDetailRowData: function (params) {
  //       params.successCallback(params.data);
  //     },
  //   };
  // }, []);

  const colDefs = [
    {
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      rowDrag: true,
      initialWidth: "170",
      initialHide: "false",
      cellRenderer: 'agGroupCellRenderer',
      headerName: "ARef",
      field: "AssignmentRef",
      comparator: stringIntComparator,
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    // {
    //   editable: false,
    //   headerName: "Cnts",
    //   initialWidth: "80",
    //   initialHide: "true",
    //   enableRowGroup: true,
    //   field: "mostUsed" ,
    //   filter: "agMultiColumnFilter",
    //   sortable:  true ,
    // },
    {
      editable: false,
      headerName: "DB",
      enableRowGroup: true,
      initialWidth: "60",
      initialHide: "true",
      field: "OASISInstance",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Status",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      tooltipField: "status",
      field: "Status",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter"
      sortable: true,
    },
    {
      editable: false,
      headerName: "TP",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "Provider",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "SC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Seller Code",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "Customer Code",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Path Name",
      enableRowGroup: true,
      initialWidth: "170",
      initialHide: "true",
      field: "Path_Name",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POR",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfReceipt",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "POD",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfDelivery",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Source",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Source",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Sink",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Sink",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Vol",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Volume",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Avail",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Availability",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    // {
    //   editable: false,
    //   headerName: "Avail2",
    //   enableRowGroup: true,
    //   initialWidth: "80",
    //   initialHide: "true",
    //   field: "Availability2" ,
    //   filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    //   sortable:  true ,
    // },
    {
      editable: false,
      headerName: "Service Inc",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Service_Increment",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Class",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Class",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Type",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Period",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Period",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Window",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Window",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Subclass",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Subclass",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      //filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "StartUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "StopUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start D",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start Date",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Start T",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Start Time",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop D",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop Date",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
      sortable: true,
    },
    {
      editable: false,
      headerName: "Stop T",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Stop Time",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Offer$",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "OfferPrice",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Sale Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Sale Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Request Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Deal Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Posting Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Request Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Related Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Seller Ref",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Pre",
      enableRowGroup: true,
      initialWidth: "80",
      initialHide: "true",
      field: "Preconfirmed",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CustName",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "CustEmail",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
  ];

  const { viewPanel, loadLayoutLocal } = useViewPanel(grid, ref, storageLocation, colDefs, true);

  const tsrSideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 180,
        maxWidth: 400,
        width: 250
      },
      {
        id: 'smallDetailsToolPanel',
        labelDefault: 'Details',
        labelKey: 'smallDetailsToolPanel',
        //iconKey: '',
        toolPanel: 'smallDetailsToolPanel',
        toolPanelParams: { rowData: tsrRowDetails },
        minWidth: 100,
        maxWidth: 300,
        width: 200
      },
      viewPanel,
    ],
    position: 'right',
  };

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayoutLocal();
  }

  return (

    <AgGridContainer
      style={{
        height: '88vh',
        width: "100%"
      }}
    >
      {/* <ReassignDialog 
        open={reassignOpen} 
        handleClose={handleReassignClose} 
        aRefs={reassignArefs} 
        value={reassignValue} 
        setValue={setReassignValue}
      />
      <RedirectDialog
        open={redirectOpen} 
        handleClose={handleRedirectClose} 
        aRefs={redirectArefs} 
        value={redirectValue} 
        setValue={setRedirectValue}
      /> */}
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Refresh the grid of TSRs, retreiving the most recently quaeried TSRs from OASIS." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>&nbsp;&nbsp;
        <Tooltip title="Select the timezone of interest.  It will be used in filtering/formating TSRs in the list below." arrow placement="top">
          <Select
            labelId="timezoneLabel"
            id="timezone"
            value={timezone}
            size={buttonSize}
            color={buttonColor}
            label="Timezone"
            onChange={handleTimezoneChange}
          >
            <MenuItem selected value={'Pacific Standard Time'}>Pacific</MenuItem>
            <MenuItem value={'Eastern Standard Time'}>Eastern</MenuItem>
            <MenuItem value={'Central Standard Time'}>Central</MenuItem>
            <MenuItem value={'Mountain Standard Time'}>Mountain</MenuItem>
          </Select>
        </Tooltip>
      </Box>
      <AgGridReact
        ref={ref}
        rowData={rowData}
        onGridReady={onGridReady}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        // masterDetail={true}
        // detailCellRendererParams={detailCellRendererParams}
        undoRedoCellEditingLimit={20}
        enableCellChangeFlash={true}
        groupDefaultExpanded={true}
        defaultColDef={defaultColDef}
        sideBar={tsrSideBar}
        gridOptions={gridOptions}
        onComponentStateChanged={onTsrComponentStateChanged}
        onCellFocused={onTsrCellFocused}
        animateRows={true}
        rowDragMultiRow={true}
        rowSelection='multiple'
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        tooltipShowDelay={0}
        components={{
          customTooltip: CustomTooltip,
          smallDetailsToolPanel: SmallDetailsToolPanel,
          viewToolPanel: GridViewToolPanel,
        }}
      />
    </AgGridContainer>
  );
});