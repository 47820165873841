import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default ({ name, control, ...inputProps }) => {
    return (
      <Controller
        render={({ field }) => <TextField {...field} {...inputProps}/>}
        name={name}
        control={control}
      />
    )
}