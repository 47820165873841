import { useEffect, useState, useRef, } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { apiUrlPrefix } from '../../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import "react-tabs/style/react-tabs.css"
import { Box, Tabs, Tab, Collapse } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import useHeader from '../../useHeader';
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import RedirectsGrid from './RedirectsGrid';
import EditCriteriaDialog from './EditCriteriaDialog';
import DivGuard from '../../Guards/DivGuard';
import { userGroups } from '../../../authConfig';
import moment from 'moment';

export default ({ visible }) => {
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const [tabIndex, setTabIndex] = useState(0);
  const [openEditCriteriaDialog, setOpenEditCriteriaDialog] = useState(false);
  const [views, setViews] = useState([]);
  const [editView, setEditView] = useState({});
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  useEffect(() => {
    fetchGridViews();
  }, []);

  async function fetchGridViews() {
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch&parm=${guid}&parm=dayaheadredirects`;
    const options = {
      headers: headers,
      url: loadUri,
    }
    axios(options).then(response => {
      const defaultView = {
        label: 'All Tsrs',
        justConfirmed: false,
      };

      const data = response.data?.map(view => {
        const v = JSON.parse(view.json ?? '{}');
        return {
          ...v,
          id: view.template,
        }
      });

      let newViews = [];
      if (!data.find(v => v.label === 'All Tsrs')) {
        newViews.push(defaultView);
      }
      newViews = [...newViews, ...data];
      setViews(newViews);
    }).catch(err => { enqueueSnackbar(`Error getting view data in Day Ahead Redirects.  ${err}`) });
  }

  function saveNewGridView(criteria) {
    const newView = {
      grid: 'dayaheadredirects',
      json: {
        ...criteria,
      },
      template: criteria.id ?? `${criteria.label} ${moment().format('MM/DD/YYYY HH:mm')}`,
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate&parm=${guid}&parm=${newView.grid}&parm=${newView.template}`;

    const options = {
      method: 'POST',
      headers: headers,
      data: newView.json,
      url: saveUri,
    }

    axios(options).then(response => {
      fetchGridViews()
    }).catch(err => { enqueueSnackbar(`Error saving Day Ahead Redirect view data.  ${err.response?.data}`) });
  }

  function handleTabSelect(event, newIndex) {
    if (newIndex <= views.length) { //last tab handles deletes, don't switch to it
      setTabIndex(newIndex);
    }
  }

  function handleDeleteCurrentTab() {
    const current = views[tabIndex];

    localStorage.removeItem(`day-ahead-redirects-grid-${current.id}`)

    const remaining = views?.filter(v => v.id !== current.id);
    if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
      handleEdit({});
    }
    setViews(remaining ?? []);
    setTabIndex(0);
    setOpenConfirmDelete(false);

    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete&parm=${guid}&parm=dayaheadredirects&parm=${current.id}`;
    const options = {
      headers: headers,
      url: deleteUri,
    }

    axios(options).catch(err => { enqueueSnackbar(`Error deleting view(s).  ${err}`) });
  }

  /*function handleAddNew(criteria) {
    setViews([...views, {
      id: `new-day-ahead-redirects-grid-${views.length}`,
      ...criteria
    }]);
    setTabIndex(views.length);
    setOpenAddNew(false);
    saveNewGridView(criteria);
  }*/

  function handleEdit(view) {
    setEditView(view);
    setOpenEditCriteriaDialog(true);
  }

  function handleSaveCriteria(criteria) {
    setOpenEditCriteriaDialog(false);
    saveNewGridView(criteria);
  }

  function handleCancelEdit() {
    setOpenEditCriteriaDialog(false);
  }

  return (
    <DivGuard groups={[userGroups.dayaheadredirects]} >
      <Box
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        <EditCriteriaDialog
          open={openEditCriteriaDialog}
          view={editView}
          handleSave={handleSaveCriteria}
          handleCancel={handleCancelEdit}
        />
        <ConfirmationDialog
          open={openConfirmDelete}
          message={`You are about to delete the current tab labelled ${views[tabIndex]?.label}. Continue?`}
          onCancel={() => setOpenConfirmDelete(false)}
          onConfirmation={handleDeleteCurrentTab}
        />
        <Box
          sx={{
            borderBottom: 1,
            bgcolor: 'background.paper',
            p: '0px',
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabSelect}
            indicatorColor="primary"
            textColor="primary"
            //minHeight="80px"
            maxHeight="10px"
            color="primary"
            backgroundColor="transparent"
          //variant="fullWidth"
          >
            {views.map((view) => (
              <Tab
                backgroundColor="transparent"
                label={view.label}
                id={`tsr-activity-tab-${view.id}`} />
            ))}
            <Tab id='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={() => handleEdit({})} />
            <Tab id='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} />
          </Tabs>
          {views.map((view, i) => (
            <Collapse in={tabIndex === i} orientation={'vertical'}>
              <RedirectsGrid
                visible={(tabIndex === i) && visible}
                view={view}
                handleEdit={() => handleEdit(view)}
              />
            </Collapse>
          ))}
        </Box>
      </Box>
    </DivGuard>
  );
}