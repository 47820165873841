import React from 'react';

export default (props) => {
 

  return (
    <div className="ag-status-name-value">
      <span className="component">After TransAssign:&nbsp;</span>
        <span className="ag-status-name-value-value">{props.api.getSelectedRows().length}&nbsp;MW</span>
    </div>
  );
};