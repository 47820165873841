import { useMemo } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography, IconButton, DialogActions, Button, } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SearchForm from './SearchForm';
import dayjs from 'dayjs';

export const SearchDialog = ({ open, close, fetch, }) => {
    const fetchCriteriaStorageKey = 'pathway-builder-fetch-criteria';
    const storedValues = JSON.parse(localStorage.getItem(fetchCriteriaStorageKey)) ?? {};

    const defaultValues = useMemo(() => ({
        timezone: 'Pacific',
        por: 'CSWS',
        pod: 'AECI',
        template: {
            tsClass: ['NON-FIRM'],
            tsIncrement: ['HOURLY'],
            tsType: ['POINT_TO_POINT'],
            tsPeriod: ['FULL_PERIOD'],
            tsWindow: ['FIXED'],
            tsSubclass: ['*'],
            excludePoints: [],
            excludeTp: [],
            ...storedValues.template,
        },
        ...storedValues,
        profile: [],
        profileConfig: {
            startDate: dayjs().startOf('day').add(1, 'day'),
            stopDate: dayjs().startOf('day').add(2, 'day'),
        },
    }), [open]);

    function onSubmit(data) {
        localStorage.setItem(fetchCriteriaStorageKey, JSON.stringify(data));
        fetch(data);
        close();
    }

    const formId = 'pathway-builder-fetch-criteria-form';

    return (
        <Dialog open={open} maxWidth='md'>
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Enter Search Criteria</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={close} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <SearchForm
                    defaultValues={defaultValues}
                    formId={formId}
                    submit={onSubmit}
                />
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={close}>Cancel</Button>
                <Button
                    form={formId}
                    type='submit'
                    variant='contained'
                >Search</Button>
            </DialogActions>
        </Dialog>
    );
}