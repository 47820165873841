import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import { DateCellEditor } from '../AgGrid/DateCellEditor';
import ProfileDetail from './ProfileDetail';
import moment from 'moment';
import Button from '@mui/material/Button';
import useGridLayout from '../useGridLayout';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TemplateToolPanel } from '../ToolPanels/TemplateToolPanel';
// import { Typography } from '@mui/material';
import CustomNoRowsOverlay from './customNoRowsOverlay.js';
//import { useLocalData } from '../useLocalData';
import { AgGridReact } from 'ag-grid-react';
import { LayoutToolPanel } from "../ToolPanels/LayoutToolPanel";
import useHeader from '../useHeader';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { logAction } from '../../utils/auditLogger';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const { addTemplate, templates, deleteTemplate, timezone, toggle, } = props;
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',];

  const [templateData, setTemplateData] = useState([]);
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const transmissionTemplateTypeId = 2;

  const [selectedRows, setSelectedRows] = useState([]);
  const [actOnConfirm, setActOnConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirmation, setOnConfirmation] = useState(() => { });
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  //const [reassignmentRowData, setReassignmentRowData] = useState([]);

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const storageLocation = 'real-time-reassignments-layout';
  const headers = useHeader();
  //useLocalData(storageLocation, ref);


  useEffect(() => {
    window.addEventListener('beforeunload', saveDataLocal);
    return () => {
      window.removeEventListener('beforeunload', saveDataLocal);
    }
  }, []);

  function saveDataLocal() {
    const data = [];
    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      data.push(newData);
    })
    localStorage.setItem(storageLocation + 'Data', JSON.stringify(data ?? []));
  };

  function loadDataLocal() {
    try {
      const dataObject = localStorage.getItem(storageLocation + 'Data');
      const data = JSON.parse(dataObject);
      const rowData = data ?? [];
      ref.current.api.applyTransaction({
        add: rowData,
        addIndex: 0,
      });
    } catch (err) {
      enqueueSnackbar(`Error loading data from ${storageLocation ?? 'local storage'}. Message: ${err}`);
    }
  };

  const baseColDefs = [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "New Aref",
      headerCheckboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      rowDrag: true,
      //dndSourcce: true,
      initialWidth: 150,
      field: "New Aref",
    },
    {
      editable: false,
      headerName: "Status",
      initialWidth: 100,
      field: "Status",
      tooltipField: "Result",
    },
    {
      editable: false,
      headerName: "TransAref",
      enableRowGroup: true,
      initialWidth: 100,
      field: "Reassigned Ref",
      filter: "agSetColumnFilter",
      filter: true,
    },
    {
      headerName: "TP",
      initialWidth: 80,
      field: "Provider",
    },
    {
      headerName: "Cust",
      initialHide: false,
      initialWidth: 80,
      field: "Customer Code",
    },
    {
      headerName: "Start D",
      initialHide: false,
      initialWidth: 80,
      field: "Start Date",
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        valueFormat: 'MM/DD/YYYY',
        closeOnSelect: true,
      },
    },
    {
      headerName: "Start T",
      initialHide: false,
      initialWidth: 80,
      field: "Start Time",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: hours,
      },
    },
    {
      headerName: "Stop D",
      initialHide: false,
      initialWidth: 80,
      field: "Stop Date",
      cellEditorPopup: true,
      cellEditor: 'dateEditor',
      cellEditorParams: {
        valueFormat: 'MM/DD/YYYY',
        closeOnSelect: true,
      },
    },
    {
      headerName: "Stop T",
      initialHide: false,
      initialWidth: 80,
      field: "Stop Time",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        values: hours,
      },
    },
    {
      headerName: "Vol",
      initialHide: false,
      initialWidth: 60,
      field: "Volume",
    },
    {
      headerName: "$$",
      initialHide: false,
      initialWidth: 60,
      field: "Price",
    },
    {
      headerName: "Seller",
      enableRowGroup: true,
      initialHide: false,
      initialWidth: 80,
      field: "Seller Code",
      filter: "agSetColumnFilter",
      filter: true,
    },
    {
      headerName: "SRef",
      initialHide: false,
      initialWidth: 80,
      field: "Sale Ref",
    },
    {
      headerName: "Pre",
      initialHide: false,
      initialWidth: 60,
      field: "Preconfirmed",
    },
    {
      editable: false,
      headerName: "Path Name",
      enableRowGroup: true,
      initialWidth: "170",
      initialHide: "true",
      field: "Path_Name",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "POR",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfReceipt",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "POD",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "false",
      field: "PointOfDelivery",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Source",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Source",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Sink",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "Sink",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Service Inc",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Service_Increment",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Class",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Class",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Type",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Type",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Period",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Period",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Window",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Window",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "Subclass",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "TS_Subclass",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRStartUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StartUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
    },
    {
      editable: false,
      headerName: "TSRStopUTC",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "StopUTC",
      filter: 'agDateColumnFilter',
      filterParams: { filterParams },
    },
    {
      editable: false,
      headerName: "TSRRequestRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Request Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRDealRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Deal Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRPostingRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Posting Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRRequestType",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "RequestType",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRRelatedRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "Related Ref",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRSellerRef",
      enableRowGroup: true,
      initialWidth: "100",
      initialHide: "true",
      field: "SellerRef",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRCustName",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_NAME",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: false,
      headerName: "TSRCustEmail",
      enableRowGroup: true,
      initialWidth: "150",
      initialHide: "true",
      field: "CUSTOMER_EMAIL",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
  ];

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

  const addReassignmentItems = (addIndex) => {
    const newItems = [
      createNewReassignmentRowData(),
    ];
    addReassignmentRow(newItems);

  };

  const addReassignmentRowCopy = (addIndex) => {
    ref.current.api.stopEditing();

    //ecz 2/9/22 - the following two lines don't work because selectedData was copied by reference,
    //so changing selectedData in one row changes it in another. We apply an 'identity map' to clone the data.
    //const selectedNodes = gridRef.current.api.getSelectedNodes();
    //const selectedData = selectedNodes.map(node => node.data);
    const selectedData = ref.current.api.getSelectedRows();

    const data = selectedData.map(function (rowData) {
      const newRow = { ...rowData }
      newRow['New Aref'] = "";
      newRow['Status'] = "";
      newRow['Result'] = "";
      return newRow;
    })

    addReassignmentRow(data);
  };

  const onRemoveReassignmentSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
  };

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    ref.current.api.forEachNode(node => rowData.push(node.data));
    let message = 'JSON for entire grid generated.';

    alert(`Selected nodes: ${JSON.stringify(rowData)}`);
    message = `Selected nodes: ${JSON.stringify(rowData)}`;
    enqueueSnackbar(message);

  }

  function renderTimezone(param) {
    switch (param) {
      case 'Pacific Standard Time':
        return 'US/Pacific';
      case 'Eastern Standard Time':
        return 'US/Eastern';
      case 'Central Standard Time':
        return 'US/Central';
      case 'Mountain Standard Time':
        return 'US/Mountain';
    }
  }

  async function onSaveDataButtonClick() {
    const hasDatesInThePast = ref.current.api.rowModel.rowsToDisplay.some(row => row.isSelected() && (moment(row.data['Start Date']).isBefore(moment(), 'day') || moment(row.data['Stop Date']).isBefore(moment(), 'day')));
    if (hasDatesInThePast) {
      setActOnConfirm(true);
      setConfirmationMessage('You are submitting reassignments with dates in the past. Are you sure you want to continue?');
      setOnConfirmation(() => () => {
        handleSubmit();
        setOpenConfirmationDialog(false);
      });
      //setConfirmationAction(action);
      setOpenConfirmationDialog(true);
    } else {
      handleSubmit();
    }
  }

  async function handleSubmit() {
    enqueueSnackbar('Submitting reassignment to OASIS...');
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkTransAssignRealTime?UserGuid=${guid}&timeZone=${renderTimezone(timezone)}&Caller=PowerStation.RealTime`;
    handlePost(url).then(response => {
      enqueueSnackbar('Reassignment sent to OASIS database.');
    });
  }

  function handleConfirm() {
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkConfirm?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`
    enqueueSnackbar('Submitting confirmation to OASIS...');
    handlePost(url).then(response => {
      enqueueSnackbar('Confirmation sent to OASIS database.');
    });
  }

  function handleWithdraw() {
    const url = `${apiUrlPrefix}/CrystalBall/TSR/BulkWithdraw?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`
    enqueueSnackbar('Submitting withdrawal to OASIS...');
    handlePost(url).then(response => {
      enqueueSnackbar('Withdrawal sent to OASIS database.');
    });
  }

  async function handlePost(url) {
    setButtonsDisabled(true);
    setSubmitButtonDisabled(true);

    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();


    const rowData = [];

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      newData['isExpanded'] = node.expanded;
      newData.priceInfo = undefined;
      newData.Price = Number(newData.Price ?? 0).toFixed(2);
      rowData.push(newData);
    })

    const options = {
      url: url,
      data: JSON.stringify(rowData),
      headers: {
        headers: headers,
      },
    }

    return axios.post(options.url, options.data, options.headers).then(response => {
      if (response?.data && !(typeof response.data === 'string')) {
        handleResponse(response.data);
        ref.current.api.hideOverlay();
        setButtonsDisabled(false);
      } else {
        throw new Error(response?.data ?? 'No response data');
      }
    }).catch(err => {
      enqueueSnackbar(`${err}`);
      logAction(`Real Time Reassign error. ${err}`, 'RealTimeReassignments', headers.userGuid);
      ref.current.api.hideOverlay();
      setButtonsDisabled(false);
    });
  }

  function handleResponse(newData) {
    const rowData = [];
    ref.current.api.forEachNode(function (node) {
      rowData.push(node.data);
    });
    ref.current.api.applyTransaction({
      remove: rowData,
      add: newData,
      addIndex: 0,
    });
    ref.current.api.forEachNode(function (node) {
      node.setSelected(node.data.isSelected);
      node.setExpanded(node.data.isExpanded);
    })
  }

  let newCount = 1;
  function createNewReassignmentRowData() {
    const newData = {

    };
    newCount++;
    return newData;
  }

  function addReassignmentRow(newRowData) {
    const res = ref.current.api.applyTransaction({
      add: newRowData,
    });
  };

  const gridOptions = {
    // [...]
    rowClassRules: {
      "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
      "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
      "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
      "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
      "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
      "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
    },

  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const reassignmentSideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 225,
        maxWidth: 225,
        width: 225
      },
      {
        id: 'reassignmentTemplates',
        labelDefault: 'Templates',
        labelKey: 'reassignmentTemplates',
        //iconKey: '',
        toolPanel: 'templateToolPanel',
        toolPanelParams: {
          fieldNames: ['PUD', 'Sale Ref', 'name'],
          currentTemplates: templates,
          addTemplate: addTemplate,
          deleteTemplate: deleteTemplate
        },
        minWidth: 180,
        maxWidth: 400,
        width: 300
      },
      layoutPanel
    ],
    position: 'right',
  };

  const statusResult = (params) => {

    var status = "Success";
    if (params.data.Status === "" & params.data.Result !== "") { status = "ERROR" }
    else if (params.data.Status === "Pre-Queued" & params.data.Result !== "") { status = "ERROR" }
    else { status = params.data.Status }

    return status;
  };

  var hourValues = {
    values: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
  }


  function onReassignmentCellClicked(e) {
    if (e.column.colDef.headerName === 'Profile') {
      ref.current.api.openToolPanel('profile');
    }
    if (e.column.colDef.headerName === 'Service') {
      ref.current.api.openToolPanel('service');
    }
  }

  function generateHourlyBlocks(config) {
    let blocks = [];
    let next = moment(config.startDate).hour(config.startHour).startOf('hour');
    const end = moment(config.stopDate).hour(config.stopHour, 'hours').startOf('hour');
    while (end.isAfter(next, 'hour')) {
      blocks.push({
        startDateTime: moment(next).format('MM/DD/YYYY HH:mm'),
        endDateTime: moment(next).add(1, 'hours').format('MM/DD/YYYY HH:mm'),
        capacityRequested: config.capacity,
        price: config.price,
      });
      next = moment(next).add(1, 'hour');
    }
    return blocks;
  }

  function onRowDataUpdated(params) {
    const rowNum = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowNum === 0);
    const selectedRowCount = params.api.getSelectedRows().length;
    setSubmitButtonDisabled(selectedRowCount === 0);

    const toUpdate = [];
    ref.current.api.forEachNode(node => {
      const data = node.data;
      if (!data.profileInfo?.length && data['Start Date'] && data['Stop Date']) {
        node.data.profileInfo = generateHourlyBlocks({
          startDate: moment(data['Start Date']),
          stopDate: moment(data['Stop Date']),
          startHour: parseInt(data['Start Time'].split(':')[0]), //for example this takes '16:00' -> 16
          stopHour: parseInt(data['Stop Time'].split(':')[0]),
          capacity: data.Volume,
          price: data.Price,
        });
        toUpdate.push(node.data)
      }
    })

    toUpdate.length && ref.current.api.applyTransaction({
      update: toUpdate,
    })
  }

  const [disableConfirm, setDisableConfirm] = useState(true);
  const [disableWithdraw, setDisableWithdraw] = useState(true);
  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    const selectedRowCount = selected.length;
    setSubmitButtonDisabled(selectedRowCount === 0);
    setSelectedRows(selected);

    const statuses = selected.map(row => row.Status);
    if (statuses.length) {
      setDisableConfirm(!statuses.every(status => ['COUNTEROFFER', 'ACCEPTED'].includes(status)));
      setDisableWithdraw(!statuses.every(status => ['COUNTEROFFER', 'ACCEPTED', 'REBID', 'STUDY', 'QUEUED', 'RECEIVED'].includes(status)));
    }
  }

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);


  // const noRowsOverlayComponentParams = useMemo(() => {
  //   return {
  //     noRowsMessageFunc: () => 'Sorry - no rows! at: ' + new Date(),
  //   };
  // }, []);

  var filterParams = {
    // provide comparator function
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // In the example application, dates are stored as dd/mm/yyyy
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  }

  function handleConfirmAction(action) {
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/Bulk${action}?userGuid=${guid}&timeZone=${renderTimezone(timezone)}&caller=PowerStation.RealTime`

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      if (response.data && response.data[0]) {
        const { goForward, userMessage } = response.data[0];
        setActOnConfirm(!!goForward);
        setConfirmationMessage(userMessage);
        setConfirmationAction(action);
        setOnConfirmation(() => handleConfirmation);
        setOpenConfirmationDialog(true);
      } else {
        enqueueSnackbar('Error- api fetch returned no data.')
      }
    }).catch(error => {
      enqueueSnackbar(`Error ${action}ing TSR. ${error.message}`)
    });
  }

  function handleConfirmation() {
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/${confirmationAction}?userGuid=${guid}&aref=${selectedRows[0]['New Aref']}&primaryProvider=${selectedRows[0].Provider}`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }

    axios(options).then(response => {
      enqueueSnackbar(`Success`)
      setOpenConfirmationDialog(false);
    }).catch(error => {
      // enqueueSnackbar(`${error.message}`)
      var partsArray = error.response?.data.split('More info follows:');
      enqueueSnackbar(`ERR: ${partsArray[0]} MESSAGE: ${error.message} URL: ${options.url} `)
      setOpenConfirmationDialog(false);
    });
  }

  function onGridReady(params) {
    loadDataLocal();
    loadLayout();
  }

  function onCellValueChanged(params) {
    const node = params.node;
    if (['Start Time', 'Start Date', 'Stop Date', 'Stop Time'].includes(params.colDef.field)) {
      const data = params.data;
      node.data.profileInfo = generateHourlyBlocks({
        startDate: moment(data['Start Date']),
        stopDate: moment(data['Stop Date']),
        startHour: parseInt(data['Start Time'].split(':')[0]), //for example this takes '16:00' -> 16
        stopHour: parseInt(data['Stop Time'].split(':')[0]),
        capacity: data.Volume,
        price: data.Price,
      })
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    }
  }

  function onCellEditingStopped(params) {
    const node = params.node;
    if (params.newValue !== params.oldValue && params.colDef.field === 'Volume') {
      node.data.profileInfo = node.data.profileInfo.map(block => ({
        ...block,
        capacityRequested: params.newValue,
      }))
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    } else if (params.newValue !== params.oldValue && params.colDef.field === 'Price') {
      node.data.profileInfo = node.data.profileInfo.map(block => ({
        ...block,
        price: params.newValue,
      }))
      ref.current.api.applyTransaction({
        update: [node.data],
      })
    }
  }

  const defaultColDef = useMemo(() => ({
    editable: params => !params.data['New Aref'],
    filter: "agSetColumnFilter",
    filter: true,
    sortable: true,
    resizable: true,
  }), [])

  return (
    <div
      style={{
        height: "41vh",
        width: "98%",
        padding: "5px",
        // clear: "both",
        // overflow: "hidden",
      }}
    >
      <ConfirmationDialog
        open={openConfirmationDialog}
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={onConfirmation}
        confirmText={actOnConfirm ? 'YES' : 'OK'}
        singleAction={!actOnConfirm}
      />
      <Box sx={{ display: 'flex', p: 1, }}>
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveReassignmentSelected()}
            endIcon={<DeleteForeverIcon />}
            id="delete"
            disabled={submitButtonDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Add a new blank row to the grid." arrow placement="top">
          <Button
            endIcon={<AddCircleOutlinedIcon />}
            id="addReassignmentRow"
            variant={buttonVariant}
            //disabled={buttonsDisabled}
            disabled
            color={buttonColor}
            size={buttonSize}
            onClick={() => addReassignmentItems()}
          >Add New</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Make a duplicate row in the grid just like the currently selected row." arrow placement="top">
          <Button
            endIcon={<ContentCopyIcon />}
            id="addReassignmentRowCopy"
            variant={buttonVariant}
            disabled={submitButtonDisabled}
            color={buttonColor}
            size={buttonSize}
            onClick={addReassignmentRowCopy}
          >Add Copy</Button>
        </Tooltip>&nbsp;

        <Tooltip title="Submit all reassignments without New TSR number and check status on TSRs with New TSR numbers." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            disabled={submitButtonDisabled}
            size={buttonSize}
            onClick={onSaveDataButtonClick}>Submit/Status Check</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Select a TSR and click here to confirm it." arrow placement="top">
          <Button
            endIcon={<CheckIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={disableConfirm}
            onClick={handleConfirm}
          >CONFIRM</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Select a TSR and click here to withdraw it." arrow placement="top">
          <Button
            endIcon={<UndoIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={disableWithdraw}
            onClick={handleWithdraw}
          >WITHDRAW</Button>
        </Tooltip>&nbsp;
        <Box sx={{ flexGrow: 1, }} />
        <Tooltip title='Click to hide the reassignments grid.' arrow placement='top'>
          <IconButton onClick={toggle} size="small">
            <RemoveIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {/* <SaveTemplateDialog 
          templateData={templateData}
          open={saveTemplateOpen}
          handleClose={handleSaveTemplateClose}
          transmissionTemplateTypeId={transmissionTemplateTypeId}/> */}
      <AgGridContainer
        style={{ width: "100%", height: '100%' }}
      >
        <AgGridReact
          ref={ref}
          columnDefs={colDefs}
          storageLocation={storageLocation}
          singleClickEdit
          onCellValueChanged={onCellValueChanged}
          statusBar={statusBar}
          overlayNoRowsTemplate={'<span style="width: 70%; font-size: 20px">No reassignments queued... drag a TSR into this area or use Trans Assign templates to queue up new reassignments  If you have no TransAssign templates, you can create a new one using the templates tool panel on the right side of this grid.</span>'}
          rowSelection="multiple"
          enableFillHandle={true}
          undoRedoCellEditing={true}
          onCellEditingStopped={onCellEditingStopped}
          //enable the next line to left them select the new aref number
          //enableCellTextSelection={true}
          undoRedoCellEditingLimit={20}
          defaultColDef={defaultColDef}
          sideBar={reassignmentSideBar}
          gridOptions={gridOptions}
          tooltipShowDelay={0}
          rowDragMultiRow
          onGridReady={onGridReady}
          //rowDragManaged={true}
          onRowSelected={onSelectionChanged}
          onRowDataUpdated={onRowDataUpdated}
          onCellClicked={onReassignmentCellClicked}
          stopEditingWhenCellsLoseFocus
          suppressRowClickSelection
          enableRangeSelection={true}
          animateRows={true}
          //master/detail
          masterDetail={true}
          detailRowAutoHeight
          //getRowHeight={getRowHeight}
          detailCellRenderer={'detailCellRenderer'}
          detailCellRendererParams={{
            includePrice: true,
            editable: data => !data['New Aref'],
            allowRelinquish: false,
          }}
          components={{
            layoutToolPanel: LayoutToolPanel,
            templateToolPanel: TemplateToolPanel,
            dateEditor: DateCellEditor,
            detailCellRenderer: ProfileDetail,
          }}
        />
      </AgGridContainer>
    </div>
  );
});
