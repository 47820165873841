import CornerRibbon from 'react-corner-ribbon';
import { Button, Stack, Typography, } from '@mui/material';
import { useContext, } from "react";
import { UserContext } from "../data/fetchUserGroups";
import { useTheme } from '@emotion/react';

export default (props) => {
  const { message, buttonText, href, groups } = props;
  const userGroups = useContext(UserContext);
  const authorized = userGroups.find(grp => groups.includes(grp));
  const display = authorized ? 'none' : 'block'; //hide the ribbon if the user is in the correct group
  const theme = useTheme();

  return (
    <div style={{ display: display }}>
      <CornerRibbon
        style={{ fontSize: 20, p: 5 }}
        position="top-right"
      >
        <Stack>
          <Typography>{message}</Typography>
          <Button
            size="large"
            sx={{ color: theme.palette.primary.white }}
            href={href}
          >{buttonText}</Button>
        </Stack>
      </CornerRibbon>
    </div>
  )
}