import { RibbonButton } from "../Ribbon";
import Refresh from "@mui/icons-material/Refresh";
import { Tooltip } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const RefreshButton = ({ formId, expanded, onRefresh, ...buttonProps }) => {
    const { handleSubmit } = useFormContext();

    function handleRefresh() {
        handleSubmit(onRefresh)();
    }

    return (
        <Tooltip title="Refresh the data in the grid.">
            <span>
                <RibbonButton
                    id={`${formId}-refresh`}
                    icon={<Refresh fontSize='small' />}
                    label={'Refresh'}
                    expanded={expanded}
                    onMouseDown={handleRefresh}
                    {...buttonProps}
                />
            </span>
        </Tooltip>
    )
}