import { ChatbotContextManagement } from './ChatbotContextManagement';
import { ChatbotTrainingManagement } from './ChatbotTrainingManagement';
import { Typography, Box, Accordion, AccordionDetails, AccordionSummary, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ChatbotManagement = () => {
    return (
        <Box sx={{ paddingTop: 2, display: 'flex', flexDirection: 'column', }}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Chatbot Context</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: '3px', }}>
                    <ChatbotContextManagement />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Chatbot Training</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingBottom: '3px', }}>
                    <ChatbotTrainingManagement />
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}