import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { useActionAudit } from '../../useActionAudit';

export default (storageLocation, gridRef, baseColDefs) => {
    const [colDefs, setColDefs] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();

    const fields = ['field', 'width', 'hide', 'sort', 'order', ];

    function saveLayoutLocal() {
        const defs = gridRef.current.api.getColumnDefs();
        const state = defs.map((def, i) => {
            const defLayout = {};
            fields.forEach(field => {
                defLayout[field] = def[field];
            });
            defLayout.order = i;
            defLayout.hide = def.hide ? def.hide : false; //set undefined value to false so it displays properly after saving/loading
            return defLayout;
        });
        const filters = gridRef.current.api.getFilterModel();
        localStorage.setItem(storageLocation, JSON.stringify({ filters: filters, state: state }));
        enqueueSnackbar('Layout saved.');
        logAction(`Layout saved`, storageLocation, state);
    };

    function loadLayout() {
      try {
        updateFromStorage();
      } catch (err) {
        enqueueSnackbar(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`);
        logAction(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, storageLocation);
      }
    };

    const deleteLayoutLocal = () => {
      clearLayout();
      localStorage.removeItem(storageLocation);
      enqueueSnackbar('Local layout deleted.');
    };
  
    const clearLayout = () => {
      gridRef.current.columnApi.resetColumnState();
      let defaultDefs = [...baseColDefs];

      //reset hidden columns to initial state.
      defaultDefs.forEach(def => def.hide = def.initialHide ?? def.hide);

      //reset sorting on all columns
      gridRef.current.columnApi.applyColumnState({
        defaultState: { sort: null }
      });

      setColDefs(defaultDefs);
      enqueueSnackbar('Layout reset.');
    };

    useEffect(() => {
        updateFromStorage();
    }, []);

    function updateFromStorage() {
      try {
        const savedLayout = JSON.parse(localStorage.getItem(storageLocation) ?? '[]');
    
        const defs = baseColDefs.map(def => {
          const newDef = { ...def };
          const defLayout = savedLayout?.state?.find(layout => layout.field === def.field);
          if(defLayout) {
            fields.forEach(field => {
              newDef[field] = defLayout[field];
            }); 
          }
          return newDef;
        });

        const sortedDefs = defs.sort((def1, def2) => def1.order - def2.order);

        if(gridRef?.current?.api) {
          gridRef.current.api.setFilterModel(savedLayout?.filters);
        }

        setColDefs(sortedDefs);
      } catch (err) {
        enqueueSnackbar(`Error loading saved layout. ${err}`);
        gridRef?.current && clearLayout();
      }
    }

    //save layout when window unloads
    useEffect(() => {
      window.addEventListener('beforeunload', saveLayoutLocal)
      return () => {
        window.removeEventListener('beforeunload', saveLayoutLocal)
      }
    }, []);

    const layoutPanel = {
      id: 'layout',
      labelDefault: 'Layout',
      labelKey: 'layout',
      toolPanel: 'layoutToolPanel',
      toolPanelParams: {
        onLayoutClear: clearLayout,
        onLayoutLoad: loadLayout,
        onLayoutSave: saveLayoutLocal,
        onLayoutDelete: deleteLayoutLocal
      },
      minWidth: 180,
      maxWidth: 400,
      width: 200
    }

    return {
        layoutPanel,
        colDefs,
        loadLayout,
    };
}