import { RibbonButton, RibbonGroupContainer } from "../../Ribbon"
import { RefreshButton } from "../../RibbonButtons"
import BarChartIcon from '@mui/icons-material/BarChart';

export const GridActionsGroup = ({ formId, expanded, setShowChart, showChart, handleRefresh }) => {
    return (
        <RibbonGroupContainer>

            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleRefresh}
            />

            {/*<RibbonButton
                onMouseDown={() => setShowChart(!showChart)}
                icon={<BarChartIcon fontSize='small' />}
                disabled
                label={showChart ? 'Hide Chart' : 'Show Chart'}
                expanded={expanded}
            />*/}

        </RibbonGroupContainer>
    )
}