import { Box, } from "@mui/material"
import { useMemo, useCallback, useState, useEffect, memo } from 'react'
import Scheduler from "../SpyMonkey/Scheduler/Scheduler";

const Calendar = () => {
    const events = [];

    const defaultView = localStorage.getItem('tsrCalendarDefaultView');
    const [alerts, setAlerts] = useState({
        open: false,
        color: "info",          // info | success | warning | error
        severity: "info",       // info | success | warning | error
        message: "🚀 TSR will be submitted at 10PM tonight ERCOTE-CSWS 31MW 🔥 🔥 🔥",
        showActionButton: true,
        showNotification: true,
        delay: 1500,
    });

    const options = useMemo(() => {
        return {
            transitionMode: "zoom", // or fade
            startWeekOn: "sun",     // or mon
            defaultMode: defaultView ?? "month",    // or week | day | timeline
            minWidth: 800,
            minHeight: 1200,
            minHeight: 800,
            maxHeight: 1200
        }
    }, []);

    const toolbarProps = useMemo(() => {
        return {
            showSearchBar: false,
            showSwitchModeButtons: true,
            showDatePicker: true
        }
    }, [])

    function handleClick(item) {
        console.log(item);
    }

    const handleEventClick = useCallback((event, item) => {
        handleClick(item);
    }, []);

    return (
        <div>
            <Box sx={{ height: '80vh', width: '100%', }}>
                <Scheduler
                    events={events}
                    alertProps={alerts}
                    options={options}
                    toolbarProps={toolbarProps}
                    onTaskClick={handleEventClick}
                />
            </Box>
        </div>
    )
}

export const AuctionCalendar = memo(Calendar);