import { Button, Grid, Box, TextField, Dialog, Slide, MenuItem, } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useMemo, forwardRef, useEffect, } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useFilteredOptions from './useFilteredOptions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, date, } from 'yup';
import FormAutocomplete from '../FormControls/FormAutocomplete';
import FormDatePicker from '../FormControls/FormDatePicker';
import dayjs from 'dayjs';

export default (props) => {
  const { open, handleCancel, handleAddNew, viewToUpdate, } = props;
  const hours = [...Array(24).keys()];

  const schema = object().shape({
    label: string().required(),
    startDate: date().nullable().typeError('Please enter a valid date.'),
    stopDate: date().nullable().typeError('Please enter a valid date.'),
    timezone: string().required('Please select a timezone.'),
  });

  const defaultValues = useMemo(() => ({
    timezone: 'Pacific Standard Time',
    ...viewToUpdate,
    startDate: viewToUpdate.startDate ? dayjs(viewToUpdate.startDate) : null,
    stopDate: viewToUpdate.stopDate ? dayjs(viewToUpdate.stopDate) : null,
  }), [viewToUpdate]);

  const { register, control, handleSubmit, formState: { errors }, getValues, reset, watch, } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const por = watch('POR');
  const pod = watch('POD');
  const timezone = watch('timezone');
  const provider = watch('Provider');
  const serviceIncrement = watch('Service_Increment');
  const { providers, filteredCombos, customerCodes, filteredTSCombos, requestTypes } = useFilteredOptions({ 
    'POR': por, 
    'POD': pod, 
    'Provider': provider, 
    'Service_Increment': serviceIncrement, 
  });

  function onSubmit(data) {
    handleAddNew(data);
  }

  useEffect(() => {
    reset(defaultValues);
  }, [viewToUpdate]);

  return (
    <Dialog maxWidth="lg" open={open} TransitionComponent={Transition}>
      <DialogTitle>{viewToUpdate.label ? 'Edit View Filters' : 'Add New View'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the desired filter criteria for this view.
        </DialogContentText>
          <form onSubmit={handleSubmit(onSubmit)} id='add-new-tsr-activity-tab-form'>
            <Grid container columns={36} spacing={2} alignItems='center' sx={{ p: 1, }}>
              <Grid item xs={10}>
                <Tooltip title="Label for the TSR Activity tab." arrow placement="top">
                  <TextField 
                    {...register('label')}
                    color="primary"
                    label="Label"
                    error={!!errors.label}
                    helperText={errors.label?.message}
                    disabled={!!viewToUpdate.label}
                    fullWidth
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={26}>
                <TextField 
                  {...register('timezone')}
                  label='Timezone'
                  select
                  value={timezone}
                  sx={{ minWidth: '200px', }}
                  error={!!errors.timezone}
                  helperText={errors.timezone?.message}
                >
                  <MenuItem value='Pacific Standard Time'>Pacific</MenuItem>
                  <MenuItem value='Mountain Standard Time'>Mountain</MenuItem>
                  <MenuItem value='Central Standard Time'>Central</MenuItem>
                  <MenuItem value='Eastern Standard Time'>Eastern</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <FormAutocomplete
                  name={'requestType'}
                  control={control}
                  fullWidth
                  options={requestTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Request Type"
                      color="success" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <FormAutocomplete
                  name={'Provider'}
                  control={control}
                  freeSolo
                  fullWidth
                  options={providers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="Provider"
                      color="success" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutocomplete
                  name={'POR'}
                  control={control} 
                  fullWidth
                  freeSolo
                  options={filteredCombos.pors}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="POR"
                      color="success" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutocomplete
                  name={'POD'}
                  control={control}
                  fullWidth
                  freeSolo
                  options={filteredCombos.pods}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="POD"
                      color="success" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={14}>
                <FormAutocomplete
                  name={'customerCode'}
                  control={control}
                  fullWidth
                  multiple
                  options={customerCodes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size='small'
                      label="Customer Code"
                      color="success" 
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7}>
                <FormDatePicker
                  name={'startDate'}
                  control={control}
                  label="Start Date"
                  views={['year', 'month', 'day']}
                  fullWidth
                  slotProps={{ textField: { 
                    size: 'small', 
                    error: !!errors.startDate,
                    helperText: errors.startDate?.message,
                  }}}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutocomplete
                  name={'startHour'}
                  control={control}
                  fullWidth
                  options={hours.map(hour => `${hour}:00`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Start Hour"
                      color="success" 
                      placeholder="Start Hour"
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7}>
                <FormDatePicker
                  name={'stopDate'}
                  control={control}
                  label="Stop Date"
                  views={['year', 'month', 'day']}
                  fullWidth
                  slotProps={{ textField: { 
                    size: 'small', 
                    error: !!errors.stopDate,
                    helperText: errors.stopDate?.message,
                  }}}
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutocomplete
                  name={'stopHour'}
                  control={control}
                  fullWidth
                  options={hours.map(hour => `${hour}:00`)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Stop Hour"
                      color="success" 
                      placeholder="Stop Hour"
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <FormAutocomplete
                  name={'Service_Increment'}
                  control={control}
                  fullWidth
                  freeSolo
                  options={filteredTSCombos.serviceIncrements}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Service Increment"
                      color="success" 
                      placeholder="Service Increment"
                      size='small'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <FormAutocomplete
                  name={'serviceClass'}
                  control={control}
                  fullWidth
                  freeSolo
                  options={[ 'FIRM', 'NON-FIRM', 'SECONDARY' ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Class"
                      color="success" 
                      placeholder="Class"
                      size='small'
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" type="submit" form='add-new-tsr-activity-tab-form'>{viewToUpdate?.label ? 'Update' : 'Add New'}</Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});