import { Typography, IconButton, Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, } from '@mui/material';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import { useSnackbar } from 'notistack';
import axios from "axios";
import { apiUrlPrefix, userGroups } from '../authConfig';
import GroupGuard from "./Guards/GroupGuard";
import useHeader from './useHeader';
import { useImageUploader } from './useImageUploader';
import { useAzureBlobs } from './useAzureBlobs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

export default ({ open, handleClose }) => {
  const headers = useHeader();
  const { ImageUploader, images, ImagePreview, handleImageUpload, clearImages, } = useImageUploader();
  const containerName = process.env.REACT_APP_AZURE_BLOB_IMAGE_CONTAINER_NAME;
  const { uploadBlob, createContainer, } = useAzureBlobs(containerName);

  const { handleSubmit, formState: { errors, }, register, reset, } = useForm({
    resolver: yupResolver(schema),
  });

  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();

  const commaSeparatedImageNames = images.map(image => image.name).join(',');
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/postFetchArray?name=PowerStationMetaData.UI_SupportTicketInsert&parm=${guid}&parm=${commaSeparatedImageNames}`;

  const saveTicketDescription = useCallback(async (description) => {
    const options = {
      method: 'POST',
      headers: headers,
      data: {
        description: description,
      },
      url: saveUri,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`${JSON.stringify(error.response)}`, { variant: 'error' })
    });
  }, [enqueueSnackbar, headers, saveUri]);

  const handleSaveImages = useCallback(async (newTicketID) => {
    if (images.length === 0) { return; }

    const newContainerName = `ticket-${newTicketID}`;
    createContainer(newContainerName).then(response => {
      images.forEach(image => {
        uploadBlob(image, newContainerName).then(response => {
          enqueueSnackbar(`Image ${image.name} uploaded.`, { variant: 'success' });
        });
      });
    });
  }, [createContainer, enqueueSnackbar, images, uploadBlob]);

  const handleSave = useCallback(debounce((description) => {
    saveTicketDescription(description).then(response => {
      const newTicketID = response?.data?.[0]?.Column1;
      if (!newTicketID) { throw new Error('Ticket ID not returned from server.'); }

      handleSaveImages(newTicketID);
    }).finally(() => {
      let message = 'Support ticket saved to the database. We will review promptly.';
      enqueueSnackbar(message, { variant: 'success' });
      clearImages();
      reset();
    });
  }, 1000), [clearImages, enqueueSnackbar, reset, handleSaveImages, saveTicketDescription]);

  const handleSaveAndClose = ({ description, }) => {
    handleClose();
    handleSave(description);
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Submit New Support Ticket</Typography>
        <Box sx={{ flexGrow: 1, }} />
        <IconButton onClick={handleClose} size='large'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>Please enter a defect report, a suggestion for improvement, or a simple how-to question.</p>
          <p>Try to articulate what you are trying to do and why you are doing it as this helps address your ticket.</p>
          <p>If this is a defect report, please reproduce the defect and provide the steps to reproduce it.</p>
        </DialogContentText>
        <form onSubmit={handleSubmit(handleSaveAndClose)} id='submit-new-ticket-form'>
          <TextField
            {...register('description')}
            autoFocus
            margin="dense"
            label="Description of the Issue"
            multiline
            rows={6}
            fullWidth
            variant="outlined"
            helperText={errors.description?.message}
            error={!!errors.description}
          />
        </form>
        <Box sx={{ p: 1, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <ImageUploader />
          <Tooltip title="Upload images related to your ticket. Multiple images may be uploaded." placement="top" arrow>
            <Button onClick={handleImageUpload} color='primary' size='large' variant='contained' sx={{ maxWidth: 400, }}>Upload Image</Button>
          </Tooltip>
          <ImagePreview />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button type='submit' form='submit-new-ticket-form'>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

const schema = object().shape({
  description: string().required('A description of the issue is required.'),
});