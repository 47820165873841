import { useEffect, useState, useRef } from 'react';
// import setTSValues from '../../utils/SetTSValues';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeOriginals.css'
import { useSnackbar } from 'notistack';
import RealTimeAvailabilityGrid from './RealTimeAvailabilityGrid';
import RealTimeOriginalRequestGrid from './RealTimeOriginalRequestGrid';
// import { Paper, TableBody, TableContainer, Table, TextField, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses } from "@mui/material";
// import Divider from '@mui/material/Divider';
//import TemplateTable from './TemplateTable'
import TemplateButtons from './TemplateButtons'
// import ResizingPane from 'react-resizing-pane';
// import { serviceRowValues } from '../../utils/RedirectServiceValues';
import DivGuard from '../Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import { useActionAudit } from '../useActionAudit';
import { useTemplateTable } from '../useTemplateTable';
import { useServiceDropdown } from '../useServiceDropdown';

const RealTimeOriginalRequests = () => {

  const availabilityGridRef = useRef();
  const originalRequestGridRef = useRef();
  const originalRequestTemplateTypeId = 4;

  const { logAction } = useActionAudit();
  const { enqueueSnackbar } = useSnackbar();
  const [setRowValuesWithService] = useServiceDropdown('Real Time Originals');

  const {
    templates: originalRequestTemplates,
    loadTemplates: reloadOriginalRequestDropZones,
    addTemplate: addOriginalRequestTemplate,
    deleteTemplate: deleteOriginalRequestTemplate,
    //onTemplateClick: onOriginalRequestTemplateClick,
    addGridDropZone
  } = useTemplateTable(originalRequestTemplateRowTransform, originalRequestTemplateTypeId, availabilityGridRef, originalRequestGridRef, 'real-time-original-requests', 'Availability Grid', 'Original Request Grid',);

  useEffect(() => {
    if (addGridDropZone) {
      addGridDropZone('#real-time-originals-grid', originalRequestTemplateRowTransform, availabilityGridRef, originalRequestGridRef);
    }
  }, [originalRequestTemplates,]);

  function addOriginalRequestTemplateAndReload(newTemplate) {
    addOriginalRequestTemplate(newTemplate);
    reloadOriginalRequestDropZones();
  }

  function bulkTransform(rows) {
    const minCapacities = {};
    rows.forEach(row => {
      Object.keys(row).forEach(key => {
        if (parseInt(key.match(/\d+/))) {
          minCapacities[key] = (minCapacities[key] || minCapacities[key] === 0) ? Math.min(row[key] ?? 0, minCapacities[key]) : row[key]
        }
      });
    })
    return rows.map(row => {
      return {
        ...row,
        ...minCapacities,
      }
    });
  }

  function originalRequestTemplateRowTransform(row, template) {
    const newRow = { ...row };
    if (template) {
      newRow['Customer Code'] = template['Customer Code'] ?? '';
      newRow['Sale Ref'] = template['Sale Ref'] ?? '';
      newRow['Service'] = template['Service'] ?? '';
      newRow['Preconfirmed'] = template['Preconfirmed'] ?? '';

      if (template['Service']) {
        const svc = {
          newValue: template['Service'],
          data: newRow,
          column: {
            colId: 'Service'
          }
        }
        setRowValuesWithService(svc);
      }
    }

    newRow['Status'] = 'PRE-SUBMITTED';
    newRow['New Aref'] = '';
    newRow['Result'] = 'PRE-SUBMITTED';
    newRow['AssignmentRef'] = undefined;
    newRow["ID"] = undefined;
    newRow["pinned"] = undefined;
    newRow["OASISInstance"] = undefined;
    newRow["StartUTC"] = undefined;
    newRow["StopUTC"] = undefined;
    newRow["Start"] = undefined;
    newRow["Stop"] = undefined;
    newRow["defaultStartDate"] = undefined;
    newRow["defaultStartTime"] = undefined;
    newRow["defaultStopDate"] = undefined;
    newRow["defaultStopTime"] = undefined;
    newRow["Redirected Ref"] = undefined;
    newRow["RequestType"] = undefined;
    newRow["Related Ref"] = undefined;
    newRow["SellerRef"] = undefined;
    newRow["PriceUnit"] = undefined;
    newRow["CUSTOMER_NAME"] = undefined;
    newRow["CUSTOMER_EMAIL"] = undefined;
    newRow["Request Ref"] = undefined;
    newRow["Deal Ref"] = undefined;
    newRow["Posting Ref"] = undefined;

    return newRow;
  }

  const d = new Date();
  const year = d.getFullYear()
  const date = d.getDate()
  const monthIndex = d.getMonth() + 1
  const hour = d.getHours() + 2;

  //const [headerLabelDate, setHeaderLabelDate] = useState(`${monthIndex}/${date}/${year} ${hour}:00`);
  const [headerLabelDate, setHeaderLabelDate] = useState(`${monthIndex}/${date}/${year} ${hour}:00`);

  //setHeaderLabelDate(date);

  const handleMasterHeaderLabelDateChange = (event) => {

    // const d = new Date(event);
    // const year = d.getFullYear()
    // const date = d.getDate()
    // const monthIndex = d.getMonth() + 1

    //setHeaderLabelDate(event.target.value);
    // setHeaderLabelDate(`${monthIndex}/${date}/${year}`);
    setHeaderLabelDate(event);

    //let message = `Start date and time changed to ${monthIndex}/${date}/${year}.`;
    let message = `Start date and time changed to ${event}`;
    enqueueSnackbar(message);

  };

  const [timezone, setTimezone] = useState('Pacific Standard Time');

  const handleMasterTimezoneChange = (event) => {
    setTimezone(event.target.value);
    //getData();
    let message = `Time zone changed to ${event.target.value}.`;
    enqueueSnackbar(message);

  };

  function onOriginalRequestTemplateClick(temp) {
    const nodes = availabilityGridRef.current.api.getBestCostNodeSelection();

    if (!nodes?.length > 0) {
      enqueueSnackbar(`Please select rows from the Availability grid.`);
      return;
    }

    let newRows = [];

    nodes.forEach(node => {
      if (node.allChildrenCount) {
        const childData = node.allLeafChildren.map(child => child.data);
        newRows = newRows.concat(bulkTransform(childData));
      } else {
        newRows.push(node.data);
      }
    })

    newRows = newRows.map(row => originalRequestTemplateRowTransform(row, temp.data));
    originalRequestGridRef.current.api.applyTransaction({ add: newRows });

    logAction(`${temp.name} template clicked.`, `Template Type Id: ${originalRequestTemplateTypeId}`);
  };

  return (
    <DivGuard groups={[userGroups.realtimeoriginals]} >
      <div className="real-time-originals-example-wrapper">


        {/* <RealTimeTSR ref={tsrGridRef} handleMasterTimezoneChange={handleMasterTimezoneChange} timezone={timezone}/> */}

        {/* <div className="real-time-originals-inner-col originals-vertical-toolbar"> */}
        {/* <TemplateTable 
              title='Original Req Templates'
              tableId='real-time-original-requests'
              tooltipText='These are original request templates. To create new ones, use the Templates tool panel on the Original Requests grid.' 
              templates={originalRequestTemplates} 
              handleRowClick={onOriginalRequestTemplateClick} 
              rowTooltip={function(template) {
                return(<span>Using this template will request a new TSR using {template.name}</span>);
              }}
            /> */}

        {/* </div> */}

        <div className="real-time-originals-container">
          <div id='real-time-availability-grid'>
            <RealTimeAvailabilityGrid handleMasterTimezoneChange={handleMasterTimezoneChange} timezone={timezone} handleMasterHeaderLabelDateChange={handleMasterHeaderLabelDateChange} headerLabelDate={headerLabelDate} ref={availabilityGridRef} templates={originalRequestTemplates} addTemplate={addOriginalRequestTemplate} deleteTemplate={deleteOriginalRequestTemplate} />
          </div>
          {/* <div className="real-time-originals-divider"/> */}
          <TemplateButtons
            title='Original Req Templates'
            tableId='real-time-original-requests'
            tooltipText='These are original request templates. To create new ones, use the Templates tool panel on the Original Requests grid.'
            templates={originalRequestTemplates}
            handleRowClick={onOriginalRequestTemplateClick}
            rowTooltip={function (template) {
              return (<span>Using this template will request a new TSR using the {template.name} template: Customer Code={template["Customer Code"]}, Sale Ref={template["Sale Ref"]}, Service={template.Service}, Pre-confirmed={template.Preconfirmed}</span>);
            }}
          />
          <div id='real-time-originals-grid'>
            <RealTimeOriginalRequestGrid ref={originalRequestGridRef} templates={originalRequestTemplates} headerLabelDate={headerLabelDate} addTemplate={addOriginalRequestTemplateAndReload} deleteTemplate={deleteOriginalRequestTemplate} timezone={timezone} />
          </div>

        </div>

      </div>

    </DivGuard>
  );

};

export default function RealTimeOriginalRequestsExport() {
  return <RealTimeOriginalRequests />;
}

