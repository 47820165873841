import { useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix, } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DivGuard from '../../components/Guards/DivGuard.js';
import { userGroups } from "../../authConfig";
import Tooltip from '@mui/material/Tooltip';
import { useActionAudit } from '../useActionAudit';
import useGridLayout from '../useGridLayout';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useData } from '../useData';
import { AgGridContainer } from '../AgGrid/AgGridContainer.js';
import '../../styles/gridRowStyles.css';
import { useColumnTypes } from '../AgGrid/useColumnTypes.js';
import { defaultColumnDef, } from '../AgGrid/defaultGridProps.js';
import dayjs from '../dayjs-tz.js';
import { Stack } from '@mui/material';

const TransNowAuditGrid = () => {
  const gridRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const { logAction } = useActionAudit();
  const columnTypes = useColumnTypes();

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserMessagesQueueFetch&parm=${guid}&parm=${timeZone}`;
  const storageLocation = 'transhunter-audit-grid-layout'
  const { rowData, getData } = useData();

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
    let message = 'CSV generated. See your downloads folder to retrieve the file.';

    enqueueSnackbar(message);
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
    let message = 'Rows copied to clipboard.';

    enqueueSnackbar(message);
  };

  // enables the fill handle
  const enableFillHandle = true;
  // enables undo / redo
  const undoRedoCellEditing = true;
  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;
  // enables flashing to help see cell changes
  const enableCellChangeFlash = true;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const groupDefaultExpanded = 1;


  const onRefreshBtn = () => {
    getData(loadUri);
    let message = 'Audit log refreshed.';
    logAction('Refresh', 'TransNowAuditGrid');
    enqueueSnackbar(message);
  };

  const gridOptions = useMemo(() => ({
    rowClassRules: {
      "row-admin-alert": params => params.data.subject === 'Admin Alert',
      "row-trans-hunter-alert": params => params.data.subject === 'Trans Hunter Alert',
    },
  }), []);

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    getData(loadUri);
    return () => {
      abortController.abort();
    }
  }, []);

  const baseColDefs = [
    {
      checkboxSelection: true,
      headerName: "Messege ID",
      field: "userMessagesQueueID",
      initialWidth: 150,
    },
    {
      headerName: "Subject",
      field: "subject",
      initialWidth: 200,
      initialHide: false,
    },
    {
      headerName: "From",
      field: "userName",
      initialWidth: 300,
      initialHide: true,
    },
    {
      headerName: "Message",
      field: "textMessage",
      initialWidth: 1000,
    },
    {
      headerName: "Received",
      initialWidth: 100,
      field: "received",
    },
    {
      headerName: "Alert Date",
      field: "dateCreated",
      type: 'dateColumn',
      valueFormatter: params => {
        //the time is in UTC, so we need to convert it to the local time zone. Shift the time by the offset.
        const offset = dayjs().utcOffset();
        return params.value ? dayjs(params.value).add(offset, 'minute').format('MM/DD/YYYY HH:mm') : '';
      },
    },
    {
      headerName: "Received Date",
      field: "dateReceived",
      type: 'dateColumn',
      valueFormatter: params => {
        //the time is in UTC, so we need to convert it to the local time zone. Shift the time by the offset.
        const offset = dayjs().utcOffset();
        return params.value ? dayjs(params.value).add(offset, 'minute').format('MM/DD/YYYY HH:mm') : '';
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    ...defaultColumnDef,
    editable: false,
  }), [])

  const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, gridRef, baseColDefs, defaultColDef);

  const sideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ],
    position: 'right',
  };

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <DivGuard groups={[userGroups.transhunter, userGroups.fastpathpro, userGroups.fastpath]} >
      <Stack direction='row' spacing={2} sx={{ p: 1, }}>
        <Tooltip title="Refresh the grid of audit records, retreiving the most recent audit log records from Power Station." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>
        <Tooltip title="Copy row data to the clip board for pasting in other applications." arrow placement="top">
          <Button
            onClick={() => onBtCopyRows()}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Ctrl-c</Button>
        </Tooltip>
        <Tooltip title="Download a copy of this audit log to a CSV format that can be opened in MS Excel." arrow placement="top">
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineOutlinedIcon />}
            id="undoBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>
        </Tooltip>
      </Stack>
      <AgGridContainer
        style={{ height: '85vh', width: "98%" }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          enableCellChangeFlash={enableCellChangeFlash}
          groupDefaultExpanded={groupDefaultExpanded}
          sideBar={sideBar}
          gridOptions={gridOptions}
          animateRows={true}
          rowData={rowData}
          groupSelectsChildren={true}
          columnTypes={columnTypes}
          components={{
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </DivGuard>
  );
};

export default function TransNowAuditGridExport() {
  return <TransNowAuditGrid />;
}