import { Typography, Stack, Card, CardContent, Chip } from "@mui/material"
import { styled } from '@mui/material/styles';

export const Message = (props) => {
    const { content, sender, stamp, error = false, } = props;
    const isUser = sender === 'user';

    const float = isUser ? 'right' : 'left';

    const StyledStack = styled(Stack)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: 'fit-content',
        maxWidth: '90%',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        float: float,
        ...(sender === 'user' && {
            marginLeft: 'auto',
            alignItems: 'flex-end',
        }),
        ...(sender !== 'user' && {
            marginRight: 'auto',
            alignItems: 'flex-start',
        }),
    }));

    const Stamp = styled(Typography)(({ theme }) => ({
        fontSize: '1em',
        variant: 'caption',
        component: 'paragraph',
        color: error ? theme.palette.error.main : 'textSecondary',
    }));

    const MessageCard = styled(Card)(({ theme, }) => ({
        height: 'auto',
        margin: 0,
        borderRadius: theme.spacing(2),
        width: 'fit-content',
        ...(isUser && {
            color: theme.palette.primary.contrastText,
            backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
        }),
    }));

    const MessageContent = styled(CardContent)(({ theme }) => ({
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        ...(!isUser && {
            paddingLeft: 0,
        }),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    }));

    const MessageText = styled(Typography)(({ theme }) => ({
        fontSize: '1.25em',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        ...(isUser && {
            textAlign: 'right',
        }),
        wordBreak: 'break-word',
    }));

    return (
        <StyledStack>
            <MessageCard
                elevation={0}
            >
                <MessageContent>
                    <MessageText >
                        {content}
                    </MessageText>
                </MessageContent>
            </MessageCard>
            <Stamp>
                {error ? 'Message failed to send.' : stamp}
            </Stamp>
        </StyledStack>
    )
}
