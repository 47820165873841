export const serviceRowValues = {
  'HOURLY SECONDARY': {
    Service: 'HOURLY SECONDARY',
    Service_Increment: 'HOURLY',
    TS_Class: 'SECONDARY',
    TS_Type: 'POINT_TO_POINT', 
    TS_Period: 'FULL_PERIOD',
    TS_Window: 'FIXED',
    TS_Subclass: 'SHORT_TERM',
  },
  'NF-SECONDARY HOURLY PTP': {
    Service: 'NF-SECONDARY HOURLY PTP',
    Service_Increment: 'HOURLY',
    TS_Class: 'SECONDARY',
    TS_Type: 'POINT_TO_POINT', 
    TS_Period: 'FULL_PERIOD',
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
  'F-HOURLY PTP': {
    Service: 'F-HOURLY PTP',
    Service_Increment: 'HOURLY', 
    TS_Class: 'Firm', 
    TS_Type: 'POINT_TO_POINT',
    TS_Period: 'FULL_PERIOD',
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
  'DLY_F': {
    Service: 'DLY_F',
    Service_Increment: 'DAILY', 
    TS_Class: 'Firm',
    TS_Type: 'POINT_TO_POINT',
    TS_Period: 'FULL_PERIOD',
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
  'HOURLY Non-Firm': {
    Service: 'HOURLY Non-Firm',
    Service_Increment: 'HOURLY', 
    TS_Class: 'Non-Firm', 
    TS_Type: 'POINT_TO_POINT', 
    TS_Period: 'FULL_PERIOD',
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
  'NETWORK HOURLY Firm': {
    Service: 'NETWORK HOURLY Firm',
    Service_Increment: 'HOURLY', 
    TS_Class: 'Firm',
    TS_Type: 'NETWORK',
    TS_Period: 'FULL_PERIOD', 
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
  'NF-HOURLY NT': {
    Service: 'NF-HOURLY NT',
    Service_Increment: 'HOURLY', 
    TS_Class: 'Non-Firm',
    TS_Type: 'NETWORK',
    TS_Period: 'FULL_PERIOD', 
    TS_Window: 'FIXED',
    TS_Subclass: "",
  },
}