import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { AgChartsReact } from 'ag-charts-react';
import { AgCharts } from 'ag-charts-enterprise';
import { memo, } from 'react';
import styled from '@mui/material/styles/styled';
import dayjs from 'dayjs';

AgCharts.setLicenseKey(process.env.REACT_APP_AG_CHARTS_ENTERPRISE_LICENSE_KEY);

const Analysis = ({ setShow, dailyData, hourlyData, seriesKeys = [], }) => {
    const theme = useTheme();
    const palette = theme.palette.primary;

    function getWindowData(windowStart, windowEnd) {
        let data = dailyData;
        const oneDay = 1000 * 60 * 60 * 24; // ms/s * s/m * m/h * h/d = ms/d

        //if the difference between the start and end is less than 60 days, add hourly data
        if ((windowEnd - windowStart) < (oneDay * 60)) {
            const windowStartMinusADay = dayjs(windowStart).subtract(1, 'day').toDate();
            const windowEndPlusADay = dayjs(windowEnd).add(1, 'day').toDate();
            data = [...data, ...hourlyData.filter(item => item.dateTimeValue >= windowStartMinusADay && item.dateTimeValue <= windowEndPlusADay)].sort((a, b) => a.dateTimeValue - b.dateTimeValue);
        }
        return data;
    }

    const chartOptions = {
        dataSource: {
            getData: ({ windowStart, windowEnd }) => {
                return getWindowData(windowStart, windowEnd);
            },
        },
        zoom: {
            enabled: true,
            anchorPointX: 'pointer',
            anchorPointY: 'pointer',
            minVisibleItemsX: 2,
            enableSelecting: true,
        },
        navigator: {
            enabled: true,
            miniChart: {
                enabled: true,
            },
        },
        background: {
            fill: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
        },
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
                strokes: [
                    palette.main,
                    palette.yellow,
                    palette.red,
                    palette.green,
                    palette.light,
                    palette.dark,
                ],
            },
        },
        series: [
            ...Object.keys(dailyData[0] || {}).filter(key => !['dateTimeValue', 'modelName'].includes(key) && (!seriesKeys.length || seriesKeys.includes(key))).map((key, index) => ({
                type: 'line',
                yName: key,
                xKey: 'dateTimeValue',
                yKey: key,
                strokeWidth: 2,
                visible: false,
                marker: {
                    size: 2,
                },
            })),
        ],
        autoSize: true,
        axes: [
            {
                position: 'bottom',
                type: 'time',
                keys: ['dateTimeValue',],
                nice: false,
                label: {
                    format: '%m/%d/%y %H:%M',
                    rotation: 30,
                },
                crosshair: {
                    enabled: true,
                },
            },
            {
                position: 'right',
                type: 'number',
                keys: [...Object.keys(dailyData[0] || {}).filter(key => !['dateTimeValue', 'modelName'].includes(key))],
                title: {
                    text: 'Error',
                },
            },
        ],
        legend: {
            position: 'right',
        },
        overlays: {
            noData: {
                text: 'No data was returned for this brain. If your brain is in the middle of training, it will need to finish before you see any prediction data.',
            },
            noVisibleSeries: {
                text: 'Click on chart legend items on the right to show/hide series. Choose Predicted Value (Overall) and Target Value (Overall) to get a general sense of how accurately your brain is predicting the values.',
            },
        },
    }

    return (
        <Box component={Paper} elevation={5} sx={{ height: '100%', width: '100%', position: 'relative', }}>
            <AgChartsReact options={chartOptions} />
            {setShow && <div style={{ position: 'absolute', top: 1, right: 1, display: 'flex', alignItems: 'center', }}>
                <Button
                    onClick={() => setShow(false)}
                >Hide</Button>
            </div>}
            <NoDataOverlay visible={dailyData.length === 0} />
        </Box>
    );
}

export default memo(Analysis);

const NoDataOverlay = ({ visible, }) => {
    return (
        <OverlayContainer visible={visible}>
            <Typography align='center' color='primary' variant='h6'>Once the brain is finished training and predictions have been updated, you will receive a text. Check back after that.</Typography>
        </OverlayContainer>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
}));