export const routePaths = {
    fastpath: '/fastpath',
    crystalball: '/crystalball',
    aboutpowerstation: '/aboutpowerstation',
    spymonkey: '/spymonkey',
    dashboard: '/dashboard',
    bidmanmockup: '/bidmanmockup',
    bidman: '/bidman',
    transnow: '/transnow',
    settings: '/settings',
    tsractivity: '/tsractivity',
    bulkoriginalrequestsview: '/bulkoriginalrequestsview',
    usergroupsview: '/usergroupsview',
    transhunter: '/transhunter',
    powerpeople: '/powerpeople',
    powersysops: '/powersysops',
    //powerfolio: '/powerfolio',
    marketplace: '/marketplace',
    dealrizz: '/dealrizz',
    productupdates: '/productupdates',
    tariffaf: '/tariffaf',
    fastpathmap: '/fastpathmap',
    brain: '/brain',
    // aisupport: '/aisupport', // Add this line
};