import { useLocalGuid } from './UserGuidContext/useLocalGuid';
import {useEffect, useState, createContext} from 'react';
import { apiUrlPrefix, } from '../authConfig';
import useHeader from '../components/useHeader';

export const UserContext = createContext([]);

export function UserGroupsProvider({children}) {
    const [groups, setGroups] = useState([]);
    const headers = useHeader();
    const guid = useLocalGuid();

    useEffect(() => {
        //getData();
        let abortController = new AbortController();  
        // your async action is here  
        getData();
        return () => {  
        abortController.abort();  
        }  
    }, [guid]);
    
    async function getData() {
        if(guid) {
            const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserGroupFetch&parm=${guid}`;

        try {
            const response = await fetch(
                uri,
                {
                    headers: headers,
            });
            const data = await response.json();
            const userGroups = data ?? [];
            setGroups([...userGroups.map(grp => grp.groupName), 'default']); 
        } catch(error) {
            alert(`Error calling fetchUserGroups.  Error Message: ${error}.  URL: ${uri}`);
        }
    } else {
        setGroups([]);
    }
}

    return(
        <UserContext.Provider value={groups}>
            {children}
        </UserContext.Provider>
    );
}