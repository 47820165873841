import React, { useEffect, useState, useRef, useMemo } from 'react';
import DetailCellRenderer from './DashboardDetailCellRenderer';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { Box, Button } from '@mui/material';
import FileUploaderDialog from './FileUploaderDialog';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { useData } from '../useData';
import { useRemoteLayout } from '../useRemoteLayout';
import { DatabaseLayoutToolPanel } from "../ToolPanels/DatabaseLayoutToolPanel.js";
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from 'ag-grid-react';
import { useGridButtons } from '../useGridButtons';
import { apiUrlPrefix } from '../../authConfig';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default React.memo(BidmanDashboard);

function BidmanDashboard({ node, oprDay, refreshAll, triggerRefresh, treeData }) {
  const { nodeId, gridDataApiEndpoint } = node;
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const { rowData, getData } = useData();
  const [fileUploaderOpen, setFileUploaderDialogOpen] = useState(false);

  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  }


  const { ExportButton, CopyRangeButton, CopyRowsButton } = useGridButtons({ gridRef });

  const handleFileUploaderDialogOpen = () => {
    setFileUploaderDialogOpen(true);
  };

  const handleFileUploaderDialogClose = () => {
    setFileUploaderDialogOpen(false);
    fetch();
  };

  const onGetRowButtonClick = e => {
    const selectedNodes = gridRef.current.api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    enqueueSnackbar(`${JSON.stringify(selectedData)}`)
  }

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    enqueueSnackbar(`${JSON.stringify(rowData)}`)
  }

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const onRefreshBtn = () => {
    fetch();
  };

  function fetch() {
    gridRef.current.api?.showLoadingOverlay();

    getData(loadDataUri).then(response => {
      gridRef.current && gridRef.current.api.hideOverlay();
    })
  };

  const gridOptions = {
    rowStyle: { p: '0', },
  };

  function updateColDefs(defs) {
    defs.forEach(def => {
      const num = def.field.slice(-1); //get last char, its the day number
      if (!parseInt(num)) { return; }
      const dateHeader = moment(oprDay).add(num - 1, 'days').format('MM/DD/YYYY');
      def.headerName = dateHeader;
      def.cellRenderer = cellRenderer;
      if (def.field === 'Market') { def.cellRenderer = 'agGroupCellRenderer' }
    });

    return (defs);
  }

  useEffect(() => {
    fetch();
  }, [oprDay]);

  useEffect(() => {
    if (refreshAll.market === node.market) { fetch(); }
  }, [refreshAll]);

  const aggFuncs = {
    // this overrides the grids built-in sum function
    'Float Sum': params => {
      let sum = 0;
      params.values.forEach(value => sum += parseFloat(value));
      return sum.toFixed(2);
    },
    'Bid Counts': params => {
      let count = 0;
      params.values.forEach((value) => {
        const groupNode =
          value !== null && value !== undefined && typeof value === 'object';
        if (groupNode) {
          count += value.count;
        } else {
          count++;
        }
      });

      return count / 24;
    },
    'Error Counts': params => {
      let count = 0;
      params.values.forEach((value) => {
        const groupNode =
          value !== null && value !== undefined && value === 'problem-cell';
        if (groupNode) {
          count += value.count;
        } else {
          count++;
        }
      });

      return count;
    },
  };

  function parseTreeForNode(selector, node, found) {
    if (selector(node)) {
      found.push(node);
    } else if (Array.isArray(node.children)) {
      node.children.forEach(child => parseTreeForNode(selector, child, found));
    }
  }

  const cellRenderer = props => {
    if (props.value) {
      const cell = props.value;
      props.node.setExpanded(false);
      return <div onClick={(event) => toggleDetailGrid(props)}>
        <img title={cell.cellToolTip} height={'16px'} width={'16px'} style={{ verticalAlign: 'middle' }} src={(cell.cellClass == "problem-cell") ? '/redx.png' : '/greenCheck.jpg'} />
        <span style={{ 'verticalAlign': 'middle', 'display': 'inline' }}>{`  ${cell.Value}`}</span>
      </div>
    } else return null;
  }

  function toggleDetailGrid(props) {
    if (!props.node.expanded) {
      //find node by matching market, category, and subaccount, and add to context for use in detail grid
      let selector = (n) => {
        return (
          n.market === props.data.Market
          && n.category === props.data.Category
          && n.subAccount === props.data.SubAccount
        );
      };

      if (props.data.Market === 'ERCOT') {
        const types = ['PTP', 'Bid', 'Offer'];
        const bidType = props.data['Bid_Type'];
        const type = types.find(t => bidType.includes(t));

        selector = (n) => {
          return (
            n.subAccount === props.data.SubAccount
            //&& n.category === props.data.Category
            && n.market === 'ERCOT'
            && n.label.includes(type)
          );
        }
      } else if (props.data.Market === 'ISONE') {
        const types = ['LCI', 'SCI', 'All'];
        const category = props.data['Category'];
        const type = types.find(t => category.includes(t));

        selector = (n) => {
          return (
            n.market === 'ISONE'
            && n.label.includes(type)
          );
        }
      } else if (props.data.Market === 'MISO') {
        selector = (n) => {
          return (
            n.market === 'MISO'
            && n.label.includes('All')
          );
        }
      } else if (props.data.Market === 'NYISO') {
        const types = ['SERNA', 'PRE', 'All'];
        const category = props.data['Category'];
        const type = types.find(t => category.includes(t));

        selector = (n) => {
          return (
            n.market === 'NYISO'
            && n.label.includes(type)
          );
        }
      }


      let found = [];
      treeData.forEach(treeNode => parseTreeForNode(selector, treeNode, found));
      const node = found[0];
      props.api.context.treeNode = node;
      props.api.context.oprDay = props.column.colDef.headerName;
    }

    //toggle grid
    const previousNode = props.api.context.node;
    const previousCol = props.api.context.column;
    //if they clicked a different problem cell, close other grids, redraw and open the grid for this cell.
    if (previousCol !== props.column.colId || previousNode !== props.node) {
      props.api.forEachLeafNode(node => node.setExpanded(false));
      props.api.redrawRows({ rowNodes: [props.node.detailNode] });
      props.node.setExpanded(true);
    } else { //otherwise just toggle the grid
      props.node.setExpanded(!props.node.expanded)
    }

    //store node and column in context to specify clicked cell
    props.api.context.node = props.node;
    props.api.context.column = props.column.colId;
  }

  const detailCellRenderer = useMemo(() => {
    return (props) => DetailCellRenderer({ ...props, refreshAll, triggerRefresh });
  }, []);

  const colDefs = useMemo(() => [
    {
      editable: false,
      headerName: "Market",
      rowGroup: true,
      enableRowGroup: true,
      field: "Market",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Subaccount",
      rowGroup: true,
      enableRowGroup: true,
      field: "SubAccount",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Category",
      rowGroup: true,
      enableRowGroup: true,
      field: "Category",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Bid Type",
      rowGroup: true,
      enableRowGroup: true,
      field: "Bid_Type",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay1",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay2",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay3",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay4",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay5",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay6",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
    {
      editable: false,
      rowGroup: false,
      enableRowGroup: false,
      field: "OprDay7",
      sortable: false,
      filter: false,
      cellRenderer: cellRenderer,
    },
  ], [treeData]);

  const { dbLayoutPanel, loadLayout } = useRemoteLayout(gridRef, nodeId, colDefs, updateColDefs);

  const defaultColDef = useMemo(() => ({
    rowGroup: false,
    pivot: false,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout(params.api);
  }

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <FileUploaderDialog
          fileUploaderOpen={fileUploaderOpen}
          handleFileUploaderDialogClose={handleFileUploaderDialogClose}
          oprDay={oprDay}
          nodeId={nodeId}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <CopyRowsButton />&nbsp;
          <ExportButton />&nbsp;
          <CopyRangeButton />&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={onGetRowButtonClick}>Get Selected</Button>&nbsp;
          {/*<Button 
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
    onClick={onGetGridDataButtonClick}>Get Data</Button>&nbsp;*/}
          {/*<Button 
              variant={buttonVariant} 
              color={buttonColor}
              size={buttonSize}
              onClick={handleFileUploaderDialogOpen}
            >IMPORT BIDS</Button>&nbsp;*/}
        </Box>

        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          ref={gridRef}
          onGridReady={onGridReady}
          masterDetail={true}
          detailRowAutoHeight
          detailCellRenderer={'detailCellRenderer'}
          rowData={rowData}
          sideBar={sideBar}
          rowClass={'dense-grid'}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          groupDefaultExpanded={-1}
          aggFuncs={aggFuncs}
          defaultColDef={defaultColDef}
          singleClickEdit
          gridOptions={gridOptions}
          rowGroupPanelShow={'always'}
          suppressAggFuncInHeader={true}
          enableRangeSelection={true}
          animateRows={true}
          rowSelection="multiple"
          components={{
            detailCellRenderer: detailCellRenderer,
            dbLayoutToolPanel: DatabaseLayoutToolPanel
          }}
        />

      </AgGridContainer>
    </div>
  );


};

// export default function BidmanGridExport({ nodeId, gridDataAPIendpoint }) {
//   return <BidmanGrid />;
// }

