import { Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";

const FormAutocomplete = ({ name, control, ...inputProps }) => {
    const defaultValue = inputProps?.multiple ? [] : null;

    return (
        <Controller
            render={({ field }) =>
                <Autocomplete
                    autoComplete
                    autoSelect
                    autoHighlight
                    {...field}
                    value={field.value ?? defaultValue}
                    onChange={(_, data) => field.onChange(data)}
                    {...inputProps}
                />
            }
            name={name}
            control={control}
        />
    )
}

export default FormAutocomplete;
