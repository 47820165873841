import dayjs from '../../dayjs-tz';
import moment from "moment";
import { maxBy } from 'lodash';

export function totalPrice(priceCurve = [], capacityCurve = []) {
    const sortedCapacities = capacityCurve.sort((a, b) => dayjs(a.OfferStartTimeUtc).diff(dayjs(b.OfferStartTimeUtc)));
    const total = priceCurve.reduce((acc, next) => {
        const price = next.OfferPrice ?? 0;
        const blockStart = dayjs(next.OfferStartTimeUtc);
        const blockEnd = dayjs(next.OfferStopTimeUtc);
        const capacityBlocksInWindow = sortedCapacities.filter(capacity => isInWindow(capacity, blockStart, blockEnd));
        const capacity = maxBy(capacityBlocksInWindow, 'Capacity')?.Capacity ?? 0;
        return acc + price * capacity;
    }, 0);
    return Number(total).toFixed(2);
}

export function isInWindow(block, start, end) {
    const blockStart = dayjs(block.OfferStartTimeUtc);
    const blockEnd = dayjs(block.OfferStopTimeUtc);
    const inWindow = blockStart.isBefore(end) && blockEnd.isAfter(start);
    return inWindow;
}

export function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = dayjs(xValue).format('MM/DD/YY HH:mm');
    const value = Number(yValue).toFixed(2);
    return {
        title: tzDate,
        content: value,
    }
}

export function configureSparkline(availabilityData) {
    const parsed = availabilityData.map(block => ({
        Capacity: parseFloat(block.Capacity ?? block.CeilingPrice ?? block.OfferPrice ?? 0),
        Start: new moment(moment.parseZone(block.OfferStartTimeUtc).format('MM/DD/YYYY HH:mm')).toDate(),
        Stop: new moment(moment.parseZone(block.OfferStopTimeUtc).format('MM/DD/YYYY HH:mm')).toDate(),
    })).sort((a, b) => a.Start - b.Start);

    return parsed.reduce((data, xyObj) => {
        return [...data, {
            x: xyObj.Start,
            y: xyObj.Capacity,
        }, {
            x: xyObj.Stop,
            y: xyObj.Capacity,
        }]
    }, []);
}