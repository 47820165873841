import { forwardRef, useContext, useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material"
import renderTimezones from "../../TSRActivity/renderTimezones";
import { IconButton, Typography, Switch, Tooltip, Box, Grid, TextField, FormGroup, FormControlLabel, FormControl } from "@mui/material"
import { Toolbar, Button,  } from "@mui/material"
import useFilteredOptions from "../../TSRActivity/useFilteredOptions";
import { useForm } from 'react-hook-form';
import FormTextField from "../../FormControls/FormTextField";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormCheckbox from "../../FormControls/FormCheckbox";
import { useSnackbar } from "notistack";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from "@mui/icons-material/Close";

export default (props) => {
  const { open, view, handleSave, handleCancel, } = props;
  const [criteria, setCriteria] = useState({})
  const { providers, filteredCombos, customerCodes, filteredTSCombos, requestTypes } = useFilteredOptions({Provider: criteria.Provider, POR: criteria.POR, POD: criteria.POD, });
  const { enqueueSnackbar } = useSnackbar();

  const schema = yup.object().shape({
    label: yup.string().required(),
  })

  const { handleSubmit, formState: { errors }, reset, control } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      justConfirmed: true,
      ...view,
    });
  }, [view])

  function onSubmit(data) {
    handleSave(data);
  }

  function onErrors() {
    enqueueSnackbar(`Please address errors before saving view.`)
  }

  return(
    <Dialog open={open} fullWidth maxWidth='lg'>
      <DialogTitle>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography>Edit Filter Criteria</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={handleCancel} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{display: 'flex', p:1, paddingTop:2 }}>
          <form id='editViewForm' onSubmit={handleSubmit(onSubmit, onErrors)} style={{ width: '100%', }}>
            <Grid container spacing={2} alignItems='center' >
              <Grid item xs={5}>
                <FormTextField 
                  name="label"
                  control={control}
                  color="primary"
                  label="Label"
                  size="small"
                  fullWidth
                  placeholder='Label'
                  error={errors?.label}
                  helperText={errors?.label?.message}
                />
              </Grid>
              <Grid item xs={3}>
                <FormAutocomplete
                  name='requestType'
                  control={control}
                  fullWidth
                  size="small"
                  options={requestTypes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Request Type"
                      color="success" 
                      placeholder="Request Type"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='customerCode'
                  control={control}
                  fullWidth
                  multiple
                  size="small"
                  options={customerCodes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Customer Code"
                      color="success" 
                      placeholder="Customer Code"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl size="small">
                  <FormControlLabel label={'Confirmed Only?'} control={
                    <FormCheckbox 
                      name='justConfirmed'
                      control={control}
                    />
                  }/>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormAutocomplete
                  name='Provider'
                  control={control}
                  fullWidth
                  multiple
                  size="small"
                  options={providers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Provider"
                      color="success" 
                      placeholder="Provider"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='POR'
                  control={control}
                  size="small"
                  freeSolo
                  fullWidth
                  options={filteredCombos.pors}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="POR"
                      color="success" 
                      placeholder="POR"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='POD'
                  control={control}
                  freeSolo
                  fullWidth
                  options={filteredCombos.pods}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="POD"
                      color="success" 
                      placeholder="POD"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='serviceIncrement'
                  control={control}
                  fullWidth
                  freeSolo
                  size="small"
                  options={filteredTSCombos.serviceIncrements}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Service Increment"
                      color="success" 
                      placeholder="Service Increment"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <FormAutocomplete
                  name='serviceClass'
                  control={control}
                  fullWidth
                  freeSolo
                  size="small"
                  options={[ 'FIRM', 'NON-FIRM', 'SECONDARY' ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Class"
                      color="success" 
                      placeholder="Class"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1, }} />
        <Button variant="contained" color="primary" type='submit' form='editViewForm'>{`Save & Close`}</Button>
      </DialogActions>
    </Dialog>
  )
}