import { Box, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton } from "@mui/material";
import { useForm, } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useMemo } from "react";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { formFieldDefs } from "./FormFieldDefs";
import CloseIcon from '@mui/icons-material/Close';

const schema = yup.object().shape({
    parentField: yup.string().required('This field is required'),
    parentValue: yup.string().required('This field is required'),
    childField: yup.string().required('This field is required'),
    childValues: yup.array().min(1, 'At least one value is required'),
});

export const CreateEditLookupRuleDialog = ({ open, onCancel, onSave, rule }) => {
    const formId = 'create-edit-lookup-rule-form';

    const defaults = useMemo(() => {
        //if there is no rule text, return empty defaults
        if (!rule) {
            return {
                parentField: '',
                parentValue: '',
                childField: '',
                childValues: [],
            };
        } else {
            return rule;
        }
    }, [open]);

    useEffect(() => {
        reset(defaults);
    }, [defaults]);

    const { control, handleSubmit, formState: { errors }, register, reset, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });

    function handleSave(data) {
        onSave(data, rule);
    }

    const childValuesHelperText = errors.childValues?.message ?? 'Type a value then press Enter to add it to the list.';

    return (
        <Dialog open={open} fullWidth maxWidth='md'>
            <DialogTitle>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>{rule?.id ? 'Edit' : 'Create'} Lookup Rule</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={onCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Box component='form' id={formId} onSubmit={handleSubmit(handleSave)} sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', width: '100%', p: 1, justifyContent: 'center' }}>
                        <Typography>When</Typography>
                        <FormAutocomplete
                            autoHighlight
                            name='parentField'
                            control={control}
                            options={formFieldDefs.map(f => f.key)}
                            getOptionLabel={(option) => formFieldDefs.find(f => f.key === option)?.label ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size='small'
                                    error={!!errors.parentField}
                                    helperText={errors.parentField?.message}
                                    variant='standard'
                                    sx={{ minWidth: '200px' }}
                                />
                            )}
                        />
                        <Typography>is</Typography>
                        <TextField
                            {...register('parentValue')}
                            error={errors.parentValue}
                            helperText={errors.parentValue?.message}
                            size='small'
                            variant='standard'
                            sx={{ minWidth: '200px' }}
                        />
                        <Typography>then</Typography>
                        <FormAutocomplete
                            autoHighlight
                            name='childField'
                            control={control}
                            options={formFieldDefs.map(f => f.key)}
                            getOptionLabel={(option) => formFieldDefs.find(f => f.key === option)?.label ?? ''}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='standard'
                                    error={!!errors.childField}
                                    helperText={errors.childField?.message}
                                    sx={{ minWidth: '200px' }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', width: '100%', p: 1, justifyContent: 'center' }}>
                        <Typography>can be</Typography>
                        <FormAutocomplete
                            name='childValues'
                            control={control}
                            options={[]}
                            freeSolo
                            sx={{
                                width: '90%',
                            }}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size='small'
                                    error={!!errors.childValues}
                                    helperText={childValuesHelperText}
                                    variant='standard'
                                />
                            )}
                        />
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={onCancel} color='error'>Cancel</Button>
                <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
            </DialogActions>
        </Dialog>
    )
}