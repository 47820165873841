// import React from "react"
// import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import { Box, Paper, Typography, Divider, Tooltip, Chip, List, ListItem, } from "@mui/material"
import { TooltipProps, tooltipClasses } from "@mui/material"

export default ({ event, children }) => {

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(1, 1, 1, .95)',
      // color: 'rgba(0, 0, 0, 0.87)',
      color: 'white',
      boxShadow: theme.shadows[5],
      fontSize: 12,
    },
  }));

  return (
    <LightTooltip
      arrow
      placement="left"
      title={
        <div>
          <Box sx={{ pt: 2, }}>
            <Divider>
              Schedule
            </Divider>
            <List dense>
              {event.TimeZone && <ListItem>{`Time Zone: ${event.TimeZone}`}</ListItem>}
              {event.TargetExecutionTime && <ListItem>{`Target Time: ${event.TargetExecutionTime}`}</ListItem>}
              {event.userName && <ListItem>{`Queued By: ${event.userName}`}</ListItem>}
            </List>
            <Divider sx={{ pt: 2, }}>
              TSR Details
            </Divider>
            <List dense>
              {event.requestType && <ListItem>{`Type: ${event.requestType}`}</ListItem>}
              {event.Provider && <ListItem>{`Provider: ${event.Provider}`}</ListItem>}
              {event.POR && <ListItem>{`POR: ${event.POR}`}</ListItem>}
              {event.POD && <ListItem>{`POD: ${event.POD}`}</ListItem>}
              {event.Source && <ListItem>{`Source: ${event.Source}`}</ListItem>}
              {event.Sink && <ListItem>{`Sink: ${event.Sink}`}</ListItem>}
              {event.Path_Name && <ListItem>{`Path Name: ${event.Path_Name}`}</ListItem>}
              {event.Service_Increment && <ListItem>{`Increment: ${event.Service_Increment}`}</ListItem>}
              {<ListItem>{`Preconfirmed: ${event.Preconfirmed ? 'Yes' : 'No'}`}</ListItem>}
            </List>
            {event.ruleName &&
              <>
                <Divider sx={{ pt: 2, }}>
                  Rule Information
                </Divider>
                <List dense>
                  <ListItem>{`Rule: ${event.ruleName}`}</ListItem>
                  <ListItem>{`Description: ${event.ruleDescription}`}</ListItem>
                  {event.ruleStatus && <ListItem>{`Rule Status: ${event.ruleStatus}`}</ListItem>}
                </List>
              </>
            }
            <Divider sx={{ pt: 2, }}>
              Status Details
            </Divider>
            <List dense>
              {event.DispatchQueueStatus && <ListItem>{`Queue Status: ${event.DispatchQueueStatus}`}</ListItem>}
              {event.TSR_NewAssignmentRef && <ListItem>{`New aRef: ${event.TSR_NewAssignmentRef}`}</ListItem>}
              {event.tsrStatus && <ListItem>{`TSR Status: ${event.tsrStatus}`}</ListItem>}
              {event.SELLER_COMMENTS && <ListItem>{`Comments: ${event.SELLER_COMMENTS}`}</ListItem>}
              {event.timeQueued && <ListItem>{`Time Queued: ${event.timeQueued}`}</ListItem>}
            </List>
          </Box>
        </div>
      }>
      {children}
    </LightTooltip>
  )
}