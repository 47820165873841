import { useMemo } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, } = props;

  const rowData = useMemo(() => {
    const detailData = JSON.parse(data.childJson ?? '[]');
    return detailData;
  }, [data])

  const colDefs = useMemo(() => [
    {
      field: 'POSTING_REF',
      headerName: 'Posting Ref'
    },
    /*{
      field: 'SYSTEM_ELEMENT',
      headerName: 'Path',
    },*/
    {
      field: 'ATTRIBUTE_VALUE',
      headerName: 'Value',
    },
    {
      field: 'ATTRIBUTE_UNITS',
      headerName: 'Unit',
      initialHide: true,
    },
    {
      field: 'SYSTEM_ATTRIBUTE',
      headerName: 'Attribute',
    },
    {
      headerName: 'Start Time',
      field: 'START_TIME',
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      headerName: 'Stop Time',
      field: 'STOP_TIME',
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      headerName: 'Last Updated',
      field: 'TIME_OF_LAST_UPDATE_UTC',
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      field: 'ANNOTATION',
      headerName: 'Annotation',
    },
  ], [])

  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
    // flex: 1,
  }), [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, }}>
      <AgGridContainer
        style={{ width: "100%", fontSize: '10px' }}
      >
        <AgGridReact
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
        />
      </AgGridContainer>
    </Box>
  );
}