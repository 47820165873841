import { Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";

const FormCheckbox = ({ name, control, ...inputProps }) => {
    return (
        <Controller
            render={({ field }) =>
                <Checkbox
                    {...field}
                    {...inputProps}
                    onChange={(_) => field.onChange(!field.value)}
                    checked={!!field.value}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            field.onChange(!field.value);
                        }
                    }}
                />
            }
            name={name}
            control={control}
        />
    )
}

export default FormCheckbox;
