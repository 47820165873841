import { useState, useEffect, useContext } from "react";
import { CriteriaContext } from "./CriteriaContext/criteriaContext";

export default (currentValues) => {
  const { Provider, POR, POD, Source, Sink, Path_Name, Service_Increment, TS_Class, TS_Type, TS_Subclass, TS_Window, TS_Period } = currentValues;
  const criteria = useContext(CriteriaContext);

  const [filteredCombos, setFilteredCombos] = useState({
    sources: [],
    sinks: [],
    pods: [],
    pors: [],
  });

  const [filteredTSCombos, setFilteredTSCombos] = useState({
    serviceIncrements: [],
    tsClasses: [],
    tsTypes: [],
    tsPeriods: [],
    tsWindows: [],
    tsSubclasses: [],
  });

  useEffect(() => {
    handleFilterUpdate();
  }, [criteria.combinations, Provider, POR, POD, Source, Sink, ]);

  useEffect(() => {
    handleTSFilterUpdate();
  }, [criteria.tsCombinations, Service_Increment, TS_Class, TS_Window, TS_Period, TS_Subclass, TS_Type])

  function handleFilterUpdate() {
    const fullyFiltered = criteria.combinations.filter(combo => {
      return (
        comboHasValue(combo, 'Provider', Provider)
        && comboHasValue(combo, 'PointOfReceipt', POR)
        && comboHasValue(combo, 'PointOfDelivery', POD)
        && comboHasValue(combo, 'Source', Source)
        && comboHasValue(combo, 'Sink', Sink)
      )
    });
    setFilteredCombos({
      pors: formatFieldArray(fullyFiltered, 'PointOfReceipt'), //use set to only keep distinct elements
      pods: formatFieldArray(fullyFiltered, 'PointOfDelivery'),
      sources: formatFieldArray(fullyFiltered, 'Source'),
      sinks: formatFieldArray(fullyFiltered, 'Sink'),
      paths: formatFieldArray(fullyFiltered, 'PathName'),
    });
  }

  function handleTSFilterUpdate() {
    const fullyFiltered = criteria.tsCombinations.filter(combo => {
      return (
        comboHasValue(combo, 'ServiceIncrement', Service_Increment)
        && comboHasValue(combo, 'TSClass', TS_Class)
        && comboHasValue(combo, 'TSType', TS_Type)
        && comboHasValue(combo, 'TSWindow', TS_Window)
        && comboHasValue(combo, 'TSPeriod', TS_Period)
        && comboHasValue(combo, 'TSSubclass', TS_Subclass)
      )
    });
    setFilteredTSCombos({
      serviceIncrements: formatFieldArray(fullyFiltered, 'ServiceIncrement'),
      tsPeriods: formatFieldArray(fullyFiltered, 'TSPeriod'),
      tsClasses: formatFieldArray(fullyFiltered, 'TSClass'),
      tsWindows: formatFieldArray(fullyFiltered, 'TSWindow'),
      tsSubclasses: formatFieldArray(fullyFiltered, 'TSSubclass'),
      tsTypes: formatFieldArray(fullyFiltered, 'TSType'),
    });
  }

  function comboHasValue(combo, field, value) {
    if(!value) return true;
    const comboVal = combo[field].toLowerCase();
    return comboVal.includes(value.toLowerCase());
  }

  function formatFieldArray(arr, field) {
    const collapsed = arr.map(combo => combo[field]).filter(e => e);
    const sorted = collapsed.sort();
    const distinct = new Set(sorted);
    return [ ...distinct ];
  }

  return {
    ...criteria,
    filteredCombos,
    filteredTSCombos,
    handleFilterUpdate,
    handleTSFilterUpdate,
  }
}