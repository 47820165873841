import { RefreshButton } from "../../RibbonButtons";
import { RibbonGroupContainer } from "../../Ribbon";

export const GridActionsGroup = ({ formId, expanded, handleFetchData, }) => {
    return (
        <RibbonGroupContainer>
            <RefreshButton
                formId={formId}
                expanded={expanded}
                onRefresh={handleFetchData}
            />
        </RibbonGroupContainer>
    );
}