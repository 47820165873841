import { useState, useEffect, useMemo } from "react";
import MarketplaceGrid from './MarketplaceGrid';
import { ToggleButtonGroup, ToggleButton, Box, Collapse, Stack, Button, Grid, Typography, Tooltip, Autocomplete, TextField, } from "@mui/material";
import TsrTile from './TsrTile';
import AddIcon from '@mui/icons-material/Add';
import { useMsal } from "@azure/msal-react";
import DeleteIcon from '@mui/icons-material/Delete';
import DetailDialog from "./DetailDialog";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { apiUrlPrefix } from "../../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import useHeader from "../useHeader";
import { AgGridContainer } from "../AgGrid/AgGridContainer";

export default (props) => {
  const { allData, handleAddNew, view, } = props;
  const viewModeKey = 'marketplace-view-mode';
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader()
  const savedMode = localStorage.getItem(viewModeKey);
  const [viewMode, setViewMode] = useState(savedMode ?? 'largetile');
  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [detailTsr, setDetailTsr] = useState({});
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const { accounts } = useMsal();
  const [providers, setProviders] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [pors, setPors] = useState([]);
  const [pods, setPods] = useState([]);

  useEffect(() => { setData(allData) }, [allData])

  useEffect(fetchCombinations, [])

  useEffect(() => {
    setPors(formatFieldArray(combinations, 'PointOfReceipt'))
    setPods(formatFieldArray(combinations, 'PointOfDelivery'))
  }, [combinations])

  useEffect(() => {
    if (view !== 'mypostings') {
      setSelected([]);
    }
  }, [view])

  function applyFilters(filt = filters) {
    const { provider, por, pod } = filt;

    const filtered = allData.filter(posting => {
      return (!provider || posting.Provider === provider)
        && (!por || posting.PointOfReceipt === por)
        && (!pod || posting.PointOfDelivery === pod)
    })

    setData(filtered);
  }

  function updateFilter(key, value) {
    const newFilters = {
      ...filters,
      [key]: value,
    }
    setFilters(newFilters);

    const filteredCombos = combinations.filter(combo => {
      return (
        comboHasValue(combo, 'Provider', newFilters.provider)
        && comboHasValue(combo, 'PointOfReceipt', newFilters.por)
        && comboHasValue(combo, 'PointOfDelivery', newFilters.pod)
      )
    });
    setPors(formatFieldArray(filteredCombos, 'PointOfReceipt'))
    setPods(formatFieldArray(filteredCombos, 'PointOfDelivery'))
  }

  function comboHasValue(combo, field, value) {
    if (!value) return true;
    const comboVal = combo[field].toLowerCase();
    return comboVal.includes(value.toLowerCase());
  }

  function formatFieldArray(arr, field) {
    const collapsed = arr.map(combo => combo[field]).filter(e => e);
    const sorted = collapsed.sort();
    const distinct = new Set(sorted);
    return [...distinct];
  }

  async function fetchCombinations() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchAllPorPodCombinations`,
    }

    axios(options).then(response => {
      setCombinations(response.data);
      setProviders([...new Set(response.data.map(combo => combo.Provider).sort())]);
    }).catch(error => {
      enqueueSnackbar(`Error fetching provider choices. Message: ${error}`)
    });
  }

  function handleTileClick(tileId) {
    if (view === 'mypostings') {
      if (selected.includes(tileId)) {
        setSelected(selected.filter(id => id !== tileId));
      } else {
        setSelected([...selected, tileId]);
      }
    }
  }

  function handleDelete() {

  }

  function handleViewDetails(tileId) {
    const tsr = data.find(posting => posting.ID === tileId);
    setDetailTsr(tsr);
    setOpenDetailsDialog(true);
  }

  const visibleData = useMemo(() => {
    if (view === 'mypostings') {
      return data.filter(posting => posting.CUSTOMER_EMAIL === accounts[0].username);
    } else return data;
  }, [view, data])

  return (
    <Box
      sx={{ p: 2, width: '100%', height: '100%', }}
    //overflow='auto'
    >
      <DetailDialog
        open={openDetailsDialog}
        handleClose={() => setOpenDetailsDialog(false)}
        tsr={detailTsr}
      />
      <Stack direction='row' spacing={2} sx={{ p: 1, }}>
        <Tooltip title="Choose a Provider to filter by." arrow placement="top">
          <Autocomplete
            autoComplete
            autoSelect
            options={providers}
            onChange={(_, newValue) => {
              updateFilter('provider', newValue);
            }}
            style={{
              width: 150,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Provider"
                width='300px'
              />
            )}
          />
        </Tooltip>
        <Tooltip title="Choose a Point of Receipt to filter by." arrow placement="top">
          <Autocomplete
            autoComplete
            autoSelect
            options={pors}
            onChange={(_, newValue) => {
              updateFilter('por', newValue);
            }}
            style={{
              width: 150,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="POR"
              />
            )}
          />
        </Tooltip>
        <Tooltip title="Choose a Point of Delivery to filter by." arrow placement="top">
          <Autocomplete
            autoComplete
            autoSelect
            options={pods}
            onChange={(_, newValue) => {
              updateFilter('pod', newValue);
            }}
            style={{
              width: 150,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="POD"
              />
            )}
          />
        </Tooltip>
        <Button
          endIcon={<FilterAltIcon />}
          variant='contained'
          size='small'
          onClick={() => applyFilters()}
        >
          Apply Filters
        </Button>
        <Button
          endIcon={<ClearIcon />}
          variant='contained'
          size='small'
          onClick={() => {
            setFilters({});
            applyFilters({});
          }}
        >
          Clear Filters
        </Button>
        <Box sx={{ flexGrow: 1, }} />
        <Button
          endIcon={<AddIcon />}
          variant='contained'
          size='small'
          onClick={handleAddNew}
        >
          Add Posting
        </Button>
        {(view === 'mypostings') && <Button
          endIcon={<DeleteIcon />}
          variant='contained'
          size='small'
          onClick={handleDelete}
        >
          Delete Posting
        </Button>}&nbsp;&nbsp;&nbsp;
        <ToggleButtonGroup
          exclusive
          value={viewMode}
          size="small"
          color="primary"
          sx={{ mt: .2, mr: 1.3, display: 'contents' }}
          onChange={(e, newMode) => {
            if (newMode) {
              setViewMode(newMode)
              localStorage.setItem(viewModeKey, newMode);
            }
          }}
        >
          {[
            { label: 'large tile', value: 'largetile' },
            { label: 'small tile', value: 'smalltile' },
            { label: 'grid', value: 'grid' }
          ].map(tb => (
            <ToggleButton sx={{ mt: .5 }} key={tb.value} value={tb.value}>
              {tb.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      {!visibleData.length &&
        <Box display='flex' alignItems='center' justifyContent='center' height='100%' width='100%'>
          No postings to show. Try changing your filters.
        </Box>
      }
      {(viewMode.includes('tile')) &&
        <Grid container spacing={3} sx={{ alignItems: 'center', }}>
          {visibleData.map(tsr =>
            <Grid item xs={viewMode.includes('large') ? 3 : 2}>
              <TsrTile
                data={tsr}
                handleSelection={handleTileClick}
                selected={selected.includes(tsr.ID)}
                handleViewDetails={handleViewDetails}
              />
            </Grid>
          )}
        </Grid>
      }
      {(viewMode === 'grid') &&
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12}>
            <AgGridContainer
              style={{ height: '80vh', width: '100%' }}
            >
              <MarketplaceGrid rowData={visibleData} />
            </AgGridContainer>
          </Grid>
        </Grid>
      }
    </Box>
  )
}