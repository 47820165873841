import { Stack, Switch, IconButton, Dialog, DialogActions, DialogContent, Tabs, Tab, DialogTitle, } from "@mui/material"
//import ProfileDialog from "./ProfileDialog";
import moment from 'moment';
import { Collapse, Typography, Tooltip, FormGroup, FormControl, Accordion, AccordionSummary, AccordionDetails, Button, Toolbar, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useFilteredCriteria from "../CriteriaContext/useFilteredCriteria";
import useRequiredFields from "../../FastPathPro/useRequiredFields";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import axios from "axios";
import { apiUrlPrefix } from "../../../authConfig";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import SummaryView from './SummaryView.js';
import { UserContext } from "../../../data/fetchUserGroups";
import ProfileDialog from "../../ProfileDialog";

export default (props) => {
    const { open, action, setAction, pathData, handleClose, view } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [tsrConfigs, setTsrConfigs] = useState({});
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const guid = useLocalGuid();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [timezone, setTimezone] = useState('US/Pacific');
    const [targetExecutionTime, setTargetExecutionTime] = useState('');
    const storageKey = `ppw-trans-transmission-dialog`;
    const persistedValues = ['requestType', 'CustomerCode', 'ServiceIncrement', 'TSClass', 'TSType', 'TSPeriod', 'TSWindow', 'TSSubclass'];
    const onLastTab = (tabIndex === pathData.length);
    const userGroups = useContext(UserContext);
    const proUser = userGroups.includes('admins') || userGroups.includes('ppwtrans');

    useEffect(() => {
      if(pathData) {
        configureDefault();
      }
    }, [pathData]);

    function configureDefault() {
      const newConfigs = {};
      const storedValueString = localStorage.getItem(storageKey);
      const storedValues = JSON.parse(storedValueString ?? '{}');
      pathData.forEach((leg, i) => {
        const profile = JSON.parse(leg.profileInfo ?? '[]').map(block => ({
          price: block.offerPrice,
          capacityRequested: parseInt(block.capacity),
          startDateTime: moment(block.StartTime),
          endDateTime: moment(block.StopTime),
        }));

        newConfigs[i] = { 
          ...storedValues,
          ...leg,
          timezone: 'US/Pacific',
          Preconfirmed: true, 
          profileInfo: [],
          maxProfile: profile,
          profileConfig: {
            startDate: moment(profile[0]?.startDateTime),
            startHour: moment(profile[0]?.startDateTime).hours(),
            stopDate: moment(profile[profile.length - 1]?.endDateTime),
            stopHour: moment(profile[profile.length - 1]?.endDateTime).hours(),
            defaultPrice: leg.OfferPrice,
            defaultCapacity: leg.maxCapacity,
          }
        }
      });
      setTsrConfigs(newConfigs);
    }

    function handleTabSelect(event, newIndex) {
        setTabIndex(newIndex);
    }

    function navigate() {
        if(onLastTab) {
            handleSave();
        } else {
            setTabIndex(i => i + 1);
        }
    }

    function handleSave(confirmed=0) {
      const params = {
        userGuid: guid,
        timeZone: timezone,
        TargetExecutionTime: targetExecutionTime ?? '',
        application: `ppwtrans`,
        data: [...Object.keys(tsrConfigs).map(key => formatJson(tsrConfigs[key]))],
      }

      const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=PowerStationMetaData.UI_TransactionQueueInsert&parm=${guid}&parm=${params.timeZone}&parm=${params.TargetExecutionTime}&parm=${action}&parm=${confirmed}&parm=${params.application}`
  
      const options = {
        method: 'POST',
        headers: headers,
        data: params.data,
        url: url,
      }
  
      axios(options).then(response => {
        if(confirmed) {
          enqueueSnackbar('Success.')
        } else {
          setOpenConfirmationDialog(true);
          setConfirmationMessage(response.data);
        }
      }).catch(error => { 
        enqueueSnackbar(`Error saving TSR trigger. ${error.message}`) 
      });

      if(confirmed) {
        setOpenConfirmationDialog(false);
        setTabIndex(0);
        handleClose();
      }
    }

    function handleChange(key, value, configKey) {
      if(persistedValues.includes(key)) {
        const storedValueString = localStorage.getItem(storageKey);
        const storedValues = JSON.parse(storedValueString ?? '{}');
        localStorage.setItem(storageKey, JSON.stringify({
          ...storedValues,
          [key]: value,
        }))
      }

      if(key === 'timezone') {
        setTimezone(value);
        handleUpdateAll('timezone', value);
        return;
      } else if (key === 'primary') {
        handlePrimaryChange(configKey, value); 
        return;
      }

      const newConfig = {
          ...tsrConfigs[configKey],
          [key]: value,
      }
      setTsrConfigs({
        ...tsrConfigs,
        [configKey]: newConfig, 
      });
    }

    function handlePrimaryChange(configKey, value) {
      const newConfigs = {
        ...tsrConfigs
      }

      Object.keys(tsrConfigs).forEach(k => {
        newConfigs[k] = {
          ...newConfigs[k],
          primary: false,
        }
      })
      newConfigs[configKey].primary = value;
      setTsrConfigs(newConfigs);
    }

    function handleProfileUpdate(info, config, configKey) {
      const newConfig = {
        ...tsrConfigs[configKey],
        profileInfo: info,
        profileConfig: config,
      }

      setTsrConfigs({
        ...tsrConfigs,
        [configKey]: newConfig,
      });
    }

    function handleProfileSave(info) {
      const newConfigs = {
        ...tsrConfigs,
      }

      Object.keys(newConfigs).forEach(key => {
        newConfigs[key].profileInfo = info[key]
      })

      setTsrConfigs(newConfigs);
    }

    function handleUpdateAll(key, value) {
      const newConfigs = {
        ...tsrConfigs
      }

      Object.keys(tsrConfigs).forEach(k => {
        newConfigs[k] = {
          ...newConfigs[k],
          [key]: value,
        }
      })

      setTsrConfigs(newConfigs);
    }

    function formatJson(config) {
      return {
        leg: config.leg,
        Provider: config.TP,
        PointOfReceipt: config.PointOfReceipt,
        PointOfDelivery: config.PointOfDelivery,
        Source: config.Source,
        Sink: config.Sink,
        ServiceIncrement: config.ServiceIncrement,
        TSClass: config.TSClass,
        TSPeriod: config.TSPeriod,
        TSSubclass: config.TSSubclass,
        TSType: config.TSType,
        TSWindow: config.TSWindow,
        profileInfo: config.profileInfo,
        profileConfig: config.profileConfig,
        PathName: config.PathName,
        Preconfirmed: config.Preconfirmed ? 'Yes' : 'No',
        'Customer Code': config.CustomerCode,
        primary: config.primary,
      }
    }

    const tabData = pathData ? pathData : [];

    return(
      <Dialog maxWidth={'lg'} open={open}>
        <DialogTitle>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography>Multiple TSRs Wizard</Typography>
                <Box sx={{ flexGrow: 1, }} />
                <IconButton onClick={handleClose} size='large'>
                    <CloseIcon />
                </IconButton>
            </Box>
        </DialogTitle>
        <DialogContent>
          <ConfirmationDialog 
            open={openConfirmationDialog} 
            message={confirmationMessage}
            onCancel={() => setOpenConfirmationDialog(false)}
            onConfirmation={() => handleSave(1)}
          />
          <Box sx={{ p: 1, }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabSelect}
              indicatorColor="primary"
              textColor="primary"
              maxHeight="10px"
              color="primary"
              backgroundColor="transparent"
            >
              {tabData.map((leg) => (
                <Tab 
                  backgroundColor="transparent"
                  label={`${leg.PathName}`} 
                  id={`${leg.PostingRef}`} />
              ))}
              <Tab backgroundColor='transparent' label='Finish' id='completeTsrTab'/>
            </Tabs>
            {tabData.map((leg, i) => (
              <Collapse in={tabIndex === i} orientation={'vertical'}>
                <LegView 
                  tsrConfig={tsrConfigs[i] ?? {}}
                  currentLeg={i}
                  pathData={pathData}
                  handleChange={(k, v) => handleChange(k, v, i)}
                  handleProfileSave={(info, config) => handleProfileSave(info, config)}
                />
              </Collapse>    
            ))}
            <Collapse in={onLastTab} orientation='vertical'>
              <SummaryView 
                data={tsrConfigs}
                action={action}
                setAction={setAction}
                handleSetDate={newVal => setTargetExecutionTime(newVal)}
                visible={onLastTab}
              />
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          {!!tabIndex && <Button variant="contained" color="primary" onClick={() => setTabIndex(i => i - 1)}>Back</Button>}
          <Box sx={{ flexGrow: 1, }}/>
          <Button variant="contained" disabled={onLastTab && !proUser} color="primary" onClick={navigate}>{onLastTab ? 'Submit Reservations' : 'Next'}</Button>
        </DialogActions>
      </Dialog>
    )
}

const LegView = (props) => {
    const { tsrConfig, handleChange, handleProfileSave, pathData, currentLeg } = props;
    const [showMore, setShowMore] = useState(false);
    const { filteredCombos, tsCombinations, customerCodes, relatedRefs, } = useFilteredCriteria(tsrConfig ?? {});
    const { hasMissingFields, isMissing, hasMissing } = useRequiredFields({ TargetExecutionTime: 'Schedule Date', TP: 'Provider', POR: 'POR', POD: 'POD', 'Customer Code': 'Customer Code', Service_Increment: 'Service Increment', TimeZone: "Timezone", profileInfo: 'Profile', TS_Type: 'TS Type', TS_Period: 'TS Period', TS_Window: 'TS Window', TS_Class: 'TS Class', })
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const userGroups = useContext(UserContext);
    const proUser = userGroups.includes('admins') || userGroups.includes('ppwtrans');

    function handleProfileUpdate(info) {
      handleProfileSave(info);
      setOpenProfileDialog(false);
    }
    
    function getRelatedRefHelperText() {
      const obj = relatedRefs.find(option => option.AssignmentRef === tsrConfig.relatedRef);
      return obj ? `POR: ${obj.PointOfReceipt} - POD: ${obj.PointOfDelivery}` : '';
    }

    const renderTimezones = {
      'Pacific': 'US/Pacific',
      'US/Pacific': 'Pacific',
      'Eastern': 'US/Eastern',
      'US/Eastern': 'Eastern',
      'Central': 'US/Central',
      'US/Central': 'Central',
      'Mountain': 'US/Mountain',
      'US/Mountain': 'Mountain',
    }

    const defaultPriceData = pathData.reduce((prices, segment, idx) => ({
      ...prices,
      [idx+1]: segment.OfferPrice
    }), {})

    return(
      <Box sx={{display: 'flex', p:1}}>
        {openProfileDialog && <ProfileDialog
          open={openProfileDialog} 
          closeDialog={() => setOpenProfileDialog(false)} 
          currentEvent={tsrConfig} 
          currentLeg={currentLeg}
          defaultInfo={tsrConfig.profileInfo}
          maxInfo={tsrConfig.maxProfile}
          pathData={pathData}
          handleProfileSave={handleProfileUpdate}
          disabled={!proUser}
          defaultValues={{
            defaultPriceData: defaultPriceData,
          }}
        />}
        <Grid container direction={'column'}>
          {showMore 
            ? (<Grid container justifyItems='start' alignItems='center' style={{ marginTop: '10px' }} spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<ChevronLeftIcon />}
                    variant="contained"
                    size='small'
                    onClick={() => setShowMore(false)}
                  >Back</Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField 
                    fullWidth
                    multiline
                    placeholder="Customer Comments"
                    value={tsrConfig.Customer_Comments}
                    rows={5}
                    variant='outlined' 
                    onChange={(e) => handleChange('Customer_Comments', e.target.value)}
                    label='Customer Comments'
                    disabled={!proUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={'Sales Ref'}
                    size='small'
                    fullWidth
                    value={tsrConfig.SaleRef}
                    onChange={(e) => handleChange('SaleRef', e.target.value)}
                    disabled={!proUser}
                  />
                </Grid>
              </Grid>) 
            : (<Grid container justifyContent="center" alignItems='center' style={{ marginTop: '10px' }} spacing={2}>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    disabled={!proUser}
                    options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                    value={renderTimezones[tsrConfig.timezone] ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('timezone', renderTimezones[newValue]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Timezone"
                        size='small'
                        color="success" 
                        placeholder="Timezone"
                        error={hasMissing && isMissing(tsrConfig, 'timezone')}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Autocomplete
                    fullWidth
                    disabled={!proUser}
                    options={filteredCombos.paths}
                    value={tsrConfig.PathName ?? ''}
                    freeSolo
                    onChange={(_, newValue) => {
                      handleChange('PathName', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Path Name"
                        color="success" 
                        placeholder="Path Name"
                        onChange={(e) => handleChange('PathName', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  {/* <Box sx={{justifyContent: 'center'}}> */}
                  <Tooltip title="Click to set additional fields such as customer code." arrow placement="top">
                    <Button
                      startIcon={<ChevronRightIcon />}
                      variant="contained"
                      fullWidth
                      onClick={() => setShowMore(true)}
                    >More</Button>
                    </Tooltip>
                  {/* </Box> */}
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={filteredCombos.pors}
                    value={tsrConfig.PointOfReceipt ?? ''}
                    onChange={(e, newValue) => {
                      handleChange('PointOfReceipt', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POR"
                        color="success" 
                        error={hasMissing && isMissing(tsrConfig, 'POR')}
                        placeholder="POR"
                        onChange={(e) => handleChange('POR', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={filteredCombos.pods}
                    value={tsrConfig.PointOfDelivery ?? ''}
                    onChange={(e, newValue) => {
                      handleChange('PointOfDelivery', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POD"
                        error={hasMissing && isMissing(tsrConfig, 'POD')}
                        onChange={(e) => handleChange('POD', e.target.value)}
                        color="success" 
                        placeholder="POD"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    freeSolo
                    disabled={!proUser}
                    fullWidth
                    options={filteredCombos.sources}
                    value={tsrConfig.Source ?? ''}
                    onChange={(e, newValue) => {
                      handleChange('Source', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Source"
                        color="success" 
                        placeholder="Source"
                        onChange={(e) => handleChange('Source', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="Sink"
                    freeSolo
                    fullWidth
                    disabled={!proUser}
                    options={filteredCombos.sinks}
                    value={tsrConfig.Sink ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('Sink', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Sink"
                        color="success" 
                        placeholder="Sink"
                        onChange={(e) => handleChange('Sink', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={customerCodes}
                    value={tsrConfig.CustomerCode}
                    onChange={(_, newValue) => {
                      handleChange('CustomerCode', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Customer Code"
                        color="success" 
                        placeholder="Customer Code"
                        onChange={(e) => handleChange('CustomerCode', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} justifyContent='flex-start'>
                  <Autocomplete
                    options={tsCombinations.tsIncrements}
                    value={tsrConfig.ServiceIncrement ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('ServiceIncrement', newValue);
                    }}
                    disabled={!proUser}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Service Increment"
                        size='small'
                        error={hasMissing && isMissing(tsrConfig, 'ServiceIncrement')}
                        color="success" 
                        onChange={(e) => handleChange('ServiceIncrement', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={tsCombinations.tsClasses}
                    value={tsrConfig.TSClass ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('TSClass', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Class"
                        color="success" 
                        placeholder="TS Class"
                        error={hasMissing && isMissing(tsrConfig, 'TSClass')}
                        onChange={(e) => handleChange('TSClass', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={tsCombinations.tsTypes}
                    value={tsrConfig.TSType ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('TSType', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Type"
                        color="success" 
                        placeholder="TS Type"
                        error={hasMissing && isMissing(tsrConfig, 'TSType')}
                        onChange={(e) => handleChange('TSType', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={tsCombinations.tsPeriods}
                    value={tsrConfig.TSPeriod ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('TSPeriod', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Period"
                        color="success" 
                        placeholder="TS Period"
                        error={hasMissing && isMissing(tsrConfig, 'TSPeriod')}
                        onChange={(e) => handleChange('TSPeriod', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={tsCombinations.tsWindows}
                    value={tsrConfig.TSWindow ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('TSWindow', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Window"
                        color="success" 
                        placeholder="TS Window"
                        error={hasMissing && isMissing(tsrConfig, 'TSWindow')}
                        onChange={(e) => handleChange('TSWindow', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disabled={!proUser}
                    options={tsCombinations.tsSubclasses}
                    value={tsrConfig.TSSubclass ?? ''}
                    onChange={(_, newValue) => {
                      handleChange('TSSubclass', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Subclass"
                        color="success" 
                        placeholder="TS Subclass"
                        onChange={(e) => handleChange('TSSubclass', e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <input
                    type='checkbox' 
                    class='large-checkbox'
                    checked={tsrConfig.primary} 
                    onChange={(e) => handleChange('primary', e.target.checked)}
                  />&nbsp; 
                  Parent
                </Grid>
                <Grid item xs={2}>
                  <input
                    type='checkbox' 
                    class='large-checkbox'
                    checked={tsrConfig.Preconfirmed} 
                    onChange={(e) => handleChange('Preconfirmed', e.target.checked)}
                  />&nbsp; 
                  Pre-confirmed
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => setOpenProfileDialog(true)}
                    variant="contained"
                    fullWidth
                  >Set Profile</Button>
                </Grid>
              </Grid>)
          } 
        </Grid>
      </Box>
    )
}