import { useQuery } from "react-query"; //useMutation
import { getLocations } from "./dataFetching";

function useLocations() {
    const { isLoading, isFetching, data, isError } = useQuery(
        ["locations"],
        getLocations
    );
    //alert(JSON.stringify(getLocations));
    return { isLoading, isFetching, data, isError };
}

export { useLocations };