import { Tooltip } from "@mui/material";
import { RibbonButton } from "../Ribbon";
import { useCallback, useState } from "react";
import debounce from "lodash/debounce";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { useApi } from "../../useApi";
import CheckIcon from '@mui/icons-material/Check';

export const ConfirmScheduleButton = ({ expanded, selectedSchedule, toConfirm, gridRef, ...buttonProps }) => {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const { headers, apiUrlPrefix, enqueueSnackbar, logAction, get } = useApi();
    const buttonDebounce = 1500;

    function handleConfirmConfirm() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to ${toConfirm ? '' : 'un'}confirm the selected schedule (${selectedSchedule.scheduleID}). Continue?`,
            onConfirmation: handleConfirm,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleConfirm = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_confirmSchedule_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedSchedule.scheduleID}`
            + `&parm=${toConfirm ? 1 : 0}`

        return get(url).then(response => {
            enqueueSnackbar(`Schedule ${toConfirm ? '' : 'un'}confirmed successfully.`, { variant: 'success' });
            logAction(`User ${toConfirm ? '' : 'un'}confirmed schedule with id ${selectedSchedule.scheduleID}`, 'Schedule Summary');
            gridRef?.current.api?.applyTransaction({ update: [{ ...selectedSchedule, Status: toConfirm ? 'CONFIRMED' : 'DRAFT', Schedule_Status: toConfirm ? 'CONFIRMED' : 'DRAFT' }] });
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, selectedSchedule]);

    const confirmButtonText = toConfirm ? 'Confirm' : 'Unconfirm';

    return (
        <>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={`${toConfirm ? 'C' : 'Unc'}onfirm the selected schedule.`}>
                <span>
                    <RibbonButton
                        icon={<CheckIcon fontSize='small' />}
                        onMouseDown={handleConfirmConfirm}
                        label={confirmButtonText}
                        expanded={expanded}
                        disabled={!selectedSchedule?.scheduleID}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>

    );
}