import { forwardRef } from "react";
import { SnackbarContent } from 'notistack';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { AlertTitle, Box } from "@mui/material";

const UserMessageBox = forwardRef((props, ref) => {
  const { id, message, title, handleClose } = props;

  return(    
    <SnackbarContent ref={ref} >
      <Stack spacing={2} sx={{ maxWidth: 600 }}>
        <Alert sx={{ maxWidth: 600 }} severity="info" elevation={6} variant="filled">
            <AlertTitle>                  
              <Box sx={{ display: 'flex' }}>
                <Typography>{message}</Typography>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => handleClose(id)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </AlertTitle>
            {title}
        </Alert>
      </Stack>  
    </SnackbarContent>
  );
})

export default UserMessageBox;