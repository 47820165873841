import { useMemo, useContext, useEffect, useState, useRef, useCallback } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useActionAudit } from '../useActionAudit';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, userGroups } from '../../authConfig';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useViewPanel } from '../ToolPanels/useViewPanel';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { AgGridReact } from 'ag-grid-react';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useData } from '../useData';
import WorkflowDialog from './WorkflowDialog';
import useHeader from '../useHeader';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUserGroups } from '../../data/useUserGroups';
import { EditXmlDialog } from './EditXmlDialog';

const WorkflowGrid = () => {
  const theme = useTheme();
  const ref = useRef();
  const { logAction } = useActionAudit();
  const { userIsInGroup } = useUserGroups();
  const hasFullAccess = userIsInGroup(userGroups.workflow);
  const initiatorOnly = userIsInGroup(userGroups.workflowinitiator) && !hasFullAccess;
  const [editXmlDialogState, setEditXmlDialogState] = useState({ open: false, xml: '', handleClose: null, });

  const grid = "workflow";

  const { enqueueSnackbar } = useSnackbar();
  const header = useHeader();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const dataUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_WorkflowsFetch&parm=${header.userGuid}`;
  const { rowData, getData, } = useData();

  const onRefreshBtn = () => {
    getData(dataUri);
    let message = 'Workflow List Refreshing...';
    enqueueSnackbar(message, { variant: 'info' });
  };

  useEffect(() => {
    rowData && ref.current.api?.redrawRows();
  }, [rowData]);

  useEffect(() => {
    let abortController = new AbortController();
    getData(dataUri);
    return () => {
      abortController.abort();
    }
  }, []);

  const addRowItems = (addIndex) => {
    const newItems = [{}];
    addRow(newItems);
  };

  function addRow(data) {
    const res = ref.current.api.applyTransaction({
      add: data,
    });
  };

  async function onSaveDataButtonClick() {
    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();
    enqueueSnackbar('Submitting workflows to database...', { variant: 'info' });

    const rowData = [];

    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      rowData.push(newData);
    })

    let saveUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_WorkflowSave&parm=${header.userGuid}`

    const options = {
      method: 'POST',
      headers: header,
      data: rowData,
      url: saveUrl
    };

    axios(options).then(response => {
      enqueueSnackbar('Workflows sent to Power Station database.', { variant: 'success' });
      logAction('Workflows saved', 'Workflow Launcher');
      getData(dataUri);
    }).catch(err => { enqueueSnackbar(`Status: ${err.response?.status}. Message: ${err} URL: ${saveUrl}`, { variant: 'error' }) });

    ref.current.api.hideOverlay();
  }

  const onBtnExport = () => {
    ref.current.api.exportDataAsCsv();
  };

  const colDefs = [
    {
      checkboxSelection: true,
      width: 30,
      initialHide: "false",
      headerName: "ID",
      field: "workflowID",
      filter: "agMultiColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Parent",
      width: "100",
      initialHide: "false",
      field: "parentName",
      filter: "agMultiColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Workflow",
      width: "100",
      initialHide: "false",
      field: "name",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Description",
      width: "100",
      initialHide: "false",
      field: "description",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      headerName: "Tenant ID",
      width: "30",
      initialHide: "true",
      field: "tenantID",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      headerName: "Last Run Date",
      width: "100",
      initialHide: "false",
      field: "lastDateTimeStarted",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      headerName: "Last Finish Date",
      width: "100",
      initialHide: "false",
      field: "lastDateTimeFinished",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      headerName: "Last Used Guid",
      width: "100",
      initialHide: "true",
      field: "lastUsedBy",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Method",
      width: "100",
      initialHide: "false",
      field: "method",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Url",
      width: "100",
      initialHide: "true",
      field: "apiUrl",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
    },
    {
      editable: hasFullAccess,
      headerName: "Context",
      width: "100",
      initialHide: "true",
      field: "context",
    },
    {
      editable: hasFullAccess,
      headerName: "Context XML",
      width: "200",
      field: "contextXml",
      cellRenderer: 'contextXmlCellRenderer',
    },
  ];

  const ContextXmlCellRenderer = (props) => {
    const contextXml = props.value;
    return (
      <Stack spacing={1} direction={'row'}>
        <Button
          size='small'
          variant='contained'
          sx={{ height: props.node.rowHeight }}
          disabled={!hasFullAccess}
          onClick={() => setEditXmlDialogState({
            open: true,
            xml: contextXml,
            handleSave: (newXml) => {
              newXml && props.node.setDataValue(props.column.colId, newXml);
            },
            handleClose: () => setEditXmlDialogState({ open: false, xml: '', handleClose: null, }),
          })}
        >Edit</Button>
        <pre>{contextXml}</pre>
      </Stack>
    );
  };

  const storageLocation = 'workflow-grid-layout';
  const useColDefs = true;
  const { viewPanel, loadLayoutLocal } = useViewPanel(grid, ref, storageLocation, colDefs, useColDefs);

  const usersSideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        viewPanel,
      ],
      position: 'right',
    }
  }, [viewPanel]);

  /******* Launch Workflow logic *******/
  const [toLaunch, setToLaunch] = useState([]);
  const [openLaunchDialog, setOpenLaunchDialog] = useState(false);

  useEffect(() => {
    if (toLaunch.length > 0) {
      setOpenLaunchDialog(true);
    } else {
      setOpenLaunchDialog(false);
    }
  }, [toLaunch]);

  function launchWorkflows() {
    const selectedData = ref.current.api.getSelectedRows();

    if (selectedData.length === 0) {
      enqueueSnackbar('Please select a workflow to launch.', { variant: 'info' });
    }

    setToLaunch(selectedData);
  }

  function handleLaunch(workflow) {
    let url = apiUrlPrefix + workflow.apiUrl;

    try {
      const context = JSON.parse(workflow.context);
      context.forEach(ctx => {
        if (ctx.type?.toLowerCase() === 'get') {
          url += `&parm=${ctx.value}`;
        }
      });
    } catch (error) {
      enqueueSnackbar(`Error parsing context JSON. Error: ${error.message}`, { variant: 'error' });
      return;
    }

    const options = {
      method: workflow.method,
      headers: header,
      data: workflow.context,
      url: url,
    }

    axios(options).then(response => {
      enqueueSnackbar(`Workflow ${workflow.name} launched.`, { variant: 'success' });
    }).catch(error => {
      enqueueSnackbar(`${workflow.name} failed to launch. ${error.message}`, { variant: 'error' });
    });

    const [justLaunched, ...remaining] = toLaunch;
    setToLaunch(remaining);
  }

  function handleLaunchCancel() {
    const [cancelled, ...remaining] = toLaunch;
    setToLaunch(remaining);
  }

  function onGridReady(params) {
    loadLayoutLocal();
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    enableRowGroup: true,
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  return (
    <AgGridContainer style={{ height: '100%', paddingRight: theme.spacing(1), }} >
      <WorkflowDialog
        workflow={toLaunch[0]}
        open={openLaunchDialog}
        handleLaunch={handleLaunch}
        handleCancel={handleLaunchCancel}
      />
      <EditXmlDialog {...editXmlDialogState} />
      <Stack direction="row" spacing={2} sx={{ paddingBottom: 1, }}>
        <Tooltip title="Refresh the user list." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>
        <Tooltip title="Add a new blank row to the grid." arrow placement="top">
          <Button
            endIcon={<AddCircleOutlinedIcon />}
            id="addRowCopy"
            disabled={initiatorOnly}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={addRowItems}
          >Add Workflow</Button>
        </Tooltip>
        <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineIcon />}
            id="csv"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>
        </Tooltip>
        <Tooltip title="Save all information." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            disabled={initiatorOnly}
            size={buttonSize}
            onClick={onSaveDataButtonClick}
          >Save All</Button>
        </Tooltip>
        <Tooltip title="Launch selected workflows." arrow placement="top">
          <Button
            endIcon={<PlayCircleOutlineIcon />}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={launchWorkflows}
          >Execute</Button>
        </Tooltip>
      </Stack>
      <AgGridReact
        ref={ref}
        rowData={rowData}
        onGridReady={onGridReady}
        storageLocation='users'
        hasLayoutPanel={false}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        enableCellChangeFlash={true}
        groupDefaultExpanded={true}
        defaultColDef={defaultColDef}
        sideBar={usersSideBar}
        rowDragManaged={true}
        animateRows={true}
        rowDragMultiRow={true}
        rowSelection='multiple'
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        tooltipShowDelay={0}
        components={{
          viewToolPanel: GridViewToolPanel,
          contextXmlCellRenderer: ContextXmlCellRenderer,
        }}
      />
    </AgGridContainer>
  );
};

export default function WorkflowGridExport() {
  return <WorkflowGrid />;
}