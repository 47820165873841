import { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import ChatIcon from '@mui/icons-material/Chat';
import { Drawer } from '../StyledDrawer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';

export const Menu = ({ handleSelection, selected }) => {
    const [open, setOpen] = useState(false);

    return (
        <Drawer
            variant="permanent"
            open={open}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <List>
                <Tooltip title="A place to learn about Tariffs." arrow placement="right">
                    <ListItemButton
                        selected={selected === 'tariffchat'}
                        onClick={() => handleSelection('tariffchat')}>
                        <ListItemIcon>
                            <ChatIcon />
                        </ListItemIcon>
                        <ListItemText primary="Chat" />
                    </ListItemButton>
                </Tooltip>
                <Tooltip title="View the calendar of market auctions." arrow placement="right">
                    <ListItemButton
                        selected={selected === 'auctions'}
                        onClick={() => handleSelection('auctions')}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Auctions" />
                    </ListItemButton>
                </Tooltip>
                <Tooltip title="Use the tariff prices default settings utility." arrow placement="right">
                    <ListItemButton
                        selected={selected === 'tariffprices'}
                        onClick={() => handleSelection('tariffprices')}>
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tariff Prices" />
                    </ListItemButton>
                </Tooltip>
            </List>
        </Drawer>
    );
}