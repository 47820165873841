import dayjs from '../dayjs-tz';
import isEqual from 'lodash/isEqual';

export const generateTotalRowData = (data, totalColKey) => {
    if (!data?.length) return [];
    return [data.reduce((acc, next) => {
        //check if next is an object; may not be the case for group rows
        if (typeof next !== 'object') return acc;
        Object.keys(next).forEach(key => {
            //try to parse the key as an int; if this fails, set the value to null; this way we only show totals for HE columns
            const isNumberCol = !isNaN(parseInt(key));
            if (isNumberCol) {
                const sum = parseFloat(acc[key] ?? 0) + parseFloat(next[key] ?? 0);
                acc[key] = parseFloat(sum.toFixed(2));
            }
        });
        return acc;
    }, { [totalColKey]: 'Total' })]; //the totalColKey is the key for the column in which to display the word Total
};

export const handleUpdateTotalRows = (api, totalColKey) => {
    const visibleRows = [];
    api.forEachNodeAfterFilterAndSort((node) => {
        visibleRows.push(node.data);
    });
    const totalRows = generateTotalRowData(visibleRows, totalColKey);
    //api.setGridOption('pinnedBottomRowData', totalRows);
    api.setPinnedBottomRowData(totalRows);
};

export const colorForValue = (val, min = -100, max = 100) => {
    const minCap = Math.max(min, -100);
    const maxCap = Math.min(max, 100);
    const slope = 1.0 / (maxCap - minCap);
    const intercept = -minCap / (maxCap - minCap);
    const effectivePrice = Math.max(Math.min(val, maxCap), minCap);
    const value = slope * effectivePrice + intercept;
    const h = (1.0 - value) * 240;
    const s = 25; // Lower saturation for more washed-out colors
    const l = 60; // Higher lightness for a more faded effect
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
}

export const heatmapStyle = (params, paletteRef) => {
    let styles = {};
    if (!!paletteRef.current.showHeatmap && !!params.value) {
        styles = {
            backgroundColor: colorForValue(params.value),
            textShadow: 'black 1px 1px 4px',
            color: 'white',
        }
    }
    return styles;
};

export function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = dayjs(xValue).format('MM/DD/YY HH:mm');
    const value = Number(yValue).toFixed(2);
    return {
        title: tzDate,
        content: value,
    }
}

export const redPastMarkerFormatter = params => {
    const { xValue, highlighted } = params;
    const isInPast = xValue < new Date();
    const defaultOptions = {
        size: highlighted ? 3 : 1,
        enabled: true,
    };
    const pastOptions = {
        fill: palette.pastelRed,
        stroke: palette.pastelRed,
    };
    return {
        ...defaultOptions,
        ...(isInPast && pastOptions),
    };
}

const palette = {
    blue: 'rgb(20,94,140)',
    lightBlue: 'rgb(182,219,242)',
    green: 'rgb(63,141,119)',
    lightGreen: 'rgba(75,168,142, 0.2)',
    pastelRed: 'rgb(255,102,102)',
};

export const diffData = (newData, oldData, id) => {
    const diffStart = dayjs();
    //check if id is a function; if so, use it to get the id, otherwise assume it's a string, and use it as the key
    const idFn = typeof id === 'function' ? id : (row) => row[id];

    const newIdHash = newData.reduce((hash, next) => {
        hash[idFn(next)] = next;
        return hash;
    }, {})

    const oldIdHash = oldData.reduce((hash, next) => {
        hash[idFn(next)] = next;
        return hash;
    }, {})

    const toAdd = newData.reduce((adding, next) => {
        if (!oldIdHash[idFn(next)]) { //if the old data doesn't have this id, it's a new row
            adding.push(next);
        }
        return adding;
    }, [])

    const toUpdate = newData.reduce((updating, next) => {
        if (oldIdHash[idFn(next)] && !isEqual(newIdHash[idFn(next)], oldIdHash[idFn(next)])) { //if the old data also has this id and the data changed, update it
            updating.push(next);
        }
        return updating;
    }, [])

    const toDelete = oldData.reduce((deleting, next) => {
        if (!newIdHash[idFn(next)]) { //if the new data doesn't have this id, delete the row
            deleting.push(next);
        }
        return deleting;
    }, [])

    return {
        toAdd,
        toUpdate,
        toDelete,
        diffStart,
    }
}

export const jsonOrCommaSeparatedFormatter = (value) => {
    //this is a bit of a mess, but the values can be in a few different formats, don't ask why
    //values may be comma separated lists, like 'MyBook,MyOtherBook'
    //they may also be stringified arrays, like'[{"label":"MyBook","percentage":"100"}]' 
    //try to parse, if it fails, split
    let parsedVal;
    try {
        const parsed = JSON.parse(value);
        parsedVal = parsed?.map((item) => `${item.label}: ${item.percentage}`).join(', ');
    } catch (e) {
        //check if value is a string
        if (typeof value === 'string') {
            parsedVal = value?.split(',').join(', ');
        } else if (Array.isArray(value)) {
            parsedVal = value?.map((item) => `${item.label}: ${item.percentage}`).join(', ');
        }
    }
    return parsedVal;
}

//determine if a date is a long day, which is a day with more than 24 hours
export const isLongDay = (date) => {
    const startOfDay = dayjs(date).startOf('day');
    const endOfDay = startOfDay.add(1, 'day');

    // Calculate the difference in hours between the start and end of the day
    const dayDuration = dayjs.duration(endOfDay.diff(startOfDay)).asHours();

    // Return true if the day duration is 25 hours
    return dayDuration > 24;
}

