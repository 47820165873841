import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { apiUrlPrefix, } from '../../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import useHeader from '../useHeader';

export const ReleaseNotes = () => {
  const header = useHeader();
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState({});

  useEffect(() => {
    loadNotes();
  }, []);


  async function loadNotes() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ReleaseNotesFetch&parm=${guid}`

    let options = {
      headers: header,
      url: url,
    };

    const response = await axios(options).catch(err => { enqueueSnackbar(`Error loading release notes.  URL: ${url} Error:  ${err}`) });

    setNotes(response?.data?.[0] ?? {});
  };

  return (
    <div style={{ width: "98%" }}>
      <Box
        sx={{
          p: '7px',
          flexGrow: 1,
        }}
      >
        {notes.html &&
          <div dangerouslySetInnerHTML={{ __html: notes.html }} />
        }
      </Box>
    </div>
  );
};