import { Box, List, ListItemIcon, ListItemButton, Paper, ListItemText, Stack, Typography, Button, Checkbox, ListItem } from "@mui/material"
import { forwardRef, useMemo, useState } from "react"
import { useLookupValues } from "./LookupContext";
import { groupBy } from "lodash";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { CreateEditLookupRuleDialog } from "./CreateEditLookupRuleDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from "../../useApi";

export const Config = forwardRef((props, ref) => {
    const { hide } = props;
    const { lookupValues, } = useLookupValues();
    const [openCreateEditRule, setOpenCreateEditRule] = useState(false);
    const [selectedRule, setSelectedRule] = useState(null);
    const [values, setValues] = useState(lookupValues);
    const { post, enqueueSnackbar, logAction, apiUrlPrefix, headers } = useApi();

    const rules = useMemo(() => {
        const newRules = [];
        for (let lookup of values) {
            if (lookup.childrenLookupValues?.length > 0) {
                //group the children by lookupField
                const children = groupBy(lookup.childrenLookupValues, 'lookupField');
                for (let childField in children) {
                    const childValues = children[childField].map(child => child.value);
                    newRules.push({
                        id: lookup.lookupValuesID,
                        childField,
                        parentField: lookup.lookupField,
                        parentValue: lookup.value,
                        childValues,
                        text: `When ${lookup.lookupField} is ${lookup.value} then ${childField} can be ${childValues.join(', ')}`,
                    });
                }
            }
        }
        return newRules;
    }, [values]);

    function handleEditRule(rule) {
        const lookupRule = rules.find(v => v.id === rule.id && v.childField === rule.childField);
        setSelectedRule(lookupRule);
        setOpenCreateEditRule(true);
    }

    function handleCreateRule() {
        setSelectedRule(null);
        setOpenCreateEditRule(true);
    }

    function handleRulesUpdate(newRule, oldRule) {
        setOpenCreateEditRule(false);
        const { parentField, parentValue, childField, childValues } = newRule;
        setValues(prev => {
            let newValues = [...prev];
            let newLookup = newValues.find(v => oldRule && v.lookupValuesID === oldRule.id && v.value === oldRule.parentValue) ?? {};

            newLookup.lookupField = parentField;
            newLookup.value = parentValue;
            newLookup.toolTip = newLookup.toolTip ?? '';
            const ignoredLookupValues = (newLookup.childrenLookupValues ?? []).filter(v => v.lookupField !== childField);
            //if the user changed the child field, we need to remove the old values that have the old child field
            let filteredLookupVales = ignoredLookupValues;
            if (oldRule?.childField !== childField) {
                filteredLookupVales = ignoredLookupValues.filter(v => v.lookupField !== oldRule.childField);
            }

            const modifiedLookupValues = childValues.map(value => {
                const tryFindExisting = values.find(v => v.lookupField === childField && v.value === value);
                return tryFindExisting ?? { lookupField: childField, value, toolTip: '' };
            });
            newLookup.childrenLookupValues = [...filteredLookupVales, ...modifiedLookupValues];

            newValues = newValues.filter(v => v.lookupValuesID !== newLookup.lookupValuesID);
            newValues.push(newLookup);
            return newValues;
        });
        setSelectedRule(null);
    }

    async function handleSaveRules() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.updateLookupValuesDefault&parm=${headers.userGuid}`;
        const formattedValues = values.map(v => ({
            ...v,
            childrenLookupValues: !!v.childrenLookupValues?.length ? JSON.stringify(v.childrenLookupValues) : undefined,
        }));

        return post(url, JSON.stringify(formattedValues)).then(response => {
            enqueueSnackbar('Lookup rules saved successfully.', { variant: 'success' });
            logAction('User updated rules.', 'Deal Entry Config', values);
            hide();
            enqueueSnackbar('Refreshing dropdown list options...', { variant: 'info' });
        });
    }

    function handleDeleteRule() {
        if (!selectedRule) return;
        setValues(prev => {
            const newValues = [...prev];
            const lookup = newValues.find(v => v.lookupValuesID === selectedRule.id && v.value === selectedRule.parentValue);
            if (!lookup) return prev;
            lookup.childrenLookupValues = lookup.childrenLookupValues.filter(v => v.lookupField !== selectedRule.childField);
            return newValues;
        });
        setSelectedRule(null);
    }

    const ruleKey = (rule) => `${rule?.id}-${rule?.childField}-${rule?.parentValue}`;

    const handleToggleSelected = (rule) => () => {
        if (ruleKey(rule) === ruleKey(selectedRule)) {
            setSelectedRule(null);
        } else {
            setSelectedRule(rule);
        }
    }

    return (
        <Box ref={ref} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
            <CreateEditLookupRuleDialog
                open={openCreateEditRule}
                onCancel={() => setOpenCreateEditRule(false)}
                onSave={handleRulesUpdate}
                rule={selectedRule}
            />
            <Stack direction='row' spacing={2} sx={{ pb: 1 }}>
                <Typography variant='title'>Click a rule to edit it. Use the buttons to save and add rules.</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteRule}
                    disabled={!selectedRule}
                >Delete</Button>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<AddIcon />}
                    onMouseDown={handleCreateRule}
                >Add</Button>
                <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    startIcon={<SaveIcon />}
                    onMouseDown={handleSaveRules}
                >Save</Button>
            </Stack>
            <List sx={{ flexGrow: 1, }} component={Paper} elevation={5}>
                {rules.map(rule => (
                    <ListItem key={ruleKey(rule)} disablePadding>
                        <ListItemIcon>
                            <Checkbox
                                checked={ruleKey(rule) === ruleKey(selectedRule)}
                                disableRipple
                                onClick={handleToggleSelected(rule)}
                            />
                        </ListItemIcon>
                        <ListItemButton onClick={() => handleEditRule(rule)} dense>
                            <ListItemText
                                primary={rule.text}>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    )
});