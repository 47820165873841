import { Card, CardContent, CardActions, CardMedia, Button, Typography, CardActionArea,  } from "@mui/material"
import { useActionAudit } from "../useActionAudit"
import { useMemo } from "react";

export default (props) => {
  const { data, handleSelection, selected, handleViewDetails } = props;
  const { logAction } = useActionAudit();

  const cardClass = useMemo(() => {
    let classes = 'card';
    if(selected) {
      classes += ' selected-card';
    }
    return classes;
  }, [selected])

  const img = {
    apiKey: 'AIzaSyCL7pD9-yZH8UpCnXDSvJ3uC39YZCzeoR4',
    por: {
      color: 'red',
      label: data.PointOfReceipt,
      lat: data.porLatitude,
      lng: data.porLongitude,
    },
    pod: {
      color: 'green',
      label: data.PointOfDelivery,
      lat: data.podLatitude,
      lng: data.podLongitude,
    }
  }

  const imgUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=terrain&scale=2&key=${img.apiKey}`
    + `&markers=color:${img.por.color}%7Clabel:${img.por.label[0]}%7C${img.por.lat},${img.por.lng}`
    + `&markers=color:${img.pod.color}%7Clabel:${img.pod.label[0]}%7C${img.pod.lat},${img.pod.lng}`
    + `&path=color:0x0000ff|weight:5|${img.por.lat},${img.por.lng}|${img.pod.lat},${img.pod.lng}`

  return (
    <Card className={cardClass}>
      <CardActionArea onClick={() => handleSelection(data.ID)}>
        <CardMedia
          className="card-media"
          image={imgUrl}
          title={`${data.PointOfReceipt} - ${data.PointOfDelivery}`}
        />
        <CardContent className="card-content">
          <Typography gutterBottom variant="h5" component="h2">
            {`${data.Provider}  :  ${data.PointOfReceipt} - ${data.PointOfDelivery}`}
          </Typography>
          <Typography>
            {`${data.Capacity}mw  |  $${data.OfferPrice}  ${data.PriceUnit}  |  Seller Code: MASKED `}
          </Typography>
          <Typography>
            {`${data.ServiceIncrement}  ${data.TSClass}  ${data.TSType}  ${data.TSWindow}`}
          </Typography>
          <Typography>
            {`${data.StartTime} - ${data.StopTime}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button 
          onClick={() => handleViewDetails(data.ID)} 
          size="small" 
          color="primary"
        >
          View Details
        </Button>
        <Button 
          //onClick={() => logAction('Clicked Demo FastPath, redirected to pipedrive form', 'aboutPowerStation')} 
          size="small" 
          color="primary"
        >
          Request Info
        </Button>
        <Button 
          //onClick={() => logAction('Clicked Demo FastPath, redirected to pipedrive form', 'aboutPowerStation')} 
          size="small" 
          color="primary"
        >
          View Profile
        </Button>
      </CardActions>
    </Card>
  )
}