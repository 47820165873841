import { useEffect, useState, createContext, useContext, useCallback, } from "react";
import { apiUrlPrefix } from "../authConfig";
import useHeader from "./useHeader";
import axios from "axios";
import { useSnackbar } from "notistack";

export const UserInfoContext = createContext();

export function UserInfoProvider({ children }) {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        fetchUserInfo();
    }, [headers]);

    const fetchUserInfo = useCallback(async () => {
        if (headers.userGuid) {
            const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserInfoFetch&parm=${headers.userGuid}`;
            return axios.get(url, { headers, }).then((response) => {
                setUserInfo(response.data[0]);
            }).catch(error => {
                let message = error.message;
                const errorData = error?.response?.data;
                if (typeof errorData === 'string' && errorData.includes('Crystal Ball API')) {
                    const split = error.response.data.split('  ');
                    message = message + ". " + split[0];
                }

                enqueueSnackbar(message, { variant: 'error' });
            });
        }
    }, [headers]);

    return (
        <UserInfoContext.Provider value={userInfo}>
            {children}
        </UserInfoContext.Provider>
    )
}

export const useUserInfo = () => useContext(UserInfoContext);