import { useState, useEffect } from "react";
import NotAuthorized from "../NotAuthorized";
import styled from '@mui/system/styled';
import { useUserGroups } from '../../data/useUserGroups';

export default function ComponentGuard(props) {
    const [isAuthorized, setIsAuthorized] = useState(true);
    const { userIsInGroup } = useUserGroups();

    useEffect(() => {
        const authorized = userIsInGroup(...props.groups);
        setIsAuthorized(authorized);
    }, [userIsInGroup])

    if (isAuthorized) {
        return <>{props.children}</>;
    } else {
        return (
            <>
                <StyledDiv>
                    {props.children}
                </StyledDiv>
                <NotAuthorized />
            </>
        );
    }
}

const StyledDiv = styled('div')(({ theme }) => ({
    pointerEvents: 'none',
    opacity: 0.4,
}));