import { useState, useRef, useMemo } from 'react';
import axios from 'axios';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, userGroups } from '../../authConfig';
import DivGuard from '../Guards/DivGuard.js';
import { useGridButtons } from '../useGridButtons'
import { AgGridReact, } from 'ag-grid-react';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import useGridLayout from '../useGridLayout';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import { useSnackbar } from "notistack";
import useHeader from '../useHeader';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddNewRequirementDialog from './AddNewRequirementDialog';
import ImportCsvButton from '../ImportCsvButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import dayjs from 'dayjs';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { DateCellEditor } from '../AgGrid/DateCellEditor';
import { useUserGroups } from '../../data/useUserGroups';

export default () => {
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_RequirementsFetch&parm=${guid}`
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_RequirementsSave&parm=${guid}`;
  const storageLocation = 'requirements-grid-saved-layout'
  const gridRef = useRef();
  const { RefreshButton, UndoButton, RedoButton, SaveButton, CopyRowsButton, AutoSizeButton } = useGridButtons({ gridRef });
  const [rowData, setRowData] = useState([]);
  const [openAddNewDialog, setOpenAddNewDialog] = useState(false);
  const { userIsInGroup } = useUserGroups();
  const isAdmin = userIsInGroup(userGroups.admins);

  async function handleSaveButtonClick() {
    const message = 'Requirements saved to database.';
    gridRef.current.api.stopEditing();
    saveData(saveUri, message);
  }

  const onRefreshBtn = () => {
    fetch();
    enqueueSnackbar('Requirements refreshed.');
  };

  async function fetch() {
    gridRef.current.api.showLoadingOverlay();

    const options = {
      method: 'GET',
      headers: headers,
      url: loadUri,
    }

    axios(options).then(response => {
      setRowData([]); //hacky way to always get onRowDataUpdated to fire even for duplicate row data, which we need to keep the cellRenderers updated
      const formattedData = response.data.map(requirement => {
        return {
          ...requirement,
          expectedReleaseDate: requirement.expectedReleaseDate ? dayjs(requirement.expectedReleaseDate).format('MM/DD/YYYY') : '',
          dateEntered: dayjs(requirement.dateEntered).format('MM/DD/YYYY'),
        }
      });
      setRowData(formattedData);
      gridRef.current && gridRef.current.api.hideOverlay();
    }).catch(error => {
      enqueueSnackbar(`Error fetching requirements. ${error.message}`)
      gridRef?.current && gridRef.current.api.hideOverlay();
    });
  };

  async function saveData() {
    axios.post(
      saveUri,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).then(response => {
      enqueueSnackbar('Grid data successfully saved.');
    }).catch(err => {
      // enqueueSnackbar(JSON.stringify(err.response.data)); 
      enqueueSnackbar(`Error saving data. Status: ${err.response?.status}. ${err.response.data}  Message: ${err}`);
    });
  };

  const CheckboxCellRenderer = props => {
    const val = typeof props.value === 'string' ? props.value.toLowerCase() === 'true' : props.value;
    return (
      <input
        type='checkbox'
        checked={val}
        onChange={(e) => {
          const colId = props.column.colId;
          props.node.setDataValue(colId, e.target.checked);
        }}
      />
    )
  }

  function onRowDataUpdated() {
    gridRef.current.api.refreshCells({ force: true, columns: ['approvedForDevelopment'] });
  }

  const baseColDefs = useMemo(() => [
    {
      editable: false,
      headerName: "ID",
      field: "requirementID",
    },
    {
      headerName: "Subject",
      field: "subject",
    },
    {
      headerName: "Description",
      field: "description",
    },
    {
      headerName: "Status",
      field: "status",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: ['Offering Identified', 'Analysis', 'Design', 'Development', 'Testing', 'UAT', 'Deployed'],
      },
      cellEditorPopup: true,
    },
    {
      headerName: 'Priority',
      field: "priority",
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: ['High', 'Medium', 'Low'],
      },
      cellEditorPopup: true,
    },
    {
      headerName: "Analysis",
      field: "analysis",
    },
    {
      editable: false,
      cellRenderer: 'checkboxCellRenderer',
      headerName: 'Approved for Development',
      field: "approvedForDevelopment",
    },
    {
      cellEditor: "datePicker",
      cellEditorPopup: true,
      headerName: 'Expected Release Date',
      field: "expectedReleaseDate",
    },
    {
      editable: false,
      headerName: 'Parent ID',
      field: "parentRequirementID",
    },
    {
      editable: false,
      headerName: 'Tenant ID',
      field: "tenantID",
    },
    {
      editable: false,
      headerName: 'Submitted By',
      field: "submittedBy",
    },
    {
      editable: false,
      headerName: "Date Entered",
      field: "dateEntered",
    },
    {
      headerName: "Design Notes",
      field: "designNotes",
    },
  ], []);

  const { colDefs, layoutPanel, loadLayout, } = useGridLayout(storageLocation, gridRef, baseColDefs);

  function handleAddNewRequirement(subject, status, description, priority, release, approved, tenant, analysis, designNotes, parentId) {
    const newRow = {
      subject: subject,
      status: status,
      description: description,
      priority: priority,
      expectedReleaseDate: dayjs(release).format('MM/DD/YYYY'),
      approvedForDevelopment: approved,
      submittedBy: guid,
      tenantID: tenant,
      analysis: analysis,
      designNotes: designNotes,
      dateEntered: dayjs().format('MM/DD/YYYY'),
      parentRequirementID: parentId,
    }
    setOpenAddNewDialog(false);
    saveRow(newRow);
  }

  function saveRow(row) {
    axios.post(
      saveUri,
      JSON.stringify([{ ...row }]),
      {
        headers: headers,
      }
    ).then(response => {
      enqueueSnackbar('Requirement successfully saved.');
      onRefreshBtn();
    }).catch(err => { enqueueSnackbar(`Error saving data. Status: ${err.response?.status}. Message: ${err}`); });
  }

  function onExport() {
    gridRef.current.api.exportDataAsCsv();
  };

  const defaultColDef = useMemo(() => ({
    editable: isAdmin,
    resizable: true,
    sortable: true,
    filter: true,
  }), [])

  const sideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ],
    position: 'right',
  };

  function onGridReady(params) {
    fetch();
    loadLayout();
  }

  return (
    <DivGuard groups={[userGroups.requirements]} >
      <AgGridContainer
        style={{
          height: "83vh",
          width: "98%",
          // zoom: "80%",
        }}
      >
        <AddNewRequirementDialog open={openAddNewDialog} handleClose={() => setOpenAddNewDialog(false)} handleSave={handleAddNewRequirement} />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title='Add a new requirement using a dialog.' arrow placement='top'>
            <Button
              endIcon={<AddCircleOutlineOutlinedIcon />}
              id="addNewBtn"
              className="add-new-btn"
              size='small'
              variant='contained'
              color='primary'
              onClick={() => setOpenAddNewDialog(true)}
            >Add New</Button>
          </Tooltip>&nbsp;
          <Tooltip title="Refresh the list of requirements." arrow placement="top">
            <RefreshButton onClick={onRefreshBtn} />
          </Tooltip>&nbsp;
          <Tooltip title="Undo the last edit made." arrow placement="top">
            <UndoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Redo the last edit made." arrow placement="top">
            <RedoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
            <CopyRowsButton />
          </Tooltip>&nbsp;
          <Tooltip title="Autosize All Columns." arrow placement="top">
            <AutoSizeButton />
          </Tooltip>&nbsp;
          <SaveButton onClick={handleSaveButtonClick} />&nbsp;
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <Button
              onClick={onExport}
              endIcon={<FileUploadIcon />}
              variant="contained"
              color="primary"
            >Export CSV</Button>
          </Tooltip>&nbsp;
          <Tooltip title='Import grid data from a .csv file.' arrow placement='top'>
            <ImportCsvButton
              variant="contained"
              color="primary"
              endIcon={<FileDownloadIcon />}
              setRowData={setRowData}
              gridRef={gridRef}
            >
              Import CSV
            </ImportCsvButton>
          </Tooltip>&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          onRowDataUpdated={onRowDataUpdated}
          suppressRowClickSelection
          enableRangeSelection={true}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          //gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          enterMovesDownAfterEdit={true}
          sideBar={sideBar}
          animateRows={true}
          components={{
            layoutToolPanel: LayoutToolPanel,
            checkboxCellRenderer: CheckboxCellRenderer,
            datePicker: DateCellEditor,
          }}
        />
      </AgGridContainer>
    </DivGuard>
  );
};