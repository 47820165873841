import { useState, useEffect, useMemo, memo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, Button, Tooltip, Box, Typography, IconButton, DialogActions, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';
import { apiUrlPrefix, } from '../../../authConfig';
import { TransferList } from './TransferList';

const AddNewOrUpdateDialog = ({ open, handleCancel, handleAddNew, viewToUpdate, allViewNames = [] }) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [allInputOutputs, setAllInputOutputs] = useState([]);

    const maxInputs = 500;

    useEffect(() => {
        fetchAllInputOutputs();
    }, []);

    const schema = yup.object().shape({
        label: yup.string().required('Label is required.').test(
            'is-unique',
            'View label already exists. Please choose a unique label.',
            value => !allViewNames.includes(value)
        ).max(50, 'Label must be less than 50 characters.').matches(/^[a-zA-Z0-9\s]+$/, 'Label must be alphanumeric.'),
        inputs: yup.array().min(1, 'Please select at least one input.').required('Please select at least one input.').max(maxInputs, `Max number of inputs is ${maxInputs}. As a paying subscribed, you can have up to 500.`),
    });

    const defaultValues = useMemo(() => ({
        label: '',
        inputs: [],
        ...viewToUpdate,
    }), [viewToUpdate]);

    useEffect(() => {
        reset(defaultValues);
    }, [viewToUpdate]);

    const { register, handleSubmit, formState: { errors }, reset, setValue, watch, control, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const inputs = watch('inputs', []);

    const onSubmit = (data) => {
        reset();
        handleAddNew(data);
    };

    async function fetchAllInputOutputs() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Chain?chain=brain&name=fetchAllInputsOutputs`;
        return axios.get(url, { headers: headers }).then((response) => {
            setAllInputOutputs(response.data);
        }).catch((error) => {
            enqueueSnackbar(`Error fetching inputs and outputs: ${error}`, { variant: 'error' });
        });
    }

    function handleListTransfer(listKey, items) {
        setValue(listKey, items);
    }

    function onError(errors) {
        const firstError = Object.values(errors)[0];
        enqueueSnackbar(`Error: ${firstError.message}`, { variant: 'error' });
    }

    return (
        <Dialog open={open} fullWidth maxWidth='xl'>
            <DialogTitle>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Select Inputs to Chart</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={handleCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit, onError)} id='create-new-correlation-view-form'>
                    <Grid container sx={{ display: 'flex', alignItems: 'center', }}>
                        <Grid item xs={4} sx={{ p: 1, }}>
                            <Tooltip title="Label for the view." arrow placement="top">
                                <TextField
                                    {...register('label')}
                                    color="primary"
                                    label="Label"
                                    size='small'
                                    error={!!errors.label}
                                    helperText={errors.label?.message}
                                    fullWidth
                                />
                            </Tooltip>
                        </Grid>
                        <TransferList
                            items={allInputOutputs}
                            handleTransfer={handleListTransfer}
                            inputs={inputs}
                            maxInputs={maxInputs}
                        />
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">Cancel</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button type="submit" form='create-new-correlation-view-form' variant="contained" color="primary">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default memo(AddNewOrUpdateDialog);