import { Ribbon } from "../../Ribbon";
import { DealActionsGroup } from "./DealActionsGroup";
import { ViewForm } from "./ViewForm";
import { GridActionsGroup } from "./GridActionsGroup";

export const DealPositionRibbon = ({ toolbarFormId, handleFetchData, gridRef, selectedRow, updateColumnToggles, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Deals',
                    content: <DealActionsGroup
                        formId={toolbarFormId}
                        selectedRow={selectedRow}
                        gridRef={gridRef}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        updateColumnToggles={updateColumnToggles}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleFetchData}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}
