import { useState, forwardRef, useRef, useCallback, useMemo } from "react"
import { Box, } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import dayjs from "dayjs"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { heatmapStyle, tooltipRenderer, redPastMarkerFormatter, } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { ProfileDetailRenderer } from "./ProfileDetailRenderer";
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { useApi } from "../../useApi";
import { useDashboardLayout } from "../useDashboardLayout";
import { CurtailmentCellRenderer } from "./CurtailmentCellRenderer"
import { useFormContext } from "react-hook-form"
import { useRowDebounce } from "../useRowDebounce"
import useHubObject from "../../HubContext/useHubObjectNoDebounce"
import { useUserInfo } from "../../UserInfoContext"

export const TagReportGrid = forwardRef(({ selectedRows, setSelectedRows, setSelectedAdjustment, layoutStorageKey, getRowId, silentUpdate }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(ref);
    const { post, headers, apiUrlPrefix, enqueueSnackbar, } = useApi();
    const { watch, getValues } = useFormContext();
    const layout = watch('layout');
    const timezone = watch('timezone');
    const userInfo = useUserInfo();
    const detailDataMap = useRef(new Map());
    const editedDetailMap = useRef(new Map());

    const silentRefresh = useCallback((hubObj) => {
        enqueueSnackbar('Tag changes detected. Refreshing...', { variant: 'info' });
        return silentUpdate({
            ...getValues(),
            tagIdx: hubObj.tagIdx,
        });
    }, []);

    useHubObject({
        action: silentRefresh,
        allowedMessages: ['tagUpdate'],
        predicate: (obj) => {
            return (obj.tenantID?.toString() === userInfo?.tenantId?.toString())
            //TODO: check obj dates against the grid's date range
        },
    });

    const defaultHourColDef = useCallback((i) => ({
        headerName: `${i}`,
        colId: i,
        valueSetter: params => {
            const data = params.data?.[i];
            data.Current = params.newValue;
            data.status = 'edited';
            return true;
        },
        valueGetter: params => {
            if (params.data?.[i]?.flag === 'red') {
                return params.data?.[i]?.Market;
            } else {
                return params.data?.[i]?.Current;
            }
        },
        // aggFunc: 'sum',
        // enableValue: true,
        editable: params => {
            const isCurrent = params.data.ProfileType?.toLowerCase() === 'current';
            const cellData = params.data?.[i];
            const submitted = cellData?.status === 'submitted';

            return isCurrent && !submitted;
        },
        enableRowGroup: false,
        aggFunc: 'sum',
        minWidth: 25,
        chartDataType: 'series',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        cellDataType: 'number',
        cellStyle: params => {
            const wasEdited = params.data?.[i]?.status === 'edited';
            const borderStyles = wasEdited ? { border: '1px dashed #727272' } : {};
            return {
                paddingLeft: '0px',
                ...borderStyles,
                ...heatmapStyle(params, paletteRef),
            }
        },
        cellRenderer: 'curtailmentCellRenderer',
        cellRendererParams: {
            hour: i,
        },
    }), []);

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Tag Code',
            field: 'tagCode',
            checkboxSelection: true,
            cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Tag Idx',
            field: 'tagIdx',
            // checkboxSelection: true,
            cellDataType: 'text',  //why is this this way? -- The word Total in the last row was not showing correctly
            filter: 'agNumberColumnFilter',
            // cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Tag Status',
            field: 'tagStatus',
        },
        {
            field: 'Scheduled',
            initialWidth: 100
        },
        {
            headerName: 'Flow Date',
            field: 'tagDate',
            type: 'dateColumn',
            dateFormat: 'MM/DD/YYYY',
            filter: 'agDateColumnFilter',
        },
        { field: 'firstPOR', headerName: 'First POR', },
        { field: 'lastPOD', headerName: 'Last POD', },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        // {
        //     headerName: 'Full Tag Profile',
        //     cellRenderer: 'agSparklineCellRenderer',
        //     sortable: false,
        //     initialHide: true,
        //     initialWidth: 300,
        //     valueGetter: (params) => {
        //         const json = params.data?.fullTagProfile;
        //         if (json) {
        //             const profile = JSON.parse(json);
        //             return profile.reduce((acc, next) => {
        //                 acc.push(
        //                     { x: dayjs(next.StartTime).startOf('hour').toDate(), y: next.MW ?? 0 },
        //                     { x: dayjs(next.StopTime).startOf('hour').toDate(), y: next.MW ?? 0 },
        //                 );
        //                 return acc;
        //             }, []);
        //         } else {
        //             return [];
        //         }
        //     },
        //     cellRendererParams: {
        //         sparklineOptions: {
        //             type: 'area',
        //             axis: {
        //                 type: 'time',
        //             },
        //             tooltip: {
        //                 renderer: tooltipRenderer
        //             },
        //             marker: {
        //                 formatter: redPastMarkerFormatter,
        //             },
        //         },
        //     },
        // },
        {
            headerName: 'Curtailment Profile',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialHide: true,
            initialWidth: 300,
            valueGetter: (params) => {
                const json = params.data?.curtailmentProfile;
                if (json) {
                    const profile = JSON.parse(json);
                    return profile.reduce((acc, next) => {
                        acc.push(
                            { x: dayjs(next.startDateTimeUtc).startOf('hour').toDate(), y: next.MWLevel ?? 0 },
                            { x: dayjs(next.endDateTimeUtc).startOf('hour').toDate(), y: next.MWLevel ?? 0 },
                        );
                        return acc;
                    }, []);
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        {
            headerName: 'Tag Profile',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialWidth: 300,
            valueGetter: (params) => {
                const { data, } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;
                    const val = data[hour]?.Current ?? 0;

                    acc.push(
                        { x: dayjs(data.tagDate).startOf('day').add(hour - 1, 'hours').toDate(), y: val, },
                        { x: dayjs(data.tagDate).startOf('day').add(hour, 'hours').toDate(), y: val, },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(defaultHourColDef(i));

            if (i === 2) {
                acc.push(defaultHourColDef('2*'));
            }

            return acc;
        }, []),
        {
            headerName: 'OASIS #',
            field: 'OASIS Number',
        },
        {
            headerName: 'Deal Type',
            field: 'dealType',
        },
        {
            headerName: 'Counterparty',
            field: 'counterParty',
        },
        {
            headerName: 'Composite State',
            field: 'compositeState',
        },
        {
            headerName: 'PSE Contact',
            field: 'PSE Contact',
        },
        {
            headerName: 'Profile Type',
            field: 'ProfileType',
        },
        {
            headerName: 'CC List',
            field: 'CC List',
            valueFormatter: params => {
                //insert spaces after each comma for readability
                return params.value?.split(',').join(', ');
            },
        },
        {
            field: 'LCA',
        },
        {
            field: 'GCA',
        },
        {
            field: 'CPSE',
        },
        {
            field: 'Buy/Sell',
        },
        {
            field: 'Start Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'Stop Time',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        {
            field: 'LastUpdated',
            headerName: 'Last Updated',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            aggFunc: 'max',
            enableValue: true,
            enableRowGroup: false,
            valueFormatter: params => {
                //the time is in UTC, so we need to convert it to the local time zone. Shift the time by the offset.
                return params.value ? dayjs(params.value).format('MM/DD/YYYY HH:mm') : '';
            },
        },
        {
            headerName: 'Schedule ID',
            field: 'scheduleId',
        },
        {
            headerName: 'Deal Id',
            field: 'dealId',
        },
        {
            field: 'Notes',
        },
        {
            field: 'Market Path',
        },
        {
            field: 'Total',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        //enableCellChangeFlash: true,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: { layout, },
        baseColDefs,
        defaultColDef,
    });

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    function onGridReady(params) {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, [applyFilters]);

    const updateSelectedRowData = useCallback(_ => {
        const selectedRows = ref.current?.api?.getSelectedRows();

        const selected = selectedRows.map(row => {
            const rowId = getRowId({ data: row });
            const hasDetailEdits = !!editedDetailMap.current.get(rowId);
            return {
                ...row,
                hasEdits: row.hasEdits || hasDetailEdits,
            };
        });

        setSelectedRows(selected);
    }, [ref, setSelectedRows, getRowId]);

    const handleRowSelected = useCallback(() => {
        updateSelectedRowData();
    }, [updateSelectedRowData]);

    const handleUpdateTotalRows = useCallback((api) => {
        const visibleRows = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            visibleRows.push(node.data);
        });
        const totalRows = generateTotalRowData(visibleRows);
        //api.setGridOption('pinnedBottomRowData', totalRows);
        api.setPinnedBottomRowData(totalRows);
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api);
    }, [handleUpdateTotalRows]);

    const generateTotalRowData = (data) => {
        if (!data?.length) return [];
        const summedData = data.reduce((acc, next) => {
            if (typeof next !== 'object') return acc;
            Object.keys(next).forEach(key => {
                const isNumberCol = !isNaN(parseInt(key));
                if (isNumberCol) {
                    const val = next[key]?.Current ?? 0;
                    const sum = parseFloat(acc[key] ?? 0) + parseFloat(val);
                    acc[key] = parseFloat(sum.toFixed(2));
                }
            });
            return acc;
        }, { tagIdx: 'Total' });

        Object.keys(summedData).forEach(key => {
            if (!isNaN(parseInt(key))) {
                summedData[key] = { Current: summedData[key] };
            }
        });
        return [summedData];
    };

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'tag-report-palette'),
        ]
    }), []);

    const detailCellRenderer = useMemo(() => {
        return (props) => ProfileDetailRenderer(props);
    }, []);

    const setDetailEdited = useCallback((nodeId, edited) => {
        editedDetailMap.current.set(nodeId, edited);
        updateSelectedRowData();
    }, [updateSelectedRowData]);

    const detailRendererParams = useMemo(() => ({
        setSelectedAdjustment,
        getDetailData: (nodeId) => detailDataMap.current.get(nodeId),
        setDetailData: (nodeId, data) => detailDataMap.current.set(nodeId, data),
        setDetailEdited,
        getRowId,
    }), [setSelectedAdjustment, getRowId, setDetailEdited]);

    const handleRowGroupOpened = useCallback((params) => {
        if (params.node.expanded && !params.node.selected) {
            params.node.setSelected(true);
        }
    }, []);

    const handleTagUpdate = useCallback((data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_ApplyEnergyEdits`
            + `&parm=${headers.userGuid}`
            + `&parm=${data.tagIdx}`
            + `&parm=${timezone}`

        post(url, data).then(_ => {
            enqueueSnackbar('Draft modification saved.', { variant: 'info' });
        });
    }, [headers, post, apiUrlPrefix, timezone, enqueueSnackbar]);

    const { batchRowUpdate } = useRowDebounce({ onTimeExpired: handleTagUpdate });

    function handleCellValueChanged(params) {
        batchRowUpdate(params);
    }

    function handleRowDataUpdated(params) {
        updateTotalRows(params);

        //make sure the selected rows have the correct data
        const updatedSelected = selectedRows.map(row => {
            const rowId = getRowId({ data: row });
            const updatedNode = ref.current.api.getRowNode(rowId);
            const selectedRowData = updatedNode?.data ?? row;

            const rowHasDetailEdits = !!editedDetailMap.current.get(rowId);
            selectedRowData.hasEdits = selectedRowData.hasEdits || rowHasDetailEdits;

            return selectedRowData;
        });
        setSelectedRows(updatedSelected);
    }

    function handleFilterChanged(params) {
        updateTotalRows(params);

        //clear selections of rows that are no longer displayed
        const api = params.api;
        const selectedNodes = api.getSelectedNodes();
        const displayedNodeIds = new Set();

        api.forEachNodeAfterFilter(node => {
            displayedNodeIds.add(node.id);
        });

        selectedNodes.forEach(node => {
            if (!displayedNodeIds.has(node.id)) {
                node.setSelected(false);
            }
        });
    }

    return (
        <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
            <Box onMouseLeave={clearHighlighting} sx={{ width: '100%', height: '100%' }}>
                <AgGridReact
                    {...defaultGridOptions}
                    containerStyle={{ width: '100%', height: '100%' }}
                    getRowId={getRowId}
                    ref={ref}
                    columnDefs={styledColDefs}
                    gridOptions={gridOptions}
                    onRowSelected={handleRowSelected}
                    onFilterChanged={handleFilterChanged}
                    onRowDataUpdated={handleRowDataUpdated}
                    onCellValueChanged={handleCellValueChanged}
                    onRowGroupOpened={handleRowGroupOpened}
                    rowSelection="single"
                    rowMultiSelectWithClick
                    enableCharts
                    onFirstDataRendered={handleFirstDataRendered}
                    suppressAggFuncInHeader={true}
                    groupTotalRow={"bottom"}
                    groupSuppressBlankHeader={false}
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    masterDetail
                    keepDetailRows
                    detailRowAutoHeight
                    detailCellRenderer={'detailCellRenderer'}
                    detailCellRendererParams={detailRendererParams}
                    onCellMouseOver={handleCellMouseOverHighlight}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        paletteToolPanel: PaletteToolPanel,
                        detailCellRenderer: detailCellRenderer,
                        curtailmentCellRenderer: CurtailmentCellRenderer,
                    }}
                />
            </Box>
        </AgGridContainer>
    )
});
