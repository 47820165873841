import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  } from '@mui/material';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import {useSnackbar} from 'notistack';
import axios from "axios";
import { apiUrlPrefix, userGroups } from '../authConfig';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useEffect} from 'react';
import useHeader from './useHeader';
import { useState } from 'react';

export default ({ open, handleClose }) => {
  const headers = useHeader();
  
  const [message, setMessage] = useState('');
  const [user, setUser] = useState('');
  const [userChoices, setUserChoices] = useState([]);
  
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect (() => {
    fetchUserSelectChoices();
  }, []);

  const saveMessageUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserMessageInsert&parm=${guid}&parm=${user}`;

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  async function fetchUserSelectChoices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch&parm=${guid}`,
    }
  
    axios(options).then(response => { 
      //const names = response.data.map(user => user.userName).filter(name => name); //filter out null or undefined values
      const names = [...new Set(response.data.map(user => user.userName).filter(name => name))]; //filter out falsey and duplicate values
      setUserChoices(names);
    }).catch(error => {
      enqueueSnackbar(`Error fetching user options. Message: ${error} URL: ${`${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch`}`)
    });

  };

  const handleMessageSaveAndClose = () => {
    let snackBarMessage = 'User message saved to the database.';
    let newObject = { message: message }

    const options = {
      method: 'POST',
      headers: headers,
      data: newObject,
      url: saveMessageUri
    };

    axios(options).then(response => {
      enqueueSnackbar(snackBarMessage);
      handleClose();
    }).catch(error => { enqueueSnackbar(`${error} at URL ${saveMessageUri}`) } );
  }

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>User Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>Please enter a message and we will deliver it promptly.</p>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          onChange={updateMessage}
          variant="outlined"
          id="messageText"
          label="Message Text"
          multiline
          rows={6}
          fullWidth
        />
        <DialogContentText>
          <p>Please enter user to send it to.</p>
        </DialogContentText>
        <Autocomplete
          freeSolo
          fullWidth
          options={userChoices}
          value={user}
          size="small"
          onChange={(_, newValue) => {
            setUser(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="User"
              color="success" 
              placeholder="User"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={handleMessageSaveAndClose}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
