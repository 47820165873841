import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import Button from '@mui/material/Button';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from 'ag-grid-react';
import { useData } from '../useData';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import useHeader from '../useHeader';
import useGridLayout from '../useGridLayout';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const guid = useLocalGuid();
  const dataUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserGroupsFetch&parm=${guid}`;
  const { enqueueSnackbar } = useSnackbar();
  const { rowData, getData } = useData();
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const storageLocation = 'real-time-UserGroups-layout'
  const headers = useHeader();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  useEffect(() => {
    //getData();
    let abortController = new AbortController();
    // your async action is here  
    getData(dataUri);
    return () => {
      abortController.abort();
    }
  }, []);

  const onBtnExport = () => {
    ref.current.api.exportDataAsCsv();
  };

  const onRemoveUserGroupSelected = () => {
    const selectedData = ref.current.api.getSelectedRows();
    const res = ref.current.api.applyTransaction({ remove: selectedData });
  };

  const onRefreshBtn = () => {
    getData(dataUri);
    let message = 'User groups refreshing...';
    enqueueSnackbar(message);
  };

  async function onSaveDataButtonClick() {
    ref.current.api.stopEditing();
    setButtonsDisabled(true);
    ref.current.api.showLoadingOverlay();
    let message = 'Submitting User Groups...';
    enqueueSnackbar(message);

    const data = [];
    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      data.push(newData);
    })

    axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserGroupsSave&parm=${guid}`,
      JSON.stringify(data),
      {
        headers: headers,
      }
    ).then(response => {
      message = 'User Group sent to power station database...';
      enqueueSnackbar(message);
      getData(dataUri);
    }).catch(err => enqueueSnackbar(`Status: ${err.response?.status}. Message: ${err}`));

    ref.current.api.hideOverlay();
    setButtonsDisabled(false);
  }

  const gridOptions = useMemo(() => {
    return {
      // [...]
      rowClassRules: {
        "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
        "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
        "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
        "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
        "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
        "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
      },
    }
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  function onRowDataUpdated(params) {
    const rowNum = params.api.rowModel.rowsToDisplay.length;
    setButtonsDisabled(rowNum === 0);
  }

  const baseColDefs = useMemo(() => [
    {
      checkboxSelection: true,
      editable: false,
      headerName: "ID",
      headerCheckboxSelection: true,
      rowDrag: true,
      initialWidth: 150,
      field: "userGroupsID",
    },
    {
      editable: false,
      headerName: "Guid",
      initialWidth: 100,
      field: "userGuid",
    },
    {
      editable: false,
      headerName: "Group ID",
      initialHide: "true",
      initialWidth: 100,
      field: "powerStationGroupID",
    },
    {
      editable: false,
      headerName: "Group Name",
      initialWidth: 100,
      field: "groupName",
    },
    {
      editable: false,
      headerName: "First",
      initialWidth: 100,
      field: "firstName",
    },
    {
      editable: false,
      headerName: "Last",
      initialWidth: 100,
      field: "lastName",
    },
    {
      editable: false,
      headerName: "User Name",
      initialWidth: 100,
      field: "userName",
    },
  ], []);

  const { loadLayout, colDefs, layoutPanel } = useGridLayout(storageLocation, ref, baseColDefs)

  const UserGroupSideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        layoutPanel,
      ],
      position: 'left',
    }
  }, []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: "agMultiColumnFilter",
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <AgGridContainer
      style={{
        height: "86vh",
        width: "98%",
        padding: "5px",
      }}
    >
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Refresh the grid of user groups." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineIcon />}
            id="csv"
            //disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Delete the currently selected row." arrow placement="top">
          <Button
            onClick={() => onRemoveUserGroupSelected()}
            endIcon={<DeleteForeverIcon />}
            id="delete"
            //disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Remove</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Save all User Group combinations." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            //disabled={buttonsDisabled}
            size={buttonSize}
            onClick={onSaveDataButtonClick}>Save All</Button>
        </Tooltip>&nbsp;
      </Box>
      <AgGridReact
        ref={ref}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        rowData={rowData}
        enterMovesDownAfterEdit={true}
        statusBar={statusBar}
        overlayNoRowsTemplate={'<span>No User Groups created. Use user templates to create a new User Group.</span>'}
        rowSelection="multiple"
        rowDragMultiRow={true}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        defaultColDef={defaultColDef}
        sideBar={UserGroupSideBar}
        gridOptions={gridOptions}
        tooltipShowDelay={0}
        onRowDataUpdated={onRowDataUpdated}
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        animateRows={true}
        groupSelectsChildren={true}
        components={{
          layoutToolPanel: LayoutToolPanel,
        }}
      />
    </AgGridContainer>
  );
});