import RestoreIcon from '@mui/icons-material/Restore'; import { RibbonButton } from "../Ribbon"
import { useApi } from "../../useApi";
import { useState, useCallback } from "react";
import debounce from 'lodash/debounce';
import { useFormContext } from "react-hook-form";
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import { Tooltip } from '@mui/material';

export const RollbackDealButton = ({ deal, disabled, onRefresh, expanded, gridRef, ...buttonProps }) => {
    const { headers, apiUrlPrefix, get, logAction, enqueueSnackbar, } = useApi();
    const { handleSubmit } = useFormContext();
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({ open: false });
    const toRollback = true;

    // const confirmButtonText = deal && !!deal.confirmed ? 'Unconfirm' : 'Confirm';
    const buttonDebounce = 1500;

    function handleConfirmRollback() {
        const action = 'rollback';
        setConfirmationDialogProps({
            open: true,
            message: `You are about to ${action} the selected deal (${deal.dealID}). Continue?`,
            onConfirmation: () => handleRollbackDeal(deal),
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleRollbackDeal = useCallback(debounce(async (dealData) => {
        setConfirmationDialogProps({ open: false });
        // const confirmed = dealData.confirmed;
        logAction(`User rolled back deal with id ${dealData.dealID}`, 'Deal Summary', dealData);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_RollbackDeal_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealData.dealID}`


        return get(url).then(response => {
            enqueueSnackbar(`Deal rolled back successfully`, { variant: 'success' });
            //use transaction to update the row
            // gridRef?.current.api?.applyTransaction({ update: [{ ...dealData, confirmed: !confirmed }] });
            handleSubmit(onRefresh)();
            gridRef?.current.api?.deselectAll();
        });
    }, buttonDebounce, { leading: true, }), [headers]);

    return (
        <>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Tooltip title={'Undo the last edit to the selected deal.'}>
                <span>
                    <RibbonButton
                        onMouseDown={() => handleConfirmRollback()}
                        icon={<RestoreIcon fontSize='small' />}
                        label="Rollback"
                        expanded={expanded}
                        disabled={disabled}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}