import { useEffect, useState } from 'react';
import useHeader from '../../useHeader';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { apiUrlPrefix } from '../../../authConfig';
import { CriteriaContext } from './criteriaContext';
import { useLocalGuid } from '../../../data/UserGuidContext/useLocalGuid';
import { requestTypes, statuses } from '../../../constants';

export default ({ children }) => {
  const headers = useHeader();
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const [combinations, setCombinations] = useState([]);
  const [tsCombinations, setTsCombinations] = useState({
    tsClasses: [],
    tsIncrements: [],
    tsTypes: [],
    tsPeriods: [],
    tsWindows: [],
    tsSubclasses: [],
  });
  const [customerCodes, setCustomerCodes] = useState([]);
  const [relatedRefs, setRelatedRefs] = useState([]);

  //hardcoded criteria fields here
  //const serviceIncrements = [ 'Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly', ];

  useEffect(() => {
    fetchPorPodChoices();
    fetchTSCombinations();
    fetchCustomerCodes();
    fetchRelatedRefs();
  }, []);

  async function fetchCustomerCodes() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_CustomerCodeChoiceFetch`,
    }

    axios(options).then(response => {
      setCustomerCodes(response.data.map(entry => entry.customerCode));
    }).catch(error => {
      enqueueSnackbar(`Error fetching customer codes. Message: ${error}`)
    });
  }

  async function fetchRelatedRefs() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_RelatedRefCombosFetch&parm=${guid}`,
    }

    axios(options).then(response => {
      setRelatedRefs(response.data);
    }).catch(error => {
      enqueueSnackbar(`Error fetching related refs. Message: ${error}`)
    });
  }

  async function fetchTSCombinations() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchPpwTSCombinations`,
    }

    axios(options).then(response => {
      const combos = {
        tsClasses: [...new Set(response.data.map(combo => combo.TSClass))],
        tsIncrements: [...new Set(response.data.map(combo => combo.ServiceIncrement))],
        tsTypes: [...new Set(response.data.map(combo => combo.TSType))],
        tsPeriods: [...new Set(response.data.map(combo => combo.TSPeriod))],
        tsWindows: [...new Set(response.data.map(combo => combo.TSWindow))],
        tsSubclasses: [...new Set(response.data.map(combo => combo.TSSubclass))],
      }
      setTsCombinations(combos);
    }).catch(error => {
      enqueueSnackbar(`Error fetching TS combinations. Message: ${error}`)
    });
  }

  async function fetchPorPodChoices() {
    const options = {
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchAllPpwPorPodCombinations`,
    }

    axios(options).then(response => {
      setCombinations(response.data?.filter(combo => combo.Provider === 'PPW'));
    }).catch(error => {
      enqueueSnackbar(`Error fetching por/pod choices. Message: ${error}`)
    });
  }

  const data = {
    combinations: combinations,
    tsCombinations: tsCombinations,
    customerCodes: customerCodes,
    relatedRefs: relatedRefs,
    //serviceIncrements: serviceIncrements,
    requestTypes: requestTypes,
    statuses: statuses,
  }

  return (
    <CriteriaContext.Provider value={data}>
      {children}
    </CriteriaContext.Provider>
  )
}