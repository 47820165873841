import { useState, useEffect, } from 'react';
import Collapse from '@mui/material/Collapse';
import Menu from './Menu';
import { Box } from '@mui/material';
import CriteriaProvider from '../CriteriaContext/CriteriaProvider';
import DivGuard from '../Guards/DivGuard';
import { userGroups } from '../../authConfig';
import BrainTopology from './BrainTopology/BrainTopology';
import { ForecastDashboard } from '../DealRizz/WeatherForecast/ForecastDashboard';
import { LMPs } from '../DealRizz/LMPs/LMPs';
import { Status } from './Status';
import { CorrelationsDashboard } from './Correlations/CorrelationsDashboard';
import { Adjustments } from './Adjustments/Adjustments';
import { Outlet, useNavigate } from 'react-router';
import { useParams } from 'react-router';

const viewStorageKey = 'brain-saved-view';

export default () => {
    const savedView = localStorage.getItem(viewStorageKey);
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;
    const navigate = useNavigate();

    useEffect(() => {
        if (!view) {
            const newView = savedView ?? 'lmps';
            navigate(`/brain/${newView}`);
        }
    }, [view, savedView]);

    function handleSelection(view) {
        navigate(`/brain/${view}`);
    }

    return (
        <DivGuard authorizeIfAuthenticated>
            <CriteriaProvider>
                <Box sx={{ display: 'flex' }}>
                    <Menu selected={view} setSelected={handleSelection} />
                    <Outlet />
                </Box>
            </CriteriaProvider>
        </DivGuard >
    )
}

export const BrainViews = () => {
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const view = params.view;

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    return (
        <Box sx={{ flexGrow: 1 }}>
            {loadedViews['braintopology'] && <Collapse in={view === 'braintopology'}>
                <BrainTopology />
            </Collapse>}
            {loadedViews['forecast'] && <Collapse in={view === 'forecast'}>
                <ForecastDashboard visible={view === 'forecast'} />
            </Collapse>}
            {loadedViews['lmps'] && <Collapse in={view === 'lmps'}>
                <LMPs visible={view === 'lmps'} />
            </Collapse>}
            {loadedViews['brainstatus'] && <Collapse in={view === 'brainstatus'}>
                <Status />
            </Collapse>}
            {loadedViews['correlations'] && <Collapse in={view === 'correlations'}>
                <DivGuard groups={[userGroups.brain]}>
                    <CorrelationsDashboard visible={view === 'correlations'} />
                </DivGuard>
            </Collapse>}
            {loadedViews['adjustments'] && <Collapse in={view === 'adjustments'}>
                <DivGuard groups={[userGroups.brain]}>
                    <Adjustments />
                </DivGuard>
            </Collapse>}
        </Box>
    )
}

export const viewTitles = {
    braintopology: 'Brain Topology',
    forecast: 'Forecast',
    lmps: 'LMPs',
    brainstatus: 'Brain Status',
    correlations: 'Correlations',
    adjustments: 'Adjustments',
};