import {apiUrlPrefix, userGroups, subscriptionKey} from '../authConfig.js';
import axios from 'axios';
// import useHeader from '../components/useHeader.js';

export function logAction(action, userInterface, guid, data={}) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UseabilityAuditInsert&parm=${guid}&parm=${encodeURIComponent(action)}&parm=${encodeURIComponent(userInterface)}` 
      
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'userGuid': guid,
      },
      //data: { data: data },
      url: url,
    }

    if(data) {
      options.data = data
    }

    axios(options).then(response => {
        return response;
    }).catch(error => { 
        return error; 
    });
}