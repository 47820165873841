import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { useColorMode } from '../../styles/ColorMode/useColorMode';

export const AgGridContainer = (props) => {
    const { mode } = useColorMode();

    const theme = mode === 'light' ? 'ag-theme-balham' : 'ag-theme-balham-dark';

    return (
        <div className={`container ${theme}`} {...props} >
            {props.children}
        </div>
    );
}