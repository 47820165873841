import { useCallback, useRef } from "react";

export const useRowDebounce = ({ debounce = 20, onTimeExpired }) => {
    const pendingUpdates = useRef({});
    const timers = useRef({});

    const batchRowUpdate = useCallback((event) => {
        const rowId = event.rowIndex;
        const updatedRowData = event.data;

        pendingUpdates.current[rowId] = updatedRowData;

        //Start a timer to send the update to the backend
        if (!timers.current[rowId]) {
            timers.current[rowId] = setTimeout(() => {
                onTimeExpired(pendingUpdates.current[rowId]);

                // Clean up
                delete pendingUpdates.current[rowId];
                delete timers.current[rowId];
            }, 100);
        }
    }, [onTimeExpired]);

    return { batchRowUpdate };
}