import { useMemo, } from "react";
import { EditTsrDialog } from "./EditTsrDialog";
import { EditTsrAlertDialog } from "./EditTsrAlertDialog";
import { EditMultiLegTsrDialog } from "./EditMultiLegTsrDialog";

export const EditDialog = ({ open, closeDialog, data, application, action, scheduleDateTime, handleUpdate }) => {

    const DialogComponent = useMemo(() => {
        if (action === 'createTsrAlert') {
            return EditTsrAlertDialog;
        } else if (Array.isArray(data)) {
            return EditMultiLegTsrDialog;
        } else {
            return EditTsrDialog;
        }
    }, [data, action])

    function handleSaveAndClose(data, actionInfo) {
        handleUpdate(data, actionInfo);
        closeDialog();
    }

    return (
        <DialogComponent
            open={open}
            data={data}
            tsr={data}
            closeDialog={closeDialog}
            handleClose={closeDialog}
            application={application}
            scheduleDateTime={scheduleDateTime}
            handleUpdate={handleSaveAndClose}
        />
    );
};