import PathMap from "./PathMap";
import { useEffect, useState } from "react";

export const FastPathMap = () => {
    const [state, setState] = useState({});

    useEffect(() => {
        //set up a broadcast channel to receive the data from the fastpathpro view.
        //send a message to the fastpathpro view to let it know that the map is ready to receive the data,
        //then store the data in state when it is received.
        const bc = new BroadcastChannel('fastpathmap');
        bc.postMessage('ready');
        bc.onmessage = function (ev) {
            setState(ev.data);
        }

        return () => {
            bc.close();
        }
    }, []);

    const { data = [], pors = [], pods = [], } = state;

    return (
        <div style={{ width: '100%', height: '94vh', }}>
            <PathMap
                data={data}
                pors={pors}
                pods={pods}
                setSelectedNodes={() => { }}
                selectedNodes={[]}
            />
        </div>
    );
}