import { useState, useEffect } from "react";

/*
  requiredFields is a flat object. The keys are the fields that we want to check are defined and nonempty in obj.
  The values of requiredFields are more user friendly names and are used to create the message to the user.
  hasMissingFields returns false if there are no missing fields, and returns the formatted user message otherwise.
  isMissing tells you whether obj is missing a specific field.
*/

export default (requiredFields) => {
  const [hasMissing, setHasMissing] = useState(false);

  function hasMissingFields(obj) {
    const messageBase = `Please fill out the indicated fields to continue: `;
    const keys = Object.keys(requiredFields);
    const missing = keys.filter(key => !obj[key]);
    const message = missing.reduce((msg, field, idx) => {
      return `${msg}${requiredFields[field]}${(idx < missing.length - 1) ? ', ' : ''}`;
    }, messageBase)
    setHasMissing(missing.length);
    if(missing.length) { return message; }
    else return false;
  }

  function isMissing(obj, field) {
    return !obj[field];
  }

  return { hasMissingFields, isMissing, hasMissing };
}