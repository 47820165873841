import { useEffect, useState, useRef, useMemo } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { userGroups } from "../../authConfig";
import DivGuard from '../Guards/DivGuard.js';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useGridButtons } from '../useGridButtons'
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels'
import useGridLayout from '../useGridLayout';
import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export const TenantSettings = () => {
  const gridRef = useRef();
  const storageLocation = 'powerstation-tenant-grid-layout'

  const { RefreshButton, UndoButton, RedoButton, CopyRowsButton, NewRowButton, ExportButton, SaveButton } = useGridButtons({ gridRef });

  const onRefreshBtn = () => {
    gridRef.current.api.showLoadingOverlay();
    getData();
  };

  async function getData() {
    //const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserGroupsFetch&parm=${guid}}`;
    //gridRef.current.getData(url); 
    if (gridRef.current?.api?.rowModel.rowsToDisplay.length === 0) { gridRef.current.api.showNoRowsOverlay(); }
  }

  useEffect(() => {
    //getData();
    let abortController = new AbortController();
    // your async action is here  
    getData();
    return () => {
      abortController.abort();
    }
  }, []);

  async function handleSaveButtonClick() {
    //const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserGroupsSave&parm=${guid}}`;
    //const message = 'User Groups saved to database.';
    //gridRef.current.saveData(url, message) 
  }

  const baseColDefs = [
    {
      editable: true,
      headerName: "Tenant ID",
      initialHide: false,
      field: "tenantId",
    },
    {
      editable: true,
      headerName: "Guid",
      initialHide: false,
      field: "userGuid",
    },
    {
      editable: true,
      headerName: "User Name",
      initialHide: false,
      field: "userName",
    },
  ];

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  const { colDefs, loadLayout, layoutPanel } = useGridLayout(storageLocation, gridRef, baseColDefs);

  const sideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
    ],
    position: 'right',
  };

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <DivGuard groups={[userGroups.usergroups]} >
      <AgGridContainer
        style={{
          height: "88vh",
          width: "100%"
        }}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title="Refresh the list of user groups." arrow placement="top">
            <RefreshButton onClick={onRefreshBtn} />
          </Tooltip>&nbsp;
          <Tooltip title="Undo the last edit made." arrow placement="top">
            <UndoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Redo the last edit made." arrow placement="top">
            <RedoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
            <CopyRowsButton />
          </Tooltip>&nbsp;
          <Tooltip title="Add a new blank row to the grid." arrow placement="top">
            <NewRowButton />
          </Tooltip>&nbsp;
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <ExportButton />
          </Tooltip>&nbsp;
          <Tooltip title='Save grid to database.'>
            <SaveButton onClick={handleSaveButtonClick} />
          </Tooltip>&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          rowData={[]}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          suppressRowClickSelection
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enterMovesDownAfterEdit={true}
          enableRangeSelection={true}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          animateRows={true}
        />
      </AgGridContainer>
    </DivGuard>
  );
};