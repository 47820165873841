import React, { useMemo, useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from 'ag-grid-react';
import useSmartColDefs from './useSmartColDefs';
import useGridLayout from '../useGridLayout';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const { viewName, updateSelected, } = props;
  const gridLayoutKey = `${viewName}-availability-grid-layout`;
  const { colDefs: baseColDefs, } = useSmartColDefs(viewName);
  const { layoutPanel, colDefs, loadLayout } = useGridLayout(gridLayoutKey, ref, baseColDefs);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ref.current?.api && loaded && loadLayout();
  }, [baseColDefs])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
      ],
    };
  }, []);

  const availabilitySideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ],
    position: 'right',
  }), [layoutPanel]);

  function onSelectionChanged() {
    const selected = ref.current.api.getSelectedRows();
    updateSelected(selected);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: "agMultiColumnFilter",
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout();
    setLoaded(true);
  }

  function onRowDataUpdated(params) {
    if (!localStorage.getItem(gridLayoutKey)) {
      params.columnApi.autoSizeAllColumns();
    }
  }

  return (
    <div>
      <AgGridContainer
        style={{
          height: "40vh",
          width: "98%"
        }}
      >
        <AgGridReact
          ref={ref}
          onRowDataUpdated={onRowDataUpdated}
          onGridReady={onGridReady}
          groupAggFiltering={true}
          suppressAggFilteredOnly={true}
          suppressAggFuncInHeader={true}
          columnDefs={colDefs}
          onSelectionChanged={onSelectionChanged}
          groupSelectsChildren={true}
          groupDefaultExpanded={-1}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          statusBar={statusBar}
          undoRedoCellEditingLimit={20}
          enableCellChangeFlash={true}
          overlayLoadingTemplate={'<span style="width: 70%; font-size: 20px">Please wait.  Loading...</span>'}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          defaultColDef={defaultColDef}
          sideBar={availabilitySideBar}
          animateRows={true}
          rowDragMultiRow
          rowSelection={"multiple"}
          //enableCellTextSelection={true}
          tooltipShowDelay={0}
          components={{
            //customTooltip: CustomTooltip,
            viewToolPanel: GridViewToolPanel,
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </div>
  )
})