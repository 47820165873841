import React, { useState, useEffect, useMemo } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Collapse, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Button, Toolbar, IconButton, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ResizingPane from 'react-resizing-pane';
import GetAppIcon from '@mui/icons-material/GetApp';
import useFilteredCriteria from "../CriteriaContext/useFilteredCriteria";

export default (props) => {
  const { criteria, handleChange, handleFetch } = props;
  const [open, setOpen] = useState(true);
  const storageConfig = { name: 'ResizablePPWCriteriaPane', type: localStorage };
  const { filteredCombos, tsCombinations } = useFilteredCriteria({});
  //const { filteredCombos, providers, filteredTSCombos, } = useFilteredCriteria({});

  const renderTimezones = {
    'Pacific': 'Pacific Standard Time',
    'Pacific Standard Time': 'Pacific',
    'Eastern': 'Eastern Standard Time',
    'Eastern Standard Time': 'Eastern',
    'Central': 'Central Standard Time',
    'Central Standard Time': 'Central',
    'Mountain': 'Mountain Standard Time',
    'Mountain Standard Time': 'Mountain',
  }

  function handleWildcardUpdate(key, value) {
    const [lastAdded] = value?.slice(-1);
    if(lastAdded === '*') {
      handleChange(key, ['*'])
    } else {
      handleChange(key, value.filter(option => option !== '*'))
    }
  }

  return(
    <div>
      <Collapse in={open} collapsedSize='80px' orientation='horizontal'>
        <ResizingPane
          sides={['right']}
          storageId={'ppw-trans-criteria-resizingpane-config'}
          storageConfig={storageConfig}
          height="100%"
          width={500}
          style={{
            border: '0px solid blue',
            minWidth: 300
          }}
        >
          <Box sx={{display: 'flex', p:1}}>
            <Grid container direction={'column'}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  px: [1],
                }}
              >{open && (<><h3>Fetch Criteria</h3><Box sx={{ flexGrow: 1, }} /></>)}
                <IconButton onClick={() => setOpen(!open)} size="large">
                  {open? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Toolbar> 
              <Divider />
              {open && 
                <Grid container justifyContent="center" alignItems='center' style={{ marginTop: '20px' }} spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                          fullWidth
                          options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                          value={renderTimezones[criteria.timezone] ?? ''}
                          onChange={(_, newValue) => {
                            handleChange('timezone', renderTimezones[newValue]);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Timezone"
                              size='small'
                              color="success" 
                            />
                          )}
                        />
                    </Grid>
                    <Grid item xs={5}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          fullWidth
                          value={dayjs(criteria.startDate)}
                          onChange={(newValue) => handleChange('startDate', newValue)} 
                          slotProps={{ textField: { size: 'small', }}}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                          fullWidth
                          options={[...Array(24).keys()].map(i => i + 1)}
                          value={criteria.endHour ?? 0}
                          onChange={(_, newValue) => {
                            handleChange('endHour', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                              label="Hour Ending"
                              color="success" 
                            />
                          )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            multiple
                            options={filteredCombos.pors}
                            value={criteria.por ?? []}
                            onChange={(_, newValue) => {
                              handleChange('por', newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="POR"
                                color="success" 
                              />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            fullWidth
                            multiple
                            options={filteredCombos.pods}
                            value={criteria.pod ?? []}
                            onChange={(_, newValue) => {
                              handleChange('pod', newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="POD"
                                color="success" 
                              />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={['*', ...tsCombinations.tsClasses]}
                        value={criteria.tsClass}
                        onChange={(_, newValue) => handleWildcardUpdate('tsClass', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Class"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={['*', ...tsCombinations.tsIncrements]}
                        value={criteria.tsIncrement}
                        onChange={(_, newValue) => handleWildcardUpdate('tsIncrement', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Increment"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        value={criteria.tsType}
                        options={['*', ...tsCombinations.tsTypes]}
                        onChange={(_, newValue) => handleWildcardUpdate('tsType', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Type"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={['*', ...tsCombinations.tsPeriods]}
                        value={criteria.tsPeriod}
                        onChange={(_, newValue) => handleWildcardUpdate('tsPeriod', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Period"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={['*', ...tsCombinations.tsWindows]}
                        value={criteria.tsWindow}
                        onChange={(_, newValue) => handleWildcardUpdate('tsWindow', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Window"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={['*', ...tsCombinations.tsSubclasses]}
                        value={criteria.tsSubclass}
                        onChange={(_, newValue) => handleWildcardUpdate('tsSubclass', newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="TS Subclass"
                            color="success" 
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                          variant="outlined"
                          size='small'
                          fullWidth
                          label="Min Capacity"
                          color="success" 
                          value={criteria.minCapacity}
                          onChange={e => handleChange('minCapacity', e.target.value)}
                        />
                    </Grid>
                    {/* changed size of button */}
                    <Grid item xs={6}>
                        <Button  
                          endIcon={<GetAppIcon />}
                          variant="contained"
                          onClick={handleFetch}
                          fullWidth
                        >Fetch Routes</Button>
                    </Grid>
                </Grid>
              } 
            </Grid>
          </Box>
        </ResizingPane>
     </Collapse>
    </div>
  )
}