import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { useState, useEffect } from 'react';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import useHeader from '../useHeader';

const moment = require('moment-timezone');

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}


function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {

    const [tsrs, setTsrs] = useState(null);
    const [time, setTime] = useState(Date.now());
    const [seconds, setSeconds] = useState(0);
    const headers = useHeader();
    
    
    useEffect(() => {
        
             
        // we will use async/await to fetch this data
        async function getData() {
      
          const response = await fetch(`${apiUrlPrefix}/CrystalBall/TSR/TransactionFeedWithFilter?desiredCount=100&emailFilter=teainc`,
          {
            headers: headers,
          });
          const data = await response.json();
          setTsrs(data.tsrs);
        }

        let abortController = new AbortController();  
        // your async action is here  
        getData();
        return () => {  
          abortController.abort();  
        }  
    
    }, []); // <- you may need to put the setBooks function in this array
    
  return (
    <React.Fragment>
      <Title>Recent TSRs</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>ARef</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Trader</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Leg</TableCell>
            <TableCell>MW</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {tsrs && (
            tsrs.map((tsr, index) => (
            <TableRow key={index}>
                <TableCell>{tsr.assignmentRef}</TableCell>
              <TableCell>{moment.tz(tsr.timeOfLastUpdate,"America/New_York").utcOffset(-8).format('MM/DD/YY hh:mm:ss A')} ET</TableCell>
              <TableCell>{tsr.customerEmail}</TableCell>
              <TableCell>{tsr.requestType}</TableCell>
              <TableCell>{tsr.pointOfReceipt} | {tsr.pointOfDelivery}</TableCell>
              <TableCell>{tsr.status}</TableCell>
              <TableCell align="right">{tsr.capacity} MW</TableCell>
            </TableRow>
          ))
        )}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more TSRs
      </Link>
    </React.Fragment>
  );
}