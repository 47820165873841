import { Grid, Tooltip, TextField, Dialog, IconButton, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array, } from 'yup';
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useMemo } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { nercPriorities } from "../../../utils/constants";

export const AutoBuildPathsDialog = (props) => {
    const { open, onClose, handleBuildPaths, } = props;
    const storageLocation = 'autoBuildPathsOptions';
    const storedDefaults = JSON.parse(localStorage.getItem(storageLocation) || '{}');

    const defaultValues = useMemo(() => ({
        requestTypes: ['*'],
        nercPriority: ['*'],
        maxLegs: 'Minimum',
        ...storedDefaults,
    }), []);

    const schema = object().shape({
        requestTypes: array().min(1, 'Please select at least one request type.').required('Please select at least one request type.'),
        nercPriority: array().min(1, 'Please select at least one NERC Priority.').required('Please select at least one NERC Priority.'),
        maxLegs: string().required('Please select a maximum number of legs.'),
    });

    const { handleSubmit, setValue, control, formState: { errors, }, } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    function onSubmit(data) {
        localStorage.setItem(storageLocation, JSON.stringify(data));
        handleBuildPaths(data);
        onClose();
    }

    function handleWildcardUpdate(key, value) {
        const [lastAdded] = value?.slice(-1);
        if (lastAdded === '*') {
            setValue(key, ['*'])
        } else {
            setValue(key, value.filter(option => option !== '*'))
        }
    }

    const maxLegNums = Array.from({ length: 10 }, (_, i) => i + 1).map(num => num.toString());
    const maxLegOptions = ['Minimum', ...maxLegNums, 'Any Length'];

    // Extract the priority values from the nercPriorities array - only take the first part, if it can be parsed as a number
    const priorityOptions = nercPriorities.map(priority => parseInt(priority.split('-')[0])).filter(priority => !isNaN(priority)).sort();

    return (
        <Dialog open={open} fullWidth maxWidth='sm'>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Auto Build Paths</Typography>
                <IconButton onClick={onClose} size='large'>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Automatically build paths that connect the selected nodes using the options below.
                </DialogContentText>
                <form id='auto-build-paths-form' onSubmit={handleSubmit(onSubmit)}>
                    <Grid spacing={1} container sx={{ paddingTop: 2, }}>
                        <Grid item xs={8}>
                            <Tooltip title="Select the request types to build paths for." arrow placement="left">
                                <FormAutocomplete
                                    name='requestTypes'
                                    control={control}
                                    onChange={(_, newVal) => {
                                        handleWildcardUpdate('requestTypes', newVal);
                                    }}
                                    multiple
                                    autoFocus
                                    autoHighlight
                                    fullWidth
                                    options={['Reserved', 'Redirect', 'Original', '*']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Request Types"
                                            error={!!errors.requestTypes}
                                            helperText={errors.requestTypes?.message ?? 'Choose * to include all request types.'}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip title="Select the maximum number of legs to include in the paths." arrow placement="left">
                                <FormAutocomplete
                                    name='maxLegs'
                                    control={control}
                                    autoHighlight
                                    fullWidth
                                    options={maxLegOptions}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Maximum Legs"
                                            error={!!errors.maxLegs}
                                            helperText={errors.maxLegs?.message}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={8}>
                            <Tooltip title="Only include legs with these NERC Priorities" arrow placement="left">
                                <FormAutocomplete
                                    name='nercPriority'
                                    control={control}
                                    onChange={(_, newVal) => {
                                        handleWildcardUpdate('nercPriority', newVal);
                                    }}
                                    multiple
                                    autoHighlight
                                    fullWidth
                                    options={['*', ...priorityOptions]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="NERC Priority"
                                            error={!!errors.nercPriority}
                                            helperText={errors.nercPriority?.message ?? 'Choose * to include legs with any priority.'}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Button
                    onClick={onClose}
                    color='primary'
                >Cancel</Button>
                <Button
                    color='primary'
                    variant="contained"
                    type="submit"
                    form='auto-build-paths-form'
                >Create Paths</Button>
            </DialogActions>
        </Dialog>
    );
}