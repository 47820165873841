export const defaultColumnDef = {
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
    enableRowGroup: true,
}

export const defaultStatusBar = {
    statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
    ],
};

const autoGroupColumnDef = {
    // group columns configured to use the Group Column Filter
    filter: 'agGroupColumnFilter',
    floatingFilter: true,
}

export const defaultGridOptions = {
    enableRangeSelection: true,
    autoGroupColumnDef: autoGroupColumnDef,
    enableFillHandle: true,
    enableRangeHandle: true,
    stopEditingWhenCellsLoseFocus: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    enableCellEditingOnBackspace: true,
    suppressLastEmptyLineOnPaste: true,
};