import { useState, useCallback, useMemo, useEffect } from "react";
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export const useChart = ({ id, }) => {
    const [chartRef, setChartRef] = useState(null);

    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    useEffect(() => {
        //destroy chart when component unmounts
        return () => {
            if (chartRef) { chartRef.destroyChart(); }
        };
    }, [chartRef]);

    /*const saveChart = useCallback(() => {
        const chartModels = gridRef.current.api.getChartModels() || [];
        if (chartModels.length > 0) {
            const chartModel = chartModels[0];
            setChart(chartModel);
        }
    }, []);

    const restoreChart = useCallback(() => {
        if (!chart) return;
        gridRef.current.api.restoreChart(chart);
    }, [chart]);

    const handleChartCreated = useCallback((event) => {
        if (chart) {
            //destroy previous chart
            const chart = event.chartId;
            gridRef.current.api.destroyChart(chart);
            restoreChart();
        }
    }, [chart]);*/

    const createChartContainer = useCallback((chartRef) => {
        const chartContainer = document.getElementById(`user-chart-${id}`);
        chartContainer.appendChild(chartRef.chartElement);
    }, []);

    /*const createChartContainer = useCallback((chartRef) => {
        const eChart = chartRef.chartElement;
        const themeName =
            document.documentElement?.getAttribute("data-default-theme") ||
            "ag-theme-quartz";
        const eParent = document.querySelector("#tag-report-chart-container");
        const chartWrapperHTML = `
        <div class="chart-wrapper ${themeName}" height='100%' width='100%'>
          <div class="chart-wrapper-top">
            <button class="chart-wrapper-close">Destroy Chart</button>
          </div>
          <div class="chart-wrapper-body" height='100%' width='100%'></div>
        </div>
      `;
        eParent.insertAdjacentHTML("beforeend", chartWrapperHTML);
        const eChartWrapper = eParent.lastElementChild;
        eChartWrapper.querySelector(".chart-wrapper-body").appendChild(eChart);
        eChartWrapper
            .querySelector(".chart-wrapper-close")
            .addEventListener("click", () => {
                chartRef.destroyChart();
                eParent.removeChild(eChartWrapper);
            });
    }, []);*/

    const ChartContainer = (props) => {
        return <div id={`user-chart-${id}`} className="chart-wrapper-body" {...props} />;
    }

    return {
        //saveChart,
        //restoreChart,
        //handleChartCreated,
        ChartContainer,
        createChartContainer,
        popupParent,
    };
}