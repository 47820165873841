import { AgGridReact } from "ag-grid-react";
import { Tooltip, FormGroup, Box, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, useContext } from 'react'
import { UserContext } from "../../../data/fetchUserGroups";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import dayjs from '../../dayjs-tz'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useTheme } from '@mui/material/styles';

export default (props) => {
  const { data, action, setAction, handleSetDate, visible } = props;
  const [date, setDate] = useState(dayjs());
  const userGroups = useContext(UserContext);
  const proUser = userGroups.includes('admins') || userGroups.includes('ppwtrans');
  const theme = useTheme();

  useEffect(() => {
    if (action === 'reservefuture') {
      handleSetDate(dayjs().format('MM/DD/YYYY HH:mm:ss'));
    } else handleSetDate('');
  }, [action])

  const formFields = useMemo(() => [
    { name: 'Provider', key: 'TP' },
    { name: 'POR', key: 'PointOfReceipt' },
    { name: 'POD', key: 'PointOfDelivery' },
    { name: 'Path Name', key: 'PathName' },
    { name: 'Source', key: 'Source' },
    { name: 'Sink', key: 'Sink' },
    { name: 'Service Increment', key: 'ServiceIncrement' },
    { name: 'TS Class', key: 'TSClass' },
    { name: 'TS Type', key: 'TSType' },
    { name: 'TS Period', key: 'TSPeriod' },
    { name: 'TS Window', key: 'TSWindow' },
    { name: 'TS Subclass', key: 'TSSubclass' },
    { name: 'Preconfirmed', key: 'Preconfirmed' },
    { name: 'Profile', key: 'profileInfo' },
  ], [])

  function cellRendererSelector(params) {
    if (params.data.name === 'Profile') {
      return {
        component: 'agSparklineCellRenderer'
      }
    } else {
      return null;
    }
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.yValue <= 0 ? palette.pastelRed : palette.green,
      stroke: params.yValue <= 0 ? palette.pastelRed : palette.green,
    };
  };

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = dayjs(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      content: yValue,
    }
  }

  const palette = {
    blue: 'rgb(20,94,140)',
    lightBlue: 'rgb(182,219,242)',
    green: 'rgb(63,141,119)',
    lightGreen: 'rgba(75,168,142, 0.2)',
    pastelRed: 'rgb(255,102,102)',
  };

  function configureProfileSparkline(params) {
    const info = params.data[params.column.colId];
    const atcData = info?.reduce((profile, block, idx) => {
      const endDate = new Date(block.endDateTime);
      const startDate = new Date(block.startDateTime);
      const capacity = parseInt(block.capacityRequested);
      const nextBlockData = [];
      if (idx > 0) {
        const prevBlock = info[idx - 1]; //fill in gaps in blocks with 0 capacity
        if (Math.abs((new Date(prevBlock.endDateTime)) - startDate) > 0) {
          nextBlockData.push({
            x: new Date(prevBlock.endDateTime),
            y: 0,
          })
          nextBlockData.push({
            x: new Date(startDate - 1000),
            y: 0,
          })
        }
      }
      nextBlockData.push({
        x: startDate,
        y: capacity,
      })
      nextBlockData.push({
        x: new Date(endDate - 1000),
        y: capacity,
      })
      return [...profile, ...nextBlockData]
    }, []);
    return atcData;
  }

  const colDefs = useMemo(() => [
    {
      field: 'name',
      headerName: '',
      flex: 1,
    },
    ...[...Object.keys(data)].map(idx => ({
      field: idx,
      headerName: `${data[idx].PointOfReceipt} -> ${data[idx].PointOfDelivery}`,
      cellRendererSelector: cellRendererSelector,
      valueGetter: (params) => {
        if (params.data.name === 'Profile') {
          return configureProfileSparkline(params);
        } else {
          return params.data[params.column.colId];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    }))
  ], [visible])

  const rowData = useMemo(() => formFields.map(field => ({
    ...[...Object.keys(data)].reduce((row, idx) => ({
      ...row,
      [idx]: data[idx][field.key],
    }), { name: field.name })
  })), [visible])

  const defaultColDef = useMemo(() => ({
    resizable: true,
    flex: 2,
  }), [])

  return (
    <Box sx={{ display: 'flex', p: 1 }}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <AgGridContainer
            style={{ width: "100%", height: '100%', fontSize: '10px' }}
          >
            <AgGridReact
              columnDefs={colDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              domLayout={'autoHeight'}
            />
          </AgGridContainer>
        </Grid>
        <Grid item xs={8}>
          <FormGroup row>
            <Tooltip title='Immediately reserve the configured transmission.' arrow placement='top'>
              <FormControlLabel control={<Checkbox disabled={!proUser} checked={action === 'reservenow'} onClick={() => setAction('reservenow')} />} label="Reserve Now" />
            </Tooltip>
            <Tooltip title='Reserve the configured transmission for a future date and time.' arrow placement='top'>
              <FormControlLabel control={<Checkbox disabled={!proUser} checked={action === 'reservefuture'} onClick={() => setAction('reservefuture')} />} label="Reserve Future" />
            </Tooltip>
            <Tooltip title='Wait for capacity to become available to reserve transmission.' arrow placement='top'>
              <FormControlLabel control={<Checkbox disabled={true || !proUser} checked={action === 'awaitavailability'} onClick={() => setAction('awaitavailability')} />} label="Reserve When Available" />
            </Tooltip>
            <Tooltip title='Only reserve transmission if capacity is available for all legs.' arrow placement='top'>
              <FormControlLabel control={<Checkbox disabled={true || !proUser} checked={action === 'allornone'} onClick={() => setAction('allornone')} />} label="All or None" />
            </Tooltip>
            <Tooltip title='Receive a text message when capacity becomes available.' arrow placement='top'>
              <FormControlLabel control={<Checkbox disabled={!proUser} checked={action === 'textalert'} onClick={() => setAction('textalert')} />} label="Text only" />
            </Tooltip>
          </FormGroup>
        </Grid>
        <Grid item xs={4}>
          {(action === 'reservefuture') && <Box sx={{ display: 'flex', flexDirection: 'row', p: 1, alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={date}
                onChange={(newValue) => {
                  handleSetDate(dayjs(newValue).format('MM/DD/YYYY HH:mm:ss'));
                  setDate(newValue);
                }}
                ampm={false}
                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                slotProps={{
                  textField: {
                    label: 'Reservation Date/Time',
                  },
                  layout: {
                    sx: {
                      ul: {
                        '::-webkit-scrollbar': {
                          width: '10px',
                        },
                        '::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.dark,
                          borderRadius: '20px',
                          border: '3px solid transparent',
                          backgroundClip: 'padding-box',
                        },
                        '::-webkit-scrollbar-track': {
                          background: 'transparent',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                          border: 0,
                        },
                      },
                    },
                  }
                }}
              />
            </LocalizationProvider>
          </Box>}
        </Grid>
      </Grid>
    </Box>
  )
}