import OpenAI from "openai";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "./useApi";

export const useAiAssistants = ({ application, files = [], }) => {
    const { get, headers, apiUrlPrefix, post, enqueueSnackbar, logAction, } = useApi();

    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState([]);
    const [error, setError] = useState(false);

    const openAiApiKey = process.env.REACT_APP_OPENAI_API_KEY;

    const [openai, setOpenai] = useState(null);
    const [assistant, setAssistant] = useState(null);
    const [thread, setThread] = useState(null);

    const initOpenAI = useCallback(async () => {

        let openai;
        try {
            openai = new OpenAI({
                apiKey: openAiApiKey,
                dangerouslyAllowBrowser: true,
            });
            
        } catch (err) {
            console.error('Failed to initialize OpenAI:', err);
            setError(true);
            return;
        }

        if (!openai) {
            console.error('OpenAI not initialized properly');
            setError(true);
            return;
        }

        const assistant = await openai.beta.assistants.retrieve("asst_OSsvqbFoOt07NbQZKgqX6jAh");
        if (!assistant?.id) {
            setError(true);
            console.error('Assistant not initialized');
            return;
        }

        const thread = await openai?.beta.threads.create({
            messages: context.reduce((acc, ctx) => {
                if (ctx.message) { // only add messages that have content, otherwise the API will throw an error
                    acc.push({
                        role: ctx.role,
                        content: ctx.message,
                    });
                }
                return acc;
            }, []),
        });
        if (!thread?.id) {
            setError(true);
            console.error('Thread not initialized');
            return;
        }

        setOpenai(openai);
        setAssistant(assistant);
        setThread(thread);
    }, [openAiApiKey, context]);

    useEffect(() => {
        if (openAiApiKey && context) {
            initOpenAI();
        }
    }, [openAiApiKey, context, initOpenAI]);

    const fetchContext = useCallback(async (newMessage, role = '') => {
        const urlSuffix = `name=PowerStationMetaData.AI_fetchHelpThread&parm=${headers.userGuid}&parm=${application}&parm=${role}`

        if (!newMessage) { // if there is no new message, fetch the context
            const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?${urlSuffix}`

            get(url).then(response => {
                setContext(response.data ?? []);
            });
        } else { // if there is a new message, push the new message to the context
            const postUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/PushFetch?${urlSuffix}`

            post(postUrl, newMessage).then(() => fetchContext());
        }

    }, [application, apiUrlPrefix, get, post, headers.userGuid]);

    useEffect(() => {
        fetchContext();
    }, [fetchContext]);

    const assistantQuery = useCallback(async (prompt) => {
        //upload all files to the assistant
        //this code could just upload the file as I am not seeing where it links it to the assistant ID
        // TODO: upload files to the assistant, not the thread
        /*const uploadedFiles = await Promise.all(
            [...files].map(file => openai.files.create({
                file: file,
                purpose: "assistants",
            }))
        );*/

        // Send a message to the thread
        await openai.beta.threads.messages.create(thread.id, {
            role: "user",
            content: prompt,
        });

        // Send the message to our backend to store the message in the context
        fetchContext(prompt, 'user');

        // Run the assistant
        const run = await openai.beta.threads.runs.create(thread.id, {
            assistant_id: assistant.id,
        });

        // Create a response
        let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

        // Wait for the response to be ready
        while (response.status === "in_progress" || response.status === "queued") {
            await new Promise((resolve) => setTimeout(resolve, 500));
            response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
        }

        // console.log(run);

        // Get the messages for the thread
        //erik, this should still work to return the old messages plus the chat gpt response
        const messageList = await openai.beta.threads.messages.list(thread.id);

        // Find the last message for the current run
        const lastMessageData = messageList.data
            .filter((message) => message.run_id === run.id && message.role === "assistant")
            .pop();

        // console.log(messageList);

        const lastMessage = lastMessageData.content[0]["text"].value;
        // Send the response to our backend to store the message in the context
        fetchContext(lastMessage, 'assistant');

        // Return the last message
        return lastMessage;
    }, [openai, assistant, thread, fetchContext]);

    const handleApiError = useCallback((err, prompt) => {
        const message = `Error submitting query. Error: ${err}`;
        enqueueSnackbar(message, { variant: 'error', });
        setLoading(false);
        logAction(`The assistant threw an error while handling a prompt`, `${application} - useAiAssistants`, {
            prompt: prompt,
        });
    }, [application, enqueueSnackbar, logAction]);

    const submitPrompt = useCallback(async (prompt) => {
        const timestamp = new Date()
        return assistantQuery(prompt).then(text => {
            const difference = new Date() - timestamp;
            console.log(`It took ${difference} milliseconds to get a response from the assistant API.`);
            return text;
        }).catch((err) => handleApiError(err, prompt));
    }, [assistantQuery, handleApiError]);

    return {
        loading,
        context,
        setLoading,
        submitPrompt,
        error,
    }
}

