import { TextField, Stack, Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Typography, Container, Box, } from '@mui/material';
import { useNavigate } from "react-router";
import { useIsAuthenticated, } from '@azure/msal-react';
import SignInButton from "./SignInButton";
import { RegisterButton } from "./RegisterButton";
import { useActionAudit } from './useActionAudit';
import { msalConfig } from '../authConfig';
import { SignOnDialog } from './SignOnDialog';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { ChatQAndA } from './ChatQAndA';
import { NewsletterDialog } from './NewsletterDialog';
import { useMsal } from '@azure/msal-react';
import { apiUrlPrefix } from '../authConfig';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import useHeader from './useHeader';

function Album() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { logAction } = useActionAudit();
  const [openLogin, setOpenLogin] = useState(false);
  const [redirectUri, setRedirectUri] = useState(msalConfig.auth.redirectUri);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const { accounts } = useMsal();
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();

  const handleFastPathClick = () => {
    if (isAuthenticated) {
      navigate("/fastpath");
      logActions('Authenticated user clicked Try Fast Path, redirected to Fast Path', 'aboutPowerStation');
    } else {
      // setRedirectUri(`${msalConfig.auth.redirectUri}/fastpath`);
      // setOpenLogin(true);
      navigate("/fastpath");
      logActions('Unauthenticated user clicked Try Fast Path, redirected to Fast Path', 'aboutPowerStation');
    }

  };

  const handleTheBrainClick = () => {
    if (isAuthenticated) {
      navigate("/brain");
      logActions('Authenticated user clicked Try the brain, redirected to the brain', 'aboutPowerStation');
    } else {
      // setRedirectUri(`${msalConfig.auth.redirectUri}/fastpath`);
      // setOpenLogin(true);
      navigate("/brain");
      logActions('Unauthenticated user clicked Try The Brain, redirected to the Brain', 'aboutPowerStation');
    }

  };

  const handleTransHunterTrialClick = () => {
    if (isAuthenticated) {
      navigate("/transhunter");
      logActions('Authenticated user clicked Trial Trans Hunter, redirected to Trans Hunter', 'aboutPowerStation');
    } else {
      // setRedirectUri(`${msalConfig.auth.redirectUri}/transhunter`);
      // setOpenLogin(true);
      navigate("/transhunter");
      logActions('Unauthenticated user clicked Trial Trans Hunter, redirected to Trans Hunter', 'aboutPowerStation');
    }

  };

  function logActions(message, userInterface) {
    logAction(message, userInterface);
  };

  const darkModeExt = theme.palette.mode === 'dark' ? '_dark' : '';

  //request a power station demo https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb
  //request fast path access https://webforms.pipedrive.com/f/6y1Agmun10X14kzXAaApB03foxNbcawIKnQDevwdSlfbkKBbhuEH2bDiG1CnjIgpij
  //fast path demo https://webforms.pipedrive.com/f/6k1duQB6AnyQfeLx6nxn9FCP3lSPlUOUZHegA2DKldxZt0MDcv4vRN5mNK1WagLkVd
  //trans hunter demo https://webforms.pipedrive.com/f/ckxJi2yaB2V2CAVy8LuMZ3TmfbG6Az1XXv0NdcXk7qdLAftJ9VQ0S3ylIEnN6qRsxd
  //trans now demo https://webforms.pipedrive.com/f/6iHXbMSZOmb728NJ4T9Li43IAKkmtZRByAFbQzcDxQNDjhyjqTALaGZtmrtDhG0OVt

  function handleSubscribe() {
    if (accounts.length > 0) { //we have the user's email, so just subscribe them
      logActions('User clicked Subscribe', 'aboutPowerStation');
      let emailAddress = accounts[0].username;
      if (!emailAddress) {//if there is no userName, try to read out of local storage
        const userName = localStorage.getItem('DefaultUserName');
        emailAddress = userName;
      }
      handleAddEmail({ email: emailAddress });
    } else { //we don't have the user's email, open the dialog so they can enter it
      setOpenSubscribeDialog(true);
    }
  }

  function handleAddEmail({ email }) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_emailDistributionInsert&parm=${encodeURIComponent(email)}`;
    const options = {
      headers: headers,
      url: url,
    }

    axios(options).then(() => {
      enqueueSnackbar(`You were subscribed to the newsletter!`, { variant: 'success' });
    }).catch(error => {
      enqueueSnackbar(error?.response?.data, { variant: 'error' });
    });
  }

  return (
    <section style={{ backgroundColor: theme.palette.background.paper, padding: theme.spacing(3, 0, 6), }}>
      <CssBaseline />
      <NewsletterDialog
        open={openSubscribeDialog}
        handleClose={() => setOpenSubscribeDialog(false)}
        handleAddEmail={handleAddEmail}
      />
      <SignOnDialog
        open={openLogin}
        onClose={() => setOpenLogin(false)}
        redirectUri={redirectUri}
      />
      <Container maxWidth="md">
        <ChatQAndA />
        {/* <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
          Introducing the Power Station Platform
        </Typography> */}
        {/* <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Made for energy traders and trading managers, the Power Station Platform turbo-charges your trading operations.  It saves your trading desk time, makes your traders smarter, and provides competitive advantage for your organization.  With new applications and features being added every day, Power Station is quickly becoming a must have for every trading operation.
        </Typography> */}
        <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <Button
            onClick={handleFastPathClick}
            variant="contained"
            color="primary"
          >
            Try Fast Path
          </Button>
          <Button
            onClick={handleTheBrainClick}
            variant="contained"
            color="primary"
          >
            Try The Brain
          </Button>
          {/*<Button
            onClick={handleTransHunterTrialClick}
            variant="contained"
            color="primary"
          >
            Try Trans Hunter
          </Button>*/}
          <Button
            href="https://youtu.be/7PnPAIAExGU?si=Ff7dupr92IgHwIOB"
            onClick={() => logActions('Clicked Watch Video, redirected to Fast Path YouTube Video', 'aboutPowerStation')}
            variant="outlined"
            color="primary">
            Watch Video
          </Button>
          <Button
            href="https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb"
            onClick={() => logActions('Clicked Request a Demo of Power Station, redirected to pipedrive form', 'aboutPowerStation')}
            variant="outlined" color="primary">
            Get Demo
          </Button>
          <SignInButton
            color="primary"
            variant="outlined"
          >
            Login
          </SignInButton>
          <RegisterButton
            color="primary"
            variant="outlined"
          >
            Register
          </RegisterButton>
          {/* <Button
            color='primary'
            variant='contained'
            onClick={handleSubscribe}
          >Subscribe</Button> */}
        </Stack>
      </Container>
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ paddingTop: theme.spacing(2), }}>
          <Grid item key={1} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + `/FastPath${darkModeExt}.webp`}
                title="Fast Path"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  Fast Path
                </Typography>
                <Typography>
                  Our Fast Path app finds the lowest cost transmission routes for a given POR and POD. Traders can easily find least cost pathways, monitor current Available Transmission Capacity, initiate Original Requests, and be alerted when transmission becomes available.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  // href="https://webforms.pipedrive.com/f/6k1duQB6AnyQfeLx6nxn9FCP3lSPlUOUZHegA2DKldxZt0MDcv4vRN5mNK1WagLkVd"
                  href="https://www.powerstationplatform.com/fastpath"
                  onClick={() => logActions('Clicked try out FastPath, redirected to fast path', 'aboutPowerStation')}
                  size="small" color="primary">
                  Try it out for free
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item key={4} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + "/daBrain.png"}
                title="Image title"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  The Brain
                </Typography>
                <Typography>
                  The Brain is an advanced prediction modeling system designed to seamlessly integrate with existing prediction algorithms, providing SMS alerts when the spread between locational marginal prices (LMP) and predicted LMP reaches profitable thresholds. With its nimble and adaptable platform, 'The Brain' allows traders to quickly experiment and adjust strategies, ensuring they stay ahead in the fast-paced energy market.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://www.powerstationplatform.com/brain"
                  onClick={() => logActions('Clicked try out the brain, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  Try it out for free
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item key={6} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + "/dealRizz.png"}
                title="Image title"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  Deal Rizz
                </Typography>
                <Typography>
                  Our deal creation and scheduling app that lets you more effectively manage your energy portfolio.  Automated tag creation and tag adjustments simplifies complex trading operations.  Integration with your risk management systems makes for a seemless operation.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb"
                  onClick={() => logActions('Clicked more info on Deal Rizz, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  More Info
                </Button>
              </CardActions>
            </Card>
          </Grid><Grid item key={2} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + `/TransHunter${darkModeExt}.webp`}
                title="Trans Hunter"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  Trans Hunter
                </Typography>
                <Typography>
                  Our Trans Hunter app lets you visualize transmission availability across all segments and then create alerts to tell you and your team when transmission becomes available.  Use Trans Hunter to review historical ATC changes, be alerted when outages end early, and much more.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://webforms.pipedrive.com/f/ckxJi2yaB2V2CAVy8LuMZ3TmfbG6Az1XXv0NdcXk7qdLAftJ9VQ0S3ylIEnN6qRsxd"
                  onClick={() => logActions('Clicked Demo of Trans Hunter, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  Request Demo
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item key={3} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + `/TransNow${darkModeExt}.webp`}
                title="Trans Now"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  Trans Now
                </Typography>
                <Typography>
                  Our Trans Now app is bulk automation for day-ahead and real-time transmission trading. Drag and drop redirects and reassignments allow for lightning fast trading operations.  Trans Now helps you easily discern how much capacity you have remaining and quickly redirect and relinquish transmission from your TSRs.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://webforms.pipedrive.com/f/6iHXbMSZOmb728NJ4T9Li43IAKkmtZRByAFbQzcDxQNDjhyjqTALaGZtmrtDhG0OVt"
                  onClick={() => logActions('Clicked Demo Trans Now, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  Request Demo
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* <Grid item key={4} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + "/CrystalBall2.png"}
                title="Image title"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  Crystal Ball API
                </Typography>
                <Typography>
                  Crystal Ball is a cost effective alternative to other web data interfaces.  Its API allows an organization to access loads of valuable trading data without the complexity or cost of currently available web data alternatives.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://webforms.pipedrive.com/f/crXLkZeKSNtvnw81dxlibSFxrT4WbmnQO2uJSWEWwVEb0r0pKsiueA7Zh1U5lGOP3Z"
                  onClick={() => logActions('Clicked Demo Crystal Ball, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  Request Demo
                </Button>
              </CardActions>
            </Card>
          </Grid> */}
          <Grid item key={5} xs={12} sm={6} md={4}>
            <Card className='card'>
              <CardMedia
                className='cardMedia'
                image={process.env.PUBLIC_URL + "/CrystalBall2.png"}
                title="Image title"
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant="h5" component="h2">
                  eTag Listener
                </Typography>
                <Typography>
                  Our eTag listener can be activated and registered to listen for your company's tagging data.  Once listening, it stores your tagging data in a database dedicated to your company, private from all other customers.  API calls allow for integration of JSON tagging data back into your back office or trading desk operations.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  href="https://webforms.pipedrive.com/f/crXLkZeKSNtvnw81dxlibSFxrT4WbmnQO2uJSWEWwVEb0r0pKsiueA7Zh1U5lGOP3Z"
                  onClick={() => logActions('Clicked Demo eTag Listener, redirected to pipedrive form', 'aboutPowerStation')}
                  size="small" color="primary">
                  Request Demo
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Album
