import { useSnackbar } from "notistack"

//hook designed to be used with axios and provide better error trapping/ user messages
export default () => {
    const { enqueueSnackbar } = useSnackbar();

    function parseError(error, url, customMessage='') {
        if(error?.message.includes('Network Error')) {
            enqueueSnackbar('Network Error. You may be experiencing internet connectivity issues.');
        } else {
            enqueueSnackbar(`${customMessage} Error: ${error}. Url: ${url}. Status: ${error.response?.status ?? 'Failed.'}`);
        }
    };

    //returns true if an error is detected.
    function parseResponseForError(response) {
        if(typeof response?.data === 'string') {
            enqueueSnackbar(response.data);
            return true;
        } else {
            return false;
        }
    }

    return {
        parseError,
        parseResponseForError
    }
}