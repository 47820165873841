import React from "react";
//import useState from "react";
//import { useSpyMonkeyStyles } from "../styles";
//import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import ComponentGuard from '../components/Guards/ComponentGuard.js';
import { userGroups } from "../authConfig";

//components
import UserGroups from "../components/Settings/UserGroups";

function UserGroupsView() {
  //const classes = useSpyMonkeyStyles();

  return (
    <ComponentGuard groups={[userGroups.usergroupsview, ]} >
      
        <Box sx={{ p: 1, display: 'flex' }}>
          <UserGroups />
        </Box>
      
    </ComponentGuard>
    );

}

export default UserGroupsView;