// import * as React from 'react';
//import { useDashboardStyles } from "../styles";
import DashboardContent from "../components/Dashboard"
import ComponentGuard from '../components/Guards/ComponentGuard.js';
import { userGroups } from "../authConfig";

function Dashboard() {
  //const classes = useDashboardStyles();

  return (
    <div>
      <ComponentGuard groups={[userGroups.dashboard]} >
          <DashboardContent />
      </ComponentGuard>
    </div>
  );
}

export default Dashboard;
