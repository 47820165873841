import { TransDealSummaryView } from "./TransDealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy";

export const TransDealSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'trans-deal-summary';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={TransDealSummaryView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
