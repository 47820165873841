import {useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import { Button, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, MenuList, MenuItem, ListItemText, ListItemIcon, } from '@mui/material';

export const LayoutToolPanel = ({onLayoutClear, onLayoutLoad, onLayoutSave, onLayoutDelete}) => {  
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  function continueDelete() {
    setShowWarningDialog(false);
    onLayoutDelete();
  }

  function toggleDialog() {
    setShowWarningDialog(!showWarningDialog);
  }

  return(
    <>
      <WarningDialog show={showWarningDialog} handleContinueDelete={continueDelete} handleClose={() => setShowWarningDialog(false)}/>
      <MenuList>
        <MenuItem onClick={onLayoutLoad}>
          <ListItemIcon>
            <RestoreIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Restore</ListItemText>
        </MenuItem>
        <MenuItem onClick={onLayoutClear}>
          <ListItemIcon>
            <ClearIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reset</ListItemText>
        </MenuItem>
        <MenuItem onClick={toggleDialog}>
            <ListItemIcon>
              <DeleteForeverIcon color='primary' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem onClick={onLayoutSave}>
          <ListItemIcon>
            <SaveIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Save</ListItemText>
        </MenuItem>
      </MenuList>
    </>
  );
};

const WarningDialog = (props) => {
  const { show, handleContinueDelete, handleClose } = props;

  return(
    <Dialog open={show}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>You are about to delete your saved layout. Continue?</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleContinueDelete}>Continue</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};