import { RibbonButton } from "../Ribbon";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import { ScheduleHistoryDialog } from "../ScheduleHistory/ScheduleHistoryDialog";

export const ScheduleHistoryButton = ({ expanded, schedule, ...buttonProps }) => {
    const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
    const id = schedule?.scheduleID;

    return (
        <>
            <ScheduleHistoryDialog
                open={openHistoryDialog}
                onClose={() => setOpenHistoryDialog(false)}
                scheduleId={id}
            />
            <Tooltip title="Select a schedule below and click here to see its history.">
                <span>
                    <RibbonButton
                        onMouseDown={() => {
                            setOpenHistoryDialog(true);
                        }}
                        icon={<HistoryIcon fontSize='small' />}
                        label={'History'}
                        disabled={!id}
                        expanded={expanded}
                        {...buttonProps}
                    />
                </span>
            </Tooltip>
        </>
    )
}
