import { List, Card, TextField, InputAdornment, IconButton, Divider, Tooltip, CardHeader, ListItemButton, ListItemIcon, Box, Typography, Checkbox, } from "@mui/material"
import ClearIcon from '@mui/icons-material/Clear';

export const ListRefinement = ({ tags, selectedTags, setSelectedTags }) => {
    function handleToggle(value) {
        const newSelectedTags = [...selectedTags];
        if (newSelectedTags.includes(value)) {
            setSelectedTags(newSelectedTags.filter((tag) => tag !== value));
        } else {
            setSelectedTags([...newSelectedTags, value]);
        }
    }

    const renderRow = (tag) => {
        return (
            <ListItemButton
                key={`tag-${tag}`}
                role="listitem"
                onClick={() => handleToggle(tag)}
                sx={{ height: 18, my: '2px' }}
            >
                <ListItemIcon>
                    <Checkbox
                        checked={selectedTags.includes(tag)}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <Box component='li' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1, }}>
                    <Typography fontSize={12} width='100%'>{tag}</Typography>
                </Box>
            </ListItemButton>
        );
    }

    const tagsSortedByTagNumber = Array.from(tags).sort((a, b) => a[1].length - b[1].length);

    return (
        <Card elevation={5} sx={{
            width: '100%',
            height: 500,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <CardHeader
                sx={{ px: 2, height: 55, display: 'flex', alignItems: 'center', }}
                title={'Refine Search'}
                avatar={
                    <IconButton
                        onClick={() => setSelectedTags([])}
                        size="small"
                    >
                        <Tooltip title="Clear Selections">
                            <ClearIcon />
                        </Tooltip>
                    </IconButton>
                }
            />
            <Divider />
            <Box sx={{ py: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
                {
                    tagsSortedByTagNumber.map(([category, tags]) => {
                        return (
                            <Box key={`category-${category}-tag-list-container`} sx={{ display: 'flex', flexDirection: 'column', }}>
                                <Typography variant='p' sx={{ px: 2, py: 0.25, }}>{category}</Typography>
                                <List sx={{ py: 0, }}>
                                    {tags.map((tag) => renderRow(tag))}
                                </List>
                            </Box>
                        )
                    })
                }
            </Box>
        </Card>
    )
}