import { useContext, useLayoutEffect } from 'react';
import { ColorThemeContext } from './ColorThemeContext';

export function useColorMode(onModeChanged) {
    const colorMode = useContext(ColorThemeContext);

    useLayoutEffect(() => {
        if (onModeChanged) {
            onModeChanged(colorMode);
        }
    }, [colorMode]);

    return colorMode;
}