import { useEffect, useMemo, useState } from "react";
import { useLocalGuid } from "../data/UserGuidContext/useLocalGuid";
import axios from "axios";
import { apiUrlPrefix } from "../authConfig";
import { useSnackbar } from 'notistack';
import useHeader from "./useHeader";
import useGridLayout from "./useGridLayout";

export default (storageKey, ref, baseColDefs) => {
  const [views, setViews] = useState([]);
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const header = useHeader();
  const { colDefs, loadLayout, applyLayout, clearLayout, captureLayout, } = useGridLayout(storageKey, ref, baseColDefs);

  function updateView(view) {
    view 
    ? handleApplyLayout(view)
    : clearLayout();
  }

  useEffect(() => {
    getViews();
  }, [])

  const viewPanel = useMemo(() => {
    return {
      id: 'gridViewToolPanel',
      labelDefault: 'Layout',
      labelKey: 'gridViewToolPanel',
      toolPanel: 'viewToolPanel',
      toolPanelParams: {
        views: views,
        saveView: postView,
        updateView: updateView,
        deleteView: deleteView,
        getViews: getViews,
      },
      minWidth: 100,
      maxWidth: 300,
      width: 200
    }
  }, [views]);

  function handleApplyLayout(view) {
    try {
      //if the view has a colState, it is an old view and needs to be converted to the new format
      if (view.colState?.length) {
        const newLayout = {
          filters: view.filters,
          state: view.colState.map(col => {
            return {
              colId: col.colId,
              width: col.width,
              hide: col.hide,
              sort: col.sort,
              order: view.colOrder.indexOf(col.colId),
              pinned: col.pinned,
            }
          }),
        }
        applyLayout(newLayout);
      } else {
        applyLayout(view);
      }
      enqueueSnackbar(`Layout applied.`);
    } catch (err) {
      enqueueSnackbar(`Error applying layout.  ${err}`);
    }
  }

  async function deleteView(name) {
    const view = views.find(v => v.template === name);
    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete&parm=${guid}&parm=${storageKey}&parm=${view.template}`;
    const options = {
      headers: header,
      url: deleteUri,
    }

    await axios(options).then(response => {
      getViews();
    }).catch(err => { enqueueSnackbar(`Error deleting view(s) in real time tsrs.  ${err}`) });
  }

  async function getViews() {
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch&parm=${guid}&parm=${storageKey}`;
    const options = {
      headers: header,
      url: loadUri,
    }
    axios(options).then(response => { 
      setViews(response.data); 
    }).catch(err => { enqueueSnackbar(`Error getting view data in real time tsrs.  ${err}`) });
  };

  function postView(viewName) {
    const newView = {
      grid: storageKey,
      json: captureLayout(),
      template: viewName,
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate&parm=${guid}&parm=${newView.grid}&parm=${newView.template}`;
    
    const options = {
      method: 'POST',
      headers: header,
      data: newView.json,
      url: saveUri,
    }

    axios(options).then(response => {
      getViews()
    }).catch(err => { enqueueSnackbar(`Error saving the view data for real time TSRs.  ${err}`) });
  }

  return {
    colDefs,
    viewPanel,
    loadLayout,
  }
};