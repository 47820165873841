import { PublicClientApplication } from '@azure/msal-browser';
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router/dom';
import { msalConfigAzureAD } from './authConfig';
import { DurableFunctionProvider } from './components/DurableFunctionContext/durableFunctionProvider.js';
import { HubProvider } from './components/HubContext/hubProvider.js';
import { IPProvider } from './components/IPContext/IPProvider.js';
import { StyledSnackbar } from './components/StyledSnackbar.js';
import { UserInfoProvider } from './components/UserInfoContext.js';
import { router } from './routeConfig.js';
import './styles/cardStyles.css';
import { ColorThemeProvider } from './styles/ColorMode/ColorThemeProvider.js';
import './styles/gridRowStyles.css';
import './styles/muiStyles.css';
import './styles/powerstationStyles.css';

const msalInstanceAzureAD = new PublicClientApplication(msalConfigAzureAD);

msalInstanceAzureAD.handleRedirectPromise().then((response) => {
    if (response !== null) {
        msalInstanceAzureAD.setActiveAccount(response.account);
    }
}).catch((error) => {
    console.error(error);
});

const SnackbarComponents = {
    success: StyledSnackbar,
    error: StyledSnackbar,
    info: StyledSnackbar,
};


function App() {
    return (
        <HelmetProvider>
            <StyledEngineProvider injectFirst>
                <ColorThemeProvider>
                    <SnackbarProvider maxSnack={5} Components={SnackbarComponents} preventDuplicate>
                        <HubProvider>
                            <DurableFunctionProvider>
                                <IPProvider>
                                    <UserInfoProvider>
                                        <RouterProvider router={router} />
                                    </UserInfoProvider>
                                </IPProvider>
                            </DurableFunctionProvider>
                        </HubProvider>
                    </SnackbarProvider>
                </ColorThemeProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    );
}

export default App;
