import { useState, useEffect } from 'react';
import FormAutocomplete from '../../FormControls/FormAutocomplete';
import FormDateTimePicker from '../../FormControls/FormDateTimePicker';
import { FormControlLabel, TextField, Grid, Button, Badge, Tooltip, Box, } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { useFormContext } from 'react-hook-form';
import { PercentageDropdown } from './PercentageDropdown';
import dayjs from 'dayjs';
import FormDatePicker from '../../FormControls/FormDatePicker';
import FormCheckbox from '../../FormControls/FormCheckbox';
import { useApi } from '../../useApi';
import { LocalizationProvider, } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useUserGroups } from '../../../data/useUserGroups';
import { userGroups } from '../../../authConfig';

export const DealEntryForm = ({ setOpenProfileEditor, options, isEdit }) => {
    const { formState: { errors, }, control, getValues, register, watch, setValue, } = useFormContext();
    const defaultBreakpoints = { xs: 10, sm: 5, md: 2, };
    const { headers, apiUrlPrefix, enqueueSnackbar, get } = useApi();
    const [traderOptions, setTraderOptions] = useState([]);
    const { userIsInGroup } = useUserGroups();
    const userIsTenantAdmin = userIsInGroup(userGroups.tenantAdmin);
    const allowFreeSolo = userIsTenantAdmin;

    useEffect(() => {
        fetchTraderList();
    }, []);

    function fetchTraderList() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserListFetch&parm=${headers.userGuid}`;

        get(url).then(response => {
            const names = [...new Set(response.data.map(user => user.userName).filter(name => name))]; //filter out falsey and duplicate values
            setTraderOptions(names);
        }).catch(error => {
            enqueueSnackbar(`Error fetching trader options. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`, { variant: 'error', });
        });
    }

    function handleDateUpdate(key, value) {
        //make sure start date is before stop date
        const start = getValues('dealStartTime');
        const stop = getValues('dealStopTime');
        if ((key === 'dealStartTime' && dayjs(stop).isBefore(value)) || (key === 'dealStopTime' && dayjs(start).isAfter(value))) {
            setValue('dealStartTime', value);
            setValue('dealStopTime', value);
        } else {
            setValue(key, value);
        }
    }

    return (
        <Box key='trader-fields-form-group' sx={{ pt: 2, }}>
            <Grid container spacing={2} columns={10}>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Transaction_Type'] ?? []}
                        control={control}
                        name="Transaction_Type"
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Transaction Type"
                                size='small'
                                error={!!errors.Transaction_Type}
                                helperText={errors.Transaction_Type?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Transaction'] ?? []}
                        control={control}
                        name="Transaction"
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Transaction"
                                size='small'
                                error={!!errors.Transaction}
                                helperText={errors.Transaction?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Counterparty'] ?? []}
                        control={control}
                        name="Counterparty"
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Counterparty"
                                size='small'
                                error={!!errors.Counterparty}
                                helperText={errors.Counterparty?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <FormAutocomplete
                        options={options['Index'] ?? []}
                        control={control}
                        name="Index"
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Index"
                                size='small'
                                error={!!errors.Index}
                                helperText={errors.Index?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item {...defaultBreakpoints}>
                    <Badge invisible={!getValues('profile')?.length && !errors.profile} sx={{ width: '100%' }} color={errors.profile ? 'error' : 'success'} badgeContent={
                        errors.profile ? <Tooltip title={errors.profile?.message} arrow placement="top"><ErrorOutline fontSize="small" /></Tooltip> : <Check fontSize="small" />
                    }>
                        <Button
                            variant="contained"
                            fullWidth
                            onMouseDown={() => setOpenProfileEditor(true)}
                        //disabled={isEdit && !userIsTenantAdmin}
                        >
                            Set Profile
                        </Button>
                    </Badge>
                </Grid>

                <Grid item xs={10} sm={5} md={3}>
                    <PercentageDropdown
                        name='Book'
                        lookupOptions={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Book"
                                error={!!errors.Book}
                                helperText={errors.Book?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={3}>
                    <FormAutocomplete
                        name='Contract'
                        control={control}
                        options={options['Contract'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Contract"
                                error={!!errors.Contract}
                                helperText={errors.Contract?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        name='Financial_Type'
                        control={control}
                        options={options['Financial_Type'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Firm/Non-Firm"
                                error={!!errors.Financial_Type}
                                helperText={errors.Financial_Type?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        autoHighlight
                        openOnFocus
                        options={termOptions.map(option => option.name)}
                        control={control}
                        name="Term"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Deal Term"
                                size='small'
                                error={!!errors.Term}
                                helperText={errors.Term?.message}
                                fullWidth
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        name='Market'
                        control={control}
                        options={options['Market'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Market"
                                error={!!errors.Market}
                                helperText={errors.Market?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        name='Zone'
                        control={control}
                        options={options['Zone'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Zone"
                                error={!!errors.Zone}
                                helperText={errors.Zone?.message}
                            />
                        )}
                    />
                </Grid>


                <Grid item xs={10} sm={5} md={3}>
                    <FormAutocomplete
                        name='Trader'
                        control={control}
                        options={traderOptions}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Trader"
                                error={!!errors.Trader}
                                helperText={errors.Trader?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={3}>
                    <TextField
                        {...register('dealToken')}
                        size='small'
                        fullWidth
                        label="Deal Name"
                        error={!!errors.dealToken}
                        helperText={errors.dealToken?.message}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={3}>
                    <FormDatePicker
                        name='Trade_Date'
                        control={control}
                        label="Trade Date"
                        format="MM/DD/YYYY"
                        views={['year', 'month', 'day',]}
                        slotProps={{
                            textField: {
                                size: 'small',
                                error: !!errors.Trade_Date,
                                helperText: errors.Trade_Date?.message,
                                fullWidth: true,
                                onBlur: () => {
                                    const startOfHour = dayjs(watch('Trade_Date')).startOf('hour');
                                    setValue('Trade_Date', startOfHour);
                                },
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={10} sm={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormDateTimePicker
                            name='dealStartTime'
                            control={control}
                            label="Deal Start"
                            onAccept={(newValue) => handleDateUpdate('dealStartTime', newValue?.startOf('hour'))}
                            views={['year', 'month', 'day', 'hours']}
                            format="MM/DD/YYYY HH:mm"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.dealStartTime,
                                    helperText: errors.dealStartTime?.message,
                                    onBlur: (e) => {
                                        handleDateUpdate('dealStartTime', dayjs(e.target.value).startOf('hour'));
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={10} sm={4} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormDateTimePicker
                            name='dealStopTime'
                            control={control}
                            label="Deal Stop"
                            onAccept={(newValue) => handleDateUpdate('dealStopTime', newValue?.startOf('hour'))}
                            views={['year', 'month', 'day', 'hours']}
                            format="MM/DD/YYYY HH:mm"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.dealStopTime,
                                    helperText: errors.dealStopTime?.message,
                                    onBlur: (e) => {
                                        handleDateUpdate('dealStopTime', dayjs(e.target.value).startOf('hour'));
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={10} sm={2} md={2}>
                    <FormControlLabel
                        control={<FormCheckbox
                            name={'confirmed'}
                            control={control}
                        />}
                        label="Confirmed"
                    />
                </Grid>

                <Grid item xs={10} sm={10} md={6}>
                    <PercentageDropdown
                        name='Strategy'
                        lookupOptions={options}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="Strategy"
                                error={!!errors.Strategy}
                                helperText={errors.Strategy?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        name='por'
                        control={control}
                        options={options['por'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POR"
                                error={!!errors.por}
                                helperText={errors.por?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10} sm={5} md={2}>
                    <FormAutocomplete
                        name='pod'
                        control={control}
                        options={options['pod'] ?? []}
                        freeSolo={allowFreeSolo}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size='small'
                                fullWidth
                                label="POD"
                                error={!!errors.pod}
                                helperText={errors.pod?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        {...register('comments')}
                        id="comments"
                        multiline
                        fullWidth
                        label="Internal Comments"
                        maxRows={5}
                        error={!!errors.comments}
                        helperText={errors.comments?.message}
                    />
                </Grid>

            </Grid>
        </Box>
    );
};

const termOptions = [
    {
        name: 'ATC',
        tooltip: 'Adds blocks covering all hours in the selected date range.',
    },
    {
        name: 'ATC Rockies',
        tooltip: 'Adds blocks covering all hours in the selected date range. Rockies hours are 7am-11pm MT.',
    },
    {
        name: 'Hourly',
        tooltip: 'Adds a block for each hour in the selected date range.',
    },
    {
        name: 'Term',
        tooltip: '',
    },
    /*{
        name: '1x8',
        tooltip: 'Adds blocks for off peak hours in the selected date range.',
    },*/
    {
        name: '6x8, 1x24',
        tooltip: 'LL hours including Sunday',
    },
    {
        name: '1x16',
        tooltip: 'Adds blocks for on peak hours in the selected date range.',
    },
    {
        name: '6x16',
        tooltip: 'HL hours excluding Sunday',
    },
    {
        name: '7x16',
        tooltip: 'HL hours including Sunday',
    },
];
