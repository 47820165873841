import { useMemo, useRef, useCallback } from "react";
import { useTheme } from "@mui/material";

export const useGridCrossHighlight = (gridRef) => {
    const theme = useTheme();
    const highlightRef = useRef({
        //nodeId: null,
        colId: null,
    });

    //callback for the onCellMouseOver event of the grid
    const handleCellMouseOverHighlight = useCallback((params) => {
        const oldColId = highlightRef.current.colId;
        const colId = params.column.colId;
        if (colId === oldColId) {
            return;
        }

        highlightRef.current.colId = colId;
        params.api.refreshCells({ force: true, columns: [colId] });
        oldColId && params.api.refreshCells({ force: true, columns: [oldColId] });
    }, []);

    //add to the cell class rules in the default column definition
    const highlightingCellClassRules = useMemo(() => ({
        "ag-column-hover": params => {
            return highlightRef.current.colId === params.column.colId;
        }
    }), []);

    const clearHighlighting = useCallback(() => {
        highlightRef.current.colId = null;
        gridRef.current.api.refreshCells({ force: true });
    }, []);

    return {
        highlightingCellClassRules,
        handleCellMouseOverHighlight,
        clearHighlighting,
    };
};
