import React, { useState } from "react";
import clsx from "clsx";
import { Toolbar, IconButton, } from "@mui/material";
import { Divider, ListItem, Box, List } from "@mui/material";
import { Drawer } from "../StyledDrawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LookupFields from "./LookupFields";
import '../../styles/lookupStyles.css';
//import { Grid } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';


export default function Lookup({ inputVals, setInputVals, handleFastPathUpdate }) {
  const [open, setOpen] = React.useState(true);

  //const drawerWidth = 350;

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          //width={drawerWidth}
          open={open}
        >
          <Divider />
          <div>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={() => setOpen(!open)} size="large">
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Toolbar>
            <Divider />
            {open && (<List>
              <ListItem className={'textLabels'} >
                <h3>Search Criteria</h3>
              </ListItem>
              <LookupFields inputVals={inputVals} setInputVals={setInputVals} handleFastPathUpdate={handleFastPathUpdate} />
            </List>)}
          </div>
        </Drawer>
      </Box>
    </div>
  );
}