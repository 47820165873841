// AISupportTickets.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix } from '../../authConfig';
import DivGuard from '../Guards/DivGuard';
import { userGroups } from '../../authConfig';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useApi } from '../useApi';
import { useChatBots } from '../useChatBots';
import { useSnackbar } from 'notistack';

const AISupportTickets = () => {
  const guid = useLocalGuid();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsFetch&parm=${guid}`;
  const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_SupportRequestsSave&parm=${guid}`;
  const [tickets, setTickets] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [approvedResponse, setApprovedResponse] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const { headers, enqueueSnackbar } = useApi();
  dayjs.extend(utc);

  // Use useChatBots hook
  const { submitPrompt } = useChatBots({
    application: 'SupportTickets',
    defaultModel: 'chatgpt', // or 'gemini' based on your preference
  });

  useEffect(() => {
    fetchTickets();
    // enqueueSnackbar(submitPrompt);
  }, []);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await axios.get(loadUri, { headers });
      const data = response.data ?? [];

      // Filter tickets with null or blank status
      const filteredData = data.filter(
        (ticket) => !ticket.status || ticket.status.trim() === ''
      );

      // Sort tickets by supportRequestID descending
      filteredData.sort((a, b) => b.supportRequestID - a.supportRequestID);

      // Initialize tickets without AI responses
      const ticketsWithAIResponses = filteredData.map((ticket) => ({
        ...ticket,
        aiResponse: null, // AI response will be fetched when user clicks the button
        fetchingAIResponse: false, // To manage loading state for individual tickets
      }));

      setTickets(ticketsWithAIResponses);
    } catch (error) {
      console.error('Error fetching support tickets:', error);
      enqueueSnackbar(`Error fetching support tickets: ${error}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAIResponseForTicket = async (ticketId) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket) =>
        ticket.supportRequestID === ticketId
          ? { ...ticket, fetchingAIResponse: true }
          : ticket
      )
    );

    const ticket = tickets.find((t) => t.supportRequestID === ticketId);

    try {
      // Generate AI response using submitPrompt
      const aiResponse = await submitPrompt(ticket.description);

      // Include ticket details in the response
      const ticketDetails = `Support Ticket ID: ${ticket.supportRequestID}\nDate Submitted: ${dayjs
        .utc(ticket.utcDataCreated)
        .local()
        .format('MM/DD/YYYY HH:mm')}\n\n`;
      const fullResponse = `${ticketDetails}${aiResponse}`;

      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.supportRequestID === ticketId
            ? { ...ticket, aiResponse: fullResponse, fetchingAIResponse: false }
            : ticket
        )
      );
    } catch (error) {
      console.error('Error generating AI response:', error);
      enqueueSnackbar('Failed to generate AI response.', { variant: 'error' });
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.supportRequestID === ticketId
            ? {
                ...ticket,
                aiResponse: 'Failed to generate AI response.',
                fetchingAIResponse: false,
              }
            : ticket
        )
      );
    }
  };

  const handleOpenDialog = (ticket) => {
    setCurrentTicket(ticket);
    setApprovedResponse(ticket.aiResponse || '');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentTicket(null);
    setApprovedResponse('');
  };

  const handleSendApprovedResponse = async () => {
    try {
      const updatedTicket = {
        ...currentTicket,
        notes: approvedResponse, // Store response in the 'notes' field
        status: 'Email Sent', // Set status to 'Email Sent'
        changed: true,
      };

      // Save the updated ticket using your existing save API
      await axios.post(saveUri, updatedTicket, { headers });

      enqueueSnackbar('Response sent successfully!', { variant: 'success' });

      // Remove the ticket from the list since it's now updated
      setTickets((prevTickets) =>
        prevTickets.filter(
          (ticket) => ticket.supportRequestID !== updatedTicket.supportRequestID
        )
      );

      handleCloseDialog();
    } catch (error) {
      console.error('Error sending response:', error);
      enqueueSnackbar('Failed to send response.', { variant: 'error' });
    }
  };

  return (
    <DivGuard groups={[userGroups.admins]}>
      <div>
        <h1>Support Admin</h1>
        {loading ? (
          <p>Loading support tickets...</p>
        ) : tickets.length > 0 ? (
          tickets.map((ticket) => (
            <div
              key={ticket.supportRequestID}
              style={{
                border: '1px solid #ccc',
                padding: '10px',
                marginBottom: '10px',
              }}
            >
              <p>
                <strong>From:</strong> {ticket.userName}
              </p>
              <p>
                <strong>Title:</strong> {ticket.title}
              </p>
              <p>
                <strong>Description:</strong> {ticket.description}
              </p>
              {ticket.aiResponse ? (
                <p>
                  <strong>AI Suggested Response:</strong>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{ticket.aiResponse}</pre>
                </p>
              ) : ticket.fetchingAIResponse ? (
                <p>Loading AI response...</p>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => fetchAIResponseForTicket(ticket.supportRequestID)}
                >
                  Fetch AI Response
                </Button>
              )}
              {ticket.aiResponse && (
                <Button
                  variant="contained"
                  onClick={() => handleOpenDialog(ticket)}
                  style={{ marginTop: '10px' }}
                >
                  Approve and Send
                </Button>
              )}
            </div>
          ))
        ) : (
          <p>No new support tickets.</p>
        )}

        {/* Dialog for editing and approving the response */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
        >
          <div style={{ padding: '20px' }}>
            <h2>Edit Response</h2>
            <TextField
              label="Response"
              multiline
              rows={10}
              fullWidth
              variant="outlined"
              value={approvedResponse}
              onChange={(e) => setApprovedResponse(e.target.value)}
            />
            <div style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button
                onClick={handleCloseDialog}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendApprovedResponse}
              >
                Send
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </DivGuard>
  );
};

export default AISupportTickets;
