import { ViewForm } from "./ViewForm";
import { Ribbon } from "../../Ribbon";
import { GridActionsGroup } from "./GridActionsGroup";

export const ForecastRibbon = ({ toolbarFormId, handleFetchData, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        formId={toolbarFormId}
                        handleFetchData={handleFetchData}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleFetchData}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}