export default (name, provider) => {
    let subclass = '';

    if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'AZPS') {
        subclass = 'SHORT_TERM';
    }    
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'CRCM') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'LAPT') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'BHBE') {
      subclass = 'SHORT_TERM';
    }
    else if(name === 'DLY NF NETWORK FULL_PERIOD FIXED' && provider === 'SWPP') {
        subclass = 'MIS'
    }
    else if(name === 'HLY NF NETWORK FULL_PERIOD FIXED' && provider === 'SWPP') {
        subclass = 'MIS';
    }
    else if(name === 'DLY F PTP FULL_PERIOD FIXED' && provider === 'AZPS') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'DLY F PTP FULL_PERIOD FIXED' && provider === 'PNM') {
        subclass = 'WITH ANCILLARY SRVCS';
    }
    else if(name === 'HLY F PTP FULL_PERIOD FIXED' && provider === 'PPW') {
      subclass = 'ST';
    }
    else if(name === 'HLY SECONDARY PTP FULL_PERIOD FIXED' && provider === 'SWPP') {
        subclass = 'CURRENT HOUR';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'TEPC') {
        subclass = 'TRANS';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'TEPC') {
        subclass = 'TRANS';
    }
    else if(name === 'HLY F PTP FULL_PERIOD FIXED' && provider === 'AZPS') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'BHBE') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'DLY F PTP FULL_PERIOD EXTENDED' && provider === 'BPAT') {
        subclass = 'MONTHLY';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'DGT') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'DGT') {
        subclass = 'STD';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED', provider === 'EPE') {
        subclass = 'WITH ANCILLARY SRVCS';
    }
    else if(name === 'HLY F PTP FULL_PERIOD FIXED' && provider === 'PNM') {
        subclass = 'WITH ANCILLARY SRVCS';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'PNM') {
        subclass = 'WITH ANCILLARY SRVCS';
    }
    else if(name === 'DLY F PTP FULL_PERIOD FIXED' && provider ===' PPW') {
        subclass = 'ST';
    }
    else if(name === 'DLY NF PTP FULL_PERIOD FIXED' && provider === 'PPW') {
        subclass = 'ST';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'TEPC') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'AZPS') {
        subclass = 'SHORT_TERM';
    }
    // else if(name === 'HLY F PTP FULL_PERIOD FIXED' && provider === 'BPAT') {
    //     subclass = 'LOSSES';
    // }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'EPE') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD EXTENDED' && provider === 'PNM') {
        subclass = 'SHORT_TERM';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'PPW') {
        subclass = 'ST';
    }
    else if(name === 'HLY NF PTP FULL_PERIOD FIXED' && provider === 'SWPP') {
        subclass = 'CURRENT HOUR';
    }

    return subclass;
}