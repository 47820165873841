import { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import { Box, } from '@mui/material';
import { useDashboard } from './useDashboard';
import Collapse from '../../FlexCollapse';

export const DashboardViewContainerLazy = ({ ViewComponent, }) => {
    const { views, dashboardKey, viewsRef, } = useDashboard();
    const [loadedViews, setLoadedViews] = useState({});
    const params = useParams();
    const currentViewId = params.id;
    const viewStorageKey = `${dashboardKey}-last-view`;

    useEffect(() => {
        if (currentViewId) {
            localStorage.setItem(viewStorageKey, currentViewId);

            if (!loadedViews[currentViewId]) {
                setLoadedViews(previouslyLoaded => ({
                    ...previouslyLoaded,
                    [currentViewId]: true
                }));
            }
        }
    }, [currentViewId, viewStorageKey]);

    return (
        <Box className="flex-column" >
            {views.map((view, i) => {
                return (
                    <Fragment
                        key={`${view.id}-${dashboardKey}-view`}
                    >
                        {loadedViews[view.id] && <Collapse
                            in={currentViewId === view.id.toString()}
                            orientation={'vertical'}
                        >
                            <ViewComponent
                                view={view}
                                ref={el => viewsRef.current[i] = el}
                            />
                        </Collapse>}
                    </Fragment>

                )
            })}
        </Box>
    );
}
