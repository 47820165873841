import { DashboardLazy } from "../Dashboard/DashboardLazy";
import { DealSummaryView } from "./DealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const DealSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'deal-summary';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={DealSummaryView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
