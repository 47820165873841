import { Box, Button, TextField, Dialog, DialogActions, DialogContent, Typography, IconButton, DialogTitle, } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from "axios";
import { apiUrlPrefix, } from '../authConfig';
import useHeader from './useHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, } from 'yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';

export default ({ open, handleClose }) => {
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const emailRef = useRef();

  const schema = object().shape({
    email: string().required('Please enter an email.'),
  });

  const { handleSubmit, formState: { errors, }, reset, register, } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  function handleAddEmail({ email }) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_emailDistributionInsert&parm=${encodeURIComponent(email)}`;

    handleSubmitToApi(url).then(() => {
      enqueueSnackbar(`Email ${email} added to distribution.`, { variant: 'success' });
      reset();
      emailRef.current.focus();
    }).catch(error => {
      enqueueSnackbar(error?.response?.data, { variant: 'error' });
    });
  }

  function handleRemoveEmail({ email }) {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_emailDistributionInactice&parm=${encodeURIComponent(email)}`;

    handleSubmitToApi(url).then(() => {
      enqueueSnackbar(`Email ${email} removed from distribution.`, { variant: 'success' });
      reset();
      emailRef.current.focus();
    }).catch(error => {
      enqueueSnackbar(error?.response?.data, { variant: 'error' });
    });
  }

  async function handleSubmitToApi(url) {
    const options = {
      headers: headers,
      url: url,
    }

    return axios(options);
  }

  function onClose() {
    reset();
    handleClose();
  }

  return (
    <Dialog fullWidth="false" maxWidth="md" open={open}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Add or Remove email from distribution</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={onClose} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form id='manage-email-distribution-form' onSubmit={handleSubmit(handleAddEmail)}>
          <TextField
            {...register('email')}
            inputRef={emailRef}
            autoFocus
            margin="dense"
            label="Email"
            fullWidth
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          form='manage-email-distribution-form'
          type='submit'
        >Add</Button>
        &nbsp;&nbsp;
        <Button
          form='manage-email-distribution-form'
          onClick={handleSubmit(handleRemoveEmail)}
        >Remove</Button>
      </DialogActions>
    </Dialog>
  )
}
