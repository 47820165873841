import { Dialog, DialogContent, Button, TextField, DialogTitle, DialogActions, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import useHeader from '../../useHeader';
import axios from 'axios';
import { apiUrlPrefix } from '../../../authConfig';

export default ({open, onCancel, onSubmit}) => {
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [aref, setAref] = useState();    
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  function handleSubmit() {
    if(!aref) { 
      setErrorText('Must not be blank.');
      setError(true); 
      return; 
    }   

    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_tsrFetchByAref&parm=${aref}`,
    }

    axios(options).then(response => {
      if(response.data && response.data[0]) {
        const tsr = response.data[0];
        onSubmit({ ...tsr, Preconfirmed: tsr.Preconfirmed === 'YES' })
        setError(false);
      } else {
        setErrorText('Unable to find TSR with matching ARef.');
        setError(true);
      }
    }).catch(error => {
      enqueueSnackbar(`Error loading data. ${error.message}`)
    });
  }

  return(
    <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open={open} maxWidth={'sm'} fullWidth>
      <DialogTitle id="duplicate-tsr-schedule-dialog">
        {"Type ARef of TSR to duplicate."}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Box sx={{p: 2}}>
          <TextField
            error={error}
            fullWidth
            helperText={error && errorText}
            onChange={e => setAref(e.target.value)}
            size="small"
            variant="outlined"
            label="ARef"
            color="success" 
            placeholder="ARef"
          />
        </Box>
        <DialogActions>
          <Button color="inherit" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Box sx={{ flexGrow: 1, }}/>
          <Button color="inherit" size="small" onClick={handleSubmit}>
            COPY
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}