import React, { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useRemoteLayout } from '../useRemoteLayout';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { DatabaseLayoutToolPanel } from "../ToolPanels/DatabaseLayoutToolPanel.js";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { Box, Button } from '@mui/material';
import FileUploaderDialog from './FileUploaderDialog';
import axios from "axios";
import { sideBar } from './bidmanUtilFunctions.js'
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useData } from '../useData';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import useHeader from '../useHeader';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default React.memo(BidmanErcotGrid);

function BidmanErcotGrid({ node, oprDay, refreshAll, triggerRefresh }) {

  const guid = useLocalGuid();
  const { nodeId, gridDataApiEndpoint, submitApiEndpoint, queryMarketApiEndpoint, submitMarketApiEndpoint } = node;
  const { rowData, getData } = useData();
  const { enqueueSnackbar } = useSnackbar();
  const [fileUploaderOpen, setFileUploaderDialogOpen] = useState(false);
  const gridRef = useRef();
  const header = useHeader();
  const [buttonsDisabled, setButtonsDisabled] = React.useState(true);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
  //       otherwise its only the uri portion (bhase url is gotten from global setting)
  //const loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  var loadDataUri = `${gridDataApiEndpoint}`;
  if (loadDataUri.includes('http')) {
    loadDataUri = `${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  } else {
    loadDataUri = `${apiUrlPrefix}/${gridDataApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&OprDay=${oprDay}`;
  }


  const handleFileUploaderDialogOpen = () => {
    setFileUploaderDialogOpen(true);
  };

  const handleFileUploaderDialogClose = () => {
    setFileUploaderDialogOpen(false);
    triggerRefresh({ market: node.market });
  };

  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  const onBtCopyRows = () => {
    gridRef.current.api.copySelectedRowsToClipboard();
  };

  const onBtCopyRange = () => {
    gridRef.current.api.copySelectedRangeToClipboard();
  };

  const onGetRowButtonClick = e => {
    const selectedNodes = gridRef.current.api.getSelectedNodes()
    const selectedData = selectedNodes.map(node => node.data)
    enqueueSnackbar(`Selected nodes: ${JSON.stringify(selectedData)}`)
  }

  const onGetGridDataButtonClick = e => {
    const rowData = [];
    gridRef.current.api.forEachNode(node => rowData.push(node.data));
    alert(`Selected nodes: ${JSON.stringify(rowData)}`)
    enqueueSnackbar('JSON generated');
  }

  async function handleSaveButtonClick() {

    gridRef.current.api.stopEditing();

    //ecz 6/30/22 remove row tooltip and cell classes before sending to db
    const flattened = [];
    gridRef.current.api.forEachLeafNode(node => {
      const data = node.data;
      const flatData = {};
      const cols = Object.keys(data);
      cols.forEach(col => {
        const num = parseInt(col.match(/\d+/)); //parse the column field for an int
        if (num) {
          flatData[`HE${num}_Q`] = data[col].Quantity?.value;
          flatData[`HE${num}_P`] = data[col].Price?.value;
          flatData[`HE${num}_MB`] = data[col].MB?.value;
          flatData[`HE${num}_CS`] = data[col].CS?.value;
        }
        else {
          flatData[col] = data[col];
        }
      });
      flattened.push(flatData);
      //return flatData;
    });

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`
    } else {
      url = `${apiUrlPrefix}/${submitApiEndpoint}userGuid=${guid}&nodeId=${nodeId}`;
    }


    axios.post(
      url,
      JSON.stringify(flattened),
      {
        headers: header,
      }
    ).then(response => {
      let message = 'Bids saved to database.';
      if (response.data !== 'Success') {
        message = response.data;
      } else {
        setDisableSaveButton(true);
      }
      enqueueSnackbar(message);
      triggerRefresh({ market: node.market });
    }).catch(err => enqueueSnackbar(`Error saving ERCOT grid.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`));
  }

  async function HandleQueryMarketButtonClick() {
    gridRef.current.api.stopEditing();
    gridRef.current.api.showLoadingOverlay();

    //mts, reference full path if defined as such (backwards compatibility), otherwise use global url prefix
    var url = `${queryMarketApiEndpoint}`;
    if (url.includes('http')) {
      url = `${queryMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    } else {
      url = `${apiUrlPrefix}/${queryMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    }

    const options = {
      method: 'GET',
      headers: header,
      url: url,
    }

    const response = await axios(options).then(response => {
      if (response.data === 'Success') {
        enqueueSnackbar('Bids queried from Market.');
      } else {
        enqueueSnackbar(response.data);
      }
    }).catch(err => { enqueueSnackbar(`Error querying bids from market.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`) });


    enqueueSnackbar('Refreshing grid data...');
    //fetch();
    triggerRefresh({ market: node.market });
  }

  async function HandleSubmitMarketButtonClick() {
    gridRef.current.api.stopEditing();
    gridRef.current.api.showLoadingOverlay();

    //mts -- for backwards compatibility handle case where entire url is specified in bidman tree table.
    //       otherwise its only the uri portion (bhase url is gotten from global setting)
    var url = `${submitApiEndpoint}`;
    if (url.includes('http')) {
      url = `${submitMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    } else {
      url = `${apiUrlPrefix}/${submitMarketApiEndpoint}userGuid=${guid}&nodeId=${nodeId}&oprDay=${oprDay}`;
    }

    const options = {
      method: 'GET',
      headers: header,
      url: url,
    }

    const response = await axios(options).then(response => {
      if (response.data === 'Success') {
        enqueueSnackbar('Bids submitted to Market.');
      } else {
        enqueueSnackbar(response.data);
      }
    }).catch(err => { enqueueSnackbar(`Error submitting bids to market.  NodeId: ${nodeId} Status: ${err.response?.status}. Message: ${err}`) });

    enqueueSnackbar('Refreshing grid data...');
    //fetch();
    triggerRefresh({ market: node.market });
  }



  // enables the fill handle
  const enableFillHandle = true;

  // enables undo / redo
  const undoRedoCellEditing = true;

  // restricts the number of undo / redo steps to 20
  const undoRedoCellEditingLimit = 20;

  // enables flashing to help see cell changes
  const enableCellChangeFlash = true;

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const denseGrid = 'dense-grid';
  const hourCells = 'hour-cells';

  const groupDefaultExpanded = -1;

  const onRefreshBtn = () => {
    enqueueSnackbar('Refreshing grid data...');
    fetch();
  };

  const rowClassRules = {
    //'row-fail': 'data.rowClass === "problem-row"',
    'row-fail': (params) => {
      return params.data?.rowClass === 'problem-row';
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    fetch();
    return () => {
      abortController.abort();
    }
  }, [oprDay]);

  useEffect(() => {
    if (refreshAll.market === node.market) { fetch(); }
  }, [refreshAll]);

  function editableCheck(params) {
    if (params.data.Version === "Scheduled") {
      return true; // true/false based on params (or some other criteria) value
    } else {
      return false;
    }
  }

  function fetch() {
    gridRef.current.api?.showLoadingOverlay();
    setButtonsDisabled(true);

    getData(loadDataUri).then(response => {
      setButtonsDisabled(false);
      setDisableSaveButton(true);
      gridRef.current && gridRef.current.api.hideOverlay();
    })
  };

  function cellRenderer(params) {
    if (params.value) {
      const col = params.colDef.field.split('.'); //the field will look like HE5.Quantity.value.
      const cellClass = params.node.data[col[0]][col[1]]['cellClass']; //use the column def to parse the row data for the cell class
      return <div style={{ verticalAlign: 'middle', display: 'inline', }} className={cellClass === 'problem-cell' ? 'row-fail' : 'row-pass'}>
        {` ${params.value}`}
      </div>
    } else return null;
  }

  const colDefs = useMemo(() => [
    {
      editable: false,
      headerName: "Key",
      enableRowGroup: true,
      field: "Header_Id",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Day",
      enableRowGroup: true,
      field: "OprDay",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "QSE",
      enableRowGroup: true,
      field: "QSE",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Location",
      enableRowGroup: true,
      initialHide: true,
      field: "Location",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Version",
      enableRowGroup: true,
      rowGroup: true,
      field: "Version",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Status",
      enableRowGroup: true,
      field: "Status",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Source",
      enableRowGroup: true,
      field: "Source",
      sortable: true,
      filter: true,
    },
    {
      editable: false,
      headerName: "Sink",
      enableRowGroup: true,
      field: "Sink",
      sortable: true,
      filter: true,
    },
    {
      suppressMenu: true,
      editable: false,
      headerName: "Expiration",
      field: "ExpirationDateTime_UTC",
      sortable: true,
      enableRowGroup: true,
      filter: true,
    },
    {
      suppressMenu: true,
      editable: false,
      headerName: "TotalQty",
      field: "TotalQty",
      sortable: true,
      enableRowGroup: true,
      filter: true,
    },
    ...[...Array(25).keys()].map(function (i) {
      i++; //1 based index, no HE0,
      const header = `HE${i}`;
      return (
        {
          cellClass: hourCells, suppressMenu: true, editable: editableCheck, headerName: header, field: header, itialWidth: 2,
          children: [
            { cellClass: hourCells, suppressMenu: true, editable: editableCheck, headerName: "Q", initialWidth: 2, field: `${header}.Quantity.value` },
            { cellClass: hourCells, suppressMenu: true, editable: editableCheck, headerName: "P", initialWidth: 2, field: `${header}.Price.value` },
            { cellClass: hourCells, suppressMenu: true, cellRenderer: cellRenderer, editable: editableCheck, headerName: "CS", initialWidth: 2, field: `${header}.CS.value` },
            { cellClass: hourCells, suppressMenu: true, cellRenderer: cellRenderer, editable: editableCheck, headerName: "MB", initialWidth: 2, field: `${header}.MB.value` }
          ]
        }
      )
    })
  ], []);

  function updateColDefs(defs) {
    defs.forEach(def => {
      const num = parseInt(def.field.match(/\d+/)); //parse the column field for an int
      if (num) {
        updateDef(def);
      }
    });

    return (defs);
  }

  function updateDef(def) {
    def.cellRenderer = cellRenderer;
    def.editable = (params) => editableCheck(params);
    if (def.children) {
      def.children.forEach(child => updateDef(child));
    }
  };


  const { dbLayoutPanel, loadLayout } = useRemoteLayout(gridRef, nodeId, colDefs, updateColDefs);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        dbLayoutPanel,
      ],
      position: 'right',
    }
  }, []);

  function onCellValueChanged(params) {
    setDisableSaveButton(false);
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: false,
    flex: 1,
    sortable: false,
    resizable: true,
    tooltipField: "rowToolTip"
  }), [])

  function onGridReady(params) {
    loadLayout(params.api);
  }

  return (
    <div>
      <AgGridContainer
        style={{ height: "85vh", width: "100%", fontSize: '10px' }}
      >
        <FileUploaderDialog
          fileUploaderOpen={fileUploaderOpen}
          handleFileUploaderDialogClose={handleFileUploaderDialogClose}
          oprDay={oprDay}
          nodeId={nodeId}
        />
        <Box sx={{ display: 'flex', p: 1 }}>
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={onRefreshBtn}
          >Refresh</Button>&nbsp;
          <Button
            onClick={() => onBtCopyRows()}
            endIcon={<ContentCopyOutlinedIcon />}
            id="copyBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >Ctrl-c</Button>&nbsp;
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineOutlinedIcon />}
            id="undoBtn"
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={() => onBtCopyRange()}
          >
            Copy Range
          </Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={HandleQueryMarketButtonClick}>QUERY BIDS</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={HandleSubmitMarketButtonClick}>SUBMIT BIDS</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            onClick={handleFileUploaderDialogOpen}
          >IMPORT BIDS</Button>&nbsp;
          <Button
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
            disabled={buttonsDisabled || disableSaveButton}
            onClick={handleSaveButtonClick}
          >Save All</Button>&nbsp;
        </Box>

        <AgGridReact
          style={{ width: '100%', height: '100%;' }}
          ref={gridRef}
          rowData={rowData}
          sideBar={sideBar}
          rowClassRules={rowClassRules}
          onCellValueChanged={onCellValueChanged}
          rowSelection="multiple"
          enableFillHandle={enableFillHandle}
          undoRedoCellEditing={undoRedoCellEditing}
          undoRedoCellEditingLimit={undoRedoCellEditingLimit}
          enableCellChangeFlash={enableCellChangeFlash}
          onGridReady={onGridReady}
          groupDefaultExpanded={groupDefaultExpanded}
          suppressCopyRowsToClipboard={true}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          rowGroupPanelShow={'always'}
          enableRangeSelection={true}
          groupSelectsChildren={true}
          components={{
            dbLayoutToolPanel: DatabaseLayoutToolPanel
          }}
        />

      </AgGridContainer>
    </div>
  );


};

// export default function BidmanGridExport({ nodeId, gridDataAPIendpoint }) {
//   return <BidmanGrid />;
// }

