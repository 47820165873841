import React, { useEffect, useState, useRef } from 'react';
//import { DataGridPro, GridToolbar, LicenseInfo, useGridApiRef } from '@mui/x-data-grid-pro';

/*LicenseInfo.setLicenseKey(
  '6ea91d57b278a973ebe74f3782caaf67T1JERVI6MzA4OTcsRVhQSVJZPTE2NjU3OTEyNTAwMDAsS0VZVkVSU0lPTj0x',
);*/

export const ProfileGrid = ({ profile, startDate, stopDate, MW, bidPrice, timezone }) => {
  //const apiRef = useGridApiRef();

  //gives single cell click functionality
  const handleCellClick = React.useCallback(
    (params) => {
      //apiRef.current.setCellMode(params.id, params.field, "edit");
    },
    []//apiRef]
  );

  //prevents a bug caused by the callback in handleCellClick: see here: https://github.com/mui-org/material-ui-x/issues/2186
  useEffect(() => {
    /*return apiRef.current.subscribeEvent(
      'cellModeChange',
      (params, event) => {
        event.defaultMuiPrevented = true;
      },
      { isFirst: true },
    );*/
  }, []);

  //set up rows
  const rows = profile.hours.map((hour) => {
    return {
      id: hour,
      startDate: startDate,
      stopDate: stopDate,
      timeZone: timezone,
      hourEnding: hour,
      MW: MW,
      Price: bidPrice
    }
  });

  //set up columns
  const columns = [
    { field: 'id', headerName: 'Id', hide: true },
    { field: 'startDate', headerName: 'Start Date', editable: false, type: 'date', sortable: true, disableColumnMenu: true },
    { field: 'stopDate', headerName: 'Stop Date', editable: false, type: 'date', sortable: true, disableColumnMenu: true },
    { field: 'timeZone', headerName: 'TZ', editable: true, type: 'singleSelect', valueOptions: ['PST', 'MST', 'CST', 'EST'], sortable: true, disableColumnMenu: true },
    { field: 'hourEnding', headerName: 'HE', editable: false, sortable: true, disableColumnMenu: true },
    { field: 'MW', headerName: 'MW', editable: false, type: 'number', sortable: true, disableColumnMenu: true },
    { field: 'Price', headerName: 'Price', editable: false, type: 'number', sortable: true, disableColumnMenu: true },
  ];

  return (
    <div>
      {/*<DataGridPro
        showPagination={false}
        hideFooter={true}
        rows={rows} 
        columns={columns}
        apiRef={apiRef}
        onCellClick={handleCellClick}
        //className={classes.bidmanRoot}
        density="compact"
        // components={{
        //   Toolbar: GridToolbar,
        // }}
        // checkboxSelection
        // disableSelectionOnClick
      />*/}

    </div>
  );
};
