import { Dialog, DialogContent, DialogActions, Button, TextField, DialogContentText, DialogTitle } from "@mui/material"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, } from 'yup';
import { useEffect } from "react";

const AddLabelDialog = (props) => {
  const { open, handleClose, handleSave, title, contentText, fieldName, } = props;

  const schema = object().shape({
    label: string().required(`${fieldName ?? 'Label'} is required`).max(50, `${fieldName ?? 'Label'} must be 50 characters or less`).min(3, `${fieldName ?? 'Label'} must be at least 3 characters`),
  })

  const { register, handleSubmit, formState: { errors, }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset();
  }, [open])

  return (
    <Dialog open={open} maxWidth='md'>
      <DialogTitle>{title ?? 'Add Label'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText ?? 'Enter a label for this view.'}
        </DialogContentText>
        <form onSubmit={handleSubmit(handleSave)} id="addDashboardLabelForm">
          <TextField
            {...register('label')}
            autoFocus
            margin="dense"
            label={`${fieldName ?? 'Label'}`}
            type="text"
            fullWidth
            error={!!errors.label}
            helperText={errors.label?.message}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Button onClick={handleClose} variant="contained">Cancel</Button>
        <Button type="submit" form='addDashboardLabelForm' variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddLabelDialog;