import React, { useMemo, useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useData } from '../useData';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Button from '@mui/material/Button';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import GridViewToolPanel from '../ToolPanels/GridViewToolPanel';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import PublishIcon from '@mui/icons-material/Publish';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useViewPanel } from '../ToolPanels/useViewPanel';
import useHeader from '../useHeader';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
  const guid = useLocalGuid();
  const dataUri = `${apiUrlPrefix}/CrystalBall/ActiveDirectory/ListUsers?userGuid=${guid}`;
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const grid = "users";
  const { rowData, getData } = useData();
  const headers = useHeader();

  const { enqueueSnackbar } = useSnackbar();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  const onRefreshBtn = () => {
    getData(dataUri);
    let message = 'User List Refreshing...';
    enqueueSnackbar(message);
  };

  useEffect(() => {
    //getData();
    let abortController = new AbortController();
    // your async action is here  
    getData(dataUri);
    return () => {
      abortController.abort();
    }
  }, []);

  function onCellEditingStarted(e) {
    setButtonsDisabled(false);
  }

  const onBtnExport = () => {
    ref.current.api.exportDataAsCsv();
  };

  const gridOptions = useMemo(() => {
    return {
      rowClassRules: {
        "row-fail": params => params.api.getValue("Status", params.node) === 'REFUSED',
        "row-pass": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
        "row-waiting": params => params.api.getValue("Status", params.node) === 'QUEUED'
      },
    }
  }, []);

  const colDefs = useMemo(() => [
    {
      editable: false,
      checkboxSelection: true,
      rowDrag: true,
      width: "170",
      initialHide: "false",
      cellRenderer: 'agGroupCellRenderer',
      headerName: "Guid",
      field: "Guid",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Name",
      enableRowGroup: true,
      width: "60",
      initialHide: "false",
      field: "DisplayName",
      filter: "agMultiColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "User Name",
      enableRowGroup: true,
      width: "100",
      initialHide: "false",
      field: "Mail",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: false,
      headerName: "Principal Name",
      enableRowGroup: true,
      width: "100",
      initialHide: "true",
      field: "UserPrincipalName",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },  //"tenantId":"2","firstName":"Tyler","lastName":"Wezenberg","phone":"","userName":"twezenberg@teainc.org"
    {
      editable: true,
      headerName: "Tenant ID",
      enableRowGroup: true,
      width: "30",
      initialHide: "true",
      field: "tenantId",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: true,
      headerName: "First Name",
      enableRowGroup: true,
      width: "100",
      initialHide: "true",
      field: "firstName",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: true,
      headerName: "Last Name",
      enableRowGroup: true,
      width: "100",
      initialHide: "true",
      field: "lastName",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
    {
      editable: true,
      headerName: "User Name",
      enableRowGroup: true,
      width: "100",
      initialHide: "true",
      field: "userName",
      filter: "agMultiColumnFilter",  // filter: "agSetColumnFilter",
      sortable: true,
    },
  ], []);

  const { viewPanel, loadLayoutLocal, saveLayoutLocal } = useViewPanel(grid, ref, 'users-ag-layout', colDefs, true);

  const usersSideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
        viewPanel,
      ],
      position: 'right',
    }
  }, [viewPanel]);

  async function onSaveDataButtonClick() {
    ref.current.api.stopEditing();
    ref.current.api.showLoadingOverlay();
    setButtonsDisabled(true);

    let message = 'Submitting users information to database...';
    enqueueSnackbar(message);

    const rowData = [];
    ref.current.api.forEachNode(function (node) {
      const newData = node.data
      newData['isSelected'] = node.isSelected();
      rowData.push(newData);
    })

    axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UsersSave&parm=${guid}`,
      JSON.stringify(rowData),
      {
        headers: headers,
      }
    ).then(response => {
      message = 'Users sent to Power Station database...';
      enqueueSnackbar(message);
      getData(dataUri);
    }).catch(err => enqueueSnackbar(`Status: ${err.response?.status}. Message: ${err}`));

    ref.current.api.hideOverlay();
  }

  function onGridReady(params) {
    loadLayoutLocal();
  }

  const defaultColDef = useMemo(() => ({
    editable: false,
    filter: true,
    floatingFilter: true,
    sortable: true,
    resizable: true,
  }), [])

  return (
    <AgGridContainer
      style={{
        height: '90vh',
        width: "100%"
      }}
    >
      <Box sx={{ display: 'flex', p: 1 }}>
        <Tooltip title="Refresh the user list." arrow placement="top">
          <Button
            endIcon={<RefreshOutlinedIcon />}
            id="refresh"
            size={buttonSize}
            variant={buttonVariant}
            color={buttonColor}
            onClick={() => onRefreshBtn()}
          >Refresh</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
          <Button
            onClick={() => onBtnExport()}
            endIcon={<DownloadForOfflineIcon />}
            id="csv"
            //disabled={buttonsDisabled}
            variant={buttonVariant}
            color={buttonColor}
            size={buttonSize}
          >CSV</Button>
        </Tooltip>&nbsp;
        <Tooltip title="Save all information." arrow placement="top">
          <Button
            endIcon={<PublishIcon />}
            variant={buttonVariant}
            color={buttonColor}
            disabled={buttonsDisabled}
            size={buttonSize}
            onClick={onSaveDataButtonClick}>Save All</Button>
        </Tooltip>&nbsp;
      </Box>
      <AgGridReact
        ref={ref}
        onGridReady={onGridReady}
        rowData={rowData}
        enableFillHandle={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        enableCellChangeFlash={true}
        groupDefaultExpanded={true}
        defaultColDef={defaultColDef}
        sideBar={usersSideBar}
        gridOptions={gridOptions}
        onCellEditingStarted={onCellEditingStarted}
        animateRows={true}
        rowDragMultiRow={true}
        rowSelection='multiple'
        enableRangeSelection={true}
        suppressRowClickSelection={true}
        tooltipShowDelay={0}
        components={{
          viewToolPanel: GridViewToolPanel,
        }}
      />
    </AgGridContainer>
  );
});