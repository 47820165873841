import { forwardRef, useContext, useState, useRef } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { Switch, Tooltip, Box, Grid, Autocomplete, TextField, FormGroup, FormControlLabel } from "@mui/material"
import { Toolbar, Button,  } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { useSnackbar } from "notistack";
import { apiUrlPrefix, userGroups } from "../../../authConfig";
import GroupGuard from "../../Guards/GroupGuard";
import EditCriteriaDialog from "./EditCriteriaDialog";
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from "../ConfirmationDialog";
import useHeader from "../../useHeader";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import axios from "axios";

export default (props) => {
  const { onRefresh, clearSelected, selectedRows, disableAllButtons, view, handleTriggerClick, autoRefresh, setAutoRefresh, handleOverwriteCriteria, } = props;
  const [openEditCriteriaDialog, setOpenEditCriteriaDialog] = useState(false);
  const tempDisableAutoRefresh = useRef(false); //prevent the grid from autorefreshing while editing criteria
  const [actOnConfirm, setActOnConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();

  function handleSaveCriteria(criteria) {
    setOpenEditCriteriaDialog(false);
    handleOverwriteCriteria(criteria);
    setAutoRefresh(!!tempDisableAutoRefresh.current);
  }

  function handleEditCriteria() {
    tempDisableAutoRefresh.current = autoRefresh;
    setAutoRefresh(false);
    setOpenEditCriteriaDialog(true);
  }

  function handleCancelEdit() {
    setOpenEditCriteriaDialog(false);
    setAutoRefresh(!!tempDisableAutoRefresh.current)
  }

  function handleConfirmAction(action) {
    const { AssignmentRef } = selectedRows[0];
    const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_ConfirmApplicationAction&parm=${guid}&parm=${AssignmentRef}&parm=${action}&parm=spymonkey&parm=`;

    const options = {
      method: 'GET',
      headers: headers,
      url: uri,
    }
  
    axios(options).then(response => {
      if(response.data && response.data[0]) {
        const { goForward, userMessage } = response.data[0];
        setActOnConfirm(!!goForward);
        setConfirmationMessage(userMessage);
        setConfirmationAction(action);
        setOpenConfirmationDialog(true);
      } else {
        enqueueSnackbar('Error- api fetch returned no data.')
      }
    }).catch(error => {
      enqueueSnackbar(`Error confirming TSR. ${error.message}`)
    });
  }

  function handleConfirmation() {
    const { AssignmentRef, Provider } = selectedRows[0];
    const uri = `${apiUrlPrefix}/CrystalBall/TSR/${confirmationAction}?userGuid=${guid}&aref=${AssignmentRef}&primaryProvider=${Provider}`;

    const options = {
      headers: headers,
      url: uri,
    }
  
    axios(options).then(response => {
      enqueueSnackbar(`Success`)
      setOpenConfirmationDialog(false);
    }).catch(error => {
      enqueueSnackbar(`${error.message}`)
      setOpenConfirmationDialog(false);
    });
  }

  return(
    <Box sx={{display: 'flex', p:1, paddingTop:2 }}>
      <EditCriteriaDialog 
        open={openEditCriteriaDialog}
        view={view}
        handleSave={handleSaveCriteria}
        handleCancel={handleCancelEdit}
      />
      <ConfirmationDialog 
        open={openConfirmationDialog} 
        message={confirmationMessage}
        onCancel={() => setOpenConfirmationDialog(false)}
        onConfirmation={handleConfirmation}
        confirmText={actOnConfirm ? 'YES' : 'OK'}
        singleAction={!actOnConfirm}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          {/*<Tooltip title={`Quickly duplicate and schedule a TSR.`} arrow placement='top'>
            <Button
              endIcon={<ContentCopyIcon />} 
              size={'small'}
              variant={'contained'} 
              color={'primary'}
              onClick={handleSubmitDuplicateClick}
              disabled={!enableOnSelection || disableAllButtons}
            >Submit Duplicate TSR</Button>
          </Tooltip>&nbsp;&nbsp;*/}
          <GroupGuard groups={[userGroups.triggeredsubmission]}>
            <Tooltip title='Trigger a TSR when selected TSRs have a status change.' arrow placement="top">
              <Button
                endIcon={<ChangeHistoryIcon />} 
                size={'small'}
                variant={'contained'} 
                color={'primary'}
                onClick={handleTriggerClick}
                disabled={!selectedRows.length || disableAllButtons}
              >Trigger on Status Change</Button>
            </Tooltip>&nbsp;&nbsp;
          </GroupGuard>
          <Button
            endIcon={<CheckIcon />} 
            size={'small'}
            variant={'contained'} 
            color={'primary'}
            onClick={() => handleConfirmAction('confirm')}
            disabled={!(selectedRows.length === 1) || disableAllButtons}
          >CONFIRM</Button>&nbsp;&nbsp;
          <Button
            endIcon={<UndoIcon />} 
            size={'small'}
            variant={'contained'} 
            color={'primary'}
            onClick={() => handleConfirmAction('withdraw')}
            disabled={!(selectedRows.length === 1) || disableAllButtons}
          >WITHDRAW</Button>
        </Grid>
        <Grid item xs={6} container justifyContent='flex-end'>
          <FormGroup>
            <FormControlLabel control={<Switch checked={autoRefresh} onChange={() => setAutoRefresh(!autoRefresh)}/>} label="Auto-refresh" />
          </FormGroup>&nbsp;&nbsp;
          <Tooltip title='Reload data with the current filter criteria.' arrow placement="top">
            <Button
              endIcon={<RefreshOutlinedIcon />} 
              size={'small'}
              variant={'contained'} 
              color={'primary'}
              onClick={onRefresh}
              disabled={disableAllButtons}
            >Refresh</Button>
          </Tooltip>&nbsp;&nbsp;
          <Tooltip title='Deselect all rows in the grid.' arrow placement="top">
            <Button
              endIcon={<RemoveDoneIcon />} 
              size={'small'}
              variant={'contained'} 
              color={'primary'}
              onClick={clearSelected}
              disabled={!selectedRows.length || disableAllButtons}
            >Clear Selected</Button>
          </Tooltip>&nbsp;&nbsp;
          <Tooltip title='Set the current filter criteria as default.' arrow placement='top'>
            <Button
              endIcon={<EditIcon />} 
              size={'small'}
              variant={'contained'} 
              color={'primary'}
              onClick={handleEditCriteria}
              disabled={disableAllButtons}
            >Edit View</Button>
          </Tooltip>&nbsp;&nbsp;
        </Grid>
      </Grid> 
    </Box>
  )
}