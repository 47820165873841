import React, { useRef, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import EmailEditor from 'react-email-editor';
import sample from './Settings/sample.json';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey } from '../authConfig';
import { useSnackbar } from 'notistack';
import { useLocalGuid } from '../data/UserGuidContext/useLocalGuid';
import Tooltip from '@mui/material/Tooltip';
import { MenuItem, TextField, } from "@mui/material";
import { Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import useHeader from './useHeader';
import { styled } from '@mui/material/styles';
  
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
}));

const Bar = styled('div')(({ theme }) => ({
  flex: 1,
  padding: 10,
  display: 'flex',
  maxHeight: 60,
  h1: {
    flex: 1,
    fontSize: 16,
    textAlign: 'left',
  },
  div: {
    width: 200,
  },
  button: {
    flex: 1,
    padding: 10,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: 'bold',
    border: 0,
    maxWidth: 150,
    cursor: 'pointer',
  }
}));

export default () => {
  const editorRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('Sample');
  const [templateDesign, setTemplateDesign] = useState(sample);
  const [templateHtml, setTemplateHtml] = useState('');
  const [openSaveCopyDialog, setOpenSaveCopyDialog] = useState(false);
  const [copyName, setCopyName] = useState('');
  const header = useHeader();
  const availableApps = ['SpyMonkey', 'TransHunter', 'TransNow', 'FastPath', 'Release Notes'];
  const [appName, setAppName] = useState(availableApps[0]);
  
  const {enqueueSnackbar} = useSnackbar();
  
  const guid = useLocalGuid();

  const buttonVariant = "contained";
  const buttonColor = "primary";
  const buttonSize = "small";

  useEffect(() => {
    loadTemplates();
  }, [appName]);

  async function loadTemplates() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_HelpTopicFetch&parm=${guid}&parm=${appName}`
    
    let options = {
      headers: header,
      method: 'GET',
      url: url, 
    };

    const temps = await axios(options).catch(err => { enqueueSnackbar(`Error loading groups.  URL: ${url} Error:  ${err}`) });

    setTemplates(temps.data ?? []);
  };

  function handleSaveCopy() {
    saveDesign(copyName);
    setOpenSaveCopyDialog(false);
  }

  const saveDesign = (name=templateName) => {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      
      saveTemplateJson(design, name);
      saveTemplateHtml(html, name);
      setTemplateHtml(html);
      setTemplateDesign(design);

    });

    loadTemplates();
  };

  const exportHtml = () => {
    editorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      setTemplateHtml(html);
      setTemplateDesign(design);
      navigator.clipboard.writeText(html)
      enqueueSnackbar('HTML copied to clipboard...');
    });
  };

  const onLoad = () => {
    if (editorRef.current) {
      editorRef.current.editor.loadDesign(templateDesign);
      editorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        setTemplateHtml(html);
      });
    }
  }

  async function saveTemplateHtml(html, name=templateName) {

    let message = 'Submitting template HTML...';
    enqueueSnackbar(message);
  
    message = 'Template HTML sent to power station database...';
    
    axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_HelpTopicHtmlSave&parm=${guid}&parm=${name}&parm=${appName}`,
      html,
      {
        headers: header,
      }
    ).then(response => {
      enqueueSnackbar(message);
    }).catch(err => function() { message = `Error saving template html... Status: ${err.response?.status}. Message: ${err}`});
  }

  async function saveTemplateJson(design, name=templateName) {

    let message = 'Submitting template JSON...';
    enqueueSnackbar(message);
  
    message = 'Template JSON sent to power station database...';
    
    axios.post(
      `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_HelpTopicJsonSave&parm=${guid}&parm=${name}&parm=${appName}`,
      JSON.stringify(design),
      {
        headers: header,
      }
    ).then(response => {
      enqueueSnackbar(message);
    }).catch(err => function() { message = `Error saving email template json... Status: ${err.response?.status}. Message: ${err}`});
  }

  function handleTemplateChange(temp) {
    setTemplateDesign(temp.json);
    setTemplateName(temp.name);
 
    editorRef.current.editor.loadDesign(JSON.parse(temp.json));
  }

  function handleTemplateNameChange(event) {
    setTemplateName(event.target.value);

    editorRef.current.editor.saveDesign((design) => {
      setTemplateDesign(design);
    });
    
  }

  return (
    <div style={{
      height: '90vh', 
      width: "98%"}}>
    <Container sx={{height: '100%'}}>
      <Bar>
        <h1>{`${appName} Help Composer`}</h1>
         <Autocomplete
            id="Application"
            fullWidth
            options={availableApps}
            value={appName}
            autoHighlight
            freeSolo={true}
            disableClearable={true}
            onChange={(event, newValue) => {
              setAppName(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                //variant="contained"
                label="Application"
                //color="primary" 
                placeholder="Application"
              />
            )}
          />&nbsp;&nbsp;
        <Tooltip title="Select a template." arrow placement="top">
          <Select
            labelId="templateLabel"
            id="template"
            width="100px"
            defaultValue={templateName}
            size={buttonSize}
            color={buttonColor}
            label="Saved Templates"
          >
            {templates.map(temp => 
              <MenuItem onClick={() => handleTemplateChange(temp)}>{temp.name}</MenuItem>
            )}
            <MenuItem onClick={() => handleTemplateChange({name: 'Sample', json: JSON.stringify(sample)})}>Sample</MenuItem>
          </Select>
        </Tooltip>&nbsp;&nbsp;
        <Tooltip title="Select an template." arrow placement="top">
          <TextField
            id="templateName"
            width="100px"
            value={templateName}
            size={buttonSize}
            onChange={handleTemplateNameChange}
            color={buttonColor}
            label="Template"
          />
        </Tooltip>&nbsp;
        <Button onClick={(e) => saveDesign()}>Save Design</Button>
        <Button onClick={() => setOpenSaveCopyDialog(true)}>Save Copy</Button>
        <Button onClick={exportHtml}>Export HTML</Button>
      </Bar>

        <EmailEditor ref={editorRef} onLoad={onLoad} />
      
    </Container>
    <Dialog maxWidth="md" open={openSaveCopyDialog}>
        <DialogTitle>Save Design Copy</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Please enter a name for the copied template.</p>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={(e) => setCopyName(e.target.value)}
            id="templatecopyname"
            label="Name of Copy"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSaveCopyDialog(false)}>Cancel</Button>
          <Button onClick={handleSaveCopy}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};