import { useEffect, useState, useRef, useMemo } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import { userGroups } from "../../authConfig";
import DivGuard from '../Guards/DivGuard.js';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import { useGridButtons } from '../useGridButtons.js';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels'
import { AgGridReact } from 'ag-grid-react';
import useGridLayout from '../useGridLayout';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import { useData } from '../useData';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

const UserGroups = () => {
  const guid = useLocalGuid();
  const gridRef = useRef();
  const storageLocation = 'powerstation-user-groups-grid-layout'
  const { rowData, getData, saveData } = useData();
  const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserGroupsFetch&parm=${guid}`;

  const { RedoButton, UndoButton, ExportButton, CopyRowsButton, RefreshButton, NewRowButton, SaveButton } = useGridButtons({ gridRef });

  const onRefreshBtn = () => {
    gridRef.current.api.showLoadingOverlay();
    getData(loadUri);
  };

  useEffect(() => {
    let abortController = new AbortController();
    // your async action is here  
    getData(loadUri);
    return () => {
      abortController.abort();
    }
  }, []);

  async function handleSaveButtonClick() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserGroupsSave&parm=${guid}`;
    const message = 'User Groups saved to database.';
    gridRef.current.api.stopEditing();
    saveData(url, message);
  }

  const baseColDefs = [
    {
      editable: false,
      headerName: "ID",
      headerCheckboxSelection: true,
      field: "userGroupsID",
    },
    {
      editable: true,
      headerName: "Group ID",
      initialHide: true,
      field: "powerStationGroupID",
    },
    {
      editable: true,
      headerName: "Group Name",
      enableRowGroup: true,
      field: "groupName",
      sortable: true,
      filter: true,
    },
    {
      editable: true,
      headerName: "Guid",
      initialHide: false,
      field: "userGuid",
    },
    {
      editable: true,
      headerName: "User Name",
      initialHide: false,
      field: "userName",
    },
  ];

  const { colDefs, loadLayout, layoutPanel } = useGridLayout(storageLocation, gridRef, baseColDefs);

  const sideBar = {
    toolPanels: [
      columnPanel,
      filterPanel,
      layoutPanel,
    ],
    position: 'right',
  };

  const defaultColDef = useMemo(() => ({
    editable: true,
    filter: true,
    sortable: false,
    resizable: true,
  }), [])

  function onGridReady(params) {
    loadLayout();
  }

  return (
    <DivGuard groups={[userGroups.usergroupsview]} >
      <AgGridContainer
        style={{
          height: "88vh",
          width: "100%"
        }}
      >
        <Box sx={{ display: 'flex', p: 1 }}>
          <Tooltip title="Refresh the list of user groups." arrow placement="top">
            <RefreshButton onClick={onRefreshBtn} />
          </Tooltip>&nbsp;
          <Tooltip title="Undo the last edit made." arrow placement="top">
            <UndoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Redo the last edit made." arrow placement="top">
            <RedoButton />
          </Tooltip>&nbsp;
          <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
            <CopyRowsButton />
          </Tooltip>&nbsp;
          <Tooltip title="Add a new blank row to the grid." arrow placement="top">
            <NewRowButton />
          </Tooltip>&nbsp;
          <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
            <ExportButton />
          </Tooltip>&nbsp;
          <SaveButton onClick={handleSaveButtonClick} />&nbsp;
        </Box>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          onGridReady={onGridReady}
          suppressRowClickSelection
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          enterMovesDownAfterEdit={true}
          enableRangeSelection={true}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          animateRows={true}
          components={{
            layoutToolPanel: LayoutToolPanel,
          }}
        />
      </AgGridContainer>
    </DivGuard>
  );
};

export default function UserGroupsExport() {
  return <UserGroups />;
}