import { TagReportView } from "./TagReportView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy.js";

export const TagReportDashboard = ({ visible, }) => {
    const dashboardKey = 'tag-report';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={TagReportView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
