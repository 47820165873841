import { useMemo } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader, CssBaseline, Grid, Container, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';
import { useActionAudit } from './useActionAudit';
import { useTheme } from '@mui/material/styles';

//request a power station demo https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb
//request fast path access https://webforms.pipedrive.com/f/6y1Agmun10X14kzXAaApB03foxNbcawIKnQDevwdSlfbkKBbhuEH2bDiG1CnjIgpij
//fast path demo https://webforms.pipedrive.com/f/6k1duQB6AnyQfeLx6nxn9FCP3lSPlUOUZHegA2DKldxZt0MDcv4vRN5mNK1WagLkVd

export default function Pricing() {
  const { logAction } = useActionAudit();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const tiers = useMemo(() => [
    {
      title: 'Free',
      price: '0',
      description: [
        'Least Cost Trans Search',
        'TSR Market Insights',
        'ATC Path Monitoring',
        'ATC Availability Pop-up Alerts',
        'Unlimited Support Tickets',
        'Unlimited User Count',
        'Upgrade required for SMS Alerts',
      ],
      buttonText: 'Sign up for free',
      buttonVariant: 'outlined',
      priceUnits: '/user/mo',
      href: 'https://webforms.pipedrive.com/f/6y1Agmun10X14kzXAaApB03foxNbcawIKnQDevwdSlfbkKBbhuEH2bDiG1CnjIgpij',
    },
    {
      title: 'ProTrader',
      subheader: 'Most popular',
      price: '299',
      description: [
        'All FREE Features, plus...',
        'Alerts on Least Cost Routes',
        'Alerts When ATC Becomes Available',
        'Alerts on TSR Status Changes',
        'SMS/Text/Email Alerts',
        'Phone and Email Support',
        'Discounts @ 20,50,100 Users',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
      priceUnits: '/user/mo',
      href: 'https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb',
    },
    {
      title: 'Enterprise Trader',
      price: '499',
      description: [
        'All ProTrader Features, plus...',
        'Bulk Original Requests from any POR to POD',
        'Bulk TransAssign and Redirect Enabled',
        'KeyVault Certificate Mgmt',
        'Single Sign On Integration',
        'Priority Email and Phone Support',
        'SSO and KeyVault require additional activation fee',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
      priceUnits: '/user/mo',
      href: 'https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb',
    },
    {
      title: 'Power Station Trial',
      price: '1,500',
      description: [
        'Best tagging interface on the market',
        'Automated tag adjustment',
        'Transmission position reporting',
        'LMP Price heat maps and alerting',
        'Triggered actions based on market prices',
        '3-month trial with no vendor setup',
        '5 user minimum',
        'SSO and KeyVault require additional activation fee',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
      priceUnits: '/user',
      href: 'https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb',
    },
    {
      title: 'Deal Rizz Pro',
      price: '649',
      description: [
        'Manual and automated deal, tag, and schedule creation',
        'Stacked profile builder',
        'Deal position reporting',
        'Transmission Position Reporting',
        'LMP Heat Map, alerting and triggering',
        'Advanced discrepancy reporting (schedules, trans, tags)',
        'Build your own forecasting engine',
        '5 user minimum',
        'SSO and KeyVault require additional activation fee',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
      priceUnits: '/user/mo',
      href: 'https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb',
    },
    {
      title: 'Deal Rizz Platinum',
      price: '899',
      description: [
        'All Deal Rizz Pro Features, plus...',
        'Advanced neural network forecasting',
        'AI Based Help tools',
        'Tariff AF uses natural language to sort through tariff changes',
        'Predict future price changes, outage probability, congestion, curtailments, and more',
        'Trigger autonomous bids, TSRs, contract cancellation, tag adjustments, and more',
        'Priority Email and Phone Support',
        'SSO and KeyVault require additional activation fee',
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
      priceUnits: '/user/mo',
      href: 'https://webforms.pipedrive.com/f/6aTRjOwO4IzvYAlAyKriSGXYYwgHtInAoJuB8jeM5Lw6dXnWl4vyeXZi6usjf6oJCb',
    },
  ], []);

  return (
    <section>
      <CssBaseline />
      <Container maxWidth="md" sx={{ p: theme.spacing(8, 0, 6), }}>
        <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
          Pricing
        </Typography>
        <Typography maxWidth="lg" variant="h5" align="center" color="textSecondary" component="p">
          In the most basic cases traders and trading managers can simply select a subscription model and arrange for invoicing and payment according to the following simple structure.
          Additional charges apply for Single Sign On, Key Vault integration, and set up fees for certificates and some high speed alerting.  Please email support@powersysops.com for more information.
        </Typography>
      </Container>
      <Container maxWidth="lg" >
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  sx={{ backgroundColor: !isDarkMode && theme.palette.primary.backgroundContrast, }}
                />
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', marginBottom: theme.spacing(2), }}>
                    <Typography component="p" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" component='p'>
                      {tier.priceUnits}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="left" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    href={tier.href}
                    onClick={() => logAction(`Clicked ${tier.title} Button`, 'aboutPowerStation')}
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}