import { useState, useRef, memo } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import { useSnackbar } from 'notistack';
import AvailabilityGrid from './AvailabilityGrid';
import AlertGrid from './AlertGrid';
import DivGuard from '../Guards/DivGuard.js';
import { useActionAudit } from '../useActionAudit';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import useHeader from "../useHeader";
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import axios from 'axios';
import AvailabilityToolbar from './AvailabilityToolbar';
import { Box, } from '@mui/material';
import _ from 'lodash';
import { useIPContext } from '../IPContext/useIPContext';

const TransAlerts = (props) => {
  const { view } = props;
  const alertGridRef = useRef();
  const availabilityGridRef = useRef();
  const { logAction } = useActionAudit();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();
  const headers = useHeader();
  const [selectedRows, setSelectedRows] = useState([])
  const toolbarConfigKey = `${view}-toolbar-config`;
  const [timezone, setTimezone] = useState(JSON.parse(localStorage.getItem(toolbarConfigKey) ?? '{}').timezone ?? 'Pacific Standard Time');
  const ip = useIPContext();

  function handleAvailabilityUpdate(newData) {
    const oldData = [];
    availabilityGridRef.current.api.forEachNode(node => oldData.push(node.data));

    if (!oldData.length) {
      availabilityGridRef.current.api.applyTransaction({
        addIndex: 0,
        add: newData,
      })
      return;
    }

    const toAddOrUpdate = diffData(newData, oldData);
    const toDeleteOrUpdate = diffData(oldData, newData);
    const toAdd = diffData(toAddOrUpdate, toDeleteOrUpdate);
    const toUpdate = diffData(toAddOrUpdate, toAdd);
    const toDelete = diffData(toDeleteOrUpdate, toUpdate);

    availabilityGridRef.current.api.applyTransaction({
      addIndex: 0,
      add: toAdd,
      update: toUpdate,
      remove: toDelete,
    })
  }

  function diffData(arr1, arr2) {
    return _.differenceWith(arr1, arr2, _.isEqual);
  }

  function fetchAvailabilityData(withLog = true, criteria) {
    if (availabilityGridRef.current?.api) {
      availabilityGridRef.current.api.showLoadingOverlay();
    } else {
      console.error('Availability grid api not found');
      return;
    }

    setTimezone(criteria.timezone)
    const hourAndMinutes = criteria.hourEnding.split(':'); //Remove one hour from the hour ending so the start/stop times come back correctly
    const startDateTime = `${criteria.startDate} ${(parseInt(hourAndMinutes[0]) - 1)}:${hourAndMinutes[1]}`

    const loadAvailabilityUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_PathsFetch`
      + `&parm=${encodeURIComponent(criteria.timezone ?? '')}`
      + `&parm=${guid}`
      + `&parm=${encodeURIComponent(view ?? '')}`
      + `&parm=${encodeURIComponent(criteria.provider ?? '')}`
      + `&parm=${encodeURIComponent(criteria.por ?? '')}`
      + `&parm=${encodeURIComponent(criteria.pod ?? '')}`
      + `&parm=${encodeURIComponent(criteria.attribute ?? '')}`
      + `&parm=${encodeURIComponent(criteria.duration ?? '')}`
      + `&parm=${encodeURIComponent(startDateTime)}`;

    const options = {
      headers: headers,
      url: loadAvailabilityUri,
    }

    const logData = { smartFilter: view, ...criteria };

    withLog && logAction(`${view} segments retrieved for ${criteria.provider} - ${criteria.por} to ${criteria.pod}`, 'TransHunter', { IP: ip, ...logData });

    axios(options).then(response => {
      handleAvailabilityUpdate(response?.data ?? []);
      withLog && logAction(`Success retrieving segments. ${response.data?.length ?? 0} rows returned.`, 'TransHunter', { IP: ip, ...logData })
      if (availabilityGridRef.current?.api) {
        availabilityGridRef.current.api.hideOverlay();
      }
    }).catch(error => {
      handleAvailabilityUpdate([]);
      enqueueSnackbar(`Error loading availability data. ${error.message}`)
      logAction(`TransHunter fetch failed: ${error.message}`, 'TransHunter', { IP: ip, ...logData });
      if (availabilityGridRef.current?.api) {
        availabilityGridRef.current.api.hideOverlay();
      }
    });
  }

  return (
    <DivGuard
      groups={[userGroups.transhunter]}
      allowDisable={false}
      message='Results will be limited in the demo version. Please contact support to upgrade.'
    >
      <Box sx={{ width: '100%' }}>
        <div className="trans-hunter-example-wrapper">
          <div className="trans-hunter-container">
            <AvailabilityToolbar handleRefresh={fetchAvailabilityData} storageKey={toolbarConfigKey} />
            <div id='trans-hunter-availability-grid'>
              <AvailabilityGrid
                viewName={view}
                updateSelected={setSelectedRows}
                ref={availabilityGridRef}
              />
            </div>
            <div id='trans-hunter-alerts-grid'>
              <AlertGrid ref={alertGridRef} timezone={timezone} selectedRows={selectedRows} view={view} />
            </div>
            {/*<TransmissionTicker />*/}
          </div>
        </div>
      </Box>
    </DivGuard>
  );
};

export default memo(TransAlerts);