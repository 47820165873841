import { Stack, Button, Box, Paper, Divider, List, Popover, ListItem, Checkbox, ListItemButton, ListItemText, ClickAwayListener, } from '@mui/material';
import { useState } from 'react'; 

const DefaultDatePopover = (props) => {
  const [selected, setSelected] = useState('');

  function handleToggle(value) {
    setSelected(current => current === value ? '' : value);
  }

  const options = [
    { text: 'Next Hour', value: 'nextHour' },
    { text: 'Next Day', value: 'nextDay' },
    { text: 'Next Week', value: 'nextWeek' },
  ]

  return (
    <Popover
      {...props}
    >
      <ClickAwayListener onClickAway={props.handleClose}>
        <Paper>
          <List dense sx={{ width: '100%', bgcolor: 'background.paper'}} >
            {options.map(option => (
              <ListItem
                key={option.value}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggle(option.value)}
                    checked={selected === option.value}
                  />
                }
                disablePadding
              >
                <ListItemButton onClick={() => handleToggle(option.value)}>
                  <ListItemText id={`checkbox-list-secondary-label-${option.text}`} primary={option.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <Stack spacing={2} direction='row' justifyContent='space-between' sx={{ p: 1, }}>
            <Button
              //variant='contained'
              onClick={props.handleClose}
            >Close</Button>
            <Box width='10px'/>
            <Button
              variant='contained'
              onClick={() => props.handleApplyDefault(selected)}
            >Apply</Button>
          </Stack>
        </Paper>
      </ClickAwayListener>
    </Popover>
  )
}

export default DefaultDatePopover;