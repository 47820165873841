import { AppBar, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Table, TableContainer, TableHead, } from "@mui/material"
import renderTimezones from "../renderTimezones";
import { Collapse, Typography, ListItemText, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Button, Toolbar, IconButton, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, useRef, memo, useCallback, useReducer } from 'react'
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AgGridReact } from 'ag-grid-react';
import Profiles from './Profiles'
import hours from './Hours'
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import useHeader from "../../useHeader";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import dayjs from '../../dayjs-tz'

export default (props) => {
  const { open, closeDialog, currentEvent, handleProfileUpdate, disabled, } = props;
  const guid = useLocalGuid();
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [gridData, setGridData] = useState([]);
  const [disableHours, setDisableHours] = useState(false);
  const [tariffPrices, setTariffPrices] = useState([]);
  const [defaultPriceData, setDefaultPriceData] = useState({});
  const formatString = 'YYYY-MM-DD HH:mm:ss.SSS';
  const gridRef = useRef();

  const defaultProfileData = {
    //timezone: 'US/Pacific',
    defaultPrice: 0,
    defaultCapacity: 0,
    startHour: 0,
    stopHour: 0,
  }

  const [profile, setProfile] = useState(defaultProfileData);

  useEffect(() => {
    const info = currentEvent?.profileInfo ?? [];
    const config = currentEvent.profileConfig ?? {};

    const newProfile = {
      ...defaultProfileData,
      ...config,
      defaultProfile: '',
    }

    updateDefaultPrice();

    setProfile(newProfile);
    handleLoadData(info)
  }, []);

  useEffect(updateDefaultPrice, [tariffPrices]);

  useEffect(fetchTariffPrices, []);

  function handleUpdate(key, value) {
    const newConfig = {
      ...profile,
      [key]: value
    }

    switch (key) {
      case 'defaultPrice':
        updateRowPrice(value);
        break;
      case 'defaultCapacity':
        updateRowCapacity(value);
      default:
        const newData = generateTransmissionBlocksUsingTemplate(newConfig);
        setGridData(newData);
        break;
    }

    setProfile(newConfig)
  }

  const {
    startDate,
    startHour,
    stopDate,
    stopHour,
    defaultPrice,
    defaultCapacity,
    defaultProfile,
  } = profile;

  useEffect(() => {
    defaultProfile && setDisableHours(defaultProfile === 'HLH' || defaultProfile === 'LLH');
  }, [defaultProfile]);

  function getDefaultPrice(profile = defaultProfile) {
    if (profile === 'HLH') {
      return defaultPriceData?.oasisPeakPrice ?? defaultPrice;
    } else if (profile === 'LLH') {
      return defaultPriceData?.oasisOffPeakPrice ?? defaultPrice;
    } else {
      return defaultPrice;
    }
  }

  function formatHour(hour) {
    return (hour < 10 ? `0${hour}:00` : `${hour}:00`);
  };

  function fetchTariffPrices() {
    const options = {
      method: 'GET',
      headers: headers,
      url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TariffPricesFetch&parm=${guid}`
    }

    axios(options).then(response => {
      setTariffPrices(response.data);
    }).catch(error => {
      enqueueSnackbar(`${error.message} ${error.response?.data}`);
    });
  }

  function updateDefaultPrice() {
    if (!tariffPrices || !currentEvent) { return; }

    const priceData = tariffPrices.find(data =>
      data.Provider === currentEvent.Provider
      && data.Service_Increment === currentEvent.Service_Increment
    );

    setDefaultPriceData(priceData ?? {});
  }

  function handleLoadData(newProfileInfo) {
    const blocks = newProfileInfo?.map(block => {
      return {
        time: {
          start: moment(block.startDateTime),
          stop: moment(block.endDateTime),
        },
        price: block.price ?? 0,
        capacity: block.capacityRequested,
      }
    });
    blocks && setGridData(blocks);
  }

  function handleSave() {
    gridRef.current.api.stopEditing();
    const blocks = [];

    gridRef.current.api.forEachNode(node => { //validate and format data before sending to api
      const row = node.data;
      blocks.push({
        price: row.price,
        capacityRequested: row.capacity,
        startDateTime: row.time.start.format(formatString),
        endDateTime: row.time.stop.format(formatString),
      })
    });

    if (blocks.find(block => isNaN(parseInt(block.price)))) {
      enqueueSnackbar(`All time blocks must have a valid price.`);
      return;
    }

    const config = {
      ...profile,
    }

    handleProfileUpdate(blocks, config);
    closeDialog();
  }

  const gridOptions = {
    rowClassRules: {
      "row-missing-price": params => params.data.price === '',
    },
  };

  const colDefs = useMemo(() => [
    {
      field: 'time',
      headerName: 'Date/Time',
      editable: false,
      valueFormatter: params => {
        const start = params.value?.start;
        const stop = params.value?.stop;
        if (start?.isValid() && stop?.isValid()) {
          if (stop.isSame(start, 'day')) {
            return `${start.format('MM/DD/YYYY HH:mm')} - ${stop.format('HH:mm')}`
          } else {
            return `${start.format('MM/DD/YYYY HH:mm')} - ${stop.format('MM/DD/YYYY HH:mm')}`
          }
        }
      }
    },
    {
      valueFormatter: params => {
        const price = parseFloat(params.data.price)
        return price.toFixed(2);
      },
      headerName: 'Price',
      field: 'price',
    },
    {
      valueFormatter: params => parseInt(params.data.capacity),
      headerName: 'Capacity',
      field: 'capacity',
    }
  ], [disabled])

  function generateTransmissionBlocksUsingTemplate(config = profile) {
    const blocks = [];
    let start = dayjs(config.startDate).hour(config.startHour).startOf('hour');
    const end = dayjs(config.stopDate).hour(config.stopHour).startOf('hour');
    switch (config.defaultProfile) {
      case 'HLH':
        return hlhBlocks(start, end, config.defaultCapacity, config.defaultPrice);
      case 'LLH':
        return llhBlocks(start, end, config.defaultCapacity, config.defaultPrice);
      case 'Shaped':
        let next = start;
        while (end.isAfter(next, 'hour')) {
          blocks.push(createNewBlock(next, next.add(1, 'hour'), config.defaultCapacity, config.defaultPrice));
          next = dayjs(next).add(1, 'hour');
        }
        return blocks;
      case 'Flat':
        return atcBlocks(start, end, config.defaultCapacity, config.defaultPrice);
      default: return blocks;
    }
  }

  const createNewBlock = (start, end, capacity, price) => {
    return {
      time: {
        start: start,
        stop: end,
      },
      capacity: capacity,
      price: price,
    }
  }

  function generateBlocks(start, end, capacity, price, selector) {
    const blocks = [];
    let next = dayjs(start).startOf('hour');
    while (end.isAfter(next, 'hour')) {
      if (selector(next)) {
        blocks.push(createNewBlock(next, next.add(1, 'hour'), capacity, price));
      }
      next = next.add(1, 'hour');
    }
    return rollUpBlocks(blocks);
  }

  function atcBlocks(start, end, capacity, price) {
    const selector = () => true;
    return generateBlocks(start, end, capacity, price, selector);
  }

  function llhBlocks(start, end, capacity, price) {
    const selector = (date) => {
      const isOffPeak = date.hour() < 6 || date.hour() >= 22;
      const isSunday = date.day() === 0;
      return isOffPeak || isSunday;
    }
    return generateBlocks(start, end, capacity, price, selector);
  }

  function hlhBlocks(start, end, capacity, price) {
    const selector = (date) => {
      const isOnPeak = date.hour() >= 6 && date.hour() < 22;
      const isSunday = date.day() === 0;
      return (!isSunday && isOnPeak)
    }
    return generateBlocks(start, end, capacity, price, selector);
  }

  function rollUpBlocks(blocks) {
    return blocks.reduce((rolledUp, next) => {
      const last = rolledUp[rolledUp.length - 1];
      if (last && (last.time.stop.isSame(next.time.start, 'hour'))) {
        last.time.stop = next.time.stop;
      } else {
        rolledUp.push(next);
      }
      return rolledUp;
    }, [])
  }

  function clearDialog() {
    setGridData([]);
    handleUpdate('defaultProfile', '');
  }

  function updateRowPrice(newPrice) {
    const newData = gridData.map(row => {
      return {
        ...row,
        price: newPrice,
      }
    });
    setGridData(newData);
  }

  function updateRowCapacity(newCapacity) {
    const newData = gridData.map(row => {
      return {
        ...row,
        capacity: newCapacity,
      }
    });
    setGridData(newData);
  }

  function onGridReady(params) {
    const newData = gridData.map(row => {
      return {
        ...row,
        price: row.price ?? defaultPrice,
        capacity: row.capacity ?? defaultCapacity,
      }
    });
    setGridData(newData);
  }

  const defaultColDef = useMemo(() => ({
    editable: !disabled,
    resizable: true,
    flex: 1,
  }), [])

  const profileOptions = useMemo(() => {
    const allOptions = Object.keys(Profiles);
    let options = allOptions;
    if (['WEEKLY', 'MONTHLY', 'YEARLY'].includes(currentEvent.Service_Increment)) {
      options = allOptions.filter(option => option !== 'Shaped');
    }
    return options;
  }, [currentEvent.Service_Increment])

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth>
      <DialogTitle>Configure Profile</DialogTitle>
      <DialogContent style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 1, height: '100%', }}>
          <Grid container spacing={2} style={{ height: '100%', justifyContent: "flex-start", }}>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  fullWidth
                  disabled={disabled}
                  label="Start Date"
                  value={moment(startDate)}
                  onChange={(newValue) => handleUpdate('startDate', newValue?.startOf('date'))}
                  renderInput={(params) => <TextField {...params} fullWidth defaultValue={null} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={hours.map(formatHour)}
                defaultValue={formatHour(0)}
                disabled={disableHours || disabled}
                value={`${startHour}:00`}
                onChange={(_, newValue) => {
                  let val = parseInt(newValue?.split(':')[0]);
                  val = isNaN(val) ? 0 : val;
                  handleUpdate('startHour', val);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Start Hour"
                    color="success"
                    placeholder="Start Hour"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Stop Date"
                  disabled={disabled}
                  value={moment(stopDate)}
                  onChange={(newValue) => handleUpdate('stopDate', newValue?.startOf('date'))}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                id="Stop Hour"
                fullWidth
                defaultValue={formatHour(0)}
                disabled={disableHours || disabled}
                options={hours.map(formatHour)}
                value={`${stopHour}:00`}
                onChange={(_, newValue) => {
                  let val = parseInt(newValue?.split(':')[0]);
                  val = isNaN(val) ? 0 : val;
                  handleUpdate('stopHour', val);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Stop Hour"
                    color="success"
                    placeholder="Stop Hour"
                  />
                )}
              />
            </Grid>
            {/*<Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                  value={renderTimezones[timezone]}
                  onChange={(_, newValue) => {
                    handleUpdate('timezone', renderTimezones[newValue]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Timezone"
                      color="success" 
                      placeholder="Timezone"
                    />
                  )}
                />
              </Grid>*/}
            <Grid item xs={4}>
              <TextField
                disabled={disabled}
                value={defaultPrice}
                onChange={(e) => {
                  handleUpdate('defaultPrice', e.target.value)
                }}
                variant="outlined"
                label="Price"
                fullWidth
                color="success"
              //placeholder="Price"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled={disabled}
                value={defaultCapacity}
                onChange={(e) => handleUpdate('defaultCapacity', e.target.value)}
                variant="outlined"
                defaultValue={0}
                label="Capacity"
                fullWidth
                color="success"
                placeholder="Capacity"
              />
            </Grid>
            <Grid item xs={4} justifyContent='flex-end'>
              <Autocomplete
                disabled={disabled}
                value={defaultProfile}
                options={profileOptions}
                onChange={(_, newValue) => {
                  handleUpdate('defaultProfile', newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Profile"
                    color="success"
                    placeholder="Profile"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ height: '100%' }}>
              <AgGridContainer style={{ width: "100%", flexGrow: '1', overflow: 'hidden', height: '100%' }} >
                <AgGridReact
                  ref={gridRef}
                  rowData={gridData}
                  domLayout='autoHeight'
                  columnDefs={colDefs}
                  overlayNoRowsTemplate={'<span>Choose dates to view and edit profile information here.</span>'}
                  onGridReady={onGridReady}
                  enableRangeSelection
                  enableFillHandle
                  stopEditingWhenCellsLoseFocus
                  singleClickEdit
                  gridOptions={gridOptions}
                  defaultColDef={defaultColDef}
                />
              </AgGridContainer>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={closeDialog}>Cancel</Button>&nbsp;
        <Button disabled={disabled} variant="contained" color="primary" onClick={clearDialog}>Clear</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button disabled={disabled} variant="contained" color="primary" onClick={handleSave}>Save & Close</Button>
      </DialogActions>
    </Dialog>
  )
}