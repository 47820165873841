//a 1-1 mapping of user-friendly timezone names to what is expected by the backend.
export default {
    'Pacific': 'Pacific Standard Time',
    'Pacific Standard Time': 'Pacific',
    'Eastern': 'Eastern Standard Time',
    'Eastern Standard Time': 'Eastern',
    'Central': 'Central Standard Time',
    'Central Standard Time': 'Central',
    'Mountain': 'Mountain Standard Time',
    'Mountain Standard Time': 'Mountain',
}