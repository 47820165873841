import { TableBody, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, tableRowClasses } from "@mui/material";
import { styled } from '@mui/material/styles';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  //   // hover: {color: '#7EA5FF'},
     
  // },
}));

export const StyledTableCell = styled(TableCell)(({ theme, fontSize }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: fontSize ?? 12,
  },
}));