import { createContext, useMemo, useContext } from 'react';
import { useEffect, useState } from 'react';
import { useApi } from '../../useApi';

export const TaggingContext = createContext();

export const TaggingProvider = ({ children }) => {
    const [marketPathPseOptions, setMarketPathPseOptions] = useState([]);
    const [sourceOptions, setSourceOptions] = useState([]);
    const { headers, get, apiUrlPrefix } = useApi();

    useEffect(() => {
        fetchMarketPathPseOptions();
        //fetchSourceOptions();
    }, []);

    async function fetchMarketPathPseOptions() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.proc_GetPSEs&parm=${headers.userGuid}`

        return get(url).then(response => {
            const newData = response?.data?.map(obj => obj.EntityCode) ?? [];
            setMarketPathPseOptions(newData);
        });
    }

    async function fetchSourceOptions() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.proc_GetSourceList&parm=${headers.userGuid}`

        return get(url).then(response => {
            const newData = response?.data?.map(obj => obj.Name) ?? [];
            setSourceOptions(newData);
        });
    }

    const data = useMemo(() => ({
        pseOptions: marketPathPseOptions,
        sourceOptions: sourceOptions,
    }), [marketPathPseOptions, sourceOptions]);

    return (
        <TaggingContext.Provider value={data}>
            {children}
        </TaggingContext.Provider>
    )
}

export function useTaggingContext() {
    return useContext(TaggingContext);
}