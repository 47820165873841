import { DashboardLazy } from "../Dashboard/DashboardLazy";
import { ScheduleSummaryView } from "./ScheduleSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const ScheduleSummaryDashboard = ({ visible, }) => {
    const dashboardKey = 'schedule-summary';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={ScheduleSummaryView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
