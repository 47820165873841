import { Dialog, DialogActions, DialogContent, } from "@mui/material"
import { Collapse, Typography, ListItemText, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Button, Toolbar, IconButton, Divider, ListItem, Box, List, Autocomplete, TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect, useMemo, useRef, memo, useCallback, forwardRef, useImperativeHandle } from 'react'
import axios from "axios";
import useHeader from "../../useHeader";
import { apiUrlPrefix } from "../../../authConfig";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../../data/UserGuidContext/useLocalGuid";
import { AgGridReact } from 'ag-grid-react';
import { AgGridContainer } from "../../AgGrid/AgGridContainer";

export default (props) => {
  const { open, onClose, onCancel, ruleId, fetchUri, disabled = false, } = props;
  const gridRef = useRef();
  const headers = useHeader();
  const guid = useLocalGuid();
  const [rules, setRules] = useState([]);
  const uri = fetchUri ?? `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_DispatchQueueRulesFetch&parm=${guid}`;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(fetch, []);

  function onGridReady(params) {
    params.api.forEachNode(node => {
      if (node.data.dispatchQueueRuleID === ruleId) { node.setSelected(true); }
    })
  }

  function fetch() {
    const options = {
      url: uri,
      headers: headers,
    }

    axios(options).then(response => {
      setRules([...response.data, /*{
          description: 'Sends {tsrNum} TSRs starting at the schedule date and repeating every {repeatInterval} seconds.',
          dispatchQueueRuleID: 999,
          name: 'Machine Gun Test',
          ruleParams: {
            tsrNum: 10,
            repeatInterval: 0.2,
          },
        }*/]);
    }).catch(error => {
      enqueueSnackbar(`${error.message} ${error.response?.data ?? ''}`)
    });
  }

  function handleSave() {
    let selectedRule = {};
    gridRef.current.api.forEachNode(node => {
      if (node.isSelected()) { selectedRule = node.data; }
    });
    onClose(selectedRule);
  }

  const colDefs = useMemo(() => [
    {
      field: 'dispatchQueueRuleID',
      headerName: 'Id',
      //flex: 1,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      checkboxSelection: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      tooltipValueGetter: params => params.value,
      //cellEditor: 'ruleEditor',
      //cellEditorPopup: true,
      /*valueFormatter: params => {
        const ruleParams = params.data.ruleParams ?? {};
        const paramKeys = Object.keys(ruleParams);
        let newVal = params.value.replace(/[{}]/g, '')
        paramKeys.forEach(key => newVal = newVal.replace(key, ruleParams[key] ?? '__'))
        return newVal;
      },*/
      flex: 2,
    },
  ], [])

  const RuleEditor = forwardRef((props, ref) => {
    const re = /({[^}]*})/
    const split = props.value.split(re);
    const [ruleParams, setRuleParams] = useState(props.data.ruleParams)

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          const updatedRules = rules.filter(rule => rule.dispatchQueueRuleID !== props.data.dispatchQueueRuleID);
          setRules([
            ...updatedRules,
            {
              ...props.data,
              ruleParams: ruleParams,
            }
          ])
          return split.reduce((value, next) => {
            if (next.includes('{')) {
              const varName = next.replace(/[{}]/g, '');
              return value + `{${ruleParams[varName]}}`
            } else {
              return value + next;
            }
          }, '');
        },
      };
    });

    const renderInput = (strVal) => {
      if (strVal.includes('{')) {
        const varName = strVal.replace(/[{}]/g, '');
        return <TextField
          size='small'
          value={ruleParams[varName]}
          onChange={(e) => setRuleParams({
            ...ruleParams,
            [varName]: e.target.value
          })}
        />
      } else {
        return strVal;
      }
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, }}>
        {split.map(renderInput)}
      </Box>
    )
  })

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    resizable: true,
  }), [])

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth>
      <DialogContent>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item xs={12}>
            <AgGridContainer
              style={{
                width: "100%",
              }}
            >
              <AgGridReact
                ref={gridRef}
                columnDefs={colDefs}
                domLayout='autoHeight'
                rowData={rules}
                rowSelection='single'
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                tooltipShowDelay={1}
                components={{
                  ruleEditor: RuleEditor,
                }}
              />
            </AgGridContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCancel}>Cancel</Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" color="primary" disabled={disabled} onClick={handleSave}>Save Rule</Button>
      </DialogActions>
    </Dialog>
  )
}