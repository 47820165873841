import React, { useEffect } from 'react';
import { Popover, IconButton } from '@mui/material';
//import { usePopoverStyles } from "../styles";
import { useLocation } from 'react-router'

/** Reusable popover menu, pass children (content) and icon as props */
function PopoverNav(props) {
    //const classes = usePopoverStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        handleClose();
    }, [location]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton
                //aria-describedby={id}
                variant="contained"
                color="primary"
                onMouseDown={handleClick}
                size="large">
                {props.icon}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...props.popoverProps}
            >
                <div>
                    {props.children}
                </div>
            </Popover>
        </div>
    );
}

export default PopoverNav;
